@use "../form"as form;
@use "../card"as card;

.store-trading-hours-edit-widget {
  .collection-type {
    > label {
      @include form.top-align-label();
      font-weight: 500;
    }
    > .form-input {
      @include form.inline;
      padding: 0;
      grid-gap: 0;
      padding-bottom: 15px;
    }
  }

  .session {
    &:first-child {
      > .form-item {
        &:first-child {
          margin: 0;
          @include card.header;
          background-color: #f9f9f9;
          font-weight: 500;
        }
      }
    }
    .form-item {
      padding: 10px 15px;
    }
  }
}
