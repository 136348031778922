@use "../card"as card;
@use "../constants"as constants;
@use "../typography"as typography;

.restaurant-header-widget,
.store-header-widget {
  display: flex;
  flex-direction: horizontal;
  justify-content: center;
  align-items: center;

  .image {
    width: constants.$header-height - 20;
    border-radius: constants.$border-radius;
    border: 3px solid #f1f1f5;
    margin-right: 10px;
    overflow: hidden;
  }

  .name {
    @include typography.h3;
  }
}
