@use "../card";
@use "../colors";
@use "../modal";
@use "../viewport";

.pos-employee-shift-break-preset-edit-widget {
  border-radius: 25px;

  .title {
    font-size: 24px;
    padding: 35px 24px 14px 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.1px;
    color: colors.$blue-gray-900;
  }

  .close-button {
    @include modal.close-button;
    top: 22px;
    right: 8px;
  }

  form {
    .form-items {
      row-gap: 16px;

      .form-item {
        padding: 0 20px;

        label {
          color: colors.$blue-gray-900;
        }
      }

      .form-input {
        padding-top: 12px;

        .text-input {
          ::placeholder {
            font-weight: 300;
          }
        }

        .radio-group {
          display: flex;
          flex-wrap: wrap;
          gap: 6px;

          .radio {
            margin: 0 20px 0 0;
          }
        }

        .form-select {
          .break-limit {
            color: colors.$blue-gray-900;
            font-weight: 300;
          }

          .options {
            width: 92%;

            .option {
              color: colors.$blue-gray-900;
            }
          }
        }
      }

      .break-limit-hours-and-minutes {
        margin-left: 15px;
        padding: 0 20px;
        justify-self: start;

        .form-input {
          display: grid;
          grid-template-areas:
            'title  title'
            'hours  minutes'
            'meta   meta';
          width: min-content;

          input {
            min-width: 80px;
          }

          @include viewport.mobile {
            input {
              min-width: 50px;
            }
          }
        }

        .form-item {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          padding: 0;

          label {
            padding-top: 12px;
          }
        }

        .break-limit-hours {
          grid-area: hours;
          padding-right: 24px;
        }
        .break-limit-minutes {
          grid-area: minutes;
        }

        @include viewport.mobile {
          .form-item {
            padding: 0 20px 0 0;
          }
        }
      }

      .all-employee-tags {
        display: flex;
        flex-wrap: wrap;
        padding: 0 20px;
        max-width: 410px;
      }

      .form-multi-select-tag-autocomplete {
        .form-input {
          padding-top: 0;
        }

        .multi-select-tags {
          max-width: 410px;
        }

        .multi-select-autocomplete {
          &__menu {
            &-list {
              max-height: 100px;
            }
          }
        }
      }
    }

    .form-buttons {
      @include card.footer;
      display: flex;

      .submit-btn {
        margin-right: 8px;
      }

      .cancel-btn {
        min-width: 100px;
        background-color: colors.$white;
        color: colors.$material-gray-600;
      }
    }
  }
}
