@use "../card" as card;
@use "../buttons"as buttons;

.store-website-custom-page-version-edit-widget {
  form {
    @include card.container;
    @include card.content;

    .custom-page-version-code > div {
      height: 70%;
    }
  }
}
