@use "./typography" as typography;
@use "./themes/default" as default-theme;
@use "colors" as colors;

.checkbox-group {
  display: flex;
  align-items: center;

  .form-checkbox {
    margin-right: 48px;

    .checkbox-label {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #{colors.$restoplus-dark};
    }

    .checkbox-label i {
      content: url('../images/unchecked-checkbox.svg');
      position: relative;
      display: inline-block;
      margin-right: 7px;
      width: 20px;
      height: 20px;
      vertical-align: text-bottom;
      transition: all 0.3s linear;
    }

    .checkbox-label input:checked + i {
      content: url('../images/green-checkbox.svg');
      position: relative;
      display: inline-block;
      margin-right: 7px;
      width: 20px;
      height: 20px;
      vertical-align: text-bottom;
      transition: all 0.3s linear;
    }

    .checkbox-label input {
      display: none;
    }
  }
}
