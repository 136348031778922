@use '../card';
@use '../constants';
@use '../typography';
@use '../viewport';
@use '../buttons';
@use '../colors';
@use '../elements';
@use '../gap';

.pos-order-history-list-item-widget {
  @include card.container;
  @include gap.vertical(18px);
  margin-top: 20px;
  padding: 15px;

  > .header {
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    grid-template-areas:
      'status-indicator collection-type            order-status'
      '.                placed-date-time           grand-total';

    .status-indicator {
      grid-area: status-indicator;
      margin: 0 15px;
      align-self: center;
      width: 26px;
      height: 26px;
      &.success {
        background-color: colors.$green-500;
      }
      &.failure {
        background-color: colors.$rose-500;
      }
    }

    .collection-type {
      grid-area: collection-type;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #000521;
      align-self: center;
    }

    .order-status {
      grid-area: order-status;
      white-space: nowrap;
      justify-self: end;
      align-self: center;
      margin-right: 10px;
      padding: 5px 8px;
      font-size: 13px;
    }

    .grand-total {
      grid-area: grand-total;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 21px;
      align-self: center;
      text-align: right;
      margin-top: 10px;
      padding-right: 20px;
      color: #171725;
    }

    .placed-date-time {
      grid-area: placed-date-time;
      white-space: nowrap;
      align-self: center;
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 21px;
      color: #6c6c6c;
      margin-top: 10px;
    }
  }

  > .body {
    margin-left: 50px;
    > * {
      display: flex;
      margin-top: 10px;
      > .icon {
        @include elements.icon(18px);
      }

      > .info {
        margin-left: 10px;
        color: #171725;
      }
    }

    .pos-order-delivery-status-widget {
      margin-bottom: 15px;
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    margin-left: 50px;
    .view-pos-order {
      @include buttons.primary-transparent;
      border-radius: 5px;
      padding: 5px 15px;
    }
  }
}
