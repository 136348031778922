@use "../card" as card;
@use "../typography" as typography;
@use "../buttons"as buttons;
@use "../form"as form;
@use "../viewport" as viewport;
@use "../colors" as colors;

.print-docket-settings-edit-widget {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  height: min-content;
  background-color: var(--card-bg);
  box-shadow: 0px 0px 3px rgb(104 122 155 / 25%);
  border-radius: 10px;
  border: 1px solid #{colors.$med-gray};

  .title {
    @include card.header();
    @include card.header-font();
  }

  form {
    padding-top: 20px;
    .inline-title > label {
      font-weight: 500;
    }
  }

  .document-edit-widget {
    @include card.container();
  }

  .sub-text {
    color: var(--message-disabled-text);
    font-size: 12px;
    padding-left: 4px;
  }

  .form-buttons {
    @include card.footer;
    button {
      padding: 9.5px 32px;
    }
  }

  .form-item {
    padding: 0 18px;
  }
}
