@use "../typography";
@use "../utils";

.table-booking-history-list-widget {
  .header {
    margin-bottom: 20px;

    .title {
      @include typography.h1;
      @include utils.curve-padding;
    }
  }
}
