@use "../card"as card;
@use "../constants"as constants;
@use "../typography"as typography;
@use "../utils"as utils;
@use "../viewport" as viewport;

.batch-upload-job-view-widget {
  .info {
    @include card.container;
    margin-top: 12px;
    padding: 10px 14px;

    .title {
      font-weight: 500;

      .value {
        font-weight: 400;
      }
    }
  }

  .failed-stores {
    margin: 20px 0;

    @include viewport.desktop {
      @include card.container;
    }

    .no-failed-stores {
      padding: 15px;
    }

    .failed-stores-header {
      display: none;

      @include viewport.desktop {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: 1fr 1fr;
        padding: 20px 14px;
        grid-gap: 10px;
        font-weight: 600;
        background-color: #f3f3f3;
        border-bottom: 1px solid #e2e2ea;
      }
    }

    .failed-store-list {
      display: grid;
      grid-auto-flow: row;
      grid-template-columns: 1fr 1fr;
      padding: 20px 14px;
      margin: 20px 0;
      grid-gap: 10px;

      @include viewport.mobile {
        @include card.container;
      }

      @include viewport.desktop {
        margin: 0;
        padding: 10px 14px;
        border-bottom: 1px solid #e2e2ea;
      }

      @include viewport.desktop {
        grid-auto-flow: column;
      }

      .header {
        margin: 0;
        font-weight: 600;

        @include viewport.desktop {
          display: none;
        }
      }
    }
  }
}
