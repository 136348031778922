@use "../buttons"as buttons;
@use "../card"as card;

$padding-horizontal: 15px;

.platform-settings-edit-widget {
  form {
    padding: 20px 0 0;
  }

  @include card.container();

  .form-item {
    padding: 0 $padding-horizontal;
  }

  .actions {
    @include card.footer();
    @include buttons.button-group();
    button {
      margin-right: 10px;
    }
  }
}
