.badge {
  border-radius: 4px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 400;

  &.primary {
    background: rgba(59, 85, 243, 0.12);
    color: var(--color-primary);
  }

  &.success {
    background: rgba(0, 191, 75, 0.12);
    color: #0e9347;
  }

  &.failure {
    background: var(--message-error-bg);
    color: var(--message-error-fg);
  }

  &.warning {
    background: var(--message-warning-bg);
    color: var(--message-warning-fg);
  }
}
