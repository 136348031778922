@use '../card'as card;
@use '../constants'as constants;
@use '../typography'as typography;
@use '../viewport'as viewport;
@use '../buttons'as buttons;

.website-item-page {
  .item-actions {
    display: grid;
    grid-template-columns: 120px 1fr;
    grid-gap: 10px;
    margin-top: 30px;
    margin-bottom: 10px;

    .quantity {
      display: grid;
      grid-template-columns: min-content min-content min-content;
      background-color: white;
      padding: 0px 20px;
      border-radius: constants.$border-radius;
      justify-content: space-between;
      align-items: center;
      @include card.container();

      .icon {
        font-size: 16px;
      }
    }

    .add-to-cart-button {
      @include buttons.add-to-cart-button;
    }
  }
}
