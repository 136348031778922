@use '../colors';
@use '../viewport';
@use '../common/elements';
@use '../common/gap';

.uber-eats-edit-menu-price-widget {
  @include gap.vertical(12px);
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  > .header {
    display: grid;
    gap: 0 15px;
    grid-template-areas:
      'label        adjust-all-prices-button'
      'description    adjust-all-prices-button';
    grid-template-columns: 1fr max-content;

    > .label {
      grid-area: label;
      padding: 12px 0;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
    }

    > .description {
      grid-area: description;
      display: flex;

      > .icon {
        @include elements.icon(20px);
        margin-right: 8px;
      }

      color: colors.$blue-gray-500;
      white-space: pre-line;
    }

    .adjust-all-prices-button {
      grid-area: adjust-all-prices-button;
      padding: 10px 18px;
      height: fit-content;
      align-self: center;

      > .label {
        display: grid;
        grid-auto-flow: column;
        gap: 7px;
      }
    }
  }

  > .body {
    .categories {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.0015em;
      text-align: left;
      margin-bottom: 20px;

      > div:first-child {
        border-radius: 8px 8px 0 0;
      }

      > div:last-child {
        border-radius: 0 0 8px 8px;
      }

      .category {
        border-radius: 0%;
        border-bottom: none;

        .header {
          display: grid;
          font-weight: 500;
          grid-template-columns: 4fr repeat(3, 1fr);
          align-items: center;
          gap: 32px;
          padding: 12px 32px;
          background-color: colors.$light-gray-100;
        }

        .item-specs {
          > .item-spec:not(:last-child) {
            border-bottom: 1px solid colors.$blue-gray-100;
          }

          .item-spec {
            padding: 16px 0;
            margin: 0 32px;
            border-radius: 0;
            border: none;
            box-shadow: none;

            .container {
              &.basic-item-spec.deal-item-spec {
                display: grid;
                grid-template-columns: 4fr repeat(3, 1fr);
                align-items: center;
                gap: 32px;
              }

              &.pizza-item-spec {
                .item-name {
                  margin-bottom: 28px;
                }

                .size {
                  display: grid;
                  grid-template-columns: 4fr repeat(3, 1fr);
                  align-items: center;
                  gap: 32px;
                  margin-bottom: 16px;
                  margin-left: 40px;

                  &:not(:last-child) {
                    padding-bottom: 20px;
                    border-bottom: 1px solid colors.$blue-gray-100;
                  }
                }
              }
            }

            .input-container {
              font-size: 16px;
              display: flex;
              border-radius: 8px;
              max-width: 120px;
              border: 1px solid colors.$blue-gray-100;
              border-radius: 8px;
              align-items: center;
              box-shadow: 0px 1px 2px 0px #{colors.$blue-gray-900}0d;
              padding: 0 2px 0 14px;
              &.disabled {
                background: colors.$blue-gray-50;
                color: colors.$blue-gray-500;
              }
              .form-input {
                background: transparent !important;
                input {
                  font-size: 16px;
                  box-sizing: border-box;
                  line-height: 24px;
                  overflow: hidden;
                  width: 100%;
                  border: none;
                  padding: 10px 0 10px 6px;
                  outline: none;
                  background: transparent;

                  &:disabled {
                    background: transparent !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  > .back-to-top-button {
    background-color: red;
    position: fixed;
    top: 90vh;
    right: 50px;
    padding: 13px;
    background-color: colors.$white;
    border-radius: 30px;
    box-shadow: 0 0 3px #687a9b40;
  }

  .end-of-list {
    text-align: center;
    padding-bottom: 20px;
    color: colors.$blue-gray-500;
  }
}

.adjust-all-prices-modal {
  box-sizing: border-box;
  padding: 32px;

  .header {
    font-size: 24px;
    font-weight: 600;
    line-height: 28.8px;
    text-align: center;
    margin-bottom: 16px;
  }

  .description {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.0025em;
    text-align: left;
    color: colors.$blue-gray-700;

    img {
      margin-right: 8px;
    }

    span {
      font-weight: 600;
    }
  }

  .radio-group {
    display: flex;
    margin: 32px 0;
  }

  .forms {
    display: flex;

    > div:first-of-type {
      margin-right: 24px;
    }

    .input-container {
      display: flex;
      align-items: center;
      font-size: 16px;
      border-radius: 8px;
      min-width: 280px;
      border: 1px solid colors.$blue-gray-100 !important;
      border-radius: 8px;
      align-items: center;
      box-shadow: 0px 1px 2px 0px #{colors.$blue-gray-900}0d;
      margin: 6px 0;
      padding-left: 14px;
      color: colors.$blue-gray-200;
      .form-item {
        width: 100%;
        input {
          font-size: 16px;
          box-sizing: border-box;
          line-height: 24px;
          overflow: hidden;
          width: 100%;
          border: none;
          background: transparent;
          padding: 10px 0 10px 6px;
          outline: none;
        }
      }

      &.percent {
        position: relative;

        .percent-sign {
          position: absolute;
          right: 30px;
        }
      }
    }
  }

  .actions {
    display: flex;
    justify-content: space-evenly;
    margin-top: 36px;

    button {
      min-width: 260px;
      height: 52px;
    }

    & .cancel-button {
      background-color: transparent;
      color: colors.$blue-gray-500;
      border: 1px solid colors.$blue-gray-500;
    }
  }
}
