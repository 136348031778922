@use "../card"as card;
@use "../viewport"as viewport;
@use "../themes/default" as default-theme;
@use "../typography" as typography;

.sunmi-cloud-kitchen-printers-page {
  .table-header {
    @include card.container;
    @include card.stack;
    padding: 5px 20px;
    margin-bottom: 20px;
    gap: 10px;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: none;
    align-items: center;

    & p {
      color: #27283e;
      font-size: 13px;
      font-weight: 500;
    }

    .binding-status,
    .connection-status {
      text-align: center;
    }
  }
  > .header .wrapper .actions {
    button {
      border: solid 1px var(--color-primary);
    }
  }

  // load more button
  .more {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-top: 16px;
    border: 1px solid #{default-theme.$link-fg};
    background-color: #{default-theme.$form-element-bg};
    color: #{default-theme.$link-fg};
  }

  .sunmi-cloud-kitchen-printer {
    @include card.container;
    @include card.stack;
    gap: 10px;
    padding: 20px;
    margin-bottom: 20px;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: none;
    align-items: center;
    color: #27283e;

    .id,
    .msn {
      font-weight: 300;
    }

    .binding-status,
    .connection-status {
      font-weight: 500;
      text-align: center;
      &.online {
        color: #{default-theme.$active-highlight};
      }
      &.failure {
        color: #fc5a5a;
      }
      &.unknown {
        color: #6c6c6c;
      }
    }

    .name {
      font-weight: 500;
    }

    .actions {
      display: grid;
      grid-auto-flow: column;
      grid-template-rows: none;
      grid-gap: 10px;
      align-items: center;
      text-align: center;
      font-weight: 600;
      font-size: 12px;

      .dropdown {
        button {
          background-color: transparent;
          box-shadow: none;
          .icon {
            font-size: 26px;
          }
        }

        .options {
          right: -20px;
          padding: 16px;
        }
      }

      .action {
        cursor: pointer;
        padding: 10px;
        border-radius: 10px;
        border: solid 1px var(--color-primary);

        &.bind {
          color: var(--color-primary);
        }

        &.disabled {
          cursor: not-allowed;
          color: #bdbdbd;
          border: solid 1px #bdbdbd;
        }

        &.refresh {
          color: #ffffff;
          background-color: var(--color-primary);
        }
      }
    }
  }
}
