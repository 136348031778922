@use "../card";
@use "../colors";

.category-edit-widget {
  @include card.container;

  form {
    @include card.content;
    margin-top: 20px;
  }
}

.swal2-container {
  .category-edit-widget {
    max-width: 364px;
    padding: 32px 24px;
    border-radius: 20px;

    .swal2-title {
      color: colors.$blue-gray-900;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }

    .swal2-content {
      padding: 0;
      margin-top: 16px;

      color: colors.$blue-gray-500;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }

    .swal2-actions {
      margin-top: 24px;
      flex-direction: column;
      gap: 16px;
      padding: 0 24px;

      button {
        border-radius: 4px;
        padding: 14px 32px;
        margin: 0;

        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }

      .confirm-button {
        width: 100%;
        background-color: colors.$blue-600;
        color: colors.$white;
      }

      .cancel-button {
        width: 100%;
        background-color: colors.$white !important;
        color: colors.$blue-gray-700;
        border: 1px solid colors.$blue-gray-500;
      }
    }
  }
}
