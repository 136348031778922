@use "../card" as card;
@use "../constants"as constants;
@use "../modal" as modal;

@mixin title {
  .icon {
    margin-right: 12px;
    font-size: 24px;
  }

  label {
    font-family: Fira Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    color: #293340;
  }
}

.pos-surcharge-edit-widget {
  .header {
    @include title;
    display: flex;
    background-color: white;
    justify-content: space-between;
    padding: 35px 35px 14px 22px;

    .title {
      font-size: 20px;
      font-weight: bold;
    }

    .close-button {
      @include modal.close-button;
    }
  }

  form {
    @include card.content;
    padding: 0 20px 20px;

    .form-buttons {
      display: flex;
      gap: 12px;
    }
  }
}
