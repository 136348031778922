@use "constants";
@use "colors";
@use "viewport";

#toasts-container {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: grid;
  grid-gap: 15px;
  z-index: 1000;

  .wrapper {
    text-align: center;
  }
}

.toast {
  display: inline-grid;
  grid-template-columns: min-content max-content min-content;
  // display: flex;
  // flex-direction: row;
  align-items: center;
  padding: 10px 15px;
  border-radius: 6px;
  box-shadow: 0px 0px 3px rgba(104, 122, 155, 0.25);

  .icon {
    font-size: 30px;
    margin-right: 10px;
  }

  .message {
    margin: 0;
    font-size: 0.85rem;
    font-weight: 400;
    max-width: 550px;
  }

  button.close {
    margin-left: 10px;
    padding: 0;
    border: 0;
    background-color: transparent;
    box-shadow: none;

    opacity: 0.5;
    color: black;
    font-size: 1.4rem;
  }

  &.success {
    border: 1px solid colors.$green-500;
    background-color: var(--message-success-bg);
    color: var(--message-success-text);
  }

  &.info {
    background-color: var(--message-info-bg);
    color: var(--message-info-text);
  }

  &.error {
    border: 1px solid colors.$rose-500;
    background-color: var(--message-error-bg);
    color: var(--message-error-text);
  }

  &.warning {
    background-color: var(--message-warning-bg);
    color: var(--message-warning-text);
  }

  &.loading {
    background-color: var(--message-loading-bg);
    color: var(--message-loading-text);

    .spinner {
      width: 22px;
      height: 22px;
      margin-right: 8px;
      animation: spin 1s infinite linear;
    }
  }
}

@include viewport.mobile {
  .toast {
    > .message {
      max-width: 300px;
    }
  }
}
