@use "../card"as card;
@use "../buttons"as buttons;

.custom-page {
  @include card.container;
  @include card.stack;
  margin: 10px 0;

  .info {
    @include card.upper;
    padding: 10px 20px;
    min-width: 0;
  }

  .name {
    margin: 2px 0;
  }

  .link {
    font-size: 14px;
    margin: 2px 0;
    opacity: 0.75;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .page-actions {
    @include card.lower;
    @include card.round-buttons;

    .page-action {
      @include card.round-button;
    }
  }
}
