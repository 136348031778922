@use '../card';
@use '../constants';
@use '../typography';
@use '../viewport';
@use '../buttons';
@use '../colors';
@use '../elements';
@use '../gap';
@use '../pill';

.online-order-history-uber-eats-order-list-item-widget {
  @include card.container;
  @include gap.vertical(18px);
  margin-top: 20px;
  padding: 25px;

  > .header {
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    grid-template-areas:
      'collection-type uber-eats-icon order-status'
      'placed-time     placed-time    grand-total ';

    > .collection-type {
      @include typography.h2;
      grid-area: collection-type;
      color: #000521;
      align-self: center;
      white-space: nowrap;
      margin-bottom: 10px;
    }

    > .uber-eats-icon {
      grid-area: uber-eats-icon;
      margin-left: 5px;
      @include elements.icon(24px);
    }

    > .order-status {
      grid-area: order-status;
      white-space: nowrap;
      justify-self: end;
      align-self: center;
      padding: 5px 8px;
      font-size: 13px;
    }

    > .placed-time {
      grid-area: placed-time;
      white-space: nowrap;
      align-self: center;
      font-style: normal;
      color: #6c6c6c;
      margin-bottom: 10px;
      font-size: 13px;
      font-weight: 300px;
    }

    > .grand-total {
      @include typography.h3;
      grid-area: grand-total;
      font-style: normal;
      align-self: center;
      text-align: right;
    }
  }

  > .body {
    @include gap.vertical(5px);
    border-top: constants.$line-dashed;
    border-bottom: constants.$line-dashed;
    padding: 10px 0px;

    > * {
      display: flex;
      > .icon {
        @include elements.icon(18px);
      }
      > .info {
        margin-left: 10px;
      }
    }

    > .delivery-address {
      color: colors.$blue-gray-500;
    }

    > .pickup-time {
      color: colors.$blue-800;

      &.past-pickup-time {
        color: colors.$rose-800;
      }
    }

    > .delivery-status {
      color: colors.$blue-600;

      &.completed {
        color: colors.$teal-600;
      }

      &.failed {
        color: colors.$rose-800;
      }

      > .info {
        display: flex;

        > .delivery-channel {
          margin-right: 10px;
        }

        > .time {
          font-weight: 500;
        }
      }
    }

    > .more-info {
      &.cancelled {
        color: colors.$rose-800;
      }

      &.rejected {
        color: colors.$rose-800;
      }
    }
  }

  > .actions {
    padding-top: 15px;
    > .view-button {
      background-color: transparent;
      border: 1px solid;
      border-radius: 4px;
      color: colors.$blue-400;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    }
  }
}
