@use '../card' as card;
@use '../typography' as typography;
@use "../themes/default" as default-theme;
@use "../colors" as colors;

.table-list-widget {
  .header {
    width: 100%;
    display: flex;
    .actions {
      margin-left: auto;

      .create-table-button {
        background-color: colors.$white;
        padding: 7px 14px;

        .label {
          color: var(--color-primary);
        }
      }
    }
  }

  .body {
    margin-top: 20px;

    .list {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

      .table {
        @include card.container;

        .info {
          @include card.content;
          display: grid;
          grid-template-columns: 1fr min-content;

          .qr-code {
            border: 1px solid var(--form-element-border);
            border-radius: 10px;
            height: 100px;
            width: 100px;
          }

          .name {
            @include typography.h2;
          }
        }

        .actions {
          @include card.footer;
          @include card.round-buttons;
          @include card.round-button;

          padding: 7px 16px;

          .action {
            padding: 0 4px;
            color: #{default-theme.$button-action};
          }
        }
      }
    }
  }
}
