@use "../colors";
@use "../viewport";

.sms-marketing-campaign-widget {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 28px 0;

    .campaign-type {
      display: flex;

      button {
        margin-right: 12px;
        color: colors.$blue-gray-900;
        background-color: transparent !important;
        border: none;
        box-shadow: unset;
        outline: none;

        &.active {
          background-color: colors.$light-gray-300 !important;
        }
      }
    }
  }

  .campaign-list-header {
    display: grid;
    grid-template-columns: 2fr 1fr 1.3fr 1.3fr 2fr 48px;
    grid-auto-flow: column;
    grid-gap: 8px;
    padding: 8px 24px;
    line-height: 24px;
    align-items: center;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.0025em;
    color: colors.$blue-gray-500;
  }

  .campaign-list {
    .campaign-list-item {
      display: grid;
      grid-template-columns: 2fr 1fr 1.3fr 1.3fr 2fr 48px;
      grid-auto-flow: column;
      grid-gap: 8px;
      padding: 8px 24px;
      align-items: center;
      background-color: colors.$white;
      border: 1px solid colors.$blue-gray-100;
      border-bottom: none;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.0025em;
      color: colors.$blue-gray-900;

      @include viewport.mobile() {
        grid-template-columns: auto auto 48px;
        grid-template-areas:
          'title title more-button'
          'recipient recipient .'
          'status status .'
          'cost cost .'
          'date date .';

        .title {
          grid-area: title;
        }

        .recipient-count {
          grid-area: recipient;
        }

        .cost {
          grid-area: cost;
        }

        .date {
          grid-area: date;
        }

        .status-container {
          grid-area: status;
        }

        .more-button {
          grid-area: more-button;
          max-height: 20px;
        }
      }

      &:first-of-type {
        border-radius: 8px 8px 0 0;
      }

      &:nth-last-child(1) {
        border-bottom: 1px solid colors.$blue-gray-100;
        border-radius: 0 0 8px 8px;
      }

      &:first-child:nth-last-child(1) {
        border-bottom: 1px solid colors.$blue-gray-100;
        border-radius: 8px;
      }

      .status {
        padding: 4px 12px;
        border-radius: 100px;
        max-height: 29px;

        &.sent {
          color: colors.$green-700;
          background-color: colors.$green-50;
        }

        &.scheduled {
          color: colors.$orange-700;
          background-color: colors.$yellow-100;
        }
      }

      .more-button {
        padding: 0;

        button {
          background-color: transparent !important;
          border: none;
          box-shadow: unset;
        }

        .overlay {
          .options {
            right: -10px;
            top: -20px;
            padding: 0 !important;
            min-width: 180px;
          }
        }
      }
    }
  }

  .view-more {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    margin: 24px auto;
    background-color: colors.$blue-gray-100;

    .icon {
      margin-right: 0 !important;
      margin-left: 7px;
      transform: scale(60%);
    }
  }

  .back-to-top-button {
    position: fixed;
    top: 90vh;
    right: 50px;
    padding: 13px;
    background-color: colors.$white;
    border-radius: 30px;

    @media (max-height: 844px) {
      top: calc(100% - 150px);
    }

    @media (min-height: 845px) {
      top: calc(100% - 150px);
    }
  }
}
