@mixin h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.1px;
  color: var(--fg);
}

@mixin h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: var(--fg);
}

@mixin h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--fg);
}

@mixin h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--fg);
}

@mixin h5 {
}

@mixin h6 {
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.1px;
  font-size: 12px;
  color: #8d92a3;
  // color: #92929d;
}

@mixin p {
  font-size: 0.85rem;
  font-weight: 300;
}

h1 {
  @include h1;
}

h2 {
  @include h2;
}

h3 {
  @include h3;
}

h4 {
  @include h4;
}

h5 {
  @include h5;
}

h6 {
  @include h6;
}

p {
  @include p;
}

/**
  * Typography
  */
@mixin caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
