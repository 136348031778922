:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  letter-spacing: .1px;
  color: var(--fg);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

h2 {
  color: var(--fg);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

h3 {
  letter-spacing: .1px;
  color: var(--fg);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

h4 {
  letter-spacing: .1px;
  color: var(--fg);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

h6 {
  letter-spacing: .1px;
  color: #8d92a3;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

p {
  font-size: .85rem;
  font-weight: 300;
}

.alert {
  border-radius: 10px;
  grid-template-columns: min-content 1fr;
  grid-template-areas: "icon message";
  align-items: center;
  padding: 10px 15px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.alert.has-title {
  grid-template-areas: "icon title"
                       ". message";
}

.alert .icon {
  flex-direction: column;
  grid-area: icon;
  justify-self: center;
  font-size: 20px;
  display: flex;
}

.alert .icon i {
  margin-right: 10px;
  font-size: 25px;
}

.alert .title {
  letter-spacing: .1px;
  color: var(--fg);
  grid-area: title;
  align-self: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.alert .message {
  grid-area: message;
  align-self: center;
  font-size: .9rem;
  font-weight: 300;
}

.alert.success {
  background-color: var(--message-success-bg);
}

.alert.success .icon, .alert.success .title, .alert.success .message {
  color: var(--message-success-text);
}

.alert.info {
  background-color: var(--message-info-bg);
  color: var(--message-info-text);
}

.alert.info .icon, .alert.info .title, .alert.info .message {
  color: var(--message-info-text);
}

.alert.error {
  background-color: var(--message-error-bg);
  color: var(--message-error-text);
}

.alert.error .icon, .alert.error .title, .alert.error .message {
  color: var(--message-error-text);
}

.alert.warning {
  background-color: var(--message-warning-bg);
  color: var(--message-warning-text);
}

.alert.warning .icon, .alert.warning .title, .alert.warning .message {
  color: var(--message-warning-text);
}

.ar-4-2 {
  aspect-ratio: 4 / 2;
  object-fit: cover;
}

.ar-4-2-5 {
  aspect-ratio: 4 / 2.5;
  object-fit: cover;
}

.ar-8-3 {
  aspect-ratio: 8 / 3;
  object-fit: cover;
}

.ar-4-3 {
  aspect-ratio: 4 / 3;
  object-fit: cover;
}

.ar-1-1 {
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.ar-4-5 {
  aspect-ratio: 4 / 5;
  object-fit: cover;
}

.ar-4-6 {
  aspect-ratio: 4 / 6;
  object-fit: cover;
}

:root {
  --bg: #f4f4f4;
  --fg: #27283e;
  --color-primary: #0062ff;
  --color-secondary: #e97a6e;
  --link-fg: #1e75ff;
  --card-bg: white;
  --form-element-bg: white;
  --form-element-fg: #44444f;
  --form-element-border: #ddd;
  --form-element-error: #fc5a5a;
  --button-primary-bg: #0062ff;
  --button-primary-fg: white;
  --button-secondary-bg: white;
  --button-secondary-fg: #696974;
  --button-success-bg: #36b37e;
  --button-success-fg: white;
  --button-danger-bg: red;
  --button-danger-fg: white;
  --message-success-text: #10b981;
  --message-success-bg: #e3fcef;
  --message-success-fg: #36b37e;
  --message-error-text: #bf2600;
  --message-error-bg: #ffebe6;
  --message-error-fg: #ff5630;
  --message-info-text: #505f79;
  --message-info-bg: white;
  --message-info-fg: #2684ff;
  --message-warning-text: #ff8b00;
  --message-warning-bg: #fffae6;
  --message-warning-fg: #ffab00;
  --message-loading-text: #505f79;
  --message-loading-bg: white;
  --message-loading-fg: #ffab00;
  --message-disabled-text: #696974;
  --message-disabled-bg: #f5f5f5;
}

[data-theme="dark"] {
  --color-bg: #000;
}

button {
  text-align: center;
  border: 0;
  border-radius: 4px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  font-family: Poppins, sans-serif;
  font-size: .75rem;
  font-weight: 500;
  display: flex;
}

button .icon {
  margin-right: 7px;
  font-size: 20px;
}

button.primary {
  color: var(--button-primary-fg);
  background-color: #2563eb;
}

button.success {
  background-color: var(--button-success-bg);
  color: var(--button-success-fg);
}

button.failure {
  color: var(--button-danger-fg);
  background-color: #9f1239;
}

button.disabled {
  cursor: not-allowed;
  color: #696974 !important;
  background-color: #f5f5f5 !important;
  border-color: #e2e2ea !important;
}

.checkbox {
  color: #555;
  grid-gap: 2px;
  grid-template-columns: min-content min-content;
  font-weight: 300;
  display: grid;
}

.checkbox input {
  vertical-align: middle;
}

.checkbox label {
  white-space: nowrap;
}

.form-switch {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
}

.form-switch i {
  width: 46px;
  height: 26px;
  vertical-align: text-bottom;
  background-color: #e6e6e6;
  border-radius: 23px;
  margin-right: 4px;
  transition: all .3s linear;
  display: inline-block;
  position: relative;
}

.form-switch i:before {
  content: "";
  width: 42px;
  height: 22px;
  background-color: #fff;
  border-radius: 11px;
  transition: all .25s linear;
  position: absolute;
  left: 0;
  transform: translate3d(2px, 2px, 0)scale3d(1, 1, 1);
}

.form-switch i:after {
  content: "";
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 11px;
  transition: all .2s ease-in-out;
  position: absolute;
  left: 0;
  transform: translate3d(2px, 2px, 0);
  box-shadow: 0 2px 2px #0000003d;
}

.form-switch:active i:after {
  width: 28px;
  transform: translate3d(2px, 2px, 0);
}

.form-switch:active input:checked + i:after {
  transform: translate3d(16px, 2px, 0);
}

.form-switch input {
  display: none;
}

.form-switch input:checked + i {
  background-color: #4bd763;
}

.form-switch input:checked + i:before {
  transform: translate3d(18px, 2px, 0)scale3d(0, 0, 0);
}

.form-switch input:checked + i:after {
  transform: translate3d(22px, 2px, 0);
}

@media (max-width: 767px) {
  .form-item.switch {
    grid-template-columns: auto max-content;
    grid-template-areas: "a b"
                         "c d";
    display: grid;
  }

  .form-item.switch label {
    grid-area: a;
    align-self: center;
  }

  .form-item.switch .form-input {
    grid-area: b;
    justify-self: end;
  }

  .form-item.switch .meta {
    margin-top: -3px;
    grid-area: c !important;
  }
}

form.default .form-item.switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

form.default .form-item.switch label {
  grid-area: a;
  align-self: center;
}

form.default .form-item.switch .form-input {
  grid-area: b;
  justify-self: end;
}

form.default .form-item.switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

form label {
  margin-left: 4px;
  font-size: .8rem;
}

form input[type="text"], form input[type="number"], form input[type="password"], form textarea {
  background-color: var(--form-element-bg);
  border: 1px solid var(--form-element-border);
  box-sizing: border-box;
  color: var(--form-element-fg);
  width: 100%;
  border-radius: 10px;
  margin: 0;
  padding: 10px 14px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  transition: all .3s;
}

form input[type="file"]::-webkit-file-upload-button {
  border: 1px solid var(--form-element-border);
  background: var(--form-element-bg);
  border-radius: 5px;
}

form input[type="file"] {
  font-family: Poppins, sans-serif;
}

form .image-preview {
  margin-bottom: 15px;
}

form .image-preview img {
  max-width: 100px;
  border: 3px solid #f1f1f5;
  border-radius: 20px;
  margin: 0;
  padding: 0;
}

form .video-preview {
  align-items: center;
  display: flex;
}

form .video-preview img {
  height: 20px;
  border: none;
  margin-right: 5px;
}

form .form-input {
  position: relative;
}

form .form-input.has-icon {
  background-color: var(--form-element-bg);
  border: 1px solid var(--form-element-border);
  box-sizing: border-box;
  color: var(--form-element-fg);
  width: 100%;
  border-radius: 10px;
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  transition: all .3s;
  display: flex;
}

form .form-input.has-icon .icon {
  width: 30px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-left: 12px;
  display: flex;
}

form .form-input.has-icon img {
  width: 100%;
  align-self: center;
}

form .form-input.has-icon input {
  border: 0;
  margin: 0;
}

form .form-input .has-prepend {
  display: flex;
}

form .form-input .has-prepend .prepend-text {
  background-color: var(--form-element-bg);
  border: 1px solid var(--form-element-border);
  box-sizing: border-box;
  color: var(--form-element-fg);
  width: 100%;
  width: auto;
  border-right: none;
  border-radius: 10px 0 0 10px;
  margin: 0;
  padding: 10px 14px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  transition: all .3s;
}

form .form-input .has-prepend input {
  width: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

form .form-input .show-password {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  font-size: 12px;
  position: absolute;
  top: 12px;
  right: 15px;
}

form .form-items {
  grid-row-gap: 18px;
  display: grid;
}

form .form-buttons {
  margin-top: 20px;
}

form .form-buttons .submit-btn {
  min-width: 100px;
}

form .select {
  background-color: var(--form-element-bg);
  border: 1px solid var(--form-element-border);
  box-sizing: border-box;
  color: var(--form-element-fg);
  width: 100%;
  border-radius: 10px;
  margin: 0;
  padding: 10px 14px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  transition: all .3s;
}

form .meta {
  margin-top: 5px;
  margin-left: 5px;
}

form .error {
  color: var(--form-element-error);
  font-size: 12px;
}

form .help {
  color: #6c6c6c;
  white-space: pre-wrap;
  font-size: 12px;
}

form select {
  box-sizing: none;
  padding: none;
  width: 100%;
  background: none;
  border: none;
  margin: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
}

form .checkbox {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

form .clear-link {
  color: var(--form-element-error);
  cursor: pointer;
  font-size: 12px;
  text-decoration: underline;
}

@media (min-width: 768px) {
  form.responsive {
    grid-template-columns: 180px 4fr 1fr;
    display: grid;
  }

  form.responsive .form-items > .form-item > label {
    text-align: right;
    color: #27283e;
    align-self: center;
    margin-right: 15px;
  }

  form.responsive .form-items {
    grid-area: 1 / 1 / auto / -1;
    row-gap: 25px;
  }

  form.responsive .form-items > .form-item {
    grid-template-columns: 180px 4fr 1fr;
    display: grid;
  }

  form.responsive .form-items > .form-item label {
    grid-area: 1 / 1 / auto / 2;
  }

  form.responsive .form-items > .form-item .form-input {
    grid-area: 1 / 2 / auto / 3;
  }

  form.responsive .form-items > .form-item .meta {
    grid-area: 2 / 2 / auto / 3;
  }

  form.responsive .form-input {
    margin-top: 0;
  }

  form.responsive .form-buttons {
    grid-area: 2 / 2 / auto / 3;
    margin-top: 30px;
    padding-left: 4px;
  }

  form.horizontal .form-items {
    grid-column-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    display: grid;
  }
}

textarea.invalid, input.invalid, .input-box.invalid {
  border-color: var(--form-element-error) !important;
}

input:disabled, textarea:disabled, .form-input.disabled {
  background: #f9f9f9 !important;
}

.form-item.inline-title {
  background-color: #f9f9f9;
  font-weight: 400;
  padding: 8px 18px !important;
}

.DayPicker {
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  display: grid;
}

@media (min-width: 1024px) {
  .DayPicker {
    background: #fff;
    border: 1px solid #73737324;
    border-radius: 10px;
    position: absolute;
    left: -28px;
  }
}

.DayPicker-wrapper {
  -webkit-user-select: none;
  user-select: none;
  flex-direction: row;
  padding-bottom: 1em;
  position: relative;
}

.DayPicker-Months {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.DayPicker-Month {
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-user-select: none;
  user-select: none;
  margin: 1em 1em 0;
  display: table;
}

.DayPicker-NavButton {
  width: 1.25em;
  height: 1.25em;
  color: #8b9898;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  margin-top: 2px;
  display: inline-block;
  position: absolute;
  top: 1em;
  left: auto;
  right: 1.5em;
}

.DayPicker-NavButton:hover {
  opacity: .8;
}

.DayPicker-NavButton--prev {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC");
  margin-right: 1.5em;
}

.DayPicker-NavButton--next {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==");
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  text-align: left;
  margin-bottom: .5em;
  padding: 0 .5em;
  display: table-caption;
}

.DayPicker-Caption > div {
  font-size: 1.15em;
  font-weight: 500;
}

.DayPicker-Weekdays {
  margin-top: 1em;
  display: table-header-group;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  color: #8b9898;
  text-align: center;
  padding: .5em;
  font-size: .875em;
  display: table-cell;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  padding: .5em;
  display: table-cell;
}

.DayPicker-WeekNumber {
  min-width: 1em;
  color: #8b9898;
  vertical-align: middle;
  text-align: right;
  cursor: pointer;
  border-right: 1px solid #eaecec;
  padding: .5em;
  font-size: .75em;
  display: table-cell;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: .5em;
}

.DayPicker-TodayButton {
  box-shadow: none;
  color: #4a90e2;
  cursor: pointer;
  background-color: #0000;
  background-image: none;
  border: none;
  font-size: .875em;
}

.DayPicker-Day--today {
  color: #d0021b;
  font-weight: 700;
}

.DayPicker-Day--outside {
  color: #8b9898;
  cursor: default;
}

.DayPicker-Day--disabled {
  color: #dce0e0;
  cursor: default;
}

.DayPicker-Day--sunday {
  background-color: #f7f8f8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #dce0e0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  color: #f0f8ff;
  background-color: #4a90e2;
  position: relative;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #51a0fa;
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #f0f8ff;
}

.DayPickerInput {
  display: inline-block;
}

.DayPickerInput-OverlayWrapper {
  z-index: 998;
  position: fixed;
  inset: 0;
}

@media (max-width: 1023px) {
  .DayPickerInput-OverlayWrapper {
    background: #000000b3;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

@media (min-width: 1024px) {
  .DayPickerInput-OverlayWrapper {
    background: unset;
    position: relative;
  }
}

.DayPickerInput-Overlay {
  z-index: 1;
  background: #fff;
  border-radius: 10px;
  left: 0;
  box-shadow: 0 2px 5px #00000026;
}

.DayPickerInput {
  width: 100%;
}

.DayPickerInput input {
  background-color: var(--form-element-bg);
  border: 1px solid var(--form-element-border);
  box-sizing: border-box;
  color: var(--form-element-fg);
  width: 100%;
  cursor: pointer;
  border-radius: 10px;
  margin: 0;
  padding: 10px 14px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  transition: all .3s;
}

.spinner {
  color: var(--color-primary);
  font-size: 30px;
  line-height: 30px;
}

.spinner .spinner {
  animation: 1s linear infinite spin;
}

.form-item.key-value-pair {
  grid-template-columns: auto 1fr;
  grid-template-areas: "a b"
                       "c c";
  display: grid;
}

.form-item.key-value-pair label {
  grid-area: a;
  align-self: flex-start;
}

.form-item.key-value-pair .form-input {
  flex-direction: row;
  grid-area: b;
  justify-content: center;
  justify-self: end;
  align-items: center;
  display: flex;
}

.form-item.key-value-pair .form-input span {
  text-align: right;
}

.form-item.key-value-pair .form-input .icon {
  padding-left: 5px;
  font-size: 16px;
}

.form-item.key-value-pair .form-input .icon.warning {
  color: #ff0;
}

.form-item.key-value-pair .form-input .icon.failure {
  color: red;
}

.form-item.key-value-pair .form-input .icon.success {
  color: green;
}

.form-item.key-value-pair .meta {
  margin-top: 5px;
  grid-area: c !important;
}

.react-tagsinput {
  background-color: #fff;
  padding-top: 5px;
  padding-left: 5px;
  overflow: hidden;
}

.react-tagsinput--focused {
  border-color: var(--message-success-text);
}

.react-tagsinput-tag {
  background-color: var(--message-success-bg);
  color: var(--message-success-text);
  border-radius: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
  font-size: .85rem;
  font-weight: 300;
  display: inline-block;
}

.react-tagsinput-remove {
  cursor: pointer;
  color: var(--message-success-text);
  font-weight: bold;
}

.react-tagsinput-tag a:before {
  content: " ×";
}

.form-address-search > label {
  margin-top: 12px;
  align-self: flex-start !important;
}

.form-address-search > .form-input {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.form-address-search .search {
  border-bottom: 1px solid #f1f1f5;
  padding: 12px 18px;
}

.form-address-search .info {
  grid-gap: 18px;
  padding: 20px 15px;
  display: grid;
}

.form-address-search .info .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.form-address-search .info .switch label {
  grid-area: a;
  align-self: center;
}

.form-address-search .info .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.form-address-search .info .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.form-address-search .info .switch label {
  text-align: left !important;
}

.form-address-search .geopoint {
  grid-gap: 15px;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.form-single-select-pill {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.form-single-select-pill .label {
  letter-spacing: .1px;
  color: var(--fg);
  font-size: 16px;
  font-weight: 300;
  line-height: 16px;
}

.form-single-select-pill .help-text {
  letter-spacing: .1px;
  color: #71717b;
  margin-bottom: 1rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.form-single-select-pill .error {
  margin-left: 10px;
  margin-right: auto;
}

.form-single-select-pill .options.large {
  grid-template-columns: repeat(4, 1fr);
  justify-self: end;
  display: grid;
}

@media (max-width: 767px) {
  .form-single-select-pill .options.large {
    grid-template-columns: repeat(2, 1fr);
  }
}

.form-single-select-pill .options.large .option {
  cursor: pointer;
  text-align: center;
  color: #0062ff;
  border: 2.25px solid #0062ff;
  border-radius: .85rem;
  justify-content: center;
  align-items: center;
  margin: 0 1rem 1rem 0;
  padding: .5rem 1rem;
  font-weight: 500;
}

.form-single-select-pill .options.large .option.selected {
  color: var(--card-bg);
  background-color: #0062ff;
}

.form-single-select-pill .options.small {
  flex-wrap: wrap;
  justify-self: end;
  display: flex;
}

.form-single-select-pill .options.small .option {
  cursor: pointer;
  text-align: center;
  color: #0062ff;
  border: 2px solid #0062ff;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin-inline: 5px;
  padding: 5.5px 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
}

.form-single-select-pill .options.small .option.selected {
  color: var(--card-bg);
  background-color: #0062ff;
}

.form-multi-select-pill {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.form-multi-select-pill .label {
  letter-spacing: .1px;
  color: var(--fg);
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
}

.form-multi-select-pill .help-text {
  letter-spacing: .1px;
  color: #71717b;
  margin-bottom: 1rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.form-multi-select-pill .error {
  margin-left: 10px;
  margin-right: auto;
}

.form-multi-select-pill .options.large {
  grid-template-columns: repeat(4, 1fr);
  justify-self: end;
  display: grid;
}

@media (max-width: 767px) {
  .form-multi-select-pill .options.large {
    grid-template-columns: repeat(2, 1fr);
  }
}

.form-multi-select-pill .options.large .option {
  cursor: pointer;
  text-align: center;
  color: #0062ff;
  border: 2.25px solid #0062ff;
  border-radius: .85rem;
  justify-content: center;
  align-items: center;
  margin: 0 1rem 1rem 0;
  padding: .5rem 1rem;
  font-weight: 500;
}

.form-multi-select-pill .options.large .option.selected {
  color: var(--card-bg);
  background-color: #0062ff;
}

.form-multi-select-pill .options.small {
  flex-wrap: wrap;
  justify-self: end;
  display: flex;
}

.form-multi-select-pill .options.small .option {
  cursor: pointer;
  text-align: center;
  color: #0062ff;
  border: 2px solid #0062ff;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin-inline: 5px;
  padding: 5.5px 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
}

.form-multi-select-pill .options.small .option.selected {
  color: var(--card-bg);
  background-color: #0062ff;
}

.form-color-picker .form-input {
  background-color: var(--card-bg);
  min-width: 251px;
  max-width: 283px;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  flex-direction: column;
  display: flex;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.form-color-picker .form-input .header {
  min-height: 80px;
  color: var(--form-element-fg);
  border-bottom: 1px solid #f1f1f5;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 12px 18px;
  display: flex;
}

.form-color-picker .form-input .header > .icon img {
  width: 40px;
  margin-bottom: 18px;
  padding-top: 12px;
}

.form-color-picker .form-input .header > .label {
  letter-spacing: .1px;
  color: var(--fg);
  color: unset;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.form-color-picker .form-input .picker {
  padding: 15px 18px;
}

.form-color-picker .form-input .picker .color-palette {
  grid-template-columns: repeat(5, 1fr);
  place-items: center;
  gap: 10px;
  margin-bottom: 10px;
  display: grid;
}

.form-color-picker .form-input .picker .color-palette .color {
  aspect-ratio: 1 / 1;
  height: 35px;
  cursor: pointer;
  border: 1px solid;
  border-radius: 4px;
  display: inline-block;
  position: relative;
}

.form-color-picker .form-input .picker .color-palette .color.selected {
  background-image: url("icon-check-black.6727bc5a.svg");
  background-position: center;
  background-repeat: no-repeat;
  border: 2px solid #000 !important;
}

.form-multi-select-tag-autocomplete {
  padding: 0 15px;
}

.form-multi-select-tag-autocomplete .multi-select-tags {
  flex-wrap: wrap;
  padding-bottom: 12px;
  display: flex;
}

.form-multi-select-tag-autocomplete .multi-select-autocomplete__control {
  cursor: text;
  border-radius: 10px;
}

.form-multi-select-tag-autocomplete .multi-select-autocomplete.invalid {
  border-color: inherit;
  padding-bottom: 6px;
}

.form-multi-select-tag-autocomplete .multi-select-autocomplete.invalid .multi-select-autocomplete__control:has(input:not(:focus)) {
  border-color: #fc5a5a;
}

.form-multi-select-tag-autocomplete .multi-select-autocomplete__menu {
  border-radius: 10px;
  position: relative;
  box-shadow: 0 0 6px #687a9b80;
}

.form-multi-select-tag-autocomplete .multi-select-autocomplete__menu .multi-select-autocomplete__option {
  cursor: pointer;
  color: #1c1d3e;
}

.checkbox-group {
  align-items: center;
  display: flex;
}

.checkbox-group .form-checkbox {
  margin-right: 48px;
}

.checkbox-group .form-checkbox .checkbox-label {
  cursor: pointer;
  color: #27283e;
  justify-content: center;
  align-items: center;
  display: flex;
}

.checkbox-group .form-checkbox .checkbox-label i {
  content: url("unchecked-checkbox.16af05c6.svg");
  width: 20px;
  height: 20px;
  vertical-align: text-bottom;
  margin-right: 7px;
  transition: all .3s linear;
  display: inline-block;
  position: relative;
}

.checkbox-group .form-checkbox .checkbox-label input:checked + i {
  content: url("green-checkbox.53cbd811.svg");
  width: 20px;
  height: 20px;
  vertical-align: text-bottom;
  margin-right: 7px;
  transition: all .3s linear;
  display: inline-block;
  position: relative;
}

.checkbox-group .form-checkbox .checkbox-label input {
  display: none;
}

.form-banner .alert.info {
  color: #4c4e68;
  background-color: #f4f4f6;
}

.form-banner .alert.info .message {
  color: #4c4e68;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.ReactModalPortal .ReactModal__Overlay {
  z-index: 1000;
  display: flex;
  background-color: #0009 !important;
}

.ReactModalPortal .ReactModal__Overlay .ReactModal__Content {
  border: unset !important;
  background: none !important;
  border-radius: 0 !important;
  padding: 0 !important;
}

.ReactModalPortal .ReactModal__Overlay .ReactModal__Content .modal {
  background-color: var(--card-bg);
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  max-height: calc(100vh - 100px);
  max-width: 90vw;
  border: 1px solid #e2e2ea;
  border-radius: 20px;
  overflow: auto;
  box-shadow: 0 0 3px #687a9b40;
}

.ReactModalPortal .ReactModal__Overlay .ReactModal__Content .modal::-webkit-scrollbar {
  width: 1px;
}

.ReactModalPortal .ReactModal__Overlay .ReactModal__Content .modal::-webkit-scrollbar-track {
  background: none;
}

.ReactModalPortal .ReactModal__Overlay .ReactModal__Content .modal::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.ReactModalPortal .ReactModal__Overlay .ReactModal__Content .modal .close {
  width: 27px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 10px;
}

@media (max-width: 767px) {
  .ReactModalPortal .ReactModal__Overlay .ReactModal__Content .modal.bottom-sheet {
    max-width: 100vw;
    max-height: -webkit-fill-available;
    border-radius: 20px 20px 0 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

.time-input .input-box {
  background-color: var(--form-element-bg);
  border: 1px solid var(--form-element-border);
  box-sizing: border-box;
  color: var(--form-element-fg);
  width: 100%;
  border-radius: 10px;
  align-items: center;
  margin: 0;
  padding: 10px 14px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  transition: all .3s;
  display: flex;
}

.time-input .input-box.disabled .input-box {
  color: #696974;
  cursor: auto;
  background-color: #f5f5f5;
  border-color: #e2e2ea;
}

.time-input .input-box input[type="text"] {
  text-align: center;
  width: 25px;
  border: none;
  padding: 0;
}

.time-input .input-box input::placeholder {
  color: #c6c7d1;
}

.time-input .input-box .separator {
  margin: 0 2.5px;
}

.time-input .input-box .icon {
  cursor: pointer;
  margin-left: auto;
  font-size: 16px;
}

.time-input .input-box.invalid > .icon {
  color: #9f1239;
}

.time-input .options {
  background-color: var(--card-bg);
  height: 200px;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 15px 18px;
  font-weight: 400;
  display: flex;
  overflow-y: hidden;
  box-shadow: 0 0 3px #687a9b40;
}

@media (max-width: 767px) {
  .time-input .options {
    max-width: 80vw;
    max-height: 80vh;
    overflow: auto;
  }
}

.time-input .options .option-list {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  height: inherit;
  overflow-y: scroll;
}

.time-input .options .option-list::-webkit-scrollbar {
  width: 1px;
}

.time-input .options .option-list::-webkit-scrollbar-track {
  background: none;
}

.time-input .options .option-list::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.time-input .options .option-list .option {
  text-align: center;
  cursor: pointer;
  padding: 8px;
}

.time-input .options .option-list .selected {
  background-color: var(--message-success-bg);
  color: var(--message-success-text);
  border-radius: 4px;
}

@media (max-width: 767px) {
  .time-input .overlay {
    z-index: 998;
    background: #000000b3;
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    inset: 0;
  }
}

@media (min-width: 768px) {
  .time-input .options-wrapper {
    position: relative;
  }

  .time-input .options {
    z-index: 999;
    margin-top: 10px;
    position: absolute;
    top: 100%;
  }
}

.dropdown > button {
  background-color: #fff;
}

.dropdown > button.open {
  color: #c6c7d1;
}

.dropdown .input-box {
  background-color: var(--form-element-bg);
  border: 1px solid var(--form-element-border);
  box-sizing: border-box;
  color: var(--form-element-fg);
  width: 100%;
  cursor: pointer;
  border-radius: 10px;
  margin: 0;
  padding: 10px 14px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  transition: all .3s;
}

.dropdown .options {
  background-color: var(--card-bg);
  grid-gap: 5px;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 10px 0;
  font-weight: 400;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

@media (max-width: 767px) {
  .dropdown .options {
    max-width: 80vw;
    max-height: 80vh;
    overflow: auto;
  }
}

.dropdown .options .search-box {
  padding: 0 12px;
}

.dropdown .options .search-box .text-input {
  padding: 6px 0 16px;
  display: flex;
}

.dropdown .options .search-box .text-input input {
  border-radius: unset;
  border: none;
  flex-grow: 1;
  margin: 0;
  padding: 0 12px;
}

.dropdown .options .search-box .text-input input:focus {
  outline: none;
}

.dropdown .options .search-box .text-input input:disabled {
  background-color: unset !important;
}

.dropdown .options .search-box .text-input input::placeholder {
  color: #9597a7;
}

.dropdown .options .search-box .text-input input:-ms-input-placeholder {
  color: #9597a7;
}

.dropdown .options .search-box .text-input input::-moz-placeholder {
  color: #9597a7;
}

.dropdown .options .search-box .text-input .clear-icon {
  height: 24px;
  width: 24px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin: -14px 12px 0 0;
  display: flex;
}

.dropdown .options .search-box .text-input .clear-icon > img {
  height: 100%;
}

.dropdown .options .search-box .text-input input {
  color: #1c1d3e;
  text-indent: 8px;
  background-image: url("icon-search.7c9ce1f3.svg");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 16px;
}

.dropdown .options .search-box .text-input input::placeholder {
  color: #c6c7d1;
}

.dropdown .options .search-box .meta > .error {
  color: #7d7e92;
  padding: 12px 0 2px;
  font-size: 16px;
}

.dropdown .options > .list > .disabled {
  color: #adafbc;
}

.dropdown .options > .list .option {
  cursor: pointer;
  padding: 10px 16px;
}

@media (max-width: 767px) {
  .dropdown .overlay {
    z-index: 998;
    background: #000000b3;
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    inset: 0;
  }
}

@media (min-width: 768px) {
  .dropdown .options-wrapper {
    position: relative;
  }

  .dropdown .options {
    z-index: 999;
    margin-top: 10px;
    position: absolute;
    top: 100%;
  }
}

.dropdown .selected .icon {
  color: var(--message-success-text);
}

.dropdown .option {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.badge {
  border-radius: 4px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 400;
}

.badge.primary {
  color: var(--color-primary);
  background: #3b55f31f;
}

.badge.success {
  color: #0e9347;
  background: #00bf4b1f;
}

.badge.failure {
  background: var(--message-error-bg);
  color: var(--message-error-fg);
}

.badge.warning {
  background: var(--message-warning-bg);
  color: var(--message-warning-fg);
}

.form-select .input-box {
  background-color: var(--form-element-bg);
  border: 1px solid var(--form-element-border);
  box-sizing: border-box;
  color: var(--form-element-fg);
  width: 100%;
  cursor: pointer;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 10px 14px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  transition: all .3s;
  display: flex;
}

.form-select .input-box:after {
  content: "";
  height: 16px;
  width: 16px;
  background: url("chevron-down-icon.10250fc8.svg") no-repeat;
}

.form-select.disabled .input-box {
  color: #696974;
  cursor: auto;
  background-color: #f5f5f5;
  border-color: #e2e2ea;
}

.form-select .options {
  background-color: var(--card-bg);
  grid-gap: 18px;
  cursor: pointer;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 20px 25px 20px 18px;
  font-weight: 400;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

@media (max-width: 767px) {
  .form-select .options {
    max-width: 80vw;
    max-height: 80vh;
    overflow: auto;
  }
}

@media (min-width: 768px) {
  .form-select .options {
    max-height: 25vh;
    overflow-y: scroll;
  }
}

@media (max-width: 767px) {
  .form-select .overlay {
    z-index: 998;
    background: #000000b3;
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    inset: 0;
  }
}

@media (min-width: 768px) {
  .form-select .options-wrapper {
    position: relative;
  }

  .form-select .options {
    z-index: 999;
    margin-top: 10px;
    position: absolute;
    top: 100%;
  }
}

.form-select .icon {
  color: #777;
  margin-right: 12px;
  font-size: 30px;
}

.form-select .selected .icon {
  color: var(--message-success-text);
}

.form-select .option {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.page > .header, .page > .header .navigation {
  margin-bottom: 20px;
}

.page > .header .navigation .back-button {
  cursor: pointer;
  gap: 12px;
  font-size: 12px;
  display: flex;
}

.page > .header .navigation .back-button .previous-page {
  color: #7d7e92;
  text-decoration: underline;
}

.page > .header .navigation .back-button .active-page {
  color: #2563eb;
}

.page > .header .wrapper {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.page > .header .title {
  color: #1c1d3e;
  padding-left: 6px;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
}

.page > .header .actions {
  z-index: 2;
}

.page > .header .actions button {
  background-color: #fff;
  padding: 7px 14px;
}

.page > .header .actions button .icon {
  color: #92929d;
  font-size: 20px;
}

.page > .header .actions button .label {
  color: var(--color-primary);
}

.page > .header .actions a:link {
  text-decoration: none;
}

.page > .header > .note {
  letter-spacing: .001em;
  color: #7d7e92;
  background-image: url("info-filled-icon.83c669a1.svg");
  background-repeat: no-repeat;
  background-size: 20px;
  margin: 8px 0 0 4px;
  padding-left: 28px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  display: flex;
}

.optimized-image img {
  width: 100%;
  display: block;
}

#toasts-container {
  grid-gap: 15px;
  z-index: 1000;
  display: grid;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

#toasts-container .wrapper {
  text-align: center;
}

.toast {
  border-radius: 6px;
  grid-template-columns: min-content max-content min-content;
  align-items: center;
  padding: 10px 15px;
  display: inline-grid;
  box-shadow: 0 0 3px #687a9b40;
}

.toast .icon {
  margin-right: 10px;
  font-size: 30px;
}

.toast .message {
  max-width: 550px;
  margin: 0;
  font-size: .85rem;
  font-weight: 400;
}

.toast button.close {
  box-shadow: none;
  opacity: .5;
  color: #000;
  background-color: #0000;
  border: 0;
  margin-left: 10px;
  padding: 0;
  font-size: 1.4rem;
}

.toast.success {
  background-color: var(--message-success-bg);
  color: var(--message-success-text);
  border: 1px solid #10b981;
}

.toast.info {
  background-color: var(--message-info-bg);
  color: var(--message-info-text);
}

.toast.error {
  background-color: var(--message-error-bg);
  color: var(--message-error-text);
  border: 1px solid #f43f5e;
}

.toast.warning {
  background-color: var(--message-warning-bg);
  color: var(--message-warning-text);
}

.toast.loading {
  background-color: var(--message-loading-bg);
  color: var(--message-loading-text);
}

.toast.loading .spinner {
  width: 22px;
  height: 22px;
  margin-right: 8px;
  animation: 1s linear infinite spin;
}

@media (max-width: 767px) {
  .toast > .message {
    max-width: 300px;
  }
}

.time-list {
  grid-template-columns: repeat(4, min-content);
  display: grid;
  grid-gap: 9px !important;
}

.time-list .option {
  text-align: center;
  white-space: nowrap;
  padding: 4px;
}

.time-list .selected {
  background-color: var(--message-success-bg);
  color: var(--message-success-text);
  border-radius: 4px;
}

.time-list .disabled {
  color: var(--message-disabled-text);
  cursor: auto;
}

.duration-picker {
  grid-gap: 15px;
  grid-template-columns: 2fr 1fr 1fr;
  align-items: center;
  display: grid;
}

.eta-options {
  margin-top: 1rem;
}

.eta-item {
  white-space: nowrap;
}

.status-indicator {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.status-indicator i {
  color: #fff;
  font-size: 14px;
}

.status-indicator.primary {
  background-color: #50b5ff;
}

.status-indicator.success {
  background-color: #3dd598;
}

.status-indicator.failure {
  background-color: #fc5a5a;
}

.status-indicator.warning {
  background-color: #ff974a;
}

.status-indicator.completed {
  background-color: #3dd598;
}

.popout.arrow-right-bottom:before, .popout.arrow-right-center:before, .popout.arrow-right-top:before, .popout.arrow-down-right:before, .popout.arrow-down-center:before, .popout.arrow-down-left:before, .popout.arrow-left-bottom:before, .popout.arrow-left-center:before, .popout.arrow-left-top:before, .popout.arrow-up-right:before, .popout.arrow-up-center:before, .popout.arrow-up-left:before {
  content: "";
  position: absolute;
}

.popout.arrow-up-right:before, .popout.arrow-up-center:before, .popout.arrow-up-left:before {
  border-bottom: 12px solid #fff;
  border-left: 12px solid #0000;
  border-right: 12px solid #0000;
  top: -12px;
}

.popout.arrow-left-bottom:before, .popout.arrow-left-center:before, .popout.arrow-left-top:before {
  border-top: 12px solid #0000;
  border-bottom: 12px solid #0000;
  border-right: 12px solid #fff;
  left: -12px;
}

.popout.arrow-down-right:before, .popout.arrow-down-center:before, .popout.arrow-down-left:before {
  border-top: 12px solid #fff;
  border-left: 12px solid #0000;
  border-right: 12px solid #0000;
  bottom: -12px;
}

.popout.arrow-right-bottom:before, .popout.arrow-right-center:before, .popout.arrow-right-top:before {
  border-top: 12px solid #0000;
  border-bottom: 12px solid #0000;
  border-left: 12px solid #fff;
  right: -12px;
}

.popout.arrow-right-center:before, .popout.arrow-left-center:before {
  top: 50%;
  transform: translateY(-50%);
}

.popout.arrow-up-center:before, .popout.arrow-down-center:before {
  left: 50%;
  transform: translateX(-50%);
}

.popout.arrow-right-top:before, .popout.arrow-left-top:before {
  top: 12px;
}

.popout.arrow-right-bottom:before, .popout.arrow-left-bottom:before {
  bottom: 12px;
}

.popout.arrow-up-left:before, .popout.arrow-down-left:before {
  left: 12px;
}

.popout.arrow-up-right:before, .popout.arrow-down-right:before {
  right: 12px;
}

.popout {
  z-index: 99;
  background-color: #fff;
  border-radius: 10px;
  padding: 16px;
  position: absolute;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.popout.arrow-down-left, .popout.arrow-down-center, .popout.arrow-down-right {
  margin-top: -12px;
}

.popout.arrow-left-top, .popout.arrow-left-center, .popout.arrow-left-bottom {
  margin-left: 12px;
}

.popout.arrow-up-left, .popout.arrow-up-center, .popout.arrow-up-right {
  margin-top: 12px;
}

.popout.arrow-right-top, .popout.arrow-right-center, .popout.arrow-right-bottom {
  margin-left: -12px;
}

progress {
  height: 8px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
}

progress::-webkit-progress-bar {
  background: #fff;
  border-radius: 10px;
}

progress::-webkit-progress-bar, progress::-webkit-progress-value, progress::-moz-progress-bar {
  border-radius: 10px;
}

.form-single-select-overlay .options {
  background-color: var(--card-bg);
  grid-gap: 18px;
  cursor: pointer;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 20px 25px 20px 18px;
  font-weight: 400;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

@media (max-width: 767px) {
  .form-single-select-overlay .options {
    max-width: 80vw;
    max-height: 80vh;
    overflow: auto;
  }

  .form-single-select-overlay .overlay {
    z-index: 998;
    background: #0003;
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    inset: 0;
  }
}

@media (min-width: 768px) {
  .form-single-select-overlay .options-wrapper {
    position: relative;
  }

  .form-single-select-overlay .options {
    z-index: 999;
    position: absolute;
    top: 100%;
  }
}

.form-single-select-overlay .icon {
  color: #777;
  margin-right: 12px;
  font-size: 30px;
}

.form-single-select-overlay .selected .icon {
  color: var(--message-success-text);
}

.form-single-select-overlay .option {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.form-iso-date-picker-input-and-dropdown.invalid > .DayPickerInput input {
  border: 1px solid;
}

.form-iso-date-picker-input-and-dropdown > .DayPickerInput input {
  background-image: url("icon-calendar.60632f5f.svg");
  background-position: 95%;
  background-repeat: no-repeat;
  background-size: 16px;
  border-radius: 8px;
  padding: 10px 14px;
}

.form-iso-date-picker-input-and-dropdown > .DayPickerInput input:focus {
  outline: none;
}

.form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper {
  top: inherit;
  z-index: 9999;
}

@media (max-width: 767px) {
  .form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper {
    height: 100vh;
  }
}

@media (min-width: 768px) {
  .form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper {
    height: 100vh;
  }
}

@media (min-width: 1024px) {
  .form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper {
    height: unset;
  }
}

.form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay {
  box-shadow: none;
}

.form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker {
  margin-bottom: 4px;
  padding-top: 8px;
}

.form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper {
  -webkit-user-select: none;
  user-select: none;
  padding: 0;
  position: relative;
}

.form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper > .DayPicker-NavBar {
  position: absolute;
  top: -8px;
  right: 0;
}

.form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper > .DayPicker-NavBar > .DayPicker-NavButton {
  width: 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
}

.form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper > .DayPicker-NavBar > .DayPicker-NavButton--prev {
  background-image: url("icon-chevron-down-filled.997792e1.svg");
  transform: rotate(90deg);
}

.form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper > .DayPicker-NavBar > .DayPicker-NavButton--next {
  background-image: url("icon-chevron-down-filled.997792e1.svg");
  right: 10px;
  transform: rotate(270deg);
}

.form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month {
  table-layout: fixed;
  border-spacing: 14px 12px;
  border-collapse: separate;
  margin: 0;
}

.form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors {
  display: flex;
  position: absolute;
  top: 0;
}

.form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector {
  font-weight: 500;
}

.form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector.month-selector {
  min-width: 150px;
  margin-right: 12px;
}

.form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector.year-selector {
  min-width: 100px;
}

.form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .form-label {
  background-color: red;
}

.form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input {
  text-align: center;
  background-color: #0000;
  position: relative;
}

.form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .value {
  background-color: #f4f4f6;
  border-radius: 8px;
  padding: 4px 8px;
}

.form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .down-arrow-icon {
  display: none;
}

.form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .options {
  max-height: 280px;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  text-align: left;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  margin-top: 5px;
  font-weight: 400;
  overflow-y: scroll;
}

.form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .options::-webkit-scrollbar {
  width: 1px;
}

.form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .options::-webkit-scrollbar-track {
  background: none;
}

.form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .options::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .options .option {
  border-bottom: 1px solid #dee0e6;
  justify-content: space-between;
  padding: 10px;
  display: flex;
}

.form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .month-year-selectors .selector .drop-down-input .options .option:last-child {
  border-bottom: 0;
}

.form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .DayPicker-Weekday {
  padding-top: 40px;
}

.form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .DayPicker-Day {
  color: #1c1d3e;
  font-weight: 500;
}

.form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .DayPicker-Day:hover {
  background-color: unset !important;
}

.form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .DayPicker-Day--disabled {
  color: #dee0e6;
}

.form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker > .DayPicker-wrapper .DayPicker-Month .DayPicker-Day--selected {
  color: #3b82f6;
  background-color: #0000;
}

.form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker-Day:hover {
  background-color: unset !important;
}

.form-iso-date-picker-input-and-dropdown > .DayPickerInput > .DayPickerInput-OverlayWrapper > .DayPickerInput-Overlay > .DayPicker-Day--selected {
  background-color: #0000;
}

.radio {
  background-color: initial;
  -webkit-appearance: auto;
  appearance: auto;
  box-sizing: border-box;
  padding: initial;
  border: initial;
  margin: 3px 3px 0 5px;
}

.radio .radio-label {
  cursor: pointer;
  letter-spacing: .1px;
  font-size: 13px;
  display: flex;
}

.radio .radio-input {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background-clip: content-box;
  border: 2px solid #9597a7;
  border-radius: 50%;
  margin: 0 8px 0 0;
  padding: 3px;
}

.radio .radio-input:checked {
  background-color: #10b981;
  border-color: #10b981;
}

.removable-tag {
  color: #368875;
  width: max-content;
  background-color: #ecfdf5;
  border-radius: 6px;
  margin: 3px;
  padding: 4px 8px;
  font-weight: 500;
  display: flex;
}

.removable-tag .remove-button {
  cursor: pointer;
  padding-left: 6px;
}

.swal2-container .swal2-popup {
  width: 370px;
  border-radius: 20px;
  padding: 30px;
}

.swal2-container .swal2-header {
  padding: 0;
}

.swal2-container .swal2-header .swal2-title {
  color: #1c1d3e;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.swal2-container .swal2-content {
  color: #7d7e92;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.swal2-container .swal2-actions {
  flex-flow: column;
}

.swal2-container .swal2-actions > button {
  width: calc(100% - 30px);
  margin: 5px 15px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.swal2-container .swal2-actions > button.swal2-confirm.confirm-button {
  background-color: #2563eb !important;
}

.swal2-container .swal2-actions > button.swal2-cancel.cancel-button {
  color: #4c4e68;
  border: 1px solid #7d7e92;
  background-color: #fff !important;
}

.swal2-container .warning .swal2-actions > button.swal2-confirm.confirm-button {
  background-color: #9f1239 !important;
}

.tabs {
  height: 100%;
  border-bottom: 1px solid #dee0e6;
  gap: 12px;
  margin-bottom: 24px;
  display: flex;
  overflow: hidden;
}

@media (max-width: 767px) {
  .tabs {
    font-size: 14px;
    overflow-x: scroll;
  }
}

.tabs .tab {
  color: #4c4e68;
  text-wrap: nowrap;
  cursor: pointer;
  padding: 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.tabs .tab.active {
  color: #2563eb;
  border-bottom: 4px solid #2563eb;
  font-weight: 500;
}

@media (max-width: 767px) {
  .tabs .tab {
    padding: 8px;
    font-size: 12px;
  }
}

#auth-layout {
  width: 100vw;
  height: 100vh;
  grid-template-rows: min-content 1fr .1fr;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  display: grid;
}

#auth-layout .nav-bar {
  text-align: center;
  background-color: #fff;
  padding: 2px 10px;
  box-shadow: 0 0 3px #687a9b40;
}

#auth-layout .nav-bar img {
  height: 30px;
  margin-top: 10px;
}

#auth-layout .contents {
  justify-content: center;
  display: grid;
}

@media (min-width: 768px) {
  #auth-layout .auth-page {
    background-color: var(--card-bg);
    max-width: 700px;
    border: 1px solid #e2e2ea;
    border-radius: 10px;
    grid-template-columns: 1fr 1fr;
    margin: 100px auto;
    display: grid;
    overflow: hidden;
    box-shadow: 0 0 3px #687a9b40;
  }
}

#auth-layout .auth-page .auth-actions .spinner {
  text-align: center;
}

#auth-layout .auth-page .page {
  align-self: center;
  margin: 1.5rem;
}

#auth-layout .auth-page .page .links {
  white-space: pre;
  text-align: center;
  margin-top: 1.5rem;
}

#auth-layout .auth-page .page button {
  width: 100%;
}

#auth-layout .auth-page .page .action {
  text-align: center;
}

#firebaseui {
  padding: 20px;
}

@media (min-width: 768px) {
  #firebaseui {
    flex-direction: column;
    justify-content: center;
    display: flex;
  }
}

.firebaseui-idp-text {
  font-family: Poppins !important;
}

.firebaseui-idp-button {
  border-radius: 10px !important;
}

#login-widget, #signup-widget, #password-reset-widget, #auth-action-handler-widget {
  min-width: 200px;
  max-width: 350px;
  flex-direction: column;
  display: flex;
}

@media (max-width: 767px) {
  #login-widget, #signup-widget, #password-reset-widget, #auth-action-handler-widget {
    background-color: var(--card-bg);
    border: 1px solid #e2e2ea;
    border-radius: 10px;
    box-shadow: 0 0 3px #687a9b40;
  }
}

#login-widget .title, #signup-widget .title, #password-reset-widget .title, #auth-action-handler-widget .title {
  border-bottom: 1px solid #f1f1f5;
  padding: 12px 18px;
}

#login-widget form, #signup-widget form, #password-reset-widget form, #auth-action-handler-widget form {
  flex: 1;
  padding: 15px 18px;
}

#login-widget .footer, #signup-widget .footer, #password-reset-widget .footer, #auth-action-handler-widget .footer {
  background-color: #fafafb;
  border-top: 1px solid #f1f1f5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 15px 18px;
}

.mdl-shadow--2dp, .mdl-shadow--3dp, .mdl-shadow--4dp, .mdl-shadow--6dp, .mdl-shadow--8dp, .mdl-shadow--16dp, .mdl-shadow--24dp {
  box-shadow: none !important;
}

.backoffice {
  width: 100vw;
  height: 100vh;
  grid-template: "header header" min-content
                 "nav main" 1fr
                 / min-content 1fr;
  display: grid;
}

.backoffice header {
  width: 100vw;
  height: 53px;
  z-index: 1;
  background-color: #fff;
  grid-area: header;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  display: grid;
  overflow: hidden;
  box-shadow: 0 0 3px #687a9b40;
}

@media (max-width: 767px) {
  .backoffice header {
    grid-template-columns: 40px 1fr 40px;
  }
}

@media (min-width: 768px) {
  .backoffice header {
    grid-template-columns: 110px 1fr 110px;
  }
}

.backoffice header .logo {
  margin-left: 20px;
}

.backoffice header .logo img {
  width: 100%;
  display: block;
}

@media (max-width: 767px) {
  .backoffice header .logo.full {
    display: none;
  }
}

.backoffice header .logo.icon {
  color: #92929d;
  cursor: pointer;
  place-self: center start;
  font-size: 20px;
}

@media (min-width: 768px) {
  .backoffice header .logo.icon {
    display: none;
  }
}

.backoffice header .title {
  text-align: center;
  color: var(--fg);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.backoffice header .initials {
  color: #fff;
  background-color: #ffc542;
  border: 3px solid #f1f1f5;
  border-radius: 10px;
  justify-self: end;
  margin-right: 20px;
  padding: 3px 4px;
  font-weight: 500;
}

.backoffice header .userinfo {
  display: none;
}

.backoffice header .restaurant {
  flex-direction: horizontal;
  justify-content: center;
  align-items: center;
  display: flex;
}

.backoffice header .restaurant .image {
  width: 33px;
  border: 3px solid #f1f1f5;
  border-radius: 10px;
  margin-right: 10px;
  overflow: hidden;
}

.backoffice header .restaurant .name {
  letter-spacing: .1px;
  color: var(--fg);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.backoffice header .restaurant .description, .backoffice header .restaurant .timezone, .backoffice header .restaurant .country {
  display: none;
}

.backoffice nav {
  z-index: 999;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  background-color: #fff;
  flex-direction: column;
  grid-area: nav;
  display: flex;
  overflow-y: scroll;
  box-shadow: 0 0 3px #687a9b40;
}

.backoffice nav::-webkit-scrollbar {
  width: 1px;
}

.backoffice nav::-webkit-scrollbar-track {
  background: none;
}

.backoffice nav::-webkit-scrollbar-thumb {
  background-color: #0000;
}

@media (max-width: 767px) {
  .backoffice nav {
    height: calc(100vh - 53px);
    transition: transform .3s;
    position: fixed;
    top: 53px;
    left: 0;
    transform: translateX(-105%);
  }

  .backoffice nav.show {
    transform: none;
  }
}

.backoffice nav .title {
  text-transform: uppercase;
  color: #92929d;
  letter-spacing: 1px;
  margin-top: 20px;
  margin-bottom: 5px;
  margin-left: 20px;
  font-size: 11.5px;
  font-weight: 400;
}

.backoffice nav .link {
  border-left: 4px solid var(--card-bg);
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;
  padding: 8px 40px 8px 16px;
  display: flex;
}

.backoffice nav .link.selected .icon.kds {
  width: 21px;
  height: 21px;
  background-image: url("icon-kds-blue.cf7662dc.svg");
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
}

.backoffice nav .link .icon {
  color: #92929d;
  margin-right: 16px;
  font-size: 21px;
}

.backoffice nav .link .icon.kds {
  width: 21px;
  height: 21px;
  background-image: url("icon-kds-grey.3e75b6f3.svg");
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
}

.backoffice nav .link .label {
  letter-spacing: .1px;
  color: var(--fg);
  white-space: nowrap;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: inline-block;
}

.backoffice nav .link.selected {
  border-left: 4px solid var(--color-primary);
}

.backoffice nav .link.selected .icon, .backoffice nav .link.selected .label {
  color: var(--color-primary);
}

.backoffice main {
  grid-area: main;
  padding: 20px;
  overflow-y: scroll;
}

.website {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  grid-template: "header header" min-content
                 "nav main" 1fr
                 "footer footer" min-content
                 / min-content 1fr;
  display: grid;
  overflow-y: hidden;
}

.website header {
  width: 100vw;
  height: 53px;
  z-index: 1;
  background-color: #fff;
  grid-area: header;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  display: grid;
  overflow: hidden;
  box-shadow: 0 0 3px #687a9b40;
}

@media (max-width: 767px) {
  .website header {
    grid-template-columns: 40px 1fr 40px;
  }
}

@media (min-width: 768px) {
  .website header {
    grid-template-columns: 110px 1fr 110px;
  }
}

.website header .logo {
  margin-left: 20px;
}

.website header .logo img {
  width: 100%;
  display: block;
}

@media (max-width: 767px) {
  .website header .logo.full {
    display: none;
  }
}

.website header .logo.icon {
  color: #92929d;
  cursor: pointer;
  place-self: center start;
  font-size: 20px;
}

@media (min-width: 768px) {
  .website header .logo.icon {
    display: none;
  }
}

.website header .title {
  text-align: center;
  color: var(--fg);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.website header .initials {
  color: #fff;
  background-color: #ffc542;
  border: 3px solid #f1f1f5;
  border-radius: 10px;
  justify-self: end;
  margin-right: 20px;
  padding: 3px 4px;
  font-weight: 500;
}

.website header .userinfo {
  display: none;
}

.website header .restaurant {
  flex-direction: horizontal;
  justify-content: center;
  align-items: center;
  display: flex;
}

.website header .restaurant .image {
  width: 33px;
  border: 3px solid #f1f1f5;
  border-radius: 10px;
  margin-right: 10px;
  overflow: hidden;
}

.website header .restaurant .name {
  letter-spacing: .1px;
  color: var(--fg);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.website header .restaurant .description, .website header .restaurant .timezone, .website header .restaurant .country {
  display: none;
}

.website nav {
  z-index: 999;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  background-color: #fff;
  flex-direction: column;
  grid-area: nav;
  display: flex;
  overflow-y: scroll;
  box-shadow: 0 0 3px #687a9b40;
}

.website nav::-webkit-scrollbar {
  width: 1px;
}

.website nav::-webkit-scrollbar-track {
  background: none;
}

.website nav::-webkit-scrollbar-thumb {
  background-color: #0000;
}

@media (max-width: 767px) {
  .website nav {
    height: calc(100vh - 53px);
    transition: transform .3s;
    position: fixed;
    top: 53px;
    left: 0;
    transform: translateX(-105%);
  }

  .website nav.show {
    transform: none;
  }
}

.website nav .title {
  text-transform: uppercase;
  color: #92929d;
  letter-spacing: 1px;
  margin-top: 20px;
  margin-bottom: 5px;
  margin-left: 20px;
  font-size: 11.5px;
  font-weight: 400;
}

.website nav .link {
  border-left: 4px solid var(--card-bg);
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;
  padding: 8px 40px 8px 16px;
  display: flex;
}

.website nav .link .icon {
  color: #92929d;
  margin-right: 16px;
  font-size: 21px;
}

.website nav .link .label {
  letter-spacing: .1px;
  color: var(--fg);
  white-space: nowrap;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: inline-block;
}

.website nav .link.selected {
  border-left: 4px solid var(--color-primary);
}

.website nav .link.selected .icon, .website nav .link.selected .label {
  color: var(--color-primary);
}

.website main {
  grid-area: main;
  padding: 20px;
  overflow-y: scroll;
}

.website .website-bottom-nav-bar {
  grid-area: footer;
}

@media (max-width: 767px) {
  #intro-widget {
    padding: 10px 25px;
  }

  #intro-widget #logo {
    display: none;
  }
}

@media (min-width: 768px) {
  #intro-widget {
    background-color: #fff;
    border-right: 1px solid #eee;
    padding: 25px;
    display: grid;
  }

  #intro-widget #logo img {
    height: 25px;
    margin-bottom: 20px;
  }
}

#intro-widget .graphic {
  text-align: center;
}

#intro-widget .graphic img {
  width: 70%;
  margin-bottom: 10px;
}

#intro-widget .marketing-message {
  letter-spacing: .1px;
  color: var(--fg);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.store-select-widget .list {
  grid-gap: 20px;
  display: grid;
}

.store-select-widget .item {
  background-color: var(--card-bg);
  cursor: pointer;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-columns: min-content 1fr;
  grid-template-areas: "a b"
                       "a c";
  align-items: center;
  padding: 15px 18px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.store-select-widget .item .icon {
  margin-top: 1px;
  margin-right: 7px;
}

.store-select-widget .item .icon i {
  background-color: var(--color-secondary);
  color: #fff;
  border-radius: 50%;
  grid-area: a;
  padding: 5px;
}

.store-select-widget .item .name {
  letter-spacing: .1px;
  color: var(--fg);
  grid-area: b;
  margin-bottom: 2px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.store-select-widget .item .address {
  letter-spacing: .1px;
  color: #666;
  grid-area: c;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
}

.restaurant-website-info {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.restaurant-website-info form {
  margin-top: 20px;
  padding: 15px 18px;
}

.restaurant-website-info .form-item {
  margin-top: .5rem;
}

.restaurant-website-info .list {
  margin-top: 1rem;
}

.restaurant-website-info .card {
  background-color: var(--card-bg);
  grid-gap: 18px;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 20px 15px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.restaurant-website-info .card .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.restaurant-website-info .card .switch label {
  grid-area: a;
  align-self: center;
}

.restaurant-website-info .card .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.restaurant-website-info .card .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.restaurant-website-info .card .switch label {
  text-align: left !important;
}

.restaurant-website-info .card .actions {
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  margin-top: -20px;
  margin-bottom: 0;
  display: grid;
}

.restaurant-website-info .card .actions .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.restaurant-website-info .card .actions .action img {
  width: 26px;
  margin-right: 5px;
}

.restaurant-website-info .btn-gallery-add {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.restaurant-website-info .hero-form-item .banner-input {
  margin-bottom: 20px;
}

.restaurant-website-info .form-input .photo, .restaurant-website-info .form-input .hero {
  background-color: var(--card-bg);
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  margin-bottom: 20px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.restaurant-website-info .form-input .photo .item, .restaurant-website-info .form-input .hero .item {
  grid-gap: 18px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 15px;
  display: grid;
  box-shadow: 0 2px #c4cbd840;
}

.restaurant-website-info .form-input .photo .item .switch, .restaurant-website-info .form-input .hero .item .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.restaurant-website-info .form-input .photo .item .switch label, .restaurant-website-info .form-input .hero .item .switch label {
  grid-area: a;
  align-self: center;
}

.restaurant-website-info .form-input .photo .item .switch .form-input, .restaurant-website-info .form-input .hero .item .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.restaurant-website-info .form-input .photo .item .switch .meta, .restaurant-website-info .form-input .hero .item .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.restaurant-website-info .form-input .photo .item .switch label, .restaurant-website-info .form-input .hero .item .switch label {
  text-align: left !important;
}

.restaurant-website-info .form-input .photo .actions, .restaurant-website-info .form-input .hero .actions {
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  padding: 5px 12px;
  display: grid;
}

.restaurant-website-info .form-input .photo .actions .action, .restaurant-website-info .form-input .hero .actions .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.restaurant-website-info .form-input .photo .actions .action img, .restaurant-website-info .form-input .hero .actions .action img {
  width: 26px;
  margin-right: 5px;
}

.restaurant-website-info .stores-form-item {
  margin-top: -20px;
}

.restaurant-website-info .stores-form-item .store-select-card {
  max-width: 35vw;
  border: none;
  grid-template-columns: 1fr min-content;
  margin-top: 14px;
  display: grid;
}

.restaurant-website-info .stores-form-item .store-select-card .action {
  height: 100%;
  grid-template-rows: 14px 14px min-content;
  display: grid;
}

.restaurant-website-info .stores-form-item .store-select-card .action .icon {
  cursor: pointer;
  grid-row-start: 3;
  margin-top: 20px;
  margin-left: 20px;
  transform: scale(2);
}

.restaurant-website-info .stores-form-item .add-store-button {
  margin-top: 20px;
}

@media (max-width: 767px) {
  .restaurant-website-info .stores-form-item .store-select-card {
    max-width: unset;
  }
}

.pizza-edit-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.pizza-edit-widget form {
  margin-top: 20px;
  padding: 15px 18px;
}

.pizza-edit-widget form .allergen-info > .form-input {
  grid-row-gap: 20px;
  display: grid;
}

.pizza-edit-widget form .allergen-info .form-item {
  display: flex;
}

.pizza-edit-widget form .allergen-info .form-input {
  width: 100%;
}

.pizza-edit-widget form .allergen-info label {
  background-color: var(--form-element-bg);
  border: 1px solid var(--form-element-border);
  box-sizing: border-box;
  color: var(--form-element-fg);
  height: -moz-fit-content;
  height: fit-content;
  width: 40%;
  border-radius: 10px;
  margin-right: 10px;
  padding: 10px 14px;
}

.pizza-edit-widget .pizza-sizes-form-item > label {
  margin-top: 12px;
  align-self: flex-start !important;
}

.pizza-edit-widget .sizes {
  grid-row-gap: 20px;
  display: grid;
}

.pizza-edit-widget .size {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.pizza-edit-widget .size .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.pizza-edit-widget .size .switch label {
  grid-area: a;
  align-self: center;
}

.pizza-edit-widget .size .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.pizza-edit-widget .size .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.pizza-edit-widget .size .name {
  border-bottom: 1px solid #f1f1f5;
  padding: 12px 18px;
}

.pizza-edit-widget .size .spec {
  grid-gap: 18px;
  padding: 15px 18px;
  display: grid;
}

.pizza-edit-widget .size .spec .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.pizza-edit-widget .size .spec .switch label {
  grid-area: a;
  align-self: center;
}

.pizza-edit-widget .size .spec .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.pizza-edit-widget .size .spec .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.pizza-edit-widget .size .spec .switch label {
  text-align: left !important;
}

.pizza-edit-widget .remove-toppings-form-item > label {
  margin-top: 12px;
  align-self: flex-start !important;
}

.pizza-edit-widget .remove-toppings-form-item .list {
  background-color: var(--card-bg);
  grid-gap: 20px;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  margin-bottom: 25px;
  padding: 15px 18px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.pizza-edit-widget .remove-toppings-form-item .list .item {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.pizza-edit-widget .remove-toppings-form-item .list .item input {
  margin-right: 20px;
}

.pizza-edit-widget .remove-toppings-form-item .list .item .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.pizza-edit-widget .remove-toppings-form-item .list .item .action img {
  width: 26px;
  margin-right: 5px;
}

.pizza-edit-widget .remove-toppings-form-item .actions {
  margin-top: 4px;
}

.pizza-edit-widget .remove-toppings-form-item .actions button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  background-color: #0000;
}

.swal2-container .pizza-spec-edit-widget {
  max-width: 364px;
  border-radius: 20px;
  padding: 32px 24px;
}

.swal2-container .pizza-spec-edit-widget .swal2-title {
  color: #1c1d3e;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.swal2-container .pizza-spec-edit-widget .swal2-content {
  color: #7d7e92;
  margin-top: 16px;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.swal2-container .pizza-spec-edit-widget .swal2-actions {
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
  padding: 0 24px;
}

.swal2-container .pizza-spec-edit-widget .swal2-actions button {
  border-radius: 4px;
  margin: 0;
  padding: 14px 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.swal2-container .pizza-spec-edit-widget .swal2-actions .confirm-button {
  width: 100%;
  color: #fff;
  background-color: #2563eb;
}

.swal2-container .pizza-spec-edit-widget .swal2-actions .cancel-button {
  width: 100%;
  color: #4c4e68;
  border: 1px solid #7d7e92;
  background-color: #fff !important;
}

.deal-edit-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.deal-edit-widget form {
  margin-top: 20px;
  padding: 15px 18px;
}

.deal-edit-widget form .allergen-info > .form-input {
  grid-row-gap: 20px;
  display: grid;
}

.deal-edit-widget form .allergen-info .form-item {
  display: flex;
}

.deal-edit-widget form .allergen-info .form-input {
  width: 100%;
}

.deal-edit-widget form .allergen-info label {
  background-color: var(--form-element-bg);
  border: 1px solid var(--form-element-border);
  box-sizing: border-box;
  color: var(--form-element-fg);
  height: -moz-fit-content;
  height: fit-content;
  width: 40%;
  border-radius: 10px;
  margin-right: 10px;
  padding: 10px 14px;
}

.deal-edit-widget .deal-pizzas-form-item > label, .deal-edit-widget .deal-items-form-item > label {
  margin-top: 12px;
  align-self: flex-start !important;
}

.deal-edit-widget .deal-items .list, .deal-edit-widget .deal-pizzas .list {
  grid-gap: 20px;
  margin-bottom: 20px;
  display: grid;
}

.deal-edit-widget .deal-items .item, .deal-edit-widget .deal-pizzas .item {
  background-color: var(--card-bg);
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.deal-edit-widget .deal-items .item .content, .deal-edit-widget .deal-pizzas .item .content {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px #c4cbd840;
}

.deal-edit-widget .deal-items .item .actions, .deal-edit-widget .deal-pizzas .item .actions {
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  padding: 5px 12px;
  display: grid;
}

.deal-edit-widget .deal-items .item .actions .action, .deal-edit-widget .deal-pizzas .item .actions .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.deal-edit-widget .deal-items .item .actions .action img, .deal-edit-widget .deal-pizzas .item .actions .action img {
  width: 26px;
  margin-right: 5px;
}

.deal-edit-widget .deal-items .item .info, .deal-edit-widget .deal-pizzas .item .info {
  grid-gap: 20px;
  border-bottom: 1px solid #f1f1f5;
  grid-template-columns: 1fr 100px;
  padding: 12px 18px;
  display: grid;
}

.deal-edit-widget .deal-items .item .info label, .deal-edit-widget .deal-items .item .info .meta, .deal-edit-widget .deal-pizzas .item .info label, .deal-edit-widget .deal-pizzas .item .info .meta {
  font-weight: normal;
}

.deal-edit-widget .deal-items .actions button, .deal-edit-widget .deal-pizzas .actions button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  background-color: #0000;
}

.deal-edit-widget .pizza-list .head {
  background-color: #f9f9f9;
  border-bottom: 1px solid #f1f1f5;
  padding: 12px 18px;
}

.deal-edit-widget .pizza-list .body {
  grid-gap: 20px;
  padding: 15px 18px;
  display: grid;
}

.deal-edit-widget .pizza-list .head, .deal-edit-widget .pizza {
  grid-gap: 15px;
  grid-template-columns: min-content 1fr 100px;
  align-items: center;
  display: grid;
}

.deal-edit-widget .deal-items .form-item.modifiers > label {
  background-color: #f9f9f9;
  border-bottom: 1px solid #f1f1f5;
  margin: 0;
  padding: 12px 18px;
  display: block;
}

.deal-edit-widget .deal-items .modifier, .deal-edit-widget .deal-items .modifier-info {
  box-shadow: none !important;
  border-radius: 0 !important;
}

.deal-edit-widget .deal-items .modifier {
  margin-bottom: 0 !important;
}

.deal-edit-widget .deal-items .modifier-list-actions {
  margin: 15px;
}

.deal-edit-widget .deal-items .modifier-list-actions button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  background-color: #0000;
}

.deal-edit-widget .deal-items .form-item.modifiers > .meta {
  display: none !important;
}

.swal2-container .deal-spec-edit-widget {
  max-width: 364px;
  border-radius: 20px;
  padding: 32px 24px;
}

.swal2-container .deal-spec-edit-widget .swal2-title {
  color: #1c1d3e;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.swal2-container .deal-spec-edit-widget .swal2-content {
  color: #7d7e92;
  margin-top: 16px;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.swal2-container .deal-spec-edit-widget .swal2-actions {
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
  padding: 0 24px;
}

.swal2-container .deal-spec-edit-widget .swal2-actions button {
  border-radius: 4px;
  margin: 0;
  padding: 14px 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.swal2-container .deal-spec-edit-widget .swal2-actions .confirm-button {
  width: 100%;
  color: #fff;
  background-color: #2563eb;
}

.swal2-container .deal-spec-edit-widget .swal2-actions .cancel-button {
  width: 100%;
  color: #4c4e68;
  border: 1px solid #7d7e92;
  background-color: #fff !important;
}

.form-item-price-spec > label {
  margin-top: 34px;
  align-self: flex-start !important;
}

.form-item-price-spec > .form-input {
  background-color: var(--card-bg);
  grid-gap: 18px;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 20px 15px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.form-item-price-spec > .form-input .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.form-item-price-spec > .form-input .switch label {
  grid-area: a;
  align-self: center;
}

.form-item-price-spec > .form-input .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.form-item-price-spec > .form-input .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.form-item-price-spec > .form-input .switch label {
  text-align: left !important;
}

.price-spec-edit-widget {
  grid-gap: 18px;
  padding: 0;
  display: grid;
}

.price-spec-edit-widget .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.price-spec-edit-widget .switch label {
  grid-area: a;
  align-self: center;
}

.price-spec-edit-widget .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.price-spec-edit-widget .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.price-spec-edit-widget .switch label {
  text-align: left !important;
}

.price-spec-edit-widget .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.price-spec-edit-widget .switch label {
  grid-area: a;
  align-self: center;
}

.price-spec-edit-widget .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.price-spec-edit-widget .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.price-spec-edit-widget .base-price, .price-spec-edit-widget .extra-price {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.price-spec-edit-widget .base-price input, .price-spec-edit-widget .extra-price input {
  max-width: 80px;
}

.price-spec-edit-widget .extra-price.form-item {
  margin-right: 15px;
}

.price-spec-edit-widget .extra {
  grid-template-columns: 4fr 1fr;
  align-items: center;
  margin-bottom: 15px;
  display: grid;
}

.restaurant-browse-page .ais-Hits-list, .restaurant-list {
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  padding: 0;
  list-style: none;
  display: grid;
}

.restaurant-browse-page .ais-Hits-list .restaurant, .restaurant-list .restaurant {
  background-color: var(--card-bg);
  cursor: pointer;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  flex-direction: column;
  display: flex;
  overflow: hidden;
  box-shadow: 0 0 3px #687a9b40;
}

.restaurant-browse-page .ais-Hits-list .restaurant .name, .restaurant-list .restaurant .name {
  letter-spacing: .1px;
  color: var(--fg);
  padding: 10px 10px 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.restaurant-browse-page .ais-Hits-list .restaurant .timezone, .restaurant-list .restaurant .timezone {
  color: #92929d;
  padding: 0 10px 10px;
  font-size: 12px;
}

.restaurant-browse-page .ais-Hits-list .restaurant .description, .restaurant-browse-page .ais-Hits-list .restaurant .country, .restaurant-list .restaurant .description, .restaurant-list .restaurant .country {
  display: none;
}

.restaurant-browse-page .ais-Hits-list .restaurant.unauthorized, .restaurant-list .restaurant.unauthorized {
  cursor: not-allowed;
}

.store-card-view {
  background-color: var(--card-bg);
  cursor: pointer;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  flex-direction: row;
  padding: 20px;
  display: flex;
  box-shadow: 0 0 3px #687a9b40;
}

.store-card-view .name {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.store-card-view .image {
  width: 60px;
  border-radius: 10px;
  margin-right: 22px;
  overflow: hidden;
}

.store-card-view .restaurant-name {
  letter-spacing: .1px;
  color: #8d92a3;
  text-transform: uppercase;
  margin-bottom: -1px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.store-card-view .store-name {
  color: var(--fg);
  color: #4c4c77;
  margin-top: -1px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.store-list {
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  display: grid;
}

.restaurant-header-widget, .store-header-widget {
  flex-direction: horizontal;
  justify-content: center;
  align-items: center;
  display: flex;
}

.restaurant-header-widget .image, .store-header-widget .image {
  width: 33px;
  border: 3px solid #f1f1f5;
  border-radius: 10px;
  margin-right: 10px;
  overflow: hidden;
}

.restaurant-header-widget .name, .store-header-widget .name {
  letter-spacing: .1px;
  color: var(--fg);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.form-input .modifier-list .modifier {
  background-color: var(--card-bg);
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  margin-bottom: 20px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.form-input .modifier-list .modifier .modifier-info {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px #c4cbd840;
}

.form-input .modifier-list .modifier .modifier-info .header {
  grid-column-gap: 10px;
  grid-template-columns: 1fr 60px 60px;
  padding: 15px;
  display: grid;
}

.form-input .modifier-list .modifier .modifier-info > .meta {
  border-bottom: 1px solid #f1f1f5;
  margin: 0;
  padding: 0 15px 15px;
}

.form-input .modifier-list .modifier .modifier-info > .badges {
  flex-direction: row;
  margin-bottom: 12px;
  margin-left: 15px;
  display: flex;
}

.form-input .modifier-list .modifier .modifier-info > .badges .badge {
  margin-right: 8px;
  display: inline;
}

.form-input .modifier-list .modifier .modifier-info .allow-multiple {
  border-bottom: 1px solid #f1f1f5;
  padding: 15px 0;
}

.form-input .modifier-list .modifier .modifier-info .allow-multiple .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  padding: 0 15px;
  display: grid;
}

.form-input .modifier-list .modifier .modifier-info .allow-multiple .switch label {
  grid-area: a;
  align-self: center;
}

.form-input .modifier-list .modifier .modifier-info .allow-multiple .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.form-input .modifier-list .modifier .modifier-info .allow-multiple .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.form-input .modifier-list .modifier .modifier-actions {
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  padding: 5px 12px;
  display: grid;
}

.form-input .modifier-list .modifier .modifier-actions .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.form-input .modifier-list .modifier .modifier-actions .action img {
  width: 26px;
  margin-right: 5px;
}

.form-input .modifier-options .head {
  padding: 15px 15px 4px;
  font-size: 13px;
}

.form-input .modifier-options > .meta {
  margin: 0;
  padding: 0 15px 15px;
}

.form-input .modifier-option {
  grid-column-gap: 10px;
  grid-template-columns: 1fr 120px min-content;
  align-items: center;
  margin-bottom: 15px;
  padding: 0 15px;
  display: grid;
}

.form-input .modifier-option .actions {
  grid-template-columns: repeat(3, min-content);
  display: grid;
}

.form-input .modifier-option .action {
  color: #999;
  cursor: pointer;
  font-size: 25px;
}

.form-input .modifier-option .action img {
  width: 26px;
  margin-right: 5px;
}

.form-input .modifier-list-actions button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  background-color: #0000;
}

.form-item.modifiers > label {
  margin-top: 12px;
  align-self: flex-start !important;
}

.pizza-settings-edit-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.pizza-settings-edit-widget form {
  margin-top: 20px;
  padding: 15px 18px;
}

.pizza-settings-edit-widget .form-input .topping, .pizza-settings-edit-widget .form-input .pizza-size {
  background-color: var(--card-bg);
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  margin-bottom: 20px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.pizza-settings-edit-widget .form-input .topping .info, .pizza-settings-edit-widget .form-input .pizza-size .info {
  grid-gap: 18px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 15px;
  display: grid;
  box-shadow: 0 2px #c4cbd840;
}

.pizza-settings-edit-widget .form-input .topping .info .switch, .pizza-settings-edit-widget .form-input .pizza-size .info .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.pizza-settings-edit-widget .form-input .topping .info .switch label, .pizza-settings-edit-widget .form-input .pizza-size .info .switch label {
  grid-area: a;
  align-self: center;
}

.pizza-settings-edit-widget .form-input .topping .info .switch .form-input, .pizza-settings-edit-widget .form-input .pizza-size .info .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.pizza-settings-edit-widget .form-input .topping .info .switch .meta, .pizza-settings-edit-widget .form-input .pizza-size .info .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.pizza-settings-edit-widget .form-input .topping .info .switch label, .pizza-settings-edit-widget .form-input .pizza-size .info .switch label {
  text-align: left !important;
}

.pizza-settings-edit-widget .form-input .topping .actions, .pizza-settings-edit-widget .form-input .pizza-size .actions {
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  padding: 5px 12px;
  display: grid;
}

.pizza-settings-edit-widget .form-input .topping .actions .action, .pizza-settings-edit-widget .form-input .pizza-size .actions .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.pizza-settings-edit-widget .form-input .topping .actions .action img, .pizza-settings-edit-widget .form-input .pizza-size .actions .action img {
  width: 26px;
  margin-right: 5px;
}

@media (min-width: 1024px) {
  .pizza-settings-edit-widget .form-input .topping .info {
    grid-auto-flow: column;
  }
}

.pizza-settings-edit-widget .form-input .toppings button, .pizza-settings-edit-widget .form-input .pizza-sizes button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  background-color: #0000;
}

.pizza-settings-edit-widget .pizza-sizes-form-item > label, .pizza-settings-edit-widget .toppings-list-form-item > label {
  margin-top: 12px;
  align-self: flex-start !important;
}

.swal2-container .pizza-settings-edit-widget {
  max-width: 364px;
  border-radius: 20px;
  padding: 32px 24px;
}

.swal2-container .pizza-settings-edit-widget .swal2-title {
  color: #1c1d3e;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.swal2-container .pizza-settings-edit-widget .swal2-content {
  color: #7d7e92;
  margin-top: 16px;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.swal2-container .pizza-settings-edit-widget .swal2-actions {
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
  padding: 0 24px;
}

.swal2-container .pizza-settings-edit-widget .swal2-actions button {
  border-radius: 4px;
  margin: 0;
  padding: 14px 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.swal2-container .pizza-settings-edit-widget .swal2-actions .confirm-button {
  width: 100%;
  color: #fff;
  background-color: #2563eb;
}

.swal2-container .pizza-settings-edit-widget .swal2-actions .cancel-button {
  width: 100%;
  color: #4c4e68;
  border: 1px solid #7d7e92;
  background-color: #fff !important;
}

.item-spec-list {
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  display: grid;
}

.item-spec {
  background-color: var(--card-bg);
  cursor: pointer;
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  display: grid;
  overflow: hidden;
  box-shadow: 0 0 3px #687a9b40;
}

.item-spec.accent {
  grid-template-rows: min-content 1fr min-content;
}

.item-spec .body {
  background-color: #fff;
  border-radius: 10px;
  grid-template: "name image" min-content
                 "description nothing" 1fr
                 "price order-system" min-content
                 / 1fr min-content;
  padding: 12px;
  display: grid;
  box-shadow: 0 2px #c4cbd840;
}

.item-spec > .accent {
  height: 6px;
  width: 100%;
  display: inline-block;
}

.item-spec .image {
  width: 100px;
  grid-area: image;
  margin-left: 10px;
  overflow: hidden;
}

.item-spec .image img {
  border-radius: 10px;
}

.item-spec .name {
  color: var(--fg);
  grid-area: name;
  align-self: flex-start;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.item-spec .description {
  letter-spacing: .1px;
  color: #8d92a3;
  grid-area: description;
  align-self: flex-start;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.item-spec .price {
  color: var(--fg);
  color: var(--color-secondary);
  grid-area: price;
  align-self: flex-end;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.item-spec .not-available {
  letter-spacing: .1px;
  color: #8d92a3;
  align-self: flex-start;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.item-spec .online-only, .item-spec .pos-only, .item-spec .uber-eats-only {
  grid-area: order-system;
  align-self: flex-start;
  padding-left: 5px;
  font-size: 13px;
}

.item-spec .actions {
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  padding: 5px 12px;
  display: grid;
}

.item-spec .actions .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.item-spec .actions .action img {
  width: 26px;
  margin-right: 5px;
}

.backoffice .category-list {
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  display: grid;
}

.backoffice .category {
  background-color: var(--card-bg);
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  display: grid;
  overflow: hidden;
  box-shadow: 0 0 3px #687a9b40;
}

.backoffice .category.accent {
  grid-template-rows: min-content 1fr min-content;
}

.backoffice .category > .accent {
  height: 6px;
  width: 100%;
}

.backoffice .category .body {
  cursor: pointer;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px #c4cbd840;
}

.backoffice .category .name {
  color: var(--fg);
  padding-left: 6px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.backoffice .category .description {
  letter-spacing: .1px;
  color: #8d92a3;
  padding-left: 6px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.backoffice .category .online-only, .backoffice .category .pos-only, .backoffice .category .uber-eats-only {
  padding-left: 6px;
}

.backoffice .category .actions {
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  padding: 5px 12px;
  display: grid;
}

.backoffice .category .actions .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.backoffice .category .actions .action img {
  width: 26px;
  margin-right: 5px;
}

.order-view-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: 0 0 3px #687a9b40;
}

.order-view-widget .comments, .order-view-widget .dropoff-notes, .order-view-widget .collection-type, .order-view-widget .collection-info, .order-view-widget .delivery-address, .order-view-widget .payment-method, .order-view-widget .payment, .order-view-widget .customer-info, .order-view-widget .transaction-fee-info, .order-view-widget .items {
  border-bottom: 1px solid #f1f1f5;
}

.order-view-widget .comments .title, .order-view-widget .dropoff-notes .title, .order-view-widget .collection-type .title, .order-view-widget .collection-info .title, .order-view-widget .delivery-address .title, .order-view-widget .payment-method .title, .order-view-widget .payment .title, .order-view-widget .customer-info .title, .order-view-widget .transaction-fee-info .title, .order-view-widget .items .title {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.order-view-widget .comments .title .icon, .order-view-widget .dropoff-notes .title .icon, .order-view-widget .collection-type .title .icon, .order-view-widget .collection-info .title .icon, .order-view-widget .delivery-address .title .icon, .order-view-widget .payment-method .title .icon, .order-view-widget .payment .title .icon, .order-view-widget .customer-info .title .icon, .order-view-widget .transaction-fee-info .title .icon, .order-view-widget .items .title .icon {
  margin-right: 12px;
  font-size: 24px;
}

.order-view-widget .comments .title label, .order-view-widget .dropoff-notes .title label, .order-view-widget .collection-type .title label, .order-view-widget .collection-info .title label, .order-view-widget .delivery-address .title label, .order-view-widget .payment-method .title label, .order-view-widget .payment .title label, .order-view-widget .customer-info .title label, .order-view-widget .transaction-fee-info .title label, .order-view-widget .items .title label {
  letter-spacing: .7px;
  text-transform: uppercase;
  color: #293340;
  font-family: Fira Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
}

.order-view-widget .collection-type, .order-view-widget .collection-info, .order-view-widget .payment-method, .order-view-widget .payment-status, .order-view-widget .payment-summary-header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  display: flex;
}

.order-view-widget .delivery-address, .order-view-widget .comments, .order-view-widget .dropoff-notes {
  grid-row-gap: 8px;
  grid-template-columns: min-content 1fr;
  grid-template-areas: "a b"
                       "c d";
  padding: 16px 20px;
  display: grid;
}

.order-view-widget .delivery-address .icon, .order-view-widget .comments .icon, .order-view-widget .dropoff-notes .icon {
  margin-right: 12px;
  font-size: 24px;
}

.order-view-widget .delivery-address label, .order-view-widget .comments label, .order-view-widget .dropoff-notes label {
  letter-spacing: .7px;
  text-transform: uppercase;
  color: #293340;
  font-family: Fira Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
}

.order-view-widget .delivery-address i, .order-view-widget .comments i, .order-view-widget .dropoff-notes i {
  grid-area: a;
}

.order-view-widget .delivery-address label, .order-view-widget .comments label, .order-view-widget .dropoff-notes label {
  grid-area: b;
  align-self: center;
}

.order-view-widget .delivery-address .value, .order-view-widget .comments .value, .order-view-widget .dropoff-notes .value {
  grid-area: d;
}

.order-view-widget .items > .title {
  padding: 16px 20px;
}

.order-view-widget .items > .item-summary-view-widget {
  border-bottom: 1px dashed #e9ecf3;
  margin-bottom: 20px;
  padding: 0 20px 12px;
}

.order-view-widget .items > .item-summary-view-widget:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.order-view-widget .amounts .discount, .order-view-widget .amounts .surcharge, .order-view-widget .amounts .delivery-fee, .order-view-widget .amounts .grand-total, .order-view-widget .amounts .gst-amount, .order-view-widget .amounts .sub-total {
  color: #565c65;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  font-family: Fira Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  display: flex;
}

.order-view-widget .amounts .grand-total {
  color: #426ed9;
  border-top: 1px dashed #e9ecf3;
  padding-top: 14px;
  padding-bottom: 14px;
  font-family: Fira Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.order-view-widget .amounts .gst-amount {
  padding-bottom: 16px;
}

.order-view-widget .collection-info, .order-view-widget .payment {
  grid-row-gap: 8px;
  grid-template-columns: min-content 1fr;
  grid-template-areas: "a b c"
                       "d e e";
  padding: 16px 20px;
  display: grid;
}

.order-view-widget .collection-info .icon, .order-view-widget .payment .icon {
  margin-right: 12px;
  font-size: 24px;
}

.order-view-widget .collection-info label, .order-view-widget .payment label {
  letter-spacing: .7px;
  text-transform: uppercase;
  color: #293340;
  font-family: Fira Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
}

.order-view-widget .collection-info i, .order-view-widget .payment i {
  grid-area: a;
}

.order-view-widget .collection-info label, .order-view-widget .payment label {
  grid-area: b;
  align-self: center;
}

.order-view-widget .collection-info .value, .order-view-widget .payment .value {
  grid-area: d;
}

.order-view-widget .collection-info .icon, .order-view-widget .payment .icon {
  grid-area: a;
}

.order-view-widget .collection-info label, .order-view-widget .payment label {
  grid-area: b;
}

.order-view-widget .collection-info .value, .order-view-widget .payment .value {
  grid-area: c;
}

.order-view-widget .collection-info .status, .order-view-widget .payment .status {
  color: #444;
  grid-area: e;
  font-size: 12px;
}

.order-view-widget .collection-info .payment-card-info, .order-view-widget .payment .payment-card-info {
  flex-direction: row;
  justify-self: end;
  align-items: center;
  display: flex;
}

.order-view-widget .collection-info .payment-card-info .wallet, .order-view-widget .collection-info .payment-card-info .brand, .order-view-widget .payment .payment-card-info .wallet, .order-view-widget .payment .payment-card-info .brand {
  height: 25px;
  margin-right: 5px;
}

.order-view-widget .payment-summary {
  border-bottom: 1px solid #f1f1f5;
}

.order-view-widget .payment-summary .title {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.order-view-widget .payment-summary .title .icon {
  margin-right: 12px;
  font-size: 24px;
}

.order-view-widget .payment-summary .title label {
  letter-spacing: .7px;
  text-transform: uppercase;
  color: #293340;
  font-family: Fira Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
}

.order-view-widget .payment-summary .payment-summary-header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  display: flex;
}

.order-view-widget .payment-summary .split-payments {
  padding: 0 28px 16px;
}

.order-view-widget .payment-summary .split-payment {
  grid-template-areas: "payment-name payment-status payment-amount"
                       "split-bill-items split-bill-items split-bill-items";
  align-items: center;
  row-gap: 4px;
  margin-top: 12px;
  padding-inline: 12px;
  display: grid;
}

.order-view-widget .payment-summary .split-payment:first-child {
  margin-top: 0;
}

.order-view-widget .payment-summary .split-payment > .name {
  grid-area: payment-name;
  font-size: 16px;
  font-weight: 500;
}

.order-view-widget .payment-summary .split-payment > .status {
  text-align: right;
  grid-area: payment-status;
  font-size: 16px;
  font-weight: 500;
}

.order-view-widget .payment-summary .split-payment > .amount {
  color: #1c1d3e;
  grid-area: payment-amount;
  justify-self: end;
  font-size: 16px;
  font-weight: 500;
}

.order-view-widget .payment-summary .split-payment .split-bill-items {
  grid-area: split-bill-items;
  margin-left: 40px;
}

.order-view-widget .payment-summary .split-payment .split-bill-items > .item {
  margin-top: 4px;
  display: list-item;
}

.order-view-widget .payment-summary .split-payment .split-bill-items > .item:first-child {
  margin-top: 0;
}

.order-view-widget .customer-info {
  grid-row-gap: 8px;
  grid-template-columns: min-content 1fr;
  grid-template-areas: "a b c"
                       ". . mobile"
                       "d e e";
  padding: 16px 20px;
  display: grid;
}

.order-view-widget .customer-info .icon {
  margin-right: 12px;
  font-size: 24px;
}

.order-view-widget .customer-info label {
  letter-spacing: .7px;
  text-transform: uppercase;
  color: #293340;
  font-family: Fira Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
}

.order-view-widget .customer-info i {
  grid-area: a;
}

.order-view-widget .customer-info label {
  grid-area: b;
  align-self: center;
}

.order-view-widget .customer-info .value {
  grid-area: d;
}

.order-view-widget .customer-info .icon {
  grid-area: a;
}

.order-view-widget .customer-info label {
  grid-area: b;
}

.order-view-widget .customer-info .customer-name {
  text-align: end;
  grid-area: c;
  font-weight: 500;
}

.order-view-widget .customer-info .customer-mobile {
  text-align: end;
  grid-area: mobile;
  font-weight: 500;
}

.order-view-widget .transaction-fee-info {
  grid-row-gap: 8px;
  grid-template-columns: min-content 1fr;
  grid-template-areas: "icon label amount"
                       ". . status"
                       "d e e";
  padding: 16px 20px;
  display: grid;
}

.order-view-widget .transaction-fee-info .icon {
  margin-right: 12px;
  font-size: 24px;
}

.order-view-widget .transaction-fee-info label {
  letter-spacing: .7px;
  text-transform: uppercase;
  color: #293340;
  font-family: Fira Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
}

.order-view-widget .transaction-fee-info i {
  grid-area: a;
}

.order-view-widget .transaction-fee-info label {
  grid-area: b;
  align-self: center;
}

.order-view-widget .transaction-fee-info .value {
  grid-area: d;
}

.order-view-widget .transaction-fee-info .icon {
  grid-area: icon;
}

.order-view-widget .transaction-fee-info label {
  grid-area: label;
}

.order-view-widget .transaction-fee-info .amount {
  text-align: end;
  grid-area: amount;
  font-weight: 500;
}

.order-view-widget .transaction-fee-info .status {
  text-align: end;
  color: #426ed9;
  grid-area: status;
  font-weight: 500;
}

.order-view-widget .uber-delivery-fee {
  color: #565c65;
  padding: 8px 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  font-family: Fira Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  display: flex;
}

.order-view-widget .uber-delivery-fee .label {
  font-weight: 500;
}

.order-view-widget .charge-to-store {
  color: #565c65;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 20px 8px;
  font-family: Fira Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  display: flex;
}

.printer-queue {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.printer-queue .title {
  letter-spacing: .1px;
  color: var(--fg);
  border-bottom: 1px solid #f1f1f5;
  padding: 12px 18px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.printer-queue .list {
  padding: 12px 18px;
}

.printer-queue .order-list-view {
  border-bottom: 1px solid #f1f1f5;
  grid-template-columns: min-content 1fr min-content;
  grid-template-areas: "restaurant-icon store-name"
                       "restaurant-icon collection-type"
                       ". grand-total"
                       ". actions";
  display: grid;
}

.printer-queue .order-list-view:last-child {
  border-bottom: none;
}

.printer-queue .order-list-view .optimized-image {
  width: 45px;
  border: 3px solid #f1f1f5;
  border-radius: 10px;
  grid-area: restaurant-icon;
  align-self: flex-start;
  margin: 0 15px;
  overflow: hidden;
}

.printer-queue .order-list-view .store-name {
  color: #000521;
  grid-area: store-name;
  align-self: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.printer-queue .order-list-view .grand-total {
  color: #fa7268;
  grid-area: grand-total;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}

.printer-queue .order-list-view .collection-type {
  white-space: nowrap;
  color: #696974;
  grid-area: collection-type;
  align-self: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.printer-queue .order-list-view .message {
  grid-area: message;
}

.printer-queue .order-list-view .status {
  white-space: nowrap;
  grid-area: status;
  place-self: center start;
}

.printer-queue .order-list-view .actions {
  border-top: 1px dashed #e9ecf3;
  flex-direction: row;
  grid-area: actions;
  margin-top: 10px;
  padding-top: 15px;
  display: flex;
}

.printer-queue .order-list-view .actions button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  box-shadow: none;
  background-color: #0000;
  border-radius: 4px;
  margin-right: 7px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 400;
}

.printer-queue .order-list-view .actions .accept-order {
  border: 1px solid var(--button-success-bg);
  color: #0e9347;
  background-color: #0000;
}

.printer-queue .order-list-view .actions .reject-order {
  color: #fc5a5a;
  background-color: #0000;
  border: 1px solid #fc5a5a;
}

.store-edit-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.store-edit-widget form {
  margin-top: 20px;
  padding: 15px 18px;
}

.store-edit-widget form .form-items > .inline-title {
  margin: 0 -18px;
}

.store-edit-widget form .form-items > .inline-title label {
  text-align: left;
}

.store-edit-widget .form-address > .form-input {
  background-color: var(--card-bg);
  grid-gap: 18px;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 20px 15px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.store-edit-widget .form-address > .form-input .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.store-edit-widget .form-address > .form-input .switch label {
  grid-area: a;
  align-self: center;
}

.store-edit-widget .form-address > .form-input .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.store-edit-widget .form-address > .form-input .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.store-edit-widget .form-address > .form-input .switch label {
  text-align: left !important;
}

.store-edit-widget .form-address > label {
  margin-top: 12px;
  align-self: flex-start !important;
}

.store-edit-widget .date-and-time-picker-container {
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
  display: grid;
}

.store-edit-widget .form-third-party-delivery-edit-widget .form-input .third-party-delivery-service {
  background-color: var(--card-bg);
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  margin-bottom: 20px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.store-edit-widget .form-third-party-delivery-edit-widget .form-input .third-party-delivery-service .info {
  grid-gap: 18px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 15px;
  display: grid;
  box-shadow: 0 2px #c4cbd840;
}

.store-edit-widget .form-third-party-delivery-edit-widget .form-input .third-party-delivery-service .info .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.store-edit-widget .form-third-party-delivery-edit-widget .form-input .third-party-delivery-service .info .switch label {
  grid-area: a;
  align-self: center;
}

.store-edit-widget .form-third-party-delivery-edit-widget .form-input .third-party-delivery-service .info .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.store-edit-widget .form-third-party-delivery-edit-widget .form-input .third-party-delivery-service .info .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.store-edit-widget .form-third-party-delivery-edit-widget .form-input .third-party-delivery-service .info .switch label {
  text-align: left !important;
}

.store-edit-widget .form-third-party-delivery-edit-widget .form-input .third-party-delivery-service .actions {
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  padding: 5px 12px;
  display: grid;
}

.store-edit-widget .form-third-party-delivery-edit-widget .form-input .third-party-delivery-service .actions .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.store-edit-widget .form-third-party-delivery-edit-widget .form-input .third-party-delivery-service .actions .action img {
  width: 26px;
  margin-right: 5px;
}

.store-edit-widget .form-third-party-delivery-edit-widget .form-input .third-party-delivery-services-list-actions > .action {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  background-color: #0000;
}

.store-edit-widget .submit-a-form-link {
  margin-left: 10px;
  text-decoration: underline;
}

.store-edit-widget .submit-a-form-link > img {
  position: relative;
  top: 2px;
  left: 10px;
}

.swal2-container .store-edit-widget {
  width: 573px;
  border-radius: 20px;
  padding: 32px 24px;
}

.swal2-container .store-edit-widget .swal2-icon.swal2-warning {
  color: #f59e0b;
  border-width: 4px;
  border-color: #f59e0b;
  font-size: 9px;
  font-weight: 500;
}

.swal2-container .store-edit-widget .swal2-title {
  color: #1c1d3e;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.swal2-container .store-edit-widget .swal2-content {
  color: #7d7e92;
  margin-top: 16px;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.swal2-container .store-edit-widget .swal2-actions {
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "cancel-button confirm-button";
  gap: 16px;
  margin-top: 24px;
  padding: 0 24px;
  display: grid;
}

.swal2-container .store-edit-widget .swal2-actions button {
  border-radius: 4px;
  margin: 0;
  padding: 14px 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.swal2-container .store-edit-widget .swal2-actions .confirm-button {
  color: #fff;
  background-color: #2563eb;
  grid-area: confirm-button;
}

.swal2-container .store-edit-widget .swal2-actions .cancel-button {
  color: #4c4e68;
  border: 1px solid #7d7e92;
  grid-area: cancel-button;
  background-color: #fff !important;
}

.coupon-card {
  background-color: var(--card-bg);
  cursor: pointer;
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  margin: 15px 0;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.coupon-card .body {
  background-color: #fff;
  border-radius: 10px;
  grid-template: "a d" min-content
                 "b d" 1fr
                 "c d" min-content
                 / 1fr min-content;
  padding: 12px;
  display: grid;
  box-shadow: 0 2px #c4cbd840;
}

.coupon-card .name {
  color: var(--fg);
  grid-area: a;
  align-self: flex-start;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.coupon-card .description {
  letter-spacing: .1px;
  color: #8d92a3;
  grid-area: b;
  align-self: flex-start;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.coupon-card .actions {
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  padding: 5px 12px;
  display: grid;
}

.coupon-card .actions .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.coupon-card .actions .action img {
  width: 26px;
  margin-right: 5px;
}

.coupon-edit-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.coupon-edit-widget form {
  margin-top: 20px;
  padding: 15px 18px;
}

.coupon-edit-widget .form-address > .form-input {
  background-color: var(--card-bg);
  grid-gap: 18px;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 20px 15px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.coupon-edit-widget .form-address > .form-input .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.coupon-edit-widget .form-address > .form-input .switch label {
  grid-area: a;
  align-self: center;
}

.coupon-edit-widget .form-address > .form-input .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.coupon-edit-widget .form-address > .form-input .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.coupon-edit-widget .form-address > .form-input .switch label {
  text-align: left !important;
}

.coupon-edit-widget .form-address > label {
  margin-top: 12px;
  align-self: flex-start !important;
}

.user-list {
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  display: grid;
}

.restaurant-user-card-view {
  background-color: var(--card-bg);
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.restaurant-user-card-view .body {
  cursor: pointer;
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  box-shadow: 0 2px #c4cbd840;
}

.restaurant-user-card-view .body .display-name {
  color: var(--fg);
  color: #4c4c77;
  text-transform: uppercase;
  margin-bottom: -1px;
  padding-left: 6px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.restaurant-user-card-view .body .email {
  letter-spacing: .1px;
  color: var(--fg);
  margin-top: -1px;
  padding-left: 6px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.restaurant-user-card-view .body .role {
  padding-left: 6px;
}

.restaurant-user-card-view .actions {
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  padding: 5px 12px;
  display: grid;
}

.restaurant-user-card-view .actions .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.restaurant-user-card-view .actions .action img {
  width: 26px;
  margin-right: 5px;
}

.domain-card {
  background-color: var(--card-bg);
  cursor: pointer;
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.domain-card .body {
  background-color: #fff;
  border-radius: 10px;
  grid-template: "domain-name qr-code" min-content
                 "restaurant-name qr-code" min-content
                 "store-name qr-code" 1fr
                 / 1fr min-content;
  padding: 15px 18px;
  display: grid;
  box-shadow: 0 2px #c4cbd840;
}

.domain-card .body .name {
  color: var(--fg);
  word-break: break-all;
  grid-area: domain-name;
  align-self: flex-start;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.domain-card .body .restaurant-name {
  letter-spacing: .1px;
  color: var(--fg);
  grid-area: restaurant-name;
  align-self: flex-start;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.domain-card .body .store-name {
  grid-area: store-name;
  align-self: flex-start;
}

.domain-card .body .qr-code-widget {
  grid-area: qr-code;
}

.domain-card .description {
  letter-spacing: .1px;
  color: #8d92a3;
  grid-area: b;
  align-self: flex-start;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.domain-card .actions {
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  padding: 5px 12px;
  display: grid;
}

.domain-card .actions .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.domain-card .actions .action img {
  width: 26px;
  margin-right: 5px;
}

.domain-list .list {
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  display: grid;
}

.stripe-payout-card {
  background-color: var(--card-bg);
  cursor: pointer;
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  margin: 15px 0;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.stripe-payout-card .body {
  background-color: #fff;
  border-bottom: none;
  border-radius: 10px;
  grid-template-columns: 1fr;
  padding: 12px;
  display: grid;
  box-shadow: 0 2px #c4cbd840;
}

.stripe-payout-card .amount, .stripe-payout-card .description, .stripe-payout-card .status, .stripe-payout-card .date-created, .stripe-payout-card .arrival-date {
  border-bottom: 1px solid #f1f1f5;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  display: flex;
}

.stripe-payout-card .amount:last-child, .stripe-payout-card .description:last-child, .stripe-payout-card .status:last-child, .stripe-payout-card .date-created:last-child, .stripe-payout-card .arrival-date:last-child {
  border-bottom: none;
}

.stripe-payout-card .amount .title, .stripe-payout-card .description .title, .stripe-payout-card .status .title, .stripe-payout-card .date-created .title, .stripe-payout-card .arrival-date .title {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.stripe-payout-card .amount .title .icon, .stripe-payout-card .description .title .icon, .stripe-payout-card .status .title .icon, .stripe-payout-card .date-created .title .icon, .stripe-payout-card .arrival-date .title .icon {
  margin-right: 12px;
  font-size: 24px;
}

.stripe-payout-card .amount .title label, .stripe-payout-card .description .title label, .stripe-payout-card .status .title label, .stripe-payout-card .date-created .title label, .stripe-payout-card .arrival-date .title label {
  letter-spacing: .7px;
  text-transform: uppercase;
  color: #293340;
  font-family: Fira Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
}

.stripe-payout-card .amount .value {
  color: #426ed9;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.stripe-payout-card .description .value {
  letter-spacing: .1px;
  color: #8d92a3;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.stripe-payout-card .arrival-date .value, .stripe-payout-card .date-created .value {
  white-space: nowrap;
  color: #696974;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.stripe-balance-widget {
  background-color: var(--card-bg);
  grid-gap: 10px;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-columns: repeat(2, min-content);
  grid-auto-flow: column;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.stripe-balance-widget .balance {
  letter-spacing: .1px;
  color: var(--fg);
  white-space: nowrap;
  text-transform: uppercase;
  padding: 10px 15px;
  font-family: Fira Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.stripe-balance-widget .balance .title {
  font-weight: 500;
}

.stripe-balance-widget .balance .amount {
  font-size: 18px;
  font-weight: 500;
}

.stripe-balance-widget .balance .amount .country-code {
  color: #8d92a3;
  font-size: 14px;
  font-weight: 400;
}

.loyalty-settings-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.loyalty-settings-widget form {
  margin-top: 20px;
  padding: 15px 18px;
}

.loyalty-settings-widget .form-input {
  margin-top: 8px;
}

.loyalty-settings-widget .form-input > .card {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .loyalty-settings-widget .form-input > .card {
    background-color: var(--card-bg);
    grid-gap: 18px;
    border: 1px solid #e2e2ea;
    border-radius: 10px;
    padding: 20px 15px;
    display: grid;
    box-shadow: 0 0 3px #687a9b40;
  }

  .loyalty-settings-widget .form-input > .card .switch {
    grid-template-columns: auto max-content;
    grid-template-areas: "a b"
                         "c d";
    display: grid;
  }

  .loyalty-settings-widget .form-input > .card .switch label {
    grid-area: a;
    align-self: center;
  }

  .loyalty-settings-widget .form-input > .card .switch .form-input {
    grid-area: b;
    justify-self: end;
  }

  .loyalty-settings-widget .form-input > .card .switch .meta {
    margin-top: -3px;
    grid-area: c !important;
  }

  .loyalty-settings-widget .form-input > .card .switch label {
    text-align: left !important;
  }
}

.loyalty-settings-widget .form-input > .card .actions {
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  margin-top: -20px;
  margin-bottom: 0;
  display: grid;
}

.loyalty-settings-widget .form-input > .card .actions .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.loyalty-settings-widget .form-input > .card .actions .action img {
  width: 26px;
  margin-right: 5px;
}

@media (min-width: 768px) {
  .loyalty-settings-widget .order-source-mobile-help-text {
    display: none;
  }
}

.loyalty-settings-widget .order-source-mobile-help-text .meta {
  display: block;
}

@media (min-width: 768px) {
  .loyalty-settings-widget .order-source-mobile-help-text .meta {
    display: none;
  }
}

.loyalty-settings-widget .form-radio-group .meta {
  display: none;
}

@media (min-width: 768px) {
  .loyalty-settings-widget .form-radio-group .meta {
    display: block;
  }
}

@media (min-width: 1024px) {
  .loyalty-settings-widget .radio-group {
    flex-wrap: wrap;
    margin-bottom: 8px;
    display: flex;
  }
}

.loyalty-settings-widget .radio {
  margin: 0 0 16px;
}

@media (min-width: 1024px) {
  .loyalty-settings-widget .radio {
    margin: 0 20px 0 0;
  }
}

.loyalty-settings-widget .amount-number-input, .loyalty-settings-widget .percentage-number-input {
  gap: 25px;
  padding: 0 2em;
  display: grid;
}

@media (min-width: 768px) {
  .loyalty-settings-widget .amount-number-input, .loyalty-settings-widget .percentage-number-input {
    grid-auto-flow: column;
    padding: 0;
  }
}

.loyalty-settings-widget .amount-number-input .form-input, .loyalty-settings-widget .percentage-number-input .form-input {
  margin-top: 8px;
}

.store-holidays-edit-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.store-holidays-edit-widget form {
  margin-top: 20px;
  padding: 15px 18px;
}

.store-holidays-edit-widget .form-input .holiday {
  background-color: var(--card-bg);
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  margin-bottom: 20px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.store-holidays-edit-widget .form-input .holiday .info {
  grid-gap: 18px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 15px;
  display: grid;
  box-shadow: 0 2px #c4cbd840;
}

.store-holidays-edit-widget .form-input .holiday .info .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.store-holidays-edit-widget .form-input .holiday .info .switch label {
  grid-area: a;
  align-self: center;
}

.store-holidays-edit-widget .form-input .holiday .info .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.store-holidays-edit-widget .form-input .holiday .info .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.store-holidays-edit-widget .form-input .holiday .info .switch label {
  text-align: left !important;
}

.store-holidays-edit-widget .form-input .holiday .actions {
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  padding: 5px 12px;
  display: grid;
}

.store-holidays-edit-widget .form-input .holiday .actions .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.store-holidays-edit-widget .form-input .holiday .actions .action img {
  width: 26px;
  margin-right: 5px;
}

.store-holidays-edit-widget .form-input .holidays button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  background-color: #0000;
}

.store-holidays-edit-widget .store-holidays-form-item > label {
  margin-top: 12px;
  align-self: flex-start !important;
}

.store-website-settings-edit-widget form {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  margin-top: 20px;
  padding: 15px 18px;
  box-shadow: 0 0 3px #687a9b40;
}

.store-website-settings-edit-widget form .key-value-pair .form-input {
  justify-self: start;
}

.store-website-settings-edit-widget form .form-items .form-item {
  padding-bottom: 10px;
}

.store-website-settings-edit-widget .card {
  background-color: var(--card-bg);
  grid-gap: 18px;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 20px 15px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.store-website-settings-edit-widget .card .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.store-website-settings-edit-widget .card .switch label {
  grid-area: a;
  align-self: center;
}

.store-website-settings-edit-widget .card .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.store-website-settings-edit-widget .card .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.store-website-settings-edit-widget .card .switch label {
  text-align: left !important;
}

.store-website-settings-edit-widget .card .actions {
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  margin-top: -20px;
  margin-bottom: 0;
  display: grid;
}

.store-website-settings-edit-widget .card .actions .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.store-website-settings-edit-widget .card .actions .action img {
  width: 26px;
  margin-right: 5px;
}

.user-restaurant-and-stores-list {
  z-index: 1;
  position: relative;
  top: -50px;
}

.user-restaurant-and-stores-list .user-restaurant-list .header, .user-restaurant-and-stores-list .user-store-list .header {
  letter-spacing: .1px;
  color: var(--fg);
  margin: 30px 0 20px;
  padding-left: 6px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.user-restaurant-and-stores-list .user-restaurant-list > .header {
  margin-top: 0;
}

.restaurant-customers .table-header {
  display: none;
}

@media (min-width: 1024px) {
  .restaurant-customers .table-header {
    background-color: var(--card-bg);
    background-color: #fafafb;
    border: 1px solid #e2e2ea;
    border-radius: 10px;
    grid-template-rows: 1fr min-content;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 20px;
    padding: 0 15px;
    display: grid;
    box-shadow: 0 0 3px #687a9b40;
  }

  .restaurant-customers .table-header p {
    font-weight: 500;
  }
}

.restaurant-customers .customer {
  background-color: var(--card-bg);
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  margin-bottom: 20px;
  padding: 15px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

@media (min-width: 1024px) {
  .restaurant-customers .customer {
    grid-template-columns: repeat(4, 1fr);
  }
}

.restaurant-customers .customer > p > span {
  font-weight: 500;
}

@media (min-width: 1024px) {
  .restaurant-customers .customer > p > span {
    display: none;
  }
}

.store-trading-hours-edit-widget .collection-type > label {
  margin-top: 12px;
  font-weight: 500;
  align-self: flex-start !important;
}

.store-trading-hours-edit-widget .collection-type > .form-input {
  grid-gap: 18px;
  grid-gap: 0;
  padding: 0 0 15px;
  display: grid;
}

.store-trading-hours-edit-widget .collection-type > .form-input .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.store-trading-hours-edit-widget .collection-type > .form-input .switch label {
  grid-area: a;
  align-self: center;
}

.store-trading-hours-edit-widget .collection-type > .form-input .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.store-trading-hours-edit-widget .collection-type > .form-input .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.store-trading-hours-edit-widget .collection-type > .form-input .switch label {
  text-align: left !important;
}

.store-trading-hours-edit-widget .session:first-child > .form-item:first-child {
  background-color: #f9f9f9;
  border-bottom: 1px solid #f1f1f5;
  margin: 0;
  padding: 12px 18px;
  font-weight: 500;
}

.store-trading-hours-edit-widget .session .form-item {
  padding: 10px 15px;
}

.weekly-hours-edit-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 0 3px #687a9b40;
}

.weekly-hours-edit-widget .daily-hours-and-available-all-day-widget .daily-hours-edit-widget {
  grid-template-areas: "label action" "hours ";
  margin: 0 auto;
  display: grid;
}

.weekly-hours-edit-widget .daily-hours-and-available-all-day-widget .daily-hours-edit-widget .action {
  color: #999;
  cursor: pointer;
  justify-items: center;
  font-size: 30px;
}

.weekly-hours-edit-widget .daily-hours-and-available-all-day-widget .daily-hours-edit-widget .action img {
  width: 26px;
  margin-right: 5px;
}

.weekly-hours-edit-widget .daily-hours-and-available-all-day-widget .daily-hours-edit-widget .hours {
  grid-gap: 10px 15px;
  grid-template-columns: 2fr min-content;
  align-items: center;
  margin-bottom: 20px;
  display: grid;
}

.weekly-hours-edit-widget .daily-hours-and-available-all-day-widget .daily-hours-edit-widget .hours .switch {
  width: max-content;
  grid-gap: 0 15px;
  display: grid;
}

.weekly-hours-edit-widget .daily-hours-and-available-all-day-widget .daily-hours-edit-widget .hours .day-interval {
  grid-gap: 15px;
  grid-template-columns: 2fr 2fr;
  align-items: center;
  display: grid;
}

.weekly-hours-edit-widget .daily-hours-and-available-all-day-widget .switch {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.printer-list-page .list, .printer-list {
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  display: grid;
}

.printer-list-page .list .printer, .printer-list .printer {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-columns: max-content 1fr;
  grid-template-areas: "name name"
                       "id-label id"
                       "password-label password"
                       "created-time-label created-time"
                       "actions actions";
  display: grid;
  overflow: hidden;
  box-shadow: 0 0 3px #687a9b40;
}

.printer-list-page .list .printer .name, .printer-list-page .list .printer .id-label, .printer-list-page .list .printer .id, .printer-list-page .list .printer .password-label, .printer-list-page .list .printer .password, .printer-list-page .list .printer .created-time-label, .printer-list-page .list .printer .created-time, .printer-list-page .list .printer .actions, .printer-list .printer .name, .printer-list .printer .id-label, .printer-list .printer .id, .printer-list .printer .password-label, .printer-list .printer .password, .printer-list .printer .created-time-label, .printer-list .printer .created-time, .printer-list .printer .actions {
  padding: 15px;
}

.printer-list-page .list .printer .name, .printer-list .printer .name {
  color: var(--fg);
  border-bottom: 1px solid #f1f1f5;
  grid-area: name;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.printer-list-page .list .printer .id-label, .printer-list .printer .id-label {
  grid-area: id-label;
}

.printer-list-page .list .printer .created-time-label, .printer-list .printer .created-time-label {
  color: #696974;
  grid-area: created-time-label;
}

.printer-list-page .list .printer .password-label, .printer-list .printer .password-label {
  grid-area: password-label;
}

.printer-list-page .list .printer .id, .printer-list .printer .id {
  grid-area: id;
  justify-self: end;
}

.printer-list-page .list .printer .password, .printer-list .printer .password {
  grid-area: password;
  justify-self: end;
}

.printer-list-page .list .printer .created-time, .printer-list .printer .created-time {
  color: #696974;
  grid-area: created-time;
  justify-self: end;
}

.printer-list-page .list .printer .actions, .printer-list .printer .actions {
  border-top: 1px solid #f1f1f5;
  grid-area: actions;
}

.printer-list-page .list .printer .actions button, .printer-list .printer .actions button {
  cursor: pointer;
  width: 100px;
}

.public-holiday-surcharge-settings-edit-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.public-holiday-surcharge-settings-edit-widget form {
  margin-top: 20px;
  padding: 15px 18px;
}

@media (min-width: 768px) {
  .public-holiday-surcharge-settings-edit-widget .order-source-mobile-help-text {
    display: none;
  }
}

.public-holiday-surcharge-settings-edit-widget .order-source-mobile-help-text .meta {
  display: block;
}

@media (min-width: 768px) {
  .public-holiday-surcharge-settings-edit-widget .order-source-mobile-help-text .meta {
    display: none;
  }
}

.public-holiday-surcharge-settings-edit-widget .form-radio-group .meta {
  display: none;
}

@media (min-width: 768px) {
  .public-holiday-surcharge-settings-edit-widget .form-radio-group .meta {
    display: block;
  }
}

@media (min-width: 1024px) {
  .public-holiday-surcharge-settings-edit-widget .radio-group {
    flex-wrap: wrap;
    margin-bottom: 8px;
    display: flex;
  }
}

.public-holiday-surcharge-settings-edit-widget .radio {
  margin: 0 0 16px;
}

@media (min-width: 1024px) {
  .public-holiday-surcharge-settings-edit-widget .radio {
    margin: 0 20px 0 0;
  }
}

.public-holiday-surcharge-settings-edit-widget .form-input .settings, .public-holiday-surcharge-settings-edit-widget .form-input .holiday {
  background-color: var(--card-bg);
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  margin-bottom: 20px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.public-holiday-surcharge-settings-edit-widget .form-input .settings .info, .public-holiday-surcharge-settings-edit-widget .form-input .holiday .info {
  grid-gap: 18px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 15px;
  display: grid;
  box-shadow: 0 2px #c4cbd840;
}

.public-holiday-surcharge-settings-edit-widget .form-input .settings .info .switch, .public-holiday-surcharge-settings-edit-widget .form-input .holiday .info .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.public-holiday-surcharge-settings-edit-widget .form-input .settings .info .switch label, .public-holiday-surcharge-settings-edit-widget .form-input .holiday .info .switch label {
  grid-area: a;
  align-self: center;
}

.public-holiday-surcharge-settings-edit-widget .form-input .settings .info .switch .form-input, .public-holiday-surcharge-settings-edit-widget .form-input .holiday .info .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.public-holiday-surcharge-settings-edit-widget .form-input .settings .info .switch .meta, .public-holiday-surcharge-settings-edit-widget .form-input .holiday .info .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.public-holiday-surcharge-settings-edit-widget .form-input .settings .info .switch label, .public-holiday-surcharge-settings-edit-widget .form-input .holiday .info .switch label {
  text-align: left !important;
}

.public-holiday-surcharge-settings-edit-widget .form-input .settings .actions, .public-holiday-surcharge-settings-edit-widget .form-input .holiday .actions {
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  padding: 5px 12px;
  display: grid;
}

.public-holiday-surcharge-settings-edit-widget .form-input .settings .actions .action, .public-holiday-surcharge-settings-edit-widget .form-input .holiday .actions .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.public-holiday-surcharge-settings-edit-widget .form-input .settings .actions .action img, .public-holiday-surcharge-settings-edit-widget .form-input .holiday .actions .action img {
  width: 26px;
  margin-right: 5px;
}

.public-holiday-surcharge-settings-edit-widget .form-input .holidays button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  background-color: #0000;
}

.public-holiday-surcharge-settings-edit-widget .public-holiday-surcharge-form-item > label {
  margin-top: 12px;
  align-self: flex-start !important;
}

.restaurant-edit-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.restaurant-edit-widget form {
  margin-top: 20px;
  padding: 15px 18px;
}

.category-edit-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.category-edit-widget form {
  margin-top: 20px;
  padding: 15px 18px;
}

.swal2-container .category-edit-widget {
  max-width: 364px;
  border-radius: 20px;
  padding: 32px 24px;
}

.swal2-container .category-edit-widget .swal2-title {
  color: #1c1d3e;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.swal2-container .category-edit-widget .swal2-content {
  color: #7d7e92;
  margin-top: 16px;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.swal2-container .category-edit-widget .swal2-actions {
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
  padding: 0 24px;
}

.swal2-container .category-edit-widget .swal2-actions button {
  border-radius: 4px;
  margin: 0;
  padding: 14px 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.swal2-container .category-edit-widget .swal2-actions .confirm-button {
  width: 100%;
  color: #fff;
  background-color: #2563eb;
}

.swal2-container .category-edit-widget .swal2-actions .cancel-button {
  width: 100%;
  color: #4c4e68;
  border: 1px solid #7d7e92;
  background-color: #fff !important;
}

.half-and-half-edit-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.half-and-half-edit-widget form {
  margin-top: 20px;
  padding: 15px 18px;
}

.half-and-half-edit-widget form .allergen-info > .form-input {
  grid-row-gap: 20px;
  display: grid;
}

.half-and-half-edit-widget form .allergen-info .form-item {
  display: flex;
}

.half-and-half-edit-widget form .allergen-info .form-input {
  width: 100%;
}

.half-and-half-edit-widget form .allergen-info label {
  background-color: var(--form-element-bg);
  border: 1px solid var(--form-element-border);
  box-sizing: border-box;
  color: var(--form-element-fg);
  height: -moz-fit-content;
  height: fit-content;
  width: 40%;
  border-radius: 10px;
  margin-right: 10px;
  padding: 10px 14px;
}

.half-and-half-edit-widget .pizzas-form-item > label {
  margin-top: 12px;
  align-self: flex-start !important;
}

.half-and-half-edit-widget .pizza-list {
  background-color: var(--card-bg);
  background-color: #fff;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  display: grid;
  box-shadow: 0 2px #c4cbd840;
}

.half-and-half-edit-widget .pizza-list .head {
  background-color: #f9f9f9;
  border-bottom: 1px solid #f1f1f5;
  padding: 12px 18px;
}

.half-and-half-edit-widget .pizza-list .body {
  grid-gap: 20px;
  padding: 15px 18px;
  display: grid;
}

.half-and-half-edit-widget .pizza-list .head, .half-and-half-edit-widget .pizza {
  grid-gap: 15px;
  grid-template-columns: min-content 1fr;
  display: grid;
}

.basic-item-edit-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.basic-item-edit-widget form {
  margin-top: 20px;
  padding: 15px 18px;
}

.basic-item-edit-widget form .allergen-info > .form-input {
  grid-row-gap: 20px;
  display: grid;
}

.basic-item-edit-widget form .allergen-info .form-item {
  display: flex;
}

.basic-item-edit-widget form .allergen-info .form-input {
  width: 100%;
}

.basic-item-edit-widget form .allergen-info label {
  background-color: var(--form-element-bg);
  border: 1px solid var(--form-element-border);
  box-sizing: border-box;
  color: var(--form-element-fg);
  height: -moz-fit-content;
  height: fit-content;
  width: 40%;
  border-radius: 10px;
  margin-right: 10px;
  padding: 10px 14px;
}

.swal2-container .basic-item-spec-edit-widget {
  max-width: 364px;
  border-radius: 20px;
  padding: 32px 24px;
}

.swal2-container .basic-item-spec-edit-widget .swal2-title {
  color: #1c1d3e;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.swal2-container .basic-item-spec-edit-widget .swal2-content {
  color: #7d7e92;
  margin-top: 16px;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.swal2-container .basic-item-spec-edit-widget .swal2-actions {
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
  padding: 0 24px;
}

.swal2-container .basic-item-spec-edit-widget .swal2-actions button {
  border-radius: 4px;
  margin: 0;
  padding: 14px 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.swal2-container .basic-item-spec-edit-widget .swal2-actions .confirm-button {
  width: 100%;
  color: #fff;
  background-color: #2563eb;
}

.swal2-container .basic-item-spec-edit-widget .swal2-actions .cancel-button {
  width: 100%;
  color: #4c4e68;
  border: 1px solid #7d7e92;
  background-color: #fff !important;
}

.custom-item-edit-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.custom-item-edit-widget form {
  margin-top: 20px;
  padding: 15px 18px;
}

.custom-item-edit-widget form .allergen-info > .form-input {
  grid-row-gap: 20px;
  display: grid;
}

.custom-item-edit-widget form .allergen-info .form-item {
  display: flex;
}

.custom-item-edit-widget form .allergen-info .form-input {
  width: 100%;
}

.custom-item-edit-widget form .allergen-info label {
  background-color: var(--form-element-bg);
  border: 1px solid var(--form-element-border);
  box-sizing: border-box;
  color: var(--form-element-fg);
  height: -moz-fit-content;
  height: fit-content;
  width: 40%;
  border-radius: 10px;
  margin-right: 10px;
  padding: 10px 14px;
}

.pos-custom-payments-settings-edit-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.pos-custom-payments-settings-edit-widget .title {
  color: var(--fg);
  border-bottom: 1px solid #f1f1f5;
  padding: 12px 18px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.pos-custom-payments-settings-edit-widget form .form-items {
  padding: 15px 18px;
}

.pos-custom-payments-settings-edit-widget form .form-buttons {
  background-color: #fafafb;
  border-top: 1px solid #f1f1f5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-top: 0;
  padding: 15px 18px;
}

.pos-settings-widget .general {
  column-count: 1;
  column-gap: 20px;
}

.pos-settings-widget .general > div {
  break-inside: avoid;
  margin-bottom: 24px;
}

@media (min-width: 768px) {
  .pos-settings-widget .general {
    column-count: 1;
  }
}

@media (min-width: 1024px) {
  .pos-settings-widget .general {
    column-count: 3;
  }
}

.pos-lock-screen-settings-edit-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.pos-lock-screen-settings-edit-widget .title {
  letter-spacing: .1px;
  color: var(--fg);
  color: var(--fg);
  border-bottom: 1px solid #f1f1f5;
  margin-bottom: 15px;
  padding: 12px 18px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.pos-lock-screen-settings-edit-widget form {
  padding: 0;
}

.pos-lock-screen-settings-edit-widget form .form-item {
  padding: 0 18px;
}

.pos-lock-screen-settings-edit-widget form .form-items {
  display: grid;
}

.pos-lock-screen-settings-edit-widget form .form-items .form-item {
  grid-template-columns: 45% 55%;
  align-items: center;
  display: grid;
}

.pos-lock-screen-settings-edit-widget form .form-items .form-item .help {
  white-space: pre-wrap;
  padding-top: 6px;
}

.pos-lock-screen-settings-edit-widget form .form-items .form-item .form-input {
  min-width: 80%;
  justify-self: end;
}

.pos-lock-screen-settings-edit-widget form .form-items .form-item .form-input .lock-screen-timeout-never {
  color: #7d7e92;
}

.pos-lock-screen-settings-edit-widget form .form-items .form-item .form-input .lock-screen-timeout-minutes {
  color: #2563eb;
}

.pos-lock-screen-settings-edit-widget form .form-items .form-item .form-input .options {
  width: max-content;
  white-space: nowrap;
}

.pos-lock-screen-settings-edit-widget .form-buttons {
  background-color: #fafafb;
  border-top: 1px solid #f1f1f5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 15px 18px;
}

.pos-employee-time-clock-settings-edit-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.pos-employee-time-clock-settings-edit-widget .title {
  letter-spacing: .1px;
  color: var(--fg);
  color: var(--fg);
  border-bottom: 1px solid #f1f1f5;
  margin-bottom: 15px;
  padding: 12px 18px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.pos-employee-time-clock-settings-edit-widget form {
  padding: 0;
}

.pos-employee-time-clock-settings-edit-widget form .form-item, .pos-employee-time-clock-settings-edit-widget form .empty-message {
  padding: 0 20px;
}

.pos-employee-time-clock-settings-edit-widget form .form-items .form-item > label {
  padding-bottom: 6px;
}

.pos-employee-time-clock-settings-edit-widget .add-work-break-button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  width: -moz-fit-content;
  width: fit-content;
  background-color: #0000;
  margin: 0 20px;
}

.pos-employee-time-clock-settings-edit-widget .form-buttons {
  background-color: #fafafb;
  border-top: 1px solid #f1f1f5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 15px 18px;
}

.pos-employee-shift-break-preset-view-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  margin: 0 20px;
  box-shadow: 0 0 3px #687a9b40;
}

.pos-employee-shift-break-preset-view-widget .fields {
  color: #1c1d3e;
  grid-template-areas: "name name"
                       "type-label type"
                       "limit-label limit-duration";
  gap: 6px;
  padding: 20px;
  font-size: 12px;
  display: grid;
}

.pos-employee-shift-break-preset-view-widget .fields .name {
  letter-spacing: .1px;
  color: #1c1d3e;
  grid-area: name;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 19.5px;
}

.pos-employee-shift-break-preset-view-widget .fields .label {
  color: #757575;
}

.pos-employee-shift-break-preset-view-widget .fields .type-label {
  grid-area: type-label;
}

.pos-employee-shift-break-preset-view-widget .fields .type {
  text-transform: capitalize;
  grid-area: type;
  justify-self: end;
}

.pos-employee-shift-break-preset-view-widget .fields .limit-label {
  grid-area: limit-label;
}

.pos-employee-shift-break-preset-view-widget .fields .limit-duration {
  grid-area: limit-duration;
  justify-self: end;
}

.pos-employee-shift-break-preset-view-widget .actions {
  background-color: #fafafb;
  border-top: 1px solid #f1f1f5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  flex-direction: row;
  align-items: center;
  padding: 15px 18px;
  display: flex;
}

.pos-employee-shift-break-preset-view-widget .actions button {
  margin-right: 10px;
}

.pos-employee-shift-break-preset-view-widget .actions a {
  margin-left: 6px;
  font-family: Poppins, sans-serif;
  font-size: .75rem;
  font-weight: 500;
}

.pos-employee-shift-break-preset-view-widget .actions a.cancel {
  color: var(--message-error-fg);
}

.pos-employee-shift-break-preset-view-widget .actions .action {
  color: #999;
  cursor: pointer;
  cursor: pointer;
  font-size: 30px;
}

.pos-employee-shift-break-preset-view-widget .actions .action img {
  width: 26px;
  margin-right: 5px;
}

.swal2-container .delete-work-break-warning {
  max-width: 350px;
  border-radius: 20px;
  padding: 32px 24px;
}

.swal2-container .delete-work-break-warning .swal2-content {
  color: #1c1d3e;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.swal2-container .delete-work-break-warning .swal2-actions {
  flex-direction: row-reverse;
  gap: 16px;
  margin-top: 24px;
}

.swal2-container .delete-work-break-warning .swal2-actions button {
  border-radius: 4px;
  margin: 0;
  padding: 10px 30px;
  font-size: 14px;
  font-weight: 500;
}

.swal2-container .delete-work-break-warning .swal2-actions .confirm-button {
  color: #fff;
  background-color: #2563eb;
}

.swal2-container .delete-work-break-warning .swal2-actions .cancel-button {
  color: #4c4e68;
  border: 1px solid #4c4e68;
  background-color: #fff !important;
}

.pos-employee-shift-break-preset-edit-widget {
  border-radius: 25px;
}

.pos-employee-shift-break-preset-edit-widget .title {
  letter-spacing: .1px;
  color: #1c1d3e;
  padding: 35px 24px 14px;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}

.pos-employee-shift-break-preset-edit-widget .close-button {
  width: 27px;
  cursor: pointer;
  position: absolute;
  top: 22px;
  right: 8px;
}

.pos-employee-shift-break-preset-edit-widget form .form-items {
  row-gap: 16px;
}

.pos-employee-shift-break-preset-edit-widget form .form-items .form-item {
  padding: 0 20px;
}

.pos-employee-shift-break-preset-edit-widget form .form-items .form-item label {
  color: #1c1d3e;
}

.pos-employee-shift-break-preset-edit-widget form .form-items .form-input {
  padding-top: 12px;
}

.pos-employee-shift-break-preset-edit-widget form .form-items .form-input .text-input ::placeholder {
  font-weight: 300;
}

.pos-employee-shift-break-preset-edit-widget form .form-items .form-input .radio-group {
  flex-wrap: wrap;
  gap: 6px;
  display: flex;
}

.pos-employee-shift-break-preset-edit-widget form .form-items .form-input .radio-group .radio {
  margin: 0 20px 0 0;
}

.pos-employee-shift-break-preset-edit-widget form .form-items .form-input .form-select .break-limit {
  color: #1c1d3e;
  font-weight: 300;
}

.pos-employee-shift-break-preset-edit-widget form .form-items .form-input .form-select .options {
  width: 92%;
}

.pos-employee-shift-break-preset-edit-widget form .form-items .form-input .form-select .options .option {
  color: #1c1d3e;
}

.pos-employee-shift-break-preset-edit-widget form .form-items .break-limit-hours-and-minutes {
  justify-self: start;
  margin-left: 15px;
  padding: 0 20px;
}

.pos-employee-shift-break-preset-edit-widget form .form-items .break-limit-hours-and-minutes .form-input {
  width: min-content;
  grid-template-areas: "title title"
                       "hours minutes"
                       "meta meta";
  display: grid;
}

.pos-employee-shift-break-preset-edit-widget form .form-items .break-limit-hours-and-minutes .form-input input {
  min-width: 80px;
}

@media (max-width: 767px) {
  .pos-employee-shift-break-preset-edit-widget form .form-items .break-limit-hours-and-minutes .form-input input {
    min-width: 50px;
  }
}

.pos-employee-shift-break-preset-edit-widget form .form-items .break-limit-hours-and-minutes .form-item {
  flex-direction: row-reverse;
  align-items: center;
  padding: 0;
  display: flex;
}

.pos-employee-shift-break-preset-edit-widget form .form-items .break-limit-hours-and-minutes .form-item label {
  padding-top: 12px;
}

.pos-employee-shift-break-preset-edit-widget form .form-items .break-limit-hours-and-minutes .break-limit-hours {
  grid-area: hours;
  padding-right: 24px;
}

.pos-employee-shift-break-preset-edit-widget form .form-items .break-limit-hours-and-minutes .break-limit-minutes {
  grid-area: minutes;
}

@media (max-width: 767px) {
  .pos-employee-shift-break-preset-edit-widget form .form-items .break-limit-hours-and-minutes .form-item {
    padding: 0 20px 0 0;
  }
}

.pos-employee-shift-break-preset-edit-widget form .form-items .all-employee-tags {
  max-width: 410px;
  flex-wrap: wrap;
  padding: 0 20px;
  display: flex;
}

.pos-employee-shift-break-preset-edit-widget form .form-items .form-multi-select-tag-autocomplete .form-input {
  padding-top: 0;
}

.pos-employee-shift-break-preset-edit-widget form .form-items .form-multi-select-tag-autocomplete .multi-select-tags {
  max-width: 410px;
}

.pos-employee-shift-break-preset-edit-widget form .form-items .form-multi-select-tag-autocomplete .multi-select-autocomplete__menu-list {
  max-height: 100px;
}

.pos-employee-shift-break-preset-edit-widget form .form-buttons {
  background-color: #fafafb;
  border-top: 1px solid #f1f1f5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 15px 18px;
  display: flex;
}

.pos-employee-shift-break-preset-edit-widget form .form-buttons .submit-btn {
  margin-right: 8px;
}

.pos-employee-shift-break-preset-edit-widget form .form-buttons .cancel-btn {
  min-width: 100px;
  color: #757575;
  background-color: #fff;
}

.pos-surcharge-settings-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.pos-surcharge-settings-widget .title {
  letter-spacing: .1px;
  color: var(--fg);
  color: var(--fg);
  border-bottom: 1px solid #f1f1f5;
  padding: 12px 18px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.pos-surcharge-settings-widget .actions {
  background-color: #fff;
  border-top: 1px solid #f1f1f5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  flex-direction: row;
  align-items: center;
  padding: 15px 18px;
  display: flex;
}

.pos-surcharge-settings-widget .actions button {
  margin-right: 10px;
}

.pos-surcharge-settings-widget .actions a {
  margin-left: 6px;
  font-family: Poppins, sans-serif;
  font-size: .75rem;
  font-weight: 500;
}

.pos-surcharge-settings-widget .actions a.cancel {
  color: var(--message-error-fg);
}

.pos-surcharge-settings-widget .actions button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  background-color: #0000;
  margin-right: 10px;
}

.pos-surcharge-settings-widget .empty-message {
  padding: 20px;
}

.pos-surcharge {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  margin: 20px;
  display: grid;
  overflow: hidden;
  box-shadow: 0 0 3px #687a9b40;
}

.pos-surcharge .fields {
  justify-content: space-between;
  padding: 15px;
  display: flex;
}

.pos-surcharge .fields .name {
  letter-spacing: .1px;
  color: var(--fg);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.pos-surcharge .fields .value {
  font-size: .85rem;
  font-weight: 300;
}

.pos-surcharge .actions {
  background-color: #fafafb;
  border-top: 1px solid #f1f1f5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 15px 18px;
  display: flex;
}

.pos-surcharge .actions button {
  width: 100px;
}

.pos-surcharge .actions .action {
  color: #999;
  cursor: pointer;
  cursor: pointer;
  font-size: 30px;
}

.pos-surcharge .actions .action img {
  width: 26px;
  margin-right: 5px;
}

.pos-discount-settings-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.pos-discount-settings-widget .title {
  letter-spacing: .1px;
  color: var(--fg);
  color: var(--fg);
  border-bottom: 1px solid #f1f1f5;
  padding: 12px 18px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.pos-discount-settings-widget .actions {
  background-color: #fff;
  border-top: 1px solid #f1f1f5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  flex-direction: row;
  align-items: center;
  padding: 15px 18px;
  display: flex;
}

.pos-discount-settings-widget .actions button {
  margin-right: 10px;
}

.pos-discount-settings-widget .actions a {
  margin-left: 6px;
  font-family: Poppins, sans-serif;
  font-size: .75rem;
  font-weight: 500;
}

.pos-discount-settings-widget .actions a.cancel {
  color: var(--message-error-fg);
}

.pos-discount-settings-widget .actions button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  background-color: #0000;
  margin-right: 10px;
}

.pos-discount-settings-widget .empty-message {
  padding: 20px;
}

.pos-discount {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  margin: 20px;
  display: grid;
  overflow: hidden;
  box-shadow: 0 0 3px #687a9b40;
}

.pos-discount .fields {
  justify-content: space-between;
  padding: 15px;
  display: flex;
}

.pos-discount .fields .name {
  letter-spacing: .1px;
  color: var(--fg);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.pos-discount .fields .value {
  font-size: .85rem;
  font-weight: 300;
}

.pos-discount .actions {
  background-color: #fafafb;
  border-top: 1px solid #f1f1f5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 15px 18px;
  display: flex;
}

.pos-discount .actions button {
  width: 100px;
}

.pos-discount .actions .action {
  color: #999;
  cursor: pointer;
  cursor: pointer;
  font-size: 30px;
}

.pos-discount .actions .action img {
  width: 26px;
  margin-right: 5px;
}

.pos-terminal-settings-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.pos-terminal-settings-widget .title {
  letter-spacing: .1px;
  color: var(--fg);
  color: var(--fg);
  border-bottom: 1px solid #f1f1f5;
  padding: 12px 18px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.pos-terminal-settings-widget .actions {
  background-color: #fff;
  border-top: 1px solid #f1f1f5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  flex-direction: row;
  align-items: center;
  padding: 15px 18px;
  display: flex;
}

.pos-terminal-settings-widget .actions button {
  margin-right: 10px;
}

.pos-terminal-settings-widget .actions a {
  margin-left: 6px;
  font-family: Poppins, sans-serif;
  font-size: .75rem;
  font-weight: 500;
}

.pos-terminal-settings-widget .actions a.cancel {
  color: var(--message-error-fg);
}

.pos-terminal-settings-widget .actions button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  background-color: #0000;
  margin-right: 10px;
}

.pos-terminal-settings-widget .empty-message {
  padding: 20px;
}

.pos-terminal {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-columns: max-content 1fr;
  grid-template-areas: "name name"
                       "id-label id"
                       "password-label password"
                       "created-time-label created-time"
                       "unique-prefix-label unique-prefix"
                       "actions actions";
  margin: 20px;
  display: grid;
  overflow: hidden;
  box-shadow: 0 0 3px #687a9b40;
}

.pos-terminal .name, .pos-terminal .id-label, .pos-terminal .id, .pos-terminal .password-label, .pos-terminal .password, .pos-terminal .created-time-label, .pos-terminal .created-time, .pos-terminal .unique-prefix-label, .pos-terminal .unique-prefix, .pos-terminal .actions {
  padding: 15px;
}

.pos-terminal .name {
  letter-spacing: .1px;
  color: var(--fg);
  border-bottom: 1px solid #f1f1f5;
  grid-area: name;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.pos-terminal .id-label, .pos-terminal .password-label, .pos-terminal .created-time-label, .pos-terminal .unique-prefix-label {
  color: #696974;
}

.pos-terminal .id-label {
  grid-area: id-label;
}

.pos-terminal .created-time-label {
  grid-area: created-time-label;
}

.pos-terminal .password-label {
  grid-area: password-label;
}

.pos-terminal .unique-prefix-label {
  grid-area: unique-prefix-label;
}

.pos-terminal .id, .pos-terminal .password, .pos-terminal .created-time, .pos-terminal .unique-prefix {
  justify-self: end;
}

.pos-terminal .id {
  grid-area: id;
}

.pos-terminal .password {
  grid-area: password;
}

.pos-terminal .created-time {
  grid-area: created-time;
}

.pos-terminal .unique-prefix {
  grid-area: unique-prefix;
}

.pos-terminal .actions {
  background-color: #fafafb;
  border-top: 1px solid #f1f1f5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  grid-area: actions;
  padding: 15px 18px;
  display: flex;
}

.pos-terminal .actions button {
  width: 100px;
}

.pos-terminal .actions .action {
  color: #999;
  cursor: pointer;
  cursor: pointer;
  font-size: 30px;
}

.pos-terminal .actions .action img {
  width: 26px;
  margin-right: 5px;
}

.pos-terminal-edit-widget {
  min-width: 570px;
  flex-direction: column;
  display: flex;
}

.pos-terminal-edit-widget .header {
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 35px 35px 14px 22px;
  display: flex;
}

.pos-terminal-edit-widget .header .icon {
  letter-spacing: .1px;
  color: var(--fg);
  margin-right: 12px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.pos-terminal-edit-widget .header label {
  letter-spacing: .7px;
  text-transform: uppercase;
  color: #293340;
  font-family: Fira Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
}

.pos-terminal-edit-widget .header .title {
  font-size: 20px;
  font-weight: bold;
}

.pos-terminal-edit-widget .header .close-button {
  width: 27px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 10px;
}

.pos-terminal-edit-widget form {
  padding: 0 20px 20px;
}

.pos-terminal-edit-widget form .form-buttons {
  gap: 12px;
  display: flex;
}

.pos-surcharge-edit-widget .header {
  background-color: #fff;
  justify-content: space-between;
  padding: 35px 35px 14px 22px;
  display: flex;
}

.pos-surcharge-edit-widget .header .icon {
  margin-right: 12px;
  font-size: 24px;
}

.pos-surcharge-edit-widget .header label {
  letter-spacing: .7px;
  text-transform: uppercase;
  color: #293340;
  font-family: Fira Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
}

.pos-surcharge-edit-widget .header .title {
  font-size: 20px;
  font-weight: bold;
}

.pos-surcharge-edit-widget .header .close-button {
  width: 27px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 10px;
}

.pos-surcharge-edit-widget form {
  padding: 0 20px 20px;
}

.pos-surcharge-edit-widget form .form-buttons {
  gap: 12px;
  display: flex;
}

.pos-discount-edit-widget .header {
  background-color: #fff;
  justify-content: space-between;
  padding: 35px 35px 14px 22px;
  display: flex;
}

.pos-discount-edit-widget .header .icon {
  margin-right: 12px;
  font-size: 24px;
}

.pos-discount-edit-widget .header label {
  letter-spacing: .7px;
  text-transform: uppercase;
  color: #293340;
  font-family: Fira Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
}

.pos-discount-edit-widget .header .title {
  font-size: 20px;
  font-weight: bold;
}

.pos-discount-edit-widget .header .close-button {
  width: 27px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 10px;
}

.pos-discount-edit-widget form {
  padding: 0 20px 20px;
}

.pos-discount-edit-widget form .form-buttons {
  gap: 12px;
  display: flex;
}

.pos-order-view-widget {
  background-color: var(--card-bg);
  max-height: 65vh;
  scrollbar-width: none;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 0 3px #687a9b40;
}

.pos-order-view-widget .header {
  background-color: #fff;
  border-bottom: 1px dashed #e9ecf3;
  border-radius: 14px 14px 0 0;
  justify-content: space-between;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  position: fixed;
}

.pos-order-view-widget .header .icon {
  margin-right: 12px;
  font-size: 24px;
}

.pos-order-view-widget .header label {
  letter-spacing: .7px;
  text-transform: uppercase;
  color: #293340;
  font-family: Fira Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
}

.pos-order-view-widget .header .title {
  margin-left: 12px;
}

.pos-order-view-widget .header .title label {
  font-size: 20px;
}

.pos-order-view-widget .header .close-button {
  width: 27px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 10px;
}

.pos-order-view-widget .body {
  margin-top: 70px;
}

.pos-order-view-widget .order-comments, .pos-order-view-widget .collection-type, .pos-order-view-widget .collection-time, .pos-order-view-widget .table-name, .pos-order-view-widget .delivery-address, .pos-order-view-widget .payment-method, .pos-order-view-widget .payment, .pos-order-view-widget .customer-info, .pos-order-view-widget .transaction-fee-info, .pos-order-view-widget .items {
  border-bottom: 1px solid #f1f1f5;
}

.pos-order-view-widget .order-comments .title, .pos-order-view-widget .collection-type .title, .pos-order-view-widget .collection-time .title, .pos-order-view-widget .table-name .title, .pos-order-view-widget .delivery-address .title, .pos-order-view-widget .payment-method .title, .pos-order-view-widget .payment .title, .pos-order-view-widget .customer-info .title, .pos-order-view-widget .transaction-fee-info .title, .pos-order-view-widget .items .title {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.pos-order-view-widget .order-comments .title .icon, .pos-order-view-widget .collection-type .title .icon, .pos-order-view-widget .collection-time .title .icon, .pos-order-view-widget .table-name .title .icon, .pos-order-view-widget .delivery-address .title .icon, .pos-order-view-widget .payment-method .title .icon, .pos-order-view-widget .payment .title .icon, .pos-order-view-widget .customer-info .title .icon, .pos-order-view-widget .transaction-fee-info .title .icon, .pos-order-view-widget .items .title .icon {
  margin-right: 12px;
  font-size: 24px;
}

.pos-order-view-widget .order-comments .title label, .pos-order-view-widget .collection-type .title label, .pos-order-view-widget .collection-time .title label, .pos-order-view-widget .table-name .title label, .pos-order-view-widget .delivery-address .title label, .pos-order-view-widget .payment-method .title label, .pos-order-view-widget .payment .title label, .pos-order-view-widget .customer-info .title label, .pos-order-view-widget .transaction-fee-info .title label, .pos-order-view-widget .items .title label {
  letter-spacing: .7px;
  text-transform: uppercase;
  color: #293340;
  font-family: Fira Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
}

.pos-order-view-widget .table-name, .pos-order-view-widget .collection-type, .pos-order-view-widget .collection-time, .pos-order-view-widget .payment-method, .pos-order-view-widget .payment-status {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  display: flex;
}

.pos-order-view-widget .delivery-address, .pos-order-view-widget .order-comments, .pos-order-view-widget .dropoff-notes {
  grid-row-gap: 8px;
  grid-template-columns: min-content 1fr;
  grid-template-areas: "a b"
                       "c d";
  padding: 16px 20px;
  display: grid;
}

.pos-order-view-widget .delivery-address .icon, .pos-order-view-widget .order-comments .icon, .pos-order-view-widget .dropoff-notes .icon {
  margin-right: 12px;
  font-size: 24px;
}

.pos-order-view-widget .delivery-address label, .pos-order-view-widget .order-comments label, .pos-order-view-widget .dropoff-notes label {
  letter-spacing: .7px;
  text-transform: uppercase;
  color: #293340;
  font-family: Fira Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
}

.pos-order-view-widget .delivery-address i, .pos-order-view-widget .order-comments i, .pos-order-view-widget .dropoff-notes i {
  grid-area: a;
}

.pos-order-view-widget .delivery-address label, .pos-order-view-widget .order-comments label, .pos-order-view-widget .dropoff-notes label {
  grid-area: b;
  align-self: center;
}

.pos-order-view-widget .delivery-address .value, .pos-order-view-widget .order-comments .value, .pos-order-view-widget .dropoff-notes .value {
  grid-area: d;
}

.pos-order-view-widget .order-comments {
  white-space: pre-wrap;
}

.pos-order-view-widget .items {
  margin: 0 5px;
}

.pos-order-view-widget .items > .item-summary-view-widget {
  border-bottom: 1px dashed #e9ecf3;
  margin-bottom: 20px;
  padding: 0 20px 12px;
}

.pos-order-view-widget .items > .item-summary-view-widget:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.pos-order-view-widget .amounts .discount, .pos-order-view-widget .amounts .custom-surcharge, .pos-order-view-widget .amounts .public-holiday-surcharge, .pos-order-view-widget .amounts .delivery-fee, .pos-order-view-widget .amounts .grand-total, .pos-order-view-widget .amounts .sub-total, .pos-order-view-widget .amounts .gst-amount {
  color: #565c65;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  font-family: Fira Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  display: flex;
}

.pos-order-view-widget .amounts .grand-total {
  color: #426ed9;
  border-top: 1px dashed #e9ecf3;
  padding-top: 14px;
  padding-bottom: 14px;
  font-family: Fira Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.pos-order-view-widget .amounts .gst-amount {
  border-top: 1px dashed #e9ecf3;
}

.pos-order-view-widget .payment {
  grid-row-gap: 8px;
  cursor: pointer;
  grid-template-columns: min-content 1fr;
  grid-template-areas: "a b c"
                       "d e e";
  padding: 16px 20px;
  display: grid;
}

.pos-order-view-widget .payment .icon {
  margin-right: 12px;
  font-size: 24px;
}

.pos-order-view-widget .payment label {
  letter-spacing: .7px;
  text-transform: uppercase;
  color: #293340;
  font-family: Fira Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
}

.pos-order-view-widget .payment i {
  grid-area: a;
}

.pos-order-view-widget .payment label {
  grid-area: b;
  align-self: center;
}

.pos-order-view-widget .payment .value {
  grid-area: d;
}

.pos-order-view-widget .payment .icon {
  grid-area: a;
}

.pos-order-view-widget .payment label {
  grid-area: b;
}

.pos-order-view-widget .payment .value {
  grid-area: c;
}

.pos-order-view-widget .payment .status {
  color: #444;
  grid-area: e;
  font-size: 12px;
}

.pos-order-view-widget .payment .payment-card-info {
  flex-direction: row;
  justify-self: end;
  align-items: center;
  display: flex;
}

.pos-order-view-widget .payment .payment-card-info .wallet, .pos-order-view-widget .payment .payment-card-info .brand {
  height: 25px;
  margin-right: 5px;
}

.pos-order-view-widget .payment-summary {
  border-bottom: 1px solid #f1f1f5;
}

.pos-order-view-widget .payment-summary .title {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.pos-order-view-widget .payment-summary .title .icon {
  margin-right: 12px;
  font-size: 24px;
}

.pos-order-view-widget .payment-summary .title label {
  letter-spacing: .7px;
  text-transform: uppercase;
  color: #293340;
  font-family: Fira Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
}

.pos-order-view-widget .payment-summary .payment-summary-header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  display: flex;
}

.pos-order-view-widget .payment-summary .split-payments {
  padding: 0 28px 16px;
}

.pos-order-view-widget .payment-summary .split-payment {
  grid-template-areas: "payment-name payment-status payment-amount"
                       "payment-items payment-items payment-items";
  align-items: center;
  row-gap: 4px;
  margin-top: 12px;
  padding-inline: 12px;
  display: grid;
}

.pos-order-view-widget .payment-summary .split-payment:first-child {
  margin-top: 0;
}

.pos-order-view-widget .payment-summary .split-payment > .name {
  grid-area: payment-name;
  font-size: 16px;
  font-weight: 500;
}

.pos-order-view-widget .payment-summary .split-payment > .status {
  text-align: right;
  grid-area: payment-status;
  font-size: 16px;
  font-weight: 500;
}

.pos-order-view-widget .payment-summary .split-payment > .amount {
  color: #1c1d3e;
  grid-area: payment-amount;
  justify-self: end;
  font-size: 16px;
  font-weight: 500;
}

.pos-order-view-widget .payment-summary .split-payment .payment-items {
  grid-area: payment-items;
  margin-left: 40px;
}

.pos-order-view-widget .payment-summary .split-payment .payment-items > .item {
  margin-top: 4px;
  display: list-item;
}

.pos-order-view-widget .payment-summary .split-payment .payment-items > .item:first-child {
  margin-top: 0;
}

.pos-order-view-widget .customer-info {
  grid-row-gap: 8px;
  grid-template-columns: min-content 1fr;
  grid-template-areas: "a b c"
                       ". . mobile"
                       "d e e";
  padding: 16px 20px;
  display: grid;
}

.pos-order-view-widget .customer-info .icon {
  margin-right: 12px;
  font-size: 24px;
}

.pos-order-view-widget .customer-info label {
  letter-spacing: .7px;
  text-transform: uppercase;
  color: #293340;
  font-family: Fira Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
}

.pos-order-view-widget .customer-info i {
  grid-area: a;
}

.pos-order-view-widget .customer-info label {
  grid-area: b;
  align-self: center;
}

.pos-order-view-widget .customer-info .value {
  grid-area: d;
}

.pos-order-view-widget .customer-info .icon {
  grid-area: a;
}

.pos-order-view-widget .customer-info label {
  grid-area: b;
}

.pos-order-view-widget .customer-info .customer-name {
  text-align: end;
  grid-area: c;
  font-weight: 500;
}

.pos-order-view-widget .customer-info .customer-mobile {
  text-align: end;
  grid-area: mobile;
  font-weight: 500;
}

.pos-order-view-widget .transaction-fee-info {
  grid-row-gap: 8px;
  grid-template-columns: min-content 1fr;
  grid-template-areas: "icon label amount"
                       ". . status"
                       "d e e";
  padding: 16px 20px;
  display: grid;
}

.pos-order-view-widget .transaction-fee-info .icon {
  margin-right: 12px;
  font-size: 24px;
}

.pos-order-view-widget .transaction-fee-info label {
  letter-spacing: .7px;
  text-transform: uppercase;
  color: #293340;
  font-family: Fira Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
}

.pos-order-view-widget .transaction-fee-info i {
  grid-area: a;
}

.pos-order-view-widget .transaction-fee-info label {
  grid-area: b;
  align-self: center;
}

.pos-order-view-widget .transaction-fee-info .value {
  grid-area: d;
}

.pos-order-view-widget .transaction-fee-info .icon {
  grid-area: icon;
}

.pos-order-view-widget .transaction-fee-info label {
  grid-area: label;
}

.pos-order-view-widget .transaction-fee-info .amount {
  text-align: end;
  grid-area: amount;
  font-weight: 500;
}

.pos-order-view-widget .transaction-fee-info .status {
  text-align: end;
  color: #426ed9;
  grid-area: status;
  font-weight: 500;
}

.pos-order-view-widget .footer {
  background: #fafafb;
  padding: 24px 16px;
  position: sticky;
  bottom: 0;
}

.pos-order-view-widget .footer .email-invoice-button {
  margin-left: auto;
  padding: 7px 16px;
  background-color: #0062ff !important;
}

.pos-order-view-widget .footer .email-invoice-button .label {
  letter-spacing: .25px;
  font-size: 12px;
  line-height: 18px;
  color: #fff !important;
}

@media (min-width: 768px) {
  .pos-order-view-widget, .pos-order-view-widget .header {
    width: 40vw;
  }
}

@media (max-width: 767px) {
  .ReactModalPortal:has(.pos-order-view-widget) .ReactModal__Content.ReactModal__Content--after-open {
    width: unset !important;
    position: absolute !important;
  }

  .ReactModalPortal:has(.pos-order-view-widget) .ReactModal__Content.ReactModal__Content--after-open .modal {
    max-width: unset;
    max-height: unset;
  }

  .pos-order-view-widget {
    max-height: 90vh;
  }

  .pos-order-view-widget .header {
    width: 100%;
  }
}

.batch-upload-job-view-widget .info {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  margin-top: 12px;
  padding: 10px 14px;
  box-shadow: 0 0 3px #687a9b40;
}

.batch-upload-job-view-widget .info .title {
  font-weight: 500;
}

.batch-upload-job-view-widget .info .title .value {
  font-weight: 400;
}

.batch-upload-job-view-widget .failed-stores {
  margin: 20px 0;
}

@media (min-width: 1024px) {
  .batch-upload-job-view-widget .failed-stores {
    background-color: var(--card-bg);
    border: 1px solid #e2e2ea;
    border-radius: 10px;
    box-shadow: 0 0 3px #687a9b40;
  }
}

.batch-upload-job-view-widget .failed-stores .no-failed-stores {
  padding: 15px;
}

.batch-upload-job-view-widget .failed-stores .failed-stores-header {
  display: none;
}

@media (min-width: 1024px) {
  .batch-upload-job-view-widget .failed-stores .failed-stores-header {
    grid-gap: 10px;
    background-color: #f3f3f3;
    border-bottom: 1px solid #e2e2ea;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
    padding: 20px 14px;
    font-weight: 600;
    display: grid;
  }
}

.batch-upload-job-view-widget .failed-stores .failed-store-list {
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: row;
  margin: 20px 0;
  padding: 20px 14px;
  display: grid;
}

@media (max-width: 767px) {
  .batch-upload-job-view-widget .failed-stores .failed-store-list {
    background-color: var(--card-bg);
    border: 1px solid #e2e2ea;
    border-radius: 10px;
    box-shadow: 0 0 3px #687a9b40;
  }
}

@media (min-width: 1024px) {
  .batch-upload-job-view-widget .failed-stores .failed-store-list {
    border-bottom: 1px solid #e2e2ea;
    grid-auto-flow: column;
    margin: 0;
    padding: 10px 14px;
  }
}

.batch-upload-job-view-widget .failed-stores .failed-store-list .header {
  margin: 0;
  font-weight: 600;
}

@media (min-width: 1024px) {
  .batch-upload-job-view-widget .failed-stores .failed-store-list .header {
    display: none;
  }
}

.delivery-area-settings-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.delivery-area-settings-widget .spinner-container {
  justify-content: center;
  align-items: center;
  display: flex;
}

.delivery-area-settings-widget form {
  margin-top: 20px;
  padding: 15px 18px;
}

.delivery-area-settings-widget .form-input .delivery-area {
  background-color: var(--card-bg);
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  margin-bottom: 20px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.delivery-area-settings-widget .form-input .delivery-area .info {
  grid-gap: 18px;
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  padding: 20px 15px;
  display: flex;
  box-shadow: 0 2px #c4cbd840;
}

.delivery-area-settings-widget .form-input .delivery-area .info .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.delivery-area-settings-widget .form-input .delivery-area .info .switch label {
  grid-area: a;
  align-self: center;
}

.delivery-area-settings-widget .form-input .delivery-area .info .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.delivery-area-settings-widget .form-input .delivery-area .info .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.delivery-area-settings-widget .form-input .delivery-area .info .switch label {
  text-align: left !important;
}

@media (min-width: 1024px) {
  .delivery-area-settings-widget .form-input .delivery-area .info {
    grid-template-rows: repeat(4, auto);
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "name delivery-charge"
                         "polygon switch"
                         "polygon min-order-value";
    display: grid;
  }

  .delivery-area-settings-widget .form-input .delivery-area .info.third-party {
    grid-template-areas: "name delivery-charge"
                         "third-party-delivery-fee third-party-delivery-fee"
                         "polygon switch"
                         "polygon min-order-value";
  }
}

.delivery-area-settings-widget .form-input .delivery-area .info .name {
  grid-area: name;
}

.delivery-area-settings-widget .form-input .delivery-area .info .delivery-charge {
  grid-area: delivery-charge;
}

.delivery-area-settings-widget .form-input .delivery-area .info .polygon {
  grid-area: polygon;
}

.delivery-area-settings-widget .form-input .delivery-area .info .polygon > .meta > .error {
  color: #f43f5e;
  font-weight: 400;
}

.delivery-area-settings-widget .form-input .delivery-area .info .polygon .invalid textarea {
  border: none;
  outline: 1px solid #f43f5e !important;
}

.delivery-area-settings-widget .form-input .delivery-area .info .switch {
  grid-area: switch;
}

.delivery-area-settings-widget .form-input .delivery-area .info .switch.third-party-delivery-fee {
  grid-area: third-party-delivery-fee;
}

.delivery-area-settings-widget .form-input .delivery-area .info .min-order-value {
  grid-area: min-order-value;
}

.delivery-area-settings-widget .form-input .actions {
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  padding: 5px 12px;
  display: grid;
}

.delivery-area-settings-widget .form-input .actions .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.delivery-area-settings-widget .form-input .actions .action img {
  width: 26px;
  margin-right: 5px;
}

.delivery-area-settings-widget .delivery-areas button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  background-color: #0000;
}

.delivery-areas-form-item > label {
  margin-top: 12px;
  align-self: flex-start !important;
}

.table-edit-widget form {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 24px 20px;
  box-shadow: 0 0 3px #687a9b40;
}

.table-edit-widget form .qr-code {
  grid-template-columns: 180px 4fr 1fr;
  display: grid;
}

.table-edit-widget form .qr-code label {
  justify-self: end;
  margin-right: 15px;
}

.table-edit-widget form .qr-code canvas {
  border: 1px solid var(--form-element-border);
  border-radius: 10px;
}

.table-edit-widget form .form-buttons {
  flex-direction: row;
  align-items: center;
  padding: 20px 0;
  display: flex;
}

.table-edit-widget form .form-buttons button {
  margin-right: 10px;
}

.table-edit-widget form .form-buttons a {
  margin-left: 6px;
  font-family: Poppins, sans-serif;
  font-size: .75rem;
  font-weight: 500;
}

.table-edit-widget form .form-buttons a.cancel {
  color: var(--message-error-fg);
}

.table-edit-widget form .form-buttons .submit-btn {
  padding: 9.5px 32px;
}

.table-edit-widget form .form-buttons .cancel-btn {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  box-shadow: none;
  background-color: #0000;
  border: .5px solid #dadada;
  padding: 9.5px 20px;
}

@media (max-width: 767px) {
  .table-edit-widget form .qr-code {
    flex-direction: column;
    display: flex;
  }
}

#code-editor-widget {
  height: 100%;
  min-height: 70vh;
  width: inherit;
  border: 1px solid #0003;
}

#code-editor-widget .monaco-editor {
  width: inherit !important;
  height: 100% !important;
}

#code-editor-widget .monaco-editor .overflow-guard {
  width: 100% !important;
  height: 100% !important;
}

#code-editor-widget .monaco-editor .lines-content {
  width: 90% !important;
}

#code-editor-widget .monaco-editor .view-lines > div {
  height: 19px;
}

#code-editor-widget .minimap {
  left: 90% !important;
}

.restaurant-website-theme .form-buttons {
  grid-area: 4 / 1 !important;
}

.qr-code-widget {
  justify-content: center;
  display: grid;
}

.qr-code-widget label {
  height: 100%;
}

.qr-code-widget .qr-code {
  border: 1px solid var(--form-element-border);
  height: 100px;
  width: 100px;
  border-radius: 10px;
}

@media (max-width: 767px) {
  .qr-code-widget {
    flex-direction: column;
    display: flex;
  }
}

.store-website-custom-navbar-edit-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.store-website-custom-navbar-edit-widget form {
  margin-top: 20px;
  padding: 15px 18px;
}

.store-website-custom-navbar-edit-widget form .items {
  background-color: var(--card-bg);
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.store-website-custom-navbar-edit-widget form .items .list {
  grid-gap: 18px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 15px;
  display: grid;
  box-shadow: 0 2px #c4cbd840;
}

.store-website-custom-navbar-edit-widget form .items .list .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.store-website-custom-navbar-edit-widget form .items .list .switch label {
  grid-area: a;
  align-self: center;
}

.store-website-custom-navbar-edit-widget form .items .list .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.store-website-custom-navbar-edit-widget form .items .list .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.store-website-custom-navbar-edit-widget form .items .list .switch label {
  text-align: left !important;
}

.store-website-custom-navbar-edit-widget form .items .list .navigation-link {
  flex-direction: row;
  gap: 12px;
  display: flex;
}

.store-website-custom-navbar-edit-widget form .items .list .navigation-link .label, .store-website-custom-navbar-edit-widget form .items .list .navigation-link .href {
  flex-grow: 1;
}

.store-website-custom-navbar-edit-widget form .items .list .navigation-link .target {
  min-width: 100px;
}

.store-website-custom-navbar-edit-widget form .items .list .navigation-link .actions {
  flex-direction: row;
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  display: grid;
}

.store-website-custom-navbar-edit-widget form .items .list .navigation-link .actions .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.store-website-custom-navbar-edit-widget form .items .list .navigation-link .actions .action img {
  width: 26px;
  margin-right: 5px;
}

.store-website-custom-navbar-edit-widget form .items .list .navigation-link .actions .invisible {
  opacity: 0;
}

.store-website-custom-navbar-edit-widget form .items .list.empty {
  color: #6c6c6c;
}

.store-website-custom-navbar-edit-widget form .items .list-actions {
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  padding: 5px 12px;
  display: grid;
}

.store-website-custom-navbar-edit-widget form .items .list-actions .list-action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.store-website-custom-navbar-edit-widget form .items .list-actions .list-action img {
  width: 26px;
  margin-right: 5px;
}

.custom-page {
  background-color: var(--card-bg);
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  margin: 10px 0;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.custom-page .info {
  min-width: 0;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  box-shadow: 0 2px #c4cbd840;
}

.custom-page .name {
  margin: 2px 0;
}

.custom-page .link {
  opacity: .75;
  text-overflow: ellipsis;
  margin: 2px 0;
  font-size: 14px;
  overflow: hidden;
}

.custom-page .page-actions {
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  padding: 5px 12px;
  display: grid;
}

.custom-page .page-actions .page-action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.custom-page .page-actions .page-action img {
  width: 26px;
  margin-right: 5px;
}

.store-website-custom-page-version-edit-widget form {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 15px 18px;
  box-shadow: 0 0 3px #687a9b40;
}

.store-website-custom-page-version-edit-widget form .custom-page-version-code > div {
  height: 70%;
}

.platform-settings-edit-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.platform-settings-edit-widget form {
  padding: 20px 0 0;
}

.platform-settings-edit-widget .form-item {
  padding: 0 15px;
}

.platform-settings-edit-widget .actions {
  background-color: #fafafb;
  border-top: 1px solid #f1f1f5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  flex-direction: row;
  align-items: center;
  padding: 15px 18px;
  display: flex;
}

.platform-settings-edit-widget .actions button {
  margin-right: 10px;
}

.platform-settings-edit-widget .actions a {
  margin-left: 6px;
  font-family: Poppins, sans-serif;
  font-size: .75rem;
  font-weight: 500;
}

.platform-settings-edit-widget .actions a.cancel {
  color: var(--message-error-fg);
}

.platform-settings-edit-widget .actions button {
  margin-right: 10px;
}

@media (max-width: 767px) {
  .new-order-list-widget .table-headers {
    display: none;
  }
}

@media (min-width: 768px) {
  .new-order-list-widget .table-headers {
    display: none;
  }
}

@media (min-width: 1024px) {
  .new-order-list-widget {
    margin-top: 10px;
  }

  .new-order-list-widget .table-headers {
    background-color: var(--card-bg);
    letter-spacing: .1px;
    color: var(--fg);
    background-color: #fafafb;
    border: 1px solid #e2e2ea;
    border-radius: 10px;
    grid-template-rows: 1fr min-content;
    grid-template-columns: 3fr 2fr 1fr 2fr 1fr 1fr;
    column-gap: 10px;
    margin-bottom: 10px;
    padding: 16px 20px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    display: grid;
    box-shadow: 0 0 3px #687a9b40;
  }
}

.new-order-list-item-widget {
  background-color: var(--card-bg);
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  margin-bottom: 10px;
  padding: 20px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.store-name .value {
  letter-spacing: .1px;
  color: var(--fg);
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
}

.order-date-time .text {
  align-self: center;
}

.status.new .value {
  color: #ffab00;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #fef3c7;
  border-radius: 4px;
  padding: 0 10px;
  font-weight: bold;
  display: inline-block;
}

.status.incomplete .value {
  color: #36b37e;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #e0f7e9;
  border-radius: 4px;
  padding: 0 10px;
  font-weight: bold;
  display: inline-block;
}

.view-button {
  justify-content: center;
  display: flex;
}

.view-button button {
  color: #1e75ff;
  width: 100%;
  background-color: #fff;
  border: 1px solid #1e75ff;
}

.restaurant-logo {
  width: 60px;
}

.restaurant-logo .image > img {
  border-radius: 10px;
}

.order-date-time .value .passed-five-minutes {
  color: #e97a6e;
}

@media (max-width: 767px) {
  .new-order-list-item-widget {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    display: flex;
  }

  .store-name {
    grid-area: auto;
  }

  .store-name .title {
    display: none;
  }

  .store-information {
    word-break: break-all;
    grid-area: auto;
  }

  .store-information .title {
    display: none;
  }

  .order-total {
    flex-direction: column;
    grid-area: auto;
    display: flex;
  }

  .order-total .title {
    font-weight: bold;
  }

  .order-date-time {
    flex-direction: column;
    grid-area: auto;
    gap: 10px;
  }

  .order-date-time .title {
    font-weight: bold;
  }

  .order-date-time .value {
    align-items: center;
    gap: 8px;
    display: flex;
  }

  .order-date-time .value .text {
    align-self: center;
  }

  .status {
    flex-direction: column;
    grid-area: auto;
    gap: 10px;
  }

  .status .title {
    font-weight: bold;
  }

  .view-button {
    grid-area: auto;
  }
}

@media (min-width: 768px) {
  .new-order-list-item-widget {
    grid-template-areas: "store-name store-information order-total"
                         "order-time status view";
    gap: 20px;
  }

  .store-name {
    flex-direction: column;
    grid-area: store-name;
    gap: 10px;
    display: flex;
  }

  .store-name .title {
    font-weight: bold;
  }

  .store-information {
    word-break: break-all;
    flex-direction: column;
    grid-area: store-information;
    gap: 10px;
    display: flex;
  }

  .store-information .title {
    font-weight: bold;
  }

  .order-total {
    flex-direction: column;
    grid-area: order-total;
    gap: 10px;
    display: flex;
  }

  .order-total .title {
    font-weight: bold;
  }

  .order-date-time {
    flex-direction: column;
    grid-area: order-time;
    gap: 10px;
  }

  .order-date-time .title {
    font-weight: bold;
  }

  .order-date-time .value {
    align-items: center;
    gap: 8px;
    display: flex;
  }

  .order-date-time .value .text {
    align-self: center;
  }

  .status {
    flex-direction: column;
    grid-area: status;
    gap: 10px;
  }

  .status .title {
    font-weight: bold;
  }

  .view-button {
    grid-area: view;
  }
}

@media (min-width: 1024px) {
  .new-order-list-item-widget {
    grid-template-columns: 3fr 2fr 1fr 2fr 1fr 1fr;
    align-items: center;
    gap: 10px;
  }

  .store-name {
    grid-area: auto;
  }

  .store-name .title {
    display: none;
  }

  .store-information {
    word-break: break-all;
    grid-area: auto;
  }

  .store-information .title {
    display: none;
  }

  .order-total {
    grid-area: auto;
  }

  .order-total .title {
    display: none;
  }

  .order-date-time {
    grid-area: auto;
  }

  .order-date-time .title {
    display: none;
  }

  .order-date-time .text {
    align-self: center;
  }

  .order-date-time .value {
    grid-area: auto;
    align-items: center;
    gap: 8px;
    display: flex;
  }

  .status {
    grid-area: auto;
  }

  .status .title {
    display: none;
  }

  .view-button {
    grid-area: auto;
  }
}

.new-order-details-widget {
  max-width: 440px;
  grid-template-areas: "title"
                       "store-order-details"
                       "actions";
  display: grid;
}

.new-order-details-widget .title {
  grid-area: title;
  justify-content: space-between;
  padding: 16px 20px;
  display: flex;
}

.new-order-details-widget .title .text {
  letter-spacing: .1px;
  color: var(--fg);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.new-order-details-widget .title .close-icon {
  width: 10px;
  cursor: pointer;
}

.new-order-details-widget .store-order-details {
  max-height: 60vh;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  grid-area: store-order-details;
  grid-template-areas: "store-details"
                       "order-details"
                       "order-items"
                       "amounts";
  font-size: 13px;
  font-weight: 300;
  display: grid;
  overflow: auto;
}

.new-order-details-widget .store-order-details::-webkit-scrollbar {
  width: 1px;
}

.new-order-details-widget .store-order-details::-webkit-scrollbar-track {
  background: none;
}

.new-order-details-widget .store-order-details::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.new-order-details-widget .store-order-details .store-details {
  word-break: break-all;
  grid-area: store-details;
  gap: 16px;
  padding: 16px 20px;
  display: flex;
}

.new-order-details-widget .store-order-details .store-details .logo {
  width: 70px;
}

.new-order-details-widget .store-order-details .store-details .logo .image > img {
  border-radius: 10px;
}

.new-order-details-widget .store-order-details .store-details .store-name {
  color: var(--fg);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.new-order-details-widget .store-order-details .order-details {
  background-color: #fafafb;
  flex-direction: column;
  gap: 8px;
  padding: 20px;
  display: flex;
}

.new-order-details-widget .store-order-details .order-details .order-detail {
  grid-template-columns: 180px 1fr;
  display: grid;
}

.new-order-details-widget .store-order-details .order-details .order-detail .label {
  font-weight: 500;
}

.new-order-details-widget .store-order-details .order-details .order-status .badge {
  width: -moz-fit-content;
  width: fit-content;
}

.new-order-details-widget .store-order-details .order-items {
  border-bottom: 1px solid #f0f0f0;
  flex-direction: column;
  grid-area: order-items;
  gap: 10px;
  margin: 0 20px;
  padding: 20px 0 10px;
  display: flex;
}

.new-order-details-widget .store-order-details .order-items .item {
  justify-content: space-between;
  display: flex;
}

.new-order-details-widget .store-order-details .order-items .item .name {
  font-weight: 500;
}

.new-order-details-widget .store-order-details .order-items .item .description {
  letter-spacing: .1px;
  color: #8d92a3;
  white-space: pre-wrap;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.new-order-details-widget .store-order-details .order-items .item .comments {
  letter-spacing: .1px;
  color: #8d92a3;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.new-order-details-widget .store-order-details .amounts {
  flex-direction: column;
  grid-area: amounts;
  gap: 8px;
  padding: 10px 20px;
  display: flex;
}

.new-order-details-widget .store-order-details .amounts > div {
  justify-content: space-between;
  font-weight: 500;
  display: flex;
}

.new-order-details-widget .store-order-details .amounts > div .label, .new-order-details-widget .store-order-details .amounts > div .value {
  color: #6c6c6c;
}

.new-order-details-widget .store-order-details .amounts .grand-total .label, .new-order-details-widget .store-order-details .amounts .grand-total .value {
  color: var(--fg);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.new-order-details-widget .actions {
  background-color: #fafafb;
  border-top: 1px solid #f0f0f0;
  grid-area: actions;
  gap: 10px;
  padding: 20px;
  display: flex;
}

.new-order-details-widget .actions button {
  width: 100%;
  cursor: pointer;
  padding: 9.5px 0;
}

.new-order-details-widget .actions button .label {
  letter-spacing: .1px;
  color: var(--fg);
  color: #f4f4f4;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.new-order-details-widget .actions .reject {
  background-color: #fc5a5a;
}

.new-order-details-widget .actions .accept, .new-order-details-widget .actions .mark-as-complete {
  background-color: #36b37e;
}

.new-order-details-widget .form-single-select-overlay {
  margin-top: -496px;
  margin-left: 242px;
}

.new-order-details-widget .form-single-select-overlay .options {
  grid-gap: 10px;
  width: 84%;
  padding: 10px 12px;
  top: -16px;
  left: -16px;
}

@media (max-width: 767px) {
  .new-order-details-widget .form-single-select-overlay .overlay {
    justify-content: end;
    align-items: end;
    padding: 40% 8%;
  }
}

.new-orders-widget .no-orders {
  text-align: center;
}

.new-orders-widget .headers {
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
  display: flex;
}

.new-orders-widget .tab-categories {
  flex-wrap: wrap;
  gap: 14px;
  margin-bottom: 20px;
  display: flex;
}

.new-orders-widget .tab-categories .tab-category {
  text-align: center;
  cursor: pointer;
  letter-spacing: .1px;
  color: var(--fg);
  color: #1e75ff;
  background-color: #fff;
  border: 1px solid #1e75ff;
  border-radius: 20px;
  padding: 10px 16px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
}

.new-orders-widget .tab-categories .tab-category.active {
  color: #fff;
  background-color: #1e75ff;
}

.new-orders-widget .tab-status {
  flex-wrap: wrap;
  gap: 4px;
  display: flex;
}

.new-orders-widget .tab-status .title {
  text-align: left;
  letter-spacing: .1px;
  color: var(--fg);
  height: -moz-fit-content;
  height: fit-content;
  padding: 10px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
}

.new-orders-widget .tab-status .tab-status-all, .new-orders-widget .tab-status .tab-status-new, .new-orders-widget .tab-status .tab-status-incomplete {
  text-align: left;
  cursor: pointer;
  letter-spacing: .1px;
  color: var(--fg);
  height: -moz-fit-content;
  height: fit-content;
  padding: 10px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
}

.new-orders-widget .tab-status .tab-status-all.active, .new-orders-widget .tab-status .tab-status-new.active, .new-orders-widget .tab-status .tab-status-incomplete.active {
  color: #1e75ff;
  background-color: #f4f4f4;
  border-bottom: 2px solid #1e75ff;
}

.new-orders-widget .load-more-button {
  justify-content: center;
  margin-top: 16px;
  display: flex;
}

.new-orders-widget .load-more-button button {
  color: #1e75ff;
  background-color: #fff;
  border: 1px solid #1e75ff;
}

.ReactModalPortal .ReactModal__Overlay .ReactModal__Content {
  top: unset !important;
  border-radius: none !important;
  background: none !important;
  border: none !important;
}

.android-install-prompt {
  background-color: var(--card-bg);
  z-index: 9999;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  flex-direction: column;
  justify-items: center;
  gap: 10px;
  padding: 32px 20px;
  display: flex;
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  box-shadow: 0 0 3px #687a9b40;
  width: 80vw !important;
}

.android-install-prompt .logo {
  grid-area: logo;
  place-items: center;
  margin-bottom: 20px;
  display: grid;
}

.android-install-prompt .logo img {
  width: 64px;
}

.android-install-prompt .message {
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.android-install-prompt .subtitle {
  text-align: center;
  letter-spacing: .1px;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
}

.android-install-prompt .actions {
  flex-direction: row-reverse;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  display: flex;
}

.android-install-prompt .actions .install-btn {
  color: var(--button-primary-fg);
  background-color: #2563eb;
}

.android-install-prompt .actions .dismiss-btn {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  background-color: #0000;
  border: none;
}

.ReactModalPortal .ReactModal__Overlay .ReactModal__Content {
  top: unset !important;
  border-radius: none !important;
  background: none !important;
  border: none !important;
}

.ios-install-prompt {
  background-color: var(--card-bg);
  z-index: 9999;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  flex-direction: column;
  justify-items: center;
  gap: 10px;
  padding: 32px 20px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 3px #687a9b40;
  width: 80vw !important;
}

.ios-install-prompt .logo {
  grid-area: logo;
  place-items: center;
  margin-bottom: 20px;
  display: grid;
}

.ios-install-prompt .logo img {
  width: 64px;
}

.ios-install-prompt .message {
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.ios-install-prompt .subtitle {
  text-align: center;
  letter-spacing: .1px;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
}

.ios-install-prompt .instructions {
  text-align: center;
  letter-spacing: .1px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3px;
  margin-top: 20px;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  display: flex;
}

.ios-install-prompt .instructions span {
  font-weight: 500;
}

.ios-install-prompt .actions button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  width: 100%;
  background-color: #0000;
  margin-top: 20px;
}

.sunmi-cloud-kitchen-printer-edit-widget {
  padding: 15px 20px;
}

.sunmi-cloud-kitchen-printer-edit-widget .header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 20px 20px;
  display: flex;
}

.sunmi-cloud-kitchen-printer-edit-widget .header .title {
  font-size: 24px;
  font-weight: 600;
}

.sunmi-cloud-kitchen-printer-edit-widget .header .close-button {
  padding: 0 20px;
}

.sunmi-cloud-kitchen-printer-edit-widget .default .form-buttons {
  grid-gap: 10px;
  grid-template-columns: min-content min-content;
  display: grid;
}

.sunmi-cloud-kitchen-printer-edit-widget .default .form-buttons button {
  padding: 11px 27px;
}

.cloud-printer-settings-widget {
  grid-gap: 20px;
  flex-direction: column;
  display: flex;
}

.cloud-printer-settings-widget .title {
  letter-spacing: .1px;
  color: var(--fg);
  letter-spacing: .1px;
  color: var(--fg);
  border-bottom: 1px solid #f1f1f5;
  padding: 12px 18px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.cloud-printer-settings-widget form {
  padding-top: 20px;
}

.cloud-printer-settings-widget form .inline-title > label {
  font-weight: 500;
}

.cloud-printer-settings-widget .document-edit-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.cloud-printer-settings-widget .sub-text {
  color: #696974;
  padding-left: 4px;
  font-size: 12px;
}

.cloud-printer-settings-widget .form-buttons {
  background-color: #fafafb;
  border-top: 1px solid #f1f1f5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 15px 18px;
}

.cloud-printer-settings-widget .form-buttons button {
  padding: 9.5px 32px;
}

.cloud-printer-settings-widget .form-item {
  padding: 0 18px;
}

.print-station-list-widget {
  background-color: var(--card-bg);
  height: min-content;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.print-station-list-widget .title {
  letter-spacing: .1px;
  color: var(--fg);
  letter-spacing: .1px;
  color: var(--fg);
  border-bottom: 1px solid #f1f1f5;
  padding: 12px 18px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.print-station-list-widget .print-stations {
  grid-gap: 20px;
  padding: 20px 18px;
  display: grid;
}

.print-station-list-widget .empty-list {
  padding: 20px;
}

.print-station-list-widget .actions > button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  background-color: #0000;
  margin: 0 18px 20px;
}

.print-station-list-widget .print-station {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.print-station-list-widget .print-station .print-station-info {
  grid-gap: 24px;
  flex-flow: column;
  padding: 24px;
  display: flex;
}

.print-station-list-widget .print-station .print-station-info .name {
  color: var(--fg);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.print-station-list-widget .print-station .print-station-info .id {
  grid-template-columns: 1fr min-content;
  display: grid;
}

.print-station-list-widget .print-station .actions {
  color: #999;
  cursor: pointer;
  background-color: #fafafb;
  border-top: 1px solid #f1f1f5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 15px 18px;
  font-size: 30px;
}

.print-station-list-widget .print-station .actions img {
  width: 26px;
  margin-right: 5px;
}

.print-station-list-widget .print-station .actions .action {
  display: inline;
}

.print-station-edit-widget {
  width: auto;
}

@media (max-width: 767px) {
  .print-station-edit-widget {
    width: unset;
  }
}

.print-station-edit-widget .header {
  grid-template-columns: max-content 1fr min-content;
  grid-template-areas: "title . close-button";
  padding: 20px;
  display: grid;
}

.print-station-edit-widget .header .title {
  letter-spacing: .1px;
  color: var(--fg);
  grid-area: title;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.print-station-edit-widget .header .close-button {
  cursor: pointer;
  grid-area: close-button;
}

.print-station-edit-widget .form-item {
  padding: 0 20px;
}

.print-station-edit-widget .inline-title {
  padding: unset;
}

.print-station-edit-widget .inline-title label {
  font-weight: 500;
}

.print-station-edit-widget .cloud-printing-settings, .print-station-edit-widget .lan-printing-settings, .print-station-edit-widget .usb-printing-settings, .print-station-edit-widget .print-order-settings {
  grid-gap: 20px;
  flex-flow: column;
  display: flex;
}

.print-station-edit-widget .include-unstationed-items, .print-station-edit-widget .include-orphaned-items {
  cursor: pointer;
  padding: 0 20px;
  font-size: .85rem;
  font-weight: 300;
}

.print-station-edit-widget .include-unstationed-items label, .print-station-edit-widget .include-orphaned-items label {
  color: #27283e;
}

.print-station-edit-widget .include-unstationed-items .sub-text, .print-station-edit-widget .include-orphaned-items .sub-text {
  color: #505f79;
  padding-left: 30px;
  font-size: 12px;
}

.print-station-edit-widget input[type="checkbox"] {
  cursor: pointer;
  display: none;
}

.print-station-edit-widget input[type="checkbox"] + label {
  cursor: pointer;
  margin-left: 30px;
  position: relative;
}

.print-station-edit-widget input[type="checkbox"] + label:before {
  content: "";
  cursor: pointer;
  width: 16px;
  height: 16px;
  border: 1px solid #999;
  position: absolute;
  top: -1px;
  left: -28px;
}

.print-station-edit-widget input[type="checkbox"]:checked + label:before {
  background-image: url("icon-check-teal.ae8781fd.svg");
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid #36b37e;
}

.print-station-edit-widget input[type="checkbox"]::placeholder {
  color: #999;
}

.print-station-edit-widget .form-buttons {
  background-color: #fafafb;
  border-top: 1px solid #f1f1f5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 15px 18px;
}

.print-station-edit-widget .form-buttons button {
  grid-gap: 20px;
  margin-right: 8px;
  padding: 9.5px 32px;
  display: inline;
}

.print-station-edit-widget .form-buttons .cancel-btn {
  background-color: var(--button-secondary-bg);
  color: var(--button-secondary-fg);
}

.print-station-edit-widget .help-text {
  color: #4c4e68;
  align-items: center;
  padding: 0 20px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  display: flex;
}

.print-station-edit-widget .help-text .info-icon {
  margin-right: 5px;
  display: flex;
}

.print-station-edit-widget .help-text .info-icon img {
  width: 14.4px;
  height: 14.4px;
}

.delivery-channel-selector-widget {
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "title title"
                       "in-house uber";
  gap: 0 20px;
  padding: 20px;
  display: grid;
}

.delivery-channel-selector-widget .title {
  letter-spacing: .1px;
  color: var(--fg);
  text-align: center;
  grid-area: title;
  margin-bottom: 20px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.delivery-channel-selector-widget .in-house-delivery-option {
  grid-area: in-house;
}

.delivery-channel-selector-widget .uber-direct-option {
  grid-area: uber;
}

.delivery-channel-selector-widget .in-house-delivery-option, .delivery-channel-selector-widget .uber-direct-option {
  letter-spacing: .1px;
  color: var(--fg);
  text-align: center;
  color: #0062ff;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 35px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.delivery-channel-selector-widget .in-house-delivery-option:hover, .delivery-channel-selector-widget .uber-direct-option:hover {
  border: 1px solid #0062ff;
}

.delivery-channel-selector-widget .in-house-delivery-option .icon, .delivery-channel-selector-widget .uber-direct-option .icon {
  width: 45px;
  margin-bottom: 17px;
}

.eta-selector-widget {
  grid-template-columns: 20px 1fr 20px;
  grid-template-areas: "back-btn title ."
                       "etas etas etas"
                       "subtitle subtitle subtitle"
                       "confirm-btn confirm-btn confirm-btn";
  padding: 20px;
  display: grid;
}

.eta-selector-widget .back-btn {
  cursor: pointer;
  grid-area: back-btn;
  padding-top: 8px;
}

.eta-selector-widget .title {
  letter-spacing: .1px;
  color: var(--fg);
  text-align: center;
  grid-area: title;
  margin-bottom: 20px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.eta-selector-widget .etas {
  grid-area: etas;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  display: grid;
}

.eta-selector-widget .etas .eta {
  letter-spacing: .1px;
  color: var(--fg);
  color: #0062ff;
  text-align: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 10px 5px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
}

.eta-selector-widget .etas .eta.selected, .eta-selector-widget .etas .eta:hover {
  border: 1px solid #0062ff;
}

.eta-selector-widget .subtitle {
  letter-spacing: .1px;
  color: var(--fg);
  text-align: center;
  grid-area: subtitle;
  margin-top: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
}

.eta-selector-widget .subtitle > span {
  font-weight: 600;
}

.eta-selector-widget .confirm-btn {
  grid-area: confirm-btn;
  margin-top: 20px;
  font-size: 14px;
}

@media (min-width: 768px) {
  .time-range-widget .horizontal > .form-items {
    flex-wrap: wrap;
    display: flex;
  }

  .time-range-widget .horizontal > .form-items .form-item:first-child {
    min-width: 200px;
  }

  .time-range-widget .horizontal > .form-items .form-item .form-input > .time-picker > .time-input {
    margin-left: 10px;
  }

  .time-range-widget .start-date, .time-range-widget .end-date {
    align-items: end;
    display: flex;
  }
}

@media (max-width: 767px) {
  .time-range-widget .horizontal > .form-items .form-item .form-input > .time-picker > .time-input {
    margin-top: 8px;
  }
}

.time-range-widget .date-range, .time-range-widget .note {
  margin-top: 12px;
  padding-left: 6px;
  font-size: 13px;
  font-style: italic;
}

.basic-item-edit-widget .invalid-print-station, .custom-item-edit-widget .invalid-print-station, .half-and-half-edit-widget .invalid-print-station, .deal-edit-widget .invalid-print-station, .pizza-edit-widget .invalid-print-station {
  color: #fc5a5a;
  border-color: #fc5a5a;
  font-style: italic;
}

.manage-item-routing-widget {
  padding: 8px;
}

.manage-item-routing-widget .store-coverage-selector {
  max-width: 400px;
  margin: 6px 0 20px;
}

.manage-item-routing-widget .overlay > .options-wrapper .options {
  min-width: 355px;
  margin-top: -16px;
  overflow: visible;
}

.manage-item-routing-widget .overlay > .options-wrapper .options .icon {
  display: none;
}

.manage-item-routing-widget .overlay > .options-wrapper .options .selected {
  background-image: url("icon-check-teal.ae8781fd.svg");
  background-position: 100%;
  background-repeat: no-repeat;
}

.manage-item-routing-widget .manage-item-routing-form {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 3px #687a9b40;
}

.manage-item-routing-widget .manage-item-routing-form .form-items {
  grid-row-gap: 0;
}

.manage-item-routing-widget .manage-item-routing-form .form-items .inline-title label {
  letter-spacing: .1px;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}

.manage-item-routing-widget .manage-item-routing-form .items {
  margin-inline: 48px;
  margin-bottom: 10px;
}

.manage-item-routing-widget .manage-item-routing-form .items .item {
  border-bottom: 1px solid #f0f0f0;
  padding-block: 10px;
}

.manage-item-routing-widget .manage-item-routing-form .items .item .label {
  letter-spacing: .1px;
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
}

.manage-item-routing-widget .manage-item-routing-form .items .item .option {
  color: #999;
  background: #fff;
  border: 2px solid #e2e2ea;
}

.manage-item-routing-widget .manage-item-routing-form .items .item .option.selected {
  color: #0062ff;
  background: #eff6ff;
  border: 2px solid #93c5fd;
}

.pos-terminal-settings-edit-widget {
  width: min(1000px, 80vw);
}

.pos-terminal-settings-edit-widget .title {
  border-bottom: 1px solid #f1f1f5;
  padding: 12px 18px;
}

.pos-terminal-settings-edit-widget form .form-items {
  grid-row-gap: 0;
}

.pos-terminal-settings-edit-widget form .form-items .inline-title label {
  letter-spacing: .1px;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}

.pos-terminal-settings-edit-widget form .form-items .switch {
  padding: 8px 18px;
}

.pos-terminal-settings-edit-widget form .form-items .switch label {
  letter-spacing: .1px;
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
}

.pos-terminal-settings-edit-widget form .items {
  margin-bottom: 10px;
}

.pos-terminal-settings-edit-widget form .items .item {
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 20px;
}

.pos-terminal-settings-edit-widget form .items .item .label {
  letter-spacing: .1px;
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
}

.pos-terminal-settings-edit-widget form .items .item .option {
  color: #999;
  background: #fff;
  border: 2px solid #e2e2ea;
}

.pos-terminal-settings-edit-widget form .items .item .option.selected {
  color: #0062ff;
  background: #eff6ff;
  border: 2px solid #93c5fd;
}

.pos-terminal-settings-edit-widget form .form-buttons {
  padding: 0 18px 12px;
}

.pos-trading-hours-edit-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 20px;
  overflow: auto;
  box-shadow: 0 0 3px #687a9b40;
}

.pos-trading-hours-edit-widget .weekly-hours, .pos-trading-hours-edit-widget .daily-hours {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.pos-trading-hours-edit-widget .daily-hours .label {
  letter-spacing: .1px;
  color: var(--fg);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.pos-trading-hours-edit-widget .start-time {
  letter-spacing: .1px;
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
}

.pos-trading-hours-edit-widget .time-period {
  gap: 10px;
  margin-bottom: 20px;
  display: flex;
}

.pos-trading-hours-edit-widget .time-period .meta {
  margin: 10px 0 0;
}

.pos-trading-hours-edit-widget .action {
  color: #999;
  cursor: pointer;
  width: min-content;
  justify-items: center;
  font-size: 30px;
}

.pos-trading-hours-edit-widget .action img {
  width: 26px;
  margin-right: 5px;
}

.time-period-edit-widget > .form-input {
  flex-wrap: wrap;
  gap: 10px;
  display: flex;
}

.time-period-edit-widget > .meta > .error {
  display: none;
}

.time-period-edit-widget .start-time {
  max-width: 300px;
  width: 16vw;
  min-width: max-content;
}

.time-period-edit-widget .start-time .input-box {
  grid-template-columns: repeat(6, max-content) 1fr;
  align-items: center;
  display: grid;
}

.time-period-edit-widget .start-time .input-box .la-clock {
  text-align: right;
}

.time-period-edit-widget .duration input[type="number"] {
  width: 100px;
}

.time-period-edit-widget .end-time-of-day {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
}

.employee-edit-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

@media (min-width: 1024px) {
  .employee-edit-widget {
    min-width: 367px;
  }
}

.employee-edit-widget .header {
  border-bottom: 1px solid #f1f1f5;
  padding: 20px;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}

.employee-edit-widget .form-items {
  grid-row-gap: 0;
}

.employee-edit-widget .form-item {
  margin: 0 20px;
  padding: 10px 0;
}

.employee-edit-widget .form-item > label {
  padding-right: 80px;
}

.employee-edit-widget .form-item.permission {
  border-bottom: 1px solid #dee0e6;
}

.employee-edit-widget .permissions-title {
  letter-spacing: .001em;
  background-color: #f6f8fb;
  margin: 0;
  padding-left: 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.employee-edit-widget .inline-title {
  margin: 0;
}

.employee-edit-widget .form-buttons {
  background-color: #fafafb;
  border-top: 1px solid #f1f1f5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  gap: 8px;
  padding: 15px 18px;
  display: flex;
}

.employee-edit-widget .form-buttons button {
  width: max-content;
  padding: 9.5px 32px;
}

.employee-edit-widget .form-buttons .cancel-btn {
  color: #757575;
  background: #fff;
  border: .5px solid #dadada;
}

.employee-edit-widget .discount-amount-limit {
  margin-top: 0;
}

.employee-edit-widget .discount-amount-limit > .form-input {
  padding-top: 6px;
}

.employee-edit-widget .report-permissions-toggle {
  cursor: pointer;
  letter-spacing: .001em;
  align-items: center;
  padding-left: 24px;
  font-weight: 500;
  line-height: 21px;
  display: flex;
}

.employee-edit-widget .report-permissions-toggle .toggle-icon {
  margin-left: 8px;
}

.employee-edit-widget .report-permissions-toggle .toggle-icon.shown {
  transform: rotate(180deg);
}

.employee-edit-widget .report-permissions {
  padding-left: 20px;
}

.employee-edit-widget .report-permissions .permissions > .form-item > label {
  padding-right: 0;
}

.employee-list-widget .employees {
  gap: 20px;
  display: grid;
}

.employee-list-widget .employees .table-header, .employee-list-widget .employees .employee {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-columns: repeat(4, 1fr);
  place-items: center;
  padding: 16px 20px;
  font-size: 13px;
  line-height: 20px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.employee-list-widget .employees .table-header .name, .employee-list-widget .employees .employee .name {
  justify-self: start;
}

.employee-list-widget .employees .table-header .actions, .employee-list-widget .employees .employee .actions {
  justify-self: end;
}

.employee-list-widget .employees .table-header .actions button, .employee-list-widget .employees .employee .actions button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  background-color: #0000;
  padding: 7px 24px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
}

.employee-list-widget .employees .employee .active-status.active {
  color: #10b981;
}

.employee-list-widget .employees .table-header {
  font-weight: 500;
}

.print-docket-settings-edit-widget {
  grid-gap: 20px;
  height: min-content;
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  flex-direction: column;
  display: flex;
  box-shadow: 0 0 3px #687a9b40;
}

.print-docket-settings-edit-widget .title {
  letter-spacing: .1px;
  color: var(--fg);
  border-bottom: 1px solid #f1f1f5;
  padding: 12px 18px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.print-docket-settings-edit-widget form {
  padding-top: 20px;
}

.print-docket-settings-edit-widget form .inline-title > label {
  font-weight: 500;
}

.print-docket-settings-edit-widget .document-edit-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.print-docket-settings-edit-widget .sub-text {
  color: var(--message-disabled-text);
  padding-left: 4px;
  font-size: 12px;
}

.print-docket-settings-edit-widget .form-buttons {
  background-color: #fafafb;
  border-top: 1px solid #f1f1f5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 15px 18px;
}

.print-docket-settings-edit-widget .form-buttons button {
  padding: 9.5px 32px;
}

.print-docket-settings-edit-widget .form-item {
  padding: 0 18px;
}

.pos-order-delivery-status-widget .delivery-status {
  margin: 15px 0;
  font-weight: 500;
}

.pos-order-delivery-status-widget .delivery-status > .icon {
  width: 18px;
}

.pos-order-delivery-status-widget .delivery-status.in-house {
  color: var(--message-disabled-text);
  display: flex;
}

.pos-order-delivery-status-widget .delivery-status.in-house .label {
  margin-left: 10px;
}

.pos-order-delivery-status-widget .delivery-status.uber {
  display: flex;
}

.pos-order-delivery-status-widget .delivery-status.uber .uber-icon {
  width: 30px;
  margin: 0 10px;
}

.pos-order-delivery-status-widget .delivery-status.uber .label.ongoing {
  color: var(--message-info-fg);
}

.pos-order-delivery-status-widget .delivery-status.uber .label.failed {
  color: var(--message-error-text);
}

.pos-order-delivery-status-widget .delivery-status.uber .label.success {
  color: var(--message-success-text);
}

.order-transaction-report-widget {
  position: relative;
}

.order-transaction-report-widget .summary {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  margin-top: 12px;
  padding: 10px 14px;
  box-shadow: 0 0 3px #687a9b40;
}

.order-transaction-report-widget .summary .statistic .title {
  font-weight: 500;
}

.order-transaction-report-widget .summary .statistic .title .value {
  font-weight: 400;
}

.order-transaction-report-widget .orders {
  margin: 20px 0;
}

@media (min-width: 1024px) {
  .order-transaction-report-widget .orders {
    background-color: var(--card-bg);
    border: 1px solid #e2e2ea;
    border-radius: 10px;
    box-shadow: 0 0 3px #687a9b40;
  }
}

.order-transaction-report-widget .orders h2 {
  padding: 0 15px;
}

.order-transaction-report-widget .orders .orders-table-header {
  display: none;
}

@media (min-width: 1024px) {
  .order-transaction-report-widget .orders .orders-table-header {
    grid-gap: 10px;
    background-color: #f3f3f3;
    border-bottom: 1px solid #e2e2ea;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    grid-auto-flow: row;
    padding: 20px 14px;
    font-weight: 600;
    display: grid;
  }
}

.order-transaction-report-widget .orders .order {
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  grid-auto-flow: row;
  margin: 20px 0;
  padding: 20px 14px;
  display: grid;
}

@media (max-width: 767px) {
  .order-transaction-report-widget .orders .order {
    background-color: var(--card-bg);
    border: 1px solid #e2e2ea;
    border-radius: 10px;
    box-shadow: 0 0 3px #687a9b40;
  }
}

@media (min-width: 1024px) {
  .order-transaction-report-widget .orders .order {
    border-bottom: 1px solid #e2e2ea;
    grid-auto-flow: column;
    margin: 0;
    padding: 10px 14px;
  }
}

.order-transaction-report-widget .orders .order .header {
  margin: 0;
  font-weight: 600;
}

@media (min-width: 1024px) {
  .order-transaction-report-widget .orders .order .header {
    display: none;
  }
}

.order-transaction-report-widget .primary {
  position: absolute;
  top: 25px;
  right: 0;
}

@media (max-width: 767px) {
  .order-transaction-report-widget .primary {
    position: unset;
    margin-bottom: 8px;
    margin-left: auto;
  }
}

.category-sales-report-settings-widget {
  box-sizing: border-box;
  padding: 0;
}

.category-sales-report-settings-widget .actions {
  width: 100%;
  justify-content: space-between;
  display: flex;
}

.category-sales-report-settings-widget .actions > .text-input {
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  padding: 8px 14px;
  font-size: 16px;
  font-weight: 400;
  box-shadow: 0 1px 2px #1c1d3e0d;
}

@media (max-width: 767px) {
  .category-sales-report-settings-widget .actions > .text-input {
    max-width: 40%;
    padding: 8px 14px;
  }
}

.category-sales-report-settings-widget .actions > .text-input > input[type="text"] {
  max-width: 222px;
  background-color: #0000;
  background-image: url("search-icon-gray.72facfa7.svg");
  background-repeat: no-repeat;
  background-size: 16px;
  border: none;
  outline: none;
  padding-left: 30px;
}

.category-sales-report-settings-widget .actions > .text-input > input[type="text"]::placeholder {
  color: #c6c7d1;
}

.category-sales-report-settings-widget .actions > button {
  border-radius: 4px;
  padding: 9.5px 24px;
  font-size: 14px;
}

@media (max-width: 767px) {
  .category-sales-report-settings-widget .actions > button {
    max-width: 45%;
    padding: 6px 12px;
    font-size: 12px;
  }
}

.category-sales-report-settings-widget .no-categories {
  letter-spacing: .005em;
  color: #c6c7d1;
  margin-top: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.category-sales-report-settings-widget .category-sales-list {
  width: 100%;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr;
  margin: 24px 0;
  display: grid;
}

@media (max-width: 767px) {
  .category-sales-report-settings-widget .category-sales-list {
    display: block;
  }
}

.category-sales-widget {
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  padding: 24px 32px;
  position: relative;
}

@media (max-width: 767px) {
  .category-sales-widget {
    margin-bottom: 16px;
    font-size: 18px;
  }
}

.category-sales-widget .category-name {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

@media (max-width: 767px) {
  .category-sales-widget .category-name {
    font-size: 18px;
  }
}

.category-sales-widget .icon {
  filter: invert(.6);
  cursor: pointer;
  font-size: 24px;
  position: absolute;
  top: 24px;
  right: 32px;
}

.category-sales-widget .category-items {
  max-height: 100px;
  width: 100%;
  margin-top: 12px;
  display: block;
  overflow: hidden;
}

.category-sales-widget .category-items .category-item {
  color: #7d7e92;
  max-width: 180px;
  white-space: nowrap;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  background-color: #f6f8fb;
  border-radius: 8px;
  margin: 0 8px 2px 0;
  padding: 2px 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: inline-block;
  overflow: hidden;
}

.category-sales-widget .category-items .category-item.hide {
  display: block;
}

@media (max-width: 767px) {
  .category-sales-widget .category-items .category-item {
    text-wrap: nowrap;
    text-overflow: ellipsis;
    max-width: 40%;
    font-size: 14px;
    overflow: hidden;
  }
}

.category-sales-edit-widget {
  box-sizing: border-box;
  background-color: #0000;
}

.category-sales-edit-widget .header {
  padding: 32px 32px 0;
  position: relative;
}

.category-sales-edit-widget .header .title {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}

.category-sales-edit-widget .header img {
  cursor: pointer;
  position: absolute;
  top: 32px;
  right: 32px;
}

.category-sales-edit-widget .header .help-text {
  letter-spacing: .001em;
  color: #7d7e92;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.category-sales-edit-widget .category-name-input {
  padding: 25px 32px;
}

.category-sales-edit-widget .category-name-input > .text-input {
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  margin-top: 6px;
  padding: 12px 14px;
  box-shadow: 0 1px 2px #1c1d3e0d;
}

.category-sales-edit-widget .category-name-input > .text-input > input[type="text"] {
  width: 80%;
  letter-spacing: 0.5%;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 400;
}

.category-sales-edit-widget .category-name-input > .text-input > input[type="text"]::placeholder {
  color: #c6c7d1;
}

.category-sales-edit-widget .actions {
  background-color: #fafafa;
  padding: 24px;
  display: flex;
}

.category-sales-edit-widget .actions > button {
  text-wrap: nowrap;
  border-radius: 4px;
  line-height: 18px;
}

@media (max-width: 767px) {
  .category-sales-edit-widget .actions > button {
    margin: 0;
    padding: 7px 16px;
    font-size: 12px;
  }
}

.category-sales-edit-widget .actions > button:disabled {
  color: #fff !important;
  background-color: #93c5fd !important;
}

.category-sales-edit-widget .actions > .delete {
  box-shadow: none;
  color: #9f1239;
  background-color: #0000;
  border: none;
  margin-right: auto;
  padding: 0;
  text-decoration: underline;
}

.category-sales-edit-widget .actions > .delete.new-category {
  visibility: hidden;
  pointer-events: none;
}

.category-sales-edit-widget .actions > :last-child {
  margin-left: 16px;
}

.category-sales-edit-widget .item-selector {
  padding: 0 32px 32px;
  display: flex;
}

.category-sales-edit-widget .item-selector > .arrow-icons {
  cursor: pointer;
  flex-direction: column;
  padding: 32px;
  display: flex;
}

@media (max-width: 767px) {
  .category-sales-edit-widget .item-selector > .arrow-icons {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
  }
}

.category-sales-edit-widget .item-selector > .arrow-icons img {
  width: 32px;
  opacity: .2;
  margin-bottom: 24px;
  display: block;
}

@media (max-width: 767px) {
  .category-sales-edit-widget .item-selector > .arrow-icons img {
    margin: 0 24px;
    transform: rotate(90deg);
  }
}

.category-sales-edit-widget .item-selector > .arrow-icons img.active {
  opacity: 1;
}

.category-sales-edit-widget .item-selector .label {
  letter-spacing: .1px;
  color: var(--fg);
  margin-bottom: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.category-sales-edit-widget .item-selector .label > span {
  letter-spacing: .004em;
  margin-left: 12px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.category-sales-edit-widget .item-selector .unassigned-items {
  width: 100%;
}

.category-sales-edit-widget .item-selector .unassigned-items > .label {
  color: #7d7e92;
  font-weight: 400;
}

.category-sales-edit-widget .item-selector .category-items {
  width: 100%;
  border-color: #4cbba1;
}

.category-sales-edit-widget .item-selector .category-items .item-selected-count {
  color: #7d7e92;
}

@media (max-width: 767px) {
  .category-sales-edit-widget .header {
    padding: 20px;
  }

  .category-sales-edit-widget .category-name-input {
    padding: 0 20px;
  }

  .category-sales-edit-widget .item-selector {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    display: flex;
  }
}

.spinner-container {
  flex-direction: column;
  align-items: center;
  padding: 20px;
  display: flex;
}

.category-sales-list-item-widget {
  min-height: 180px;
  max-height: 180px;
  height: min-content;
  max-width: 350px;
  scrollbar-color: #dee0e6 transparent;
  scrollbar-width: thin;
  border: 1px solid #dee0e6;
  border-radius: 12px;
  padding: 0 16px 16px;
  overflow-y: scroll;
}

.category-sales-list-item-widget::-webkit-scrollbar-button {
  display: none;
}

.category-sales-list-item-widget::-webkit-scrollbar-thumb {
  border-radius: 8px;
}

@media (max-width: 767px) {
  .category-sales-list-item-widget {
    max-width: 100%;
  }
}

.category-sales-list-item-widget.assigned-items {
  border-color: #4cbba1;
}

.category-sales-list-item-widget .item {
  cursor: pointer;
  max-width: 280px;
  border-radius: 6px;
  margin-bottom: 2px;
  padding: 4px 8px;
}

@media (max-width: 767px) {
  .category-sales-list-item-widget .item {
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.category-sales-list-item-widget .item.selected {
  background-color: #f6f8fb;
}

.category-sales-list-item-widget > .text-input input {
  background-color: #0000;
  background-image: url("search-icon-gray.72facfa7.svg");
  background-position: 8px 10px;
  background-repeat: no-repeat;
  background-size: 16px;
  border: none;
  outline: none;
  padding: 12px 16px 8px 32px;
}

.category-sales-list-item-widget > .text-input input::placeholder {
  color: #c6c7d1;
}

.category-sales-list-item-widget .placeholder {
  color: #7d7e92;
  padding-top: 18px;
}

.category-sales-list-item-widget::-webkit-scrollbar-thumb {
  background-color: #dee0e6;
  border-radius: 8px;
}

.category-sales-list-item-widget::-webkit-scrollbar {
  width: 4px;
}

.category-sales-list-item-widget::-webkit-scrollbar-track {
  height: 90%;
}

.category-sales-list-item-widget::-webkit-scrollbar-track-piece:end {
  margin-bottom: 8px;
}

.category-sales-list-item-widget::-webkit-scrollbar-track-piece:start {
  margin-top: 8px;
}

.breadcrumbs-widget .breadcrumb {
  color: #7d7e92;
  display: inline;
}

.breadcrumbs-widget .breadcrumb:not(:first-child):before {
  content: url("chevron-right-icon.c977b7dd.svg");
  align-self: center;
  margin: 0 8px;
  position: relative;
  top: 2px;
}

.breadcrumbs-widget .breadcrumb:last-child {
  color: #2563eb;
  cursor: text;
  font-weight: 500;
  text-decoration: none;
}

.loading-widget {
  text-align: center;
  width: 50vw;
  min-width: 310px;
  max-width: 573px;
  box-sizing: border-box;
  border-radius: 10px;
  gap: 16px;
  padding: 32px;
  display: grid;
}

.loading-widget > .title {
  color: #1c1d3e;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.loading-widget > .message {
  color: #4c4e68;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.alert-widget {
  text-align: center;
  width: 50vw;
  min-width: 357px;
  max-width: 573px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  display: flex;
}

.alert-widget > .icon {
  width: 64px;
}

.alert-widget > .title {
  color: #1c1d3e;
  white-space: pre-wrap;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.alert-widget > .content {
  color: #7d7e92;
  margin-top: 16px;
  font-size: 16px;
  font-weight: 400;
}

.alert-widget > .actions {
  width: 100%;
  height: min-content;
  margin-top: 24px;
}

.alert-widget > .actions button {
  color: var(--button-primary-fg);
  width: inherit;
  background-color: #2563eb;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
}

.alert-widget > a {
  color: #3f9c86;
  text-decoration: none;
}

.alert-widget.warning, .alert-widget.info {
  max-width: 397px;
}

.alert-widget.warning > .actions, .alert-widget.info > .actions {
  gap: 16px;
  display: grid;
}

.alert-widget.warning > .actions button, .alert-widget.info > .actions button {
  padding: 14px 32px;
}

.alert-widget.warning > .actions .cancel-button, .alert-widget.info > .actions .cancel-button {
  color: #4c4e68;
  background: none;
  border: 1px solid #7d7e92;
}

.alert-widget.warning > .actions .proceed-button, .alert-widget.info > .actions .proceed-button {
  color: #fff;
  background-color: #9f1239;
}

@media (max-width: 767px) {
  .alert-widget {
    display: flex;
  }

  .alert-widget .actions {
    flex-direction: column;
    display: flex;
  }
}

.manage-store-integration-list-widget > .header {
  display: none;
}

.manage-store-integration-list-widget .store-integrations {
  gap: 12px;
  display: grid;
}

@media (min-width: 1024px) {
  .manage-store-integration-list-widget > .header {
    color: #7d7e92;
    grid-template-columns: 40% 1fr 1fr 100px;
    align-items: center;
    margin-bottom: 12px;
    padding: 0 20px;
    display: grid;
  }

  .manage-store-integration-list-widget .category {
    all: unset;
  }

  .manage-store-integration-list-widget .store-integrations {
    display: grid;
  }

  .manage-store-integration-list-widget .info {
    display: contents;
  }

  .manage-store-integration-list-widget .manage-store-integration-list-item-widget {
    grid-template-columns: 40% 1fr 1fr 100px;
    display: grid;
  }
}

.manage-store-integration-list-item-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.manage-store-integration-list-item-widget .category {
  all: unset;
}

@media (max-width: 1024px) {
  .manage-store-integration-list-item-widget .info {
    grid-template-columns: min-content 1fr;
    grid-template-areas: "icon label"
                         "icon status"
                         "icon category"
                         "action action";
    align-items: center;
    gap: 4px 16px;
    padding: 12px;
    display: grid;
  }

  .manage-store-integration-list-item-widget .info .name {
    display: contents;
  }

  .manage-store-integration-list-item-widget .info .name .icon {
    grid-area: icon;
  }

  .manage-store-integration-list-item-widget .info .name .label {
    grid-area: label;
    font-size: 14px;
  }

  .manage-store-integration-list-item-widget .info .category {
    all: unset;
    color: #7d7e92;
    grid-area: category;
    font-size: 10px;
  }

  .manage-store-integration-list-item-widget .info .category:before {
    content: "Category: ";
  }

  .manage-store-integration-list-item-widget .info .status {
    grid-area: status;
    font-size: 12px;
  }

  .manage-store-integration-list-item-widget .action {
    background-color: #fafafb;
    border-top: 1px solid #f1f1f5;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    grid-area: action;
    padding: 12px 16px;
  }
}

@media (min-width: 1024px) {
  .manage-store-integration-list-item-widget {
    align-items: center;
    padding: 24px 20px;
  }

  .manage-store-integration-list-item-widget > .info > .name {
    align-items: center;
    display: flex;
  }

  .manage-store-integration-list-item-widget > .info > .name > .icon {
    width: 48px;
  }

  .manage-store-integration-list-item-widget > .info > .name > .label {
    margin-left: 12px;
    font-size: 16px;
    font-weight: 500;
  }
}

.manage-store-integration-list-item-widget .status {
  color: #4c4e68;
}

.manage-store-integration-list-item-widget .status.is-integrated {
  color: #3f9c86;
}

.manage-store-integration-list-item-widget .action button {
  box-shadow: none;
  color: #2563eb;
  background: none;
  border: 1px solid #60a5fa;
  border-radius: 4px;
}

.manage-store-integration-list-item-widget .action button.remove-button {
  color: #f43f5e;
  border: 1px solid #f43f5e;
}

.blocked-remove-integration-info-alert {
  width: unset;
}

.blocked-remove-integration-info-alert .content {
  width: 100%;
  margin-top: 0;
}

.blocked-remove-integration-info-alert .content .body {
  max-width: 394px;
}

.blocked-remove-integration-info-alert .actions {
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-top: 24px;
  display: grid;
}

.blocked-remove-integration-info-alert .actions button {
  border-radius: 4px;
  padding: 14px 32px;
  font-size: 16px;
}

.blocked-remove-integration-info-alert .actions .redirect-button {
  color: #fff;
  background-color: #2563eb;
}

.blocked-remove-integration-info-alert .actions .cancel-button {
  box-shadow: none;
  color: #4c4e68;
  background: none;
  outline: 1px solid #7d7e92;
}

.integrations-widget .no-integrations-info {
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin-top: 25vh;
  display: flex;
}

.integrations-widget .no-integrations-info > .icon {
  height: 64px;
  grid-area: icon;
}

.integrations-widget .no-integrations-info .title {
  color: #4c4e68;
  grid-area: title;
  margin-top: 24px;
  font-size: 24px;
  font-weight: 600;
}

.integrations-widget .no-integrations-info .sub-title {
  color: #7d7e92;
  margin-top: 24px;
  font-size: 16px;
}

.integrations-widget .no-integrations-info .sub-title .link {
  color: #2563eb;
  cursor: pointer;
  font-weight: 500;
  display: inline-block;
}

.integrations-widget .no-integrations-info .sub-title .icon {
  display: none;
}

.integrations-widget .integrations {
  grid-template-columns: repeat(auto-fill, 552px);
  gap: 20px;
  display: grid;
}

.integrations-widget .integrations .uber-eats-integration-list-item-widget .enable-integration-action, .integrations-widget .integrations .anz-worldline-integration-list-item-widget .enable-integration-action {
  color: #368875;
}

.integrations-widget .integrations .anz-worldline-integration-list-item-widget .delete-integration-action {
  color: #9f1239;
}

.integrations-list-item-widget {
  background-color: var(--card-bg);
  max-width: 550px;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-columns: 1fr min-content;
  padding: 24px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.integrations-list-item-widget .info {
  grid-template-columns: min-content 1fr;
  grid-template-areas: "logo name"
                       "logo status"
                       "description description";
  gap: 8px 16px;
  display: grid;
}

.integrations-list-item-widget .info .logo {
  grid-area: logo;
}

.integrations-list-item-widget .info .name {
  grid-area: name;
  font-size: 18px;
  font-weight: 500;
}

.integrations-list-item-widget .info .status {
  color: #4c4e68;
  letter-spacing: .035px;
  grid-area: status;
  font-size: 14px;
  line-height: 21px;
}

.integrations-list-item-widget .info .status.is-enabled {
  color: #059669;
}

.integrations-list-item-widget .info .description {
  color: #7d7e92;
  grid-area: description;
  margin-top: 10px;
}

.uber-eats-integration-widget {
  padding-bottom: 40px;
}

.uber-eats-integration-widget > .header {
  grid-template-columns: min-content 1fr max-content;
  grid-template-areas: "logo title status"
                       "logo sub-title sub-title";
  gap: 8px 24px;
  margin: 24px 0;
  display: grid;
}

.uber-eats-integration-widget > .header .logo {
  height: 80px;
  grid-area: logo;
}

.uber-eats-integration-widget > .header .title {
  grid-area: title;
  font-size: 34px;
  font-weight: 600;
  line-height: 40.8px;
}

.uber-eats-integration-widget > .header .sub-title {
  grid-area: sub-title;
}

.uber-eats-integration-widget > .header .status {
  color: #4c4e68;
  grid-area: status;
}

.uber-eats-integration-widget > .header .status.is-enabled {
  color: #059669;
}

.uber-eats-integration-widget > .body > .sub-header {
  font-size: 24px;
  font-weight: 600;
}

.uber-eats-integration-widget > .body .banner-widget, .uber-eats-integration-widget > .body .uber-eats-integration-setup-item-widget {
  margin-top: 24px;
}

.uber-eats-integration-widget > .super-admin-settings {
  margin-top: 56px;
}

.uber-eats-integration-widget > .super-admin-settings > :not(:first-child) {
  margin-top: 24px;
}

.uber-eats-integration-widget > .super-admin-settings > .label {
  white-space: nowrap;
  color: #7d7e92;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  display: flex;
}

.uber-eats-integration-widget > .super-admin-settings > .label > .line {
  height: 1px;
  background-color: #dee0e6;
  flex-grow: 1;
  margin-left: 12px;
}

.uber-eats-integration-widget .uber-eats-integration-action-widget {
  margin-top: 32px;
}

@media (max-width: 767px) {
  .uber-eats-integration-widget > .header {
    grid-template-columns: min-content 1fr;
    grid-template-areas: "logo title"
                         "logo status"
                         "sub-title sub-title";
    align-items: center;
  }
}

.uber-eats-integration-setup-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 32px;
  box-shadow: 0 0 3px #687a9b40;
}

.uber-eats-integration-setup-widget > .header {
  margin-bottom: 32px;
}

.uber-eats-integration-setup-widget > .header .title {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.uber-eats-integration-setup-widget > .header .sub-title {
  margin-top: 8px;
}

.uber-eats-integration-setup-widget .setup-steps {
  gap: 24px;
  display: grid;
}

.uber-eats-integration-setup-widget .setup-steps .uber-eats-authorisation-card-widget button {
  color: var(--button-primary-fg);
  background-color: #2563eb;
  border-radius: 4px;
  padding: 14px 32px;
}

.uber-eats-integration-setup-widget .setup-steps .uber-eats-authorisation-card-widget.is-authorised button {
  box-shadow: none;
  color: #88133a;
  background: none;
  border: none;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
}

.uber-eats-integration-setup-widget .setup-steps .uber-eats-check-menu-card-widget .task button {
  box-shadow: none;
  color: #2563eb;
  background: none;
  border: none;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
}

.uber-eats-integration-setup-widget .setup-steps .uber-eats-check-menu-card-widget .warning {
  color: #9d4c01;
  align-items: center;
  display: flex;
}

.uber-eats-integration-setup-widget .setup-steps .uber-eats-check-menu-card-widget .warning > .icon {
  filter: brightness(0) saturate() invert(20%) sepia(93%) saturate(1797%) hue-rotate(14deg) brightness(96%) contrast(89%);
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uber-eats-integration-setup-widget .setup-steps .uber-eats-check-menu-card-widget .warning > .icon > img {
  height: 100%;
}

.uber-eats-integration-setup-widget .setup-steps .uber-eats-check-menu-card-widget .warning > .text {
  margin-left: 8px;
}

.uber-eats-integration-setup-widget .setup-steps .uber-eats-store-matching-card-widget {
  gap: 24px;
  display: grid;
}

.uber-eats-integration-setup-widget .setup-steps .uber-eats-store-matching-card-widget .task button {
  color: var(--button-primary-fg);
  background-color: #2563eb;
  border-radius: 4px;
  padding: 14px 32px;
}

.uber-eats-integration-setup-widget .setup-steps .uber-eats-store-matching-card-widget .task button.disabled {
  cursor: not-allowed;
  color: #fff !important;
  background-color: #93c5fd !important;
}

.uber-eats-integration-setup-item-widget {
  background-color: var(--card-bg);
  box-shadow: 0 0 3px #687a9b40;
  box-shadow: none;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 24px;
}

.uber-eats-integration-setup-item-widget > .header {
  display: flex;
}

.uber-eats-integration-setup-item-widget > .header > .title {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.uber-eats-integration-setup-item-widget > .content {
  padding-top: 16px;
  font-size: 16px;
}

.uber-eats-store-information-widget .unauthorised-view {
  grid-template-columns: 1fr min-content;
  align-items: center;
  gap: 16px;
  display: grid;
}

.uber-eats-store-information-widget .no-store-information-view .uber-eats-integrate-store-widget {
  margin-top: 32px;
}

.uber-eats-store-information-widget .store-information-view .title {
  border-bottom: 1px solid #dee0e6;
  padding: 12px 0;
  font-size: 16px;
  font-weight: 500;
}

.uber-eats-store-information-widget .store-information-view .info {
  gap: 16px 0;
  padding: 20px 0;
  display: grid;
}

.uber-eats-store-information-widget .store-information-view .key-value-pair {
  grid-template-columns: 120px 1fr;
  gap: 8px;
  display: grid;
}

.uber-eats-store-information-widget .store-information-view .key-value-pair .label {
  color: #7d7e92;
}

@media (max-width: 767px) {
  .uber-eats-store-information-widget .store-information-view .key-value-pair {
    display: unset;
  }

  .uber-eats-store-information-widget .store-information-view .key-value-pair .value {
    word-break: break-all;
  }
}

.uber-eats-integrate-store-widget {
  background: #fafafa;
  grid-template-columns: 1fr max-content;
  grid-template-areas: "sub-header sub-header"
                       "store-selector match-store-button";
  align-items: center;
  gap: 0 64px;
  padding: 20px;
  display: grid;
}

.uber-eats-integrate-store-widget > .sub-header {
  grid-area: sub-header;
  font-size: 16px;
  font-weight: 500;
  display: inline-flex;
}

.uber-eats-integrate-store-widget > .sub-header .store-name {
  color: #1e40af;
  margin-left: 8px;
}

.uber-eats-integrate-store-widget > .form-item {
  grid-area: store-selector;
  margin-top: 16px;
}

.uber-eats-integrate-store-widget > .form-item label {
  font-size: 14px;
}

.uber-eats-integrate-store-widget > .form-item .input-box {
  margin-top: 8px;
  font-size: 16px;
}

.uber-eats-integrate-store-widget > .form-item .input-box > span {
  color: #c6c7d1;
}

.uber-eats-integrate-store-widget .integrate-store-button {
  background-color: #2563eb;
  border-radius: 4px;
  grid-area: match-store-button;
  padding: 9.5px 24px;
  color: #fff !important;
}

.uber-eats-integrate-store-widget .integrate-store-button.disabled {
  background-color: #93c5fd !important;
}

.uber-eats-preview-menu-widget > .header {
  color: #4c4e68;
  justify-content: space-between;
  font-size: 14px;
}

@media (max-width: 767px) {
  .uber-eats-preview-menu-widget > .header {
    display: unset;
  }
}

.uber-eats-preview-menu-widget > .content {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.uber-eats-preview-menu-widget > .content .link {
  cursor: pointer;
  color: #2563eb;
  padding: 9.5px 16px;
  font-weight: 500;
  text-decoration: underline;
}

.uber-eats-preview-menu-widget > .content .link .icon {
  display: none;
}

@media (max-width: 767px) {
  .uber-eats-preview-menu-widget > .content {
    gap: 17.5px;
    display: grid;
  }

  .uber-eats-preview-menu-widget > .content .link {
    padding: unset;
  }
}

.uber-eats-login-button {
  color: #fff;
  white-space: nowrap;
  height: min-content;
  background: #2563eb;
  border-radius: 4px;
  padding: 9.5px 24px;
  font-size: 14px;
  font-weight: 500;
}

.uber-eats-login-button .icon {
  margin: 0;
  font-size: 20px;
}

.manage-add-on-list-widget > .header {
  display: none;
}

.manage-add-on-list-widget .add-ons {
  gap: 12px;
  display: grid;
}

@media (min-width: 1024px) {
  .manage-add-on-list-widget > .header {
    color: #7d7e92;
    grid-template-columns: repeat(2, 1fr) 100px;
    align-items: center;
    padding: 0 20px;
    display: grid;
  }

  .manage-add-on-list-widget .add-ons {
    padding: 12px 0 20px;
  }
}

.manage-add-on-list-item-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

@media (max-width: 1024px) {
  .manage-add-on-list-item-widget .info {
    grid-template-columns: min-content 1fr;
    grid-template-areas: "icon label"
                         "icon status"
                         "action action";
    align-items: center;
    gap: 4px 16px;
    padding: 12px;
    display: grid;
  }

  .manage-add-on-list-item-widget .info .icon {
    grid-area: icon;
  }

  .manage-add-on-list-item-widget .info .label {
    grid-area: label;
    font-size: 14px;
  }

  .manage-add-on-list-item-widget .info .status {
    grid-area: status;
    font-size: 12px;
  }
}

.manage-add-on-list-item-widget .action {
  background-color: #fafafb;
  border-top: 1px solid #f1f1f5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  grid-area: action;
  padding: 12px 16px;
}

@media (min-width: 1024px) {
  .manage-add-on-list-item-widget {
    grid-template-columns: 1fr max-content;
    align-items: center;
    padding: 24px 20px;
    display: grid;
  }

  .manage-add-on-list-item-widget .info {
    grid-template-columns: min-content calc(50% - 48px) 50%;
    align-items: center;
    display: grid;
  }

  .manage-add-on-list-item-widget .info > .label {
    margin-left: 12px;
    font-size: 16px;
    font-weight: 500;
  }

  .manage-add-on-list-item-widget .action {
    all: unset;
    width: 100px;
  }
}

.manage-add-on-list-item-widget .info > .status {
  color: #4c4e68;
}

.manage-add-on-list-item-widget .info > .status.is-enabled {
  color: #3f9c86;
}

.manage-add-on-list-item-widget .action button {
  box-shadow: none;
  color: #2563eb;
  background: none;
  border: 1px solid #60a5fa;
  border-radius: 4px;
}

.manage-add-on-list-item-widget .action button.remove-button {
  color: #f43f5e;
  border: 1px solid #f43f5e;
}

.blocked-remove-store-add-on-info-alert {
  padding: 32px 24px;
}

.blocked-remove-store-add-on-info-alert .content {
  width: 100%;
  margin-top: 0;
}

.blocked-remove-store-add-on-info-alert .content .body {
  color: #4c4e68;
  font-weight: 400;
}

.blocked-remove-store-add-on-info-alert .actions {
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-top: 24px;
  padding: 0 24px;
  display: grid;
}

.blocked-remove-store-add-on-info-alert .actions button {
  border-radius: 4px;
  padding: 14px 32px;
  font-size: 16px;
}

.blocked-remove-store-add-on-info-alert .actions .redirect-button {
  color: #fff;
  background-color: #2563eb;
}

.blocked-remove-store-add-on-info-alert .actions .cancel-button {
  box-shadow: none;
  color: #4c4e68;
  background: none;
  outline: 1px solid #7d7e92;
}

.ReactModalPortal:has(.remove-store-add-on-warning-alert) .ReactModal__Content.ReactModal__Content--after-open .modal > .remove-store-add-on-warning-alert {
  padding: 32px 24px;
}

.ReactModalPortal:has(.remove-store-add-on-warning-alert) .ReactModal__Content.ReactModal__Content--after-open .modal > .remove-store-add-on-warning-alert .actions {
  max-width: 280px;
}

.add-ons-widget .no-add-ons-info {
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin-top: 25vh;
  display: flex;
}

.add-ons-widget .no-add-ons-info > .icon {
  height: 64px;
  grid-area: icon;
}

.add-ons-widget .no-add-ons-info .title {
  color: #4c4e68;
  grid-area: title;
  margin-top: 24px;
  font-size: 24px;
  font-weight: 600;
}

.add-ons-widget .no-add-ons-info .sub-title {
  color: #7d7e92;
  margin-top: 24px;
  font-size: 16px;
}

.add-ons-widget .no-add-ons-info .sub-title .link {
  color: #2563eb;
  cursor: pointer;
  font-weight: 500;
  display: inline-block;
}

.add-ons-widget .no-add-ons-info .sub-title .icon {
  display: none;
}

.add-ons-widget .add-ons {
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  display: grid;
}

@media (min-width: 1024px) {
  .add-ons-widget .add-ons {
    grid-template-columns: repeat(auto-fill, 552px);
  }
}

@media (max-width: 767px) {
  .add-ons-widget .add-ons {
    grid-template-columns: 1fr;
  }
}

.add-on-list-item-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

@media (min-width: 1024px) {
  .add-on-list-item-widget {
    max-width: 550px;
  }
}

.add-on-list-item-widget .info {
  grid-template: "logo name" max-content
                 "logo status" max-content
                 "description description" 1fr
                 / min-content 1fr;
  gap: 8px 16px;
  padding: 15px 18px;
  display: grid;
}

.add-on-list-item-widget .info .logo {
  width: 64px;
  grid-area: logo;
}

.add-on-list-item-widget .info .label {
  grid-area: name;
  font-size: 18px;
  font-weight: 500;
}

.add-on-list-item-widget .info .status {
  color: #4c4e68;
  letter-spacing: .035px;
  grid-area: status;
  font-size: 14px;
  line-height: 21px;
}

.add-on-list-item-widget .info .status.is-enabled {
  color: #059669;
}

.add-on-list-item-widget .info .description {
  color: #7d7e92;
  grid-area: description;
  margin-top: 10px;
}

.add-on-list-item-widget .actions {
  background-color: #f6f8fb;
  border-top: 1px solid #f1f1f5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  flex-grow: 1;
  padding: 15px 18px;
}

.add-on-list-item-widget .actions button {
  color: #2563eb;
  background-color: #0000;
  border: 1px solid #60a5fa;
  border-radius: 4px;
}

.add-on-list-item-widget .actions button.hide {
  visibility: hidden;
}

.swal2-container.unconnect-store-confirmation > .swal2-modal {
  width: 375px;
  border-radius: 20px;
  padding: 30px 20px;
}

.swal2-container.unconnect-store-confirmation > .swal2-modal > .swal2-header > .swal2-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.swal2-container.unconnect-store-confirmation > .swal2-modal > .swal2-content {
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.swal2-container.unconnect-store-confirmation > .swal2-modal > .swal2-actions {
  flex-flow: column;
  padding: 0 25px;
}

.swal2-container.unconnect-store-confirmation > .swal2-modal > .swal2-actions > button {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.swal2-container.unconnect-store-confirmation > .swal2-modal > .swal2-actions > .swal2-confirm {
  background-color: #9f1239;
}

.swal2-container.unconnect-store-confirmation > .swal2-modal > .swal2-actions > .swal2-cancel {
  color: #4c4e68;
  border: 1px solid #7d7e92;
  background-color: #fff !important;
}

.connected-stores-settings-widget {
  padding-bottom: 40px;
}

.connected-stores-settings-widget > .header {
  grid-template-columns: min-content 1fr max-content;
  grid-template-areas: "logo title status"
                       "logo sub-title sub-title";
  gap: 8px 24px;
  margin: 24px 0;
  display: grid;
}

.connected-stores-settings-widget > .header .logo {
  height: 48px;
  grid-area: logo;
}

@media (min-width: 768px) {
  .connected-stores-settings-widget > .header .logo {
    height: 80px;
  }
}

.connected-stores-settings-widget > .header .title {
  grid-area: title;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

@media (min-width: 768px) {
  .connected-stores-settings-widget > .header .title {
    font-size: 34px;
    font-weight: 600;
    line-height: 41px;
  }
}

.connected-stores-settings-widget > .header .sub-title {
  grid-area: sub-title;
  margin-top: 10px;
}

@media (min-width: 768px) {
  .connected-stores-settings-widget > .header .sub-title {
    margin-top: 0;
  }
}

.connected-stores-settings-widget > .header .status {
  color: #4c4e68;
  grid-area: status;
}

.connected-stores-settings-widget > .header .status.is-enabled {
  color: #059669;
}

@media (max-width: 767px) {
  .connected-stores-settings-widget > .header {
    grid-template-columns: min-content 1fr;
    grid-template-areas: "logo title"
                         "logo status"
                         "sub-title sub-title";
    align-items: center;
  }
}

.connected-stores-settings-widget .restaurant-and-store-connector, .connected-stores-settings-widget .connected-stores {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 24px;
  box-shadow: 0 0 3px #687a9b40;
}

.connected-stores-settings-widget .restaurant-and-store-connector > .header, .connected-stores-settings-widget .connected-stores > .header {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.connected-stores-settings-widget .restaurant-and-store-connector {
  margin-bottom: 35px;
}

.connected-stores-settings-widget .restaurant-and-store-connector .store-selection-container {
  background-color: #fafafa;
  border-radius: 10px;
  grid-template-columns: 1fr max-content;
  grid-template-areas: "header connect-store-button"
                       "sub-header connect-store-button"
                       "select-restaurant-dropdown connect-store-button"
                       "select-store-dropdown connect-store-button";
  gap: 0 60px;
  margin-top: 24px;
  padding: 20px;
  display: grid;
}

.connected-stores-settings-widget .restaurant-and-store-connector .store-selection-container > .header {
  grid-area: header;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.connected-stores-settings-widget .restaurant-and-store-connector .store-selection-container > .header > span {
  color: #1e40af;
}

.connected-stores-settings-widget .restaurant-and-store-connector .store-selection-container > .sub-header {
  grid-area: sub-header;
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.connected-stores-settings-widget .restaurant-and-store-connector .store-selection-container > .dropdown {
  display: grid;
}

.connected-stores-settings-widget .restaurant-and-store-connector .store-selection-container > .dropdown > button {
  border-radius: 8px;
  grid-template-columns: 1fr max-content;
  grid-auto-flow: column;
  padding: 10px;
  font-size: 14px;
  display: grid;
}

.connected-stores-settings-widget .restaurant-and-store-connector .store-selection-container > .dropdown > button > .icon {
  margin: 0;
  font-size: 12px;
}

.connected-stores-settings-widget .restaurant-and-store-connector .store-selection-container > .dropdown > button > .label {
  text-align: left;
  grid-column: 1;
}

.connected-stores-settings-widget .restaurant-and-store-connector .store-selection-container > .dropdown > .overlay > .options-wrapper > .options {
  width: -webkit-fill-available;
  font-size: 14px;
}

.connected-stores-settings-widget .restaurant-and-store-connector .store-selection-container > .dropdown > .overlay > .options-wrapper > .options > .search-box input {
  font-size: 14px;
}

.connected-stores-settings-widget .restaurant-and-store-connector .store-selection-container > .dropdown > .overlay > .options-wrapper > .options > .list {
  max-height: 310px;
  overflow: scroll;
}

.connected-stores-settings-widget .restaurant-and-store-connector .store-selection-container > .dropdown > .overlay > .options-wrapper > .options > .list .option:hover {
  background-color: #f4f4f6;
}

.connected-stores-settings-widget .restaurant-and-store-connector .store-selection-container .select-restaurant-dropdown {
  grid-area: select-restaurant-dropdown;
}

.connected-stores-settings-widget .restaurant-and-store-connector .store-selection-container .select-store-dropdown {
  grid-area: select-store-dropdown;
  margin-top: 20px;
}

.connected-stores-settings-widget .restaurant-and-store-connector .store-selection-container > button {
  grid-area: connect-store-button;
  align-self: self-end;
}

.connected-stores-settings-widget .connected-stores .connected-stores-container {
  grid-gap: 10px;
  margin-top: 24px;
  display: grid;
}

.connected-stores-settings-widget .connected-stores .connected-stores-container .connected-store-list-item-widget {
  grid-gap: 0 10px;
  background-color: #fafafa;
  border-radius: 10px;
  grid-template-columns: min-content 1fr min-content;
  padding: 20px;
  display: grid;
}

.connected-stores-settings-widget .connected-stores .connected-stores-container .connected-store-list-item-widget > i {
  color: #9597a7;
  font-size: 18px;
}

.connected-stores-settings-widget .connected-stores .connected-stores-container .connected-store-list-item-widget .store-name {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.connected-stores-settings-widget .connected-stores .connected-stores-container .connected-store-list-item-widget .unconnect-button {
  cursor: pointer;
  color: #9f1239;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-decoration: underline;
}

.connected-stores-settings-widget .no-stores {
  color: #7d7e92;
  font-size: 16px;
}

.sms-marketing-add-on-widget {
  padding-bottom: 40px;
}

.sms-marketing-add-on-widget > .header {
  grid-template-columns: min-content 1fr max-content;
  grid-template-areas: "logo title status"
                       "logo sub-title sub-title";
  gap: 8px 24px;
  margin: 24px 0;
  display: grid;
}

.sms-marketing-add-on-widget > .header .logo {
  height: 48px;
  grid-area: logo;
}

@media (min-width: 768px) {
  .sms-marketing-add-on-widget > .header .logo {
    height: 80px;
  }
}

.sms-marketing-add-on-widget > .header .title {
  grid-area: title;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

@media (min-width: 768px) {
  .sms-marketing-add-on-widget > .header .title {
    font-size: 34px;
    font-weight: 600;
    line-height: 41px;
  }
}

.sms-marketing-add-on-widget > .header .sub-title {
  grid-area: sub-title;
  margin-top: 10px;
}

@media (min-width: 768px) {
  .sms-marketing-add-on-widget > .header .sub-title {
    margin-top: 0;
  }
}

.sms-marketing-add-on-widget > .header .status {
  color: #4c4e68;
  grid-area: status;
}

.sms-marketing-add-on-widget > .header .status.is-enabled {
  color: #059669;
}

@media (max-width: 767px) {
  .sms-marketing-add-on-widget > .header {
    grid-template-columns: min-content 1fr;
    grid-template-areas: "logo title"
                         "logo status"
                         "sub-title sub-title";
    align-items: center;
  }
}

.sms-marketing-add-on-info-widget {
  gap: 20px;
  display: grid;
}

.sms-marketing-add-on-info-widget .sender-informations {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 3px #687a9b40;
}

@media (min-width: 768px) {
  .sms-marketing-add-on-info-widget .sender-informations {
    padding: 30px;
  }
}

.sms-marketing-add-on-info-widget .sender-informations .title {
  color: #1c1d3e;
  margin-bottom: 25px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.sms-marketing-add-on-info-widget .sender-informations .sub-title {
  color: #1c1d3e;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.sms-marketing-add-on-info-widget .sender-informations .informations {
  border-top: 1px solid #dedee3;
  gap: 15px;
  margin-top: 10px;
  padding-top: 10px;
  display: grid;
}

.sms-marketing-add-on-info-widget .sender-informations .informations .information {
  grid-auto-flow: row;
  gap: 5px;
  display: grid;
}

@media (min-width: 768px) {
  .sms-marketing-add-on-info-widget .sender-informations .informations .information {
    grid-template-columns: 20% 80%;
    grid-auto-flow: column;
    gap: 15px;
  }
}

.sms-marketing-add-on-info-widget .sender-informations .informations .information > div {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.sms-marketing-add-on-info-widget .sender-informations .informations .information > div.label {
  color: #7d7e92;
}

.sms-marketing-add-on-info-widget .sender-informations .informations .information > div.value {
  color: #1c1d3e;
}

.sms-marketing-add-on-info-widget .sender-informations .informations .information > div.value .view-terms-link {
  color: #2563eb;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-decoration: underline;
}

.sms-marketing-add-on-info-widget .sender-informations .informations .information > div.value .signed-by {
  color: #7d7e92;
  margin-top: 15px;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 21px;
}

@media (min-width: 768px) {
  .sms-marketing-add-on-info-widget .sender-informations .informations .information > div.value .signed-by {
    margin-top: 5px;
  }
}

.sms-marketing-add-on-info-widget .email-updates {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 3px #687a9b40;
}

@media (min-width: 768px) {
  .sms-marketing-add-on-info-widget .email-updates {
    padding: 30px;
  }
}

.sms-marketing-add-on-info-widget .email-updates .title {
  color: #1c1d3e;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.sms-marketing-add-on-info-widget .email-updates .sub-title {
  color: #4c4e68;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.sms-marketing-add-on-info-widget .email-updates > .sms-marketing-recipient-emails-widget {
  margin: 0;
}

.sms-marketing-add-on-info-widget .email-updates > .sms-marketing-recipient-emails-widget > .form-item {
  margin-top: 25px;
}

.sms-marketing-add-on-info-widget .email-updates > .sms-marketing-recipient-emails-widget > .form-item label {
  margin-left: 0;
}

.sms-marketing-add-on-info-widget .email-updates > .sms-marketing-recipient-emails-widget > .form-item .react-tagsinput, .sms-marketing-add-on-info-widget .email-updates > .sms-marketing-recipient-emails-widget > .emails-container {
  padding-left: 0;
}

.sms-marketing-add-on-info-widget .email-updates > .sms-marketing-recipient-emails-widget > .emails-container .label {
  margin-top: 25px;
}

.sms-marketing-add-on-info-widget .preview-campaigns {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 3px #687a9b40;
}

@media (min-width: 768px) {
  .sms-marketing-add-on-info-widget .preview-campaigns {
    grid-template-columns: 1fr max-content;
    grid-template-areas: "title title"
                         "sub-title go-to-sms-campaigns-link";
    gap: 0 15px;
    padding: 30px;
    display: grid;
  }
}

.sms-marketing-add-on-info-widget .preview-campaigns .title {
  color: #1c1d3e;
  grid-area: title;
  margin-bottom: 25px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.sms-marketing-add-on-info-widget .preview-campaigns .sub-title {
  color: #1c1d3e;
  grid-area: sub-title;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

@media (min-width: 768px) {
  .sms-marketing-add-on-info-widget .preview-campaigns .sub-title {
    margin-bottom: 0;
  }
}

.sms-marketing-add-on-info-widget .preview-campaigns .go-to-sms-campaigns-link {
  color: #2563eb;
  grid-area: go-to-sms-campaigns-link;
  justify-self: flex-end;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-decoration: underline;
}

.sms-marketing-add-on-info-widget .unlink-sender-id {
  color: #9f1239;
  cursor: pointer;
  margin: 20px 15px 40px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-decoration: underline;
}

.swal2-container.cancel-scheduled-sms .swal2-popup {
  width: auto;
}

.swal2-container.cancel-scheduled-sms .swal2-icon {
  height: 56px;
  width: 56px;
  color: #f59e0b;
  border-color: #f59e0b;
}

.swal2-container.cancel-scheduled-sms .swal2-icon .swal2-icon-content {
  font-size: 45px;
}

.swal2-container.cancel-scheduled-sms .swal2-title {
  letter-spacing: .005em;
  color: #4c4e68;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.swal2-container.cancel-scheduled-sms .swal2-actions {
  flex-direction: column-reverse;
}

@media (min-width: 1024px) {
  .swal2-container.cancel-scheduled-sms .swal2-actions {
    flex-direction: row-reverse;
  }

  .swal2-container.cancel-scheduled-sms .swal2-actions button {
    min-width: 220px;
  }
}

.link-existing-sender-id-modal {
  grid-template-columns: 1fr max-content;
  grid-template-areas: "title close-icon"
                       "form form";
  gap: 0 40px;
  padding-top: 20px;
  display: grid;
  border: none !important;
}

.link-existing-sender-id-modal .title {
  grid-area: title;
  padding-left: 20px;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}

.link-existing-sender-id-modal .close-icon {
  cursor: pointer;
  grid-area: close-icon;
  align-self: center;
  padding-right: 20px;
}

.link-existing-sender-id-modal .link-sender-id.form {
  grid-area: form;
  margin-top: 10px;
}

.link-existing-sender-id-modal .link-sender-id.form .form-item {
  margin-top: 10px;
  padding: 0 20px;
}

.link-existing-sender-id-modal .link-sender-id.form .form-item > label {
  color: #1c1d3e;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.link-existing-sender-id-modal .link-sender-id.form .actions {
  background-color: #fafafa;
  border-top: 1px solid #f4f4f6;
  grid-template-columns: repeat(2, max-content);
  grid-auto-flow: column;
  gap: 10px;
  margin-top: 20px;
  padding: 20px;
  display: grid;
}

.link-existing-sender-id-modal .link-sender-id.form .actions > button {
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
}

.link-existing-sender-id-modal .link-sender-id.form .actions > button .label {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.link-existing-sender-id-modal .link-sender-id.form .actions .cancel-button {
  color: #1c1d3e;
}

.sms-marketing-add-on-setup-widget .twilio-integration-setup-label {
  margin-bottom: 24px;
}

.sms-marketing-add-on-setup-widget .twilio-integration-setup-label .title {
  color: #1c1d3e;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

@media (min-width: 768px) {
  .sms-marketing-add-on-setup-widget .twilio-integration-setup-label .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
  }
}

.sms-marketing-add-on-setup-widget .twilio-integration-setup-label .sub-title {
  color: #4c4e68;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

@media (min-width: 768px) {
  .sms-marketing-add-on-setup-widget .twilio-integration-setup-label .sub-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

.sms-marketing-add-on-setup-widget .integration-is-almost-ready-banner {
  border-radius: 10px;
  margin-bottom: 24px;
  padding: 20px 30px;
}

.sms-marketing-add-on-setup-widget .integration-is-almost-ready-banner .message > .bold {
  font-weight: 500;
}

.sms-marketing-add-on-setup-widget .initial-setup-requirements {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 0 3px #687a9b40;
}

.sms-marketing-add-on-setup-widget .initial-setup-requirements .title {
  color: #1c1d3e;
  align-content: center;
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.sms-marketing-add-on-setup-widget .initial-setup-requirements .steps {
  gap: 20px;
  margin-bottom: 20px;
  display: grid;
}

.sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .agree-to-terms-and-conditions-step {
  border-radius: 10px;
  grid-template-columns: min-content 1fr max-content;
  grid-template-areas: "icon title title"
                       "icon sub-title sub-title"
                       ". . action"
                       "form form form";
  gap: 0 20px;
  padding: 15px;
  display: grid;
}

@media (min-width: 768px) {
  .sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .agree-to-terms-and-conditions-step {
    padding: 25px 20px;
  }
}

@media (min-width: 1024px) {
  .sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .agree-to-terms-and-conditions-step {
    grid-template-areas: "icon title action"
                         "icon sub-title action"
                         "form form form";
  }
}

.sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .agree-to-terms-and-conditions-step.current {
  background-color: #fff;
  border: 2px solid #4cbba1;
}

.sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .agree-to-terms-and-conditions-step.done {
  background-color: #ecfdf5;
  border: 2px solid #ecfdf5;
}

.sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .agree-to-terms-and-conditions-step.done > .icon {
  cursor: not-allowed;
}

.sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .agree-to-terms-and-conditions-step.disabled {
  opacity: .5;
  pointer-events: none;
  border: 2px solid #dee0e6;
}

.sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .agree-to-terms-and-conditions-step .icon {
  cursor: pointer;
  grid-area: icon;
}

.sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .agree-to-terms-and-conditions-step .title {
  color: #1c1d3e;
  grid-area: title;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

@media (min-width: 768px) {
  .sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .agree-to-terms-and-conditions-step .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }
}

.sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .agree-to-terms-and-conditions-step .sub-title {
  color: #4c4e68;
  grid-area: sub-title;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

@media (min-width: 768px) {
  .sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .agree-to-terms-and-conditions-step .sub-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

.sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .agree-to-terms-and-conditions-step .action {
  grid-area: action;
  grid-auto-flow: column;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  display: grid;
}

@media (min-width: 1024px) {
  .sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .agree-to-terms-and-conditions-step .action {
    margin-top: 0;
    margin-right: 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
  }
}

.sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .agree-to-terms-and-conditions-step .action > a {
  color: #2563eb;
}

.sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .agree-to-terms-and-conditions-step .form {
  grid-area: form;
}

.sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .agree-to-terms-and-conditions-step .sub-title > .signed-by {
  color: #3f9c86;
  margin-top: 5px;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  line-height: 21px;
}

.sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .recipient-emails-step {
  border-radius: 10px;
  grid-template-columns: min-content 1fr max-content;
  grid-template-areas: "icon title title"
                       "icon sub-title sub-title"
                       ". . action"
                       "form form form";
  gap: 0 20px;
  padding: 15px;
  display: grid;
}

@media (min-width: 768px) {
  .sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .recipient-emails-step {
    padding: 25px 20px;
  }
}

@media (min-width: 1024px) {
  .sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .recipient-emails-step {
    grid-template-areas: "icon title action"
                         "icon sub-title action"
                         "form form form";
  }
}

.sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .recipient-emails-step.current {
  background-color: #fff;
  border: 2px solid #4cbba1;
}

.sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .recipient-emails-step.done {
  background-color: #ecfdf5;
  border: 2px solid #ecfdf5;
}

.sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .recipient-emails-step.done > .icon {
  cursor: not-allowed;
}

.sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .recipient-emails-step.disabled {
  opacity: .5;
  pointer-events: none;
  border: 2px solid #dee0e6;
}

.sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .recipient-emails-step .icon {
  cursor: pointer;
  grid-area: icon;
}

.sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .recipient-emails-step .title {
  color: #1c1d3e;
  grid-area: title;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

@media (min-width: 768px) {
  .sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .recipient-emails-step .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }
}

.sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .recipient-emails-step .sub-title {
  color: #4c4e68;
  grid-area: sub-title;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

@media (min-width: 768px) {
  .sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .recipient-emails-step .sub-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

.sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .recipient-emails-step .action {
  grid-area: action;
  grid-auto-flow: column;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  display: grid;
}

@media (min-width: 1024px) {
  .sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .recipient-emails-step .action {
    margin-top: 0;
    margin-right: 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
  }
}

.sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .recipient-emails-step .action > a {
  color: #2563eb;
}

.sms-marketing-add-on-setup-widget .initial-setup-requirements .steps .recipient-emails-step .form {
  grid-area: form;
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  margin-top: 24px;
  padding: 30px;
  box-shadow: 0 0 3px #687a9b40;
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration .header {
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .sms-marketing-add-on-setup-widget .twilio-sender-id-registration .header {
    grid-template-columns: 1fr max-content;
    grid-template-areas: "title button";
    display: grid;
  }
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration .header .title {
  color: #1c1d3e;
  grid-area: title;
  align-content: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration .header .link-existing-sender-id-button {
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 5px;
  grid-area: button;
  align-self: center;
  margin-top: 10px;
  padding: 10px 20px;
}

@media (min-width: 768px) {
  .sms-marketing-add-on-setup-widget .twilio-sender-id-registration .header .link-existing-sender-id-button {
    margin-top: 0;
  }
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration .header .link-existing-sender-id-button .label {
  grid-auto-flow: column;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  display: grid;
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration .steps {
  gap: 20px;
  margin-bottom: 20px;
  display: grid;
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration .steps .step {
  border-radius: 10px;
  grid-template-columns: min-content 1fr max-content;
  grid-template-areas: "icon title title"
                       "icon sub-title sub-title"
                       ". . action"
                       "form form form";
  gap: 0 20px;
  padding: 15px;
  display: grid;
}

@media (min-width: 768px) {
  .sms-marketing-add-on-setup-widget .twilio-sender-id-registration .steps .step {
    padding: 25px 20px;
  }
}

@media (min-width: 1024px) {
  .sms-marketing-add-on-setup-widget .twilio-sender-id-registration .steps .step {
    grid-template-areas: "icon title action"
                         "icon sub-title action"
                         "form form form";
  }
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration .steps .step.current {
  background-color: #fff;
  border: 2px solid #4cbba1;
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration .steps .step.done {
  background-color: #ecfdf5;
  border: 2px solid #ecfdf5;
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration .steps .step.done > .icon {
  cursor: not-allowed;
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration .steps .step.disabled {
  opacity: .5;
  pointer-events: none;
  border: 2px solid #dee0e6;
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration .steps .step .icon {
  cursor: pointer;
  grid-area: icon;
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration .steps .step .title {
  color: #1c1d3e;
  grid-area: title;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

@media (min-width: 768px) {
  .sms-marketing-add-on-setup-widget .twilio-sender-id-registration .steps .step .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration .steps .step .sub-title {
  color: #4c4e68;
  grid-area: sub-title;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

@media (min-width: 768px) {
  .sms-marketing-add-on-setup-widget .twilio-sender-id-registration .steps .step .sub-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration .steps .step .action {
  grid-area: action;
  grid-auto-flow: column;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  display: grid;
}

@media (min-width: 1024px) {
  .sms-marketing-add-on-setup-widget .twilio-sender-id-registration .steps .step .action {
    margin-top: 0;
    margin-right: 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
  }
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration .steps .step .action > a {
  color: #2563eb;
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration .steps .step .form {
  grid-area: form;
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration .link-sender-id-step {
  border-radius: 10px;
  grid-template-columns: min-content 1fr max-content;
  grid-template-areas: "icon title title"
                       "icon sub-title sub-title"
                       ". . action"
                       "form form form";
  gap: 0 20px;
  padding: 15px;
  display: grid;
}

@media (min-width: 768px) {
  .sms-marketing-add-on-setup-widget .twilio-sender-id-registration .link-sender-id-step {
    padding: 25px 20px;
  }
}

@media (min-width: 1024px) {
  .sms-marketing-add-on-setup-widget .twilio-sender-id-registration .link-sender-id-step {
    grid-template-areas: "icon title action"
                         "icon sub-title action"
                         "form form form";
  }
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration .link-sender-id-step.current {
  background-color: #fff;
  border: 2px solid #4cbba1;
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration .link-sender-id-step.done {
  background-color: #ecfdf5;
  border: 2px solid #ecfdf5;
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration .link-sender-id-step.done > .icon {
  cursor: not-allowed;
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration .link-sender-id-step.disabled {
  opacity: .5;
  pointer-events: none;
  border: 2px solid #dee0e6;
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration .link-sender-id-step .icon {
  cursor: pointer;
  grid-area: icon;
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration .link-sender-id-step .title {
  color: #1c1d3e;
  grid-area: title;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

@media (min-width: 768px) {
  .sms-marketing-add-on-setup-widget .twilio-sender-id-registration .link-sender-id-step .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration .link-sender-id-step .sub-title {
  color: #4c4e68;
  grid-area: sub-title;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

@media (min-width: 768px) {
  .sms-marketing-add-on-setup-widget .twilio-sender-id-registration .link-sender-id-step .sub-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration .link-sender-id-step .action {
  grid-area: action;
  grid-auto-flow: column;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  display: grid;
}

@media (min-width: 1024px) {
  .sms-marketing-add-on-setup-widget .twilio-sender-id-registration .link-sender-id-step .action {
    margin-top: 0;
    margin-right: 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
  }
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration .link-sender-id-step .action > a {
  color: #2563eb;
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration .link-sender-id-step .form {
  grid-area: form;
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration .link-sender-id-step .link-sender-id.form {
  background-color: #fafafa;
  border-radius: 5px;
  gap: 15px;
  margin: 20px;
  padding: 20px;
  display: grid;
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration .link-sender-id-step .link-sender-id.form .form-item {
  display: grid;
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration .link-sender-id-step .link-sender-id.form .form-item > label {
  color: #1c1d3e;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration .link-sender-id-step .link-sender-id.form .link-sender-id-button {
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
  margin-top: 5px;
}

.sms-marketing-add-on-setup-widget .twilio-sender-id-registration .link-sender-id-step .link-sender-id.form .link-sender-id-button .label {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.terminate-agreement-modal {
  padding: 32px 24px;
}

@media (min-width: 768px) {
  .terminate-agreement-modal {
    max-width: 540px;
  }
}

.terminate-agreement-modal .header {
  text-align: center;
  color: #1c1d3e;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.terminate-agreement-modal .body {
  margin: 20px 0;
}

.terminate-agreement-modal .body .notes {
  margin: 0 20px 20px;
}

.terminate-agreement-modal .body .notes .note {
  color: #1c1d3e;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.terminate-agreement-modal .body .signed-by-form {
  gap: 10px 15px;
  display: grid;
}

@media (min-width: 768px) {
  .terminate-agreement-modal .body .signed-by-form {
    grid-template-columns: repeat(2, 1fr);
  }

  .terminate-agreement-modal .body .signed-by-form > .full-name {
    grid-column: span 2;
  }
}

.terminate-agreement-modal .body .signed-by-form > .form-item > label {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.terminate-agreement-modal .body .signed-by-form > .form-item > .form-input {
  margin-top: 5px;
}

.terminate-agreement-modal .actions {
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: column;
  gap: 15px;
  margin: 0 20px;
  display: grid;
}

.terminate-agreement-modal .actions > button {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.terminate-agreement-modal .actions .go-back-button {
  color: #4c4e68;
  box-shadow: none;
  background-color: #fff;
  border: 1px solid #d3d3d9;
  border-radius: 4px;
}

.sms-marketing-terms-and-conditions-widget {
  max-width: 800px;
  max-height: 65vh;
}

.sms-marketing-terms-and-conditions-widget .header {
  z-index: 1;
  width: calc(100% - 36px);
  background-color: #fff;
  border-bottom: 1px solid #dee0e6;
  border-radius: 10px 10px 0 0;
  grid-template-columns: 1fr min-content;
  grid-auto-flow: column;
  padding: 17px;
  display: grid;
  position: fixed;
}

.sms-marketing-terms-and-conditions-widget .header .label {
  color: #1c1d3e;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}

.sms-marketing-terms-and-conditions-widget .header .close-icon {
  width: 35px;
  cursor: pointer;
  align-self: center;
}

@media (min-width: 768px) {
  .sms-marketing-terms-and-conditions-widget .header {
    position: absolute;
  }
}

.sms-marketing-terms-and-conditions-widget .body {
  padding: 90px 20px;
}

.sms-marketing-terms-and-conditions-widget .body .terms-and-conditions > .title {
  color: #1c1d3e;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.sms-marketing-terms-and-conditions-widget .body .terms-and-conditions .last-updated-date-label {
  color: #7d7e92;
  margin: 10px 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.sms-marketing-terms-and-conditions-widget .body .terms-and-conditions .links {
  padding: 10px 0;
  display: grid;
}

.sms-marketing-terms-and-conditions-widget .body .terms-and-conditions .links > .link {
  margin-bottom: 10px;
}

.sms-marketing-terms-and-conditions-widget .body .terms-and-conditions .link {
  color: #2563eb;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-decoration: none;
  display: flex;
}

.sms-marketing-terms-and-conditions-widget .body .terms-and-conditions .link .bullet {
  color: #7d7e92;
  margin-right: 10px;
  font-size: 20px;
}

.sms-marketing-terms-and-conditions-widget .body .terms-and-conditions .link > img {
  margin-left: 3px;
}

.sms-marketing-terms-and-conditions-widget .body .terms-and-conditions .link .label {
  text-decoration: underline;
}

.sms-marketing-terms-and-conditions-widget .body .terms-and-conditions > .sub-title {
  color: #1c1d3e;
  margin: 15px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.sms-marketing-terms-and-conditions-widget .body .terms-and-conditions .temrs {
  color: #1c1d3e;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.sms-marketing-terms-and-conditions-widget .body .signed-by-labels, .sms-marketing-terms-and-conditions-widget .body .signed-by-form {
  gap: 10px 15px;
  margin-top: 20px;
  display: grid;
}

@media (min-width: 768px) {
  .sms-marketing-terms-and-conditions-widget .body .signed-by-labels, .sms-marketing-terms-and-conditions-widget .body .signed-by-form {
    grid-template-columns: repeat(2, 1fr);
  }

  .sms-marketing-terms-and-conditions-widget .body .signed-by-labels > .full-name, .sms-marketing-terms-and-conditions-widget .body .signed-by-form > .full-name {
    grid-column: span 2;
  }
}

.sms-marketing-terms-and-conditions-widget .body .signed-by-labels > .form-item > label, .sms-marketing-terms-and-conditions-widget .body .signed-by-form > .form-item > label {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.sms-marketing-terms-and-conditions-widget .body .signed-by-labels > .form-item > .form-input, .sms-marketing-terms-and-conditions-widget .body .signed-by-form > .form-item > .form-input {
  margin-top: 5px;
}

.sms-marketing-terms-and-conditions-widget .body .signed-by-labels > .signed-by > .label, .sms-marketing-terms-and-conditions-widget .body .signed-by-form > .signed-by > .label {
  color: #7d7e92;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.sms-marketing-terms-and-conditions-widget .body .signed-by-labels > .signed-by > .value, .sms-marketing-terms-and-conditions-widget .body .signed-by-form > .signed-by > .value {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.sms-marketing-terms-and-conditions-widget .actions {
  width: calc(100% - 36px);
  background-color: #fafafa;
  border-top: 1px solid #dee0e6;
  border-radius: 0 0 10px 10px;
  grid-template-columns: 1fr max-content;
  grid-auto-flow: column;
  gap: 15px;
  padding: 17px;
  display: grid;
  position: fixed;
  bottom: 0;
}

@media (min-width: 768px) {
  .sms-marketing-terms-and-conditions-widget .actions {
    position: absolute;
  }
}

.sms-marketing-terms-and-conditions-widget .actions .cancel-button, .sms-marketing-terms-and-conditions-widget .actions .close-button {
  color: #1c1d3e;
  box-shadow: none;
  background-color: #dee0e6;
  border-radius: 4px;
}

.sms-marketing-terms-and-conditions-widget .actions > button {
  justify-self: flex-end;
}

.sms-marketing-recipient-emails-widget.form {
  grid-auto-flow: row;
  gap: 20px;
  margin-top: 20px;
  margin-left: 56px;
  display: grid;
}

@media (min-width: 1024px) {
  .sms-marketing-recipient-emails-widget.form {
    grid-template-columns: 1fr max-content;
    grid-auto-flow: column;
  }
}

.sms-marketing-recipient-emails-widget.form.error > .form-item > .meta > .help {
  color: #f43f5e;
}

.sms-marketing-recipient-emails-widget.form .react-tagsinput-tag {
  color: #4c4e68;
  background-color: #f4f4f6;
}

.sms-marketing-recipient-emails-widget.form .react-tagsinput-tag:last-of-type {
  margin-bottom: 15px !important;
}

.sms-marketing-recipient-emails-widget.form .react-tagsinput-tag > .react-tagsinput-remove {
  color: #4c4e68;
  font-size: 22px;
  font-weight: 300;
  line-height: 0;
  position: relative;
  top: 4px;
}

.sms-marketing-recipient-emails-widget.form > .form-item label {
  color: #7d7e92;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.sms-marketing-recipient-emails-widget.form > .form-item .meta > .help {
  color: #4c4e68;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.sms-marketing-recipient-emails-widget.form .actions {
  justify-self: end;
}

@media (min-width: 1024px) {
  .sms-marketing-recipient-emails-widget.form .actions {
    align-self: end;
    margin-bottom: 26px;
  }
}

.sms-marketing-recipient-emails-widget.form .actions > .confirm-recipients-button {
  color: #2563eb;
  background-color: #fff;
  border: 1px solid #60a5fa;
}

.sms-marketing-recipient-emails-widget.form .emails-container {
  padding-left: 5px;
}

.sms-marketing-recipient-emails-widget.form .emails-container .label {
  color: #4c4e68;
  grid-template-columns: repeat(2, min-content);
  grid-auto-flow: column;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: grid;
}

.sms-marketing-recipient-emails-widget.form .emails-container .label > img {
  cursor: pointer;
  margin-left: 5px;
}

.sms-marketing-recipient-emails-widget.form .emails-container > .emails {
  gap: 10px;
  margin-top: 10px;
  display: flex;
}

.sms-marketing-recipient-emails-widget.form .emails-container > .emails .email {
  color: #4c4e68;
  border: 1px solid #d3d3d9;
  border-radius: 4px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.sms-marketing-campaign-widget .header {
  justify-content: space-between;
  align-items: center;
  margin: 28px 0;
  display: flex;
}

.sms-marketing-campaign-widget .header .campaign-type {
  display: flex;
}

.sms-marketing-campaign-widget .header .campaign-type button {
  color: #1c1d3e;
  box-shadow: unset;
  border: none;
  outline: none;
  margin-right: 12px;
  background-color: #0000 !important;
}

.sms-marketing-campaign-widget .header .campaign-type button.active {
  background-color: #dedee3 !important;
}

.sms-marketing-campaign-widget .campaign-list-header {
  grid-gap: 8px;
  text-transform: uppercase;
  letter-spacing: .0025em;
  color: #7d7e92;
  grid-template-columns: 2fr 1fr 1.3fr 1.3fr 2fr 48px;
  grid-auto-flow: column;
  align-items: center;
  padding: 8px 24px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  display: grid;
}

.sms-marketing-campaign-widget .campaign-list .campaign-list-item {
  grid-gap: 8px;
  letter-spacing: .0025em;
  color: #1c1d3e;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-bottom: none;
  grid-template-columns: 2fr 1fr 1.3fr 1.3fr 2fr 48px;
  grid-auto-flow: column;
  align-items: center;
  padding: 8px 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: grid;
}

@media (max-width: 767px) {
  .sms-marketing-campaign-widget .campaign-list .campaign-list-item {
    grid-template-columns: auto auto 48px;
    grid-template-areas: "title title more-button"
                         "recipient recipient ."
                         "status status ."
                         "cost cost ."
                         "date date .";
  }

  .sms-marketing-campaign-widget .campaign-list .campaign-list-item .title {
    grid-area: title;
  }

  .sms-marketing-campaign-widget .campaign-list .campaign-list-item .recipient-count {
    grid-area: recipient;
  }

  .sms-marketing-campaign-widget .campaign-list .campaign-list-item .cost {
    grid-area: cost;
  }

  .sms-marketing-campaign-widget .campaign-list .campaign-list-item .date {
    grid-area: date;
  }

  .sms-marketing-campaign-widget .campaign-list .campaign-list-item .status-container {
    grid-area: status;
  }

  .sms-marketing-campaign-widget .campaign-list .campaign-list-item .more-button {
    max-height: 20px;
    grid-area: more-button;
  }
}

.sms-marketing-campaign-widget .campaign-list .campaign-list-item:first-of-type {
  border-radius: 8px 8px 0 0;
}

.sms-marketing-campaign-widget .campaign-list .campaign-list-item:nth-last-child(1) {
  border-bottom: 1px solid #dee0e6;
  border-radius: 0 0 8px 8px;
}

.sms-marketing-campaign-widget .campaign-list .campaign-list-item:first-child:nth-last-child(1) {
  border-bottom: 1px solid #dee0e6;
  border-radius: 8px;
}

.sms-marketing-campaign-widget .campaign-list .campaign-list-item .status {
  max-height: 29px;
  border-radius: 100px;
  padding: 4px 12px;
}

.sms-marketing-campaign-widget .campaign-list .campaign-list-item .status.sent {
  color: #047857;
  background-color: #ecfdf5;
}

.sms-marketing-campaign-widget .campaign-list .campaign-list-item .status.scheduled {
  color: #c15e01;
  background-color: #fef3c7;
}

.sms-marketing-campaign-widget .campaign-list .campaign-list-item .more-button {
  padding: 0;
}

.sms-marketing-campaign-widget .campaign-list .campaign-list-item .more-button button {
  box-shadow: unset;
  border: none;
  background-color: #0000 !important;
}

.sms-marketing-campaign-widget .campaign-list .campaign-list-item .more-button .overlay .options {
  min-width: 180px;
  top: -20px;
  right: -10px;
  padding: 0 !important;
}

.sms-marketing-campaign-widget .view-more {
  background-color: #dee0e6;
  flex-direction: row-reverse;
  justify-content: center;
  margin: 24px auto;
  display: flex;
}

.sms-marketing-campaign-widget .view-more .icon {
  margin-left: 7px;
  transform: scale(.6);
  margin-right: 0 !important;
}

.sms-marketing-campaign-widget .back-to-top-button {
  background-color: #fff;
  border-radius: 30px;
  padding: 13px;
  position: fixed;
  top: 90vh;
  right: 50px;
}

@media (max-height: 844px) {
  .sms-marketing-campaign-widget .back-to-top-button {
    top: calc(100% - 150px);
  }
}

@media (min-height: 845px) {
  .sms-marketing-campaign-widget .back-to-top-button {
    top: calc(100% - 150px);
  }
}

.sms-marketing-mobile-preview-widget {
  flex-direction: column;
  align-items: center;
  padding: 32px 0 0;
  display: flex;
}

.sms-marketing-mobile-preview-widget .label {
  color: #7d7e92;
  margin-bottom: 24px;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
}

.sms-marketing-mobile-preview-widget .mobile-preview {
  width: 90%;
  max-width: 300px;
  min-height: 350px;
  background-image: url("mobile-preview-half.38dc0172.svg");
  background-repeat: no-repeat;
  background-size: contain;
  padding: 22px;
}

.sms-marketing-mobile-preview-widget .mobile-preview .sender-id {
  text-align: center;
  text-align: center;
  color: #000;
  opacity: .5;
  padding: 14px 0;
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
}

.sms-marketing-mobile-preview-widget .mobile-preview .message {
  width: -moz-fit-content;
  width: fit-content;
  letter-spacing: .0025em;
  max-width: 62%;
  text-wrap: wrap;
  word-wrap: break-word;
  background-color: #f4f4f6;
  border-radius: 1px 12px 12px;
  margin: 15px 0 0 20px;
  padding: 16px;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  overflow: hidden;
}

.sms-marketing-mobile-preview-widget .mobile-preview .message .opt-out-link {
  margin-top: 21px;
}

.sms-marketing-mobile-preview-widget .mobile-preview .message .placeholder {
  color: #7d7e92;
}

.sms-marketing-mobile-preview-widget .sms-count {
  text-align: center;
  letter-spacing: .004em;
  align-items: center;
  margin-top: 24px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  display: flex;
  position: relative;
}

.sms-marketing-mobile-preview-widget .sms-count img {
  cursor: pointer;
  margin-left: 8px;
}

.sms-marketing-mobile-preview-widget .sms-count .tooltip {
  width: 260px;
  text-align: justify;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  display: none;
  position: absolute;
  bottom: 40px;
  left: -50%;
  box-shadow: 2px 6px 24px #0000001a;
}

.sms-marketing-mobile-preview-widget .sms-count .tooltip .arrow {
  border-top: 12px solid #fff;
  border-left: 12px solid #0000;
  border-right: 12px solid #0000;
  position: absolute;
  bottom: -12px;
  right: 45%;
}

@media (max-width: 767px) {
  .sms-marketing-campaign-preview-widget {
    max-height: 90vh;
  }
}

.sms-marketing-campaign-preview-widget .header {
  border-bottom: 1px solid #dee0e6;
  justify-content: space-between;
  padding: 20px;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  display: flex;
}

.sms-marketing-campaign-preview-widget .header img {
  cursor: pointer;
}

.sms-marketing-campaign-preview-widget .body {
  grid-gap: 32px;
  grid-template-columns: 1fr 1fr;
  padding: 0 32px 32px;
  display: grid;
}

@media (max-width: 767px) {
  .sms-marketing-campaign-preview-widget .body {
    display: block;
  }
}

.sms-marketing-campaign-preview-widget .body .campaign-details {
  max-width: 320px;
  padding-top: 32px;
}

.sms-marketing-campaign-preview-widget .body .campaign-details .label {
  color: #7d7e92;
  margin-bottom: 6px;
  font-size: 12px;
  font-weight: 400;
}

.sms-marketing-campaign-preview-widget .body .campaign-details .detail {
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 400;
}

.sms-marketing-campaign-preview-widget .body .campaign-details .detail span {
  color: #1e40af;
  font-weight: 500;
}

.sms-marketing-campaign-preview-widget .body .campaign-details .detail .actual-cost {
  margin-top: 24px;
}

.sms-marketing-campaign-preview-widget .body .campaign-details .cost-info {
  color: #65667d;
  max-width: 320px;
  background-color: #f6f8fb;
  border-radius: 6px;
  align-items: flex-start;
  padding: 12px;
  font-size: 12px;
  display: flex;
}

.sms-marketing-campaign-preview-widget .body .campaign-details .cost-info img {
  width: 14px;
  margin: 2px 6px 0 0;
}

.sms-marketing-campaign-preview-widget .actions {
  background-color: #fafafa;
  align-items: center;
  padding: 20px;
  display: flex;
  box-shadow: inset 0 1px #f1f1f5;
}

.sms-marketing-campaign-preview-widget .actions.draft {
  justify-content: flex-end;
}

.sms-marketing-campaign-preview-widget .actions.sent, .sms-marketing-campaign-preview-widget .actions.scheduled {
  justify-content: space-between;
}

.sms-marketing-campaign-preview-widget .actions.sent .status-info, .sms-marketing-campaign-preview-widget .actions.scheduled .status-info {
  align-items: center;
  display: flex;
}

.sms-marketing-campaign-preview-widget .actions.sent .status-info .status, .sms-marketing-campaign-preview-widget .actions.scheduled .status-info .status {
  border-radius: 100px;
  margin-right: 8px;
  padding: 4px 12px;
}

.sms-marketing-campaign-preview-widget .actions.sent .status-info img, .sms-marketing-campaign-preview-widget .actions.scheduled .status-info img {
  height: 13px;
  cursor: pointer;
  margin-left: 10px;
}

.sms-marketing-campaign-preview-widget .actions.sent .status-action, .sms-marketing-campaign-preview-widget .actions.scheduled .status-action {
  color: #9f1239;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-decoration: underline;
}

.sms-marketing-campaign-preview-widget .actions.sent .helper-text, .sms-marketing-campaign-preview-widget .actions.scheduled .helper-text {
  letter-spacing: .004em;
  color: #7d7e92;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.sms-marketing-campaign-preview-widget .actions.sent .status {
  color: #047857;
  background-color: #ecfdf5;
}

.sms-marketing-campaign-preview-widget .actions.scheduled .status {
  color: #c15e01;
  background-color: #fef3c7;
}

@media (max-width: 767px) {
  .sms-marketing-campaign-preview-widget .actions {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.sms-marketing-campaign-preview-widget .actions button {
  margin-left: 12px;
}

.table-map-settings-widget {
  padding-bottom: 40px;
}

.table-map-settings-widget > .header {
  margin: 24px 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 40.8px;
}

.table-map-settings-widget .settings {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-columns: 1fr max-content;
  grid-template-areas: "header toggle"
                       "info info";
  align-items: center;
  gap: 16px;
  padding: 24px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.table-map-settings-widget .settings > .header {
  grid-area: header;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.table-map-settings-widget .settings > .toggle {
  grid-area: toggle;
}

.table-map-settings-widget .settings > .info {
  grid-area: info;
  font-size: 16px;
  line-height: 24px;
}

.swal2-container.google-review-link-confirmation > .swal2-modal {
  width: 375px;
  border-radius: 20px;
  padding: 32px 24px;
}

.swal2-container.google-review-link-confirmation > .swal2-modal > .swal2-header > .swal2-title {
  color: #1c1d3e;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.swal2-container.google-review-link-confirmation > .swal2-modal > .swal2-content {
  color: #7d7e92;
  margin: 12px 25px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.swal2-container.google-review-link-confirmation > .swal2-modal > .swal2-actions {
  flex-flow: column;
  padding: 0 25px;
}

.swal2-container.google-review-link-confirmation > .swal2-modal > .swal2-actions > button {
  width: 100%;
  padding: 14px 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.swal2-container.google-review-link-confirmation > .swal2-modal > .swal2-actions > .swal2-confirm {
  background-color: #2563eb;
}

.swal2-container.google-review-link-confirmation > .swal2-modal > .swal2-actions > .swal2-cancel {
  color: #4c4e68;
  border: 1px solid #7d7e92;
  margin-top: 12px;
  background-color: #fff !important;
}

.swal2-container.google-review-remove-link-confirmation > .swal2-modal {
  width: 391px;
  border-radius: 20px;
  padding: 32px 20px;
}

.swal2-container.google-review-remove-link-confirmation > .swal2-modal > .swal2-header > .swal2-title {
  color: #1c1d3e;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.swal2-container.google-review-remove-link-confirmation > .swal2-modal > .swal2-content {
  color: #7d7e92;
  margin-top: 12px;
  font-size: 16px;
  line-height: 24px;
}

.swal2-container.google-review-remove-link-confirmation > .swal2-modal > .swal2-actions {
  flex-flow: column;
  padding: 0 21px;
}

.swal2-container.google-review-remove-link-confirmation > .swal2-modal > .swal2-actions > button {
  width: 100%;
  padding: 14px 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.swal2-container.google-review-remove-link-confirmation > .swal2-modal > .swal2-actions > .swal2-confirm {
  background-color: #9f1239 !important;
}

.swal2-container.google-review-remove-link-confirmation > .swal2-modal > .swal2-actions > .swal2-cancel {
  color: #4c4e68;
  border: 1px solid #7d7e92;
  margin-top: 12px;
  background-color: #fff !important;
}

.google-review-settings-widget {
  padding-bottom: 40px;
}

.google-review-settings-widget > .header {
  grid-template-columns: min-content 1fr max-content;
  grid-template-areas: "logo title status"
                       "logo sub-title sub-title";
  gap: 8px 24px;
  margin: 24px 0;
  display: grid;
}

.google-review-settings-widget > .header .logo {
  height: 80px;
  grid-area: logo;
}

.google-review-settings-widget > .header .title {
  color: #1c1d3e;
  grid-area: title;
  font-size: 34px;
  font-weight: 600;
  line-height: 40.8px;
}

.google-review-settings-widget > .header .sub-title {
  color: #4c4e68;
  grid-area: sub-title;
  margin-top: 8px;
  font-size: 16px;
}

.google-review-settings-widget > .header .status {
  color: #4c4e68;
  grid-area: status;
}

.google-review-settings-widget > .header .status.is-enabled {
  color: #059669;
}

.google-review-settings-widget > .settings-info .title {
  color: #1c1d3e;
  font-size: 24px;
  font-weight: 600;
}

.google-review-settings-widget > .settings-info .sub-title {
  color: #4c4e68;
  margin-top: 8px;
  font-size: 16px;
}

.google-review-settings-widget > .settings-info .sub-title span {
  color: #1d4ed8;
  cursor: pointer;
  text-decoration: underline;
}

.google-review-info-pop-up-widget {
  max-width: 490px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  display: flex;
}

.google-review-info-pop-up-widget .illustration {
  width: 391px;
  height: 247px;
}

.google-review-info-pop-up-widget .pop-up-display-indicators {
  align-items: center;
  margin-top: 24px;
  display: flex;
}

.google-review-info-pop-up-widget .pop-up-display-indicators > div:nth-child(2) {
  margin: 0 12px;
}

.google-review-info-pop-up-widget .pop-up-display-indicators .indicator {
  width: 12px;
  height: 12px;
  background-color: #dee0e6;
  border-radius: 100%;
}

.google-review-info-pop-up-widget .pop-up-display-indicators .indicator.active {
  background-color: #3b82f6;
}

.google-review-info-pop-up-widget .title {
  letter-spacing: .1px;
  color: var(--fg);
  padding-top: 24px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.google-review-info-pop-up-widget .description {
  color: var(--fg);
  color: #7d7e92;
  text-align: center;
  padding-top: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.google-review-info-pop-up-widget button {
  width: 88%;
  margin-top: 20px;
  padding: 14px 32px;
  font-size: 16px;
}

.google-review-info-pop-up-widget.customer-feedback-info .description {
  margin: 0 50px;
}

.google-review-info-pop-up-widget.review-link-info .description {
  margin: 0 5px;
}

.google-review-setup-widget .google-review-link-edit {
  background-color: var(--card-bg);
  background-color: #fafafa;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  margin-top: 24px;
  padding: 32px;
  box-shadow: 0 0 3px #687a9b40;
}

.google-review-setup-widget .google-review-link-edit .header {
  color: #1c1d3e;
  align-items: center;
  padding-bottom: 24px;
  font-size: 20px;
  font-weight: 600;
  display: flex;
}

.google-review-setup-widget .google-review-link-edit .input-container {
  border: 1.5px solid #4cbba1;
  border-radius: 10px;
  padding: 32px 24px;
}

.google-review-setup-widget .google-review-link-edit .input-container .header {
  color: #1c1d3e;
  padding-bottom: unset;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  display: flex;
}

.google-review-setup-widget .google-review-link-edit .input-container .sub-header {
  color: #4c4e68;
  margin-top: 8px;
  font-size: 16px;
}

.google-review-setup-widget .google-review-link-edit .input-container .sub-header span {
  color: #1d4ed8;
  cursor: pointer;
  text-decoration: underline;
}

.google-review-setup-widget .google-review-link-edit .input-container .link-input {
  margin-top: 8px;
}

.google-review-setup-widget .google-review-link-edit .input-container .link-input form {
  grid-template-columns: 9fr 1fr;
  display: grid;
}

.google-review-setup-widget .google-review-link-edit .input-container .link-input form .form-buttons {
  min-width: 136px;
  margin-top: 27px;
  margin-left: 30px;
}

.google-review-setup-widget .google-review-link-edit .input-container .link-input form .form-buttons button {
  width: 100%;
  padding: 13px 18px;
}

.google-review-setup-widget .google-review-link-edit .input-container .link-input form .text-input input {
  width: 100%;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  margin-top: 8px;
  padding: 12px 14px;
  font-size: 14px;
}

.google-review-setup-widget .google-review-link-view {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  margin-top: 24px;
  padding: 32px;
  box-shadow: 0 0 3px #687a9b40;
}

.google-review-setup-widget .google-review-link-view .header {
  color: #1c1d3e;
  padding-bottom: unset;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  display: flex;
}

.google-review-setup-widget .google-review-link-view .header img {
  padding-right: 8px;
}

.google-review-setup-widget .google-review-link-view .link-container {
  background-color: #f4f4f6;
  border-radius: 10px;
  justify-content: space-between;
  margin-top: 24px;
  padding: 24px;
  display: flex;
}

.google-review-setup-widget .google-review-link-view .link-container .link {
  cursor: pointer;
  display: flex;
}

.google-review-setup-widget .google-review-link-view .link-container .link img {
  margin-right: 10px;
}

.google-review-setup-widget .google-review-link-view .link-container .link a {
  color: #1e40af;
}

.google-review-setup-widget .google-review-link-view .link-container .remove-link {
  color: #9f1239;
  cursor: pointer;
  font-weight: 500;
  text-decoration: underline;
}

.google-review-setup-widget .google-review-link-view .link-info {
  align-items: center;
  margin-top: 32px;
  font-size: 14px;
  display: flex;
}

.google-review-setup-widget .google-review-link-view .link-info img {
  margin-right: 5px;
}

.google-review-setup-widget .display-settings {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  margin-top: 24px;
  padding: 32px;
  box-shadow: 0 0 3px #687a9b40;
}

.google-review-setup-widget .display-settings .header {
  color: #1c1d3e;
  padding-bottom: unset;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  display: flex;
}

.google-review-setup-widget .display-settings .show-review-link-settings, .google-review-setup-widget .display-settings .qr-code-settings {
  border: 1px solid #dee0e6;
  border-radius: 10px;
  grid-template-columns: 1fr max-content;
  grid-template-areas: "title toggle"
                       "info info"
                       "print-qr-code-settings print-qr-code-settings"
                       "download-qr-code download-qr-code";
  margin-top: 24px;
  padding: 32px 24px;
  display: grid;
}

.google-review-setup-widget .display-settings .show-review-link-settings .title, .google-review-setup-widget .display-settings .qr-code-settings .title {
  color: #1c1d3e;
  padding-bottom: unset;
  grid-area: title;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  display: flex;
}

.google-review-setup-widget .display-settings .show-review-link-settings .info, .google-review-setup-widget .display-settings .qr-code-settings .info {
  grid-area: info;
  line-height: 30px;
}

.google-review-setup-widget .display-settings .show-review-link-settings .toggle, .google-review-setup-widget .display-settings .qr-code-settings .toggle {
  grid-area: toggle;
}

.google-review-setup-widget .display-settings .show-review-link-settings .review-source-link, .google-review-setup-widget .display-settings .qr-code-settings .review-source-link {
  margin-top: 20px;
  display: flex;
}

.google-review-setup-widget .display-settings .show-review-link-settings .review-source-link .checkbox, .google-review-setup-widget .display-settings .qr-code-settings .review-source-link .checkbox {
  margin-right: 15px;
}

.google-review-setup-widget .display-settings .show-review-link-settings .review-source-link .checkbox label, .google-review-setup-widget .display-settings .qr-code-settings .review-source-link .checkbox label {
  color: #1c1d3e;
  margin-left: 7px;
}

.google-review-setup-widget .display-settings .show-review-link-settings .print-qr-code-settings, .google-review-setup-widget .display-settings .show-review-link-settings .download-qr-code, .google-review-setup-widget .display-settings .qr-code-settings .print-qr-code-settings, .google-review-setup-widget .display-settings .qr-code-settings .download-qr-code {
  border-top: 1px solid #dee0e6;
  grid-template-columns: 1fr max-content;
  grid-template-areas: "title toggle"
                       "info info";
  align-items: center;
  margin-top: 24px;
  padding-top: 24px;
  display: grid;
}

.google-review-setup-widget .display-settings .show-review-link-settings .print-qr-code-settings, .google-review-setup-widget .display-settings .qr-code-settings .print-qr-code-settings {
  grid-area: print-qr-code-settings;
}

.google-review-setup-widget .display-settings .show-review-link-settings .print-qr-code-settings .printed-invoice-or-ticket, .google-review-setup-widget .display-settings .qr-code-settings .print-qr-code-settings .printed-invoice-or-ticket {
  margin-top: 20px;
  display: flex;
}

.google-review-setup-widget .display-settings .show-review-link-settings .print-qr-code-settings .printed-invoice-or-ticket .checkbox, .google-review-setup-widget .display-settings .qr-code-settings .print-qr-code-settings .printed-invoice-or-ticket .checkbox {
  margin-right: 15px;
}

.google-review-setup-widget .display-settings .show-review-link-settings .print-qr-code-settings .printed-invoice-or-ticket .checkbox label, .google-review-setup-widget .display-settings .qr-code-settings .print-qr-code-settings .printed-invoice-or-ticket .checkbox label {
  color: #1c1d3e;
  margin-left: 7px;
}

.google-review-setup-widget .display-settings .show-review-link-settings .download-qr-code, .google-review-setup-widget .display-settings .qr-code-settings .download-qr-code {
  grid-area: download-qr-code;
}

.google-review-setup-widget .display-settings .show-review-link-settings .download-qr-code .download-button, .google-review-setup-widget .display-settings .qr-code-settings .download-qr-code .download-button {
  color: #2563eb;
  background-color: #0000;
  border: 1px solid #60a5fa;
  border-radius: 4px;
  font-size: 16px;
}

.google-review-setup-widget .display-settings .show-review-link-settings .download-qr-code > .customer-review-qr-code-widget, .google-review-setup-widget .display-settings .qr-code-settings .download-qr-code > .customer-review-qr-code-widget {
  display: none;
}

.google-review-setup-widget .email-settings, .google-review-setup-widget .review-form-customization-settings {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  margin-top: 24px;
  padding: 32px;
  box-shadow: 0 0 3px #687a9b40;
}

.google-review-setup-widget .email-settings .header, .google-review-setup-widget .review-form-customization-settings .header {
  color: #1c1d3e;
  padding-bottom: unset;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  display: flex;
}

.google-review-setup-widget .email-settings .header img, .google-review-setup-widget .review-form-customization-settings .header img {
  padding-right: 8px;
}

.google-review-setup-widget .email-settings .info, .google-review-setup-widget .review-form-customization-settings .info {
  font-size: 14px;
}

.google-review-setup-widget .email-settings .customer-review-notifications, .google-review-setup-widget .email-settings .customer-banner-color, .google-review-setup-widget .review-form-customization-settings .customer-review-notifications, .google-review-setup-widget .review-form-customization-settings .customer-banner-color {
  border: 1px solid #dee0e6;
  border-radius: 10px;
  grid-template-columns: 1fr max-content;
  grid-template-areas: "title toggle"
                       "info info";
  margin-top: 24px;
  padding: 32px 24px;
  display: grid;
}

.google-review-setup-widget .email-settings .customer-review-notifications .title, .google-review-setup-widget .email-settings .customer-banner-color .title, .google-review-setup-widget .review-form-customization-settings .customer-review-notifications .title, .google-review-setup-widget .review-form-customization-settings .customer-banner-color .title {
  color: #1c1d3e;
  padding-bottom: unset;
  grid-area: title;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  display: flex;
}

.google-review-setup-widget .email-settings .customer-review-notifications .info, .google-review-setup-widget .email-settings .customer-banner-color .info, .google-review-setup-widget .review-form-customization-settings .customer-review-notifications .info, .google-review-setup-widget .review-form-customization-settings .customer-banner-color .info {
  grid-area: info;
  line-height: 30px;
}

.google-review-setup-widget .email-settings .customer-review-notifications .toggle, .google-review-setup-widget .email-settings .customer-banner-color .toggle, .google-review-setup-widget .review-form-customization-settings .customer-review-notifications .toggle, .google-review-setup-widget .review-form-customization-settings .customer-banner-color .toggle {
  grid-area: toggle;
}

.google-review-setup-widget .email-settings .customer-review-notifications .review-source-link, .google-review-setup-widget .email-settings .customer-banner-color .review-source-link, .google-review-setup-widget .review-form-customization-settings .customer-review-notifications .review-source-link, .google-review-setup-widget .review-form-customization-settings .customer-banner-color .review-source-link {
  margin-top: 20px;
  display: flex;
}

.google-review-setup-widget .email-settings .customer-review-notifications .review-source-link .checkbox, .google-review-setup-widget .email-settings .customer-banner-color .review-source-link .checkbox, .google-review-setup-widget .review-form-customization-settings .customer-review-notifications .review-source-link .checkbox, .google-review-setup-widget .review-form-customization-settings .customer-banner-color .review-source-link .checkbox {
  margin-right: 15px;
}

.google-review-setup-widget .email-settings .customer-review-notifications .review-source-link .checkbox label, .google-review-setup-widget .email-settings .customer-banner-color .review-source-link .checkbox label, .google-review-setup-widget .review-form-customization-settings .customer-review-notifications .review-source-link .checkbox label, .google-review-setup-widget .review-form-customization-settings .customer-banner-color .review-source-link .checkbox label {
  color: #1c1d3e;
  margin-left: 7px;
}

.google-review-setup-widget .email-settings .customer-review-notifications .recipients, .google-review-setup-widget .email-settings .customer-banner-color .recipients, .google-review-setup-widget .review-form-customization-settings .customer-review-notifications .recipients, .google-review-setup-widget .review-form-customization-settings .customer-banner-color .recipients {
  margin-top: 24px;
}

.google-review-setup-widget .email-settings .customer-review-notifications .recipients .header, .google-review-setup-widget .email-settings .customer-banner-color .recipients .header, .google-review-setup-widget .review-form-customization-settings .customer-review-notifications .recipients .header, .google-review-setup-widget .review-form-customization-settings .customer-banner-color .recipients .header {
  color: #4c4e68;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  display: flex;
}

.google-review-setup-widget .email-settings .customer-review-notifications .recipients .header img, .google-review-setup-widget .email-settings .customer-banner-color .recipients .header img, .google-review-setup-widget .review-form-customization-settings .customer-review-notifications .recipients .header img, .google-review-setup-widget .review-form-customization-settings .customer-banner-color .recipients .header img {
  cursor: pointer;
  margin-left: 10px;
}

.google-review-setup-widget .email-settings .customer-review-notifications .recipients .confirmed-emails, .google-review-setup-widget .email-settings .customer-banner-color .recipients .confirmed-emails, .google-review-setup-widget .review-form-customization-settings .customer-review-notifications .recipients .confirmed-emails, .google-review-setup-widget .review-form-customization-settings .customer-banner-color .recipients .confirmed-emails {
  flex-wrap: wrap;
  margin-top: 12px;
  display: flex;
}

.google-review-setup-widget .email-settings .customer-review-notifications .recipients .confirmed-emails .email, .google-review-setup-widget .email-settings .customer-banner-color .recipients .confirmed-emails .email, .google-review-setup-widget .review-form-customization-settings .customer-review-notifications .recipients .confirmed-emails .email, .google-review-setup-widget .review-form-customization-settings .customer-banner-color .recipients .confirmed-emails .email {
  color: #4c4e68;
  border: 1px solid #d3d3d9;
  border-radius: 4px;
  margin: 0 10px 10px 0;
  padding: 4px 12px;
  font-size: 14px;
}

.google-review-setup-widget .email-settings .customer-review-notifications .recipients .unconfirmed-emails, .google-review-setup-widget .email-settings .customer-banner-color .recipients .unconfirmed-emails, .google-review-setup-widget .review-form-customization-settings .customer-review-notifications .recipients .unconfirmed-emails, .google-review-setup-widget .review-form-customization-settings .customer-banner-color .recipients .unconfirmed-emails {
  flex-wrap: wrap;
  margin: 12px 0 14px;
  display: flex;
}

.google-review-setup-widget .email-settings .customer-review-notifications .recipients .unconfirmed-emails .email, .google-review-setup-widget .email-settings .customer-banner-color .recipients .unconfirmed-emails .email, .google-review-setup-widget .review-form-customization-settings .customer-review-notifications .recipients .unconfirmed-emails .email, .google-review-setup-widget .review-form-customization-settings .customer-banner-color .recipients .unconfirmed-emails .email {
  color: #4c4e68;
  background-color: #e9e9ec;
  border-radius: 10px;
  margin: 0 10px 10px 0;
  padding: 4px 12px;
  font-size: 14px;
}

.google-review-setup-widget .email-settings .customer-review-notifications .recipients .unconfirmed-emails img, .google-review-setup-widget .email-settings .customer-banner-color .recipients .unconfirmed-emails img, .google-review-setup-widget .review-form-customization-settings .customer-review-notifications .recipients .unconfirmed-emails img, .google-review-setup-widget .review-form-customization-settings .customer-banner-color .recipients .unconfirmed-emails img {
  cursor: pointer;
  margin-left: 10px;
}

.google-review-setup-widget .email-settings .customer-review-notifications .recipients .email-input form, .google-review-setup-widget .email-settings .customer-banner-color .recipients .email-input form, .google-review-setup-widget .review-form-customization-settings .customer-review-notifications .recipients .email-input form, .google-review-setup-widget .review-form-customization-settings .customer-banner-color .recipients .email-input form {
  display: flex;
}

.google-review-setup-widget .email-settings .customer-review-notifications .recipients .email-input form .form-items, .google-review-setup-widget .email-settings .customer-banner-color .recipients .email-input form .form-items, .google-review-setup-widget .review-form-customization-settings .customer-review-notifications .recipients .email-input form .form-items, .google-review-setup-widget .review-form-customization-settings .customer-banner-color .recipients .email-input form .form-items {
  width: 85%;
}

.google-review-setup-widget .email-settings .customer-review-notifications .recipients .email-input form .form-buttons, .google-review-setup-widget .email-settings .customer-banner-color .recipients .email-input form .form-buttons, .google-review-setup-widget .review-form-customization-settings .customer-review-notifications .recipients .email-input form .form-buttons, .google-review-setup-widget .review-form-customization-settings .customer-banner-color .recipients .email-input form .form-buttons {
  margin-top: 0;
  margin-left: 30px;
}

.google-review-setup-widget .email-settings .customer-review-notifications .recipients .email-input form .form-buttons button, .google-review-setup-widget .email-settings .customer-banner-color .recipients .email-input form .form-buttons button, .google-review-setup-widget .review-form-customization-settings .customer-review-notifications .recipients .email-input form .form-buttons button, .google-review-setup-widget .review-form-customization-settings .customer-banner-color .recipients .email-input form .form-buttons button {
  width: 100%;
  color: #2563eb;
  background-color: #0000;
  border: 1px solid #60a5fa;
  border-radius: 4px;
}

.google-review-setup-widget .email-settings .customer-review-notifications .banner-color .title, .google-review-setup-widget .email-settings .customer-banner-color .banner-color .title, .google-review-setup-widget .review-form-customization-settings .customer-review-notifications .banner-color .title, .google-review-setup-widget .review-form-customization-settings .customer-banner-color .banner-color .title {
  margin: 15px 0;
  font-size: 16px;
  font-weight: 500;
}

.uber-eats-pos-order-integration-settings-widget > .header > .switch {
  cursor: pointer;
  margin: 0 0 0 24px;
}

.uber-eats-pos-order-integration-settings-widget > .header > .switch i {
  width: 46px;
  height: 26px;
  vertical-align: text-bottom;
  background-color: #c6c7d1;
  border-radius: 23px;
  margin-right: 4px;
  transition: all .3s linear;
  display: inline-block;
  position: relative;
}

.uber-eats-pos-order-integration-settings-widget > .header > .switch i:before {
  content: "";
  width: 42px;
  height: 22px;
  background-color: #c6c7d1;
  border-radius: 11px;
  transition: all .25s linear;
  position: absolute;
  left: 0;
  transform: translate3d(2px, 2px, 0)scale3d(1, 1, 1);
}

.uber-eats-pos-order-integration-settings-widget > .header > .switch i:after {
  content: "";
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 11px;
  transition: all .2s ease-in-out;
  position: absolute;
  left: 0;
  transform: translate3d(2px, 2px, 0);
  box-shadow: 0 2px 2px #0000003d;
}

.uber-eats-pos-order-integration-settings-widget > .header > .switch:active i:after {
  width: 28px;
  transform: translate3d(2px, 2px, 0);
}

.uber-eats-pos-order-integration-settings-widget > .header > .switch:active input:checked + i:after {
  transform: translate3d(16px, 2px, 0);
}

.uber-eats-pos-order-integration-settings-widget > .header > .switch input {
  display: none;
}

.uber-eats-pos-order-integration-settings-widget > .header > .switch input:checked + i {
  background-color: #34c759;
}

.uber-eats-pos-order-integration-settings-widget > .header > .switch input:checked + i:before {
  transform: translate3d(18px, 2px, 0)scale3d(0, 0, 0);
}

.uber-eats-pos-order-integration-settings-widget > .header > .switch input:checked + i:after {
  transform: translate3d(22px, 2px, 0);
}

.banner-widget {
  border-radius: 6px;
  grid-template-columns: min-content 1fr min-content;
  padding: 24px 24px 0;
  font-size: 16px;
  line-height: 24px;
  display: grid;
}

.banner-widget > .icon {
  margin-right: 8px;
}

.banner-widget > .content > .title {
  font-size: 18px;
  font-weight: 500;
}

.banner-widget > .close-button {
  cursor: pointer;
  margin-left: 8px;
}

.banner-widget.success {
  color: #064e3b;
  background-color: #d1fae5;
  border: 1px solid #064e3b;
}

.banner-widget.info {
  color: #1e40af;
  background-color: #eff6ff;
  border: 1px solid #1e40af;
}

.banner-widget.warning {
  color: #92400e;
  background-color: #fef3c7;
  border: 1px solid #f59e0b;
}

.banner-widget.disallowed {
  color: #9f1239;
  background-color: #ffe4e6;
  border: 1px solid #f43f5e;
}

.uber-eats-unauthorised-alert .actions {
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-top: 24px;
  display: grid;
}

.uber-eats-unauthorised-alert .actions button {
  font-size: 16px;
}

.uber-eats-unauthorised-alert .actions .cancel-button {
  box-shadow: none;
  color: #4c4e68;
  background: none;
  border-radius: 4px;
  outline: 1px solid #7d7e92;
}

@media (max-width: 767px) {
  .uber-eats-unauthorised-alert {
    width: 100%;
  }

  .uber-eats-unauthorised-alert .title {
    font-size: 18px;
  }

  .uber-eats-unauthorised-alert .actions {
    flex-direction: column;
    display: flex;
  }
}

.uber-eats-setup-banner {
  position: relative;
}

.uber-eats-setup-banner table, .uber-eats-setup-banner th, .uber-eats-setup-banner td {
  border-collapse: collapse;
  color: #000;
  vertical-align: top;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 2px 6px;
  font-size: 12px;
  line-height: 18px;
}

.uber-eats-setup-banner table .menu-name, .uber-eats-setup-banner th .menu-name, .uber-eats-setup-banner td .menu-name {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  background-color: #666;
  padding: 8px;
  font-weight: bold;
}

.uber-eats-setup-banner table .category-name, .uber-eats-setup-banner th .category-name, .uber-eats-setup-banner td .category-name {
  background-color: #d9d9d9;
  padding: 8px;
  font-weight: bold;
}

.uber-eats-setup-banner table .table-header, .uber-eats-setup-banner th .table-header, .uber-eats-setup-banner td .table-header {
  font-size: 10px;
  font-weight: bold;
}

.uber-eats-setup-banner .content {
  grid-template-rows: repeat(2, min-content);
  grid-template-columns: 1fr min-content;
  grid-template-areas: "title actions"
                       "body actions"
                       "footer footer";
  align-items: center;
  gap: 16px;
  display: grid;
}

.uber-eats-setup-banner .title {
  grid-area: title;
}

.uber-eats-setup-banner .body {
  grid-area: body;
}

.uber-eats-setup-banner .actions {
  white-space: nowrap;
  grid-area: actions;
  margin-left: 16px;
}

.uber-eats-setup-banner .actions .submit-form-button {
  margin-bottom: 16px;
}

.uber-eats-setup-banner .footer {
  letter-spacing: .0025em;
  background-color: #dbeafe;
  border-radius: 0 0 6px 6px;
  grid-area: footer;
  justify-content: space-between;
  align-items: center;
  margin: 0 -24px 0 -56px;
  padding: 14px 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: flex;
}

.uber-eats-setup-banner .footer .download-button > button {
  min-width: 120px;
  box-shadow: none;
  cursor: pointer;
  background-color: #0000;
  align-items: center;
  font-size: 12px;
  display: flex;
}

.uber-eats-setup-banner .footer .download-button > button:hover {
  background-color: #bfdbfe;
}

.uber-eats-setup-banner .footer .download-button > button > .icon {
  color: #1e40af;
}

.uber-eats-setup-banner .footer .download-button > button > .label {
  color: #2563eb;
}

.uber-eats-setup-banner .footer .download-button > .overlay {
  color: #000;
}

.uber-eats-setup-banner .footer .download-button > .overlay .options-wrapper > .options {
  width: 100%;
  padding-left: 6px;
}

.uber-eats-setup-banner .ready-to-enable-order-flow .enable-button {
  color: #fff;
  background-color: #059669;
  border-radius: 4px;
  padding: 9.5px 24px;
}

.uber-eats-setup-banner .uber-eats-submit-form-banner {
  padding-bottom: 0;
}

.uber-eats-setup-banner .uber-eats-submit-form-banner > .content > .body > .menu-integration-steps {
  margin-left: 4px;
  list-style-type: decimal;
  list-style-position: inside;
}

.uber-eats-setup-banner .uber-eats-submit-form-banner > .content > .body > .menu-integration-steps > :not(:first-child) {
  margin-top: 8px;
}

.uber-eats-setup-banner .uber-eats-submit-form-banner > .content > .body > .menu-integration-steps > :not(.step-list) {
  display: list-item;
}

.uber-eats-setup-banner .uber-eats-submit-form-banner > .content > .body > .menu-integration-steps > .step-list {
  margin-left: 16px;
  list-style-type: disc;
  list-style-position: inside;
}

.uber-eats-setup-banner .uber-eats-submit-form-banner > .content > .body > .menu-integration-steps > .step-list > * {
  display: list-item;
}

.uber-eats-setup-banner .uber-eats-submit-form-banner > .content > .body > .menu-integration-steps > .step-list > * > .label {
  font-weight: 500;
}

.uber-eats-setup-banner .uber-eats-submit-form-banner > .content > .actions {
  align-self: flex-start;
}

.uber-eats-setup-banner .uber-eats-submit-form-banner > .content > .actions > .submit-form-button {
  color: #fff;
  background-color: #2563eb;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 9.5px 24px;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  display: flex;
}

.uber-eats-setup-banner .uber-eats-submit-form-banner > .content > .actions > .submit-form-button > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  display: flex;
}

.uber-eats-setup-banner .uber-eats-submit-form-banner > .content > .actions > .submit-form-button > .icon > img {
  height: 100%;
}

@media (max-width: 767px) {
  .uber-eats-setup-banner .content {
    display: unset;
    align-items: unset;
  }

  .uber-eats-setup-banner .actions {
    margin: 0;
  }

  .uber-eats-setup-banner .actions .submit-form-button, .uber-eats-setup-banner .actions .uber-eats-login-button, .uber-eats-setup-banner .actions .enable-button {
    box-sizing: border-box;
    text-align: center;
    width: 100%;
    margin-top: 12px;
    display: block;
  }
}

.swal2-container .uber-eats-submit-form-banner {
  width: 573px;
  border-radius: 20px;
  padding: 32px 24px;
}

.swal2-container .uber-eats-submit-form-banner .swal2-icon.swal2-warning {
  color: #f59e0b;
  border-width: 4px;
  border-color: #f59e0b;
  font-size: 9px;
  font-weight: 500;
}

.swal2-container .uber-eats-submit-form-banner .swal2-title {
  color: #1c1d3e;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.swal2-container .uber-eats-submit-form-banner .swal2-content {
  color: #7d7e92;
  margin-top: 16px;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.swal2-container .uber-eats-submit-form-banner .swal2-actions {
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "cancel-button confirm-button";
  gap: 16px;
  margin-top: 24px;
  padding: 0 24px;
  display: grid;
}

.swal2-container .uber-eats-submit-form-banner .swal2-actions button {
  border-radius: 4px;
  margin: 0;
  padding: 14px 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.swal2-container .uber-eats-submit-form-banner .swal2-actions .confirm-button {
  white-space: nowrap;
  color: #fff;
  background-color: #2563eb;
  grid-area: confirm-button;
}

.swal2-container .uber-eats-submit-form-banner .swal2-actions .cancel-button {
  color: #4c4e68;
  border: 1px solid #7d7e92;
  grid-area: cancel-button;
  background-color: #fff !important;
}

.uber-eats-delete-integration-alert.alert-widget {
  max-width: 573px;
}

.uber-eats-delete-integration-alert.alert-widget .content {
  text-align: left;
}

.uber-eats-delete-integration-alert.alert-widget .content ul {
  margin: 0;
}

.uber-eats-delete-integration-alert.alert-widget .actions {
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "back-button delete-button";
  gap: 16px;
  display: grid;
}

.uber-eats-delete-integration-alert.alert-widget .actions button {
  white-space: nowrap;
  padding: 14px 32px;
}

.uber-eats-delete-integration-alert.alert-widget .actions .proceed-button {
  grid-area: delete-button;
}

.uber-eats-delete-integration-alert.alert-widget .actions .cancel-button {
  grid-area: back-button;
}

@media (max-width: 767px) {
  .uber-eats-delete-integration-alert.alert-widget {
    width: 100%;
  }

  .uber-eats-delete-integration-alert.alert-widget .title {
    font-size: 18px;
  }

  .uber-eats-delete-integration-alert.alert-widget .actions {
    flex-direction: column;
    display: flex;
  }
}

.uber-eats-cancel-integration-setup-alert.alert-widget {
  max-width: 573px;
}

.uber-eats-cancel-integration-setup-alert.alert-widget .content {
  text-align: left;
}

.uber-eats-cancel-integration-setup-alert.alert-widget .content ul {
  margin: 0;
}

.uber-eats-cancel-integration-setup-alert.alert-widget .actions {
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "back-button delete-button";
  gap: 16px;
  display: grid;
}

.uber-eats-cancel-integration-setup-alert.alert-widget .actions button {
  white-space: nowrap;
  padding: 14px 32px;
}

.uber-eats-cancel-integration-setup-alert.alert-widget .actions .proceed-button {
  grid-area: delete-button;
}

.uber-eats-cancel-integration-setup-alert.alert-widget .actions .cancel-button {
  grid-area: back-button;
}

@media (max-width: 767px) {
  .uber-eats-cancel-integration-setup-alert.alert-widget {
    width: 100%;
  }

  .uber-eats-cancel-integration-setup-alert.alert-widget .title {
    font-size: 18px;
  }

  .uber-eats-cancel-integration-setup-alert.alert-widget .actions {
    flex-direction: column;
    display: flex;
  }
}

.uber-eats-integration-action-widget {
  padding: 9.5px 16px;
}

.uber-eats-integration-action-widget .cancel-integration-setup-button, .uber-eats-integration-action-widget .delete-integration-button {
  box-shadow: none;
  background: none;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  display: inline;
}

.uber-eats-integration-action-widget .delete-integration-button.disabled {
  cursor: not-allowed;
  color: #fda4af !important;
}

.uber-eats-integration-action-widget .cancel-integration-setup-button.disabled {
  cursor: not-allowed;
  color: #adadb8 !important;
}

.uber-eats-integration-action-widget .delete-integration-button {
  color: #9f1239;
}

.uber-eats-integration-action-widget .incorrect-account-notice, .uber-eats-integration-action-widget .disabled-cancellation-help-text {
  color: #7d7e92;
  margin-left: 16px;
  font-size: 14px;
  display: inline;
}

@media (max-width: 767px) {
  .uber-eats-integration-action-widget, .uber-eats-integration-action-widget button {
    padding: 0;
  }
}

.custom-payment-settings-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.custom-payment-settings-widget form {
  margin: 20px 0;
}

.custom-payment-settings-widget .empty-custom-payments {
  padding: 0 20px;
}

@media (max-width: 767px) {
  .custom-payment-settings-widget .form-items {
    margin: 0 20px;
  }
}

.custom-payment-settings-widget .custom-payments .custom-payment-list-widget .custom-payment-list-item-widget {
  background-color: var(--card-bg);
  background-color: #0000;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  margin: 20px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.custom-payment-settings-widget .custom-payments .custom-payment-list-widget .custom-payment-list-item-widget .name {
  color: var(--fg);
  padding: 20px 0 20px 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.custom-payment-settings-widget .custom-payments .custom-payment-list-widget .custom-payment-list-item-widget .actions {
  background-color: #fafafa;
  border: 1px solid #f6f8fb;
  border-radius: 10px;
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  padding: 8px;
  display: grid;
}

.custom-payment-settings-widget .custom-payments .custom-payment-list-widget .custom-payment-list-item-widget .actions .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
  color: #4c4e68 !important;
}

.custom-payment-settings-widget .custom-payments .custom-payment-list-widget .custom-payment-list-item-widget .actions .action:first-child {
  padding-left: 8px;
}

.custom-payment-settings-widget .custom-payments .custom-payment-list-widget .custom-payment-list-item-widget .actions .action img {
  width: 26px;
  margin-right: 5px;
}

.custom-payment-settings-widget .add-custom-payment-method-button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  background-color: #0000;
  border-radius: 3px;
  margin: 18px;
}

.custom-payment-edit-widget {
  width: 39vw;
}

@media (max-width: 767px) {
  .custom-payment-edit-widget {
    width: 100%;
  }
}

.custom-payment-edit-widget .header {
  letter-spacing: .1px;
  color: var(--fg);
  padding: 20px 20px 30px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.custom-payment-edit-widget form .form-items .form-input {
  margin: 10px 20px 0;
}

.custom-payment-edit-widget form .form-items .form-input ::placeholder {
  color: #999;
}

.custom-payment-edit-widget form .form-items .form-input .form-switch {
  padding: 0;
}

.custom-payment-edit-widget form .form-items label:first-child {
  color: #27283e;
}

.custom-payment-edit-widget form .form-items label {
  letter-spacing: .1px;
  color: var(--fg);
  color: #1c1d3e;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.custom-payment-edit-widget form .form-items .meta .error {
  padding: 0 15px;
}

.custom-payment-edit-widget form .form-items .form-item:first-child label {
  padding: 0 20px;
}

.custom-payment-edit-widget form .form-items .inline-title label {
  color: #171725;
}

.custom-payment-edit-widget form .form-items .switch label {
  color: #27283e;
  padding: 0 20px;
}

.custom-payment-edit-widget form .form-buttons {
  gap: 8px;
  padding: 20px;
  display: flex;
}

.custom-payment-edit-widget form .form-buttons button {
  width: max-content;
  border-radius: 3px;
  padding: 9.5px 32px;
  font-size: 13px;
}

.custom-payment-edit-widget form .form-buttons .submit-btn {
  color: var(--button-primary-fg);
  background-color: #2563eb;
}

.custom-payment-edit-widget form .form-buttons .submit-btn.disabled {
  color: #fff !important;
  background-color: #93c5fd !important;
}

.custom-payment-edit-widget form .form-buttons .cancel-btn {
  color: #757575;
  background: #fff;
  border: .5px solid #dadada;
  color: #1c1d3e !important;
  background-color: #dee0e6 !important;
}

.online-order-payment-settings-widget {
  display: grid;
}

@media (min-width: 768px) {
  .online-order-payment-settings-widget {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
}

.online-order-payment-settings-widget form {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.online-order-payment-settings-widget form .form-items .form-item:first-child {
  border-radius: 10px 10px 0 0;
}

.online-order-payment-settings-widget form .form-items .switch {
  padding: 0 20px;
}

.online-order-payment-settings-widget form .form-buttons {
  background-color: #f6f8fb;
  border-radius: 0 0 10px 10px;
  padding: 20px;
}

.delete-custom-payment-method {
  background-color: #0c0d3433 !important;
}

.delete-custom-payment-method .swal2-modal {
  width: 340px !important;
  background-color: #fff !important;
  border-radius: 20px !important;
  padding: 15px 0 25px !important;
}

.delete-custom-payment-method .swal2-modal .swal2-header {
  padding: 10px 30px;
  position: relative;
}

.delete-custom-payment-method .swal2-modal .swal2-content {
  white-space: pre-wrap;
  color: #7d7e92;
  margin: 0;
  padding: 0 30px;
  font-size: 14px;
}

.delete-custom-payment-method .swal2-modal .swal2-title {
  width: 100%;
  color: #1c1d3e;
  justify-content: center;
  margin-top: 15px;
  font-size: 20px;
  line-height: 26px;
  margin: 0 !important;
}

.delete-custom-payment-method .swal2-modal .swal2-html-container {
  text-align: center;
  margin: 0 auto;
  position: relative;
}

.delete-custom-payment-method .swal2-modal .swal2-footer {
  border-top: none;
  margin: 0;
  padding: 24px 25px 0;
  font-size: 14px;
}

.delete-custom-payment-method .swal2-modal .swal2-actions {
  flex-direction: column;
  gap: 15px;
  margin: 0;
  padding: 30px 30px 0;
  display: flex;
}

.delete-custom-payment-method .swal2-modal .swal2-actions button {
  text-transform: uppercase;
  width: 100%;
  margin: 0;
  padding: 12px;
  text-align: center !important;
}

.delete-custom-payment-method .swal2-modal .swal2-actions .confirm-button {
  color: var(--button-primary-fg);
  text-transform: capitalize;
  background-color: #2563eb;
  border-radius: 4px;
}

.delete-custom-payment-method .swal2-modal .swal2-actions .cancel-button {
  color: #757575;
  text-transform: capitalize;
  background: #fff;
  border: .5px solid #dadada;
  border-radius: 4px;
  background: none !important;
}

.stripe-settings-widget .stripe-actions {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.stripe-settings-widget .stripe-actions button {
  margin-right: 10px;
}

.stripe-settings-widget .stripe-actions a {
  margin-left: 6px;
  font-family: Poppins, sans-serif;
  font-size: .75rem;
  font-weight: 500;
}

.stripe-settings-widget .stripe-actions a.cancel {
  color: var(--message-error-fg);
}

.stripe-settings-widget .stripe-actions button {
  margin-right: 10px;
}

.stripe-settings-widget .stripe-actions .stripe-account-action {
  background-color: #fff;
  border: 1px solid #60a5fa;
  border-radius: 4px;
  font-weight: 500;
}

.stripe-settings-widget .stripe-actions .stripe-account-action i {
  color: #7d7e92;
}

.stripe-settings-widget .stripe-actions .stripe-account-action span {
  color: #2563eb;
}

.stripe-settings-widget .stripe-accounts {
  grid-gap: 20px;
  display: grid;
}

@media (min-width: 768px) {
  .stripe-settings-widget .stripe-accounts {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
}

.stripe-settings-widget .stripe-accounts .current-external-account, .stripe-settings-widget .stripe-accounts .add-external-account, .stripe-settings-widget .stripe-accounts .payout-schedule, .stripe-settings-widget .stripe-accounts .update-payout-schedule, .stripe-settings-widget .stripe-accounts .connected-account {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.stripe-settings-widget .stripe-accounts .current-external-account form, .stripe-settings-widget .stripe-accounts .add-external-account form, .stripe-settings-widget .stripe-accounts .payout-schedule form, .stripe-settings-widget .stripe-accounts .update-payout-schedule form, .stripe-settings-widget .stripe-accounts .connected-account form {
  padding: 20px 0;
}

.stripe-settings-widget .stripe-accounts .current-external-account .title, .stripe-settings-widget .stripe-accounts .add-external-account .title, .stripe-settings-widget .stripe-accounts .payout-schedule .title, .stripe-settings-widget .stripe-accounts .update-payout-schedule .title, .stripe-settings-widget .stripe-accounts .connected-account .title {
  letter-spacing: .1px;
  color: var(--fg);
  border-bottom: 1px solid #f1f1f5;
  padding: 12px 18px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.stripe-settings-widget .stripe-accounts .current-external-account .form-item, .stripe-settings-widget .stripe-accounts .add-external-account .form-item, .stripe-settings-widget .stripe-accounts .payout-schedule .form-item, .stripe-settings-widget .stripe-accounts .update-payout-schedule .form-item, .stripe-settings-widget .stripe-accounts .connected-account .form-item {
  padding: 0 15px;
}

.stripe-settings-widget .stripe-accounts .current-external-account .actions, .stripe-settings-widget .stripe-accounts .add-external-account .actions, .stripe-settings-widget .stripe-accounts .payout-schedule .actions, .stripe-settings-widget .stripe-accounts .update-payout-schedule .actions, .stripe-settings-widget .stripe-accounts .connected-account .actions {
  background-color: #fafafb;
  border-top: 1px solid #f1f1f5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  flex-direction: row;
  align-items: center;
  padding: 15px 18px;
  display: flex;
}

.stripe-settings-widget .stripe-accounts .current-external-account .actions button, .stripe-settings-widget .stripe-accounts .add-external-account .actions button, .stripe-settings-widget .stripe-accounts .payout-schedule .actions button, .stripe-settings-widget .stripe-accounts .update-payout-schedule .actions button, .stripe-settings-widget .stripe-accounts .connected-account .actions button {
  margin-right: 10px;
}

.stripe-settings-widget .stripe-accounts .current-external-account .actions a, .stripe-settings-widget .stripe-accounts .add-external-account .actions a, .stripe-settings-widget .stripe-accounts .payout-schedule .actions a, .stripe-settings-widget .stripe-accounts .update-payout-schedule .actions a, .stripe-settings-widget .stripe-accounts .connected-account .actions a {
  margin-left: 6px;
  font-family: Poppins, sans-serif;
  font-size: .75rem;
  font-weight: 500;
}

.stripe-settings-widget .stripe-accounts .current-external-account .actions a.cancel, .stripe-settings-widget .stripe-accounts .add-external-account .actions a.cancel, .stripe-settings-widget .stripe-accounts .payout-schedule .actions a.cancel, .stripe-settings-widget .stripe-accounts .update-payout-schedule .actions a.cancel, .stripe-settings-widget .stripe-accounts .connected-account .actions a.cancel {
  color: var(--message-error-fg);
}

.stripe-settings-widget .stripe-accounts .current-external-account .actions button, .stripe-settings-widget .stripe-accounts .add-external-account .actions button, .stripe-settings-widget .stripe-accounts .payout-schedule .actions button, .stripe-settings-widget .stripe-accounts .update-payout-schedule .actions button, .stripe-settings-widget .stripe-accounts .connected-account .actions button {
  margin-right: 10px;
}

.stripe-settings-widget .stripe-accounts .add-external-account .StripeElement {
  padding: 15px 18px;
}

.stripe-settings-widget .stripe-accounts .update-payout-schedule, .stripe-settings-widget .stripe-accounts .payout-schedule {
  margin-top: 20px;
}

.store-sales-report-widget {
  position: relative;
}

.store-sales-report-widget button {
  background-color: unset;
  box-shadow: none;
}

.store-sales-report-widget .store-sales-report-filter-summary {
  display: none;
}

@media (max-width: 767px) {
  .store-sales-report-widget .store-sales-report-filter-summary {
    font-size: 12px;
    display: inline-block;
  }

  .store-sales-report-widget .store-sales-report-filter-summary .date, .store-sales-report-widget .store-sales-report-filter-summary .custom-date-range > *, .store-sales-report-widget .store-sales-report-filter-summary .channel {
    font-weight: 400px;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #dee0e6;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 5px;
    display: table;
  }

  .store-sales-report-widget .store-sales-report-filter-summary .date > span, .store-sales-report-widget .store-sales-report-filter-summary .custom-date-range > * > span, .store-sales-report-widget .store-sales-report-filter-summary .channel > span {
    color: #1c1d3e;
  }
}

.store-sales-report-widget .download-store-sales-report {
  height: min-content;
  border-radius: 5px;
  padding: 10px 24px;
  font-size: 14px;
  line-height: 21px;
  text-decoration: none;
  position: absolute;
  top: 20px;
  right: 0;
  color: #fff !important;
  background-color: #2563eb !important;
}

@media (max-width: 767px) {
  .store-sales-report-widget .download-store-sales-report {
    top: 3px;
  }

  .store-sales-report-widget:not(:has(.download-store-sales-report)) .store-sales-report-filter .filters {
    margin-top: 0;
  }
}

.store-sales-report-widget .store-sales-report-breakdown .header {
  color: #1c1d3e;
  border-bottom: 1px solid #dee0e6;
  margin-bottom: 0;
  padding-bottom: 24px;
  font-size: 18px;
  font-weight: 500;
}

.store-sales-report-widget .store-sales-report-breakdown .no-report {
  text-align: center;
  color: #4c4e68;
  opacity: .5;
  margin: 56px auto;
}

.store-sales-report-widget .store-sales-report-breakdown .breakdown-by-gst .net-sales .label, .store-sales-report-widget .store-sales-report-breakdown .breakdown-by-gst .gst .label {
  padding-left: 16px;
}

.store-sales-report-widget .store-sales-report-breakdown .total-sales, .store-sales-report-widget .store-sales-report-breakdown .total-sales ~ div {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 8px;
  gap: 24px;
  margin-bottom: 24px;
  padding: 24px;
  display: grid;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0000001a;
}

.store-sales-report-widget .store-sales-report-breakdown .total-sales .label, .store-sales-report-widget .store-sales-report-breakdown .total-sales ~ div .label {
  color: #838393;
  font-size: 16px;
}

.store-sales-report-widget .store-sales-report-breakdown .total-sales .value {
  color: #1c1d3e;
  font-size: 34px;
  font-weight: 600;
}

.store-sales-report-widget .store-sales-report-breakdown .total-sales ~ div .key-value-pair {
  grid-template-columns: 150px 1fr;
  grid-template-areas: "label value";
  align-items: center;
  gap: 60px;
  display: grid;
}

.store-sales-report-widget .store-sales-report-breakdown .total-sales ~ div .key-value-pair .label {
  color: #838393;
  grid-area: label;
  font-size: 16px;
  display: flex;
}

.store-sales-report-widget .store-sales-report-breakdown .total-sales ~ div .key-value-pair .label .info {
  margin-left: 4px;
  position: relative;
}

.store-sales-report-widget .store-sales-report-breakdown .total-sales ~ div .key-value-pair .label .info img {
  margin-top: 3px;
}

.store-sales-report-widget .store-sales-report-breakdown .total-sales ~ div .key-value-pair .label .info .info-text {
  visibility: hidden;
  z-index: 2;
  width: 160px;
  color: #1c1d3e;
  text-align: center;
  letter-spacing: .03px;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  position: absolute;
  top: -45px;
  left: 120%;
  box-shadow: 0 8px 12px #091e4226, 0 0 1px #091e424f;
}

.store-sales-report-widget .store-sales-report-breakdown .total-sales ~ div .key-value-pair .label .info .info-text:before {
  content: "";
  z-index: 1;
  background-color: #fff;
  padding: 5px;
  position: absolute;
  top: 47%;
  left: -2%;
  transform: rotate(45deg);
}

.store-sales-report-widget .store-sales-report-breakdown .total-sales ~ div .key-value-pair .label .info:hover .info-text {
  visibility: visible;
}

.store-sales-report-widget .store-sales-report-breakdown .total-sales ~ div .key-value-pair .value {
  color: #1c1d3e;
  font-size: 16px;
  font-weight: 500;
}

@media (max-width: 767px) {
  .store-sales-report-widget .store-sales-report-breakdown .total-sales ~ div .key-value-pair {
    display: unset;
  }

  .store-sales-report-widget .store-sales-report-breakdown .total-sales ~ div .key-value-pair .value {
    word-break: break-all;
  }
}

.uber-eats-menu-widget {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.uber-eats-menu-widget > :not(:first-child) {
  margin-top: 12px;
}

.uber-eats-menu-widget > .header > .label {
  padding: 12px 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
}

.uber-eats-menu-widget > .header > .description {
  color: #7d7e92;
  white-space: pre-line;
}

.uber-eats-menu-widget > .header > .description > .header {
  align-items: center;
  margin-bottom: 12px;
  display: flex;
}

.uber-eats-menu-widget > .header > .description > .header > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  display: flex;
}

.uber-eats-menu-widget > .header > .description > .header > .icon > img {
  height: 100%;
}

.uber-eats-menu-widget > .header > .description > .store-menu-page-link {
  color: #2563eb;
  cursor: pointer;
  text-decoration: underline;
}

.uber-eats-menu-widget > .body {
  flex-direction: column;
  padding-bottom: 20px;
  display: flex;
}

.uber-eats-menu-widget > .body.no-categories {
  height: 100%;
}

.uber-eats-menu-widget > .body > .header {
  grid-template-columns: max-content 1fr max-content;
  grid-auto-flow: column;
  display: grid;
}

.uber-eats-menu-widget > .body > .header .collection-type-dropdown {
  min-width: 164px;
}

.uber-eats-menu-widget > .body > .header > .store-hours-button {
  color: #000;
  cursor: pointer;
  border-right: 1px solid #dee0e6;
  grid-auto-flow: column;
  place-self: center end;
  gap: 7px;
  margin: 0 15px;
  padding: 10px 15px;
  text-decoration: underline;
  display: grid;
}

.uber-eats-menu-widget > .body > .header .edit-prices-button {
  padding: 10px 18px;
}

.uber-eats-menu-widget > .body > .header .edit-prices-button > .label {
  grid-auto-flow: column;
  gap: 7px;
  display: grid;
}

.uber-eats-menu-widget > .body > .categories {
  margin-top: 20px;
}

.uber-eats-menu-widget > .body > .categories > .category {
  all: revert;
  background: #fff;
  border: 1px solid #dee0e6;
  border-radius: 10px;
  margin-top: 20px;
  padding: 20px;
}

.uber-eats-menu-widget > .body > .categories > .category:first-child {
  margin-top: 0;
}

.uber-eats-menu-widget > .body > .categories > .category > .header {
  justify-content: space-between;
  display: flex;
}

.uber-eats-menu-widget > .body > .categories > .category > .header .container > .label {
  display: flex;
}

.uber-eats-menu-widget > .body > .categories > .category > .header .container > .label > .name {
  all: unset;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.uber-eats-menu-widget > .body > .categories > .category > .header .container > .label > .bullet {
  color: #c6c7d1;
  margin: 0 8px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.uber-eats-menu-widget > .body > .categories > .category > .header .container > .label > .item-count {
  color: #7d7e92;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.uber-eats-menu-widget > .body > .categories > .category > .header .container > .description {
  all: unset;
  color: #7d7e92;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.uber-eats-menu-widget > .body > .categories > .category > .header > .icon {
  height: 32px;
  width: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uber-eats-menu-widget > .body > .categories > .category > .header > .icon > img {
  height: 100%;
}

.uber-eats-menu-widget > .body > .categories > .category > .item-specs {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
  margin-top: 24px;
  display: grid;
}

@media (min-width: 1024px) {
  .uber-eats-menu-widget > .body > .categories > .category > .item-specs {
    grid-template-columns: repeat(3, 1fr);
  }
}

.uber-eats-menu-widget > .body > .categories > .category > .item-specs > .item-spec {
  all: unset;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  grid-template-columns: min-content min-content auto;
  grid-template-areas: "image name name"
                       "image price see-modifiers-button"
                       "image description description";
  grid-auto-rows: min-content;
  align-items: center;
  padding: 12px;
  display: grid;
}

.uber-eats-menu-widget > .body > .categories > .category > .item-specs > .item-spec > .image {
  all: unset;
  grid-area: image;
  margin-right: 10px;
}

.uber-eats-menu-widget > .body > .categories > .category > .item-specs > .item-spec > .name {
  all: unset;
  grid-area: name;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
}

.uber-eats-menu-widget > .body > .categories > .category > .item-specs > .item-spec > .price {
  all: unset;
  grid-area: price;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.uber-eats-menu-widget > .body > .categories > .category > .item-specs > .item-spec > .see-modifiers-button {
  color: #2563eb;
  cursor: pointer;
  grid-area: see-modifiers-button;
  align-items: center;
  margin-left: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  display: flex;
}

.uber-eats-menu-widget > .body > .categories > .category > .item-specs > .item-spec > .see-modifiers-button > .icon {
  height: 12px;
  width: 12px;
  filter: brightness(0) saturate() invert(26%) sepia(61%) saturate(4015%) hue-rotate(218deg) brightness(98%) contrast(87%);
  justify-content: center;
  align-items: center;
  display: flex;
}

.uber-eats-menu-widget > .body > .categories > .category > .item-specs > .item-spec > .see-modifiers-button > .icon > img {
  height: 100%;
}

.uber-eats-menu-widget > .body > .categories > .category > .item-specs > .item-spec > .description {
  all: unset;
  color: #7d7e92;
  grid-area: description;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.uber-eats-menu-widget > .body > .no-categories {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uber-eats-menu-widget > .body > .no-categories > :not(:first-child) {
  margin-top: 32px;
}

.uber-eats-menu-widget > .body > .no-categories > .icon {
  height: 64px;
  width: 64px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uber-eats-menu-widget > .body > .no-categories > .icon > img {
  height: 100%;
}

.uber-eats-menu-widget > .body > .no-categories > .title {
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
}

.uber-eats-menu-widget > .body > .no-categories > .description {
  color: #7d7e92;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.uber-eats-menu-widget > .body > .no-categories > .description > .store-menu-page-link {
  color: #2563eb;
  cursor: pointer;
  text-decoration: underline;
}

.uber-eats-menu-widget > .body > .publish-button {
  color: #fff;
  background-color: #2563eb;
  border-radius: 4px;
  margin-top: 20px;
  margin-left: auto;
  padding: 14px 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.uber-eats-menu-item-spec-modifiers-widget-modal {
  width: 90vw;
  max-width: 480px !important;
}

.swal2-container .uber-eats-menu-widget {
  max-width: 364px;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 20px;
  padding: 32px 24px;
}

.swal2-container .uber-eats-menu-widget .swal2-title {
  color: #1c1d3e;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.swal2-container .uber-eats-menu-widget .swal2-content {
  color: #7d7e92;
  margin-top: 16px;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.swal2-container .uber-eats-menu-widget .swal2-actions {
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
  padding: 0 24px;
}

.swal2-container .uber-eats-menu-widget .swal2-actions button {
  border-radius: 4px;
  margin: 0;
  padding: 14px 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.swal2-container .uber-eats-menu-widget .swal2-actions .confirm-button {
  width: 100%;
  color: #fff;
  background-color: #2563eb;
}

.swal2-container .uber-eats-menu-widget .swal2-actions .cancel-button {
  width: 100%;
  color: #4c4e68;
  border: 1px solid #7d7e92;
  background-color: #fff !important;
}

.uber-eats-edit-menu-price-widget {
  height: 100%;
  flex-direction: column;
  display: flex;
  position: relative;
}

.uber-eats-edit-menu-price-widget > :not(:first-child) {
  margin-top: 12px;
}

.uber-eats-edit-menu-price-widget > .header {
  grid-template-columns: 1fr max-content;
  grid-template-areas: "label adjust-all-prices-button"
                       "description adjust-all-prices-button";
  gap: 0 15px;
  display: grid;
}

.uber-eats-edit-menu-price-widget > .header > .label {
  grid-area: label;
  padding: 12px 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
}

.uber-eats-edit-menu-price-widget > .header > .description {
  color: #7d7e92;
  white-space: pre-line;
  grid-area: description;
  display: flex;
}

.uber-eats-edit-menu-price-widget > .header > .description > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  display: flex;
}

.uber-eats-edit-menu-price-widget > .header > .description > .icon > img {
  height: 100%;
}

.uber-eats-edit-menu-price-widget > .header .adjust-all-prices-button {
  height: -moz-fit-content;
  height: fit-content;
  grid-area: adjust-all-prices-button;
  align-self: center;
  padding: 10px 18px;
}

.uber-eats-edit-menu-price-widget > .header .adjust-all-prices-button > .label {
  grid-auto-flow: column;
  gap: 7px;
  display: grid;
}

.uber-eats-edit-menu-price-widget > .body .categories {
  letter-spacing: .0015em;
  text-align: left;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 24px;
}

.uber-eats-edit-menu-price-widget > .body .categories > div:first-child {
  border-radius: 8px 8px 0 0;
}

.uber-eats-edit-menu-price-widget > .body .categories > div:last-child {
  border-radius: 0 0 8px 8px;
}

.uber-eats-edit-menu-price-widget > .body .categories .category {
  border-bottom: none;
  border-radius: 0%;
}

.uber-eats-edit-menu-price-widget > .body .categories .category .header {
  background-color: #f4f4f6;
  grid-template-columns: 4fr repeat(3, 1fr);
  align-items: center;
  gap: 32px;
  padding: 12px 32px;
  font-weight: 500;
  display: grid;
}

.uber-eats-edit-menu-price-widget > .body .categories .category .item-specs > .item-spec:not(:last-child) {
  border-bottom: 1px solid #dee0e6;
}

.uber-eats-edit-menu-price-widget > .body .categories .category .item-specs .item-spec {
  box-shadow: none;
  border: none;
  border-radius: 0;
  margin: 0 32px;
  padding: 16px 0;
}

.uber-eats-edit-menu-price-widget > .body .categories .category .item-specs .item-spec .container.basic-item-spec.deal-item-spec {
  grid-template-columns: 4fr repeat(3, 1fr);
  align-items: center;
  gap: 32px;
  display: grid;
}

.uber-eats-edit-menu-price-widget > .body .categories .category .item-specs .item-spec .container.pizza-item-spec .item-name {
  margin-bottom: 28px;
}

.uber-eats-edit-menu-price-widget > .body .categories .category .item-specs .item-spec .container.pizza-item-spec .size {
  grid-template-columns: 4fr repeat(3, 1fr);
  align-items: center;
  gap: 32px;
  margin-bottom: 16px;
  margin-left: 40px;
  display: grid;
}

.uber-eats-edit-menu-price-widget > .body .categories .category .item-specs .item-spec .container.pizza-item-spec .size:not(:last-child) {
  border-bottom: 1px solid #dee0e6;
  padding-bottom: 20px;
}

.uber-eats-edit-menu-price-widget > .body .categories .category .item-specs .item-spec .input-container {
  max-width: 120px;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  align-items: center;
  padding: 0 2px 0 14px;
  font-size: 16px;
  display: flex;
  box-shadow: 0 1px 2px #1c1d3e0d;
}

.uber-eats-edit-menu-price-widget > .body .categories .category .item-specs .item-spec .input-container.disabled {
  color: #7d7e92;
  background: #f6f8fb;
}

.uber-eats-edit-menu-price-widget > .body .categories .category .item-specs .item-spec .input-container .form-input {
  background: none !important;
}

.uber-eats-edit-menu-price-widget > .body .categories .category .item-specs .item-spec .input-container .form-input input {
  box-sizing: border-box;
  width: 100%;
  background: none;
  border: none;
  outline: none;
  padding: 10px 0 10px 6px;
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
}

.uber-eats-edit-menu-price-widget > .body .categories .category .item-specs .item-spec .input-container .form-input input:disabled {
  background: none !important;
}

.uber-eats-edit-menu-price-widget > .back-to-top-button {
  background-color: #fff;
  border-radius: 30px;
  padding: 13px;
  position: fixed;
  top: 90vh;
  right: 50px;
  box-shadow: 0 0 3px #687a9b40;
}

.uber-eats-edit-menu-price-widget .end-of-list {
  text-align: center;
  color: #7d7e92;
  padding-bottom: 20px;
}

.adjust-all-prices-modal {
  box-sizing: border-box;
  padding: 32px;
}

.adjust-all-prices-modal .header {
  text-align: center;
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
}

.adjust-all-prices-modal .description {
  letter-spacing: .0025em;
  text-align: left;
  color: #4c4e68;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: flex;
}

.adjust-all-prices-modal .description img {
  margin-right: 8px;
}

.adjust-all-prices-modal .description span {
  font-weight: 600;
}

.adjust-all-prices-modal .radio-group {
  margin: 32px 0;
  display: flex;
}

.adjust-all-prices-modal .forms {
  display: flex;
}

.adjust-all-prices-modal .forms > div:first-of-type {
  margin-right: 24px;
}

.adjust-all-prices-modal .forms .input-container {
  min-width: 280px;
  color: #c6c7d1;
  border-radius: 8px;
  align-items: center;
  margin: 6px 0;
  padding-left: 14px;
  font-size: 16px;
  display: flex;
  box-shadow: 0 1px 2px #1c1d3e0d;
  border: 1px solid #dee0e6 !important;
}

.adjust-all-prices-modal .forms .input-container .form-item {
  width: 100%;
}

.adjust-all-prices-modal .forms .input-container .form-item input {
  box-sizing: border-box;
  width: 100%;
  background: none;
  border: none;
  outline: none;
  padding: 10px 0 10px 6px;
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
}

.adjust-all-prices-modal .forms .input-container.percent {
  position: relative;
}

.adjust-all-prices-modal .forms .input-container.percent .percent-sign {
  position: absolute;
  right: 30px;
}

.adjust-all-prices-modal .actions {
  justify-content: space-evenly;
  margin-top: 36px;
  display: flex;
}

.adjust-all-prices-modal .actions button {
  min-width: 260px;
  height: 52px;
}

.adjust-all-prices-modal .actions .cancel-button {
  color: #7d7e92;
  background-color: #0000;
  border: 1px solid #7d7e92;
}

.uber-eats-menu-store-hours-widget {
  max-width: 362px;
  width: 100vw;
  color: #1c1d3e;
  padding: 32px 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  box-shadow: 0 0 3px #687a9b40;
}

.uber-eats-menu-store-hours-widget > .close-button {
  height: 13px;
  width: 13px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.uber-eats-menu-store-hours-widget > .close-button > img {
  height: 100%;
}

.uber-eats-menu-store-hours-widget > .header {
  padding-bottom: 14px;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
}

.uber-eats-menu-store-hours-widget > .body {
  margin-top: 16px;
}

.uber-eats-menu-store-hours-widget > .body > .day-of-week {
  justify-content: space-between;
  margin-top: 20px;
  display: flex;
}

.uber-eats-menu-store-hours-widget > .body > .day-of-week:first-child {
  margin-top: 0;
}

.uber-eats-menu-store-hours-widget > .body > .day-of-week > .label {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.uber-eats-menu-store-hours-widget > .body > .day-of-week > .daily-hours {
  text-align: right;
}

.uber-eats-menu-item-spec-modifiers-widget {
  color: #1c1d3e;
  padding: 32px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.uber-eats-menu-item-spec-modifiers-widget > .close-button {
  height: 13px;
  width: 13px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.uber-eats-menu-item-spec-modifiers-widget > .close-button > img {
  height: 100%;
}

.uber-eats-menu-item-spec-modifiers-widget > .header {
  padding-bottom: 14px;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
}

.uber-eats-menu-basic-item-spec-modifiers-view-widget {
  margin-top: 16px;
}

.uber-eats-menu-basic-item-spec-modifiers-view-widget > :not(:first-child) {
  margin-top: 32px;
}

.uber-eats-menu-basic-item-spec-modifiers-view-widget > .modifier-spec > .label {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.uber-eats-menu-basic-item-spec-modifiers-view-widget > .modifier-spec > .options {
  margin-top: 16px;
}

.uber-eats-menu-basic-item-spec-modifiers-view-widget > .modifier-spec > .options > :not(:first-child) {
  margin-top: 8px;
}

.uber-eats-menu-basic-item-spec-modifiers-view-widget > .modifier-spec > .options > .modifier-option {
  justify-content: space-between;
  display: flex;
}

.uber-eats-menu-pizza-spec-modifiers-view-widget {
  margin-top: 16px;
}

.uber-eats-menu-pizza-spec-modifiers-view-widget > :not(:first-child) {
  margin-top: 32px;
}

.uber-eats-menu-pizza-spec-modifiers-view-widget > .sizes > .label {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.uber-eats-menu-pizza-spec-modifiers-view-widget > .sizes > .options {
  margin-top: 16px;
}

.uber-eats-menu-pizza-spec-modifiers-view-widget > .sizes > .options > :not(:first-child) {
  margin-top: 24px;
}

.uber-eats-menu-pizza-spec-modifiers-view-widget > .sizes > .options > .size > .label {
  justify-content: space-between;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  display: flex;
}

.uber-eats-menu-pizza-spec-modifiers-view-widget > .sizes > .options > .size > .size-toppings {
  color: #4c4e68;
  margin-top: 16px;
}

.uber-eats-menu-pizza-spec-modifiers-view-widget > .sizes > .options > .size > .size-toppings > :not(:first-child) {
  margin-top: 8px;
}

.uber-eats-menu-pizza-spec-modifiers-view-widget > .sizes > .options > .size > .size-toppings > .size-topping {
  justify-content: space-between;
  display: flex;
}

.uber-eats-menu-pizza-spec-modifiers-view-widget > .remove-toppings > .label {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.uber-eats-menu-pizza-spec-modifiers-view-widget > .remove-toppings > .options {
  margin-top: 16px;
}

.uber-eats-menu-pizza-spec-modifiers-view-widget > .remove-toppings > .options > :not(:first-child) {
  margin-top: 8px;
}

.uber-eats-menu-pizza-spec-modifiers-view-widget > .remove-toppings > .options > .remove-topping {
  justify-content: space-between;
  display: flex;
}

.uber-eats-menu-deal-spec-modifiers-view-widget {
  margin-top: 16px;
}

.uber-eats-menu-deal-spec-modifiers-view-widget > :not(:first-child), .uber-eats-menu-deal-spec-modifiers-view-widget > .deal-pizza-specs > :not(:first-child) {
  margin-top: 32px;
}

.uber-eats-menu-deal-spec-modifiers-view-widget > .deal-pizza-specs > .deal-pizza-spec > .label {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.uber-eats-menu-deal-spec-modifiers-view-widget > .deal-pizza-specs > .deal-pizza-spec > .options {
  margin-top: 16px;
}

.uber-eats-menu-deal-spec-modifiers-view-widget > .deal-pizza-specs > .deal-pizza-spec > .options > :not(:first-child) {
  margin-top: 8px;
}

.uber-eats-menu-deal-spec-modifiers-view-widget > .deal-pizza-specs > .deal-pizza-spec > .options > .deal-pizza-spec-pizza-spec {
  justify-content: space-between;
  display: flex;
}

.uber-eats-menu-deal-spec-modifiers-view-widget > .deal-item-specs > :not(:first-child) {
  margin-top: 32px;
}

.uber-eats-menu-deal-spec-modifiers-view-widget > .deal-item-specs > .deal-item-spec > .label {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.uber-eats-menu-deal-spec-modifiers-view-widget > .deal-item-specs > .deal-item-spec > .modifier-specs {
  margin-top: 16px;
}

.uber-eats-menu-deal-spec-modifiers-view-widget > .deal-item-specs > .deal-item-spec > .modifier-specs > :not(:first-child) {
  margin-top: 24px;
}

.uber-eats-menu-deal-spec-modifiers-view-widget > .deal-item-specs > .deal-item-spec > .modifier-specs > .modifier-spec > .label {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.uber-eats-menu-deal-spec-modifiers-view-widget > .deal-item-specs > .deal-item-spec > .modifier-specs > .modifier-spec > .options {
  margin-top: 16px;
}

.uber-eats-menu-deal-spec-modifiers-view-widget > .deal-item-specs > .deal-item-spec > .modifier-specs > .modifier-spec > .options > :not(:first-child) {
  margin-top: 8px;
}

.uber-eats-menu-deal-spec-modifiers-view-widget > .deal-item-specs > .deal-item-spec > .modifier-specs > .modifier-spec > .options > .modifier-option {
  justify-content: space-between;
  display: flex;
}

.uber-eats-menu-deal-spec-modifiers-view-widget > .modifier-specs > :not(:first-child) {
  margin-top: 32px;
}

.uber-eats-menu-deal-spec-modifiers-view-widget > .modifier-specs > .modifier-spec > .label {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.uber-eats-menu-deal-spec-modifiers-view-widget > .modifier-specs > .modifier-spec > .options {
  margin-top: 16px;
}

.uber-eats-menu-deal-spec-modifiers-view-widget > .modifier-specs > .modifier-spec > .options > :not(:first-child) {
  margin-top: 8px;
}

.uber-eats-menu-deal-spec-modifiers-view-widget > .modifier-specs > .modifier-spec > .options > .modifier-option {
  justify-content: space-between;
  display: flex;
}

.online-order-history-uber-eats-order-list-item-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  margin-top: 20px;
  padding: 25px;
  box-shadow: 0 0 3px #687a9b40;
}

.online-order-history-uber-eats-order-list-item-widget > :not(:first-child) {
  margin-top: 18px;
}

.online-order-history-uber-eats-order-list-item-widget > .header {
  grid-template-columns: min-content 1fr min-content;
  grid-template-areas: "collection-type uber-eats-icon order-status"
                       "placed-time placed-time grand-total";
  display: grid;
}

.online-order-history-uber-eats-order-list-item-widget > .header > .collection-type {
  color: var(--fg);
  color: #000521;
  white-space: nowrap;
  grid-area: collection-type;
  align-self: center;
  margin-bottom: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.online-order-history-uber-eats-order-list-item-widget > .header > .uber-eats-icon {
  height: 24px;
  width: 24px;
  grid-area: uber-eats-icon;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  display: flex;
}

.online-order-history-uber-eats-order-list-item-widget > .header > .uber-eats-icon > img {
  height: 100%;
}

.online-order-history-uber-eats-order-list-item-widget > .header > .order-status {
  white-space: nowrap;
  grid-area: order-status;
  place-self: center end;
  padding: 5px 8px;
  font-size: 13px;
}

.online-order-history-uber-eats-order-list-item-widget > .header > .placed-time {
  white-space: nowrap;
  color: #6c6c6c;
  font-size: 13px;
  font-style: normal;
  font-weight: 300px;
  grid-area: placed-time;
  align-self: center;
  margin-bottom: 10px;
}

.online-order-history-uber-eats-order-list-item-widget > .header > .grand-total {
  letter-spacing: .1px;
  color: var(--fg);
  text-align: right;
  grid-area: grand-total;
  align-self: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.online-order-history-uber-eats-order-list-item-widget > .body {
  border-top: 1px dashed #e9ecf3;
  border-bottom: 1px dashed #e9ecf3;
  padding: 10px 0;
}

.online-order-history-uber-eats-order-list-item-widget > .body > :not(:first-child) {
  margin-top: 5px;
}

.online-order-history-uber-eats-order-list-item-widget > .body > * {
  display: flex;
}

.online-order-history-uber-eats-order-list-item-widget > .body > * > .icon {
  height: 18px;
  width: 18px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.online-order-history-uber-eats-order-list-item-widget > .body > * > .icon > img {
  height: 100%;
}

.online-order-history-uber-eats-order-list-item-widget > .body > * > .info {
  margin-left: 10px;
}

.online-order-history-uber-eats-order-list-item-widget > .body > .delivery-address {
  color: #7d7e92;
}

.online-order-history-uber-eats-order-list-item-widget > .body > .pickup-time {
  color: #1e40af;
}

.online-order-history-uber-eats-order-list-item-widget > .body > .pickup-time.past-pickup-time {
  color: #9f1239;
}

.online-order-history-uber-eats-order-list-item-widget > .body > .delivery-status {
  color: #2563eb;
}

.online-order-history-uber-eats-order-list-item-widget > .body > .delivery-status.completed {
  color: #3f9c86;
}

.online-order-history-uber-eats-order-list-item-widget > .body > .delivery-status.failed {
  color: #9f1239;
}

.online-order-history-uber-eats-order-list-item-widget > .body > .delivery-status > .info {
  display: flex;
}

.online-order-history-uber-eats-order-list-item-widget > .body > .delivery-status > .info > .delivery-channel {
  margin-right: 10px;
}

.online-order-history-uber-eats-order-list-item-widget > .body > .delivery-status > .info > .time {
  font-weight: 500;
}

.online-order-history-uber-eats-order-list-item-widget > .body > .more-info.cancelled, .online-order-history-uber-eats-order-list-item-widget > .body > .more-info.rejected {
  color: #9f1239;
}

.online-order-history-uber-eats-order-list-item-widget > .actions {
  padding-top: 15px;
}

.online-order-history-uber-eats-order-list-item-widget > .actions > .view-button {
  color: #60a5fa;
  background-color: #0000;
  border: 1px solid;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.uber-eats-order-view-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  box-shadow: 0 0 3px #687a9b40;
}

.uber-eats-order-view-widget > :not(:first-child) {
  border-top: 1px dashed #dee0e6;
  justify-content: space-between;
  margin-top: 16px;
  padding-top: 16px;
  display: flex;
}

.uber-eats-order-view-widget > :not(:first-child) > .label {
  align-items: center;
  display: flex;
}

.uber-eats-order-view-widget > :not(:first-child) > .label > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  display: flex;
}

.uber-eats-order-view-widget > :not(:first-child) > .label > .icon > img {
  height: 100%;
}

.uber-eats-order-view-widget > .items > :not(:first-child) {
  margin-top: 16px;
}

.uber-eats-order-view-widget > .items > .label {
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  display: flex;
}

.uber-eats-order-view-widget > .items > .label > .icon {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  display: flex;
}

.uber-eats-order-view-widget > .items > .label > .icon > img {
  height: 100%;
}

.uber-eats-order-view-widget > .items > .item-summary-view-widget:not(:last-child) {
  border-bottom: 1px dashed #dee0e6;
  padding-bottom: 16px;
}

.uber-eats-order-view-widget > .delivery-address > .value {
  color: #7d7e92;
}

.uber-eats-order-view-widget > .payment-type > .value {
  color: #7d7e92;
  align-items: center;
  display: flex;
}

.uber-eats-order-view-widget > .payment-type > .value > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  display: flex;
}

.uber-eats-order-view-widget > .payment-type > .value > .icon > img {
  height: 100%;
}

.uber-eats-order-view-widget > .customer > .value {
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}

.uber-eats-order-view-widget > .comments {
  flex-direction: column;
}

.uber-eats-order-view-widget > .comments > .value {
  color: #7d7e92;
  margin-left: 32px;
}

.uber-eats-order-view-widget > .amounts {
  color: #7d7e92;
  flex-direction: column;
  font-weight: 500;
}

.uber-eats-order-view-widget > .amounts > :not(:first-child) {
  margin-top: 16px;
}

.uber-eats-order-view-widget > .amounts > * {
  justify-content: space-between;
  display: flex;
}

.uber-eats-order-view-widget > .grand-total {
  color: #2563eb;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.uber-eats-order-view-widget > .gst-amount {
  color: #7d7e92;
  border-top: none;
  padding-top: 12px;
}

.pos-order-history-list-item-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  margin-top: 20px;
  padding: 15px;
  box-shadow: 0 0 3px #687a9b40;
}

.pos-order-history-list-item-widget > :not(:first-child) {
  margin-top: 18px;
}

.pos-order-history-list-item-widget > .header {
  grid-template-columns: min-content 1fr min-content;
  grid-template-areas: "status-indicator collection-type order-status"
                       ". placed-date-time grand-total";
  display: grid;
}

.pos-order-history-list-item-widget > .header .status-indicator {
  width: 26px;
  height: 26px;
  grid-area: status-indicator;
  align-self: center;
  margin: 0 15px;
}

.pos-order-history-list-item-widget > .header .status-indicator.success {
  background-color: #10b981;
}

.pos-order-history-list-item-widget > .header .status-indicator.failure {
  background-color: #f43f5e;
}

.pos-order-history-list-item-widget > .header .collection-type {
  color: #000521;
  grid-area: collection-type;
  align-self: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.pos-order-history-list-item-widget > .header .order-status {
  white-space: nowrap;
  grid-area: order-status;
  place-self: center end;
  margin-right: 10px;
  padding: 5px 8px;
  font-size: 13px;
}

.pos-order-history-list-item-widget > .header .grand-total {
  text-align: right;
  color: #171725;
  grid-area: grand-total;
  align-self: center;
  margin-top: 10px;
  padding-right: 20px;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.pos-order-history-list-item-widget > .header .placed-date-time {
  white-space: nowrap;
  color: #6c6c6c;
  grid-area: placed-date-time;
  align-self: center;
  margin-top: 10px;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
}

.pos-order-history-list-item-widget > .body {
  margin-left: 50px;
}

.pos-order-history-list-item-widget > .body > * {
  margin-top: 10px;
  display: flex;
}

.pos-order-history-list-item-widget > .body > * > .icon {
  height: 18px;
  width: 18px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pos-order-history-list-item-widget > .body > * > .icon > img {
  height: 100%;
}

.pos-order-history-list-item-widget > .body > * > .info {
  color: #171725;
  margin-left: 10px;
}

.pos-order-history-list-item-widget > .body .pos-order-delivery-status-widget {
  margin-bottom: 15px;
}

.pos-order-history-list-item-widget .actions {
  flex-direction: row;
  margin-left: 50px;
  display: flex;
}

.pos-order-history-list-item-widget .actions .view-pos-order {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  background-color: #0000;
  border-radius: 5px;
  padding: 5px 15px;
}

.online-order-history-website-order-list-item-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  margin-top: 20px;
  padding: 25px;
  box-shadow: 0 0 3px #687a9b40;
}

.online-order-history-website-order-list-item-widget > :not(:first-child) {
  margin-top: 18px;
}

.online-order-history-website-order-list-item-widget > .header {
  grid-template-columns: min-content 1fr min-content;
  grid-template-areas: "collection-type website-order-icon order-status"
                       "placed-time placed-time grand-total";
  display: grid;
}

.online-order-history-website-order-list-item-widget > .header .collection-type {
  color: var(--fg);
  color: #000521;
  white-space: nowrap;
  grid-area: collection-type;
  align-self: center;
  margin-bottom: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.online-order-history-website-order-list-item-widget > .header .website-order-icon {
  grid-area: website-order-icon;
  margin-left: 5px;
}

.online-order-history-website-order-list-item-widget > .header .order-status {
  white-space: nowrap;
  grid-area: order-status;
  place-self: center end;
  padding: 5px 8px;
  font-size: 13px;
}

.online-order-history-website-order-list-item-widget > .header .placed-time {
  white-space: nowrap;
  color: #6c6c6c;
  font-size: 13px;
  font-style: normal;
  font-weight: 300px;
  grid-area: placed-time;
  align-self: center;
  margin-bottom: 10px;
}

.online-order-history-website-order-list-item-widget > .header .grand-total {
  letter-spacing: .1px;
  color: var(--fg);
  text-align: right;
  grid-area: grand-total;
  align-self: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.online-order-history-website-order-list-item-widget > .body {
  border-top: 1px dashed #e9ecf3;
  border-bottom: 1px dashed #e9ecf3;
  padding: 10px 0;
}

.online-order-history-website-order-list-item-widget > .body > :not(:first-child) {
  margin-top: 5px;
}

.online-order-history-website-order-list-item-widget > .body > * {
  display: flex;
}

.online-order-history-website-order-list-item-widget > .body > * > .icon {
  height: 18px;
  width: 18px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.online-order-history-website-order-list-item-widget > .body > * > .icon > img {
  height: 100%;
}

.online-order-history-website-order-list-item-widget > .body > * > .info {
  margin-left: 10px;
}

.online-order-history-website-order-list-item-widget > .body .delivery-status {
  margin: 15px 0;
  font-weight: 500;
}

.online-order-history-website-order-list-item-widget > .body .delivery-status > .icon {
  width: 18px;
}

.online-order-history-website-order-list-item-widget > .body .delivery-status.in-house {
  color: var(--message-disabled-text);
  display: flex;
}

.online-order-history-website-order-list-item-widget > .body .delivery-status.in-house .label {
  margin-left: 10px;
}

.online-order-history-website-order-list-item-widget > .body .delivery-status.uber {
  display: flex;
}

.online-order-history-website-order-list-item-widget > .body .delivery-status.uber .uber-icon {
  width: 30px;
  margin: 0 10px;
}

.online-order-history-website-order-list-item-widget > .body .delivery-status.uber .label.ongoing {
  color: var(--message-info-fg);
}

.online-order-history-website-order-list-item-widget > .body .delivery-status.uber .label.failed {
  color: var(--message-error-text);
}

.online-order-history-website-order-list-item-widget > .body .delivery-status.uber .label.success {
  color: var(--message-success-text);
}

.online-order-history-website-order-list-item-widget .actions {
  flex-direction: row;
  display: flex;
}

.online-order-history-website-order-list-item-widget .actions button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  box-shadow: none;
  background-color: #0000;
  border-radius: 4px;
  margin-right: 10px;
  padding: 4px 15px;
  font-size: 12px;
  font-weight: 400;
}

.online-order-history-website-order-list-item-widget .actions .accept-order {
  border: 1px solid var(--button-success-bg);
  color: #0e9347;
  background-color: #0000;
}

.online-order-history-website-order-list-item-widget .actions .reject-order {
  color: #fc5a5a;
  background-color: #0000;
  border: 1px solid #fc5a5a;
}

.online-order-history-website-order-list-item-widget .actions .options {
  margin-top: 2rem;
}

.online-order-history-google-order-list-item-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  margin-top: 20px;
  padding: 25px;
  box-shadow: 0 0 3px #687a9b40;
}

.online-order-history-google-order-list-item-widget > :not(:first-child) {
  margin-top: 18px;
}

.online-order-history-google-order-list-item-widget > .header {
  grid-template-columns: min-content 1fr min-content;
  grid-template-areas: "collection-type google-order-icon order-status"
                       "placed-time placed-time grand-total";
  display: grid;
}

.online-order-history-google-order-list-item-widget > .header .collection-type {
  color: var(--fg);
  color: #000521;
  white-space: nowrap;
  grid-area: collection-type;
  align-self: center;
  margin-bottom: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.online-order-history-google-order-list-item-widget > .header .google-order-icon {
  height: 24px;
  width: 24px;
  grid-area: google-order-icon;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  display: flex;
}

.online-order-history-google-order-list-item-widget > .header .google-order-icon > img {
  height: 100%;
}

.online-order-history-google-order-list-item-widget > .header .order-status {
  white-space: nowrap;
  grid-area: order-status;
  place-self: center end;
  padding: 5px 8px;
  font-size: 13px;
}

.online-order-history-google-order-list-item-widget > .header .placed-time {
  white-space: nowrap;
  color: #6c6c6c;
  font-size: 13px;
  font-style: normal;
  font-weight: 300px;
  grid-area: placed-time;
  align-self: center;
  margin-bottom: 10px;
}

.online-order-history-google-order-list-item-widget > .header .grand-total {
  letter-spacing: .1px;
  color: var(--fg);
  text-align: right;
  grid-area: grand-total;
  align-self: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.online-order-history-google-order-list-item-widget > .body {
  border-top: 1px dashed #e9ecf3;
  border-bottom: 1px dashed #e9ecf3;
  padding: 10px 0;
}

.online-order-history-google-order-list-item-widget > .body > :not(:first-child) {
  margin-top: 5px;
}

.online-order-history-google-order-list-item-widget > .body > * {
  display: flex;
}

.online-order-history-google-order-list-item-widget > .body > * > .icon {
  height: 18px;
  width: 18px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.online-order-history-google-order-list-item-widget > .body > * > .icon > img {
  height: 100%;
}

.online-order-history-google-order-list-item-widget > .body > * > .info {
  margin-left: 10px;
}

.online-order-history-google-order-list-item-widget .actions {
  flex-direction: row;
  display: flex;
}

.online-order-history-google-order-list-item-widget .actions button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  box-shadow: none;
  background-color: #0000;
  border-radius: 4px;
  margin-right: 10px;
  padding: 4px 15px;
  font-size: 12px;
  font-weight: 400;
}

.online-order-history-google-order-list-item-widget .actions .accept-order {
  border: 1px solid var(--button-success-bg);
  color: #0e9347;
  background-color: #0000;
}

.online-order-history-google-order-list-item-widget .actions .reject-order {
  color: #fc5a5a;
  background-color: #0000;
  border: 1px solid #fc5a5a;
}

.online-order-history-google-order-list-item-widget .actions .options {
  margin-top: 2rem;
}

@media (max-width: 767px) {
  .store-sales-report-filter.desktop-view {
    display: none;
  }
}

.store-sales-report-filter .header {
  display: flex;
}

.store-sales-report-filter .header .title {
  letter-spacing: .1px;
  color: var(--fg);
  flex: 1;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.store-sales-report-filter .header img {
  cursor: pointer;
}

@media (min-width: 768px) {
  .store-sales-report-filter .header {
    display: none;
  }
}

.store-sales-report-filter .filters {
  grid-template-rows: auto;
  grid-template-columns: 240px 240px;
  gap: 20px;
  margin-bottom: 15px;
  display: grid;
}

@media (max-width: 767px) {
  .store-sales-report-filter .filters {
    flex-direction: column;
    grid-template-columns: 1fr 1fr;
    padding: 30px 30px 0;
    display: flex;
  }
}

.store-sales-report-filter .filters > .form-item label {
  color: #7d7e92;
}

.store-sales-report-filter .date-picker .form-iso-date-picker-input {
  grid-template-rows: auto;
  grid-template-columns: 240px 240px;
  gap: 20px;
  margin: 20px 0;
  display: grid;
}

@media (max-width: 767px) {
  .store-sales-report-filter .date-picker .form-iso-date-picker-input {
    flex-direction: column;
    grid-template-columns: 1fr 1fr;
    padding: 0 30px 30px;
    display: flex;
  }
}

.store-sales-report-filter .date-picker .form-iso-date-picker-input .sub-label {
  color: #7d7e92;
  text-align: center;
  position: absolute;
  top: 150%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 767px) {
  .store-sales-report-filter .date-picker .form-iso-date-picker-input .sub-label {
    top: 100%;
  }
}

.store-sales-report-filter .date-picker .form-iso-date-picker-input .DayPicker {
  border-radius: 8px;
  margin-top: 10px;
  margin-left: 25px;
}

@media (max-width: 767px) {
  .store-sales-report-filter .date-picker .form-iso-date-picker-input .DayPicker {
    margin-left: 0;
  }
}

.store-sales-report-filter .date-picker .form-iso-date-picker-input .DayPicker-NavButton--prev {
  background-image: url("icon-chevron-left.dae29227.svg");
  margin-right: 1.5em;
}

.store-sales-report-filter .date-picker .form-iso-date-picker-input .DayPicker-NavButton--next {
  background-image: url("icon-chevron-right.e80783e2.svg");
}

.store-sales-report-filter .date-picker .form-iso-date-picker-input .current-date .DayPicker-Day--today {
  color: #fff !important;
  outline-offset: -5px !important;
  background-color: #2563eb !important;
  border-radius: 25% !important;
}

.store-sales-report-filter .date-picker .form-iso-date-picker-input .selected-date .DayPicker-Day--today {
  color: #1c1d3e;
  font-weight: normal;
}

.store-sales-report-filter .date-picker .form-iso-date-picker-input .selected-date .DayPicker-Day--highlighted {
  color: #fff !important;
  outline-offset: -5px !important;
  background-color: #2563eb !important;
  border-radius: 25% !important;
}

.store-sales-report-filter .date-picker .form-iso-date-picker-input .DayPicker-WeekdaysRow {
  text-transform: uppercase;
}

.store-sales-report-filter .date-picker .form-iso-date-picker-input .DayPicker-WeekdaysRow .DayPicker-Weekday:first-child:after, .store-sales-report-filter .date-picker .form-iso-date-picker-input .DayPicker-WeekdaysRow .DayPicker-Weekday:nth-child(2):after {
  content: "N";
}

.store-sales-report-filter .date-picker .form-iso-date-picker-input .DayPicker-WeekdaysRow .DayPicker-Weekday:nth-child(3):after {
  content: "E";
}

.store-sales-report-filter .date-picker .form-iso-date-picker-input .DayPicker-WeekdaysRow .DayPicker-Weekday:nth-child(4):after {
  content: "D";
}

.store-sales-report-filter .date-picker .form-iso-date-picker-input .DayPicker-WeekdaysRow .DayPicker-Weekday:nth-child(5):after {
  content: "U";
}

.store-sales-report-filter .date-picker .form-iso-date-picker-input .DayPicker-WeekdaysRow .DayPicker-Weekday:nth-child(6):after {
  content: "I";
}

.store-sales-report-filter .date-picker .form-iso-date-picker-input .DayPicker-WeekdaysRow .DayPicker-Weekday:nth-child(7):after {
  content: "T";
}

.store-sales-report-filter .month-picker {
  width: 240px;
  margin-bottom: 15px;
}

.pos-terminal-home-page-settings-widget .form-items {
  flex-wrap: wrap;
  display: flex;
  overflow: hidden;
}

.pos-terminal-home-page-settings-widget .form-item {
  margin-left: 24px;
}

.pos-terminal-home-page-settings-widget .form-item .form-input .header {
  background: linear-gradient(#f2f7fd 0%, #fff 69.29%), #fff;
  flex-direction: column;
}

.pos-terminal-home-page-settings-widget .form-item .form-input .header .label {
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
}

.pos-terminal-home-page-settings-widget .form-item .form-input .color {
  box-sizing: border-box;
}

.actions-toggle-widget {
  position: relative;
}

.actions-toggle-widget .actions-toggle {
  text-align: center;
  height: 27px;
  cursor: pointer;
  background: #e9e9ec;
  border-radius: 8px;
  padding: 5px 14px;
  font-size: 15px;
  font-weight: 900;
}

.actions-toggle-widget .actions {
  width: max-content;
  background-color: #fff;
  border-radius: 10px;
  position: absolute;
  top: 42px;
  right: 0;
  box-shadow: 0 0 6px #687a9b80;
}

.actions-toggle-widget .actions > .action {
  border-top: 1px solid #dee0e6;
}

.actions-toggle-widget .actions > .action:first-child {
  border-top: 0;
  margin-top: 0;
}

.actions-toggle-widget .action {
  cursor: pointer;
  padding: 16px;
}

.anz-worldline-delete-integration-alert.warning.alert-widget {
  max-width: unset;
  width: 50vw;
  min-width: 357px;
  max-width: 573px;
}

.anz-worldline-delete-integration-alert.warning.alert-widget > .content {
  text-align: left;
}

.anz-worldline-delete-integration-alert.warning.alert-widget > .actions {
  margin-top: unset;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "cancel-button proceed-button";
  display: grid;
}

.anz-worldline-delete-integration-alert.warning.alert-widget > .actions .proceed-button {
  grid-area: proceed-button;
}

.anz-worldline-delete-integration-alert.warning.alert-widget > .actions .cancel-button {
  grid-area: cancel-button;
}

@media (max-width: 767px) {
  .anz-worldline-delete-integration-alert.warning.alert-widget {
    width: 90vw;
    min-width: unset;
  }

  .anz-worldline-delete-integration-alert.warning.alert-widget > .actions {
    display: flex;
  }
}

body:has(.email-order-invoice-widget) {
  overflow: hidden;
}

.email-order-invoice-widget {
  max-height: calc(100vh - 100px);
  grid-template-columns: minmax(0, 570px);
  display: grid;
}

.email-order-invoice-widget .header {
  grid-template-columns: 1fr min-content;
  grid-template-areas: "title button";
  margin: 32px;
  display: grid;
}

.email-order-invoice-widget .header .title {
  color: #171725;
  grid-area: title;
  align-self: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}

.email-order-invoice-widget .header .close-button {
  cursor: pointer;
  grid-area: button;
  align-self: center;
}

.email-order-invoice-widget form {
  margin: 0 32px;
}

.email-order-invoice-widget form label {
  color: #1c1d3e;
}

.email-order-invoice-widget form .react-tagsinput-tag {
  color: #3b825d;
  margin-bottom: 12px;
}

.email-order-invoice-widget form .react-tagsinput-input[value=""] {
  color: #6c6c6c;
}

.email-order-invoice-widget form .react-tagsinput-input {
  color: #1c1d3e;
}

.email-order-invoice-widget .preview {
  max-height: calc(100vh - 482px);
  display: grid;
  overflow: auto;
}

.email-order-invoice-widget .preview > .label {
  color: #1c1d3e;
  margin: 32px 32px 8px;
}

.email-order-invoice-widget .preview .container {
  max-width: 506px;
  scrollbar-width: none;
  border: 1px solid #dee0e6;
  border-radius: 12px;
  justify-self: center;
  overflow-y: auto;
}

.email-order-invoice-widget .preview .container .export {
  overscroll-behavior: contain;
}

.email-order-invoice-widget .preview .container .secondary-font {
  color: #7d7e92;
  font-size: 12px;
  line-height: 18px;
}

.email-order-invoice-widget .preview .container .normal-font {
  color: #27283e;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.email-order-invoice-widget .preview .container .normal-bold-font {
  color: #27283e;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.email-order-invoice-widget .preview .container .text-align-right {
  text-align: right;
}

.email-order-invoice-widget .preview .container .text-align-center {
  text-align: center;
}

.email-order-invoice-widget .preview .container .dot {
  width: 4px;
  color: #27283e;
  cursor: inherit;
  margin: 0 10px;
  font-size: 10px;
  font-weight: 600;
  line-height: 18px;
  text-decoration: none;
}

.email-order-invoice-widget .preview .container .border-top {
  border-top: 1px solid #dee0e6;
}

.email-order-invoice-widget .preview .container .color-green {
  color: #3f9c86;
}

.email-order-invoice-widget .preview .container .color-red {
  color: #9f1239;
}

.email-order-invoice-widget .preview .container .email-banner {
  grid-gap: 16px;
  background-color: #4c4e68;
  border-radius: 10px 10px 0 0;
  grid-template-columns: 50px minmax(1px, 542px);
  padding: 39px 30px;
  display: grid;
}

.email-order-invoice-widget .preview .container .email-banner img {
  width: 50px;
  height: 50px;
}

.email-order-invoice-widget .preview .container .email-banner .email-subject {
  color: #fff;
  text-align: left;
  vertical-align: middle;
  margin-left: 16px;
  font-size: 40px;
  font-weight: 500;
  line-height: 44px;
}

.email-order-invoice-widget .preview .container .store-details {
  grid-gap: 15px;
  background-color: #fff;
  border-bottom: 1px solid #dee0e6;
  grid-template-columns: 95px minmax(1px, 488px);
  place-items: center;
  margin-bottom: 4px;
  padding: 20px;
  display: grid;
}

.email-order-invoice-widget .preview .container .store-details .logo {
  width: 95px;
  height: 95px;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.email-order-invoice-widget .preview .container .store-details .logo img {
  width: 95px;
  height: 95px;
  border-radius: 8px;
}

.email-order-invoice-widget .preview .container .store-details .detail {
  justify-self: flex-start;
}

.email-order-invoice-widget .preview .container .store-details .detail .name {
  color: #1c1d3e;
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
}

.email-order-invoice-widget .preview .container .store-details .detail .abn, .email-order-invoice-widget .preview .container .store-details .detail .phone {
  color: #1c1d3e;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.email-order-invoice-widget .preview .container .store-details .detail .address {
  color: #1c1d3e;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.email-order-invoice-widget .preview .container .order-invoice-info {
  background-color: #fff;
  padding: 20px 27.5px;
}

.email-order-invoice-widget .preview .container .order-invoice-info .details {
  border: 2px solid #dee0e6;
  border-radius: 12px;
  gap: 12px;
  margin-top: 4px;
  margin-bottom: 12px;
  padding: 12px 24px 16px;
  display: grid;
}

.email-order-invoice-widget .preview .container .order-invoice-info .detail {
  grid-template-columns: max-content 1fr;
  display: grid;
}

.email-order-invoice-widget .preview .container .order-invoice-info .detail:last-child {
  margin-bottom: 0;
}

.email-order-invoice-widget .preview .container .order-invoice-info .label {
  color: #1c1d3e;
  font-weight: 500;
}

.email-order-invoice-widget .preview .container .order-invoice-info .data {
  color: #1c1d3e;
  justify-items: flex-end;
}

.email-order-invoice-widget .preview .container .order-summary {
  background-color: #fff;
  padding: 0 27.5px 27px;
}

.email-order-invoice-widget .preview .container .order-summary .summary {
  border: 2px solid #dee0e6;
  border-radius: 20px;
  padding: 28px 24px 16px;
}

.email-order-invoice-widget .preview .container .order-summary .title {
  width: 100%;
  text-align: center;
  color: #1c1d3e;
  padding: 6px 0 2px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.email-order-invoice-widget .preview .container .order-summary .label-format {
  border-bottom: 1px solid #dee0e6;
  grid-template-columns: minmax(1px, 434px) 30px 71px;
  padding: 2px 0 4px;
  display: grid;
}

.email-order-invoice-widget .preview .container .order-summary .items {
  border-bottom: 1px solid #dee0e6;
  padding: 4px 0 12px;
}

.email-order-invoice-widget .preview .container .order-summary .items .format {
  min-height: 28px;
  grid-template-columns: minmax(1px, 434px) 30px 71px;
  padding: 2px 0;
  display: grid;
}

.email-order-invoice-widget .preview .container .order-summary .subtotal {
  margin-top: 2px;
}

.email-order-invoice-widget .preview .container .order-summary .goods-services-format {
  grid-template-columns: minmax(1px, 430px) 107px;
  padding: 2px 0;
  display: grid;
}

.email-order-invoice-widget .preview .container .order-summary .gst-amount {
  padding-bottom: 5px;
}

.email-order-invoice-widget .preview .container .order-summary .gst-free-items-text-disclaimer {
  border-top: 1px solid #dee0e6;
  padding: 8px 0 4px;
}

.email-order-invoice-widget .preview .container .payment-status {
  justify-content: center;
  display: flex;
}

.email-order-invoice-widget .preview .container .payment-status .label {
  max-width: 69%;
  width: 100%;
  text-align: center;
  color: #3f9c86;
  text-transform: uppercase;
  border: 1px solid #dee0e6;
  border-width: 1px 0;
  padding: 12px 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
}

.email-order-invoice-widget .preview .container .payment-status .label.color-red {
  color: #9f1239;
}

.email-order-invoice-widget .preview .container .payment-status .label.color-yellow {
  color: #b45309;
}

.email-order-invoice-widget .preview .container .order-invoice-customer-review-link-button {
  background-color: #fff;
  border: none;
  border-radius: 48px;
  justify-self: center;
  align-items: center;
  margin-top: 32px;
  padding: 16px 24px;
  display: flex;
  box-shadow: 0 2px 24px #0000001a;
}

.email-order-invoice-widget .preview .container .order-invoice-customer-review-link-button > .label {
  align-items: center;
  display: flex;
}

.email-order-invoice-widget .preview .container .order-invoice-customer-review-link-button > .label img {
  width: 44px;
  height: 44px;
}

.email-order-invoice-widget .preview .container .order-invoice-customer-review-link-button > .label span {
  color: #1c1d3e;
  padding-left: 16px;
  font-size: 18px;
  font-weight: 500;
}

.email-order-invoice-widget .preview .container .footer {
  grid-gap: 6px;
  background-color: #fff;
  margin-top: 32px;
  padding-bottom: 46px;
  display: flex;
}

.email-order-invoice-widget .preview .container .footer .label {
  margin: 6px 0 auto auto;
  line-height: 20px;
}

.email-order-invoice-widget .preview .container .footer .logo {
  width: 100px;
  height: 28px;
  margin: auto auto auto 0;
}

.email-order-invoice-widget .preview .container .hidden {
  display: none;
}

.email-order-invoice-widget .actions {
  background-color: #e0e0e0;
  align-items: center;
  margin-top: 32px;
  padding: 20px 20px 20px 36px;
  display: flex;
  position: sticky;
  bottom: 0;
}

.email-order-invoice-widget .actions .download-invoice {
  cursor: pointer;
  grid-template-columns: min-content 1fr;
  column-gap: 8px;
  text-decoration: none;
  display: grid;
}

.email-order-invoice-widget .actions .download-invoice .label {
  color: #1e40af;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.email-order-invoice-widget .actions .cancel-button {
  border: 1px solid #dee0e6;
  margin-left: auto;
  margin-right: 16px;
}

.email-order-invoice-widget .actions .send-button {
  color: #fff;
  background-color: #2563eb;
  border: 1px solid #2563eb;
}

.email-order-invoice-widget .actions .send-button.disabled {
  color: #fff !important;
  background-color: #93c5fd !important;
  border-color: #93c5fd !important;
}

@media (max-width: 767px) {
  .ReactModalPortal:has(.email-order-invoice-modal) .ReactModal__Overlay--after-open {
    background-color: #0000005e !important;
  }

  .ReactModalPortal:has(.email-order-invoice-modal) .ReactModal__Content.ReactModal__Content--after-open {
    width: unset !important;
    position: absolute !important;
  }

  .ReactModalPortal:has(.email-order-invoice-modal) .ReactModal__Content.ReactModal__Content--after-open .modal {
    max-width: unset;
    max-height: calc(100vh - 50px);
  }

  .email-order-invoice-modal .email-order-invoice-widget {
    max-height: unset;
  }

  .email-order-invoice-modal .email-order-invoice-widget .header .title {
    font-size: 20px;
    line-height: 24px;
  }

  .email-order-invoice-modal .email-order-invoice-widget .form-item label {
    font-size: 14px;
    line-height: 21px;
  }

  .email-order-invoice-modal .email-order-invoice-widget .form-item .react-tagsinput-tag {
    font-weight: 500;
    line-height: 19.5px;
  }

  .email-order-invoice-modal .email-order-invoice-widget .form-item .react-tagsinput-input {
    padding: 12px 14px;
    font-size: 13px;
    font-weight: 300;
    line-height: 19.5px;
  }

  .email-order-invoice-modal .preview {
    max-height: calc(100vh - 382px);
    margin: 0 32px 32px;
  }

  .email-order-invoice-modal .preview > .label {
    margin: 32px 0 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }

  .email-order-invoice-modal .preview .container .normal-font {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .email-order-invoice-modal .preview .container .normal-bold-font {
    font-size: 12px;
    line-height: 18px;
  }

  .email-order-invoice-modal .preview .container .email-banner {
    grid-template-columns: 28px minmax(1px, 542px);
    align-items: center;
    padding: 18px;
  }

  .email-order-invoice-modal .preview .container .email-banner img {
    width: 31px;
    height: 31px;
  }

  .email-order-invoice-modal .preview .container .email-banner .email-subject {
    margin-left: unset;
    font-size: 24px;
    font-weight: 400;
  }

  .email-order-invoice-modal .preview .container .store-details {
    grid-gap: 5px;
    margin-bottom: 2px;
    padding: 15px 5px;
  }

  .email-order-invoice-modal .preview .container .store-details .logo, .email-order-invoice-modal .preview .container .store-details .logo img {
    width: 70px;
    height: 70px;
  }

  .email-order-invoice-modal .preview .container .store-details .detail .name {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
  }

  .email-order-invoice-modal .preview .container .store-details .detail .abn, .email-order-invoice-modal .preview .container .store-details .detail .phone, .email-order-invoice-modal .preview .container .store-details .detail .address {
    font-size: 12px;
    line-height: 18px;
  }

  .email-order-invoice-modal .preview .container .order-invoice-info {
    padding: 11px 20px 8px;
  }

  .email-order-invoice-modal .preview .container .order-invoice-info .details {
    padding: 14px 18px;
  }

  .email-order-invoice-modal .preview .container .order-summary {
    padding: 8px 20px 11px;
  }

  .email-order-invoice-modal .preview .container .order-summary .title {
    font-weight: 500;
  }

  .email-order-invoice-modal .preview .footer .logo {
    width: 85px;
    height: 24px;
  }

  .email-order-invoice-modal .actions {
    margin-top: unset;
  }

  .email-order-invoice-modal .actions .download-invoice .label, .email-order-invoice-modal .actions .cancel-button .label, .email-order-invoice-modal .actions .send-button .label {
    font-size: 12px;
    line-height: 18px;
  }
}

.sms-marketing-settings-widget .display-selector {
  border-bottom: 1px solid #dee0e6;
  display: flex;
}

.sms-marketing-settings-widget .display-selector .option {
  cursor: pointer;
  letter-spacing: .005em;
  color: #4c4e68;
  margin-right: 12px;
  padding: 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.sms-marketing-settings-widget .display-selector .option.selected {
  color: #2563eb;
  border-bottom: 4px solid #2563eb;
}

.sms-marketing-recipients-widget {
  box-sizing: border-box;
  color: #1c1d3e;
  margin-top: 24px;
}

.sms-marketing-recipients-widget .no-result {
  letter-spacing: .005em;
  color: #7d7e92;
  margin-top: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.sms-marketing-recipients-widget .header {
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  font-weight: 500;
  display: flex;
}

@media (min-width: 1024px) {
  .sms-marketing-recipients-widget .header {
    flex-direction: row;
    align-items: center;
  }
}

.sms-marketing-recipients-widget .header .text-input {
  min-width: 320px;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  padding: 8px 14px;
  box-shadow: 0 1px 2px #1c1d3e0d;
}

.sms-marketing-recipients-widget .header .text-input input {
  width: 90%;
  background-image: url("search-icon-gray.72facfa7.svg");
  background-repeat: no-repeat;
  background-size: 16px;
  border: none;
  outline: none;
  padding-left: 24px;
}

.sms-marketing-recipients-widget .header .text-input input::placeholder {
  color: #c6c7d1;
}

.sms-marketing-recipients-widget .header .sms-recipient-count {
  letter-spacing: .0025em;
  margin-top: 8px;
  font-weight: 400;
  line-height: 21px;
}

@media (min-width: 1024px) {
  .sms-marketing-recipients-widget .header .sms-recipient-count {
    margin-top: 0;
  }
}

.sms-marketing-recipients-widget .sms-recipients-list .sms-recipient-list-item {
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-bottom: none;
  grid-template-columns: 1fr 1fr 48px;
  grid-auto-flow: column;
  align-items: center;
  padding: 8px 24px;
  line-height: 24px;
  display: grid;
}

@media (max-width: 767px) {
  .sms-marketing-recipients-widget .sms-recipients-list .sms-recipient-list-item {
    grid-template-areas: "name name button"
                         "number number button";
  }

  .sms-marketing-recipients-widget .sms-recipients-list .sms-recipient-list-item .name {
    grid-area: name;
  }

  .sms-marketing-recipients-widget .sms-recipients-list .sms-recipient-list-item .number {
    grid-area: number;
  }

  .sms-marketing-recipients-widget .sms-recipients-list .sms-recipient-list-item .unsubscribe-button {
    grid-area: button;
  }
}

.sms-marketing-recipients-widget .sms-recipients-list .sms-recipient-list-item:first-of-type {
  border-radius: 8px 8px 0 0;
}

.sms-marketing-recipients-widget .sms-recipients-list .sms-recipient-list-item:first-child:nth-last-child(1) {
  border-bottom: 1px solid #dee0e6;
  border-radius: 8px;
}

.sms-marketing-recipients-widget .sms-recipients-list .sms-recipient-list-item:nth-last-child(1) {
  border-bottom: 1px solid #dee0e6;
  border-radius: 0 0 8px 8px;
}

.sms-marketing-recipients-widget .sms-recipients-list .sms-recipient-list-item .unsubscribe-button {
  color: #9f1239;
  padding: 0;
}

.sms-marketing-recipients-widget .sms-recipients-list .sms-recipient-list-item .unsubscribe-button button {
  box-shadow: unset;
  border: none;
  background-color: #0000 !important;
}

.sms-marketing-recipients-widget .sms-recipients-list .sms-recipient-list-item .unsubscribe-button .overlay .options {
  min-width: 180px;
  top: -20px;
  right: -10px;
  padding: 0 !important;
}

.sms-marketing-recipients-widget .list-header {
  color: #7d7e92;
  grid-template-columns: 1fr 1fr 48px;
  margin-top: 16px;
  padding: 8px 24px;
  font-size: 12px;
  font-weight: 500;
  display: grid;
}

@media (max-width: 767px) {
  .sms-marketing-recipients-widget .list-header {
    visibility: hidden;
    margin-top: -8px;
  }
}

.sms-marketing-recipients-widget .back-to-top-button {
  background-color: #fff;
  border-radius: 30px;
  padding: 13px;
  position: fixed;
  top: 90vh;
  right: 50px;
}

@media (max-height: 844px) {
  .sms-marketing-recipients-widget .back-to-top-button {
    top: calc(100% - 150px);
  }
}

@media (min-height: 845px) {
  .sms-marketing-recipients-widget .back-to-top-button {
    top: calc(100% - 150px);
  }
}

.table-list-widget .header {
  width: 100%;
  display: flex;
}

.table-list-widget .header .actions {
  margin-left: auto;
}

.table-list-widget .header .actions .create-table-button {
  background-color: #fff;
  padding: 7px 14px;
}

.table-list-widget .header .actions .create-table-button .label {
  color: var(--color-primary);
}

.table-list-widget .body {
  margin-top: 20px;
}

.table-list-widget .body .list {
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  display: grid;
}

.table-list-widget .body .list .table {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.table-list-widget .body .list .table .info {
  grid-template-columns: 1fr min-content;
  padding: 15px 18px;
  display: grid;
}

.table-list-widget .body .list .table .info .qr-code {
  border: 1px solid var(--form-element-border);
  height: 100px;
  width: 100px;
  border-radius: 10px;
}

.table-list-widget .body .list .table .info .name {
  color: var(--fg);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.table-list-widget .body .list .table .actions {
  color: #999;
  cursor: pointer;
  background-color: #fafafb;
  border-top: 1px solid #f1f1f5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  padding: 7px 16px;
  font-size: 30px;
  display: grid;
}

.table-list-widget .body .list .table .actions img {
  width: 26px;
  margin-right: 5px;
}

.table-list-widget .body .list .table .actions .action {
  color: #999;
  padding: 0 4px;
}

.kds-devices-widget .add-kds-device-button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  background-color: #0000;
  margin-top: 24px;
}

.kds-devices-widget .kds-device-list-widget {
  grid-template-columns: repeat(auto-fill, minmax(362px, 1fr));
  gap: 20px;
  display: grid;
}

.kds-device-view-widget {
  background-color: var(--card-bg);
  color: #1c1d3e;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 4px #00000040;
}

.kds-device-view-widget .name {
  letter-spacing: .1px;
  color: var(--fg);
  border-bottom: 1px solid #dee0e6;
  grid-area: name;
  padding: 12px 18px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.kds-device-view-widget .info {
  grid-template-columns: max-content 1fr;
  grid-template-areas: "name name"
                       "id-label id"
                       "password-label password"
                       "created-time-label created-time"
                       "actions actions";
  gap: 12px;
  padding: 4px 16px;
  display: grid;
}

.kds-device-view-widget .info .id-label {
  grid-area: id-label;
}

.kds-device-view-widget .info .id {
  grid-area: id;
  justify-self: end;
}

.kds-device-view-widget .info .password-label {
  grid-area: password-label;
}

.kds-device-view-widget .info .password {
  grid-area: password;
  justify-self: end;
}

.kds-device-view-widget .info .created-time-label {
  color: #7d7e92;
  grid-area: created-time-label;
}

.kds-device-view-widget .info .created-time {
  color: #7d7e92;
  grid-area: created-time;
  justify-self: end;
}

.kds-device-view-widget .actions {
  background-color: #f6f8fb;
  border-top: 1px solid #f1f1f5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  grid-area: actions;
  gap: 4px;
  padding: 15px 18px;
  display: flex;
}

.kds-device-view-widget .actions .icon {
  color: #7d7e92;
  cursor: pointer;
  font-size: 24px;
}

.ReactModalPortal:has(.delete-kds-device-warning-alert) .ReactModal__Content.ReactModal__Content--after-open .modal > .delete-kds-device-warning-alert {
  padding: 32px 48px;
}

.kds-device-edit-widget {
  width: auto;
}

.kds-device-edit-widget .header {
  grid-template-areas: "title close-button";
  gap: 50px;
  padding: 20px;
  display: grid;
}

.kds-device-edit-widget .header .title {
  color: #171725;
  grid-area: title;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}

.kds-device-edit-widget .header .close-button {
  cursor: pointer;
  grid-area: close-button;
  padding-top: 5px;
}

.kds-device-edit-widget .form-items {
  gap: 8px;
  padding: 16px 20px;
  display: grid;
}

.kds-device-edit-widget .form-items .form-input {
  margin-top: 6px;
}

.kds-device-edit-widget .form-buttons {
  background-color: #fafafb;
  border-top: 1px solid #f1f1f5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-top: 0;
  padding: 15px 18px;
}

.kds-device-edit-widget .form-buttons button {
  grid-gap: 20px;
  box-shadow: none;
  letter-spacing: .1px;
  color: var(--fg);
  margin-right: 8px;
  padding: 9.5px 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: inline;
}

.kds-device-edit-widget .form-buttons .submit-btn {
  color: #fff;
}

.kds-device-edit-widget .form-buttons .submit-btn.disabled {
  color: #fff !important;
  background-color: #93c5fd !important;
}

.kds-device-edit-widget .form-buttons .cancel-btn {
  color: #1c1d3e;
  background-color: #dee0e6;
}

.kds-time-settings-edit-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.kds-time-settings-edit-widget .title {
  letter-spacing: .1px;
  color: var(--fg);
  border-bottom: 1px solid #dee0e6;
  padding: 14px 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.kds-time-settings-edit-widget form {
  padding: 0;
}

.kds-time-settings-edit-widget form .form-items .form-item {
  grid-template-areas: "label dropdown"
                       "help-text dropdown";
  align-items: center;
  column-gap: 24px;
  padding: 24px;
  display: grid;
}

@media (max-width: 767px) {
  .kds-time-settings-edit-widget form .form-items .form-item {
    grid-template-areas: "label"
                         "help-text"
                         "dropdown";
  }
}

.kds-time-settings-edit-widget form .form-items .form-item label {
  color: var(--fg);
  grid-area: label;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.kds-time-settings-edit-widget form .form-items .form-item label:before {
  content: "";
  width: 12px;
  height: 12px;
  background-color: #d97706;
  border-radius: 50%;
  margin-right: 8px;
  display: inline-block;
}

.kds-time-settings-edit-widget form .form-items .form-item .meta .help {
  letter-spacing: .1px;
  color: var(--fg);
  color: #9597a7;
  grid-area: help-text;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

@media (max-width: 767px) {
  .kds-time-settings-edit-widget form .form-items .form-item .meta {
    padding: 3px 0 24px;
  }
}

.kds-time-settings-edit-widget form .form-items .form-item .form-input {
  grid-area: dropdown;
}

.kds-time-settings-edit-widget form .form-items .form-item .form-input .options {
  width: 100%;
  grid-gap: 0;
  padding: 20px 0;
}

.kds-time-settings-edit-widget form .form-items .form-item .form-input .options .option {
  padding: 12px 14px;
  position: relative;
}

.kds-time-settings-edit-widget form .form-items .form-item .form-input .options .option .icon {
  display: none;
}

.kds-time-settings-edit-widget form .form-items .form-item .form-input .options .option.selected {
  background-color: #ecf9f6;
}

.kds-time-settings-edit-widget form .form-items .form-item .form-input .options .option.selected:after {
  content: "";
  width: 14px;
  height: 14px;
  background-image: url("icon-check-teal.ae8781fd.svg");
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
}

@media (min-width: 768px) {
  .kds-time-settings-edit-widget form.responsive .form-items > .form-item {
    grid-row-gap: 8px;
    grid-template-columns: 2fr 1fr;
    display: grid;
  }

  .kds-time-settings-edit-widget form.responsive .form-items > .form-item label {
    text-align: left;
    grid-area: 1 / 1 / auto / 2;
  }

  .kds-time-settings-edit-widget form.responsive .form-items > .form-item .form-input {
    grid-area: 1 / 2 / 3 / 3;
  }

  .kds-time-settings-edit-widget form.responsive .form-items > .form-item .meta {
    grid-area: 2 / 1;
  }
}

.kds-prep-time-change-confirm-widget {
  width: 357px;
  border-radius: 20px;
  padding: 32px 24px;
}

.kds-prep-time-change-confirm-widget > .title {
  color: #1c1d3e;
  text-align: center;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.kds-prep-time-change-confirm-widget > .message {
  color: #7d7e92;
  text-align: center;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.kds-prep-time-change-confirm-widget > .buttons .confirm-btn {
  color: #fff;
  width: 100%;
  background-color: #2563eb;
  border-radius: 4px;
  margin-bottom: 12px;
  padding: 14px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.kds-prep-time-change-confirm-widget > .buttons .go-back-btn {
  color: #4c4e68;
  width: 100%;
  background-color: #fff;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  margin-bottom: 12px;
  padding: 14px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.anz-worldline-integration-widget {
  padding-bottom: 40px;
}

@media (max-width: 767px) {
  .anz-worldline-integration-widget > .header {
    grid-template-columns: min-content 1fr;
    grid-template-areas: "logo title"
                         "logo status"
                         "description description";
    align-items: center;
  }
}

.anz-worldline-integration-widget > .header {
  grid-template-columns: min-content 1fr max-content;
  grid-template-areas: "logo title status"
                       "logo description description";
  gap: 8px 24px;
  margin: 24px 0;
  display: grid;
}

.anz-worldline-integration-widget > .header > .logo {
  height: 80px;
  grid-area: logo;
}

.anz-worldline-integration-widget > .header > .title {
  grid-area: title;
  font-size: 34px;
  font-weight: 600;
  line-height: 40.8px;
}

.anz-worldline-integration-widget > .header > .sub-title {
  grid-area: sub-title;
}

.anz-worldline-integration-widget > .header > .status {
  color: #4c4e68;
  grid-area: status;
}

.anz-worldline-integration-widget > .header > .status.enabled {
  color: #059669;
}

.anz-worldline-integration-widget > .moto-transactions {
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 10px;
  grid-template-areas: "title switch"
                       "description .";
  gap: 8px;
  margin-top: 32px;
  padding: 24px;
  display: grid;
}

.anz-worldline-integration-widget > .moto-transactions > .title {
  grid-area: title;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.anz-worldline-integration-widget > .moto-transactions > .description {
  color: #4c4e68;
  grid-area: description;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.anz-worldline-integration-widget > .moto-transactions > .switch {
  cursor: pointer;
  grid-area: switch;
  justify-self: self-end;
  margin: 0;
}

.anz-worldline-integration-widget > .moto-transactions > .switch i {
  width: 46px;
  height: 26px;
  vertical-align: text-bottom;
  background-color: #c6c7d1;
  border-radius: 23px;
  margin-right: 4px;
  transition: all .3s linear;
  display: inline-block;
  position: relative;
}

.anz-worldline-integration-widget > .moto-transactions > .switch i:before {
  content: "";
  width: 42px;
  height: 22px;
  background-color: #c6c7d1;
  border-radius: 11px;
  transition: all .25s linear;
  position: absolute;
  left: 0;
  transform: translate3d(2px, 2px, 0)scale3d(1, 1, 1);
}

.anz-worldline-integration-widget > .moto-transactions > .switch i:after {
  content: "";
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 11px;
  transition: all .2s ease-in-out;
  position: absolute;
  left: 0;
  transform: translate3d(2px, 2px, 0);
  box-shadow: 0 2px 2px #0000003d;
}

.anz-worldline-integration-widget > .moto-transactions > .switch:active i:after {
  width: 28px;
  transform: translate3d(2px, 2px, 0);
}

.anz-worldline-integration-widget > .moto-transactions > .switch:active input:checked + i:after {
  transform: translate3d(16px, 2px, 0);
}

.anz-worldline-integration-widget > .moto-transactions > .switch input {
  display: none;
}

.anz-worldline-integration-widget > .moto-transactions > .switch input:checked + i {
  background-color: #34c759;
}

.anz-worldline-integration-widget > .moto-transactions > .switch input:checked + i:before {
  transform: translate3d(18px, 2px, 0)scale3d(0, 0, 0);
}

.anz-worldline-integration-widget > .moto-transactions > .switch input:checked + i:after {
  transform: translate3d(22px, 2px, 0);
}

.anz-worldline-integration-widget > .delete-integration-button {
  cursor: pointer;
  color: #9f1239;
  margin-top: 42px;
  margin-left: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.anz-worldline-integration-widget .enable-moto-transactions-alert-widget {
  max-width: 573px !important;
}

.anz-worldline-integration-widget .enable-moto-transactions-alert-widget > .actions {
  flex-direction: row-reverse;
  display: flex !important;
}

.anz-worldline-integration-widget .enable-moto-transactions-alert-widget > .actions > .proceed-button {
  background-color: #2563eb !important;
}

@media (max-width: 767px) {
  .anz-worldline-integration-widget .enable-moto-transactions-alert-widget {
    width: 100%;
  }

  .anz-worldline-integration-widget .enable-moto-transactions-alert-widget > .title {
    font-size: 18px;
  }

  .anz-worldline-integration-widget .enable-moto-transactions-alert-widget > .actions {
    flex-direction: column;
    display: flex;
  }
}

.anz-worldline-integration-widget .delete-anz-worldline-integration-alert-widget {
  max-width: 573px !important;
}

.anz-worldline-integration-widget .delete-anz-worldline-integration-alert-widget > .content {
  text-align: left;
}

.anz-worldline-integration-widget .delete-anz-worldline-integration-alert-widget > .content ul {
  margin: 0;
}

.anz-worldline-integration-widget .delete-anz-worldline-integration-alert-widget > .actions {
  flex-direction: row-reverse;
  display: flex !important;
}

@media (max-width: 767px) {
  .anz-worldline-integration-widget .delete-anz-worldline-integration-alert-widget {
    width: 100%;
  }

  .anz-worldline-integration-widget .delete-anz-worldline-integration-alert-widget > .title {
    font-size: 18px;
  }

  .anz-worldline-integration-widget .delete-anz-worldline-integration-alert-widget > .actions {
    flex-direction: column;
    display: flex;
  }
}

.backoffice .online-order-history-page .header > .wrapper > .actions .download-online-orders {
  height: min-content;
  border-radius: 5px;
  padding: 10px 24px;
  font-size: 14px;
  line-height: 21px;
  text-decoration: none;
  top: 20px;
  right: 0;
  color: #fff !important;
  background-color: #2563eb !important;
}

.backoffice .online-order-history-page .body > .online-order-history-widget > .store-sales-report-link {
  color: #7d7e92;
  margin-top: 20px;
  margin-left: 5px;
}

.backoffice .online-order-history-page .body > .online-order-history-widget > .store-sales-report-link span {
  color: #1d4ed8;
  cursor: pointer;
  text-decoration: underline;
}

.backoffice .online-order-history-page .body > .online-order-history-widget .online-order-history-list-widget .no-online-orders, .backoffice .online-order-history-page .body > .online-order-history-widget .online-order-history-list-widget .no-results-found {
  color: #7d7e92;
  margin-top: 15px;
  margin-left: 5px;
}

.backoffice .online-order-history-page .body > .online-order-history-widget .back-to-top-button {
  background-color: #fff;
  border-radius: 30px;
  padding: 13px;
  position: fixed;
  top: 50rem;
  right: 50px;
}

@media (max-height: 844px) {
  .backoffice .online-order-history-page .body > .online-order-history-widget .back-to-top-button {
    top: calc(100% - 180px);
  }
}

@media (min-height: 845px) {
  .backoffice .online-order-history-page .body > .online-order-history-widget .back-to-top-button {
    top: calc(100% - 180px);
  }
}

.backoffice .online-order-history-page .body > .online-order-history-widget .end-of-list {
  text-align: center;
  color: #7d7e92;
  margin-top: 20px;
}

.backoffice .online-order-history-page .body > .online-order-history-widget .view-more-button {
  color: #fff;
  background-color: #4c4e68;
  border-radius: 5px;
  margin: 20px auto auto;
  padding: 15px 110px;
}

.store-timings-edit-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.store-timings-edit-widget form {
  margin-top: 20px;
  padding: 15px 18px;
}

.store-timings-edit-widget .dow > label {
  margin-top: 12px;
  font-weight: 500;
  align-self: flex-start !important;
}

.store-timings-edit-widget .dow > .form-input {
  background-color: var(--card-bg);
  grid-gap: 18px;
  grid-gap: 0;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 0;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.store-timings-edit-widget .dow > .form-input .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.store-timings-edit-widget .dow > .form-input .switch label {
  grid-area: a;
  align-self: center;
}

.store-timings-edit-widget .dow > .form-input .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.store-timings-edit-widget .dow > .form-input .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.store-timings-edit-widget .dow > .form-input .switch label {
  text-align: left !important;
}

.store-timings-edit-widget .hours {
  margin: 5px 0;
}

.store-timings-edit-widget .session:first-child > .form-item:first-child {
  background-color: #f9f9f9;
  border-bottom: 1px solid #f1f1f5;
  margin: 0;
  padding: 12px 18px;
  font-weight: 500;
}

.store-timings-edit-widget .session .form-item {
  padding: 10px 15px;
}

.delivery-settings-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.delivery-settings-widget form {
  margin-top: 20px;
  padding: 15px 18px;
}

.delivery-settings-widget .form-input .delivery-suburb {
  background-color: var(--card-bg);
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  margin-bottom: 20px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.delivery-settings-widget .form-input .delivery-suburb .info {
  grid-gap: 18px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 15px;
  display: grid;
  box-shadow: 0 2px #c4cbd840;
}

.delivery-settings-widget .form-input .delivery-suburb .info .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.delivery-settings-widget .form-input .delivery-suburb .info .switch label {
  grid-area: a;
  align-self: center;
}

.delivery-settings-widget .form-input .delivery-suburb .info .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.delivery-settings-widget .form-input .delivery-suburb .info .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.delivery-settings-widget .form-input .delivery-suburb .info .switch label {
  text-align: left !important;
}

@media (min-width: 1024px) {
  .delivery-settings-widget .form-input .delivery-suburb .info {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: column;
  }
}

.delivery-settings-widget .form-input .delivery-suburb .actions {
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  padding: 5px 12px;
  display: grid;
}

.delivery-settings-widget .form-input .delivery-suburb .actions .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.delivery-settings-widget .form-input .delivery-suburb .actions .action img {
  width: 26px;
  margin-right: 5px;
}

.delivery-settings-widget .form-input .delivery-suburbs button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  background-color: #0000;
}

.delivery-settings-widget .delivery-suburbs-form-item > label {
  margin-top: 12px;
  align-self: flex-start !important;
}

.backoffice-menu-page form {
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  .backoffice-menu-page form {
    max-width: 300px;
  }
}

.order-settings-page .body {
  grid-gap: 20px;
  display: grid;
}

@media (min-width: 768px) {
  .order-settings-page .body {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
}

.order-settings-page .checkout-settings, .order-settings-page .order-settings, .order-settings-page .printer-settings {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.order-settings-page .checkout-settings form, .order-settings-page .order-settings form, .order-settings-page .printer-settings form {
  padding: 20px 0;
}

.order-settings-page .checkout-settings .title, .order-settings-page .order-settings .title, .order-settings-page .printer-settings .title {
  letter-spacing: .1px;
  color: var(--fg);
  border-bottom: 1px solid #f1f1f5;
  padding: 12px 18px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.order-settings-page .checkout-settings .form-item, .order-settings-page .order-settings .form-item, .order-settings-page .printer-settings .form-item {
  padding: 0 15px;
}

.order-settings-page .checkout-settings .order-monitor .emails, .order-settings-page .order-settings .order-monitor .emails, .order-settings-page .printer-settings .order-monitor .emails {
  border: 3px solid #f9f9f9;
  border-radius: 10px;
  margin: 15px;
  padding: 10px 15px;
}

.order-settings-page .checkout-settings .order-monitor .emails .form-item, .order-settings-page .order-settings .order-monitor .emails .form-item, .order-settings-page .printer-settings .order-monitor .emails .form-item {
  padding: 8px 0;
}

.order-settings-page .checkout-settings .order-monitor .emails .checkbox, .order-settings-page .order-settings .order-monitor .emails .checkbox, .order-settings-page .printer-settings .order-monitor .emails .checkbox {
  padding: 0;
}

.order-settings-page .checkout-settings .order-monitor .emails .send-email-to, .order-settings-page .order-settings .order-monitor .emails .send-email-to, .order-settings-page .printer-settings .order-monitor .emails .send-email-to {
  padding: 10px 0 5px 4px;
  font-size: 13px;
}

.order-settings-page .checkout-settings .order-monitor .emails .send-email-to span, .order-settings-page .order-settings .order-monitor .emails .send-email-to span, .order-settings-page .printer-settings .order-monitor .emails .send-email-to span {
  color: red;
}

.order-settings-page .checkout-settings .order-monitor .emails .copy-email, .order-settings-page .order-settings .order-monitor .emails .copy-email, .order-settings-page .printer-settings .order-monitor .emails .copy-email {
  color: #6c6c6c;
  font-weight: 300;
}

.order-settings-page .checkout-settings .order-monitor .emails .copy-email .checkbox, .order-settings-page .order-settings .order-monitor .emails .copy-email .checkbox, .order-settings-page .printer-settings .order-monitor .emails .copy-email .checkbox {
  all: unset;
}

.order-settings-page .checkout-settings .order-monitor .emails .copy-email label, .order-settings-page .order-settings .order-monitor .emails .copy-email label, .order-settings-page .printer-settings .order-monitor .emails .copy-email label {
  white-space: nowrap;
}

.order-settings-page .checkout-settings .actions, .order-settings-page .order-settings .actions, .order-settings-page .printer-settings .actions {
  background-color: #fafafb;
  border-top: 1px solid #f1f1f5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  flex-direction: row;
  align-items: center;
  padding: 15px 18px;
  display: flex;
}

.order-settings-page .checkout-settings .actions button, .order-settings-page .order-settings .actions button, .order-settings-page .printer-settings .actions button {
  margin-right: 10px;
}

.order-settings-page .checkout-settings .actions a, .order-settings-page .order-settings .actions a, .order-settings-page .printer-settings .actions a {
  margin-left: 6px;
  font-family: Poppins, sans-serif;
  font-size: .75rem;
  font-weight: 500;
}

.order-settings-page .checkout-settings .actions a.cancel, .order-settings-page .order-settings .actions a.cancel, .order-settings-page .printer-settings .actions a.cancel {
  color: var(--message-error-fg);
}

.order-settings-page .checkout-settings .actions button, .order-settings-page .order-settings .actions button, .order-settings-page .printer-settings .actions button {
  margin-right: 10px;
}

.order-settings-page .checkout-settings .upsell-stack .upsell-stack-items {
  margin: 15px 0;
}

.order-settings-page .checkout-settings .upsell-stack .upsell-stack-items .upsell-item {
  grid-template-columns: 1fr min-content;
  display: grid;
}

.order-settings-page .checkout-settings .upsell-stack .upsell-stack-items .upsell-item .actions {
  background-color: #fff;
  border: none;
  align-self: flex-end;
  padding: 0 15px 0 0;
}

.order-settings-page .checkout-settings .upsell-stack .upsell-stack-items .upsell-item .actions .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.order-settings-page .checkout-settings .upsell-stack .upsell-stack-items .upsell-item .actions .action img {
  width: 26px;
  margin-right: 5px;
}

.order-settings-page .checkout-settings .upsell-stack button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  background-color: #0000;
  margin-left: 18px;
}

.printer-page .printer-edit-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.printer-page form {
  margin-top: 20px;
  padding: 15px 18px;
}

.printer-page .body {
  grid-gap: 20px;
  display: grid;
}

@media (min-width: 768px) {
  .printer-page .body {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
}

.loyalty-cards-page .loyalty-cards {
  margin-top: 20px;
}

.loyalty-cards-page .loyalty-card {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-areas: "date mobile amount"
                       "actions actions actions";
  margin: 10px 0;
  padding: 18px 0;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.loyalty-cards-page .loyalty-card .grid-text, .loyalty-cards-page .loyalty-card .modal.order-history-modal .order-history-modal .orders .loyalty-reward .view-order, .modal.order-history-modal .order-history-modal .orders .loyalty-reward .loyalty-cards-page .loyalty-card .view-order, .loyalty-cards-page .loyalty-card .modal.order-history-modal .order-history-modal .orders .loyalty-reward .amount, .modal.order-history-modal .order-history-modal .orders .loyalty-reward .loyalty-cards-page .loyalty-card .amount, .loyalty-cards-page .loyalty-card .modal.order-history-modal .order-history-modal .orders .loyalty-reward .grand-total, .modal.order-history-modal .order-history-modal .orders .loyalty-reward .loyalty-cards-page .loyalty-card .grand-total, .loyalty-cards-page .loyalty-card .modal.order-history-modal .order-history-modal .orders .loyalty-reward .date, .modal.order-history-modal .order-history-modal .orders .loyalty-reward .loyalty-cards-page .loyalty-card .date, .loyalty-cards-page .loyalty-card .amount, .loyalty-cards-page .loyalty-card .mobile, .loyalty-cards-page .loyalty-card .date {
  color: var(--message-info-text);
  align-self: center;
  margin: 0 15px;
}

.loyalty-cards-page .loyalty-card .header-text, .loyalty-cards-page .loyalty-card .modal.order-history-modal .order-history-modal .orders .loyalty-reward .view-order .header, .modal.order-history-modal .order-history-modal .orders .loyalty-reward .view-order .loyalty-cards-page .loyalty-card .header, .loyalty-cards-page .loyalty-card .modal.order-history-modal .order-history-modal .orders .loyalty-reward .amount .header, .modal.order-history-modal .order-history-modal .orders .loyalty-reward .amount .loyalty-cards-page .loyalty-card .header, .loyalty-cards-page .loyalty-card .modal.order-history-modal .order-history-modal .orders .loyalty-reward .grand-total .header, .modal.order-history-modal .order-history-modal .orders .loyalty-reward .grand-total .loyalty-cards-page .loyalty-card .header, .loyalty-cards-page .loyalty-card .modal.order-history-modal .order-history-modal .orders .loyalty-reward .date .header, .modal.order-history-modal .order-history-modal .orders .loyalty-reward .date .loyalty-cards-page .loyalty-card .header, .loyalty-cards-page .loyalty-card .amount .header, .loyalty-cards-page .loyalty-card .mobile .header, .loyalty-cards-page .loyalty-card .date .header {
  color: var(--message-info-text);
  margin: 0;
  font-size: 12px;
  font-style: italic;
  font-weight: bold;
}

.loyalty-cards-page .loyalty-card .date {
  grid-area: date;
}

.loyalty-cards-page .loyalty-card .mobile {
  grid-area: mobile;
}

.loyalty-cards-page .loyalty-card .amount {
  grid-area: amount;
}

.loyalty-cards-page .loyalty-card .actions {
  border-top: 1px dashed #e9ecf3;
  grid-area: actions;
  margin: 15px 15px 0;
  padding-top: 15px;
  display: flex;
}

.loyalty-cards-page .loyalty-card .actions button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  box-shadow: none;
  background-color: #0000;
  border-radius: 4px;
  margin-right: 10px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 400;
}

.modal.order-history-modal .order-history-modal .header {
  justify-content: space-between;
  display: flex;
}

.modal.order-history-modal .order-history-modal .header .title {
  letter-spacing: .1px;
  color: var(--fg);
  padding-left: 6px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.modal.order-history-modal .order-history-modal .header .close-button {
  cursor: pointer;
  padding: 15px;
}

.modal.order-history-modal .order-history-modal .orders {
  height: 65vh;
  overflow-y: auto;
}

.modal.order-history-modal .order-history-modal .orders .loyalty-reward {
  background-color: var(--card-bg);
  max-width: 100%;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  margin: 15px 20px;
  padding: 18px 0;
  display: flex;
  box-shadow: 0 0 3px #687a9b40;
}

.modal.order-history-modal .order-history-modal .orders .loyalty-reward .grid-text, .modal.order-history-modal .order-history-modal .orders .loyalty-reward .view-order, .modal.order-history-modal .order-history-modal .orders .loyalty-reward .amount, .modal.order-history-modal .order-history-modal .orders .loyalty-reward .grand-total, .modal.order-history-modal .order-history-modal .orders .loyalty-reward .date, .modal.order-history-modal .order-history-modal .orders .loyalty-reward .loyalty-cards-page .loyalty-card .mobile, .loyalty-cards-page .loyalty-card .modal.order-history-modal .order-history-modal .orders .loyalty-reward .mobile {
  color: var(--message-info-text);
  margin: 0 20px;
}

.modal.order-history-modal .order-history-modal .orders .loyalty-reward .header-text, .modal.order-history-modal .order-history-modal .orders .loyalty-reward .view-order .header, .modal.order-history-modal .order-history-modal .orders .loyalty-reward .amount .header, .modal.order-history-modal .order-history-modal .orders .loyalty-reward .grand-total .header, .modal.order-history-modal .order-history-modal .orders .loyalty-reward .date .header, .loyalty-cards-page .loyalty-card .date .modal.order-history-modal .order-history-modal .orders .loyalty-reward .header, .modal.order-history-modal .order-history-modal .orders .loyalty-reward .loyalty-cards-page .loyalty-card .mobile .header, .loyalty-cards-page .loyalty-card .mobile .modal.order-history-modal .order-history-modal .orders .loyalty-reward .header, .loyalty-cards-page .loyalty-card .amount .modal.order-history-modal .order-history-modal .orders .loyalty-reward .header {
  color: var(--message-info-text);
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-style: italic;
  font-weight: bold;
  line-height: 25px;
}

.modal.order-history-modal .order-history-modal .orders .loyalty-reward .view-order {
  cursor: pointer;
  color: var(--link-fg);
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  box-shadow: none;
  background-color: #0000;
  border-radius: 4px;
  margin: 15px 15px 0;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 400;
}

@media (max-width: 767px) {
  .modal.order-history-modal .order-history-modal .orders {
    margin-right: 0;
  }

  .modal.order-history-modal .order-history-modal .orders .loyalty-reward {
    flex-direction: column;
  }

  .modal.order-history-modal .order-history-modal .orders .loyalty-reward .view-order {
    margin: 10px 0 0 20px;
  }
}

.monthly-order-stats .table-header {
  display: none;
}

@media (min-width: 1024px) {
  .monthly-order-stats .table-header {
    background-color: var(--card-bg);
    background-color: #fafafb;
    border: 1px solid #e2e2ea;
    border-radius: 10px;
    grid-template-rows: none;
    grid-template-columns: repeat(7, 1fr);
    grid-auto-flow: column;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 15px;
    display: grid;
    box-shadow: 0 0 3px #687a9b40;
  }

  .monthly-order-stats .table-header p {
    font-weight: 500;
  }
}

.monthly-order-stats .stat-container {
  background-color: var(--card-bg);
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  margin-bottom: 20px;
  padding: 15px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

@media (min-width: 1024px) {
  .monthly-order-stats .stat-container {
    grid-template-rows: none;
    grid-template-columns: repeat(7, 1fr);
    grid-auto-flow: column;
  }
}

.monthly-order-stats .stat-container > p > span {
  font-weight: 500;
}

@media (min-width: 1024px) {
  .monthly-order-stats .stat-container > p > span {
    display: none;
  }
}

.domain-edit-page .domain-edit-widget, .domain-list-page .domain-edit-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.domain-edit-page .domain-edit-widget form, .domain-list-page .domain-edit-widget form {
  margin-top: 20px;
  padding: 15px 18px;
}

.domain-edit-page .domain-edit-widget form .qr-code, .domain-list-page .domain-edit-widget form .qr-code {
  width: 200px;
  height: 200px;
}

.domain-edit-page .react-autosuggest__suggestions-list, .domain-list-page .react-autosuggest__suggestions-list {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  margin: .5rem 0;
  padding: 15px 18px;
  list-style-type: none;
  box-shadow: 0 0 3px #687a9b40;
}

.domain-edit-page .react-autosuggest__suggestions-list .suggestion, .domain-list-page .react-autosuggest__suggestions-list .suggestion {
  cursor: pointer;
  padding: .5rem 0;
}

.domain-edit-page .react-autosuggest__suggestions-list .suggestion span, .domain-list-page .react-autosuggest__suggestions-list .suggestion span {
  padding: 1rem;
}

.domain-edit-page .react-autosuggest__suggestions-list .suggestion:hover, .domain-list-page .react-autosuggest__suggestions-list .suggestion:hover {
  background-color: #f4f4f4;
}

.transaction-fee-page .transaction-fee-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.transaction-fee-page .transaction-fee-widget .form-items > .form-item > label {
  margin: 0 10px 0 15px;
}

.transaction-fee-page .transaction-fee-widget form {
  margin-top: 20px;
  padding: 15px 18px;
}

.backoffice .pos-order-history-page .header > .wrapper > .actions .download-pos-orders {
  height: min-content;
  border-radius: 5px;
  padding: 10px 24px;
  font-size: 14px;
  line-height: 21px;
  text-decoration: none;
  top: 20px;
  right: 0;
  color: #fff !important;
  background-color: #2563eb !important;
}

.backoffice .pos-order-history-page .body > .pos-order-history-widget > .store-sales-report-link {
  color: #7d7e92;
  margin-top: 20px;
  margin-left: 5px;
}

.backoffice .pos-order-history-page .body > .pos-order-history-widget > .store-sales-report-link span {
  color: #1d4ed8;
  cursor: pointer;
  text-decoration: underline;
}

.backoffice .pos-order-history-page .body > .pos-order-history-widget .pos-order-history-list-widget .no-pos-orders, .backoffice .pos-order-history-page .body > .pos-order-history-widget .pos-order-history-list-widget .no-results-found {
  color: #7d7e92;
  margin-top: 15px;
  margin-left: 5px;
}

.backoffice .pos-order-history-page .body > .pos-order-history-widget .back-to-top-button {
  background-color: #fff;
  border-radius: 30px;
  padding: 13px;
  position: fixed;
  top: 50rem;
  right: 50px;
}

@media (max-height: 844px) {
  .backoffice .pos-order-history-page .body > .pos-order-history-widget .back-to-top-button {
    top: calc(100% - 180px);
  }
}

@media (min-height: 845px) {
  .backoffice .pos-order-history-page .body > .pos-order-history-widget .back-to-top-button {
    top: calc(100% - 180px);
  }
}

.backoffice .pos-order-history-page .body > .pos-order-history-widget .end-of-list {
  text-align: center;
  color: #7d7e92;
  margin-top: 20px;
}

.backoffice .pos-order-history-page .body > .pos-order-history-widget .view-more-button {
  color: #fff;
  background-color: #4c4e68;
  border-radius: 5px;
  margin: 20px auto auto;
  padding: 15px 110px;
}

.backoffice .batch-upload-jobs-page .batch-upload-jobs-list {
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  display: grid;
}

.backoffice .batch-upload-jobs-page .batch-upload-jobs-list .batch-upload-job {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-columns: max-content 1fr;
  grid-template-areas: "id-group id-group"
                       "status-label status"
                       "failed-stores-label failed-stores"
                       "created-time-label created-time"
                       "date-finished-label date-finished"
                       "actions actions";
  display: grid;
  overflow: hidden;
  box-shadow: 0 0 3px #687a9b40;
}

.backoffice .batch-upload-jobs-page .batch-upload-jobs-list .batch-upload-job .id-label, .backoffice .batch-upload-jobs-page .batch-upload-jobs-list .batch-upload-job .id, .backoffice .batch-upload-jobs-page .batch-upload-jobs-list .batch-upload-job .status-label, .backoffice .batch-upload-jobs-page .batch-upload-jobs-list .batch-upload-job .status, .backoffice .batch-upload-jobs-page .batch-upload-jobs-list .batch-upload-job .failed-stores-label, .backoffice .batch-upload-jobs-page .batch-upload-jobs-list .batch-upload-job .failed-stores, .backoffice .batch-upload-jobs-page .batch-upload-jobs-list .batch-upload-job .created-time-label, .backoffice .batch-upload-jobs-page .batch-upload-jobs-list .batch-upload-job .created-time, .backoffice .batch-upload-jobs-page .batch-upload-jobs-list .batch-upload-job .date-finished-label, .backoffice .batch-upload-jobs-page .batch-upload-jobs-list .batch-upload-job .date-finished, .backoffice .batch-upload-jobs-page .batch-upload-jobs-list .batch-upload-job .actions {
  padding: 15px;
}

.backoffice .batch-upload-jobs-page .batch-upload-jobs-list .batch-upload-job .id-group {
  color: var(--fg);
  border-bottom: 1px solid #f1f1f5;
  grid-area: id-group;
  grid-template-areas: "id-label id";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  display: grid;
}

.backoffice .batch-upload-jobs-page .batch-upload-jobs-list .batch-upload-job .id-label {
  grid-area: id-label;
}

.backoffice .batch-upload-jobs-page .batch-upload-jobs-list .batch-upload-job .status-label {
  grid-area: status-label;
}

.backoffice .batch-upload-jobs-page .batch-upload-jobs-list .batch-upload-job .created-time-label {
  color: #696974;
  grid-area: created-time-label;
}

.backoffice .batch-upload-jobs-page .batch-upload-jobs-list .batch-upload-job .date-finished-label {
  color: #696974;
  grid-area: date-finished-label;
}

.backoffice .batch-upload-jobs-page .batch-upload-jobs-list .batch-upload-job .failed-stores-label {
  grid-area: failed-stores-label;
}

.backoffice .batch-upload-jobs-page .batch-upload-jobs-list .batch-upload-job .id {
  grid-area: id;
  justify-self: end;
}

.backoffice .batch-upload-jobs-page .batch-upload-jobs-list .batch-upload-job .status {
  grid-area: status;
  justify-self: end;
}

.backoffice .batch-upload-jobs-page .batch-upload-jobs-list .batch-upload-job .failed-stores {
  grid-area: failed-stores;
  justify-self: end;
}

.backoffice .batch-upload-jobs-page .batch-upload-jobs-list .batch-upload-job .created-time {
  color: #696974;
  grid-area: created-time;
  justify-self: end;
}

.backoffice .batch-upload-jobs-page .batch-upload-jobs-list .batch-upload-job .date-finished {
  color: #696974;
  grid-area: date-finished;
  justify-self: end;
}

.backoffice .batch-upload-jobs-page .batch-upload-jobs-list .batch-upload-job .actions {
  border-top: 1px solid #f1f1f5;
  grid-area: actions;
}

.backoffice .batch-upload-jobs-page .batch-upload-jobs-list .batch-upload-job .actions button {
  cursor: pointer;
  width: 100px;
}

.online-ordering-settings-page .body {
  grid-gap: 20px;
  display: grid;
}

@media (min-width: 768px) {
  .online-ordering-settings-page .body {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
}

.online-ordering-settings-page .online-ordering-settings, .online-ordering-settings-page .table-ordering-settings {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.online-ordering-settings-page .online-ordering-settings form, .online-ordering-settings-page .table-ordering-settings form {
  padding: 20px 0;
}

.online-ordering-settings-page .online-ordering-settings .title, .online-ordering-settings-page .table-ordering-settings .title {
  letter-spacing: .1px;
  color: var(--fg);
  border-bottom: 1px solid #f1f1f5;
  padding: 12px 18px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.online-ordering-settings-page .online-ordering-settings .form-item, .online-ordering-settings-page .table-ordering-settings .form-item {
  padding: 0 15px;
}

.online-ordering-settings-page .online-ordering-settings .actions, .online-ordering-settings-page .table-ordering-settings .actions {
  background-color: #fafafb;
  border-top: 1px solid #f1f1f5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  flex-direction: row;
  align-items: center;
  padding: 15px 18px;
  display: flex;
}

.online-ordering-settings-page .online-ordering-settings .actions button, .online-ordering-settings-page .table-ordering-settings .actions button {
  margin-right: 10px;
}

.online-ordering-settings-page .online-ordering-settings .actions a, .online-ordering-settings-page .table-ordering-settings .actions a {
  margin-left: 6px;
  font-family: Poppins, sans-serif;
  font-size: .75rem;
  font-weight: 500;
}

.online-ordering-settings-page .online-ordering-settings .actions a.cancel, .online-ordering-settings-page .table-ordering-settings .actions a.cancel {
  color: var(--message-error-fg);
}

.online-ordering-settings-page .online-ordering-settings .actions button, .online-ordering-settings-page .table-ordering-settings .actions button {
  margin-right: 10px;
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

:root {
  --bg: #f4f4f4;
  --fg: #27283e;
  --color-primary: #0062ff;
  --color-secondary: #e97a6e;
  --link-fg: #1e75ff;
  --card-bg: white;
  --form-element-bg: white;
  --form-element-fg: #44444f;
  --form-element-border: #ddd;
  --form-element-error: #fc5a5a;
  --button-primary-bg: #0062ff;
  --button-primary-fg: white;
  --button-secondary-bg: white;
  --button-secondary-fg: #696974;
  --button-success-bg: #36b37e;
  --button-success-fg: white;
  --button-danger-bg: red;
  --button-danger-fg: white;
  --message-success-text: #10b981;
  --message-success-bg: #e3fcef;
  --message-success-fg: #36b37e;
  --message-error-text: #bf2600;
  --message-error-bg: #ffebe6;
  --message-error-fg: #ff5630;
  --message-info-text: #505f79;
  --message-info-bg: white;
  --message-info-fg: #2684ff;
  --message-warning-text: #ff8b00;
  --message-warning-bg: #fffae6;
  --message-warning-fg: #ffab00;
  --message-loading-text: #505f79;
  --message-loading-bg: white;
  --message-loading-fg: #ffab00;
  --message-disabled-text: #696974;
  --message-disabled-bg: #f5f5f5;
}

[data-theme="dark"] {
  --color-bg: #000;
}

.form-switch {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
}

.form-switch i {
  width: 46px;
  height: 26px;
  vertical-align: text-bottom;
  background-color: #e6e6e6;
  border-radius: 23px;
  margin-right: 4px;
  transition: all .3s linear;
  display: inline-block;
  position: relative;
}

.form-switch i:before {
  content: "";
  width: 42px;
  height: 22px;
  background-color: #fff;
  border-radius: 11px;
  transition: all .25s linear;
  position: absolute;
  left: 0;
  transform: translate3d(2px, 2px, 0)scale3d(1, 1, 1);
}

.form-switch i:after {
  content: "";
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 11px;
  transition: all .2s ease-in-out;
  position: absolute;
  left: 0;
  transform: translate3d(2px, 2px, 0);
  box-shadow: 0 2px 2px #0000003d;
}

.form-switch:active i:after {
  width: 28px;
  transform: translate3d(2px, 2px, 0);
}

.form-switch:active input:checked + i:after {
  transform: translate3d(16px, 2px, 0);
}

.form-switch input {
  display: none;
}

.form-switch input:checked + i {
  background-color: #4bd763;
}

.form-switch input:checked + i:before {
  transform: translate3d(18px, 2px, 0)scale3d(0, 0, 0);
}

.form-switch input:checked + i:after {
  transform: translate3d(22px, 2px, 0);
}

@media (max-width: 767px) {
  .form-item.switch {
    grid-template-columns: auto max-content;
    grid-template-areas: "a b"
                         "c d";
    display: grid;
  }

  .form-item.switch label {
    grid-area: a;
    align-self: center;
  }

  .form-item.switch .form-input {
    grid-area: b;
    justify-self: end;
  }

  .form-item.switch .meta {
    margin-top: -3px;
    grid-area: c !important;
  }
}

form.default .form-item.switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

form.default .form-item.switch label {
  grid-area: a;
  align-self: center;
}

form.default .form-item.switch .form-input {
  grid-area: b;
  justify-self: end;
}

form.default .form-item.switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

form label {
  margin-left: 4px;
  font-size: .8rem;
}

form input[type="text"], form input[type="number"], form input[type="password"], form textarea {
  background-color: var(--form-element-bg);
  border: 1px solid var(--form-element-border);
  box-sizing: border-box;
  color: var(--form-element-fg);
  width: 100%;
  border-radius: 10px;
  margin: 0;
  padding: 10px 14px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  transition: all .3s;
}

form input[type="file"]::-webkit-file-upload-button {
  border: 1px solid var(--form-element-border);
  background: var(--form-element-bg);
  border-radius: 5px;
}

form input[type="file"] {
  font-family: Poppins, sans-serif;
}

form .image-preview {
  margin-bottom: 15px;
}

form .image-preview img {
  max-width: 100px;
  border: 3px solid #f1f1f5;
  border-radius: 20px;
  margin: 0;
  padding: 0;
}

form .video-preview {
  align-items: center;
  display: flex;
}

form .video-preview img {
  height: 20px;
  border: none;
  margin-right: 5px;
}

form .form-input {
  position: relative;
}

form .form-input.has-icon {
  background-color: var(--form-element-bg);
  border: 1px solid var(--form-element-border);
  box-sizing: border-box;
  color: var(--form-element-fg);
  width: 100%;
  border-radius: 10px;
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  transition: all .3s;
  display: flex;
}

form .form-input.has-icon .icon {
  width: 30px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-left: 12px;
  display: flex;
}

form .form-input.has-icon img {
  width: 100%;
  align-self: center;
}

form .form-input.has-icon input {
  border: 0;
  margin: 0;
}

form .form-input .has-prepend {
  display: flex;
}

form .form-input .has-prepend .prepend-text {
  background-color: var(--form-element-bg);
  border: 1px solid var(--form-element-border);
  box-sizing: border-box;
  color: var(--form-element-fg);
  width: 100%;
  width: auto;
  border-right: none;
  border-radius: 10px 0 0 10px;
  margin: 0;
  padding: 10px 14px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  transition: all .3s;
}

form .form-input .has-prepend input {
  width: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

form .form-input .show-password {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  font-size: 12px;
  position: absolute;
  top: 12px;
  right: 15px;
}

form .form-items {
  grid-row-gap: 18px;
  display: grid;
}

form .form-buttons {
  margin-top: 20px;
}

form .form-buttons .submit-btn {
  min-width: 100px;
}

form .select {
  background-color: var(--form-element-bg);
  border: 1px solid var(--form-element-border);
  box-sizing: border-box;
  color: var(--form-element-fg);
  width: 100%;
  border-radius: 10px;
  margin: 0;
  padding: 10px 14px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  transition: all .3s;
}

form .meta {
  margin-top: 5px;
  margin-left: 5px;
}

form .error {
  color: var(--form-element-error);
  font-size: 12px;
}

form .help {
  color: #6c6c6c;
  white-space: pre-wrap;
  font-size: 12px;
}

form select {
  box-sizing: none;
  padding: none;
  width: 100%;
  background: none;
  border: none;
  margin: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
}

form .checkbox {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

form .clear-link {
  color: var(--form-element-error);
  cursor: pointer;
  font-size: 12px;
  text-decoration: underline;
}

@media (min-width: 768px) {
  form.responsive {
    grid-template-columns: 180px 4fr 1fr;
    display: grid;
  }

  form.responsive .form-items > .form-item > label {
    text-align: right;
    color: #27283e;
    align-self: center;
    margin-right: 15px;
  }

  form.responsive .form-items {
    grid-area: 1 / 1 / auto / -1;
    row-gap: 25px;
  }

  form.responsive .form-items > .form-item {
    grid-template-columns: 180px 4fr 1fr;
    display: grid;
  }

  form.responsive .form-items > .form-item label {
    grid-area: 1 / 1 / auto / 2;
  }

  form.responsive .form-items > .form-item .form-input {
    grid-area: 1 / 2 / auto / 3;
  }

  form.responsive .form-items > .form-item .meta {
    grid-area: 2 / 2 / auto / 3;
  }

  form.responsive .form-input {
    margin-top: 0;
  }

  form.responsive .form-buttons {
    grid-area: 2 / 2 / auto / 3;
    margin-top: 30px;
    padding-left: 4px;
  }

  form.horizontal .form-items {
    grid-column-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    display: grid;
  }
}

textarea.invalid, input.invalid, .input-box.invalid {
  border-color: var(--form-element-error) !important;
}

input:disabled, textarea:disabled, .form-input.disabled {
  background: #f9f9f9 !important;
}

.form-item.inline-title {
  background-color: #f9f9f9;
  font-weight: 400;
  padding: 8px 18px !important;
}

.restaurant-browse-page .ais-SearchBox .ais-SearchBox-form {
  width: -moz-fit-content;
  width: fit-content;
  display: block;
  position: relative;
}

.restaurant-browse-page .ais-SearchBox input {
  background-color: var(--form-element-bg);
  border: 1px solid var(--form-element-border);
  box-sizing: border-box;
  color: var(--form-element-fg);
  width: 100%;
  width: 350px;
  text-indent: 25px;
  background-image: url("search-icon.e822c8e9.svg");
  background-position: 12px;
  background-repeat: no-repeat;
  background-size: 17px;
  border-radius: 10px;
  margin: 0 0 25px;
  padding: 10px 14px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  transition: all .3s;
}

.restaurant-browse-page .ais-SearchBox input[type="search"]::-webkit-search-decoration, .restaurant-browse-page .ais-SearchBox input[type="search"]::-webkit-search-cancel-button, .restaurant-browse-page .ais-SearchBox input[type="search"]::-webkit-search-results-button, .restaurant-browse-page .ais-SearchBox input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.restaurant-browse-page .ais-SearchBox button {
  display: none;
}

.restaurant-browse-page .ais-Pagination-list {
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.restaurant-browse-page .ais-Pagination {
  color: #3a4570;
}

.restaurant-browse-page .ais-Pagination-link {
  color: var(--color-primary);
  text-decoration: none;
  transition: color .2s ease-out;
}

.restaurant-browse-page .ais-Pagination-list {
  justify-content: center;
}

.restaurant-browse-page .ais-Pagination-item + .ais-Pagination-item {
  margin-left: .3rem;
}

.restaurant-browse-page .ais-Pagination-link {
  border: 1px solid #c4c8d8;
  border-radius: 5px;
  padding: .3rem .6rem;
  transition: background-color .2s ease-out;
  display: block;
}

.restaurant-browse-page .ais-Pagination-item--disabled .ais-Pagination-link {
  opacity: .6;
  cursor: not-allowed;
  color: var(--message-disabled-text);
}

.restaurant-browse-page .ais-Pagination-item--disabled .ais-Pagination-link:focus, .restaurant-browse-page .ais-Pagination-item--disabled .ais-Pagination-link:hover {
  color: var(--message-disabled-text);
}

.restaurant-browse-page .ais-Pagination-item--selected .ais-Pagination-link {
  color: #fff;
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.table-list.page .body .list {
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  display: grid;
}

.table-list.page .body .list .table {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.table-list.page .body .list .table .info {
  grid-template-columns: 1fr min-content;
  padding: 15px 18px;
  display: grid;
}

.table-list.page .body .list .table .info .qr-code {
  border: 1px solid var(--form-element-border);
  height: 100px;
  width: 100px;
  border-radius: 10px;
}

.table-list.page .body .list .table .info .name {
  color: var(--fg);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.table-list.page .body .list .table .actions {
  color: #999;
  cursor: pointer;
  background-color: #fafafb;
  border-top: 1px solid #f1f1f5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  padding: 7px 16px;
  font-size: 30px;
  display: grid;
}

.table-list.page .body .list .table .actions img {
  width: 26px;
  margin-right: 5px;
}

.table-list.page .body .list .table .actions .action {
  color: #999;
  padding: 0 4px;
}

.platform-settings-page .body {
  grid-gap: 20px;
  display: grid;
}

@media (min-width: 768px) {
  .platform-settings-page .body {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
}

.store-print-settings-page .body {
  grid-gap: 20px;
  display: grid;
}

@media (min-width: 768px) {
  .store-print-settings-page .body {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }
}

.sunmi-cloud-kitchen-printers-page .table-header {
  background-color: var(--card-bg);
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: none;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-flow: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  padding: 5px 20px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.sunmi-cloud-kitchen-printers-page .table-header p {
  color: #27283e;
  font-size: 13px;
  font-weight: 500;
}

.sunmi-cloud-kitchen-printers-page .table-header .binding-status, .sunmi-cloud-kitchen-printers-page .table-header .connection-status {
  text-align: center;
}

.sunmi-cloud-kitchen-printers-page > .header .wrapper .actions button {
  border: solid 1px var(--color-primary);
}

.sunmi-cloud-kitchen-printers-page .more {
  color: #1e75ff;
  background-color: #fff;
  border: 1px solid #1e75ff;
  justify-content: center;
  margin: 16px auto 0;
  display: flex;
}

.sunmi-cloud-kitchen-printers-page .sunmi-cloud-kitchen-printer {
  background-color: var(--card-bg);
  color: #27283e;
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: none;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-flow: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  padding: 20px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.sunmi-cloud-kitchen-printers-page .sunmi-cloud-kitchen-printer .id, .sunmi-cloud-kitchen-printers-page .sunmi-cloud-kitchen-printer .msn {
  font-weight: 300;
}

.sunmi-cloud-kitchen-printers-page .sunmi-cloud-kitchen-printer .binding-status, .sunmi-cloud-kitchen-printers-page .sunmi-cloud-kitchen-printer .connection-status {
  text-align: center;
  font-weight: 500;
}

.sunmi-cloud-kitchen-printers-page .sunmi-cloud-kitchen-printer .binding-status.online, .sunmi-cloud-kitchen-printers-page .sunmi-cloud-kitchen-printer .connection-status.online {
  color: #10b981;
}

.sunmi-cloud-kitchen-printers-page .sunmi-cloud-kitchen-printer .binding-status.failure, .sunmi-cloud-kitchen-printers-page .sunmi-cloud-kitchen-printer .connection-status.failure {
  color: #fc5a5a;
}

.sunmi-cloud-kitchen-printers-page .sunmi-cloud-kitchen-printer .binding-status.unknown, .sunmi-cloud-kitchen-printers-page .sunmi-cloud-kitchen-printer .connection-status.unknown {
  color: #6c6c6c;
}

.sunmi-cloud-kitchen-printers-page .sunmi-cloud-kitchen-printer .name {
  font-weight: 500;
}

.sunmi-cloud-kitchen-printers-page .sunmi-cloud-kitchen-printer .actions {
  grid-gap: 10px;
  text-align: center;
  grid-template-rows: none;
  grid-auto-flow: column;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  display: grid;
}

.sunmi-cloud-kitchen-printers-page .sunmi-cloud-kitchen-printer .actions .dropdown button {
  box-shadow: none;
  background-color: #0000;
}

.sunmi-cloud-kitchen-printers-page .sunmi-cloud-kitchen-printer .actions .dropdown button .icon {
  font-size: 26px;
}

.sunmi-cloud-kitchen-printers-page .sunmi-cloud-kitchen-printer .actions .dropdown .options {
  padding: 16px;
  right: -20px;
}

.sunmi-cloud-kitchen-printers-page .sunmi-cloud-kitchen-printer .actions .action {
  cursor: pointer;
  border: solid 1px var(--color-primary);
  border-radius: 10px;
  padding: 10px;
}

.sunmi-cloud-kitchen-printers-page .sunmi-cloud-kitchen-printer .actions .action.bind {
  color: var(--color-primary);
}

.sunmi-cloud-kitchen-printers-page .sunmi-cloud-kitchen-printer .actions .action.disabled {
  cursor: not-allowed;
  color: #bdbdbd;
  border: 1px solid #bdbdbd;
}

.sunmi-cloud-kitchen-printers-page .sunmi-cloud-kitchen-printer .actions .action.refresh {
  color: #fff;
  background-color: var(--color-primary);
}

.reports-page > .header .title {
  padding: 0;
}

.swal2-container.delete-category .swal2-popup {
  border-radius: 20px;
  padding: 32px 24px;
}

.swal2-container.delete-category .swal2-actions {
  flex-direction: column;
}

.swal2-container.delete-category .swal2-actions button {
  width: 70%;
  border-radius: 4px;
}

.swal2-container.delete-category .swal2-actions .swal2-confirm {
  background-color: #9f1239;
}

.swal2-container.delete-category .swal2-actions .swal2-cancel {
  color: #4c4e68;
  border: 1px solid #7d7e92;
  background-color: #0000 !important;
}

.dine-in-page main {
  overflow: auto;
}

.dine-in-page .display-selector {
  height: 100%;
  border-bottom: 1px solid #dee0e6;
  margin-bottom: 4px;
  display: flex;
  overflow: hidden;
}

@media (max-width: 767px) {
  .dine-in-page .display-selector {
    font-size: 14px;
    overflow-x: scroll;
  }
}

.dine-in-page .display-selector .option {
  color: var(--fg);
  color: #4c4e68;
  text-wrap: nowrap;
  cursor: pointer;
  margin-right: 12px;
  padding: 12px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

@media (max-width: 767px) {
  .dine-in-page .display-selector .option {
    padding: 8px;
    font-size: 12px;
  }
}

.dine-in-page .display-selector .option.active {
  color: #2563eb;
  border-bottom: 4px solid #2563eb;
}

.dine-in-page .table-booking-settings-edit-widget {
  margin-top: 20px;
}

.dine-in-page .dine-in-settings-edit-widget {
  background-color: #fff;
  border-radius: 10px;
  gap: 24px;
  margin-top: 20px;
  padding: 24px 0;
}

@media (max-width: 767px) {
  .dine-in-page .dine-in-settings-edit-widget {
    margin-top: 24px;
    padding: 24px 20px;
  }

  .dine-in-page .dine-in-settings-edit-widget form .label {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }

  .dine-in-page .dine-in-settings-edit-widget form .meta {
    margin-top: 8px;
  }

  .dine-in-page .dine-in-settings-edit-widget form .help {
    color: #7d7e92;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
  }

  .dine-in-page .dine-in-settings-edit-widget form .form-buttons {
    margin-top: 24px;
  }
}

.swal2-container.delete-category .swal2-popup {
  border-radius: 20px;
  padding: 32px 24px;
}

.swal2-container.delete-category .swal2-actions {
  flex-direction: column;
}

.swal2-container.delete-category .swal2-actions button {
  width: 70%;
  border-radius: 4px;
}

.swal2-container.delete-category .swal2-actions .swal2-confirm {
  background-color: #9f1239;
}

.swal2-container.delete-category .swal2-actions .swal2-cancel {
  color: #4c4e68;
  border: 1px solid #7d7e92;
  background-color: #0000 !important;
}

.manage-store-integrations-page .body > .header {
  margin: 20px 0;
  font-size: 12px;
  font-weight: 500;
}

@media (min-width: 768px) {
  .manage-store-integrations-page .body > .header {
    font-size: 24px;
    font-weight: 600;
  }
}

.uber-eats-menu-page {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.uber-eats-menu-page > .body {
  height: 100%;
}

.uber-eats-edit-menu-price-page {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.uber-eats-edit-menu-price-page > .body {
  height: 100%;
}

.website-order-view-page .email-invoice-button {
  margin-left: auto;
  background-color: #0062ff !important;
  padding: 9.5px 32px !important;
}

.website-order-view-page .email-invoice-button .label {
  letter-spacing: .25px;
  font-size: 14px;
  line-height: 21px;
  color: #fff !important;
}

.google-order-view-page .email-invoice-button {
  margin-left: auto;
  background-color: #0062ff !important;
  padding: 9.5px 32px !important;
}

.google-order-view-page .email-invoice-button .label {
  letter-spacing: .25px;
  font-size: 14px;
  line-height: 21px;
  color: #fff !important;
}

.uber-eats-order-view-page .email-invoice-button {
  margin-left: auto;
  background-color: #0062ff !important;
  padding: 9.5px 32px !important;
}

.uber-eats-order-view-page .email-invoice-button .label {
  letter-spacing: .25px;
  font-size: 14px;
  line-height: 21px;
  color: #fff !important;
}

.add-ons-page > .header .actions .manage-add-ons-button {
  background-color: #2563eb;
}

.add-ons-page > .header .actions .manage-add-ons-button .label {
  letter-spacing: .1px;
  color: var(--fg);
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.add-ons-page .no-add-ons-info {
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin-top: 25vh;
  display: flex;
}

.add-ons-page .no-add-ons-info > .icon {
  height: 64px;
  grid-area: icon;
}

.add-ons-page .no-add-ons-info .title {
  color: #4c4e68;
  grid-area: title;
  margin-top: 24px;
  font-size: 24px;
  font-weight: 600;
}

.add-ons-page .no-add-ons-info .sub-title {
  color: #7d7e92;
  margin-top: 24px;
  font-size: 16px;
}

.add-ons-page .no-add-ons-info .sub-title .link {
  color: #2563eb;
  cursor: pointer;
  font-weight: 500;
  display: inline-block;
}

.add-ons-page .no-add-ons-info .sub-title .icon {
  display: none;
}

.add-ons-page .add-ons {
  grid-template-columns: repeat(auto-fill, 552px);
  gap: 20px;
  display: grid;
}

.manage-add-ons-page .body .manage-add-ons-widget > .header {
  margin: 20px 0;
  font-size: 12px;
  font-weight: 500;
}

@media (min-width: 768px) {
  .manage-add-ons-page .body .manage-add-ons-widget > .header {
    font-size: 24px;
    font-weight: 600;
  }
}

.sms-marketing-new-campaign-page {
  color: #1c1d3e;
}

.sms-marketing-new-campaign-page .header {
  margin: 20px 0 32px;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
}

.sms-marketing-new-campaign-page .sms-marketing-edit-campaign-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 24px;
  box-shadow: 0 0 3px #687a9b40;
}

@media (min-width: 1024px) {
  .sms-marketing-new-campaign-page .sms-marketing-edit-campaign-widget .sms-form .form-items {
    grid-gap: 24px;
    grid-template-columns: 1fr 1fr;
    display: grid;
  }
}

.sms-marketing-new-campaign-page .sms-marketing-edit-campaign-widget .sms-form .form-input {
  margin-top: 6px;
}

.sms-marketing-new-campaign-page .sms-marketing-edit-campaign-widget .sms-form .form-input input:disabled {
  color: #7d7e92;
}

.sms-marketing-new-campaign-page .sms-marketing-edit-campaign-widget .sms-form .form-buttons {
  justify-content: end;
  display: flex;
}

.sms-marketing-new-campaign-page .sms-marketing-edit-campaign-widget .sms-form .campaign-name {
  margin-bottom: 24px;
}

.sms-marketing-new-campaign-page .sms-marketing-edit-campaign-widget .sms-form .message-input {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
}

.sms-marketing-new-campaign-page .sms-marketing-edit-campaign-widget .sms-form .message-input::-webkit-scrollbar {
  width: 1px;
}

.sms-marketing-new-campaign-page .sms-marketing-edit-campaign-widget .sms-form .message-input::-webkit-scrollbar-track {
  background: none;
}

.sms-marketing-new-campaign-page .sms-marketing-edit-campaign-widget .sms-form .message-input::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.sms-marketing-new-campaign-page .sms-marketing-edit-campaign-widget .sms-form .message-input .form-input.disabled {
  background-color: #0000 !important;
}

.sms-marketing-new-campaign-page .sms-marketing-edit-campaign-widget .sms-form .message-input .error {
  display: none;
}

.sms-marketing-new-campaign-page .sms-marketing-edit-campaign-widget .sms-form .message-input textarea:disabled {
  color: #7d7e92;
  background-color: #f6f8fb;
}

.sms-marketing-new-campaign-page .sms-marketing-edit-campaign-widget .sms-form .message-length {
  text-align: end;
  letter-spacing: .004em;
  margin-bottom: 24px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.sms-marketing-new-campaign-page .sms-marketing-edit-campaign-widget .sms-form .recipient-count {
  margin: 0 0 24px 4px;
}

.sms-marketing-new-campaign-page .sms-marketing-edit-campaign-widget .sms-form .recipient-count .label {
  margin-bottom: 6px;
}

.sms-marketing-new-campaign-page .sms-marketing-edit-campaign-widget .sms-form .recipient-count span {
  color: #1e40af;
  font-weight: 500;
}

.sms-marketing-new-campaign-page .sms-marketing-edit-campaign-widget .sms-form .recipient-count .no-recipient {
  letter-spacing: .004em;
  color: #9f1239;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.sms-marketing-new-campaign-page .sms-marketing-edit-campaign-widget .sms-form .delivery-schedule .delivery-schedule-option {
  border: 1px solid #dee0e6;
  border-radius: 10px;
  margin-top: 12px;
  padding: 14px 16px;
}

.sms-marketing-new-campaign-page .sms-marketing-edit-campaign-widget .sms-form .delivery-schedule .delivery-schedule-option.invalid {
  border-color: var(--form-element-error);
}

.sms-marketing-new-campaign-page .sms-marketing-edit-campaign-widget .sms-form .delivery-schedule .delivery-schedule-option .radio {
  align-items: center;
  display: flex;
}

.sms-marketing-new-campaign-page .sms-marketing-edit-campaign-widget .sms-form .delivery-schedule .delivery-schedule-option .radio label {
  font-weight: 400;
}

.sms-marketing-new-campaign-page .sms-marketing-edit-campaign-widget .sms-form .delivery-schedule .delivery-schedule-option p {
  color: #7d7e92;
  margin: unset;
  margin-top: 4px;
  padding-left: 36px;
  font-size: 12px;
}

.sms-marketing-new-campaign-page .sms-marketing-edit-campaign-widget .sms-form .delivery-schedule .date-time-picker {
  flex-direction: column;
  margin-top: 6px;
  padding-left: 28px;
  display: flex;
}

@media (min-width: 1024px) {
  .sms-marketing-new-campaign-page .sms-marketing-edit-campaign-widget .sms-form .delivery-schedule .date-time-picker {
    flex-direction: row;
  }

  .sms-marketing-new-campaign-page .sms-marketing-edit-campaign-widget .sms-form .delivery-schedule .date-time-picker > div {
    flex: 1;
    margin-left: 8px;
  }
}

.sms-marketing-new-campaign-page .sms-marketing-edit-campaign-widget .sms-form .delivery-schedule .date-time-picker > div {
  margin-bottom: 6px;
}

.sms-marketing-new-campaign-page .sms-marketing-edit-campaign-widget .sms-form .delivery-schedule .date-time-picker .time-input .input-box {
  border-radius: 8px;
}

.sms-marketing-new-campaign-page .sms-marketing-edit-campaign-widget .sms-form .delivery-schedule .date-time-picker .time-input .options-wrapper .options {
  top: unset;
  bottom: 48px;
}

.sms-marketing-new-campaign-page .sms-marketing-edit-campaign-widget .sms-form .delivery-schedule .date-time-picker .DayPicker {
  bottom: 45px;
  left: 0;
}

.sms-marketing-new-campaign-page .sms-marketing-edit-campaign-widget .sms-form .delivery-schedule .error-message {
  letter-spacing: .004em;
  color: var(--form-element-error);
  padding-top: 6px;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
}

.item-summary-view-widget {
  color: #7d7e92;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: "quantity name price"
                       ". discount discount"
                       ". extra-charge extra-charge"
                       ". description ."
                       ". comments comments";
  column-gap: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: grid;
}

.item-summary-view-widget > .quantity {
  color: #1c1d3e;
  grid-area: quantity;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.item-summary-view-widget > .name {
  color: #1c1d3e;
  grid-area: name;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.item-summary-view-widget > .price {
  color: #1c1d3e;
  grid-area: price;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.item-summary-view-widget > .discount {
  grid-area: discount;
  justify-content: space-between;
  margin-top: 8px;
  display: flex;
}

.item-summary-view-widget > .extra-charge {
  grid-area: extra-charge;
  justify-content: space-between;
  margin-top: 8px;
  display: flex;
}

.item-summary-view-widget > .comments {
  grid-area: comments;
  justify-content: space-between;
  margin-top: 8px;
  display: flex;
}

.item-summary-view-widget > .item-description-view-widget {
  grid-area: description;
  margin-top: 8px;
}

.split-bill-item-summary-view-widget {
  color: #7d7e92;
  grid-template-columns: 1fr auto;
  grid-template-areas: "name price"
                       "description ."
                       "comments comments";
  column-gap: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: grid;
}

.split-bill-item-summary-view-widget > .name {
  color: #1c1d3e;
  grid-area: name;
  font-weight: 500;
}

.split-bill-item-summary-view-widget > .price {
  grid-area: price;
  font-weight: 500;
}

.split-bill-item-summary-view-widget > .comments {
  grid-area: comments;
  justify-content: space-between;
  margin-top: 8px;
  display: flex;
}

.split-bill-item-summary-view-widget > .item-description-view-widget {
  grid-area: description;
  margin-top: 8px;
}

.item-description-view-widget {
  white-space: pre-wrap;
  flex-direction: column;
  display: flex;
}

.item-description-view-widget > .title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.item-description-view-widget > .title + * {
  margin-top: 8px;
}

.item-description-view-widget > .modifier, .item-description-view-widget > .simple {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.item-description-view-widget > .simple:first-child + .title {
  margin-top: 8px;
}

.item-description-view-widget > .break {
  height: 8px;
}

.item-description-view-widget > .break + * {
  margin-top: 0;
}

.online-ordering {
  padding-top: 53px;
}

.online-ordering header {
  width: 100%;
  position: fixed;
  top: 0;
}

.online-ordering main {
  margin-top: var(--safe-area-inset-top);
  padding: 20px;
}

.online-ordering footer {
  display: none;
}

@media (max-width: 767px) {
  .online-ordering main {
    padding-bottom: 100px;
  }

  .online-ordering footer {
    width: 100%;
    display: grid;
    position: fixed;
    bottom: 0;
  }
}

@media (min-width: 1024px) {
  .online-ordering main {
    max-width: 900px;
    margin: 0 auto;
  }
}

.online-ordering .menu-page .categories {
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  display: grid;
}

.online-ordering .menu-page .category {
  background-color: var(--card-bg);
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: min-content min-content;
  padding: 10px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.online-ordering .menu-page .category .image {
  margin-bottom: 15px;
  overflow: hidden;
}

.online-ordering .menu-page .category .image img {
  border-radius: 10px;
}

.online-ordering .menu-page .category .name {
  color: var(--fg);
  padding-left: 6px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.online-ordering .menu-page .category .description {
  letter-spacing: .1px;
  color: #8d92a3;
  padding-left: 6px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.online-ordering .menu-page .item {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 3px #687a9b40;
}

@media (max-width: 767px) {
  .online-ordering .menu-page .item .body {
    grid-template: "a a" min-content
                   "b c" 1fr
                   "d d" min-content
                   / 1fr min-content;
    display: grid;
  }

  .online-ordering .menu-page .item .body .image {
    grid-area: a;
    overflow: hidden;
  }

  .online-ordering .menu-page .item .body .image img {
    border-radius: 10px;
  }

  .online-ordering .menu-page .item .body .name {
    color: var(--fg);
    grid-area: b;
    align-self: flex-start;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }

  .online-ordering .menu-page .item .body .price {
    color: var(--fg);
    color: var(--color-secondary);
    grid-area: c;
    align-self: flex-end;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }

  .online-ordering .menu-page .item .body .description {
    letter-spacing: .1px;
    color: #8d92a3;
    grid-area: d;
    align-self: flex-start;
    padding-bottom: 10px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }

  .online-ordering .menu-page .item .body .name, .online-ordering .menu-page .item .body .price, .online-ordering .menu-page .item .body .description, .online-ordering .menu-page .item .body .image {
    padding-left: 12px;
    padding-right: 12px;
  }

  .online-ordering .menu-page .item .body .image, .online-ordering .menu-page .item .body .name, .online-ordering .menu-page .item .body .price {
    padding-top: 12px;
  }

  .online-ordering .menu-page .item .body .description {
    padding-bottom: 15px;
  }
}

@media (min-width: 768px) {
  .online-ordering .menu-page .item .body {
    grid-template: "a d" min-content
                   "b d" 1fr
                   "c d" min-content
                   / 1fr min-content;
    padding: 15px 18px;
    display: grid;
  }

  .online-ordering .menu-page .item .body .image {
    width: 100px;
    grid-area: d;
    margin-left: 10px;
    overflow: hidden;
  }

  .online-ordering .menu-page .item .body .image img {
    border-radius: 10px;
  }

  .online-ordering .menu-page .item .body .name {
    color: var(--fg);
    grid-area: a;
    align-self: flex-start;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }

  .online-ordering .menu-page .item .body .description {
    letter-spacing: .1px;
    color: #8d92a3;
    grid-area: b;
    align-self: flex-start;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }

  .online-ordering .menu-page .item .body .price {
    color: var(--fg);
    color: var(--color-secondary);
    grid-area: c;
    align-self: flex-end;
    margin-top: 4px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
}

.online-ordering .menu-page .item .action {
  background-color: #fafafb;
  border-top: 1px solid #f1f1f5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  flex-direction: row;
  grid-area: e;
  align-items: center;
  padding: 12px 18px;
  display: flex;
}

.online-ordering .menu-page .item .action img {
  margin-right: 10px;
}

.online-ordering .menu-page .item .action span {
  color: #426ed9;
  font-family: Fira Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}

.online-ordering header .wrapper {
  width: 100vw;
  height: 53px;
  justify-content: space-between;
  align-items: center;
  display: grid;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .online-ordering header .wrapper {
    max-width: 940px;
    margin: 0 auto;
  }
}

@media (max-width: 767px) {
  .online-ordering header .wrapper {
    grid-template-columns: 50px 1fr 50px;
    grid-template-areas: "back-button store-name actions";
  }

  .online-ordering header .wrapper .cart, .online-ordering header .wrapper .menu {
    display: none !important;
  }

  .online-ordering header .wrapper .userinfo .full {
    display: none;
  }

  .online-ordering header .wrapper .back-button {
    color: #999;
    background-color: #fff;
    border: 3px solid #f1f1f5;
    border-radius: 10px;
    flex-direction: row;
    grid-area: backbutton;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    padding: 3px 0;
    font-size: 18px;
    font-weight: 500;
    display: flex;
  }

  .online-ordering header .wrapper .login-icon {
    cursor: pointer;
  }

  .online-ordering header .wrapper .login-icon .icon {
    color: var(--fg);
    margin-top: 4px;
    font-size: 30px;
  }
}

@media (min-width: 768px) {
  .online-ordering header .wrapper {
    grid-template-columns: min-content 1fr min-content;
    grid-template-areas: "store-name menu actions";
  }

  .online-ordering header .wrapper .back-button {
    display: none;
  }

  .online-ordering header .wrapper .store-header-widget {
    white-space: nowrap;
    justify-self: start;
    margin-left: 20px;
  }

  .online-ordering header .wrapper .userinfo .full {
    display: none;
  }

  .online-ordering header .wrapper .menu {
    background-color: #fff;
    flex-direction: row;
    justify-self: center;
    display: flex;
  }

  .online-ordering header .wrapper .menu .item, .online-ordering header .wrapper .menu .item-with-counter {
    cursor: pointer;
    height: 53px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 16px;
    display: flex;
    position: relative;
  }

  .online-ordering header .wrapper .menu .item .label, .online-ordering header .wrapper .menu .item-with-counter .label {
    letter-spacing: .1px;
    color: #171725;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
  }

  .online-ordering header .wrapper .menu .item.selected .label, .online-ordering header .wrapper .menu .item-with-counter.selected .label {
    color: var(--color-primary);
  }

  .online-ordering header .wrapper .menu .item.selected .bleed, .online-ordering header .wrapper .menu .item-with-counter.selected .bleed {
    height: 4px;
    width: 120%;
    background-color: var(--color-primary);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    position: absolute;
    bottom: 0;
  }

  .online-ordering header .wrapper .menu .item .count, .online-ordering header .wrapper .menu .item-with-counter .count {
    background: var(--button-success-bg);
    color: #fff;
    border-radius: 5px;
    padding: 1px 5px 0 6px;
    font-size: 10px;
    font-weight: 500;
    position: absolute;
    top: 9px;
    right: -13px;
  }

  .online-ordering header .wrapper .actions {
    flex-direction: row;
    place-self: center flex-end;
    align-items: center;
    margin-right: 20px;
    display: flex;
  }

  .online-ordering header .wrapper .login-icon {
    cursor: pointer;
  }

  .online-ordering header .wrapper .login-icon .icon {
    color: var(--fg);
    margin-top: 5px;
    font-size: 30px;
  }
}

.online-ordering header .wrapper .back-button {
  grid-area: back-button;
}

.online-ordering header .wrapper .actions {
  grid-area: actions;
}

.online-ordering header .wrapper .store-header-widget {
  cursor: pointer;
  grid-area: store-name;
}

.online-ordering header .wrapper .menu {
  grid-area: menu;
}

.online-ordering header .wrapper .logo {
  margin-left: 20px;
}

.online-ordering header .wrapper .logo img {
  width: 100%;
  display: block;
}

@media (max-width: 767px) {
  .online-ordering header .wrapper .logo.full {
    display: none;
  }
}

.online-ordering header .wrapper .logo.icon {
  color: #92929d;
  cursor: pointer;
  place-self: center start;
  font-size: 20px;
}

@media (min-width: 768px) {
  .online-ordering header .wrapper .logo.icon {
    display: none;
  }
}

.online-ordering header .wrapper .userinfo {
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  display: flex;
}

.online-ordering header .wrapper .avatar {
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-right: 2px;
}

.online-ordering header .wrapper .avatar img {
  width: 26px;
  border: 3px solid #f1f1f5;
  border-radius: 10px;
}

.online-ordering header .wrapper .initials {
  color: #fff;
  background-color: #ffc542;
  border: 3px solid #f1f1f5;
  border-radius: 10px;
  place-self: center end;
  padding: 3px 4px;
  font-weight: 500;
}

.online-ordering header .wrapper .cart {
  cursor: pointer;
  margin-right: 20px;
  position: relative;
}

.online-ordering header .wrapper .cart .icon {
  margin-top: 5px;
  font-size: 30px;
}

.online-ordering header .wrapper .cart .count {
  background: var(--button-success-bg);
  color: #fff;
  border-radius: 5px;
  grid-area: count;
  padding: 1px 5px 0 6px;
  font-size: 10px;
  font-weight: 500;
  position: absolute;
  top: 0;
  right: -8px;
}

.online-ordering .banner {
  padding: 20px 20px 0;
}

@media (min-width: 1024px) {
  .online-ordering .banner {
    max-width: 900px;
    margin: 0 auto;
  }
}

.online-ordering-bottom-nav-bar {
  z-index: 1;
  background-color: #fff;
  grid-template-columns: repeat(4, 1fr);
  display: grid;
  box-shadow: 0 -1px 3px #687a9b40;
}

@supports (padding: max(0px)) {
  .online-ordering-bottom-nav-bar {
    padding-bottom: max(10px, var(--safe-area-inset-bottom));
  }
}

.online-ordering-bottom-nav-bar .item {
  text-align: center;
  grid-template-rows: min-content min-content;
  display: grid;
  position: relative;
}

.online-ordering-bottom-nav-bar .item .icon {
  font-size: 20px;
}

.online-ordering-bottom-nav-bar .item .label {
  font-size: 8px;
}

.online-ordering-bottom-nav-bar .item, .online-ordering-bottom-nav-bar .item-with-counter {
  padding-top: 10px;
}

.online-ordering-bottom-nav-bar .item.selected .icon, .online-ordering-bottom-nav-bar .item.selected .label, .online-ordering-bottom-nav-bar .item-with-counter.selected .icon, .online-ordering-bottom-nav-bar .item-with-counter.selected .label {
  color: var(--color-primary);
  font-weight: 500;
}

.online-ordering-bottom-nav-bar .item-with-counter {
  text-align: center;
  grid-template-rows: min-content;
  grid-template-columns: 1fr min-content 1fr;
  display: grid;
}

.online-ordering-bottom-nav-bar .item-with-counter .content {
  text-align: center;
  grid-template-rows: min-content min-content;
  position: relative;
}

.online-ordering-bottom-nav-bar .item-with-counter .icon {
  font-size: 20px;
}

.online-ordering-bottom-nav-bar .item-with-counter .label {
  font-size: 8px;
}

.online-ordering-bottom-nav-bar .item-with-counter .count {
  background: var(--button-success-bg);
  color: #fff;
  border-radius: 5px;
  padding: 1px 5px 0 6px;
  font-size: 10px;
  font-weight: 500;
  position: absolute;
  top: 0;
  right: -8px;
}

.online-ordering .cart .alert {
  box-shadow: none;
  margin: 10px 0;
}

.online-ordering .cart form {
  padding-top: 0 !important;
}

.online-ordering .cart form .form-items {
  grid-gap: 10px;
}

.online-ordering .cart .sections {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  margin-bottom: 30px;
  box-shadow: 0 0 3px #687a9b40;
}

.online-ordering .cart .section {
  border-bottom: 1px solid #f1f1f5;
}

.online-ordering .cart .section > .header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  display: flex;
}

.online-ordering .cart .section > .header .title {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.online-ordering .cart .section > .header .title i {
  margin-right: 12px;
  font-size: 24px;
}

.online-ordering .cart .section > .header .title span {
  letter-spacing: .7px;
  text-transform: uppercase;
  color: #293340;
  font-family: Fira Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
}

.online-ordering .cart .section > .header .title-add-action {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.online-ordering .cart .section > .header .title-add-action img {
  margin-right: 8px;
}

.online-ordering .cart .section > .header .title-add-action span {
  text-align: right;
  color: #426ed9;
  font-family: Fira Sans;
  font-size: 12.5px;
  font-style: normal;
  font-weight: 500;
}

.online-ordering .cart .section > .header .title-value {
  text-align: right;
  color: #426ed9;
  font-family: Fira Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
}

.online-ordering .cart .section > .header .title-value.close {
  color: var(--color-secondary);
}

.online-ordering .cart .items .cart-item {
  border-bottom: 1px dashed #e9ecf3;
  margin-bottom: 20px;
  padding: 0 20px 12px;
}

.online-ordering .cart .items .cart-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.online-ordering .cart .items .cart-item > .header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.online-ordering .cart .items .cart-item > .header .name {
  color: #000521;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.online-ordering .cart .items .cart-item > .header .price {
  color: #fa7268;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.online-ordering .cart .items .cart-item > .footer {
  grid-template-columns: 1fr min-content;
  justify-content: space-between;
  align-items: flex-end;
  margin: 8px 0;
  display: grid;
}

.online-ordering .cart .items .cart-item .summary {
  align-self: start;
  margin-right: 30px;
}

.online-ordering .cart .items .cart-item .summary pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  color: #8d92a3;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
  overflow-x: auto;
  margin: 0 !important;
}

.online-ordering .cart .items .cart-item .quantity {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.online-ordering .cart .items .cart-item .quantity .number {
  color: #000521;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
}

.online-ordering .cart .items .cart-item .quantity .icon {
  cursor: pointer;
}

.online-ordering .cart .items .cart-item .quantity .dec {
  padding-right: 10px;
}

.online-ordering .cart .items .cart-item .quantity .inc {
  padding-left: 10px;
}

.online-ordering .cart .items .cart-item .quantity .icon {
  color: #8d92a3;
  font-size: 24px;
}

.online-ordering .cart .amounts {
  padding: 10px 20px;
}

.online-ordering .cart .amounts .amount {
  color: #565c65;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  font-family: Fira Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  display: flex;
}

.online-ordering .cart .amounts .grand-total {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
}

.online-ordering .cart button.place-order {
  width: 100%;
  background-color: var(--button-success-bg);
  color: var(--button-success-fg);
  white-space: nowrap;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 20px;
  font-family: Fira Sans;
  font-size: 14px;
}

.online-ordering .cart .collection-times-wrapper {
  grid-template-columns: 1fr;
  margin-bottom: 15px;
  padding: 10px 20px;
  display: grid;
  overflow: hidden;
}

.online-ordering .cart .collection-times {
  grid-gap: 10px;
  grid-template-columns: min-content;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  display: grid;
  overflow-x: scroll;
}

.online-ordering .cart .collection-times .collection-time {
  white-space: nowrap;
  box-sizing: border-box;
  border: 1px solid #426ed9;
  border-radius: 8px;
  padding: 12px;
  font-family: Fira Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 14px;
}

.online-ordering .cart .add-delivery-address {
  padding: 5px 20px 10px;
}

.online-ordering .cart .add-delivery-address .delivery-suburbs {
  margin-top: 10px;
  margin-bottom: 5px;
  display: grid;
}

.online-ordering .cart .add-delivery-address .delivery-suburbs .delivery-suburb {
  grid-gap: 5px;
  border-bottom: 1px dashed #e9ecf3;
  grid-template-columns: min-content 1fr min-content;
  padding: 10px 0;
  display: grid;
}

.online-ordering .cart .add-delivery-address .delivery-suburbs .delivery-suburb.selected {
  color: var(--fg);
  font-weight: 500;
}

.online-ordering .cart .add-delivery-address .delivery-suburbs .delivery-suburb:last-child {
  border-bottom: 0;
}

.online-ordering .cart .add-delivery-address .delivery-suburbs .delivery-suburb .charge {
  white-space: nowrap;
}

.online-ordering .cart .saved-addresses {
  padding: 5px 20px 10px;
}

.online-ordering .cart .saved-addresses .saved-address {
  grid-gap: 5px;
  border-bottom: 1px dashed #e9ecf3;
  grid-template-columns: min-content 1fr;
  padding: 10px 0;
  display: grid;
  grid-template-columns: min-content 1fr min-content min-content !important;
}

.online-ordering .cart .saved-addresses .saved-address.selected {
  color: var(--fg);
  font-weight: 500;
}

.online-ordering .cart .saved-addresses .saved-address:last-child {
  border-bottom: 0;
}

.online-ordering .cart .saved-addresses .radio-button {
  position: relative;
  top: 10px;
}

.online-ordering .cart .saved-addresses .address {
  padding-top: 10px;
}

.online-ordering .cart .saved-addresses .icon {
  color: #8d92a3;
  cursor: pointer;
  font-size: 24px;
}

.online-ordering .cart .saved-comments {
  padding: 5px 20px 10px;
}

.online-ordering .cart .saved-comments .saved-comment {
  grid-gap: 5px;
  border-bottom: 1px dashed #e9ecf3;
  grid-template-columns: min-content 1fr;
  padding: 10px 0;
  display: grid;
  grid-template-columns: min-content 1fr min-content min-content !important;
}

.online-ordering .cart .saved-comments .saved-comment.selected {
  color: var(--fg);
  font-weight: 500;
}

.online-ordering .cart .saved-comments .saved-comment:last-child {
  border-bottom: 0;
}

.online-ordering .cart .saved-comments .radio-button {
  position: relative;
  top: 10px;
}

.online-ordering .cart .saved-comments .comment {
  padding-top: 10px;
}

.online-ordering .cart .saved-comments .icon {
  color: #8d92a3;
  cursor: pointer;
  font-size: 24px;
}

.online-ordering .cart .payment-types {
  padding: 5px 20px 10px;
}

.online-ordering .cart .payment-types .payment-type {
  grid-gap: 5px;
  border-bottom: 1px dashed #e9ecf3;
  grid-template-columns: min-content 1fr;
  padding: 10px 0;
  display: grid;
}

.online-ordering .cart .payment-types .payment-type.selected {
  color: var(--fg);
  font-weight: 500;
}

.online-ordering .cart .payment-types .payment-type:last-child {
  border-bottom: 0;
}

.online-ordering .cart .suggested-coupon, .online-ordering .cart .credential-already-in-use, .online-ordering .cart .mobile-update-widget, .online-ordering .cart .unlink-mobile-number, .online-ordering .cart .add-credit-card, .online-ordering .cart .add-comments, .online-ordering .cart .add-coupon, .online-ordering .cart .add-delivery-address, .online-ordering .cart .update-display-name {
  padding: 5px 20px 10px;
}

.online-ordering .cart .credential-already-in-use .message, .online-ordering .cart .mobile-update-widget .message, .online-ordering .cart .unlink-mobile-number .message, .online-ordering .cart .add-credit-card .message, .online-ordering .cart .add-comments .message, .online-ordering .cart .add-coupon .message, .online-ordering .cart .add-delivery-address .message, .online-ordering .cart .update-display-name .message {
  padding-left: 6px;
}

.online-ordering .cart .credential-already-in-use .actions, .online-ordering .cart .mobile-update-widget .actions, .online-ordering .cart .unlink-mobile-number .actions, .online-ordering .cart .add-credit-card .actions, .online-ordering .cart .add-comments .actions, .online-ordering .cart .add-coupon .actions, .online-ordering .cart .add-delivery-address .actions, .online-ordering .cart .update-display-name .actions {
  grid-gap: 10px;
  grid-template-columns: min-content;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 15px;
  display: grid;
}

.online-ordering .cart .credential-already-in-use .actions button, .online-ordering .cart .mobile-update-widget .actions button, .online-ordering .cart .unlink-mobile-number .actions button, .online-ordering .cart .add-credit-card .actions button, .online-ordering .cart .add-comments .actions button, .online-ordering .cart .add-coupon .actions button, .online-ordering .cart .add-delivery-address .actions button, .online-ordering .cart .update-display-name .actions button {
  background-color: #fff;
  padding: 7px 14px;
}

.online-ordering .cart .credential-already-in-use .actions button .icon, .online-ordering .cart .mobile-update-widget .actions button .icon, .online-ordering .cart .unlink-mobile-number .actions button .icon, .online-ordering .cart .add-credit-card .actions button .icon, .online-ordering .cart .add-comments .actions button .icon, .online-ordering .cart .add-coupon .actions button .icon, .online-ordering .cart .add-delivery-address .actions button .icon, .online-ordering .cart .update-display-name .actions button .icon {
  color: #92929d;
  font-size: 20px;
}

.online-ordering .cart .credential-already-in-use .actions button .label, .online-ordering .cart .mobile-update-widget .actions button .label, .online-ordering .cart .unlink-mobile-number .actions button .label, .online-ordering .cart .add-credit-card .actions button .label, .online-ordering .cart .add-comments .actions button .label, .online-ordering .cart .add-coupon .actions button .label, .online-ordering .cart .add-delivery-address .actions button .label, .online-ordering .cart .update-display-name .actions button .label {
  color: var(--color-primary);
}

.online-ordering .cart .credential-already-in-use .actions button img, .online-ordering .cart .mobile-update-widget .actions button img, .online-ordering .cart .unlink-mobile-number .actions button img, .online-ordering .cart .add-credit-card .actions button img, .online-ordering .cart .add-comments .actions button img, .online-ordering .cart .add-coupon .actions button img, .online-ordering .cart .add-delivery-address .actions button img, .online-ordering .cart .update-display-name .actions button img {
  margin-right: 10px;
}

.online-ordering .cart .credential-already-in-use .actions button span, .online-ordering .cart .mobile-update-widget .actions button span, .online-ordering .cart .unlink-mobile-number .actions button span, .online-ordering .cart .add-credit-card .actions button span, .online-ordering .cart .add-comments .actions button span, .online-ordering .cart .add-coupon .actions button span, .online-ordering .cart .add-delivery-address .actions button span, .online-ordering .cart .update-display-name .actions button span {
  white-space: nowrap;
  color: #426ed9;
}

.online-ordering .cart .credential-already-in-use .actions a, .online-ordering .cart .mobile-update-widget .actions a, .online-ordering .cart .unlink-mobile-number .actions a, .online-ordering .cart .add-credit-card .actions a, .online-ordering .cart .add-comments .actions a, .online-ordering .cart .add-coupon .actions a, .online-ordering .cart .add-delivery-address .actions a, .online-ordering .cart .update-display-name .actions a {
  color: var(--color-secondary);
  white-space: nowrap;
  margin-left: 5px;
  font-size: .75rem;
  font-weight: 500;
  text-decoration: none;
}

.online-ordering .cart .credential-already-in-use b, .online-ordering .cart .mobile-update-widget b, .online-ordering .cart .unlink-mobile-number b, .online-ordering .cart .add-credit-card b, .online-ordering .cart .add-comments b, .online-ordering .cart .add-coupon b, .online-ordering .cart .add-delivery-address b, .online-ordering .cart .update-display-name b {
  color: #426ed9;
  white-space: nowrap;
  font-weight: 500;
}

.online-ordering .cart .credential-already-in-use .warning-text, .online-ordering .cart .mobile-update-widget .warning-text, .online-ordering .cart .unlink-mobile-number .warning-text, .online-ordering .cart .add-credit-card .warning-text, .online-ordering .cart .add-comments .warning-text, .online-ordering .cart .add-coupon .warning-text, .online-ordering .cart .add-delivery-address .warning-text, .online-ordering .cart .update-display-name .warning-text {
  color: var(--color-secondary);
  margin-left: 4px;
  font-size: 10.5px;
}

.online-ordering .cart .mobile-number .name {
  padding: 5px 20px 10px;
}

.online-ordering .cart > .alert {
  margin: 5px 20px 20px;
}

.online-ordering .cart .StripeElement {
  margin-bottom: 10px;
}

.online-ordering .cart .powered-by-stripe {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 10px;
  display: flex;
}

.online-ordering .cart .powered-by-stripe .logo {
  margin-right: 10px;
}

.online-ordering .cart .powered-by-stripe .logo img {
  width: 40px;
}

.online-ordering .cart .powered-by-stripe .description {
  color: #6c6c6c;
  font-size: 10.5px;
}

.online-ordering nav {
  z-index: 999;
  max-width: 70%;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  background-color: #fff;
  flex-direction: column;
  grid-area: nav;
  padding-bottom: 10px;
  display: flex;
  overflow-y: scroll;
  box-shadow: 0 0 3px #687a9b40;
}

.online-ordering nav::-webkit-scrollbar {
  width: 1px;
}

.online-ordering nav::-webkit-scrollbar-track {
  background: none;
}

.online-ordering nav::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.online-ordering nav .title {
  text-transform: uppercase;
  color: #92929d;
  letter-spacing: 1px;
  margin-top: 20px;
  margin-bottom: 5px;
  margin-left: 20px;
  font-size: 11.5px;
  font-weight: 400;
}

.online-ordering nav .link {
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;
  padding: 8px 40px 8px 16px;
  display: flex;
}

.online-ordering nav .link .icon {
  color: #92929d;
  margin-right: 16px;
  font-size: 21px;
}

.online-ordering nav .link .label {
  letter-spacing: .1px;
  color: var(--fg);
  white-space: nowrap;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: inline-block;
}

.online-ordering nav .link.selected {
  border-left: 4px solid var(--color-primary);
}

.online-ordering nav .link.selected .icon, .online-ordering nav .link.selected .label {
  color: var(--color-primary);
}

.online-ordering nav .link.logout {
  background: #ff974a;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  margin: 0;
  box-shadow: inset -1px 0 #e2e2ea;
}

.online-ordering nav {
  border-radius: 10px;
  margin: 20px;
  transition: transform .3s;
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(120%);
}

.online-ordering nav.show {
  transform: none;
}

.online-ordering .overlay.show {
  z-index: 998;
  background: #0006;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.online-ordering .profile {
  text-align: center;
  border-bottom: 1px solid #f1f1f5;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
  padding: 40px 0;
  display: flex;
}

.online-ordering .profile .name {
  letter-spacing: .1px;
  color: #171725;
  margin-top: 10px;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.online-ordering .profile .message {
  letter-spacing: .1px;
  color: #92929d;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
}

.online-ordering .contact {
  grid-gap: 20px;
  border-bottom: 1px solid #f1f1f5;
  margin: 0 20px;
  padding: 25px 0;
  display: grid;
}

.online-ordering .contact .item {
  grid-gap: 2px;
  margin-left: 5px;
  display: grid;
}

.online-ordering .contact .item label {
  letter-spacing: .8px;
  text-transform: uppercase;
  color: #92929d;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.online-ordering .contact .item .value {
  letter-spacing: .1px;
  color: #171725;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  display: block;
}

.online-ordering .avatar {
  width: 60px;
  height: 60px;
}

.online-ordering .avatar img {
  width: 54px;
  border: 3px solid #f1f1f5;
  border-radius: 10px;
}

@media (min-width: 768px) {
  .online-ordering .order-history-page form > .form-items > .form-item {
    max-width: 300px;
  }
}

.online-ordering .order-history-page .date-range {
  margin-top: 12px;
  padding-left: 6px;
  font-size: 13px;
  font-style: italic;
}

.online-ordering .order-history-page .summary {
  grid-row-gap: 20px;
  grid-column-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  margin: 20px 0;
  display: grid;
}

.online-ordering .order-history-page .summary .statistic {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 10px 20px 15px;
  box-shadow: 0 0 3px #687a9b40;
}

.online-ordering .order-history-page .summary .statistic .title {
  letter-spacing: .1px;
  color: #555;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.online-ordering .order-history-page .summary .statistic .value {
  grid-template-columns: max-content max-content;
  justify-content: start;
  align-items: center;
  margin-top: 10px;
  display: grid;
}

.online-ordering .order-history-page .summary .statistic .prefix {
  color: #171725;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.online-ordering .order-history-page .summary .statistic .suffix {
  letter-spacing: .1px;
  color: #777;
  margin-left: 8px;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.online-ordering .order-history-page .summary .statistic.cash .suffix, .online-ordering .order-history-page .summary .statistic.credit-card .suffix {
  color: #3dd598;
}

.online-ordering .order-history-page .summary .statistic.pickup .suffix, .online-ordering .order-history-page .summary .statistic.delivery .suffix {
  color: #ff974a;
}

.online-ordering .order-history-page .summary .statistic.dine-in .suffix {
  color: #a461d8;
}

.online-ordering .order-history-page .list {
  grid-gap: 25px;
  display: grid;
}

.online-ordering .order-history-page .order-list-view {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-columns: min-content 1fr min-content;
  grid-template-areas: "status-indicator collection-type status"
                       ". time grand-total"
                       ". message message"
                       ". actions actions";
  padding: 18px 0;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.online-ordering .order-history-page .order-list-view .status-indicator {
  grid-area: status-indicator;
  align-self: center;
  margin: 0 15px;
}

.online-ordering .order-history-page .order-list-view .collection-type {
  color: #000521;
  grid-area: collection-type;
  align-self: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.online-ordering .order-history-page .order-list-view .grand-total {
  color: #fa7268;
  text-align: right;
  grid-area: grand-total;
  align-self: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}

.online-ordering .order-history-page .order-list-view .time {
  white-space: nowrap;
  color: #696974;
  grid-area: time;
  align-self: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.online-ordering .order-history-page .order-list-view .message {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  grid-area: message;
}

.online-ordering .order-history-page .order-list-view .status {
  white-space: nowrap;
  grid-area: status;
  place-self: center end;
}

.online-ordering .order-history-page .order-list-view .actions {
  grid-area: actions;
}

.online-ordering .order-history-page .order-list-view .status {
  margin-right: 20px;
}

.online-ordering .order-history-page .order-list-view .grand-total, .online-ordering .order-history-page .order-list-view .message {
  padding-right: 20px;
}

.online-ordering .order-history-page .order-list-view .time, .online-ordering .order-history-page .order-list-view .grand-total, .online-ordering .order-history-page .order-list-view .message {
  border-bottom: 1px dashed #e9ecf3;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.online-ordering .order-history-page .order-list-view .time, .online-ordering .order-history-page .order-list-view .grand-total {
  margin-top: 8px;
}

.online-ordering .order-history-page .order-list-view .actions {
  flex-direction: row;
  display: flex;
}

.online-ordering .order-history-page .order-list-view .actions button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  box-shadow: none;
  background-color: #0000;
  border-radius: 4px;
  margin-right: 10px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 400;
}

.online-ordering .order-history-page .order-list-view .actions .accept-order {
  border: 1px solid var(--button-success-bg);
  color: #0e9347;
  background-color: #0000;
}

.online-ordering .order-history-page .order-list-view .actions .reject-order {
  color: #fc5a5a;
  background-color: #0000;
  border: 1px solid #fc5a5a;
}

.coupons {
  grid-gap: 20px;
  display: grid;
}

.coupon {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 3px #687a9b40;
}

@media (max-width: 767px) {
  .coupon .body {
    grid-template: "a a" min-content
                   "b c" 1fr
                   "d d" min-content
                   / 1fr min-content;
    display: grid;
  }

  .coupon .body .name {
    color: var(--fg);
    grid-area: b;
    align-self: flex-start;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }

  .coupon .body .code {
    color: var(--fg);
    color: var(--color-secondary);
    grid-area: c;
    align-self: start;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }

  .coupon .body .description {
    letter-spacing: .1px;
    color: #8d92a3;
    grid-area: d;
    align-self: flex-start;
    padding-bottom: 10px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }

  .coupon .body .name, .coupon .body .code, .coupon .body .description {
    padding-left: 18px;
    padding-right: 18px;
  }

  .coupon .body .name, .coupon .body .code {
    padding-top: 12px;
  }

  .coupon .body .description {
    padding-bottom: 15px;
  }
}

@media (min-width: 768px) {
  .coupon .body {
    grid-template: "a d" min-content
                   "b d" 1fr
                   "c d" min-content
                   / 1fr min-content;
    padding: 15px 18px;
    display: grid;
  }

  .coupon .body .name {
    color: var(--fg);
    grid-area: a;
    align-self: flex-start;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }

  .coupon .body .description {
    letter-spacing: .1px;
    color: #8d92a3;
    grid-area: b;
    align-self: flex-start;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }

  .coupon .body .code {
    color: var(--fg);
    color: var(--color-secondary);
    grid-area: c;
    align-self: flex-end;
    margin-top: 4px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
}

.coupon .action {
  background-color: #fafafb;
  border-top: 1px solid #f1f1f5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  flex-direction: row;
  grid-area: e;
  align-items: center;
  padding: 12px 18px;
  display: flex;
}

.coupon .action img {
  cursor: pointer;
  margin-right: 10px;
}

.coupon .action span {
  color: #426ed9;
  cursor: pointer;
  font-family: Fira Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}

.restaurant-website header {
  width: 100%;
  position: fixed;
  top: 0;
}

.restaurant-website main {
  margin-top: var(--safe-area-inset-top);
  padding: 73px 20px 20px;
}

@media (min-width: 1024px) {
  .restaurant-website main {
    max-width: 900px;
    margin: 0 auto;
  }
}

.restaurant-website header {
  padding: 0;
  padding-top: var(--safe-area-inset-top);
  height: 53px;
  z-index: 1;
  background-color: #fff;
  align-items: center;
  box-shadow: 0 0 3px #687a9b40;
}

.restaurant-website header .wrapper {
  width: 100vw;
  height: 53px;
  justify-content: center;
  align-items: center;
  display: grid;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .restaurant-website header .wrapper {
    max-width: 940px;
    margin: 0 auto;
  }
}

.restaurant-website-page {
  grid-row-gap: 30px;
  margin-bottom: 40px;
  display: grid;
}

@media (max-width: 767px) {
  .restaurant-website-page .heros {
    grid-gap: 16px;
    grid-template-columns: 83vw;
    grid-auto-columns: 83vw;
    grid-auto-flow: column;
    display: grid;
    overflow-x: auto;
  }

  .restaurant-website-page .heros::-webkit-scrollbar {
    display: none;
  }

  .restaurant-website-page .hero {
    width: 83vw;
  }
}

@media (min-width: 768px) {
  .restaurant-website-page .heros {
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    display: grid;
  }
}

.restaurant-website-page .hero {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 3px #687a9b40;
}

.restaurant-website-page .hero .content {
  padding: 15px 18px;
}

.restaurant-website-page .hero .title {
  color: var(--fg);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.restaurant-website-page .hero .description {
  color: #666;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
}

.restaurant-website-page .hero .title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.restaurant-website-page .hero .description {
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.restaurant-website-page .ctas {
  grid-gap: 12px;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.restaurant-website-page .ctas .cta {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 18px 18px 30px;
  box-shadow: 0 0 3px #687a9b40;
}

.restaurant-website-page .ctas .cta .title {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
}

.restaurant-website-page .ctas .cta .icon img {
  height: 18px;
  width: auto;
}

.restaurant-website-page .ctas .cta.food-order {
  background: linear-gradient(148.83deg, #3a91ec .88%, #426ed9 100%);
}

.restaurant-website-page .ctas .cta.book-a-table {
  background: linear-gradient(150.26deg, #ff825f 11.6%, #ff4c51 86.35%);
}

.restaurant-website-page .quick-links {
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(80px, .5fr));
  display: grid;
}

.restaurant-website-page .quick-links .quick-link {
  background-color: var(--card-bg);
  cursor: pointer;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.restaurant-website-page .quick-links .quick-link .icon {
  width: 40px;
  color: #1b2749;
  margin: 5px auto;
}

.restaurant-website-page .quick-links .quick-link .label {
  text-align: center;
  color: #7f8592;
  padding-top: 3px;
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
}

.restaurant-website-page .about {
  background-color: var(--card-bg);
  cursor: pointer;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-columns: min-content 1fr;
  grid-template-areas: "a b"
                       "a c";
  align-items: center;
  padding: 15px 18px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.restaurant-website-page .about .icon {
  margin-top: 1px;
  margin-right: 15px;
}

.restaurant-website-page .about .icon i {
  background-color: var(--color-secondary);
  color: #fff;
  border-radius: 50%;
  grid-area: a;
  padding: 5px;
}

.restaurant-website-page .about .title {
  letter-spacing: .1px;
  color: var(--fg);
  text-transform: uppercase;
  grid-area: b;
  margin: 8px 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.restaurant-website-page .about .description {
  letter-spacing: .1px;
  color: #666;
  grid-area: c;
  font-size: 14px;
  font-style: normal;
  line-height: 22px;
}

.restaurant-website-page .photos {
  grid-row-gap: 20px;
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 15px 18px 25px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.restaurant-website-page .photos .header {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.restaurant-website-page .photos .header .icon {
  margin-top: 1px;
  margin-right: 15px;
}

.restaurant-website-page .photos .header .icon i {
  background-color: var(--color-secondary);
  color: #fff;
  border-radius: 50%;
  grid-area: a;
  padding: 5px;
}

.restaurant-website-page .photos .header .title {
  letter-spacing: .1px;
  color: var(--fg);
  text-transform: uppercase;
  grid-area: b;
  margin: 8px 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.restaurant-website-page .photos .body {
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  display: grid;
}

.restaurant-website-page .photos .image img {
  border-radius: 10px;
}

.store-website header {
  width: 100%;
  position: fixed;
  top: 0;
}

.store-website main {
  margin-top: var(--safe-area-inset-top);
  padding: 73px 20px 20px;
}

@media (min-width: 1024px) {
  .store-website main {
    max-width: 900px;
    margin: 0 auto;
  }
}

.store-website header {
  padding: 0;
  padding-top: var(--safe-area-inset-top);
  height: 53px;
  z-index: 1;
  background-color: #fff;
  align-items: center;
  box-shadow: 0 0 3px #687a9b40;
}

.store-website header .wrapper {
  width: 100vw;
  height: 53px;
  justify-content: center;
  align-items: center;
  display: grid;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .store-website header .wrapper {
    max-width: 940px;
    margin: 0 auto;
  }
}

.store-website-page {
  grid-row-gap: 30px;
  margin-bottom: 40px;
  display: grid;
}

@media (max-width: 767px) {
  .store-website-page .heros {
    grid-gap: 16px;
    grid-template-columns: 83vw;
    grid-auto-columns: 83vw;
    grid-auto-flow: column;
    display: grid;
    overflow-x: auto;
  }

  .store-website-page .heros::-webkit-scrollbar {
    display: none;
  }

  .store-website-page .hero {
    width: 83vw;
  }
}

@media (min-width: 768px) {
  .store-website-page .heros {
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    display: grid;
  }
}

.store-website-page .hero {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 3px #687a9b40;
}

.store-website-page .hero .content {
  padding: 15px 18px;
}

.store-website-page .hero .title {
  color: var(--fg);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.store-website-page .hero .description {
  color: #666;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
}

.store-website-page .hero .title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.store-website-page .hero .description {
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.store-website-page .ctas {
  grid-gap: 12px;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.store-website-page .ctas a:link, .store-website-page .ctas a:visited, .store-website-page .ctas a:hover, .store-website-page .ctas a:active {
  text-decoration: none;
}

.store-website-page .ctas .cta {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 18px 18px 30px;
  box-shadow: 0 0 3px #687a9b40;
}

.store-website-page .ctas .cta .title {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
}

.store-website-page .ctas .cta .icon {
  color: #fff;
  font-size: 30px;
}

.store-website-page .ctas .cta.food-order {
  background: linear-gradient(148.83deg, #3a91ec .88%, #426ed9 100%);
}

.store-website-page .ctas .cta.book-a-table {
  background: linear-gradient(150.26deg, #ff825f 11.6%, #ff4c51 86.35%);
}

.store-website-page .quick-links {
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(80px, .5fr));
  display: grid;
}

.store-website-page .quick-links .quick-link {
  background-color: var(--card-bg);
  cursor: pointer;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.store-website-page .quick-links .quick-link .icon {
  width: 40px;
  color: #1b2749;
  margin: 5px auto;
}

.store-website-page .quick-links .quick-link .label {
  text-align: center;
  color: #7f8592;
  padding-top: 3px;
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
}

.store-website-page .about {
  background-color: var(--card-bg);
  cursor: pointer;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-columns: min-content 1fr;
  grid-template-areas: "a b"
                       "a c";
  align-items: center;
  padding: 15px 18px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.store-website-page .about .icon {
  margin-top: 1px;
  margin-right: 15px;
}

.store-website-page .about .icon i {
  background-color: var(--color-secondary);
  color: #fff;
  border-radius: 50%;
  grid-area: a;
  padding: 5px;
}

.store-website-page .about .title {
  letter-spacing: .1px;
  color: var(--fg);
  text-transform: uppercase;
  grid-area: b;
  margin: 8px 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.store-website-page .about .description {
  letter-spacing: .1px;
  color: #666;
  grid-area: c;
  font-size: 14px;
  font-style: normal;
  line-height: 22px;
}

.store-website-page .photos {
  grid-row-gap: 20px;
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 15px 18px 25px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.store-website-page .photos .header {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.store-website-page .photos .header .icon {
  margin-top: 1px;
  margin-right: 15px;
}

.store-website-page .photos .header .icon i {
  background-color: var(--color-secondary);
  color: #fff;
  border-radius: 50%;
  grid-area: a;
  padding: 5px;
}

.store-website-page .photos .header .title {
  letter-spacing: .1px;
  color: var(--fg);
  text-transform: uppercase;
  grid-area: b;
  margin: 8px 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.store-website-page .photos .body {
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  display: grid;
}

.store-website-page .photos .image img {
  border-radius: 10px;
}

.website-items-page .items {
  grid-gap: 20px;
  display: grid;
}

.website-items-page .item {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 3px #687a9b40;
}

@media (max-width: 767px) {
  .website-items-page .item .body {
    grid-template: "a a" min-content
                   "b c" 1fr
                   "d d" min-content
                   / 1fr min-content;
    display: grid;
  }

  .website-items-page .item .body .image {
    grid-area: a;
    overflow: hidden;
  }

  .website-items-page .item .body .image img {
    border-radius: 10px;
  }

  .website-items-page .item .body .name {
    color: var(--fg);
    grid-area: b;
    align-self: flex-start;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }

  .website-items-page .item .body .price {
    color: var(--fg);
    color: var(--color-secondary);
    grid-area: c;
    align-self: start;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }

  .website-items-page .item .body .description {
    letter-spacing: .1px;
    color: #8d92a3;
    grid-area: d;
    align-self: flex-start;
    padding-bottom: 10px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }

  .website-items-page .item .body .name, .website-items-page .item .body .price, .website-items-page .item .body .description {
    padding-left: 18px;
    padding-right: 18px;
  }

  .website-items-page .item .body .image {
    border-bottom: 1px dashed #e9ecf3;
    margin-left: 12px;
    margin-right: 12px;
    padding-top: 14px;
    padding-bottom: 20px;
  }

  .website-items-page .item .body .name, .website-items-page .item .body .price {
    padding-top: 12px;
  }

  .website-items-page .item .body .description {
    padding-bottom: 15px;
  }
}

@media (min-width: 768px) {
  .website-items-page .item .body {
    grid-template: "a d" min-content
                   "b d" 1fr
                   "c d" min-content
                   / 1fr min-content;
    padding: 15px 18px;
    display: grid;
  }

  .website-items-page .item .body .image {
    width: 100px;
    grid-area: d;
    margin-left: 10px;
    overflow: hidden;
  }

  .website-items-page .item .body .image img {
    border-radius: 10px;
  }

  .website-items-page .item .body .name {
    color: var(--fg);
    grid-area: a;
    align-self: flex-start;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }

  .website-items-page .item .body .description {
    letter-spacing: .1px;
    color: #8d92a3;
    grid-area: b;
    align-self: flex-start;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }

  .website-items-page .item .body .price {
    color: var(--fg);
    color: var(--color-secondary);
    grid-area: c;
    align-self: flex-end;
    margin-top: 4px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
}

.website-items-page .item .action {
  background-color: #fafafb;
  border-top: 1px solid #f1f1f5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  flex-direction: row;
  grid-area: e;
  align-items: center;
  padding: 12px 18px;
  display: flex;
}

.website-items-page .item .action img {
  margin-right: 10px;
}

.website-items-page .item .action span {
  color: #426ed9;
  font-family: Fira Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}

.website-item-page .item-actions {
  grid-gap: 10px;
  grid-template-columns: 120px 1fr;
  margin-top: 30px;
  margin-bottom: 10px;
  display: grid;
}

.website-item-page .item-actions .quantity {
  background-color: #fff;
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-columns: min-content min-content min-content;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.website-item-page .item-actions .quantity .icon {
  font-size: 16px;
}

.website-item-page .item-actions .add-to-cart-button {
  background-color: var(--card-bg);
  background-color: var(--button-success-bg);
  color: var(--button-success-fg);
  white-space: nowrap;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-columns: min-content min-content;
  justify-content: space-between;
  padding: 20px;
  font-family: Fira Sans;
  font-weight: 500;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.online-ordering .modifiers {
  grid-gap: 20px;
  display: grid;
}

.online-ordering .modifier {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.online-ordering .modifier .header {
  border-bottom: 1px solid #f1f1f5;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 18px;
  display: flex;
}

.online-ordering .modifier .header .title {
  letter-spacing: .1px;
  color: var(--fg);
  color: var(--color-secondary);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.online-ordering .modifier .options {
  padding: 15px 18px;
  display: grid;
}

.online-ordering .modifier .options .option:last-child {
  border-bottom: 0;
}

.online-ordering .modifier .option {
  color: #555;
  border-bottom: 1px dashed #e9ecf3;
  grid-template-columns: min-content 1fr min-content;
  padding: 10px 0;
  font-weight: 400;
  display: grid;
}

.online-ordering .modifier .option.selected {
  color: var(--fg);
  font-weight: 500;
}

.online-ordering .modifier .option input {
  margin-right: 12px;
}

.online-ordering .modifier .option {
  grid-template-columns: max-content 1fr 75px;
  grid-template-areas: "a b c";
  align-items: center;
}

.online-ordering .modifier .option .clickable {
  flex-direction: row;
  grid-area: a;
  align-items: center;
  margin-right: 10px;
  display: flex;
}

.online-ordering .modifier .option .quantity {
  height: 20px;
  flex-direction: row;
  grid-area: b;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.online-ordering .modifier .option .quantity .number {
  color: #000521;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.online-ordering .modifier .option .quantity .icon {
  cursor: pointer;
}

.online-ordering .modifier .option .quantity .dec {
  padding-left: 15px;
  padding-right: 10px;
}

.online-ordering .modifier .option .quantity .inc {
  padding-left: 10px;
  padding-right: 15px;
}

.online-ordering .modifier .option .quantity .icon {
  color: #8d92a3;
  font-size: 20px;
}

.online-ordering .modifier .option .extra-charge {
  white-space: nowrap;
  grid-area: c;
  justify-content: flex-end;
  display: flex;
}

.collection-type-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  margin-bottom: 30px;
  display: grid;
  overflow: hidden;
  box-shadow: 0 0 3px #687a9b40;
}

.collection-type-widget .collection-type {
  text-align: center;
  cursor: pointer;
  border-right: 1px solid #e2e2ea;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  display: flex;
}

.collection-type-widget .collection-type:last-child {
  border-right: none;
}

.collection-type-widget .collection-type span {
  letter-spacing: .1px;
  color: #171725;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
}

.collection-type-widget .collection-type.selected {
  background-color: #a461d81a;
}

.collection-type-widget .collection-type.selected .dot {
  width: 7px;
  height: 7px;
  background: #a461d8;
  border-radius: 50%;
  margin-right: 8px;
  display: inline-block;
}

.collection-type-banner {
  margin-bottom: 30px;
}

.deal-page .deal-content {
  grid-gap: 20px;
  display: grid;
}

.deal-page .done-button {
  background-color: var(--card-bg);
  background-color: var(--button-success-bg);
  color: var(--button-success-fg);
  white-space: nowrap;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  margin-top: 10px;
  padding: 20px;
  font-family: Fira Sans;
  box-shadow: 0 0 3px #687a9b40;
}

.deal-page .choose-pizza {
  grid-gap: 20px;
  display: grid;
}

.deal-page .choose-pizza .pizza-spec {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 15px 18px;
  overflow: hidden;
  box-shadow: 0 0 3px #687a9b40;
}

.deal-page .choose-pizza .pizza-spec .header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.deal-page .choose-pizza .pizza-spec .name {
  color: var(--fg);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.deal-page .choose-pizza .pizza-spec .price {
  color: var(--fg);
  color: var(--color-secondary);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.deal-page .choose-pizza .pizza-spec .description {
  letter-spacing: .1px;
  color: #8d92a3;
  margin-bottom: 5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.deal-page .customize-pizza, .deal-pizzas {
  grid-gap: 20px;
  display: grid;
}

.deal-pizzas .deal-pizza {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.deal-pizzas .deal-pizza .header {
  border-bottom: 1px solid #f1f1f5;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 18px;
  display: flex;
}

.deal-pizzas .deal-pizza .header .title {
  letter-spacing: .1px;
  color: var(--fg);
  color: var(--color-secondary);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.deal-pizzas .deal-pizza .body {
  margin-top: 5px;
  margin-bottom: 5px;
}

.deal-pizzas .deal-pizza button {
  box-shadow: none;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 400;
}

.deal-pizzas .deal-pizza .half-and-half .deal-pizza-entry:first-child {
  border-bottom: 1px dashed #e9ecf3;
  margin-bottom: 3px;
  padding-bottom: 18px;
}

.deal-pizzas .deal-pizza .deal-pizza-entry {
  padding: 15px 18px;
}

.deal-pizzas .deal-pizza .deal-pizza-entry .choose-container {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.deal-pizzas .deal-pizza .deal-pizza-entry .choose-pizza-button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  background-color: #0000;
}

.deal-pizzas .deal-pizza .deal-pizza-entry .customize-pizza-button {
  border: 1px solid var(--button-success-bg);
  color: #0e9347;
  background-color: #0000;
}

.deal-pizzas .deal-pizza .deal-pizza-entry .summary {
  color: #555;
  margin: 15px 5px 0;
  font-size: 12px;
  font-weight: 300;
}

.deal-pizzas .deal-pizza .deal-pizza-entry .summary pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0;
  font-family: Poppins;
  overflow-x: auto;
}

.deal-items {
  grid-gap: 20px;
  display: grid;
}

.deal-items .deal-item {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 3px #687a9b40;
}

.deal-items .deal-item .header {
  border-bottom: 1px solid #f1f1f5;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 18px;
  display: flex;
}

.deal-items .deal-item .header .name {
  letter-spacing: .1px;
  color: var(--fg);
  color: var(--color-secondary);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.deal-items .deal-item .summary {
  color: #555;
  padding: 15px 18px;
  font-size: 12px;
  font-weight: 300;
}

.deal-items .deal-item .summary pre {
  margin: 0;
  font-family: Poppins;
}

.deal-items .deal-item button {
  box-shadow: none;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 400;
}

.deal-items .deal-item .modifiers {
  grid-gap: 0 !important;
}

.deal-items .deal-item .modifier {
  box-shadow: none !important;
  border: none !important;
  border-radius: 0 !important;
}

.deal-items .deal-item .modifier .header {
  background-color: #f9f9f9;
  border-bottom: 1px solid #f1f1f5;
  margin: 0;
  padding: 12px 18px;
  border: 0 !important;
}

.deal-items .deal-item .modifier .header .title {
  color: var(--fg);
  font-size: .8rem;
  font-weight: 300;
}

.item-actions {
  grid-gap: 10px;
  grid-template-columns: 120px 1fr;
  margin-top: 30px;
  margin-bottom: 10px;
  display: grid;
}

.item-actions .quantity {
  background-color: #fff;
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-columns: min-content min-content min-content;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.item-actions .quantity .icon {
  font-size: 16px;
}

.item-actions .add-to-cart-button {
  background-color: var(--card-bg);
  background-color: var(--button-success-bg);
  color: var(--button-success-fg);
  white-space: nowrap;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-columns: min-content min-content;
  justify-content: space-between;
  padding: 20px;
  font-family: Fira Sans;
  font-weight: 500;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.website-order-success-page #order-history-button {
  color: var(--button-primary-fg);
  background-color: #2563eb;
  margin-left: 6px;
}

.table-booking-settings-edit-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.table-booking-settings-edit-widget form {
  margin-top: 20px;
  padding: 15px 18px;
}

.table-booking-settings-edit-widget form .form-items > .inline-title {
  margin: 0 -18px;
}

.table-booking-settings-edit-widget form .form-items > .inline-title label {
  white-space: nowrap;
}

.table-booking-settings-edit-widget .seating-area-info {
  background-color: var(--card-bg);
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  margin: .8rem 0;
  padding: 1rem;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.table-booking-settings-edit-widget .seating-area-info button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  background-color: #0000;
}

.table-booking-settings-edit-widget .seating-area-info .options {
  margin-top: 1rem;
}

.table-booking-settings-edit-widget .seating-area-info .options .area-name {
  background-color: var(--card-bg);
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  margin: 1.2rem 0;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.table-booking-settings-edit-widget .seating-area-info .options .info {
  grid-gap: 18px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 15px;
  display: grid;
  box-shadow: 0 2px #c4cbd840;
}

.table-booking-settings-edit-widget .seating-area-info .options .info .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.table-booking-settings-edit-widget .seating-area-info .options .info .switch label {
  grid-area: a;
  align-self: center;
}

.table-booking-settings-edit-widget .seating-area-info .options .info .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.table-booking-settings-edit-widget .seating-area-info .options .info .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.table-booking-settings-edit-widget .seating-area-info .options .info .switch label {
  text-align: left !important;
}

.table-booking-settings-edit-widget .seating-area-info .options .actions {
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  padding: 5px 12px;
  display: grid;
}

.table-booking-settings-edit-widget .seating-area-info .options .actions .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.table-booking-settings-edit-widget .seating-area-info .options .actions .action img {
  width: 26px;
  margin-right: 5px;
}

.table-booking-settings-edit-widget .request-info {
  background-color: var(--card-bg);
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  margin: .8rem 0;
  padding: 1rem;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.table-booking-settings-edit-widget .request-info button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  background-color: #0000;
}

.table-booking-settings-edit-widget .request-info .options {
  margin-top: 1rem;
}

.table-booking-settings-edit-widget .request-info .options .template {
  background-color: var(--card-bg);
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  margin: 1.2rem 0;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.table-booking-settings-edit-widget .request-info .options .info {
  grid-gap: 18px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 15px;
  display: grid;
  box-shadow: 0 2px #c4cbd840;
}

.table-booking-settings-edit-widget .request-info .options .info .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.table-booking-settings-edit-widget .request-info .options .info .switch label {
  grid-area: a;
  align-self: center;
}

.table-booking-settings-edit-widget .request-info .options .info .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.table-booking-settings-edit-widget .request-info .options .info .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.table-booking-settings-edit-widget .request-info .options .info .switch label {
  text-align: left !important;
}

.table-booking-settings-edit-widget .request-info .options .actions {
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  padding: 5px 12px;
  display: grid;
}

.table-booking-settings-edit-widget .request-info .options .actions .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.table-booking-settings-edit-widget .request-info .options .actions .action img {
  width: 26px;
  margin-right: 5px;
}

.table-booking-settings-edit-widget .table-booking-unavailability-container > label {
  align-self: flex-start !important;
}

.table-booking-settings-edit-widget .add-unavailable-date-button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  background-color: #0000;
}

.table-booking-settings-unavailable-date-list-widget .unavailable-date {
  background-color: var(--card-bg);
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  margin-bottom: 20px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.table-booking-settings-unavailable-date-list-widget .info {
  grid-gap: 18px;
  background-color: #fff;
  border-radius: 10px;
  grid-template-areas: "date-message"
                       "hours";
  gap: 24px;
  padding: 20px 15px;
  display: grid;
  box-shadow: 0 2px #c4cbd840;
}

.table-booking-settings-unavailable-date-list-widget .info .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.table-booking-settings-unavailable-date-list-widget .info .switch label {
  grid-area: a;
  align-self: center;
}

.table-booking-settings-unavailable-date-list-widget .info .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.table-booking-settings-unavailable-date-list-widget .info .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.table-booking-settings-unavailable-date-list-widget .info .switch label {
  text-align: left !important;
}

.table-booking-settings-unavailable-date-list-widget .info input {
  border-radius: 8px;
  margin-top: 6px;
}

.table-booking-settings-unavailable-date-list-widget .info .date-message-container {
  grid-area: date-message;
  grid-template-columns: repeat(2, auto);
  gap: 20px;
  display: grid;
}

@media (max-width: 767px) {
  .table-booking-settings-unavailable-date-list-widget .info .date-message-container {
    grid-template-columns: auto;
  }
}

.table-booking-settings-unavailable-date-list-widget .info .date-message-container > .form-item > .form-input > .daypicker-container > .DayPickerInput input {
  background-image: url("icon-calendar.60632f5f.svg");
  background-position: 97%;
  background-repeat: no-repeat;
  margin-right: 60px;
  padding: 12px 14px;
}

.table-booking-settings-unavailable-date-list-widget .info .date-message-container > .form-item > .form-input.invalid > .daypicker-container > .DayPickerInput input {
  border: 1px solid #f43f5e;
}

.table-booking-settings-unavailable-date-list-widget .info .date-message-container > .form-item > .form-input.message input {
  padding: 12px 14px;
}

.table-booking-settings-unavailable-date-list-widget .table-booking-settings-unavailable-time-of-day-list-widget {
  grid-area: hours;
}

.table-booking-settings-unavailable-date-list-widget .add-hours-button {
  box-shadow: none;
  color: #2563eb;
  width: max-content;
  background-color: #0000;
  border: 0;
  justify-content: flex-start;
  padding: 7px 12px 7px 4px;
  text-decoration: underline;
}

.table-booking-settings-unavailable-date-list-widget .add-hours-button .label {
  display: flex;
}

.table-booking-settings-unavailable-date-list-widget .add-hours-button .label .icon {
  margin-right: 4px;
}

.table-booking-settings-unavailable-date-list-widget .add-hours-button:hover {
  background-color: #eff6ff;
}

.table-booking-settings-unavailable-date-list-widget .actions {
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  padding: 5px 12px;
  display: grid;
}

.table-booking-settings-unavailable-date-list-widget .actions .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.table-booking-settings-unavailable-date-list-widget .actions .action img {
  width: 26px;
  margin-right: 5px;
}

.table-booking-settings-unavailable-time-of-day-list-widget {
  grid-template-columns: 1fr auto;
  gap: 12px;
  display: grid;
}

.table-booking-settings-unavailable-time-of-day-list-widget > .form-item > .form-input {
  gap: 12px;
  display: grid;
}

.table-booking-settings-unavailable-time-of-day-list-widget > .form-item > .form-input > .unavailable-time-of-day {
  grid-template-columns: 1fr 16px;
  gap: 20px;
  display: grid;
}

.table-booking-settings-unavailable-time-of-day-list-widget > .form-item > .form-input > .unavailable-time-of-day > .form-item > .form-input {
  gap: 12px;
  display: grid;
}

.table-booking-settings-unavailable-time-of-day-list-widget > .form-item > .form-input > .unavailable-time-of-day > .form-item > .form-input > .day-interval {
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  display: grid;
}

@media (max-width: 767px) {
  .table-booking-settings-unavailable-time-of-day-list-widget > .form-item > .form-input > .unavailable-time-of-day > .form-item > .form-input > .day-interval {
    grid-template-columns: 1fr;
    gap: 12px;
  }
}

.table-booking-settings-unavailable-time-of-day-list-widget > .form-item > .form-input > .unavailable-time-of-day > .form-item > .form-input > .day-interval > .time-input > .input-box input {
  margin-top: 0;
}

.table-booking-settings-unavailable-time-of-day-list-widget > .form-item > .form-input > .unavailable-time-of-day > .form-item > .form-input > .day-interval > .time-input:first-of-type {
  width: 95%;
}

@media (max-width: 767px) {
  .table-booking-settings-unavailable-time-of-day-list-widget > .form-item > .form-input > .unavailable-time-of-day > .form-item > .form-input > .day-interval > .time-input:first-of-type {
    width: 100%;
  }
}

.table-booking-settings-unavailable-time-of-day-list-widget > .form-item > .form-input > .unavailable-time-of-day > .form-item > .form-input > .day-interval > .time-input:last-of-type {
  margin-left: -5%;
}

@media (max-width: 767px) {
  .table-booking-settings-unavailable-time-of-day-list-widget > .form-item > .form-input > .unavailable-time-of-day > .form-item > .form-input > .day-interval > .time-input:last-of-type {
    margin-left: 0;
  }
}

.table-booking-settings-unavailable-time-of-day-list-widget > .form-item > .form-input > .unavailable-time-of-day > .delete-icon {
  color: #999;
  cursor: pointer;
  color: #f43f5e;
  padding-top: 4px;
  font-size: 24px;
}

.table-booking-settings-unavailable-time-of-day-list-widget > .form-item > .form-input > .unavailable-time-of-day > .delete-icon img {
  width: 26px;
  margin-right: 5px;
}

.table-booking-settings-unavailable-time-of-day-list-widget .add-icon {
  color: #999;
  cursor: pointer;
  padding-top: 4px;
  font-size: 24px;
}

.table-booking-settings-unavailable-time-of-day-list-widget .add-icon img {
  width: 26px;
  margin-right: 5px;
}

.table-booking-history-page .header {
  margin-bottom: unset;
}

.table-booking-history-page .alert {
  margin-top: 1rem;
}

.table-booking-view-page .header {
  margin-bottom: unset;
}

.table-booking-view-page .alert {
  margin-top: 1rem;
}

.table-booking-history-list-widget .header {
  margin-bottom: 20px;
}

.table-booking-history-list-widget .header .title {
  letter-spacing: .1px;
  color: var(--fg);
  padding-left: 6px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.table-booking-history-list-item-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-columns: min-content 1fr min-content;
  gap: 1rem;
  margin: 1rem 0;
  padding: 1rem;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.table-booking-history-list-item-widget .details {
  grid-template-rows: min-content;
  gap: .8rem;
  display: grid;
}

.table-booking-history-list-item-widget .details .booked-for {
  font-size: 16px;
  font-weight: 500;
}

.table-booking-history-list-item-widget .details .customer span {
  display: block;
}

.table-booking-history-list-item-widget .details .seating-area span {
  color: #0062ff;
  background-color: #bfdbfe;
  margin: 0 1rem .5rem 0;
  padding: .2rem 1rem;
  font-weight: 400;
}

.table-booking-history-list-item-widget .details .special-request {
  border-color: #e2e2ea;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  padding-bottom: 10px;
}

.table-booking-history-list-item-widget .details .special-request span {
  color: #0062ff;
  background-color: #bfdbfe;
  margin: 0 1rem .5rem 0;
  padding: .2rem 1rem;
  font-weight: 400;
}

.table-booking-history-list-item-widget .details .special-request .note {
  margin-top: 1rem;
}

.table-booking-history-list-item-widget .details .actions {
  grid-template-columns: 100px 100px;
  gap: .5rem;
  display: grid;
}

.table-booking-history-list-item-widget .details .actions .confirm {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  box-shadow: none;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  background-color: #0000;
  border-radius: 4px;
  margin-top: .5rem;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 400;
}

.table-booking-history-list-item-widget .details .actions .reject, .table-booking-history-list-item-widget .details .actions .cancel {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  box-shadow: none;
  color: var(--button-danger-bg);
  border: 1px solid var(--button-danger-bg);
  background-color: #0000;
  border-radius: 4px;
  margin-top: .5rem;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 400;
}

.table-booking-history-list-item-widget > .more-actions-button {
  height: 24px;
  width: 24px;
  cursor: pointer;
  background-color: #ffffff1a;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.table-booking-history-list-item-widget > .more-actions-button > .icon {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.table-booking-history-list-item-widget > .more-actions-button > .icon > img {
  height: 100%;
}

.table-booking-view-widget .details {
  background-color: #fff;
  border-radius: 10px;
}

.table-booking-view-widget .details .detail {
  color: #171725;
  border-bottom: 1px solid #dee0e6;
  align-items: center;
  gap: 10px;
  padding: 15px 20px;
  font-size: 13px;
  line-height: 19.5px;
  display: flex;
}

.table-booking-view-widget .details .detail.time {
  text-transform: uppercase;
}

.table-booking-view-widget .details .detail .label {
  text-transform: uppercase;
  font-weight: 700;
  line-height: 16px;
}

.table-booking-view-widget .details .detail .value {
  margin-left: auto;
  font-weight: 300;
}

.table-booking-view-widget .details .detail .value.bold-700 {
  font-weight: 700;
}

.table-booking-view-widget .details .detail .value.seating {
  color: #0062ff;
  background-color: #e7eafe;
  padding: 2px 8px;
  font-weight: 400;
}

.table-booking-view-widget .details .detail.customer-name .value {
  font-weight: 700;
}

.table-booking-view-widget .details .detail:last-child {
  border-bottom: unset;
}

.table-booking-view-widget .activity-log {
  margin-top: 17.5px;
}

.table-booking-view-widget .activity-log .activity-log-button {
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  display: flex;
}

.table-booking-view-widget .activity-log .activity-log-button svg {
  margin: 0 16px 0 6px;
  transform: rotate(-90deg);
}

.table-booking-view-widget .activity-log .activity-log-button.close svg {
  transform: rotate(0);
}

.table-booking-back-link-header-widget .title {
  letter-spacing: .1px;
  color: var(--fg);
  margin: 20px 0;
  padding-left: 6px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

@media (max-width: 1024px) {
  .table-booking-back-link-header-widget .title {
    margin: 16px 0 20px;
  }
}

@media (max-width: 768px) {
  .table-booking-back-link-header-widget .title {
    margin: 16px 0;
  }
}

.table-booking-activity-logs-widget {
  margin-top: 17.5px;
}

.table-booking-activity-logs-widget .activity-log-button {
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  display: flex;
}

.table-booking-activity-logs-widget .activity-log-button svg {
  margin: 0 16px 0 6px;
  transform: rotate(-90deg);
}

.table-booking-activity-logs-widget .activity-log-button.close svg {
  transform: rotate(0);
}

.table-booking-activity-logs-list-widget .activity-logs-category .activity-logs-category-date {
  color: #1c1d3e;
  margin-top: 20px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.table-booking-activity-logs-list-widget .activity-logs-category .activity-logs, .table-booking-activity-logs-list-item-widget {
  gap: 8px;
  display: grid;
}

.table-booking-activity-logs-list-item-widget .field {
  gap: 6px;
  display: flex;
}

.table-booking-activity-logs-list-item-widget .activity-log-time {
  color: #1c1d3e;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.table-booking-activity-logs-list-item-widget .activity-log-action {
  color: #4c4e68;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.table-booking-activity-logs-list-item-widget .capitalize {
  text-transform: capitalize;
}

.table-booking-activity-logs-list-item-widget .lower-case {
  text-transform: lowercase;
}

.table-booking-activity-logs-list-item-widget .removed {
  display: none;
}

.table-booking-activity-logs-list-item-widget .value {
  color: #1c1d3e;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.popout-table-booking-history-list-item-more-actions-widget > .view-booking-details-button {
  cursor: pointer;
  align-items: center;
  padding: 14px 16px;
  display: flex;
}

.popout-table-booking-history-list-item-more-actions-widget {
  letter-spacing: .1px;
  color: var(--fg);
  white-space: nowrap;
  flex-direction: column;
  padding: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  position: absolute;
}

.popout-table-booking-history-list-item-more-actions-widget > .view-booking-details-button {
  border-bottom: 1px solid #dee0e6;
  border-radius: 10px;
  margin-top: 26px;
  padding: 12px 16px;
  box-shadow: 0 0 6px #687a9b80;
}

.popout-table-booking-history-list-item-more-actions-widget > .view-booking-details-button > .icon {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  display: flex;
}

.popout-table-booking-history-list-item-more-actions-widget > .view-booking-details-button > .icon > img {
  height: 18px;
}

html, body {
  margin: 0;
}

body {
  background-color: var(--bg);
  overscroll-behavior-y: none;
  font-family: Poppins, sans-serif;
  font-size: .85rem;
  font-weight: 300;
}

a {
  color: var(--link-fg);
  cursor: pointer;
}

b {
  font-weight: 500;
}

textarea {
  resize: none;
}

.component {
  background-color: var(--color-bg);
}

button {
  cursor: pointer;
  box-shadow: 0 0 3px #687a9b40;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.firebase-emulator-warning {
  display: none;
}

.highlight:before {
  content: "";
  height: 16px;
  z-index: -1;
  background: #d1ebff;
  position: absolute;
  bottom: 2px;
  left: 0;
  right: -2px;
}

/*# sourceMappingURL=index.30e21c34.css.map */
