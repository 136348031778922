@use "../viewport";
@use "../colors";
@use "../typography";

.pos-settings-widget {
  .general {
    column-count: 1;
    column-gap: 20px;

    > div {
      break-inside: avoid;
      margin-bottom: 24px;
    }

    @include viewport.tablet {
      column-count: 1;
    }

    @include viewport.desktop {
      column-count: 3;
    }
  }
}
