@use "../card"as card;
@use "../constants"as constants;
@use "../form"as form;
@use "../viewport"as viewport;
@use "../buttons"as buttons;
@use "../colors"as colors;

$padding-horizontal: 15px;

.stripe-settings-widget {
  .stripe-actions {
    @include buttons.button-group();
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    button {
      margin-right: 10px;
    }

    .stripe-account-action {
      border: 1px solid colors.$blue-400;
      border-radius: 4px;
      font-weight: 500;
      background-color: colors.$white;
      i {
        color: colors.$blue-gray-500;
      }
      span {
        color: colors.$blue-600;
      }
    }
  }

  .stripe-accounts {
    display: grid;
    grid-gap: 20px;
    @include viewport.tablet {
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }
    .current-external-account,
    .add-external-account,
    .payout-schedule,
    .update-payout-schedule,
    .connected-account {
      form {
        padding: 20px 0;
      }
      .title {
        @include card.header();
        @include card.header-font();
      }

      @include card.container();

      .form-item {
        padding: 0 $padding-horizontal;
      }

      .actions {
        @include card.footer();
        @include buttons.button-group();
        button {
          margin-right: 10px;
        }
      }
    }

    .add-external-account {
      .StripeElement {
        @include card.content();
      }
    }
    .update-payout-schedule,
    .payout-schedule {
      margin-top: 20px;
    }
  }
}
