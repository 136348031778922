@use "form";
@use "scrollbar";
@use "card";
@use "modal";
@use "viewport";
@use "colors";

@mixin disabled {
  background-color: colors.$material-gray-100;
  border-color: colors.$med-gray;
  color: #696974;
}

.time-input {
  .input-box {
    @include form.form-input();
    padding: form.$padding;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;

    &.disabled {
      .input-box {
        @include disabled();
        cursor: auto;
      }
    }

    input[type='text'] {
      text-align: center;
      border: none;
      width: 25px;
      padding: 0;
    }

    input::placeholder {
      color: colors.$blue-gray-200;
    }

    .separator {
      margin: 0 2.5px;
    }

    .icon {
      cursor: pointer;
      font-size: 16px;
      margin-left: auto;
    }

    &.invalid {
      > .icon {
        color: colors.$rose-800;
      }
    }
  }

  .options {
    @include card.container();
    @include card.content();
    @include modal.container();
    display: flex;
    font-weight: 400;
    height: 200px;
    overflow-y: hidden;
    width: fit-content;

    .option-list {
      @include scrollbar.hide;
      height: inherit;
      overflow-y: scroll;

      .option {
        text-align: center;
        padding: 8px;
        cursor: pointer;
      }

      .selected {
        background-color: var(--message-success-bg);
        color: var(--message-success-text);
        border-radius: 4px;
      }
    }
  }

  @include viewport.mobile {
    .overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 998;
    }
  }

  @include viewport.tablet {
    .options-wrapper {
      position: relative;
    }
    .options {
      position: absolute;
      top: 100%;
      margin-top: 10px;
      z-index: 999;
    }
  }
}
