@use "../colors";
@use "../card";
@use "../viewport";
@use "../scrollbar";

.sms-marketing-new-campaign-page {
  color: colors.$blue-gray-900;

  .header {
    font-size: 24px;
    font-weight: 600;
    line-height: 28.8px;
    margin: 20px 0 32px;
  }

  .sms-marketing-edit-campaign-widget {
    @include card.container();
    padding: 24px;

    .sms-form {
      .form-items {
        @include viewport.desktop() {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 24px;
        }
      }
      .form-input {
        margin-top: 6px;

        input {
          &:disabled {
            color: colors.$blue-gray-500;
          }
        }
      }

      .form-buttons {
        display: flex;
        justify-content: end;
      }

      .campaign-name {
        margin-bottom: 24px;
      }

      .message-input {
        @include scrollbar.hide();

        .form-input {
          &.disabled {
            background-color: transparent !important;
          }
        }

        .error {
          display: none;
        }

        textarea {
          &:disabled {
            background-color: colors.$blue-gray-50;
            color: colors.$blue-gray-500;
          }
        }
      }

      .message-length {
        margin-bottom: 24px;
        text-align: end;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.004em;
      }

      .recipient-count {
        margin: 0 0 24px 4px;
        .label {
          margin-bottom: 6px;
        }

        span {
          color: colors.$blue-800;
          font-weight: 500;
        }

        .no-recipient {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0.004em;
          color: colors.$rose-800;
        }
      }

      .delivery-schedule {
        .delivery-schedule-option {
          border: 1px solid colors.$blue-gray-100;
          margin-top: 12px;
          border-radius: 10px;
          padding: 14px 16px;

          &.invalid {
            border-color: var(--form-element-error);
          }

          .radio {
            display: flex;
            align-items: center;

            label {
              font-weight: 400;
            }
          }

          p {
            color: colors.$blue-gray-500;
            margin: unset;
            margin-top: 4px;
            font-size: 12px;
            padding-left: 36px;
          }
        }

        .date-time-picker {
          display: flex;
          flex-direction: column;
          padding-left: 28px;
          margin-top: 6px;

          @include viewport.desktop {
            flex-direction: row;
            > div {
              flex: 1;
              margin-left: 8px;
            }
          }

          > div {
            margin-bottom: 6px;
          }

          .time-input {
            .input-box {
              border-radius: 8px;
            }

            .options-wrapper {
              .options {
                top: unset;
                bottom: 48px;
              }
            }
          }

          .DayPicker {
            bottom: 45px;
            left: 0;
          }
        }

        .error-message {
          font-size: 12px;
          font-weight: 300;
          line-height: 18px;
          letter-spacing: 0.004em;
          padding-top: 6px;
          color: var(--form-element-error);
        }
      }
    }
  }
}
