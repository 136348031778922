@use '../typography' as typography;
@use '../scrollbar' as scrollbar;
@use '../card' as card;
@use '../buttons' as buttons;
@use '../themes/default' as default-theme;
@use '../colors' as colors;
@use "../viewport" as viewport;

.print-station-edit-widget {
  width: auto;

  @include viewport.mobile {
    width: unset;
  }

  .header {
    display: grid;
    grid-template-columns: max-content 1fr min-content;
    grid-template-areas: 'title . close-button';
    padding: 20px;
    .title {
      @include typography.h1;
      grid-area: title;
    }
    .close-button {
      grid-area: close-button;
      cursor: pointer;
    }
  }

  .form-item {
    padding: 0 20px;
    padding-bottom: 0;
  }

  .inline-title {
    padding: unset;
    label {
      font-weight: 500;
    }
  }

  .cloud-printing-settings,
  .lan-printing-settings,
  .usb-printing-settings,
  .print-order-settings {
    display: flex;
    flex-flow: column;
    grid-gap: 20px;
  }

  .include-unstationed-items,
  .include-orphaned-items {
    @include typography.p;
    padding: 0 20px;
    cursor: pointer;
    label {
      color: #{default-theme.$fg};
    }
    .sub-text {
      padding-left: 30px;
      font-size: 12px;
      color: #{default-theme.$message-info-text};
    }
  }

  input[type='checkbox'] {
    display: none;
    cursor: pointer;
    + label {
      position: relative;
      margin-left: 30px;
      cursor: pointer;
      &::before {
        content: '';
        cursor: pointer;
        position: absolute;
        top: -1px;
        left: -28px;
        border: 1px solid #{default-theme.$button-action};
        width: 16px;
        height: 16px;
      }
    }
    &:checked {
      + label::before {
        border: 1px solid #{default-theme.$message-success-fg};
        background-image: url('../images/icon-check-teal.svg');
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    &::placeholder {
      color: #999999;
    }
  }

  .form-buttons {
    @include card.footer;
    button {
      padding: 9.5px 32px;
      display: inline;
      grid-gap: 20px;
      margin-right: 8px;
    }
    .cancel-btn {
      @include buttons.secondary;
    }
  }

  .help-text {
    @include typography.caption;
    color: colors.$blue-gray-700;
    display: flex;
    align-items: center;
    padding: 0 20px;

    .info-icon {
      display: flex;
      margin-right: 5px;

      img {
        width: 14.4px;
        height: 14.4px;
      }
    }
  }
}
