@use "constants"as constants;
@use "typography"as typography;

.alert {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-areas: 'icon message';
  align-items: center;

  &.has-title {
    grid-template-areas:
      'icon title'
      '.    message';
  }

  padding: 10px 15px;
  border-radius: constants.$border-radius;
  box-shadow: 0px 0px 3px rgba(104, 122, 155, 0.25);

  .icon {
    font-size: 20px;
    grid-area: icon;
    display: flex;
    flex-direction: column;
    justify-self: center;
    i {
      margin-right: 10px;
      font-size: 25px;
    }
  }

  .title {
    @include typography.h3;
    grid-area: title;
    align-self: center;
  }

  .message {
    font-weight: 300;
    font-size: 0.9rem;
    grid-area: message;
    align-self: center;
  }

  &.success {
    background-color: var(--message-success-bg);

    .icon,
    .title,
    .message {
      color: var(--message-success-text);
    }
  }

  &.info {
    background-color: var(--message-info-bg);
    color: var(--message-info-text);

    .icon,
    .title,
    .message {
      color: var(--message-info-text);
    }
  }

  &.error {
    background-color: var(--message-error-bg);
    color: var(--message-error-text);

    .icon,
    .title,
    .message {
      color: var(--message-error-text);
    }
  }

  &.warning {
    background-color: var(--message-warning-bg);
    color: var(--message-warning-text);

    .icon,
    .title,
    .message {
      color: var(--message-warning-text);
    }
  }
}
