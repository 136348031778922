.status-indicator {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;
  border-radius: 50%;

  i {
    font-size: 14px;
    color: white;
  }

  &.primary {
    background-color: #50b5ff;
  }
  &.success {
    background-color: #3dd598;
  }
  &.failure {
    background-color: #fc5a5a;
  }
  &.warning {
    background-color: #ff974a;
  }
  &.completed {
    background-color: #3dd598;
  }
}
