@use "../card"as card;
@use "../constants"as constants;
@use "../scrollbar"as scrollbar;
@use "../typography"as typography;
@use "../viewport"as viewport;

.restaurant-website {
  header {
    align-items: center;
    background-color: white;
    box-shadow: 0px 0px 3px rgba(104, 122, 155, 0.25);
    padding: 0;
    padding-top: var(--safe-area-inset-top);
    height: constants.$header-height;
    z-index: 1;
  }

  header .wrapper {
    width: 100vw;
    @include viewport.desktop {
      max-width: constants.$max-header-width;
      margin: 0 auto;
    }

    display: grid;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    height: constants.$header-height;
  }
}
