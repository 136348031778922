@use "viewport"as viewport;

.form-item.key-value-pair {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    'a b'
    'c c';

  label {
    grid-area: a;
    align-self: flex-start;
  }

  .form-input {
    grid-area: b;
    justify-self: end;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    span {
      // font-weight: 400;
      text-align: right;
    }
    .icon {
      font-size: 16px;
      padding-left: 5px;
      &.warning {
        color: yellow;
      }
      &.failure {
        color: red;
      }
      &.success {
        color: green;
      }
    }
  }

  .meta {
    grid-area: c !important;
    margin-top: 5px;
  }
}

// .form-key-value-pair {
//   display: inline-block;
//   cursor: pointer;
//   -webkit-tap-highlight-color: transparent;
// }

// /**
//  * A normal switch will render as a form item, where the meta is displayed below the form-value (which is the switch)
//  * In an inline switch, we change the layout to render the meta below the label itself.
//  */

// @include viewport.mobile {

// }
