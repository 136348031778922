@use '../switch';
@use '../colors';
@use '../viewport';
@use '../common/gap';

.anz-worldline-integration-widget {
  padding-bottom: 40px;

  @include viewport.mobile {
    > .header {
      grid-template-areas:
        'logo        title'
        'logo        status'
        'description description';
      grid-template-columns: min-content 1fr;
      align-items: center;
    }
  }

  > .header {
    margin: 24px 0;
    display: grid;
    grid-template-areas:
      'logo title       status'
      'logo description description';

    grid-template-columns: min-content 1fr max-content;
    gap: 8px 24px;

    > .logo {
      grid-area: logo;
      height: 80px;
    }

    > .title {
      grid-area: title;
      font-size: 34px;
      font-weight: 600;
      line-height: 40.8px;
    }

    > .sub-title {
      grid-area: sub-title;
    }

    > .status {
      grid-area: status;
      color: colors.$blue-gray-700;

      &.enabled {
        color: colors.$green-600;
      }
    }
  }

  > .moto-transactions {
    display: grid;
    grid-template-areas:
      'title       switch'
      'description .     ';
    gap: 8px;
    background-color: colors.$white;
    padding: 24px;
    border-radius: 10px;
    border: 1px solid colors.$blue-gray-100;
    margin-top: 32px;

    > .title {
      grid-area: title;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }

    > .description {
      grid-area: description;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: colors.$blue-gray-700;
    }

    > .switch {
      grid-area: switch;
      justify-self: self-end;
      @include switch.form-switch;
    }
  }

  > .delete-integration-button {
    margin-left: 16px;
    margin-top: 42px;
    cursor: pointer;

    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: colors.$rose-800;
  }

  .enable-moto-transactions-alert-widget {
    max-width: 573px !important;

    > .actions {
      display: flex !important;
      flex-direction: row-reverse;

      > .proceed-button {
        background-color: colors.$blue-600 !important;
      }
    }

    @include viewport.mobile {
      width: 100%;

      > .title {
        font-size: 18px;
      }

      > .actions {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .delete-anz-worldline-integration-alert-widget {
    max-width: 573px !important;

    > .content {
      text-align: left;

      ul {
        margin: 0;
      }
    }

    > .actions {
      display: flex !important;
      flex-direction: row-reverse;
    }

    @include viewport.mobile {
      width: 100%;

      > .title {
        font-size: 18px;
      }

      > .actions {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
