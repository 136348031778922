@use "../card";
@use "../colors";
@use "../switch";
@use "../constants";
@use "../form";
@use "../buttons";
@use "./base-item-edit-widget" as base-item;

$padding-horizontal: 15px;

.deal-edit-widget {
  @include card.container;

  form {
    @include card.content;
    margin-top: 20px;

    .allergen-info {
      @include base-item.allergen-info;
    }
  }

  .deal-pizzas-form-item > label,
  .deal-items-form-item > label {
    @include form.top-align-label;
  }

  .deal-items,
  .deal-pizzas {
    .list {
      display: grid;
      grid-gap: 20px;
      margin-bottom: 20px;
    }

    .item {
      @include card.container;
      @include card.stack;

      .content {
        @include card.upper;
      }
      .actions {
        @include card.lower;
        @include card.round-buttons;
        .action {
          @include card.round-button;
        }
      }

      .info {
        display: grid;
        grid-template-columns: 1fr 100px;
        grid-gap: 20px;
        @include card.header;
        label,
        .meta {
          font-weight: normal;
        }
      }
    }

    .actions {
      button {
        @include buttons.primary-transparent;
      }
    }
  }

  .pizza-list {
    .head {
      @include card.header;
      background-color: #f9f9f9;
    }
    .body {
      @include card.content;
      display: grid;
      grid-gap: 20px;
    }
  }

  // pizzas table
  .pizza-list .head,
  .pizza {
    display: grid;
    grid-template-columns: min-content 1fr 100px;
    align-items: center;
    grid-gap: 15px;
  }

  /**
   * Deal Items
   */
  .deal-items {
    .form-item.modifiers > label {
      display: block;
      margin: 0;
      @include card.header;
      background-color: #f9f9f9;
    }

    .modifiers {
      .form-input,
      .meta {
        // margin: 15px;
      }
    }

    .modifier,
    .modifier-info {
      box-shadow: none !important;
      border-radius: 0 !important;
    }
    .modifier {
      margin-bottom: 0px !important;
    }

    .modifier-list-actions {
      margin: 15px;
      button {
        @include buttons.primary-transparent;
      }
    }

    // hide the error, at modifiers level, it's shown at the items anyways
    .form-item.modifiers > .meta {
      display: none !important;
    }
  }
}

.swal2-container {
  .deal-spec-edit-widget {
    max-width: 364px;
    padding: 32px 24px;
    border-radius: 20px;

    .swal2-title {
      color: colors.$blue-gray-900;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }

    .swal2-content {
      padding: 0;
      margin-top: 16px;

      color: colors.$blue-gray-500;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }

    .swal2-actions {
      margin-top: 24px;
      flex-direction: column;
      gap: 16px;
      padding: 0 24px;

      button {
        border-radius: 4px;
        padding: 14px 32px;
        margin: 0;

        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }

      .confirm-button {
        width: 100%;
        background-color: colors.$blue-600;
        color: colors.$white;
      }

      .cancel-button {
        width: 100%;
        background-color: colors.$white !important;
        color: colors.$blue-gray-700;
        border: 1px solid colors.$blue-gray-500;
      }
    }
  }
}
