@use '../card' as card;
@use '../viewport' as viewport;
@use '../typography' as typography;

.pos-trading-hours-edit-widget {
  @include card.container;
  padding: 20px;
  overflow: auto;

  .weekly-hours {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .daily-hours {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .label {
      @include typography.h3;
    }
  }

  .start-time {
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }

  .time-period {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;

    .meta {
      margin: 0;
      margin-top: 10px;
    }
  }

  .action {
    @include card.round-button;
    justify-items: center;
    width: min-content;
  }
}
