@use "../typography.scss";
@use "../colors";
@use "../elements";
@use "../constants";

%button {
  display: flex;
  align-items: center;
  padding: 14px 16px;
  cursor: pointer;
}

.popout-table-booking-history-list-item-more-actions-widget {
  display: flex;
  flex-direction: column;
  padding: 0;
  @include typography.h3;
  font-weight: 500;
  line-height: 24px;
  white-space: nowrap;
  position: absolute;

  > .view-booking-details-button {
    @extend %button;
    border-bottom: 1px solid colors.$blue-gray-100;
    padding: 12px 16px;
    margin-top: 26px;
    box-shadow: 0px 0px 6px 0px #687a9b80;
    border-radius: constants.$border-radius;

    > .icon {
      @include elements.icon($size: 20px, $iconSize: 18px);
      margin-right: 8px;
    }
  }
}
