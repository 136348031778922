@use '../colors';
@use '../viewport';

.uber-eats-preview-menu-widget {
  > .header {
    justify-content: space-between;

    font-size: 14px;
    color: colors.$blue-gray-700;

    @include viewport.mobile {
      display: unset;
    }
  }

  > .content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .link {
      padding: 9.5px 16px;
      cursor: pointer;
      text-decoration: underline;
      color: colors.$blue-600;
      font-weight: 500;

      .icon {
        display: none;
      }
    }

    @include viewport.mobile {
      display: grid;
      gap: 17.5px;
      .link {
        padding: unset;
      }
    }
  }
}
