@use "../scss/colors" as colors;

.radio {
  background-color: initial;
  appearance: auto;
  box-sizing: border-box;
  margin: 3px 3px 0px 5px;
  padding: initial;
  border: initial;

  .radio-label {
    cursor: pointer;
    display: flex;
    font-size: 13px;
    letter-spacing: 0.1px;
  }

  .radio-input {
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #{colors.$blue-gray-400};
    border-radius: 50%;
    background-clip: content-box;
    padding: 3px;
    margin-right: 8px;

    &:checked {
      background-color: #{colors.$green-500};
      border-color: #{colors.$green-500};
    }
  }
}
