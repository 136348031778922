@use '../switch';

.uber-eats-pos-order-integration-settings-widget {
  > .header {
    > .switch {
      @include switch.form-switch;
      margin-left: 24px;
    }
  }
}
