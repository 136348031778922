@use '../themes/default' as default-theme;

.basic-item-edit-widget,
.custom-item-edit-widget,
.half-and-half-edit-widget,
.deal-edit-widget,
.pizza-edit-widget {
  .invalid-print-station {
    color: #{default-theme.$form-element-error};
    font-style: italic;
    border-color: #{default-theme.$form-element-error};
  }
}
