@use "../card";
@use "../colors";

.manage-item-routing-widget {
  padding: 8px;

  .store-coverage-selector {
    margin: 6px 0 20px 0;
    max-width: 400px;
  }

  .overlay {
    > .options-wrapper {
      .options {
        min-width: 355px;
        overflow: visible;
        margin-top: -16px;

        .icon {
          display: none;
        }

        & .selected {
          background-image: url('../images/icon-check-teal.svg');
          background-repeat: no-repeat;
          background-position: right 50%;
        }
      }
    }
  }

  .manage-item-routing-form {
    @include card.container;
    overflow: hidden;

    .form-items {
      grid-row-gap: 0;

      .inline-title {
        label {
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          letter-spacing: 0.1px;
        }
      }
    }

    .items {
      margin-inline: 48px;
      margin-bottom: 10px;

      .item {
        padding-block: 10px;
        border-bottom: 1px solid #f0f0f0;

        .label {
          font-weight: 300;
          font-size: 13px;
          line-height: 20px;
          letter-spacing: 0.1px;
        }

        .option {
          background: colors.$white;
          border: 2px solid colors.$med-gray;
          color: #999999;

          &.selected {
            background: colors.$blue-50;
            border: 2px solid colors.$blue-300;
            color: colors.$blue;
          }
        }
      }
    }
  }
}
