@use "../card";
@use "../colors";
@use "../constants";
@use "../form";
@use "../buttons";
@use "../viewport";

$padding-horizontal: 15px;

.pizza-settings-edit-widget {
  @include card.container;

  form {
    @include card.content;
    margin-top: 20px;
  }

  .form-input {
    .topping,
    .pizza-size {
      @include card.container;
      @include card.stack;
      margin-bottom: 20px;

      .info {
        @include card.upper;
        @include form.inline;
      }
      .actions {
        @include card.lower;
        @include card.round-buttons;
        .action {
          @include card.round-button;
        }
      }
    }

    .topping {
      .info {
        @include viewport.desktop {
          grid-auto-flow: column;
        }
      }
    }

    .toppings,
    .pizza-sizes {
      button {
        @include buttons.primary-transparent;
      }
    }
  }

  .pizza-sizes-form-item > label,
  .toppings-list-form-item > label {
    @include form.top-align-label();
  }
}

.swal2-container {
  .pizza-settings-edit-widget {
    max-width: 364px;
    padding: 32px 24px;
    border-radius: 20px;

    .swal2-title {
      color: colors.$blue-gray-900;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }

    .swal2-content {
      padding: 0;
      margin-top: 16px;

      color: colors.$blue-gray-500;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }

    .swal2-actions {
      margin-top: 24px;
      flex-direction: column;
      gap: 16px;
      padding: 0 24px;

      button {
        border-radius: 4px;
        padding: 14px 32px;
        margin: 0;

        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }

      .confirm-button {
        width: 100%;
        background-color: colors.$blue-600;
        color: colors.$white;
      }

      .cancel-button {
        width: 100%;
        background-color: colors.$white !important;
        color: colors.$blue-gray-700;
        border: 1px solid colors.$blue-gray-500;
      }
    }
  }
}
