@use '../card'as card;
@use '../buttons' as buttons;

.ReactModalPortal {
  .ReactModal__Overlay {
    .ReactModal__Content {
      top: unset !important;
      border: none !important;
      background: none !important;
      border-radius: none !important;
    }
  }
}

.android-install-prompt {
  @include card.container;
  z-index: 9999;
  position: absolute;
  bottom: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
  padding: 32px 20px;
  width: 80vw !important;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-items: center;


  .logo {
    display: grid;
    align-items: center;
    justify-items: center;
    grid-area: logo;
    margin-bottom: 20px;

    img {
      width: 64px;
    }
  }

  .message {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    /* or 26px */

    text-align: center;
  }

  .subtitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.1px;
  }
  
  .actions {
    margin-top: 20px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    gap: 20px;

    .install-btn {
      @include buttons.primary;
    }

    .dismiss-btn {
      @include buttons.primary-transparent;
      border: none;
    }
  }
}