@use '../typography' as typography;
@use '../card' as card;

.pos-custom-payments-settings-edit-widget {
  @include card.container();

  .title {
    @include card.header();
    @include typography.h2;
  }

  form {
    .form-items {
      @include card.content();
    }

    .form-buttons {
      @include card.footer();
      margin-top: 0;
    }
  }
}
