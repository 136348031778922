@use "form"as form;
@use "card"as card;
@use "viewport"as viewport;
@use "modal"as modal;

.form-single-select-overlay {
  .options {
    @include card.container();
    @include card.content();
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 25px;
    display: grid;
    grid-gap: 18px;
    font-weight: 400;
    @include modal.container();
    cursor: pointer;
  }

  @include viewport.mobile {
    .overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 998;
    }
  }

  @include viewport.tablet {
    .options-wrapper {
      position: relative;
    }
    .options {
      position: absolute;
      top: 100%;
      z-index: 999;
    }
  }

  .icon {
    margin-right: 12px;
    font-size: 30px;
    color: #777777;
  }

  .selected {
    .icon {
      color: var(--message-success-text);
    }
  }

  .option {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
