@use '../card' as card;
@use '../constants' as constants;
@use '../viewport' as viewport;

.qr-code-widget {
  display: grid;
  justify-content: center;

  label {
    height: 100%;
  }
  .qr-code {
    border: 1px solid var(--form-element-border);
    border-radius: 10px;
    height: 100px;
    width: 100px;
  }
}

@include viewport.mobile {
  .qr-code-widget {
    display: flex;
    flex-direction: column;
  }
}
