$border-radius: 10px;
$horizontal-gap: 20px;
$header-height: 53px;
$line: 1px solid #f1f1f5;
$line-dashed: 1px dashed #e9ecf3;
$curve-padding: 6px;
$border-radius-small: 4px;

$max-body-width: 900px;
$max-header-width: $max-body-width + (2 * $horizontal-gap);

:root {
  // https://stackoverflow.com/a/54101084
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;

  @supports (top: constant(safe-area-inset-top)) {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }

  @supports (top: env(safe-area-inset-top)) {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}
