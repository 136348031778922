@use "../colors";
@use '../card';
@use '../elements';
@use '../pill';
@use '../gap';

.uber-eats-order-view-widget {
  @include card.container();
  @include gap.vertical(16px);
  padding: 20px;

  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  > *:not(:first-child) {
    display: flex;
    justify-content: space-between;
    padding-top: 16px;
    border-top: 1px dashed colors.$blue-gray-100;

    > .label {
      display: flex;
      align-items: center;

      > .icon {
        @include elements.icon(20px);
        margin-right: 12px;
      }
    }
  }

  > .items {
    @include gap.vertical(16px);

    > .label {
      display: flex;
      align-items: center;

      font-weight: 500;
      font-size: 14px;
      line-height: 21px;

      > .icon {
        @include elements.icon(24px);
        margin-right: 12px;
      }
    }

    > .item-summary-view-widget {
      &:not(:last-child) {
        padding-bottom: 16px;
        border-bottom: 1px dashed colors.$blue-gray-100;
      }
    }
  }

  > .delivery-address {
    > .value {
      color: colors.$blue-gray-500;
    }
  }

  > .payment-type {
    > .value {
      display: flex;
      align-items: center;
      color: colors.$blue-gray-500;

      > .icon {
        @include elements.icon(20px);
        margin-right: 8px;
      }
    }
  }

  > .customer {
    > .value {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  > .comments {
    flex-direction: column;

    > .value {
      color: colors.$blue-gray-500;
      margin-left: 32px;
    }
  }

  > .amounts {
    @include gap.vertical(16px);
    flex-direction: column;
    color: colors.$blue-gray-500;
    font-weight: 500;

    > * {
      display: flex;
      justify-content: space-between;
    }
  }

  > .grand-total {
    color: colors.$blue-600;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  > .gst-amount {
    color: colors.$blue-gray-500;
    border-top: none;
    padding-top: 12px;
  }
}
