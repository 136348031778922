@use '../colors';

.split-bill-item-summary-view-widget {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    'name         price   '
    'description  .       '
    'comments     comments';
  column-gap: 4px;
  color: colors.$blue-gray-500;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  > .name {
    grid-area: name;
    color: colors.$blue-gray-900;
    font-weight: 500;
  }

  > .price {
    grid-area: price;
    font-weight: 500;
  }

  > .comments {
    grid-area: comments;
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
  }

  > .item-description-view-widget {
    grid-area: description;
    margin-top: 8px;
  }
}
