@use "../colors";
@use "../card";
@use "../constants";
@use "./add-ons-widget";

.swal2-container.unconnect-store-confirmation {
  > .swal2-modal {
    padding: 30px 20px;
    width: 375px;
    border-radius: 20px;

    > .swal2-header > .swal2-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
    }

    > .swal2-content {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding: 0;
    }

    > .swal2-actions {
      flex-flow: column;
      padding: 0 25px;

      > button {
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }

      > .swal2-confirm {
        background-color: colors.$rose-800;
      }

      > .swal2-cancel {
        border: 1px solid colors.$blue-gray-500;
        color: colors.$blue-gray-700;
        background-color: white !important;
      }
    }
  }
}

.connected-stores-settings-widget {
  @include add-ons-widget.header();

  .restaurant-and-store-connector,
  .connected-stores {
    @include card.container;
    padding: 24px;

    > .header {
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
    }
  }

  .restaurant-and-store-connector {
    margin-bottom: 35px;

    .store-selection-container {
      display: grid;
      grid-template-columns: 1fr max-content;
      grid-template-areas:
        'header                        connect-store-button'
        'sub-header                    connect-store-button'
        'select-restaurant-dropdown    connect-store-button'
        'select-store-dropdown         connect-store-button';
      gap: 0 60px;
      border-radius: constants.$border-radius;
      background-color: colors.$light-gray-50;
      margin-top: 24px;
      padding: 20px;

      > .header {
        grid-area: header;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        > span {
          color: colors.$blue-800;
        }
      }

      > .sub-header {
        grid-area: sub-header;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        margin-top: 20px;
        margin-bottom: 15px;
      }

      > .dropdown {
        display: grid;

        > button {
          display: grid;
          grid-auto-flow: column;
          grid-template-columns: 1fr max-content;
          padding: 10px;
          border-radius: 8px;
          font-size: 14px;

          > .icon {
            font-size: 12px;
            margin: 0;
          }

          > .label {
            grid-column: 1;
            text-align: left;
          }
        }
        > .overlay > .options-wrapper > .options {
          width: -webkit-fill-available;
          font-size: 14px;

          > .search-box {
            input {
              font-size: 14px;
            }
          }

          > .list {
            max-height: 310px;
            overflow: scroll;

            .option:hover {
              background-color: colors.$light-gray-100;
            }
          }
        }
      }

      .select-restaurant-dropdown {
        grid-area: select-restaurant-dropdown;
      }

      .select-store-dropdown {
        grid-area: select-store-dropdown;
        margin-top: 20px;
      }

      > button {
        grid-area: connect-store-button;
        align-self: self-end;
      }
    }
  }

  .connected-stores {
    .connected-stores-container {
      margin-top: 24px;
      display: grid;
      grid-gap: 10px;

      .connected-store-list-item-widget {
        border-radius: constants.$border-radius;
        background-color: colors.$light-gray-50;
        padding: 20px;
        display: grid;
        grid-template-columns: min-content 1fr min-content;
        grid-gap: 0 10px;

        > i {
          font-size: 18px;
          color: colors.$blue-gray-400;
        }

        .store-name {
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
        }

        .unconnect-button {
          cursor: pointer;
          color: colors.$rose-800;
          text-decoration: underline;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
        }
      }
    }
  }

  .no-stores {
    color: colors.$blue-gray-500;
    font-size: 16px;
  }
}
