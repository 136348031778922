@use "../constants";
@use "../card";
@use "../typography";
@use "../buttons";
@use "../colors";

.pos-employee-time-clock-settings-edit-widget {
  @include card.container();

  .title {
    @include card.header();
    @include card.header-font();
    @include typography.h2;
    margin-bottom: 15px;
  }

  form {
    @include card.content;
    padding: 0;

    .form-item,
    .empty-message {
      padding: 0 20px;
    }

    .form-items {
      .form-item > label {
        padding-bottom: 6px;
      }
    }
  }

  .add-work-break-button {
    @include buttons.primary-transparent;
    width: fit-content;
    margin: 0 20px;
  }

  .form-buttons {
    @include card.footer;
  }
}
