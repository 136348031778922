@use "../card"as card;
@use "../constants"as constants;
@use "../form"as form;
@use "../buttons"as buttons;

.coupon-edit-widget {
  @include card.container;

  form {
    @include card.content;
    margin-top: 20px;
  }

  .form-address {
    > .form-input {
      @include card.container;
      @include card.content;
      @include form.inline;
    }
    > label {
      @include form.top-align-label();
    }
  }
}
