@use "../card"as card;
@use "../constants"as constants;
@use "../scrollbar"as scrollbar;
@use "../typography"as typography;
@use "../viewport"as viewport;

$icon-size: 21px;

@mixin icon {
  display: inline-block;
  width: $icon-size;
  height: $icon-size;
  background-repeat: no-repeat;
  background-size: contain;
}

.backoffice {
  display: grid;
  width: 100vw;
  height: 100vh;
  grid-template-areas:
    'header header'
    'nav main';
  grid-template-rows: min-content 1fr;
  grid-template-columns: min-content 1fr;

  header {
    grid-area: header;
    display: grid;
    width: 100vw;

    @include viewport.mobile {
      grid-template-columns: 40px 1fr 40px;
    }

    @include viewport.tablet {
      grid-template-columns: 110px 1fr 110px;
    }

    overflow: hidden;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    box-shadow: 0px 0px 3px rgba(104, 122, 155, 0.25);
    padding: 0;
    height: constants.$header-height;
    z-index: 1;

    .logo {
      margin-left: constants.$horizontal-gap;

      img {
        display: block;
        width: 100%;
      }

      &.full {
        @include viewport.mobile {
          display: none;
        }
      }

      &.icon {
        @include viewport.tablet {
          display: none;
        }

        font-size: 20px;
        color: #92929d;
        justify-self: start;
        align-self: center;
        cursor: pointer;
      }
    }

    .title {
      text-align: center;
      @include typography.h2;
    }

    .initials {
      justify-self: end;
      background-color: #ffc542;
      border-radius: constants.$border-radius;
      border: 3px solid #f1f1f5;
      margin-right: constants.$horizontal-gap;
      padding: 3px 4px;
      font-weight: 500;
      color: white;
    }

    .userinfo {
      display: none;
    }

    .restaurant {
      display: flex;
      flex-direction: horizontal;
      justify-content: center;
      align-items: center;

      .image {
        width: constants.$header-height - 20;
        border-radius: constants.$border-radius;
        border: 3px solid #f1f1f5;
        margin-right: 10px;
        overflow: hidden;
      }

      .name {
        @include typography.h3;
      }

      .description,
      .timezone,
      .country {
        display: none;
      }
    }
  }

  nav {
    grid-area: nav;
    display: flex;
    overflow-y: scroll;
    background-color: white;
    box-shadow: 0px 0px 3px rgba(104, 122, 155, 0.25);
    flex-direction: column;
    z-index: 999;

    @include scrollbar.hide;

    @include viewport.mobile {
      position: fixed;
      top: constants.$header-height;
      left: 0;
      transform: translateX(-105%);
      transition: transform 300ms;
      height: calc(100vh - #{constants.$header-height});

      &.show {
        transform: none;
      }
    }

    .title {
      text-transform: uppercase;
      color: #92929d;
      font-size: 11.5px;
      font-weight: 400;
      letter-spacing: 1px;
      margin-top: 20px;
      margin-bottom: 5px;
      margin-left: constants.$horizontal-gap;
    }

    .link {
      $bleed-width: 4px;
      display: flex;
      flex-direction: row;
      margin: 5px 0;
      padding: 8px 40px;
      padding-left: constants.$horizontal-gap - $bleed-width;
      border-left: $bleed-width solid var(--card-bg);
      align-items: center;
      cursor: pointer;

      &.selected {
        .icon.kds {
          @include icon;
          background-image: url('../images/icon-kds-blue.svg');
        }
      }

      .icon {
        color: #92929d;
        font-size: $icon-size;
        margin-right: 16px;

        &.kds {
          @include icon;
          background-image: url('../images/icon-kds-grey.svg');
        }
      }

      .label {
        @include typography.h4;
        display: inline-block;
        white-space: nowrap;
      }

      &.selected {
        border-left: $bleed-width solid var(--color-primary);

        .icon,
        .label {
          color: var(--color-primary);
        }
      }
    }
  }

  main {
    grid-area: main;
    padding: constants.$horizontal-gap;
    overflow-y: scroll;
  }
}