@use "../themes/default" as default-theme;
@use "../typography" as typography;

.new-orders-widget {
  .no-orders {
    text-align: center;
  }

  .headers {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  .tab-categories {
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
    margin-bottom: 20px;

    .tab-category {
      list-style: none;
      text-align: center;
      cursor: pointer;
      padding: 10px 16px;
      @include typography.h4;
      border-radius: 20px;
      border: 1px solid #{default-theme.$link-fg};
      background-color: #{default-theme.$form-element-bg};
      color: #{default-theme.$link-fg};
    }

    .tab-category.active {
      background-color: #{default-theme.$link-fg};
      color: #{default-theme.$form-element-bg};
    }
  }

  .tab-status {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    .title {
      list-style: none;
      text-align: left;
      padding: 10px;
      @include typography.h4;
      height: fit-content;
    }
    
    .tab-status-all,
    .tab-status-new,
    .tab-status-incomplete {
      list-style: none;
      text-align: left;
      cursor: pointer;
      padding: 10px;
      @include typography.h4;
      height: fit-content;
    }

    .tab-status-all.active,
    .tab-status-new.active,
    .tab-status-incomplete.active {
      background-color: #{default-theme.$bg};
      color: #{default-theme.$link-fg};
      border-bottom: 2px solid #{default-theme.$link-fg};
    }
  }

  .load-more-button {
    display: flex;
    justify-content: center;
    margin-top: 16px;

    button {
      border: 1px solid #{default-theme.$link-fg};
      background-color: #{default-theme.$form-element-bg};
      color: #{default-theme.$link-fg};
    }
  }
}
