@use "../card"as card;
@use "../constants"as constants;
@use "../form"as form;
@use "../buttons"as buttons;
@use "../switch"as switch;

$padding-horizontal: 15px;

.form-input {
  .modifier-list {
    .modifier {
      @include card.container;
      @include card.stack;
      margin-bottom: 20px;

      .modifier-info {
        @include card.upper;

        .header {
          display: grid;
          grid-template-columns: 1fr 60px 60px;
          grid-column-gap: 10px;
          padding: $padding-horizontal;
        }
        > .meta {
          margin: 0;
          padding: 0 $padding-horizontal $padding-horizontal;
          border-bottom: constants.$line;
        }

        > .badges {
          display: flex;
          flex-direction: row;
          margin-left: $padding-horizontal;
          margin-bottom: 12px;

          .badge {
            margin-right: 8px;
            display: inline;
          }
        }
        .allow-multiple {
          padding: 15px 0;
          .switch {
            padding: 0px $padding-horizontal;
            @include switch.inline();
          }
          border-bottom: constants.$line;
        }
      }

      .modifier-actions {
        @include card.lower;
        @include card.round-buttons;
        .action {
          @include card.round-button;
        }
      }
    }
  }

  .modifier-options {
    .head {
      // @include card.header;
      // background-color: #f9f9f9;
      // margin-bottom: 15px;
      padding: $padding-horizontal $padding-horizontal 4px $padding-horizontal;
      font-size: 13px;
    }
    > .meta {
      margin: 0;
      padding: 0 $padding-horizontal $padding-horizontal;
    }
  }
  .modifier-option {
    padding: 0px $padding-horizontal;
    display: grid;
    grid-template-columns: 1fr 120px min-content;
    grid-column-gap: 10px;
    margin-bottom: 15px;
    align-items: center;

    .actions {
      display: grid;
      grid-template-columns: repeat(3, min-content);
    }

    .action {
      @include card.round-button;
      font-size: 25px;
    }
  }

  .modifier-list-actions {
    button {
      @include buttons.primary-transparent;
    }
  }
}

.form-item.modifiers > label {
  @include form.top-align-label();
}
