@use '../typography';
@use '../viewport';

.manage-add-ons-page {
  .body {
    .manage-add-ons-widget > .header {
      font-size: 12px;
      font-weight: 500;
      margin: 20px 0;
    }
  }

  @include viewport.tablet {
    .body {
      .manage-add-ons-widget > .header {
        font-size: 24px;
        font-weight: 600;
      }
    }
  }
}
