@use '../card' as card;

.pos-terminal-settings-edit-widget {
  width: min(1000px, 80vw);

  .title {
    @include card.header();
  }

  form {
    .form-items {
      grid-row-gap: 0;
  
      .inline-title {
        label {
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          letter-spacing: 0.1px;
        }
      }

      .switch {
        padding: 8px 18px;

        label {
          font-weight: 300;
          font-size: 13px;
          line-height: 20px;
          letter-spacing: 0.1px;
        }
      }
    }

    .items {
      margin-bottom: 10px;
      .item {
        padding: 10px 20px;
        border-bottom: 1px solid #F0F0F0;

        .label {
          font-weight: 300;
          font-size: 13px;
          line-height: 20px;
          letter-spacing: 0.1px;
        }

        .option {
          background: #FFFFFF;
          border: 2px solid #E2E2EA;
          color: #999999;
  
          &.selected {
            background: #EFF6FF;
            border: 2px solid #93C5FD;
            color: #0062FF;
          }
        }
      }
    }

    .form-buttons {
      padding: 0 18px 12px;
    }
  }
}