@use "../card"as card;
@use "../constants"as constants;
@use "../typography"as typography;
@use "../utils"as utils;

.domain-list {
  .list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-gap: 15px;
  }
}
