@use "../card"as card;
@use "../viewport"as viewport;

.monthly-order-stats {
  .table-header {
    display: none;
    @include viewport.desktop {
      @include card.container;
      @include card.stack;
      padding: 0 15px;
      margin-bottom: 20px;
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: repeat(7, 1fr);
      grid-template-rows: none;
      align-items: center;

      & p {
        font-weight: 500;
      }
    }
  }
  .stat-container {
    @include card.container;
    @include card.stack;
    padding: 15px;
    margin-bottom: 20px;

    @include viewport.desktop {
      grid-auto-flow: column;
      grid-template-columns: repeat(7, 1fr);
      grid-template-rows: none;
    }
  }

  .stat-container > p > span {
    font-weight: 500;
    @include viewport.desktop {
      display: none;
    }
  }
}
