@use "../card";
@use "../viewport";

.order-transaction-report-widget {
  position: relative;

  .summary {
    @include card.container;
    margin-top: 12px;
    padding: 10px 14px;

    .statistic {
      .title {
        font-weight: 500;

        .value {
          font-weight: 400;
        }
      }
    }
  }

  .orders {
    margin: 20px 0;

    @include viewport.desktop {
      @include card.container;
    }

    h2 {
      padding: 0 15px;
    }

    .orders-table-header {
      display: none;

      @include viewport.desktop {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
        padding: 20px 14px;
        grid-gap: 10px;
        font-weight: 600;
        background-color: #f3f3f3;
        border-bottom: 1px solid #e2e2ea;
      }
    }

    .order {
      display: grid;
      grid-auto-flow: row;
      grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
      padding: 20px 14px;
      margin: 20px 0;
      grid-gap: 10px;

      @include viewport.mobile {
        @include card.container;
      }

      @include viewport.desktop {
        margin: 0;
        padding: 10px 14px;
        border-bottom: 1px solid #e2e2ea;
      }

      @include viewport.desktop {
        grid-auto-flow: column;
      }

      .header {
        margin: 0;
        font-weight: 600;

        @include viewport.desktop {
          display: none;
        }
      }
    }
  }

  .primary {
    position: absolute;
    top: 25px;
    right: 0;

    @include viewport.mobile {
      position: unset;
      margin-left: auto;
      margin-bottom: 8px;
    }
  }
}
