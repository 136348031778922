@use "constants"as constants;
@use "typography"as typography;

.react-tagsinput {
  background-color: #fff;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
}

.react-tagsinput--focused {
  border-color: var(--message-success-text);
}

.react-tagsinput-tag {
  @include typography.p;
  background-color: var(--message-success-bg);
  border-radius: 5px;
  color: var(--message-success-text);
  display: inline-block;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
  color: var(--message-success-text);
}

.react-tagsinput-tag a::before {
  content: ' ×';
}
