@use "../colors";
@use "../viewport";

.manage-add-on-list-widget {
  > .header {
    display: none;
  }

  .add-ons {
    display: grid;
    gap: 12px;
  }

  @include viewport.desktop {
    > .header {
      display: grid;
      grid-template-columns: repeat(2, 1fr) 100px;
      padding: 0 20px;
      color: colors.$blue-gray-500;
      align-items: center;
    }

    .add-ons {
      padding: 12px 0 20px 0;
    }
  }
}
