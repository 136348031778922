@use '../card' as card;
@use '../viewport' as viewport;
@use '../buttons' as buttons;
@use '../colors' as colors;
@use '../constants' as constants;
@use '../elements' as elements;

.table-booking-history-list-item-widget {
  margin: 1rem 0;

  @include card.container();
  display: grid;
  padding: 1rem;
  grid-template-columns: min-content 1fr min-content;
  gap: 1rem;

  .details {
    display: grid;
    grid-template-rows: min-content;
    gap: 0.8rem;

    .booked-for {
      font-size: 16px;
      font-weight: 500;
    }
    .customer {
      span {
        display: block;
      }
    }

    .seating-area {
      span {
        background-color: colors.$blue-200;
        padding: 0.2rem 1rem 0.2rem 1rem;
        color: colors.$blue;
        font-weight: 400;
        margin: 0rem 1rem 0.5rem 0rem;
      }
    }

    .special-request {
      border-bottom: 2px solid;
      border-color: colors.$med-gray;
      padding-bottom: 10px;

      span {
        background-color: colors.$blue-200;
        padding: 0.2rem 1rem 0.2rem 1rem;
        color: colors.$blue;
        font-weight: 400;
        // border: 1px
        margin: 0rem 1rem 0.5rem 0rem;
      }

      .note {
        margin-top: 1rem;
      }
    }

    .actions {
      display: grid;
      grid-template-columns: 100px 100px;
      gap: 0.5rem;

      .confirm {
        margin-top: 0.5rem;
        @include buttons.primary-transparent;
        @include buttons.badge-button;
        color: var(--color-primary);
        border: 1px solid var(--color-primary);
      }

      .reject {
        margin-top: 0.5rem;
        @include buttons.primary-transparent;
        @include buttons.badge-button;
        color: var(--button-danger-bg);
        border: 1px solid var(--button-danger-bg);
      }

      .cancel {
        margin-top: 0.5rem;
        @include buttons.primary-transparent;
        @include buttons.badge-button;
        color: var(--button-danger-bg);
        border: 1px solid var(--button-danger-bg);
      }
    }
  }

  > .more-actions-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    background-color: #{colors.$white}1a;
    border-radius: constants.$border-radius-small;
    cursor: pointer;

    > .icon {
      @include elements.icon(24px);
    }
  }
}
