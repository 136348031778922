@use "../card";
@use "../form";
@use "../colors";
@use "../viewport";

.table-booking-settings-unavailable-date-list-widget {
  .unavailable-date {
    @include card.container;
    @include card.stack;
    margin-bottom: 20px;
  }

  .info {
    @include card.upper;
    @include form.inline;
    grid-template-areas:
      'date-message'
      'hours';
    gap: 24px;

    input {
      border-radius: 8px;
      margin-top: 6px;
    }

    .date-message-container {
      grid-area: date-message;

      display: grid;
      gap: 20px;
      grid-template-columns: repeat(2, auto);

      @include viewport.mobile {
        grid-template-columns: auto;
      }

      > .form-item {
        > .form-input {
          > .daypicker-container {
            > .DayPickerInput {
              input {
                background-image: url('../images/icon-calendar.svg');
                background-repeat: no-repeat;
                background-position: 97%;
                padding: 12px 14px;
                margin-right: 60px;
              }
            }
          }

          &.invalid {
            > .daypicker-container {
              > .DayPickerInput {
                input {
                  border: 1px solid colors.$rose-500;
                }
              }
            }
          }

          &.message {
            input {
              padding: 12px 14px;
            }
          }
        }
      }
    }
  }

  .table-booking-settings-unavailable-time-of-day-list-widget {
    grid-area: hours;
  }

  .add-hours-button {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    text-decoration: underline;
    color: colors.$blue-600;
    padding: 7px 12px 7px 4px;
    justify-content: flex-start;
    width: max-content;

    .label {
      display: flex;

      .icon {
        margin-right: 4px;
      }
    }

    &:hover {
      background-color: colors.$blue-50;
    }
  }

  .actions {
    @include card.lower;
    @include card.round-buttons;
    .action {
      @include card.round-button;
    }
  }
}
