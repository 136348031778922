@use "../card"as card;
@use "../constants"as constants;
@use "../scrollbar"as scrollbar;
@use "../typography"as typography;
@use "../viewport"as viewport;

.online-ordering {
  // header {
  //   align-items: center;
  //   background-color: white;
  //   box-shadow: 0px 0px 3px rgba(104, 122, 155, 0.25);
  //   padding: 0;
  //   padding-top: var(--safe-area-inset-top);
  //   height: constants.$header-height;
  //   z-index: 1;
  // }

  header .wrapper {
    width: 100vw;
    @include viewport.desktop {
      max-width: constants.$max-header-width;
      margin: 0 auto;
    }

    display: grid;
    overflow: hidden;
    justify-content: space-between;
    align-items: center;
    height: constants.$header-height;

    @include viewport.mobile {
      grid-template-columns: 50px 1fr 50px;
      grid-template-areas: 'back-button store-name actions';
      .cart,
      .menu {
        display: none !important;
      }
      .userinfo .full {
        display: none;
      }
      .back-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        grid-area: backbutton;
        margin-left: 20px;
        font-size: 18px;
        color: #999999;
        background-color: white;
        border-radius: 10px;
        border: 3px solid #f1f1f5;
        padding: 3px 0px;
        font-weight: 500;
      }
      .login-icon {
        .icon {
          margin-top: 4px;
          font-size: 30px;
          color: var(--fg);
        }
        cursor: pointer;
      }
    }

    @include viewport.tablet {
      grid-template-columns: min-content 1fr min-content;
      grid-template-areas: 'store-name menu actions';
      .back-button {
        display: none;
      }
      .store-header-widget {
        margin-left: 20px;
        white-space: nowrap;
        justify-self: start;
      }
      .userinfo .full {
        display: none;
      }
      .menu {
        justify-self: center;
      }

      .menu {
        display: flex;
        flex-direction: row;
        background-color: white;

        .item,
        .item-with-counter {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          cursor: pointer;
          height: constants.$header-height;
          margin: 0px 16px;

          .label {
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 21px;
            letter-spacing: 0.1px;
            color: #171725;
          }

          &.selected {
            .label {
              color: var(--color-primary);
            }
            .bleed {
              position: absolute;
              bottom: 0px;
              height: 4px;
              width: 120%;
              background-color: var(--color-primary);
              border-top-left-radius: 3px;
              border-top-right-radius: 3px;
            }
          }
          .count {
            position: absolute;
            right: -13px;
            top: 9px;
            background: var(--button-success-bg);
            padding: 1px 5px 0px 6px;
            border-radius: 5px;
            font-size: 10px;
            color: white;
            font-weight: 500;
          }
        }
      }

      .actions {
        display: flex;
        flex-direction: row;
        justify-self: flex-end;
        align-self: center;
        align-items: center;
        margin-right: constants.$horizontal-gap;
      }

      .login-icon {
        .icon {
          margin-top: 5px;
          font-size: 30px;
          color: var(--fg);
        }
        cursor: pointer;
      }
    }

    .back-button {
      grid-area: back-button;
    }

    .actions {
      grid-area: actions;
    }

    .store-header-widget {
      grid-area: store-name;
      cursor: pointer;
    }

    .menu {
      grid-area: menu;
    }

    .logo {
      margin-left: constants.$horizontal-gap;

      img {
        display: block;
        width: 100%;
      }

      &.full {
        @include viewport.mobile {
          display: none;
        }
      }

      &.icon {
        @include viewport.tablet {
          display: none;
        }

        font-size: 20px;
        color: #92929d;
        justify-self: start;
        align-self: center;
        cursor: pointer;
      }
    }

    .userinfo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-right: constants.$horizontal-gap;
    }

    .avatar {
      width: 32px;
      height: 32px;
      margin-right: 2px;
      cursor: pointer;
      img {
        border-radius: constants.$border-radius;
        border: 3px solid #f1f1f5;
        width: 26px;
      }
    }

    .initials {
      justify-self: end;
      background-color: #ffc542;
      border-radius: constants.$border-radius;
      border: 3px solid #f1f1f5;
      padding: 3px 4px;
      font-weight: 500;
      color: white;
      align-self: center;
    }

    .cart {
      position: relative;
      margin-right: 20px;
      cursor: pointer;

      .icon {
        font-size: 30px;
        margin-top: 5px;
      }

      .count {
        grid-area: count;
        position: absolute;
        right: -8px;
        top: 0px;
        background: var(--button-success-bg);
        padding: 1px 5px 0px 6px;
        border-radius: 5px;
        font-size: 10px;
        color: white;
        font-weight: 500;
      }
    }
  }
}
