@use '../card'as card;
@use '../constants'as constants;
@use '../typography'as typography;
@use '../viewport'as viewport;
@use '../buttons'as buttons;

.pos-order-delivery-status-widget {
  .delivery-status {
    margin: 15px 0px;
    font-weight: 500;

    > .icon {
      width: 18px;
    }

    &.in-house {
      display: flex;
      color: var(--message-disabled-text);
      .label {
        margin-left: 10px;
      }
    }

    &.uber {
      display: flex;
      .uber-icon {
        width: 30px;
        margin: 0px 10px;
      }

      .label {
        &.ongoing {
          color: var(--message-info-fg);
        }

        &.failed {
          color: var(--message-error-text);
        }

        &.success {
          color: var(--message-success-text);
        }
      }
    }
  }
}
