@use '../card'as card;
@use '../constants'as constants;
@use '../typography'as typography;
@use '../viewport'as viewport;
@use '../utils'as utils;

.online-ordering {
  .menu-page {
    .categories {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
      grid-gap: 15px;
    }

    .category {
      @include card.container;
      padding: 10px;
      display: grid;
      grid-template-rows: min-content min-content;
      background-color: white;
      cursor: pointer;

      .image {
        img {
          border-radius: constants.$border-radius;
        }
        overflow: hidden;
        margin-bottom: 15px;
      }

      .name {
        @include typography.h2;
        @include utils.curve-padding;
      }

      .description {
        @include typography.h6;
        @include utils.curve-padding;
      }
    }

    .item {
      @include card.container;
      overflow: hidden;

      @include viewport.mobile {
        .body {
          display: grid;
          grid-template-columns: 1fr min-content;
          grid-template-rows: min-content 1fr min-content;
          grid-template-areas:
            'a a'
            'b c'
            'd d';

          .image {
            overflow: hidden;
            grid-area: a;

            img {
              border-radius: constants.$border-radius;
            }
          }

          .name {
            @include typography.h2;
            grid-area: b;
            align-self: flex-start;
          }
          .price {
            grid-area: c;
            @include typography.h2;
            color: var(--color-secondary);
            align-self: flex-end;
          }

          .description {
            @include typography.h6;
            font-weight: 400;
            font-size: 12px;
            grid-area: d;
            align-self: flex-start;
            padding-bottom: 10px;
          }

          .name,
          .price,
          .description,
          .image {
            padding-left: 12px;
            padding-right: 12px;
          }

          .image {
            padding-top: 12px;
          }

          .name,
          .price {
            padding-top: 12px;
          }

          .description {
            padding-bottom: 15px;
          }
        }
      }
      @include viewport.tablet {
        .body {
          // padding: 12px;
          @include card.content;
          display: grid;
          grid-template-columns: 1fr min-content;
          grid-template-rows: min-content 1fr min-content;
          grid-template-areas:
            'a d'
            'b d'
            'c d';

          .image {
            overflow: hidden;
            grid-area: d;
            width: 100px;
            margin-left: 10px;

            img {
              border-radius: constants.$border-radius;
            }
          }

          .name {
            @include typography.h2;
            grid-area: a;
            align-self: flex-start;
          }

          .description {
            @include typography.h6;
            font-weight: 400;
            font-size: 12px;
            grid-area: b;
            align-self: flex-start;
          }

          .price {
            grid-area: c;
            @include typography.h2;
            color: var(--color-secondary);
            align-self: flex-end;
            margin-top: 4px;
          }
        }
      }

      .action {
        grid-area: e;
        @include card.footer;
        padding: 12px 18px;
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          margin-right: 10px;
        }
        span {
          font-family: Fira Sans;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 25px;
          color: #426ed9;
        }
      }
    }
  }
}
