@use "../constants"as constants;
@use "../viewport" as viewport;

.time-range-widget {
  @include viewport.tablet {
    .horizontal > .form-items {
      display: flex;
      flex-wrap: wrap;
        
      .form-item {
        &:first-child {
          min-width: 200px;
        }
    
        .form-input > .time-picker > .time-input { 
          margin-left: 10px;
        }
      }
    }
    
    .start-date,
    .end-date {
      display: flex;
      align-items: end;
    }
  }

  @include viewport.mobile {
    .horizontal > .form-items {
      .form-item {
        .form-input > .time-picker > .time-input { 
          margin-top: 8px;
        }
      }
    }
  }

  .date-range, .note {
    margin-top: 12px;
    font-style: italic;
    font-size: 13px;
    padding-left: constants.$curve-padding;
  }
}

