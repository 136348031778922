@use "../card";
@use "../colors";
@use "../viewport";

.table-booking-settings-unavailable-time-of-day-list-widget {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 12px;

  > .form-item {
    > .form-input {
      display: grid;
      gap: 12px;

      > .unavailable-time-of-day {
        display: grid;
        grid-template-columns: 1fr 16px;
        gap: 20px;

        > .form-item > .form-input {
          display: grid;
          gap: 12px;

          > .day-interval {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;

            @include viewport.mobile {
              grid-template-columns: 1fr;
              gap: 12px;
            }

            > .time-input {
              > .input-box {
                input {
                  margin-top: 0;
                }
              }

              &:first-of-type {
                width: 95%;

                @include viewport.mobile {
                  width: 100%;
                }
              }

              &:last-of-type {
                margin-left: -5%;

                @include viewport.mobile {
                  margin-left: 0;
                }
              }
            }
          }
        }

        > .delete-icon {
          @include card.round-button;
          font-size: 24px;
          color: colors.$rose-500;
          padding-top: 4px;
        }
      }
    }
  }

  .add-icon {
    @include card.round-button;
    font-size: 24px;
    padding-top: 4px;
  }
}
