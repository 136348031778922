@use '../colors';
@use '../viewport';
@use '../common/elements';
@use '../common/gap';

.uber-eats-setup-banner {
  // FOR PDF
  table,
  th,
  td {
    border: 1px solid #ccc;
    border-collapse: collapse;
    background-color: colors.$white;
    color: black;
    padding: 2px 6px;
    font-size: 12px;
    vertical-align: top;
    line-height: 18px;
    & .menu-name {
      background-color: #666666;
      color: colors.$white;
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
      padding: 8px;
    }

    & .category-name {
      background-color: #d9d9d9;
      font-weight: bold;
      padding: 8px;
    }

    & .table-header {
      font-weight: bold;
      font-size: 10px;
    }
  }

  position: relative;
  .content {
    display: grid;
    grid-template-areas:
      'title actions'
      'body actions'
      'footer footer';
    gap: 16px;
    grid-template-columns: 1fr min-content;
    grid-template-rows: repeat(2, min-content);
    align-items: center;
  }

  .title {
    grid-area: title;
  }

  .body {
    grid-area: body;
  }

  .actions {
    grid-area: actions;
    white-space: nowrap;
    margin-left: 16px;

    .submit-form-button {
      margin-bottom: 16px;
    }
  }

  .footer {
    grid-area: footer;
    background-color: colors.$blue-100;
    margin: 0 -24px 0 -56px;
    border-radius: 0 0 6px 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.0025em;

    .download-button {
      > button {
        display: flex;
        min-width: 120px;
        align-items: center;
        font-size: 12px;
        background-color: transparent;
        box-shadow: none;
        cursor: pointer;

        &:hover {
          background-color: colors.$blue-200;
        }

        > .icon {
          color: colors.$blue-800;
        }

        > .label {
          color: colors.$blue-600;
        }
      }

      > .overlay {
        color: colors.$black;

        .options-wrapper > .options {
          width: 100%;
          padding-left: 6px;
        }
      }
    }
  }

  .ready-to-enable-order-flow .enable-button {
    background-color: colors.$green-600;
    color: colors.$white;
    border-radius: 4px;
    padding: 9.5px 24px;
  }

  .uber-eats-submit-form-banner {
    padding-bottom: 0;
    > .content {
      > .body {
        > .menu-integration-steps {
          @include gap.vertical(8px);
          list-style-type: decimal;
          list-style-position: inside;
          margin-left: 4px;

          > *:not(.step-list) {
            display: list-item;
          }

          > .step-list {
            list-style-type: disc;
            list-style-position: inside;
            margin-left: 16px;

            > * {
              display: list-item;

              > .label {
                font-weight: 500;
              }
            }
          }
        }
      }

      > .actions {
        align-self: flex-start;

        > .submit-form-button {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: colors.$blue-600;
          padding: 9.5px 24px;
          border-radius: 4px;
          color: colors.$white;
          text-decoration: none;
          display: flex;
          font-size: 14px;
          font-weight: 500;

          > .icon {
            @include elements.icon(20px);
            margin-left: 8px;
          }
        }
      }
    }
  }

  @include viewport.mobile {
    .content {
      display: unset;
      align-items: unset;
    }
    .actions {
      margin: 0;
      .submit-form-button,
      .uber-eats-login-button,
      .enable-button {
        display: block;
        box-sizing: border-box;
        margin-top: 12px;
        text-align: center;
        width: 100%;
      }
    }
  }
}

.swal2-container {
  .uber-eats-submit-form-banner {
    width: 573px;
    padding: 32px 24px;
    border-radius: 20px;

    .swal2-icon.swal2-warning {
      color: colors.$yellow-500;
      border-color: colors.$yellow-500;
      border-width: 4px;
      font-size: 9px;
      font-weight: 500;
    }

    .swal2-title {
      color: colors.$blue-gray-900;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }

    .swal2-content {
      padding: 0;
      margin-top: 16px;

      color: colors.$blue-gray-500;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }

    .swal2-actions {
      margin-top: 24px;
      display: grid;
      grid-template-areas: 'cancel-button confirm-button';
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
      padding: 0 24px;

      button {
        border-radius: 4px;
        padding: 14px 32px;
        margin: 0;

        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }

      .confirm-button {
        grid-area: confirm-button;
        white-space: nowrap;
        background-color: colors.$blue-600;
        color: colors.$white;
      }

      .cancel-button {
        grid-area: cancel-button;
        background-color: colors.$white !important;
        color: colors.$blue-gray-700;
        border: 1px solid colors.$blue-gray-500;
      }
    }
  }
}
