@use '../colors';
@use '../viewport';

.sms-marketing-recipient-emails-widget.form {
  display: grid;
  grid-auto-flow: row;
  gap: 20px;
  margin-left: 56px;
  margin-top: 20px;

  @include viewport.desktop {
    grid-auto-flow: column;
    grid-template-columns: 1fr max-content;
  }

  &.error > .form-item > .meta > .help {
    color: colors.$rose-500;
  }

  .react-tagsinput-tag {
    background-color: colors.$light-gray-100;
    color: colors.$blue-gray-700;

    &:last-of-type {
      margin-bottom: 15px !important;
    }

    > .react-tagsinput-remove {
      color: colors.$blue-gray-700;
      font-weight: 300;
      font-size: 22px;
      line-height: 0;
      position: relative;
      top: 4px;
    }
  }

  > .form-item {
    label {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: colors.$blue-gray-500;
    }

    .meta > .help {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: colors.$blue-gray-700;
    }
  }

  .actions {
    justify-self: end;

    @include viewport.desktop {
      align-self: end;
      margin-bottom: 26px;
    }

    > .confirm-recipients-button {
      background-color: white;
      border: 1px solid colors.$blue-400;
      color: colors.$blue-600;
    }
  }

  .emails-container {
    padding-left: 5px;

    .label {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: repeat(2, min-content);
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: colors.$blue-gray-700;

      > img {
        margin-left: 5px;
        cursor: pointer;
      }
    }

    > .emails {
      display: flex;
      gap: 10px;
      margin-top: 10px;

      .email {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        padding: 4px 12px;
        border: 1px solid colors.$light-gray-400;
        border-radius: 4px;
        color: colors.$blue-gray-700;
      }
    }
  }
}
