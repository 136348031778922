@use "../typography" as typography;
@use "../colors" as colors;

.table-booking-view-widget {
  .details {
    background-color: colors.$white;
    border-radius: 10px;
    .detail {
      display: flex;
      gap: 10px;
      padding: 15px 20px;
      border-bottom: 1px solid colors.$blue-gray-100;
      align-items: center;
      font-size: 13px;
      line-height: 19.5px;
      color: #171725;

      &.time {
        text-transform: uppercase;
      }

      .label {
        line-height: 16px;
        font-weight: 700;
        text-transform: uppercase;
      }

      .value {
        margin-left: auto;
        font-weight: 300;

        &.bold-700 {
          font-weight: 700;
        }

        &.seating {
          font-weight: 400;
          color: colors.$blue;
          background-color: #e7eafe;
          padding: 2px 8px;
        }
      }

      &.customer-name {
        .value {
          font-weight: 700;
        }
      }
    }

    .detail:last-child {
      border-bottom: unset;
    }
  }

  .activity-log {
    margin-top: 17.5px;

    .activity-log-button {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;

      svg {
        transform: rotate(-90deg);
        margin: 0px 16px 0px 6px;
      }

      &.close {
        svg {
          transform: rotate(0);
        }
      }
    }
  }
}
