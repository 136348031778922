@use "colors";

.form-banner {
  .alert {
    &.info {
      background-color: colors.$light-gray-100;
      color: colors.$blue-gray-700;

      .message {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: colors.$blue-gray-700;
      }
    }
  }
}
