@mixin allergen-info {
  > .form-input {
    display: grid;
    grid-row-gap: 20px;
  }

  .form-item {
    display: flex;
  }

  .form-input {
    width: 100%;
  }

  label {
    background-color: var(--form-element-bg);
    border-radius: 10px;
    border: 1px solid var(--form-element-border);
    box-sizing: border-box;
    color: var(--form-element-fg);
    padding: 10px 14px;
    margin-right: 10px;
    height: fit-content;
    width: 40%;
  }
}
