@use "../card"as card;
@use "../constants"as constants;
@use "../form"as form;
@use "../buttons"as buttons;
@use "../viewport"as viewport;

.loyalty-settings-widget {
  @include card.container;

  form {
    @include card.content;
    margin-top: 20px;
  }

  .form-input {
    margin-top: 8px;
  }

  .form-input > .card {
    @include viewport.tablet {
      @include card.container;
      @include form.inline;
    }
    margin-top: 1rem;
    margin-bottom: 1rem;

    .actions {
      margin-top: -20px;
      margin-bottom: 0px;
      @include card.round-buttons;

      .action {
        @include card.round-button;
      }
    }
  }

  .order-source-mobile-help-text {
    @include viewport.tablet {
      display: none;
    }

    .meta {
      display: block;
      @include viewport.tablet {
        display: none;
      }
    }
  }

  .form-radio-group {
    .meta {
      display: none;

      @include viewport.tablet {
        display: block;
      }
    }
  }

  .radio-group {
    @include viewport.desktop {
      display: flex;
      margin-bottom: 8px;
      flex-wrap: wrap;
    }
  }

  .radio {
    margin: 0 0 16px 0;
    @include viewport.desktop {
      margin: 0 20px 0 0;
    }
  }

  .amount-number-input,
  .percentage-number-input {
    display: grid;
    gap: 25px;
    padding: 0 2em 0 2em;

    @include viewport.tablet {
      grid-auto-flow: column;
      padding: 0;
    }

    .form-input {
      margin-top: 8px;
    }
  }
}
