@use '../colors';
@use '../viewport';

.category-sales-widget {
  box-sizing: border-box;
  background-color: colors.$white;
  border-radius: 8px;
  border: 1px solid colors.$blue-gray-100;
  padding: 24px 32px;
  position: relative;
  width: 100%;

  @include viewport.mobile {
    font-size: 18px;
    margin-bottom: 16px;
  }

  .category-name {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;

    @include viewport.mobile {
      font-size: 18px;
    }
  }

  .icon {
    position: absolute;
    right: 32px;
    top: 24px;
    filter: invert(0.6);
    font-size: 24px;
    cursor: pointer;
  }

  .category-items {
    display: block;
    margin-top: 12px;
    max-height: 100px;
    overflow: hidden;
    width: 100%;

    .category-item {
      display: inline-block;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      padding: 2px 8px;
      background-color: colors.$blue-gray-50;
      color: colors.$blue-gray-500;
      border-radius: 8px;
      margin: 0 8px 2px 0;
      max-width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-wrap: nowrap;
      text-overflow: ellipsis;

      // this is to hide the element permanently on the .category-item div and prevent flickering caused by the intersection observer
      &.hide {
        display: block;
      }

      @include viewport.mobile {
        font-size: 14px;
        overflow: hidden;
        text-wrap: nowrap;
        text-overflow: ellipsis;
        max-width: 40%;
      }
    }
  }
}
