@use '../colors';

.actions-toggle-widget {
  position: relative;

  .actions-toggle {
    border-radius: 8px;
    background: colors.$light-gray-200;
    padding: 5px 14px;
    text-align: center;
    height: 27px;
    font-weight: 900;
    font-size: 15px;
    cursor: pointer;
  }

  .actions {
    position: absolute;
    top: 42px;
    right: 0;
    width: max-content;
    box-shadow: 0px 0px 6px 0px #687a9b80;
    border-radius: 10px;
    background-color: colors.$white;

    > .action {
      &:first-child {
        margin-top: 0;
        border-top: 0;
      }

      border-top: 1px solid colors.$blue-gray-100;
    }
  }

  .action {
    padding: 16px;
    cursor: pointer;
  }
}
