@use "../colors";
@use "../card";
@use "../buttons";
@use "../typography";
@use "../constants";

.kds-device-view-widget {
  @include card.container;
  box-shadow: 0px 0px 4px 0px #00000040;
  color: colors.$blue-gray-900;

  .name {
    @include card.header;
    @include typography.h3;
    border-bottom: 1px solid colors.$blue-gray-100;
    grid-area: name;
  }

  .info {
    display: grid;
    grid-template-areas:
      'name               name'
      'id-label           id'
      'password-label     password'
      'created-time-label created-time'
      'actions            actions';
    grid-template-columns: max-content 1fr;
    gap: 12px;
    padding: 4px 16px;

    .id-label {
      grid-area: id-label;
    }

    .id {
      grid-area: id;
      justify-self: end;
    }

    .password-label {
      grid-area: password-label;
    }

    .password {
      grid-area: password;
      justify-self: end;
    }

    .created-time-label {
      grid-area: created-time-label;
      color: colors.$blue-gray-500;
    }

    .created-time {
      grid-area: created-time;
      justify-self: end;
      color: colors.$blue-gray-500;
    }
  }

  .actions {
    @include card.footer();
    display: flex;
    border-top: constants.$line;
    background-color: colors.$blue-gray-50;
    gap: 4px;
    grid-area: actions;

    .icon {
      font-size: 24px;
      color: colors.$blue-gray-500;
      cursor: pointer;
    }
  }
}

.ReactModalPortal:has(.delete-kds-device-warning-alert) {
  .ReactModal__Content.ReactModal__Content--after-open {
    .modal {
      > .delete-kds-device-warning-alert {
        padding: 32px 48px;


      }
    }
  }
}
