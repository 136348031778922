@use '../viewport' as viewport;

.store-print-settings-page {
  .body {
    display: grid;
    grid-gap: 20px;

    @include viewport.tablet {
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    }
  }
}
