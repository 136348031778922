@use '../colors';
@use "../viewport";

.sms-marketing-mobile-preview-widget {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0 0 0;

  .label {
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    margin-bottom: 24px;
    color: colors.$blue-gray-500;
  }

  .mobile-preview {
    background-image: url('../images/mobile-preview-half.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 90%;
    max-width: 300px;
    min-height: 350px;
    padding: 22px;

    .sender-id {
      padding: 14px 0;
      text-align: center;
      font-size: 10px;
      font-weight: 500;
      line-height: 15px;
      text-align: center;
      color: colors.$black;
      opacity: 50%;
    }

    .message {
      margin: 15px 0 0 20px;
      padding: 16px;
      background-color: colors.$light-gray-100;
      width: fit-content;
      border-radius: 1px 12px 12px 12px;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.0025em;
      max-width: 62%;
      overflow: hidden;
      text-wrap: wrap;
      word-wrap: break-word;

      .opt-out-link {
        margin-top: 21px;
      }

      .placeholder {
        color: colors.$blue-gray-500;
      }
    }
  }

  .sms-count {
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 24px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.004em;
    position: relative;

    img {
      cursor: pointer;
      margin-left: 8px;
    }

    .tooltip {
      position: absolute;
      display: none;
      width: 260px;
      text-align: justify;
      bottom: 40px;
      left: -50%;
      background-color: colors.$white;
      box-shadow: 2px 6px 24px 0px #0000001a;
      padding: 16px;
      border-radius: 8px;

      .arrow {
        position: absolute;
        bottom: -12px;
        right: 45%;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 12px solid colors.$white;
      }
    }
  }
}
