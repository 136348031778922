@use '../constants' as constants;
@use '../card' as card;
@use '../buttons' as buttons;
@use '../scrollbar' as scrollbar;
@use '../viewport' as viewport;

.loyalty-cards-page {
  .loyalty-cards {
    margin-top: 20px;
  }

  .loyalty-card {
    @include card.container;
    display: grid;
    grid-template-areas:
      'date             mobile          amount'
      'actions          actions         actions';

    padding: 18px 0;
    margin: 10px 0;

    .grid-text {
      margin: 0 15px;
      align-self: center;
      color: var(--message-info-text);
    }

    .header-text {
      margin: 0;
      font-size: 12px;
      font-weight: bold;
      font-style: italic;
      color: var(--message-info-text);
    }

    .date {
      grid-area: date;
      @extend .grid-text;
      .header {
        @extend .header-text;
      }
    }

    .mobile {
      grid-area: mobile;
      @extend .grid-text;
      .header {
        @extend .header-text;
      }
    }
    .amount {
      grid-area: amount;
      @extend .grid-text;
      .header {
        @extend .header-text;
      }
    }

    .actions {
      grid-area: actions;
      margin: 15px 15px 0 15px;
      padding-top: 15px;
      border-top: constants.$line-dashed;
    }

    .actions {
      display: flex;
      button {
        @include buttons.primary-transparent;
        @include buttons.badge-button;
        margin-right: 10px;
      }
    }
  }
}

.modal.order-history-modal {
  .order-history-modal {
    .header {
      display: flex;
      justify-content: space-between;

      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.1px;
        color: var(--fg);
        padding-left: 6px;
      }

      .close-button {
        padding: 15px;
        cursor: pointer;
      }
    }

    .orders {
      height: 65vh;
      overflow-y: auto;

      .loyalty-reward {
        @include card.container;
        display: flex;
        padding: 18px 0;
        margin: 15px 20px 15px 20px;
        max-width: 100%;

        .grid-text {
          margin: 0 20px;
          color: var(--message-info-text);
        }

        .header-text {
          margin: 0;
          padding: 0;
          font-size: 12px;
          font-weight: bold;
          font-style: italic;
          color: var(--message-info-text);
          line-height: 25px;
        }

        .date {
          @extend .grid-text;
          .header {
            @extend .header-text;
          }
        }

        .grand-total {
          @extend .grid-text;
          .header {
            @extend .header-text;
          }
        }

        .amount {
          @extend .grid-text;
          .header {
            @extend .header-text;
          }
        }

        .view-order {
          cursor: pointer;
          color: var(--link-fg);
          @extend .grid-text;
          .header {
            @extend .header-text;
          }
        }

        .view-order {
          @include buttons.primary-transparent;
          @include buttons.badge-button;
          margin: 15px 15px 0 15px;
        }
      }
    }

    @include viewport.mobile {
      .orders {
        margin-right: 0;
        .loyalty-reward {
          flex-direction: column;

          .view-order {
            margin: 10px 0 0 20px;
          }
        }
      }
    }
  }
}
