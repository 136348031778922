@use "../colors";
@use "../buttons";

.kds-devices-widget {
  .add-kds-device-button {
    @include buttons.primary-transparent;
    margin-top: 24px;
  }

  .kds-device-list-widget {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(362px, 1fr));
    gap: 20px;
  }
}
