@use '../card';
@use '../colors';
@use '../viewport';

.manage-store-integration-list-item-widget {
  @include card.container;

  .category {
    all: unset;
  }

  @media (max-width: #{viewport.$desktop-width}) {
    .info {
      display: grid;
      padding: 12px;
      grid-template-areas:
        'icon label'
        'icon status'
        'icon category'
        'action action';

      grid-template-columns: min-content 1fr;
      gap: 4px 16px;
      align-items: center;

      .name {
        display: contents;
        .icon {
          grid-area: icon;
        }
        .label {
          grid-area: label;
          font-size: 14px;
        }
      }

      .category {
        all: unset;
        grid-area: category;
        color: colors.$blue-gray-500;
        font-size: 10px;
        &::before {
          content: 'Category: ';
        }
      }
      .status {
        grid-area: status;
        font-size: 12px;
      }
    }
    .action {
      grid-area: action;
      @include card.footer;
      padding: 12px 16px;
    }
  }

  @include viewport.desktop {
    padding: 24px 20px;
    align-items: center;

    > .info > .name {
      display: flex;
      align-items: center;

      > .icon {
        width: 48px;
      }

      > .label {
        font-size: 16px;
        font-weight: 500;
        margin-left: 12px;
      }
    }
  }

  .status {
    color: colors.$blue-gray-700;
    &.is-integrated {
      color: colors.$teal-600;
    }
  }

  .action {
    button {
      background: none;
      box-shadow: none;
      border: 1px solid colors.$blue-400;
      border-radius: 4px;
      color: colors.$blue-600;

      &.remove-button {
        border: 1px solid colors.$rose-500;
        color: colors.$rose-500;
      }
    }
  }
}

.blocked-remove-integration-info-alert {
  width: unset;

  .content {
    margin-top: 0;
    width: 100%;

    .body {
      max-width: 394px;
    }
  }

  .actions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-top: 24px;

    button {
      font-size: 16px;
      border-radius: 4px;
      padding: 14px 32px;
    }

    .redirect-button {
      background-color: colors.$blue-600;
      color: colors.$white;
    }

    .cancel-button {
      background: none;
      box-shadow: none;
      outline: 1px solid colors.$blue-gray-500;
      color: colors.$blue-gray-700;
    }
  }
}
