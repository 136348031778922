@use "../colors";
@use "../card";
@use "../constants";
@use "../form";
@use "../buttons";

.store-edit-widget {
  @include card.container;

  form {
    @include card.content;
    margin-top: 20px;
    .form-items > .inline-title {
      margin: 0 -18px;
      label {
        text-align: left;
      }
    }
  }

  .form-address {
    > .form-input {
      @include card.container;
      @include card.content;
      @include form.inline;
    }
    > label {
      @include form.top-align-label;
    }
  }

  .date-and-time-picker-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .form-third-party-delivery-edit-widget {
    .form-input {
      .third-party-delivery-service {
        @include card.container;
        @include card.stack;
        margin-bottom: 20px;

        .info {
          @include card.upper;
          @include form.inline;
        }

        .actions {
          @include card.lower;
          @include card.round-buttons;
          .action {
            @include card.round-button;
          }
        }
      }

      .third-party-delivery-services-list-actions > .action {
        @include buttons.primary-transparent;
      }
    }
  }

  .submit-a-form-link {
    margin-left: 10px;
    text-decoration: underline;

    > img {
      position: relative;
      top: 2px;
      left: 10px;
    }
  }
}

.swal2-container {
  .store-edit-widget {
    width: 573px;
    padding: 32px 24px;
    border-radius: 20px;

    .swal2-icon.swal2-warning {
      color: colors.$yellow-500;
      border-color: colors.$yellow-500;
      border-width: 4px;
      font-size: 9px;
      font-weight: 500;
    }

    .swal2-title {
      color: colors.$blue-gray-900;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }

    .swal2-content {
      padding: 0;
      margin-top: 16px;

      color: colors.$blue-gray-500;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }

    .swal2-actions {
      margin-top: 24px;
      display: grid;
      grid-template-areas: 'cancel-button confirm-button';
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
      padding: 0 24px;

      button {
        border-radius: 4px;
        padding: 14px 32px;
        margin: 0;

        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }

      .confirm-button {
        grid-area: confirm-button;
        background-color: colors.$blue-600;
        color: colors.$white;
      }

      .cancel-button {
        grid-area: cancel-button;
        background-color: colors.$white !important;
        color: colors.$blue-gray-700;
        border: 1px solid colors.$blue-gray-500;
      }
    }
  }
}
