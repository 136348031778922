@use "../card";
@use "../colors";
@use "../switch";
@use "../constants";
@use "../form";
@use "../buttons";
@use "./base-item-edit-widget" as base-item;

$padding-horizontal: 15px;

.pizza-edit-widget {
  @include card.container;

  form {
    @include card.content;
    margin-top: 20px;

    .allergen-info {
      @include base-item.allergen-info;
    }
  }

  .pizza-sizes-form-item > label {
    @include form.top-align-label;
  }
  .sizes {
    display: grid;
    grid-row-gap: 20px;
  }
  .size {
    @include card.container;

    .switch {
      @include switch.inline();
    }

    .name {
      @include card.header;
    }

    .spec {
      @include form.inline;
      @include card.content;

      // > :nth-child(2) {
      //   display: flex;
      //   flex-direction: row;
      //   align-items: center;
      //   justify-content: space-between;

      //   input {
      //     max-width: 80px;
      //   }
      // }
    }
  }

  .remove-toppings-form-item {
    > label {
      @include form.top-align-label;
    }
    .list {
      @include card.container;
      @include card.content;
      display: grid;
      grid-gap: 20px;
      margin-bottom: 25px;

      .item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        input {
          margin-right: 20px;
        }
        .action {
          @include card.round-button;
        }
      }
    }
    .actions {
      margin-top: 4px;
      button {
        @include buttons.primary-transparent;
      }
    }
  }
}

.swal2-container {
  .pizza-spec-edit-widget {
    max-width: 364px;
    padding: 32px 24px;
    border-radius: 20px;

    .swal2-title {
      color: colors.$blue-gray-900;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }

    .swal2-content {
      padding: 0;
      margin-top: 16px;

      color: colors.$blue-gray-500;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }

    .swal2-actions {
      margin-top: 24px;
      flex-direction: column;
      gap: 16px;
      padding: 0 24px;

      button {
        border-radius: 4px;
        padding: 14px 32px;
        margin: 0;

        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }

      .confirm-button {
        width: 100%;
        background-color: colors.$blue-600;
        color: colors.$white;
      }

      .cancel-button {
        width: 100%;
        background-color: colors.$white !important;
        color: colors.$blue-gray-700;
        border: 1px solid colors.$blue-gray-500;
      }
    }
  }
}
