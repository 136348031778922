@use "../card" as card;
@use "../constants"as constants;
@use "../form"as form;
@use "../buttons"as buttons;

$padding-horizontal: 15px;
.store-website-settings-edit-widget {
  form {
    @include card.container;
    @include card.content;
    margin-top: 20px;

    .key-value-pair {
      .form-input {
        justify-self: start;
      }
    }

    .form-items {
      .form-item {
        padding-bottom: 10px;
      }
    }
  }

  .card {
    @include card.container;
    @include form.inline;
    margin-top: 1rem;
    margin-bottom: 1rem;

    .actions {
      margin-top: -20px;
      margin-bottom: 0px;
      @include card.round-buttons;

      .action {
        @include card.round-button;
      }
    }
  }
}
