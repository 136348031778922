@use '../card' as card;

.collection-type-widget {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  @include card.container;
  overflow: hidden;
  margin-bottom: 30px;

  .collection-type {
    text-align: center;
    border-right: 1px solid #e2e2ea;
    padding: 12px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:last-child {
      border-right: none;
    }

    span {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.1px;
      color: #171725;
    }

    &.selected {
      background-color: rgba(164, 97, 216, 0.1);

      .dot {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        display: inline-block;
        background: #a461d8;
        margin-right: 8px;
      }
    }
  }
}

.collection-type-banner {
  margin-bottom: 30px;
}
