@use '../colors';
@use '../common/elements';

.uber-eats-menu-store-hours-widget {
  max-width: 362px;
  width: 100vw;
  padding: 32px 24px;

  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: colors.$blue-gray-900;

  box-shadow: 0px 0px 3px 0px rgba(104, 122, 155, 0.25);

  > .close-button {
    margin-left: auto;
    @include elements.icon(13px);
    cursor: pointer;
  }

  > .header {
    padding-bottom: 14px;

    font-weight: 600;
    font-size: 24px;
    line-height: 28.8px;
  }

  > .body {
    margin-top: 16px;

    > .day-of-week {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      &:first-child {
        margin-top: 0;
      }

      > .label {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }

      > .daily-hours {
        text-align: right;
      }
    }
  }
}
