@use '../colors';
@use '../viewport';
@use '../common/elements';
@use '../common/gap';

.uber-eats-menu-widget {
  @include gap.vertical(12px);
  display: flex;
  flex-direction: column;
  height: 100%;

  > .header {
    > .label {
      padding: 12px 0;

      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
    }

    > .description {
      > .header {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        > .icon {
          @include elements.icon(20px);
          margin-right: 8px;
        }
      }
      color: colors.$blue-gray-500;
      white-space: pre-line;

      > .store-menu-page-link {
        color: colors.$blue-600;
        text-decoration: underline;

        cursor: pointer;
      }
    }
  }

  > .body {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;

    &.no-categories {
      height: 100%;
    }

    > .header {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: max-content 1fr max-content;

      .collection-type-dropdown {
        min-width: 164px;
      }

      > .store-hours-button {
        display: grid;
        grid-auto-flow: column;
        gap: 7px;
        color: colors.$black;
        text-decoration: underline;
        align-self: center;
        justify-self: end;
        border-right: 1px solid colors.$blue-gray-100;
        margin: 0 15px;
        padding: 10px 15px;

        cursor: pointer;
      }

      .edit-prices-button {
        padding: 10px 18px;
        > .label {
          display: grid;
          grid-auto-flow: column;
          gap: 7px;
        }
      }
    }

    > .categories {
      margin-top: 20px;

      > .category {
        all: revert;
        padding: 20px;
        background: colors.$white;
        border: 1px solid colors.$blue-gray-100;
        border-radius: 10px;
        margin-top: 20px;

        &:first-child {
          margin-top: 0;
        }

        > .header {
          display: flex;
          justify-content: space-between;

          .container {
            > .label {
              display: flex;

              > .name {
                all: unset;

                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
              }

              > .bullet {
                margin: 0 8px;

                color: colors.$blue-gray-200;
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
              }

              > .item-count {
                color: colors.$blue-gray-500;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
              }
            }

            > .description {
              all: unset;

              color: colors.$blue-gray-500;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
            }
          }

          > .icon {
            @include elements.icon(32px);
          }
        }

        > .item-specs {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
          gap: 24px;
          margin-top: 24px;

          @include viewport.desktop {
            & {
              grid-template-columns: repeat(3, 1fr);
            }
          }

          > .item-spec {
            all: unset;
            display: grid;
            grid-template-areas:
              'image name        name                '
              'image price       see-modifiers-button'
              'image description description         ';
            grid-template-columns: min-content min-content auto;
            grid-auto-rows: min-content;
            align-items: center;
            padding: 12px;
            border: 1px solid colors.$blue-gray-100;
            border-radius: 8px;

            > .image {
              all: unset;
              grid-area: image;
              margin-right: 10px;
            }

            > .name {
              all: unset;
              grid-area: name;

              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
            }

            > .price {
              all: unset;
              grid-area: price;

              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
            }

            > .see-modifiers-button {
              grid-area: see-modifiers-button;
              display: flex;
              align-items: center;
              margin-left: 10px;

              color: colors.$blue-600;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;

              cursor: pointer;

              > .icon {
                @include elements.icon(12px);
                filter: brightness(0) saturate(100%) invert(26%) sepia(61%) saturate(4015%) hue-rotate(218deg)
                  brightness(98%) contrast(87%);
              }
            }

            > .description {
              all: unset;
              grid-area: description;

              color: colors.$blue-gray-500;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
            }
          }
        }
      }
    }

    > .no-categories {
      @include gap.vertical(32px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      > .icon {
        @include elements.icon(64px);
      }

      > .title {
        font-weight: 600;
        font-size: 24px;
        line-height: 28.8px;
      }

      > .description {
        color: colors.$blue-gray-500;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        > .store-menu-page-link {
          color: colors.$blue-600;
          text-decoration: underline;

          cursor: pointer;
        }
      }
    }

    > .publish-button {
      padding: 14px 32px;
      margin-left: auto;
      margin-top: 20px;
      background-color: colors.$blue-600;
      border-radius: 4px;

      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: colors.$white;
    }
  }
}

.uber-eats-menu-item-spec-modifiers-widget-modal {
  width: 90vw;
  max-width: 480px !important;
}

.swal2-container {
  .uber-eats-menu-widget {
    max-width: 364px;
    padding: 32px 24px;
    border-radius: 20px;
    height: fit-content;

    .swal2-title {
      color: colors.$blue-gray-900;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }

    .swal2-content {
      padding: 0;
      margin-top: 16px;

      color: colors.$blue-gray-500;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }

    .swal2-actions {
      margin-top: 24px;
      flex-direction: column;
      gap: 16px;
      padding: 0 24px;

      button {
        border-radius: 4px;
        padding: 14px 32px;
        margin: 0;

        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }

      .confirm-button {
        width: 100%;
        background-color: colors.$blue-600;
        color: colors.$white;
      }

      .cancel-button {
        width: 100%;
        background-color: colors.$white !important;
        color: colors.$blue-gray-700;
        border: 1px solid colors.$blue-gray-500;
      }
    }
  }
}
