@use '../colors';
@use '../common/elements';
@use '../common/gap';

.uber-eats-menu-deal-spec-modifiers-view-widget {
  @include gap.vertical(32px);
  margin-top: 16px;

  > .deal-pizza-specs {
    @include gap.vertical(32px);

    > .deal-pizza-spec {
      > .label {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
      }

      > .options {
        @include gap.vertical(8px);
        margin-top: 16px;

        > .deal-pizza-spec-pizza-spec {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }

  > .deal-item-specs {
    @include gap.vertical(32px);

    > .deal-item-spec {
      > .label {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
      }

      > .modifier-specs {
        @include gap.vertical(24px);
        margin-top: 16px;

        > .modifier-spec {
          > .label {
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
          }

          > .options {
            @include gap.vertical(8px);
            margin-top: 16px;

            > .modifier-option {
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }
    }
  }

  > .modifier-specs {
    @include gap.vertical(32px);

    > .modifier-spec {
      > .label {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
      }

      > .options {
        @include gap.vertical(8px);
        margin-top: 16px;

        > .modifier-option {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
