@use "../card"as card;
@use "../constants"as constants;
@use "../form"as form;
@use "../buttons"as buttons;
@use "../viewport" as viewport;

$padding-horizontal: 15px;

.restaurant-website-info {
  @include card.container;

  form {
    @include card.content;
    margin-top: 20px;
  }

  .form-item {
    margin-top: 0.5rem;
  }

  .list {
    margin-top: 1rem;
  }

  .card {
    @include card.container;
    @include form.inline;
    margin-top: 1rem;
    margin-bottom: 1rem;

    .actions {
      margin-top: -20px;
      margin-bottom: 0px;
      @include card.round-buttons;

      .action {
        @include card.round-button;
      }
    }
  }

  .btn-gallery-add {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .hero-form-item .banner-input {
    margin-bottom: 20px;
  }

  .form-input {
    .photo,
    .hero {
      @include card.container;
      @include card.stack;
      margin-bottom: 20px;

      .item {
        @include card.upper;
        @include form.inline;
      }
      .actions {
        @include card.lower;
        @include card.round-buttons;
        .action {
          @include card.round-button;
        }
      }
    }
  }

  .stores-form-item {
    margin-top: -20px;

    .store-select-card {
      display: grid;
      grid-template-columns: 1fr min-content;
      border: none;
      max-width: 35vw;
      margin-top: 14px;

      .action {
        height: 100%;
        display: grid;
        grid-template-rows: 14px 14px min-content;
      }
      .action .icon {
        grid-row-start: 3;
        transform: scale(2);
        margin-left: 20px;
        margin-top: 20px;
        cursor: pointer;
      }
    }
    .add-store-button {
      margin-top: 20px;
    }
  }

  @include viewport.mobile {
    .stores-form-item {
      .store-select-card {
        max-width: unset;
      }
    }
  }
}
