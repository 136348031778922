@use '../colors';
@use "../viewport";

@mixin header() {
  padding-bottom: 40px;

  > .header {
    margin: 24px 0;
    display: grid;
    grid-template-areas:
      'logo title status'
      'logo sub-title sub-title';

    grid-template-columns: min-content 1fr max-content;
    gap: 8px 24px;

    .logo {
      grid-area: logo;
      height: 48px;

      @include viewport.tablet {
        height: 80px;
      }
    }

    .title {
      grid-area: title;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;

      @include viewport.tablet {
        font-size: 34px;
        font-weight: 600;
        line-height: 41px;
      }
    }

    .sub-title {
      grid-area: sub-title;
      margin-top: 10px;

      @include viewport.tablet {
        margin-top: 0;
      }
    }

    .status {
      grid-area: status;
      color: colors.$blue-gray-700;

      &.is-enabled {
        color: colors.$green-600;
      }
    }
  }

  @include viewport.mobile {
    > .header {
      grid-template-areas:
        'logo title'
        'logo status'
        'sub-title sub-title';
      grid-template-columns: min-content 1fr;
      align-items: center;
    }
  }
}

.add-ons-widget {
  .no-add-ons-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 25vh;

    > .icon {
      grid-area: icon;
      height: 64px;
    }

    .title {
      grid-area: title;
      font-size: 24px;
      font-weight: 600;
      color: colors.$blue-gray-700;
      margin-top: 24px;
    }

    .sub-title {
      font-size: 16px;
      color: colors.$blue-gray-500;
      margin-top: 24px;

      .link {
        display: inline-block;
        color: colors.$blue-600;
        font-weight: 500;
        cursor: pointer;
      }
      .icon {
        display: none;
      }
    }
  }

  .add-ons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    @include viewport.desktop {
      grid-template-columns: repeat(auto-fill, 552px);
    }

    @include viewport.mobile {
      grid-template-columns: 1fr;
    }
  }
}
