.checkbox {
  display: grid;
  grid-template-columns: min-content min-content;
  font-weight: 300;
  color: #555555;
  grid-gap: 2px;

  input {
    vertical-align: middle;
  }
  label {
    white-space: nowrap;
  }
}
