@use "../card" as card;
@use "../form" as form;
@use "./base-item-edit-widget" as base-item;

.half-and-half-edit-widget {
  @include card.container;

  form {
    @include card.content;
    margin-top: 20px;

    .allergen-info {
      @include base-item.allergen-info;
    }
  }

  .pizzas-form-item > label {
    @include form.top-align-label;
  }

  .pizza-list {
    @include card.container;
    @include card.stack;
    @include card.upper;

    .head {
      @include card.header;
      background-color: #f9f9f9;
    }
    .body {
      @include card.content;
      display: grid;
      grid-gap: 20px;
    }
  }

  .pizza-list .head,
  .pizza {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-gap: 15px;
  }
}
