@use '../card'as card;
@use '../constants'as constants;
@use '../typography'as typography;

.item-spec {
  overflow: hidden;
  @include card.container;
  @include card.stack;
  display: grid;
  grid-template-rows: 1fr min-content;
  cursor: pointer;
  background-color: #fafafb;

  &.accent {
    grid-template-rows: min-content 1fr min-content;
  }

  .body {
    @include card.upper;
    padding: 12px;
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-template-rows: min-content 1fr min-content;
    grid-template-areas:
      'name image'
      'description nothing'
      'price order-system';
  }

  > .accent {
    height: 6px;
    width: 100%;
    display: inline-block;
  }

  .image {
    overflow: hidden;
    grid-area: image;
    width: 100px;
    margin-left: 10px;

    img {
      border-radius: constants.$border-radius;
    }
  }

  .name {
    @include typography.h2;
    grid-area: name;
    align-self: flex-start;
  }

  .description {
    @include typography.h6;
    grid-area: description;
    align-self: flex-start;
  }

  .price {
    grid-area: price;
    @include typography.h2;
    color: var(--color-secondary);
    align-self: flex-end;
  }

  .not-available {
    @include typography.h6;
    font-size: 13px;
    align-self: flex-start;
  }

  .online-only,
  .pos-only,
  .uber-eats-only {
    grid-area: order-system;
    font-size: 13px;
    align-self: flex-start;
    padding-left: 5px;
  }

  .actions {
    @include card.lower;
    @include card.round-buttons;

    .action {
      @include card.round-button;
    }
  }
}
