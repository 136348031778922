@use '../colors';
@use '../typography';
@use '../viewport';
@use '../scrollbar';

.dine-in-page {
  main {
    overflow: auto;
  }

  .display-selector {
    display: flex;
    border-bottom: 1px solid colors.$blue-gray-100;
    margin-bottom: 4px;
    overflow: hidden;
    height: 100%;

    @include viewport.mobile {
      font-size: 14px;
      overflow-x: scroll;
    }

    .option {
      @include typography.h2;
      line-height: 24px;
      padding: 12px;
      margin-right: 12px;
      color: colors.$blue-gray-700;
      text-wrap: nowrap;
      cursor: pointer;
      font-weight: 400;

      @include viewport.mobile {
        font-size: 12px;
        padding: 8px;
      }

      &.active {
        color: colors.$blue-600;
        border-bottom: 4px solid colors.$blue-600;
      }
    }
  }

  .table-booking-settings-edit-widget {
    margin-top: 20px;
  }

  .dine-in-settings-edit-widget {
    margin-top: 20px;
    border-radius: 10px;
    padding: 24px 0;
    gap: 24px;
    background-color: colors.$white;
  }

  @include viewport.mobile {
    .dine-in-settings-edit-widget {
      margin-top: 24px;
      padding: 24px 20px;

      form {
        .label {
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
        }

        .meta {
          margin-top: 8px;
        }

        .help {
          color: colors.$blue-gray-500;
          font-size: 12px;
          line-height: 18px;
          font-weight: 300;
        }

        .form-buttons {
          margin-top: 24px;
        }
      }
    }
  }
}

.swal2-container {
  &.delete-category {
    .swal2-popup {
      border-radius: 20px;
      padding: 32px 24px;
    }

    .swal2-actions {
      flex-direction: column;

      button {
        border-radius: 4px;
        width: 70%;
      }
      .swal2-confirm {
        background-color: colors.$rose-800;
      }

      .swal2-cancel {
        background-color: transparent !important;
        color: colors.$blue-gray-700;
        border: 1px solid colors.$blue-gray-500;
      }
    }
  }
}
