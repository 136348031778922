$bg: #f4f4f4;
$fg: #27283e;

$primary: #0062ff;
$secondary: #e97a6e;

$card-bg: white;

$link-fg: #1e75ff;

$active-highlight: #10b981;

$form-element-bg: white;
$form-element-fg: #44444f;
$form-element-border: #ddd;
$form-element-error: #fc5a5a;

$button-action: #999;

$button-primary-bg: $primary;
$button-primary-fg: white;

$button-secondary-bg: white;
$button-secondary-fg: #696974;

$button-success-bg: #36b37e;
$button-success-fg: white;

$button-danger-bg: red;
$button-danger-fg: white;

$message-success-text: #10b981;
$message-success-bg: #e3fcef;
$message-success-fg: #36b37e;

$message-error-text: #bf2600;
$message-error-bg: #ffebe6;
$message-error-fg: #ff5630;

$message-info-text: #505f79;
$message-info-bg: white;
$message-info-fg: #2684ff;

$message-warning-text: #ff8b00;
$message-warning-bg: #fffae6;
$message-warning-fg: #ffab00;

$message-loading-text: #505f79;
$message-loading-bg: white;
$message-loading-fg: #ffab00;

$message-disabled-text: #696974;
$message-disabled-bg: #f5f5f5;
