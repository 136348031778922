@use '../card' as card;

.table-map-settings-widget {
  padding-bottom: 40px;

  > .header {
    font-size: 24px;
    font-weight: 600;
    line-height: 40.8px;

    margin: 24px 0;
  }

  .settings {
    @include card.container;
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-areas:
      'header     toggle'
      'info       info';
    gap: 16px;
    padding: 24px;
    align-items: center;

    > .header {
      grid-area: header;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
    }

    > .toggle {
      grid-area: toggle;
    }

    > .info {
      grid-area: info;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
