@use "viewport"as viewport;

.form-multi-select-pill {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .label {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: var(--fg);
  }

  .help-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1px;
    margin-bottom: 1rem;
    color: #71717b;
  }

  .error {
    margin-right: auto;
    margin-left: 10px;
  }

  .options.large {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-self: end;

    @include viewport.mobile {
      grid-template-columns: repeat(2, 1fr);
    }

    .option {
      cursor: pointer;

      border: 2.25px solid;
      border-color: #0062FF;
      margin: 0rem 1rem 1rem 0rem;
      padding: 0.50rem 1rem 0.50rem 1rem;
      border-radius: 0.85rem;

      text-align: center;
      color: #0062FF;
      font-weight: 500;

      justify-content: center;
      align-items: center;
    }

    .option.selected {
      background-color: #0062FF;
      color: var(--card-bg);
    }

  }

  .options.small {
    display: flex;
    flex-wrap: wrap;
    justify-self: end;

    .option {
      cursor: pointer;

      border: 2px solid;
      border-color: #0062FF;
      margin-inline: 5px;
      padding: 5.5px 8px;
      border-radius: 10px;
      text-align: center;
      color: #0062FF;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;

      justify-content: center;
      align-items: center;
    }

    .option.selected {
      background-color: #0062FF;
      color: var(--card-bg);
    }

  }

}
