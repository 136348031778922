@use "form";
@use "card";
@use "viewport";
@use "modal";
@use 'colors';
@use 'text-input';
@use 'typography';

.dropdown {
  > button {
    background-color: white;

    &.open {
      color: colors.$blue-gray-200;
    }
  }

  .input-box {
    @include form.form-input();
    padding: form.$padding;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
  }

  .options {
    @include card.container();
    @include card.content();
    padding: 10px 0;
    display: grid;
    grid-gap: 5px;
    font-weight: 400;
    @include modal.container();

    .search-box {
      padding: 0 12px;

      .text-input {
        @include text-input.form-text-input;
        padding: 6px 0 16px 0;

        input {
          color: colors.$blue-gray-900;
          text-indent: 8px;
          background-image: url('../images/icon-search.svg');
          background-repeat: no-repeat;
          background-position: left;
          background-size: 16px;

          &::placeholder {
            color: colors.$blue-gray-200;
          }
        }
      }

      .meta > .error {
        font-size: 16px;
        color: colors.$blue-gray-500;
        padding: 12px 0 2px 0;
      }
    }

    > .list {
      > .disabled {
        color: colors.$blue-gray-300;
      }

      .option {
        cursor: pointer;
        padding: 10px 16px;
      }
    }
  }

  @include viewport.mobile {
    .overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 998;
    }
  }

  @include viewport.tablet {
    .options-wrapper {
      position: relative;
    }
    .options {
      position: absolute;
      top: 100%;
      margin-top: 10px;
      z-index: 999;
    }
  }

  .selected {
    .icon {
      color: var(--message-success-text);
    }
  }

  .option {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
