@use "../constants" as constants;
@use "../card" as card;
@use "../typography" as typography;
@use "../buttons"as buttons;

.pos-terminal-settings-widget {
  .title {
    @include card.header();
    @include card.header-font();
    @include typography.h2;
  }

  @include card.container();

  .actions {
    @include card.footer();
    @include buttons.button-group();
    background-color: #fff;
    button {
      margin-right: 10px;
      @include buttons.primary-transparent;
    }
  }

  .empty-message {
    padding: 20px;
  }
}

.pos-terminal {
  @include card.container;
  margin: 20px;

  display: grid;
  grid-template-areas:
    'name                     name'
    'id-label                 id'
    'password-label           password'
    'created-time-label       created-time'
    'unique-prefix-label      unique-prefix'
    'actions                  actions';
  grid-template-columns: max-content 1fr;
  overflow: hidden;

  .name,
  .id-label,
  .id,
  .password-label,
  .password,
  .created-time-label,
  .created-time,
  .unique-prefix-label,
  .unique-prefix,
  .actions {
    padding: 15px;
  }

  .name {
    @include typography.h3;
    grid-area: name;
    border-bottom: constants.$line;
  }



  .id-label,
  .password-label,
  .created-time-label,
  .unique-prefix-label {
    color: #696974;
  }
  .id-label {
    grid-area: id-label;
  }
  .created-time-label {
    grid-area: created-time-label;
  }
  .password-label {
    grid-area: password-label;
  }
  .unique-prefix-label {
    grid-area: unique-prefix-label;
  }


  .id,
  .password,
  .created-time,
  .unique-prefix {
    justify-self: end;
  }
  .id {
    grid-area: id;
  }
  .password {
    grid-area: password;
  }
  .created-time {
    grid-area: created-time;
  }
  .unique-prefix {
    grid-area: unique-prefix;
  }

  .actions {
    @include card.footer();
    display: flex;
    grid-area: actions;

    button {
      width: 100px;
    }
    .action {
      @include card.round-button;
      cursor: pointer;
    }
  }
}
