@mixin icon($size: 16px, $iconSize: 100%) {
  display: flex;
  align-items: center;
  justify-content: center;
  height: $size;
  width: $size;

  > img {
    height: $iconSize;
  }
}
