@use '../colors';

.uber-eats-integrate-store-widget {
  display: grid;
  grid-template-areas:
    'sub-header sub-header'
    'store-selector match-store-button';
  grid-template-columns: 1fr max-content;
  gap: 0 64px;
  align-items: center;
  background: colors.$light-gray-50;
  padding: 20px;

  > .sub-header {
    grid-area: sub-header;
    display: inline-flex;
    font-size: 16px;
    font-weight: 500;

    .store-name {
      margin-left: 8px;
      color: colors.$blue-800;
    }
  }

  > .form-item {
    grid-area: store-selector;
    margin-top: 16px;

    label {
      font-size: 14px;
    }
    .input-box {
      font-size: 16px;
      margin-top: 8px;
      > span {
        color: colors.$blue-gray-200;
      }
    }
  }

  .integrate-store-button {
    grid-area: match-store-button;
    background-color: colors.$blue-600;
    color: colors.$white !important;
    border-radius: 4px;
    padding: 9.5px 24px;

    &.disabled {
      background-color: colors.$blue-300 !important;
    }
  }
}
