@use "../typography";
@use "../utils";
@use '../viewport';

.table-booking-back-link-header-widget {
  .title {
    @include typography.h1;
    @include utils.curve-padding;
    margin: 20px 0px;
  }

  @media (max-width: #{viewport.$desktop-width}) {
    .title {
      margin: 16px 0px 20px 0px;
    }
  }

  @media (max-width: #{viewport.$tablet-width}) {
    .title {
      margin: 16px 0px 16px 0px;
    }
  }
}
