@use "../card"as card;
@use "../constants"as constants;
@use "../typography"as typography;

.restaurant-user-card-view {
  @include card.container;
  @include card.stack;
  display: grid;
  grid-template-rows: 1fr min-content;
  background-color: #fafafb;

  .body {
    @include card.upper;
    cursor: pointer;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .display-name {
      @include typography.h2;
      padding-left: 6px;
      color: #4c4c77;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: -1px;
    }

    .email {
      @include typography.h4;
      padding-left: 6px;
      margin-top: -1px;
    }

    .role {
      padding-left: 6px;
    }
  }

  .actions {
    @include card.lower;
    @include card.round-buttons;

    .action {
      @include card.round-button;
    }
  }
}
