@use "./card"as card;
@use "./constants"as constants;
@use "./form"as form;
@use "./buttons"as buttons;

.form-address-search {
  >label {
    @include form.top-align-label();
  }

  >.form-input {
    @include card.container;

  }

  .search {
    @include card.header;
  }

  .info {
    @include card.content;
    @include form.inline;
  }

  .geopoint {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }
}