@use "../themes/default" as default-theme;
@use "../card" as card;
@use "../viewport" as viewport;
@use "../typography" as typography;

.new-order-list-item-widget {
  @include card.container;
  @include card.stack;
  padding: 20px;
  margin-bottom: 10px;
}

.store-name {
  .value {
    @include typography.h3;
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.order-date-time {
  .text {
    align-self: center;
  }
}

.status.new .value {
  color: #{default-theme.$message-warning-fg};
  background-color: #fef3c7;
  display: inline-block;
  width: fit-content;
  height: fit-content;
  padding: 0 10px;
  border-radius: 4px;
  font-weight: bold;
}

.status.incomplete .value {
  color: #{default-theme.$message-success-fg};
  background-color: #e0f7e9;
  display: inline-block;
  width: fit-content;
  height: fit-content;
  padding: 0 10px;
  border-radius: 4px;
  font-weight: bold;
}

.view-button {
  display: flex;
  justify-content: center;
  button {
    border: 1px solid #{default-theme.$link-fg};
    background-color: #{default-theme.$form-element-bg};
    color: #{default-theme.$link-fg};
    width: 100%;
  }
}

.restaurant-logo {
  width: 60px;

  .image > img {
    border-radius: 10px;
  }
}

.order-date-time .value .passed-five-minutes {
  color: #{default-theme.$secondary};
}

@include viewport.mobile {
  .new-order-list-item-widget {
    gap: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .store-name {
    grid-area: auto;

    .title {
      display: none;
    }
  }

  .store-information {
    grid-area: auto;
    word-break: break-all;
    .title {
      display: none;
    }
  }

  .order-total {
    grid-area: auto;

    display: flex;
    flex-direction: column;

    .title {
      font-weight: bold;
    }
  }

  .order-date-time {
    grid-area: auto;
    flex-direction: column;
    gap: 10px;

    .title {
      font-weight: bold;
    }

    .value {
      display: flex;
      align-items: center;
      gap: 8px;

      .text {
        align-self: center;
      }
    }
  }

  .status {
    grid-area: auto;
    flex-direction: column;
    gap: 10px;

    .title {
      font-weight: bold;
    }
  }

  .view-button {
    grid-area: auto;
  }
}

@include viewport.tablet {
  .new-order-list-item-widget {
    gap: 20px;
    grid-template-areas:
      'store-name store-information order-total'
      'order-time status view';
  }

  .store-name {
    grid-area: store-name;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .title {
      font-weight: bold;
    }
  }

  .store-information {
    grid-area: store-information;
    display: flex;
    flex-direction: column;
    gap: 10px;
    word-break: break-all;

    .title {
      font-weight: bold;
    }
  }

  .order-total {
    grid-area: order-total;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .title {
      font-weight: bold;
    }
  }

  .order-date-time {
    grid-area: order-time;
    flex-direction: column;
    gap: 10px;

    .title {
      font-weight: bold;
    }

    .value {
      display: flex;
      align-items: center;
      gap: 8px;

      .text {
        align-self: center;
      }
    }
  }

  .status {
    grid-area: status;
    flex-direction: column;
    gap: 10px;

    .title {
      font-weight: bold;
    }
  }

  .view-button {
    grid-area: view;
  }
}

@include viewport.desktop {
  .new-order-list-item-widget {
    grid-template-columns: 3fr 2fr 1fr 2fr 1fr 1fr;
    gap: 10px;
    align-items: center;
  }

  .store-name {
    grid-area: auto;

    .title {
      display: none;
    }
  }

  .store-information {
    grid-area: auto;
    word-break: break-all;
    .title {
      display: none;
    }
  }

  .order-total {
    grid-area: auto;

    .title {
      display: none;
    }
  }

  .order-date-time {
    grid-area: auto;

    .title {
      display: none;
    }

    .text {
      align-self: center;
    }

    .value {
      grid-area: auto;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .status {
    grid-area: auto;

    .title {
      display: none;
    }
  }

  .view-button {
    grid-area: auto;
  }
}
