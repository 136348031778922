@use '../card'as card;
@use '../constants'as constants;
@use '../typography'as typography;
@use '../viewport'as viewport;

.coupons {
  display: grid;
  grid-gap: 20px;
}

.coupon {
  @include card.container;
  overflow: hidden;

  @include viewport.mobile {
    .body {
      display: grid;
      grid-template-columns: 1fr min-content;
      grid-template-rows: min-content 1fr min-content;
      grid-template-areas:
        'a a'
        'b c'
        'd d';

      .name {
        @include typography.h2;
        grid-area: b;
        align-self: flex-start;
      }

      .code {
        grid-area: c;
        @include typography.h2;
        color: var(--color-secondary);
        align-self: start;
      }

      .description {
        @include typography.h6;
        font-weight: 400;
        font-size: 12px;
        grid-area: d;
        align-self: flex-start;
        padding-bottom: 10px;
      }

      $padding-horizontal: 12px;
      $curve-padding: 6px;

      .name,
      .code,
      .description {
        padding-left: $padding-horizontal + $curve-padding;
        padding-right: $padding-horizontal + $curve-padding;
      }

      .name,
      .code {
        padding-top: 12px;
      }

      .description {
        padding-bottom: 15px;
      }
    }
  }
  @include viewport.tablet {
    .body {
      // padding: 12px;
      @include card.content;
      display: grid;
      grid-template-columns: 1fr min-content;
      grid-template-rows: min-content 1fr min-content;
      grid-template-areas:
        'a d'
        'b d'
        'c d';

      .name {
        @include typography.h2;
        grid-area: a;
        align-self: flex-start;
      }

      .description {
        @include typography.h6;
        font-weight: 400;
        font-size: 12px;
        grid-area: b;
        align-self: flex-start;
      }

      .code {
        grid-area: c;
        @include typography.h2;
        color: var(--color-secondary);
        align-self: flex-end;
        margin-top: 4px;
      }
    }
  }

  .action {
    grid-area: e;
    @include card.footer;
    padding: 12px 18px;
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      margin-right: 10px;
      cursor: pointer;
    }
    span {
      font-family: Fira Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 25px;
      color: #426ed9;
      cursor: pointer;
    }
  }
}
