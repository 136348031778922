@use '../common/gap';

.uber-eats-menu-basic-item-spec-modifiers-view-widget {
  @include gap.vertical(32px);
  margin-top: 16px;

  > .modifier-spec {
    > .label {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }

    > .options {
      @include gap.vertical(8px);
      margin-top: 16px;

      > .modifier-option {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
