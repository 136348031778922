@use "../card"as card;

.weekly-hours-edit-widget {
  @include card.container;
  padding: 15px;

  .daily-hours-and-available-all-day-widget {
    .daily-hours-edit-widget {
      margin: 0 auto;
      display: grid;
      grid-template-areas:
        'label action'
        'hours ';

      .action {
        @include card.round-button;
        justify-items: center;
      }

      .hours {
        display: grid;
        grid-template-columns: 2fr min-content;
        grid-gap: 10px 15px;
        align-items: center;
        margin-bottom: 20px;

        .switch {
          width: max-content;
          display: grid;
          grid-gap: 0 15px;
        }
        .day-interval {
          display: grid;
          grid-template-columns: 2fr 2fr;
          grid-gap: 15px;
          align-items: center;
        }
      }
    }

    .switch {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
