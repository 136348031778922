@use '../colors';

.backoffice {
  .pos-order-history-page {
    .header > .wrapper > .actions {
      .download-pos-orders {
        background-color: colors.$blue-600 !important;
        color: colors.$white !important;
        height: min-content;
        right: 0;
        top: 20px;
        border-radius: 5px;
        text-decoration: none;
        font-size: 14px;
        line-height: 21px;
        padding: 10px 24px;
      }
    }
    
    .body {
      > .pos-order-history-widget {
        > .store-sales-report-link {
          color: colors.$blue-gray-500;
          margin-top: 20px;
          margin-left: 5px;
          span {
            color: colors.$blue-700;
            text-decoration: underline;
            cursor: pointer;
          }
        }

        .pos-order-history-list-widget {
          .no-pos-orders,
          .no-results-found {
            color: colors.$blue-gray-500;
            margin-top: 15px;
            margin-left: 5px;
          }
        }

        .back-to-top-button {
          position: fixed;
          top: 50rem;
          right: 50px;
          padding: 13px;
          background-color: colors.$white;
          border-radius: 30px;

          @media (max-height: 844px) {
            top: calc(100% - 180px);
          }

          @media (min-height: 845px) {
            top: calc(100% - 180px);
          }
        }

        .end-of-list {
          text-align: center;
          margin-top: 20px;
          color: colors.$blue-gray-500;
        }

        .view-more-button {
          margin: auto;
          margin-top: 20px;
          background-color: colors.$blue-gray-700;
          color: colors.$white;
          padding: 15px 110px;
          border-radius: 5px;
        }
      }
    }
  }
}
