@use "../typography";
@use "../card";
@use "../buttons";
@use "../colors";

.kds-device-edit-widget {
  width: auto;

  .header {
    display: grid;
    grid-template-areas: "title close-button";
    padding: 20px;
    gap: 50px;

    .title {
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      grid-area: title;
      color: #171725;
    }

    .close-button {
      grid-area: close-button;
      padding-top: 5px;
      cursor: pointer;
    }
  }

  .form-items {
    display: grid;
    gap: 8px;
    padding: 16px 20px;

    .form-input {
      margin-top: 6px;
    }
  }

  .form-buttons {
    @include card.footer;
    margin-top: 0;

    button {
      padding: 9.5px 24px;
      display: inline;
      grid-gap: 20px;
      margin-right: 8px;
      box-shadow: none;
      @include typography.h3;
    }

    .submit-btn {
      color: colors.$white;

      &.disabled {
        color: colors.$white !important;
        background-color: colors.$blue-300 !important;
      }
    }

    .cancel-btn {
      color: colors.$blue-gray-900;
      background-color: colors.$blue-gray-100;
    }
  }
}
