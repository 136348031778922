@use "../colors";
@use "../typography";
@use "../viewport";
@use "../scrollbar";

.reports-page {
  > .header {
    .title {
      padding: 0;
    }
  }
}

.swal2-container {
  &.delete-category {
    .swal2-popup {
      border-radius: 20px;
      padding: 32px 24px;
    }

    .swal2-actions {
      flex-direction: column;

      button {
        border-radius: 4px;
        width: 70%;
      }
      .swal2-confirm {
        background-color: colors.$rose-800;
      }

      .swal2-cancel {
        background-color: transparent !important;
        color: colors.$blue-gray-700;
        border: 1px solid colors.$blue-gray-500;
      }
    }
  }
}
