@use "../card" as card;
@use "../typography" as typography;
@use "../buttons"as buttons;
@use "../form"as form;
@use "../viewport" as viewport;

.cloud-printer-settings-widget {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;

  .title {
    @include card.header();
    @include card.header-font();
    @include typography.h3;
  }

  form {
    padding-top: 20px;
    .inline-title > label {
      font-weight: 500;
    }
  }

  .document-edit-widget {
    @include card.container();
  }

  .sub-text {
    color: #696974;
    font-size: 12px;
    padding-left: 4px;
  }

  .form-buttons {
    @include card.footer;
    button {
      padding: 9.5px 32px;
    }
  }

  .form-item {
    padding: 0 18px;
  }
}
