@use "../card"as card;
@use "../constants"as constants;
@use "../form"as form;
@use "../buttons"as buttons;
@use "../viewport"as viewport;
@use "../colors" as colors;

$padding-horizontal: 15px;

.delivery-area-settings-widget {
  @include card.container;

  .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  form {
    @include card.content;
    margin-top: 20px;
  }

  .form-input {
    .delivery-area {
      @include card.container;
      @include card.stack;
      margin-bottom: 20px;

      .info {
        @include card.upper;
        @include form.inline;

        display: flex;
        flex-direction: column;

        @include viewport.desktop {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(4, auto);
          &.third-party {
            grid-template-areas:
              'name     delivery-charge'
              'third-party-delivery-fee third-party-delivery-fee'
              'polygon  switch'
              'polygon  min-order-value';
          }
          grid-template-areas:
            'name     delivery-charge'
            'polygon  switch'
            'polygon  min-order-value';
        }

        .name {
          grid-area: name;
        }

        .delivery-charge {
          grid-area: delivery-charge;
        }

        .polygon {
          grid-area: polygon;

          > .meta {
            > .error {
              color: colors.$rose-500;
              font-weight: 400;
            }
          }

          .invalid {
            textarea {
              border: none;
              outline: 1px solid colors.$rose-500 !important;
            }
          }
        }

        .switch {
          grid-area: switch;
          &.third-party-delivery-fee {
            grid-area: third-party-delivery-fee;
          }
        }

        .min-order-value {
          grid-area: min-order-value;
        }
      }
    }

    .actions {
      @include card.lower;
      @include card.round-buttons;
      .action {
        @include card.round-button;
      }
    }
  }

  .delivery-areas {
    button {
      @include buttons.primary-transparent;
    }
  }
}

.delivery-areas-form-item > label {
  @include form.top-align-label();
}
