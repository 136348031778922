@use "../colors";
@use "../buttons";

.delete-custom-payment-method {
  background-color: rgba(12, 13, 52, 0.2) !important;

  .swal2-modal {
    background-color: colors.$white !important;
    border-radius: 20px !important;
    width: 340px !important;
    padding: 15px 0 !important;
    padding-bottom: 25px !important;

    .swal2-header {
      position: relative;
      padding: 10px 30px;
    }

    .swal2-content {
      white-space: pre-wrap;
      padding: 0 30px;
      margin: 0;
      color: colors.$blue-gray-500;
      font-size: 14px;
    }

    .swal2-title {
      justify-content: center;
      margin-top: 15px;
      width: 100%;
      color: colors.$blue-gray-900;
      font-size: 20px;
      line-height: 26px;
      margin: 0 !important;
    }

    .swal2-html-container {
      margin: 0 auto;
      position: relative;
      text-align: center;
    }

    .swal2-footer {
      margin: 0;
      padding: 24px 25px 0;
      font-size: 14px;
      border-top: none;
    }

    .swal2-actions {
      display: flex;
      gap: 15px;
      flex-direction: column;
      margin: 0;
      padding: 30px 30px 0;

      button {
        text-align: center !important;
        padding: 12px;
        text-transform: uppercase;
        width: 100%;
        margin: 0;
      }

      .confirm-button {
        @include buttons.primary;
        text-transform: capitalize;
        border-radius: 4px;
      }

      .cancel-button {
        @include buttons.cancel-button;
        border-radius: 4px;
        background: none !important;
        text-transform: capitalize;
      }
    }
  }
}
