@use '../colors';
@use '../common/elements';
@use '../common/gap';

.uber-eats-menu-item-spec-modifiers-widget {
  padding: 32px;

  color: colors.$blue-gray-900;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  > .close-button {
    margin-left: auto;
    @include elements.icon(13px);
    cursor: pointer;
  }

  > .header {
    padding-bottom: 14px;

    font-weight: 600;
    font-size: 24px;
    line-height: 28.8px;
  }
}
