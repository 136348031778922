@use '../colors';
@use '../viewport';
@use '../common/gap';

.uber-eats-integration-widget {
  padding-bottom: 40px;

  > .header {
    margin: 24px 0;
    display: grid;
    grid-template-areas:
      'logo title status'
      'logo sub-title sub-title';

    grid-template-columns: min-content 1fr max-content;
    gap: 8px 24px;

    .logo {
      grid-area: logo;
      height: 80px;
    }
    .title {
      grid-area: title;
      font-size: 34px;
      font-weight: 600;
      line-height: 40.8px;
    }
    .sub-title {
      grid-area: sub-title;
    }
    .status {
      grid-area: status;
      color: colors.$blue-gray-700;
      &.is-enabled {
        color: colors.$green-600;
      }
    }
  }

  > .body {
    > .sub-header {
      font-size: 24px;
      font-weight: 600;
    }

    .banner-widget,
    .uber-eats-integration-setup-item-widget {
      margin-top: 24px;
    }
  }

  > .super-admin-settings {
    margin-top: 56px;
    @include gap.vertical(24px);

    > .label {
      display: flex;
      align-items: center;
      white-space: nowrap;

      color: colors.$blue-gray-500;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;

      > .line {
        flex-grow: 1;
        height: 1px;
        margin-left: 12px;
        background-color: colors.$blue-gray-100;
      }
    }
  }

  .uber-eats-integration-action-widget {
    margin-top: 32px;
  }

  @include viewport.mobile {
    > .header {
      grid-template-areas:
        'logo title'
        'logo status'
        'sub-title sub-title';
      grid-template-columns: min-content 1fr;
      align-items: center;
    }
  }
}
