@use '../colors';

.banner-widget {
  padding: 24px 24px 0;
  font-size: 16px;
  border-radius: 6px;
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  line-height: 24px;

  > .icon {
    margin-right: 8px;
  }

  > .content {
    > .title {
      font-size: 18px;
      font-weight: 500;
    }
  }

  > .close-button {
    margin-left: 8px;
    cursor: pointer;
  }

  &.success {
    border: 1px solid colors.$green-900;
    background-color: colors.$green-100;
    color: colors.$green-900;
  }

  &.info {
    border: 1px solid colors.$blue-800;
    background-color: colors.$blue-50;
    color: colors.$blue-800;
  }

  &.warning {
    border: 1px solid colors.$yellow-500;
    background-color: colors.$yellow-100;
    color: colors.$yellow-800;
  }

  &.disallowed {
    border: 1px solid colors.$rose-500;
    background-color: colors.$rose-100;
    color: colors.$rose-800;
  }
}
