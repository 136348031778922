@use "../card" as card;
@use "./base-item-edit-widget" as base-item;

.custom-item-edit-widget {
  @include card.container;

  form {
    @include card.content;
    margin-top: 20px;

    .allergen-info {
      @include base-item.allergen-info();
    }
  }
}
