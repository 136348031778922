@use "../viewport"as viewport;
@use "../card"as card;

.printer-page {
  .printer-edit-widget {
    @include card.container;
  }

  form {
    @include card.content;
    margin-top: 20px;
  }

  .body {
    display: grid;
    grid-gap: 20px;
    @include viewport.tablet {
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }
  }
}
