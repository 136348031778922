@use "../card"as card;
@use "../buttons"as buttons;
@use "../viewport"as viewport;
@use '../colors'as colors;

.online-order-payment-settings-widget {
  display: grid;
  @include viewport.tablet {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }

  form {
    @include card.container;
    .form-items {
      .form-item:first-child {
        border-radius: 10px 10px 0px 0px;
      }

      .switch {
        padding: 0px 20px;
      }
    }

    .form-buttons {
      background-color: colors.$blue-gray-50;
      padding: 20px;
      border-radius: 0px 0px 10px 10px;
    }
  }
}
