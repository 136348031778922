@use '../colors';
@use '../viewport';

.uber-eats-unauthorised-alert {
  .actions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-top: 24px;

    button {
      font-size: 16px;
    }

    .cancel-button {
      background: none;
      box-shadow: none;
      outline: 1px solid colors.$blue-gray-500;
      border-radius: 4px;
      color: colors.$blue-gray-700;
    }
  }

  @include viewport.mobile {
    width: 100%;

    .title {
      font-size: 18px;
    }

    .actions {
      display: flex;
      flex-direction: column;
    }
  }
}
