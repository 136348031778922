@use "../buttons" as buttons;

.website-order-view-page {
  .email-invoice-button {
    @include buttons.email-invoice-button();

    margin-left: auto;
    padding: 9.5px 32px !important;
    .label {
      font-size: 14px;
      line-height: 21px;
    }
  }
}
