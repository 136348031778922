@use "../card";
@use "../typography";
@use "../colors";
@use "../viewport";

.kds-time-settings-edit-widget {
  @include card.container;

  .title {
    padding: 14px 24px;
    @include typography.h3;
    border-bottom: 1px solid colors.$blue-gray-100;
  }

  form {
    @include card.content;
    padding: 0;

    .form-items {
      .form-item {
        display: grid;
        grid-template-areas:
          'label dropdown'
          'help-text dropdown';
        column-gap: 24px;
        align-items: center;
        padding: 24px;

        @include viewport.mobile {
          grid-template-areas:
            'label'
            'help-text'
            'dropdown';
        }

        label {
          @include typography.h2;
          font-weight: 500;
          grid-area: label;

          &::before {
            content: '';
            display: inline-block;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: colors.$yellow-600;
            margin-right: 8px;
          }
        }

        .meta {
          .help {
            @include typography.h3;
            font-weight: 400;
            color: colors.$blue-gray-400;
            grid-area: help-text;
          }

          @include viewport.mobile {
            padding: 3px 0 24px 0;
          }
        }

        .form-input {
          grid-area: dropdown;

          .options {
            width: 100%;
            padding: 20px 0;
            grid-gap: 0;

            .option {
              padding: 12px 14px;
              position: relative;

              .icon {
                display: none;
              }

              &.selected {
                background-color: colors.$teal-50;

                &::after {
                  content: '';
                  position: absolute;
                  width: 14px;
                  height: 14px;
                  right: 14px;
                  top: 50%;
                  transform: translateY(-50%);
                  background-image: url('../images/icon-check-teal.svg');
                }
              }
            }
          }
        }
      }
    }
  }

  @include viewport.tablet {
    form.responsive {
      .form-items > .form-item {
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-row-gap: 8px;

        label {
          grid-row: 1;
          grid-column: 1 / 2;
          text-align: left;
        }

        .form-input {
          grid-row: 1 / 3;
          grid-column: 2 / 3;
        }

        .meta {
          grid-row: 2;
          grid-column: 1;
        }
      }
    }
  }
}
