@use "../card"as card;
@use "../constants"as constants;
@use "../scrollbar"as scrollbar;
@use "../typography"as typography;
@use "../viewport"as viewport;

.online-ordering {
  nav {
    grid-area: nav;
    display: flex;
    overflow-y: scroll;
    background-color: white;
    // background-color: hsla(240, 120%, 12%, 1);
    box-shadow: 0px 0px 3px rgba(104, 122, 155, 0.25);
    flex-direction: column;
    z-index: 999;
    max-width: 70%;
    padding-bottom: 10px;

    @include scrollbar.hide;

    .title {
      text-transform: uppercase;
      color: #92929d;
      font-size: 11.5px;
      font-weight: 400;
      letter-spacing: 1px;
      margin-top: 20px;
      margin-bottom: 5px;
      margin-left: constants.$horizontal-gap;
    }

    .link {
      $bleed-width: 4px;
      display: flex;
      flex-direction: row;
      margin: 5px 0;
      padding: 8px 40px;
      padding-left: constants.$horizontal-gap - $bleed-width;
      // border-left: $bleed-width solid var(--card-bg);
      align-items: center;
      cursor: pointer;

      .icon {
        color: #92929d;
        font-size: 21px;
        margin-right: 16px;
        // color: white;
      }

      .label {
        @include typography.h4;
        display: inline-block;
        white-space: nowrap;
        // color: white;
      }

      &.selected {
        border-left: $bleed-width solid var(--color-primary);

        .icon,
        .label {
          color: var(--color-primary);
        }
      }
      &.logout {
        background: #ff974a;
        box-shadow: inset -1px 0px 0px #e2e2ea;
        border-bottom-left-radius: constants.$border-radius;
        border-bottom-right-radius: constants.$border-radius;
        margin: 0;
      }
    }
  }

  nav {
    position: fixed;
    top: 0;
    right: 0;
    transform: translateX(120%);
    transition: transform 300ms;
    margin: 20px;
    border-radius: constants.$border-radius;

    &.show {
      transform: none;
    }
  }

  .overlay.show {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 998;
  }

  .profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0px;
    margin: 0 20px;
    text-align: center;
    border-bottom: constants.$line;

    .name {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #171725;
      // color: white;
      margin-top: 10px;
    }

    .message {
      font-family: Poppins;
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.1px;
      color: #92929d;
    }
  }

  .contact {
    margin: 0 20px;
    padding: 25px 0;
    border-bottom: constants.$line;
    // padding-left: 16px;
    // padding-right: 16px;
    display: grid;
    grid-gap: 20px;

    .item {
      display: grid;
      grid-gap: 2px;
      margin-left: 5px;

      label {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 18px;
        letter-spacing: 0.8px;
        text-transform: uppercase;
        color: #92929d;
      }
      .value {
        display: block;
        font-family: Poppins;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 21px;
        letter-spacing: 0.1px;
        color: #171725;
        // color: white;
      }
    }
  }

  .avatar {
    width: 60px;
    height: 60px;
    img {
      border-radius: constants.$border-radius;
      border: 3px solid #f1f1f5;
      width: 54px;
    }
  }
}
