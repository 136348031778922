@use '../colors';

.uber-eats-login-button {
  background: colors.$blue-600;
  color: colors.$white;
  padding: 9.5px 24px;
  border-radius: 4px;
  white-space: nowrap;
  height: min-content;
  font-size: 14px;
  font-weight: 500;

  .icon {
    font-size: 20px;
    margin: 0;
  }
}
