@use "../viewport"as viewport;

.backoffice-menu-page {
  form {
    margin-bottom: 25px;
  }

  @include viewport.tablet {
    form {
      max-width: 300px;
    }
  }
}
