@use '../card';
@use '../colors';
@use "../viewport";

.add-on-list-item-widget {
  @include card.container;
  display: grid;
  grid-template-rows: 1fr min-content;

  @include viewport.desktop {
    max-width: 550px;
  }

  .info {
    @include card.content;
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-template-rows: max-content max-content 1fr;
    grid-template-areas:
      'logo name'
      'logo status'
      'description description';
    gap: 8px 16px;

    .logo {
      grid-area: logo;
      width: 64px;
    }

    .label {
      grid-area: name;
      font-size: 18px;
      font-weight: 500;
    }

    .status {
      grid-area: status;
      color: colors.$blue-gray-700;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.035px;

      &.is-enabled {
        color: colors.$green-600;
      }
    }

    .description {
      grid-area: description;
      margin-top: 10px;
      color: colors.$blue-gray-500;
    }
  }

  .actions {
    @include card.footer;
    background-color: colors.$blue-gray-50;
    flex-grow: 1;

    button {
      border: 1px solid colors.$blue-400;
      color: colors.$blue-600;
      border-radius: 4px;
      background-color: transparent;

      &.hide {
        visibility: hidden;
      }
    }
  }
}
