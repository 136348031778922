@use "../card";
@use "../colors";
@use "../constants";
@use "../modal";
@use "../scrollbar";
@use '../viewport';
@use "../buttons";

$padding-horizontal: 20px;

@mixin title {
  .icon {
    margin-right: 12px;
    font-size: 24px;
  }

  label {
    font-family: Fira Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    color: #293340;
  }
}

@mixin section {
  border-bottom: constants.$line;
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    @include title();
  }
}

@mixin section-nvpair {
  padding: 16px $padding-horizontal;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@mixin section-head-body {
  padding: 16px $padding-horizontal;
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-row-gap: 8px;
  grid-template-areas:
    'a b'
    'c d';

  @include title();

  i {
    grid-area: a;
  }
  label {
    grid-area: b;
    align-self: center;
  }
  .value {
    grid-area: d;
  }
}

@mixin amount {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px $padding-horizontal;

  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #565c65;
}

.pos-order-view-widget {
  @include card.container();
  max-height: 65vh;
  overflow: hidden auto;
  scrollbar-width: none;

  .header {
    @include title;
    position: fixed;
    display: flex;
    background-color: white;
    justify-content: space-between;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 14px 14px 0 0;
    border-bottom: constants.$line-dashed;

    .title {
      margin-left: 12px;

      label {
        font-size: 20px;
      }
    }

    .close-button {
      @include modal.close-button;
    }
  }

  .body {
    margin-top: 70px;
  }

  .order-comments,
  .collection-type,
  .collection-time,
  .table-name,
  .delivery-address,
  .payment-method,
  .payment,
  .customer-info,
  .transaction-fee-info,
  .items {
    @include section;
  }

  .table-name,
  .collection-type,
  .collection-time,
  .payment-method,
  .payment-status {
    @include section-nvpair();
  }

  .delivery-address,
  .order-comments,
  .dropoff-notes {
    @include section-head-body();
  }

  .order-comments {
    white-space: pre-wrap;
  }

  .items {
    margin: 0px 5px;

    > .item-summary-view-widget {
      padding: 0 $padding-horizontal 12px $padding-horizontal;
      border-bottom: constants.$line-dashed;
      margin-bottom: 20px;

      &:last-child {
        border-bottom: none;
        margin-bottom: 0px;
      }
    }
  }

  .amounts {
    .discount,
    .custom-surcharge,
    .public-holiday-surcharge,
    .delivery-fee,
    .grand-total,
    .sub-total,
    .gst-amount {
      @include amount;
    }

    .grand-total {
      padding-top: 14px;
      padding-bottom: 14px;
      font-size: 15px;
      font-family: Fira Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #426ed9;
      border-top: constants.$line-dashed;
    }

    .gst-amount {
      border-top: constants.$line-dashed;
    }
  }

  .payment {
    @include section-head-body;
    grid-template-areas:
      'a b c'
      'd e e';

    cursor: pointer;

    .icon {
      grid-area: a;
    }
    label {
      grid-area: b;
    }

    .value {
      grid-area: c;
    }
    .status {
      grid-area: e;
      font-size: 12px;
      color: #444444;
    }

    .payment-card-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-self: end;
      .wallet,
      .brand {
        height: 25px;
        margin-right: 5px;
      }
    }
  }

  .payment-summary {
    @include section;

    .payment-summary-header {
      @include section-nvpair();
    }

    .split-payments {
      padding: 0 28px 16px 28px;
    }

    .split-payment {
      display: grid;
      grid-template-areas:
        'payment-name     payment-status    payment-amount'
        'payment-items    payment-items     payment-items';
      row-gap: 4px;
      padding-inline: 12px;
      align-items: center;
      margin-top: 12px;

      &:first-child {
        margin-top: 0;
      }

      > .name {
        grid-area: payment-name;
        font-weight: 500;
        font-size: 16px;
      }

      > .status {
        grid-area: payment-status;
        text-align: right;
        font-weight: 500;
        font-size: 16px;
      }

      > .amount {
        grid-area: payment-amount;
        justify-self: end;

        font-weight: 500;
        font-size: 16px;
        color: colors.$blue-gray-900;
      }

      .payment-items {
        grid-area: payment-items;
        margin-left: 40px;

        > .item {
          display: list-item;
          margin-top: 4px;

          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }

  .customer-info {
    @include section-head-body;
    grid-template-areas:
      'a b c'
      '. . mobile'
      'd e e';

    .icon {
      grid-area: a;
    }
    label {
      grid-area: b;
    }

    .customer-name {
      text-align: end;
      grid-area: c;
      font-weight: 500;
    }
    .customer-mobile {
      text-align: end;
      grid-area: mobile;
      font-weight: 500;
    }
  }

  .transaction-fee-info {
    @include section-head-body;
    grid-template-areas:
      'icon label amount'
      '.    .     status'
      'd    e     e';

    .icon {
      grid-area: icon;
    }
    label {
      grid-area: label;
    }

    .amount {
      text-align: end;
      grid-area: amount;
      font-weight: 500;
    }
    .status {
      text-align: end;
      grid-area: status;
      color: #426ed9;
      font-weight: 500;
    }
  }

  .footer {
    bottom: 0;
    position: sticky;
    background: #fafafb;
    padding: 24px 16px;
    .email-invoice-button {
      @include buttons.email-invoice-button();

      margin-left: auto;
      padding: 7px 16px;
      .label {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}

@include viewport.tablet {
  .pos-order-view-widget {
    width: 40vw;

    .header {
      width: 40vw;
    }
  }
}

@include viewport.mobile {
  .ReactModalPortal:has(.pos-order-view-widget) {
    .ReactModal__Content.ReactModal__Content--after-open {
      position: absolute !important;
      width: unset !important;

      .modal {
        max-width: unset;
        max-height: unset;
      }
    }
  }

  .pos-order-view-widget {
    max-height: 90vh;
    .header {
      width: 100%;
    }
  }
}
