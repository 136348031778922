@use '../card'as card;
@use '../buttons'as buttons;
@use '../constants'as constants;
@use '../typography'as typography;
@use '../viewport'as viewport;
@use './modifiers'as modifiers;

@mixin summary-text {
  font-size: 12px;
  font-weight: 300;
  color: #555555;
  pre {
    font-family: Poppins;
    margin: 0;
    // font-style: normal;
    // letter-spacing: 0.1px;
    // font-size: 12px;
    // color: #8d92a3;
    // font-weight: 400;
  }
}

.deal-page {
  .deal-content {
    display: grid;
    grid-gap: modifiers.$grid-gap;
  }
  .done-button {
    @include buttons.footer-button;
    margin-top: 10px;
  }

  .choose-pizza {
    display: grid;
    grid-gap: 20px;

    .pizza-spec {
      @include card.container;
      @include card.content;
      overflow: hidden;

      .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .name {
        @include typography.h2;
      }

      .price {
        @include typography.h2;
        color: var(--color-secondary);
      }

      .description {
        @include typography.h6;
        font-weight: 400;
        font-size: 12px;
        margin-bottom: 5px;
      }
    }
  }
  .customize-pizza {
    @include modifiers.modifiers;
  }
}

.deal-pizzas {
  @include modifiers.modifiers;

  .deal-pizza {
    @include card.container;

    .header {
      @include card.header;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .title {
        @include modifiers.title;
      }
    }

    .body {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    button {
      @include buttons.badge-button;
    }

    .half-and-half {
      .deal-pizza-entry:first-child {
        margin-bottom: 3px;
        padding-bottom: 18px;
        border-bottom: 1px dashed #e9ecf3;
      }
    }

    .deal-pizza-entry {
      @include card.content;

      .choose-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .choose-pizza-button {
        @include buttons.primary-transparent;
      }

      .customize-pizza-button {
        @include buttons.success-transparent;
      }

      .summary {
        margin: 15px 5px 0 5px;
        @include summary-text();

        pre {
          overflow-x: auto;
          white-space: pre-wrap;
          word-wrap: break-word;
        }
      }
    }
  }
}

.deal-items {
  @include modifiers.modifiers;

  .deal-item {
    @include card.container;
    overflow: hidden;

    .header {
      @include card.header;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .name {
        @include modifiers.title;
      }
    }

    .summary {
      @include card.content();
      @include summary-text();
    }

    button {
      @include buttons.badge-button;
    }

    .modifiers {
      grid-gap: 0 !important;
    }
    .modifier {
      box-shadow: none !important;
      border-radius: 0 !important;
      border: none !important;

      .header {
        margin: 0;
        border: 0 !important;
        @include card.header;
        background-color: #f9f9f9;
        .title {
          font-size: 0.8rem;
          font-weight: 300;
          color: var(--fg);
        }
      }
    }
  }
}
