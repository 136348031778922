@use "../constants";
@use '../colors';
@use '../typography';

.google-review-info-pop-up-widget {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  max-width: 490px;

  .illustration {
    width: 391px;
    height: 247px;
  } 

  .pop-up-display-indicators {
    display: flex;
    align-items: center;
    margin-top: 24px;

    > div:nth-child(2) {
      margin: 0 12px;
    }

    .indicator {
      width: 12px;
      height: 12px;
      background-color: colors.$blue-gray-100;
      border-radius: 100%;

      &.active {
        background-color: colors.$blue-500;
      }
    }
  }

  .title {
    @include typography.h1;
    padding-top: 24px;
  }

  .description {
    @include typography.h2;
    font-weight: 500;
    color: colors.$blue-gray-500;
    text-align: center;
    padding-top: 16px;
  }

  button {
    padding: 14px 32px;
    width:  88%;
    font-size: 16px;
    margin-top: 20px;
  }

  &.customer-feedback-info {
    .description {
      margin: 0 50px;
    }
  }

  &.review-link-info {
    .description {
      margin: 0 5px;
    }
  }
}