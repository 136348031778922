@use "../colors";

.item-summary-view-widget {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas:
    'quantity name         price       '
    '.        discount     discount    '
    '.        extra-charge extra-charge'
    '.        description  .           '
    '.        comments     comments    ';
  column-gap: 4px;
  color: colors.$blue-gray-500;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  > .quantity {
    grid-area: quantity;
    color: colors.$blue-gray-900;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  > .name {
    grid-area: name;
    color: colors.$blue-gray-900;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  > .price {
    grid-area: price;
    color: colors.$blue-gray-900;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  > .discount {
    grid-area: discount;
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
  }

  > .extra-charge {
    grid-area: extra-charge;
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
  }

  > .comments {
    grid-area: comments;
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
  }

  > .item-description-view-widget {
    grid-area: description;
    margin-top: 8px;
  }
}
