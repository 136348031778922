#code-editor-widget {
  height: 100%;
  min-height: 70vh;
  width: inherit;
  border: 1px solid rgba(0, 0, 0, 0.2);

  .monaco-editor {
    width: inherit !important;
    height: 100% !important;

    .overflow-guard {
      width: 100% !important;
      height: 100% !important;
    }

    .lines-content {
      width: 90% !important;
    }

    .view-lines {
      > div {
        height: 19px;
      }
    }
  }

  .minimap {
    left: 90% !important;
  }
}
