@use "../colors";
@use "../typography";
@use "../viewport";

.category-sales-edit-widget {
  box-sizing: border-box;
  background-color: transparent;

  .header {
    padding: 32px;
    padding-bottom: 0;
    position: relative;

    .title {
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
    }

    img {
      position: absolute;
      cursor: pointer;
      // adjust according to the header padding
      right: 32px;
      top: 32px;
    }

    .help-text {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.001em;
      color: colors.$blue-gray-500;
    }
  }

  .category-name-input {
    padding: 25px 32px;

    > .text-input {
      padding: 12px 14px;
      border: 1px solid colors.$blue-gray-100;
      border-radius: 8px;
      box-shadow: 0px 1px 2px 0px #{colors.$blue-gray-900}0d;
      background-color: colors.$white;
      margin-top: 6px;

      > input[type='text'] {
        font-size: 16px;
        font-weight: 400;
        border: none;
        width: 80%;
        outline: none;
        letter-spacing: 0.5%;

        &::placeholder {
          color: colors.$blue-gray-200;
        }
      }
    }
  }

  .actions {
    padding: 24px;
    display: flex;
    background-color: colors.$material-gray-50;

    > button {
      border-radius: 4px;
      line-height: 18px;
      text-wrap: nowrap;

      @include viewport.mobile {
        font-size: 12px;
        padding: 7px 16px;
        margin: 0;
      }

      &:disabled {
        background-color: colors.$blue-300 !important;
        color: colors.$white !important;
      }
    }

    > .delete {
      margin-right: auto;
      background-color: transparent;
      border: none;
      box-shadow: none;
      color: colors.$rose-800;
      text-decoration: underline;
      padding: 0;

      &.new-category {
        visibility: hidden;
        pointer-events: none;
      }
    }

    > :last-child {
      margin-left: 16px;
    }
  }

  .item-selector {
    padding: 32px;
    padding-top: 0;
    display: flex;

    > .arrow-icons {
      padding: 32px;
      display: flex;
      flex-direction: column;
      @include viewport.mobile {
        padding: 12px;
        justify-content: center;
        flex-direction: row;
        align-items: center;
      }

      cursor: pointer;
      img {
        width: 32px;
        display: block;
        margin-bottom: 24px;
        opacity: 20%;
        @include viewport.mobile {
          transform: rotate(90deg);
          margin-bottom: 0;
          margin: 0 24px;
        }

        &.active {
          opacity: 100%;
        }
      }
    }

    .label {
      @include typography.h4;
      font-size: 14px;
      margin-bottom: 8px;

      > span {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.004em;
        margin-left: 12px;
      }
    }

    .unassigned-items {
      width: 100%;
      > .label {
        color: colors.$blue-gray-500;
        font-weight: 400;
      }
    }

    .category-items {
      width: 100%;
      border-color: colors.$teal-500;

      .item-selected-count {
        color: colors.$blue-gray-500;
      }
    }
  }
}

@include viewport.mobile {
  .category-sales-edit-widget {
    .header {
      padding: 20px;
    }

    .category-name-input {
      padding: 0 20px;
    }

    .item-selector {
      display: flex;
      padding: 20px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

.spinner-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
