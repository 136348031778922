@use '../colors';
@use "../viewport";

.category-sales-report-settings-widget {
  box-sizing: border-box;
  padding: 0;

  .actions {
    display: flex;
    justify-content: space-between;
    width: 100%;

    > .text-input {
      font-size: 16px;
      font-weight: 400;
      padding: 8px 14px;
      border: 1px solid colors.$blue-gray-100;
      border-radius: 8px;
      box-shadow: 0px 1px 2px 0px #{colors.$blue-gray-900}0d;
      background-color: colors.$white;
      @include viewport.mobile {
        max-width: 40%;
        padding: 8px 14px;
      }

      > input[type='text'] {
        padding-left: 30px;
        max-width: 222px;
        border: none;
        outline: none;
        background-color: transparent;
        background-image: url('../images/search-icon-gray.svg');
        background-repeat: no-repeat;
        background-size: 16px;

        &::placeholder {
          color: colors.$blue-gray-200;
        }
      }
    }

    > button {
      font-size: 14px;
      border-radius: 4px;
      padding: 9.5px 24px;
      @include viewport.mobile {
        max-width: 45%;
        padding: 6px 12px;
        font-size: 12px;
      }
    }
  }

  .no-categories {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: colors.$blue-gray-200;
    margin-top: 24px;
  }

  .category-sales-list {
    width: 100%;
    margin: 24px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;

    @include viewport.mobile {
      display: block;
    }
  }
}
