@use "../constants" as constants;
@use "../card" as card;
@use "../typography" as typography;
@use "../buttons"as buttons;

.pos-surcharge-settings-widget {
  .title {
    @include card.header();
    @include card.header-font();
    @include typography.h2;
  }

  @include card.container();

  .actions {
    @include card.footer();
    @include buttons.button-group();
    background-color: #fff;
    button {
      margin-right: 10px;
      @include buttons.primary-transparent;
    }
  }

  .empty-message {
    padding: 20px;
  }
}

.pos-surcharge {
  @include card.container;
  margin: 20px;

  display: grid;
  overflow: hidden;

  .fields {
    display: flex;
    justify-content: space-between;
    padding: 15px;

    .name {
      @include typography.h3;
    }
    .value {
      @include typography.p;
    }
  }

  .actions {
    @include card.footer();
    display: flex;
    border-top: constants.$line;

    button {
      width: 100px;
    }
    .action {
      @include card.round-button;
      cursor: pointer;
    }
  }
}
