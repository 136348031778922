@use "../constants" as constants;
@use "../card" as card;
@use "../typography" as typography;
@use "../buttons"as buttons;
@use "../colors" as colors;

.pos-lock-screen-settings-edit-widget {
  @include card.container();

  .title {
    @include card.header();
    @include card.header-font();
    @include typography.h2;
    margin-bottom: 15px
  }

  form {
    @include card.content;
    padding: 0;

    .form-item {
      padding: 0 18px;
    }

    .form-items {
      display: grid;

      .form-item {
        display: grid;
        grid-template-columns: 45% 55%;
        align-items: center;

        .help {
          white-space: pre-wrap;
          padding-top: 6px;
        }

        .form-input {
          min-width: 80%;
          justify-self: end;

          .lock-screen-timeout-never {
            color: #{colors.$blue-gray-500};
          }

          .lock-screen-timeout-minutes {
            color: #{colors.$blue-600};
          }

          .options {
            width: max-content;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .form-buttons {
    @include card.footer;
  }
}
