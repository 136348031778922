@use "../colors" as colors;

.table-booking-activity-logs-list-item-widget {
  display: grid;
  gap: 8px;

  .field {
    display: flex;
    gap: 6px;
  }

  .activity-log-time {
    color: colors.$blue-gray-900;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
  }
  .activity-log-action {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: colors.$blue-gray-700;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .lower-case {
    text-transform: lowercase;
  }

  .removed {
    display: none;
  }

  .value {
    color: colors.$blue-gray-900;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
  }
}
