@use "../card"as card;
@use "../form"as form;
@use "../constants"as constants;
@use "../typography"as typography;
@use "../buttons"as buttons;
@use "../utils"as utils;
@use "../selection_list"as selection_list;

$padding-horizontal: 20px;

@mixin section-body {
  padding: 5px $padding-horizontal 10px;
}

@mixin section-footer {
  display: grid;
  grid-template-columns: min-content;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  grid-gap: 10px;
  margin-top: 20px;
  margin-bottom: 15px;
  align-items: center;

  @include buttons.compact();

  button {
    img {
      margin-right: 10px;
    }

    span {
      white-space: nowrap;
      color: #426ed9;
    }
  }
  a {
    font-weight: 500;
    font-size: 0.75rem;
    text-decoration: none;
    margin-left: 5px;
    color: var(--color-secondary);
    white-space: nowrap;
  }
}

.online-ordering {
  .cart {
    .alert {
      margin: 10px 0;
      box-shadow: none;
    }

    form {
      padding-top: 0 !important;
      .form-items {
        grid-gap: 10px;
      }
    }

    .sections {
      @include card.container;
      margin-bottom: 30px;
    }

    .section {
      border-bottom: constants.$line;

      > .header {
        padding: 16px $padding-horizontal;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .title {
          display: flex;
          flex-direction: row;
          align-items: center;

          i {
            margin-right: 12px;
            font-size: 24px;
          }

          span {
            font-family: Fira Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            letter-spacing: 0.7px;
            text-transform: uppercase;
            color: #293340;
          }
        }

        .title-add-action {
          display: flex;
          flex-direction: row;
          align-items: center;

          img {
            margin-right: 8px;
          }

          span {
            font-family: Fira Sans;
            font-style: normal;
            font-weight: 500;
            font-size: 12.5px;
            text-align: right;
            color: #426ed9;
          }
        }

        .title-value {
          font-family: Fira Sans;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          text-align: right;
          color: #426ed9;
          &.close {
            color: var(--color-secondary);
          }
        }
      }
    }

    .items {
      .cart-item {
        padding: 0 $padding-horizontal 12px $padding-horizontal;
        border-bottom: constants.$line-dashed;
        margin-bottom: 20px;

        &:last-child {
          border-bottom: none;
          margin-bottom: 0px;
        }

        > .header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .name {
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #000521;
          }
          .price {
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: #fa7268;
          }
        }
        > .footer {
          display: grid;
          grid-template-columns: 1fr min-content;
          justify-content: space-between;
          align-items: flex-end;
          margin: 8px 0px;
        }

        .summary {
          align-self: start;
          margin-right: 30px;

          pre {
            overflow-x: auto;
            white-space: pre-wrap;
            word-wrap: break-word;
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            color: #8d92a3;
            margin: 0 !important;
          }
        }

        .quantity {
          display: flex;
          flex-direction: row;
          align-items: center;

          .number {
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            color: #000521;
          }

          .icon {
            cursor: pointer;
          }

          .dec {
            padding-right: 10px;
          }

          .inc {
            padding-left: 10px;
          }

          .icon {
            color: #8d92a3;
            font-size: 24px;
          }
        }
      }
    }

    .amounts {
      padding: 10px $padding-horizontal;
      .amount {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0;

        font-family: Fira Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #565c65;
      }
      .grand-total {
        font-weight: bold;
        font-size: 16px;
        margin-top: 10px;
      }
    }

    button.place-order {
      width: 100%;
      font-size: 14px;
      @include buttons.place-order-button;
      padding: 20px;
      margin-bottom: 10px;
    }

    .collection-times-wrapper {
      display: grid;
      grid-template-columns: 1fr;
      overflow: hidden;
      padding: 10px $padding-horizontal;
      margin-bottom: 15px;
    }

    .collection-times {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: min-content;
      grid-auto-columns: min-content;
      grid-gap: 10px;
      overflow-x: scroll;

      .collection-time {
        padding: 12px;
        white-space: nowrap;
        border: 1px solid #426ed9;
        box-sizing: border-box;
        border-radius: 8px;
        font-family: Fira Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
      }
    }

    .add-delivery-address {
      @include section-body();

      .delivery-suburbs {
        display: grid;
        margin-top: 10px;
        margin-bottom: 5px;

        .delivery-suburb {
          @include selection_list.item();
          grid-template-columns: min-content 1fr min-content;
          .charge {
            white-space: nowrap;
          }
        }
      }
    }

    .saved-addresses {
      @include section-body();
      .saved-address {
        @include selection_list.item();
        grid-template-columns: min-content 1fr min-content min-content !important;
      }

      .radio-button {
        top: 10px;
        position: relative;
      }

      .address {
        padding-top: 10px;
      }

      .icon {
        color: #8d92a3;
        font-size: 24px;
        cursor: pointer;
      }
    }

    .saved-comments {
      @include section-body();
      .saved-comment {
        @include selection_list.item();
        grid-template-columns: min-content 1fr min-content min-content !important;
      }

      .radio-button {
        top: 10px;
        position: relative;
      }

      .comment {
        padding-top: 10px;
      }

      .icon {
        color: #8d92a3;
        font-size: 24px;
        cursor: pointer;
      }
    }

    .payment-types {
      @include section-body();
      .payment-type {
        @include selection_list.item();
      }
    }

    .suggested-coupon {
      padding: 5px 20px 10px;
    }

    .credential-already-in-use,
    .mobile-update-widget,
    .unlink-mobile-number,
    .add-credit-card,
    .add-comments,
    .add-coupon,
    .add-delivery-address,
    .update-display-name {
      @include section-body();
      .message {
        padding-left: constants.$curve-padding;
      }
      .actions {
        @include section-footer();
      }

      b {
        color: #426ed9;
        font-weight: 500;
        white-space: nowrap;
      }

      .warning-text {
        color: var(--color-secondary);
        font-size: 10.5px;
        margin-left: 4px;
      }
    }

    .mobile-number {
      .name {
        @include section-body();
      }
    }

    > .alert {
      margin: 5px $padding-horizontal 20px;
    }

    .StripeElement {
      margin-bottom: 10px;
    }

    .powered-by-stripe {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      // margin-top: 7px;
      // border-top: constants.$line-dashed;
      padding-top: 15px;
      padding-bottom: 10px;
      .logo {
        margin-right: 10px;
        img {
          width: 40px;
        }
      }
      .description {
        @include form.help-text();
        font-size: 10.5px;
      }
    }
  }
}
