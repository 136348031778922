.sunmi-cloud-kitchen-printer-edit-widget {
  padding: 15px 20px;
  .header {
    padding: 0px 0px 20px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 24px;
      font-weight: 600;
    }

    .close-button {
      padding: 0px 20px;
      
    }
  }

  .default {
    .form-buttons {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: min-content min-content;

      button {
        padding: 11px 27px;
      }
    }
  }
}
