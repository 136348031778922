@use "../colors";
@use "../viewport";
@use "../card";
@use "../constants";

@mixin card-header {
  display: flex;
  align-items: center;
  color: colors.$blue-gray-900;
  font-weight: 600;
  font-size: 20px;
}

@mixin card-sub-title {
  color: colors.$blue-gray-700;
  font-size: 16px;
  margin-top: 8px;
}

.google-review-setup-widget {
  .google-review-link-edit {
    @include card.container;
    border-radius: constants.$border-radius;
    background-color: colors.$light-gray-50;
    margin-top: 24px;
    padding: 32px;

    .header {
      @include card-header();
      padding-bottom: 24px;
    }

    .input-container {
      padding: 32px 24px;
      border: 1.5px solid colors.$teal-500;
      border-radius: constants.$border-radius;

      .header {
        @include card-header();
        padding-bottom: unset;
      }

      .sub-header {
        @include card-sub-title();

        span {
          color: colors.$blue-700;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .link-input {
        margin-top: 8px;

        form {
          display: grid;
          grid-template-columns: 9fr 1fr;
        
          .form-buttons {
            margin-top: 27px;
            margin-left: 30px;
            min-width: 136px;

            button {
              width: 100%;
              padding: 13px 18px;
            }
          }

          .text-input {
            input {
              margin-top: 8px;
              padding: 12px 14px;
              font-size: 14px;
              border-radius: 8px;
              border: 1px solid colors.$blue-gray-100;
              width: 100%;
            }
          }
        }
      }
    }
  }

  .google-review-link-view {
    @include card.container;
    border-radius: constants.$border-radius;
    margin-top: 24px;
    padding: 32px;

    .header {
      @include card-header();
      padding-bottom: unset;

      img {
        padding-right: 8px;
      }
    }

    .link-container {
      background-color: colors.$light-gray-100;
      border-radius: constants.$border-radius;
      padding: 24px;
      display: flex;
      margin-top: 24px;
      justify-content: space-between;

      .link {
        cursor: pointer;
        display: flex;
        
        img {
          margin-right: 10px;
        }

        a {
          color: colors.$blue-800;
        }
      }

      .remove-link {
        color: colors.$rose-800;
        font-weight: 500;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .link-info {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-top: 32px;

      img {
        margin-right: 5px;
      }
    }
  }

  .display-settings {
    @include card.container;
    border-radius: constants.$border-radius;
    margin-top: 24px;
    padding: 32px;

    .header {
      @include card-header();
      padding-bottom: unset;
    }

    .show-review-link-settings, .qr-code-settings {
      display: grid;
      grid-template-columns: 1fr max-content;
      grid-template-areas:
        'title                     toggle'
        'info                      info'
        'print-qr-code-settings    print-qr-code-settings'
        'download-qr-code          download-qr-code';
        
      margin-top: 24px;
      border: 1px solid colors.$blue-gray-100;
      border-radius: constants.$border-radius;
      padding: 32px 24px;

      .title {
        grid-area: title;
        @include card-header();
        padding-bottom: unset;
      }

      .info {
        grid-area: info;
        line-height: 30px;
      }

      .toggle {
        grid-area: toggle;
      }

      .review-source-link {
        margin-top: 20px;
        display: flex;

        .checkbox {
          margin-right: 15px;

          label {
            margin-left: 7px;
            color: colors.$blue-gray-900;
          }
        }
      }

      .print-qr-code-settings, .download-qr-code {
        display: grid;
        grid-template-columns: 1fr max-content;
        grid-template-areas:
          'title      toggle'
          'info       info';

        margin-top: 24px;
        padding-top: 24px;
        border-top: 1px solid colors.$blue-gray-100;
        align-items: center;
      }
      
      .print-qr-code-settings {
        grid-area: print-qr-code-settings;

        .printed-invoice-or-ticket {
          margin-top: 20px;
          display: flex;

          .checkbox {
            margin-right: 15px;

            label {
              margin-left: 7px;
              color: colors.$blue-gray-900;
            }
          }
        }
      }

      .download-qr-code {
        grid-area: download-qr-code;

        .download-button {
          color: colors.$blue-600;
          background-color: transparent;
          border: 1px solid colors.$blue-400;
          border-radius: 4px;
          font-size: 16px;
        }

        > .customer-review-qr-code-widget {
          display: none;
        }
      }
    }
  }

  .email-settings,
  .review-form-customization-settings {
    @include card.container;
    border-radius: constants.$border-radius;
    margin-top: 24px;
    padding: 32px;

    .header {
      @include card-header();
      padding-bottom: unset;

      img {
        padding-right: 8px;
      }
    }

    .info {
      font-size: 14px;
    }

    .customer-review-notifications,
    .customer-banner-color {
      display: grid;
      grid-template-columns: 1fr max-content;
      grid-template-areas:
        'title   toggle'
        'info    info';

      margin-top: 24px;
      border: 1px solid colors.$blue-gray-100;
      border-radius: constants.$border-radius;
      padding: 32px 24px;

      .title {
        grid-area: title;
        @include card-header();
        padding-bottom: unset;
      }

      .info {
        grid-area: info;
        line-height: 30px;
      }

      .toggle {
        grid-area: toggle;
      }

      .review-source-link {
        margin-top: 20px;
        display: flex;

        .checkbox {
          margin-right: 15px;

          label {
            margin-left: 7px;
            color: colors.$blue-gray-900;
          }
        }
      }

      .recipients {
        margin-top: 24px;

        .header {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: colors.$blue-gray-700;
          font-weight: 400;
          
          img {
            margin-left: 10px;
            cursor: pointer;
          }
        }

        .confirmed-emails {
          margin-top: 12px;
          display: flex;
          flex-wrap: wrap;

          .email {
            padding: 4px 12px;
            color: colors.$blue-gray-700;
            font-size: 14px;
            border-radius: 4px;
            border: 1px solid colors.$light-gray-400;
            margin: 0 10px 10px 0;
          }
        }

        .unconfirmed-emails {
          margin: 12px 0 14px;
          display: flex;
          flex-wrap: wrap;

          .email {
            padding: 4px 12px;
            color: colors.$blue-gray-700;
            font-size: 14px;
            border-radius: constants.$border-radius;
            background-color: colors.$light-gray-200;
            margin: 0 10px 10px 0;
          }

          img {
            margin-left: 10px;
            cursor: pointer;
          }
        }

        .email-input {
          form {
            display: flex;
            .form-items {
              width: 85%;
            }
            .form-buttons {
              margin-top: 0px;
              margin-left: 30px;
              button {
                width: 100%;
                background-color: colors.$blue-gray-50;
                border: 1px solid colors.$blue-400;
                color: colors.$blue-600;
                border-radius: 4px;
                background-color: transparent;
              }
            }
          }
        }
      }

      .banner-color {
        .title {
          margin: 15px 0px;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }
}
