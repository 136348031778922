@use "typography";
@use "utils";
@use "buttons";
@use "colors";

.page {
  > .header {
    margin-bottom: 20px;

    .navigation {
      margin-bottom: 20px;
      .back-button {
        cursor: pointer;
        gap: 12px;
        display: flex;
        font-size: 12px;

        .previous-page {
          color: colors.$blue-gray-500;
          text-decoration: underline;
        }
        .active-page {
          color: colors.$blue-600;
        }
      }
    }

    .wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .title {
      font-size: 24px;
      font-weight: 600;
      line-height: 28.8px;
      color: colors.$blue-gray-900;
      @include utils.curve-padding;
    }

    .actions {
      @include buttons.compact;
      z-index: 2;

      a:link {
        text-decoration: none;
      }
    }

    > .note {
      display: flex;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0.001em;
      color: colors.$blue-gray-500;
      background-image: url('../images/info-filled-icon.svg');
      background-repeat: no-repeat;
      background-size: 20px;
      padding-left: 28px;
      margin: 8px 0 0 4px;
    }
  }

  .body {
    // overflow-y: scroll;
  }

  .footer {
  }
}
