@use '../colors';

.integrations-widget {
  .no-integrations-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 25vh;

    > .icon {
      grid-area: icon;
      height: 64px;
    }

    .title {
      grid-area: title;
      font-size: 24px;
      font-weight: 600;
      color: colors.$blue-gray-700;
      margin-top: 24px;
    }

    .sub-title {
      font-size: 16px;
      color: colors.$blue-gray-500;
      margin-top: 24px;

      .link {
        display: inline-block;
        color: colors.$blue-600;
        font-weight: 500;
        cursor: pointer;
      }
      .icon {
        display: none;
      }
    }
  }

  .integrations {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, 552px);

    .uber-eats-integration-list-item-widget {
      .enable-integration-action {
        color: colors.$teal-700;
      }
    }

    .anz-worldline-integration-list-item-widget {
      .enable-integration-action {
        color: colors.$teal-700;
      }
      .delete-integration-action {
        color: colors.$rose-800;
      }
    }
  }
}
