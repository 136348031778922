@use "../card" as card;
@use "../form"as form;

.store-website-custom-navbar-edit-widget {
  @include card.container;

  form {
    @include card.content;
    margin-top: 20px;

    .items {
      @include card.container;
      @include card.stack;

      .list {
        @include card.upper;
        @include form.inline;
        .navigation-link {
          display: flex;
          flex-direction: row;
          gap: 12px;

          .label {
            flex-grow: 1;
          }

          .href {
            flex-grow: 1;
          }

          .target {
            min-width: 100px;
          }

          .actions {
            display: flex;
            flex-direction: row;
            @include card.round-buttons;

            .action {
              @include card.round-button;
            }

            .invisible {
              opacity: 0;
            }
          }
        }
      }

      .list.empty {
        color: #6c6c6c;
      }

      .list-actions {
        @include card.lower;
        @include card.round-buttons;

        .list-action {
          @include card.round-button;
        }
      }
    }
  }
}
