@use "./add-ons-widget";
@use '../card';
@use '../colors';
@use '../viewport';

@mixin steps {
  display: grid;
  gap: 20px;
  margin-bottom: 20px;
}

@mixin step {
  display: grid;
  grid-template-areas:
    'icon    title      title'
    'icon    sub-title  sub-title'
    '.       .          action'
    'form    form       form';
  grid-template-columns: min-content 1fr max-content;
  gap: 0px 20px;
  padding: 15px;
  border-radius: 10px;

  @include viewport.tablet {
    padding: 25px 20px;
  }

  @include viewport.desktop {
    grid-template-areas:
      'icon   title      action'
      'icon   sub-title  action'
      'form   form       form';
  }

  &.current {
    background-color: white;
    border: 2px solid colors.$teal-500;
  }

  &.done {
    background-color: colors.$green-50;
    border: 2px solid colors.$green-50;

    > .icon {
      cursor: not-allowed;
    }
  }

  &.disabled {
    border: 2px solid colors.$blue-gray-100;
    opacity: 0.5;
    pointer-events: none;
  }

  .icon {
    grid-area: icon;
    cursor: pointer;
  }

  .title {
    grid-area: title;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: colors.$blue-gray-900;
    margin-bottom: 5px;

    @include viewport.tablet {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .sub-title {
    grid-area: sub-title;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: colors.$blue-gray-700;

    @include viewport.tablet {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .action {
    grid-area: action;
    grid-auto-flow: column;
    gap: 8px;
    align-items: center;
    margin-top: 10px;
    display: grid;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;

    @include viewport.desktop {
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      margin-top: 0;
      margin-right: 20px;
    }

    > a {
      color: colors.$blue-600;
    }
  }

  .form {
    grid-area: form;
  }
}

.link-existing-sender-id-modal {
  display: grid;
  grid-template-areas:
    'title  close-icon'
    'form   form';
  grid-template-columns: 1fr max-content;
  gap: 0 40px;
  border: none !important;
  padding-top: 20px;

  .title {
    grid-area: title;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    padding-left: 20px;
  }

  .close-icon {
    grid-area: close-icon;
    cursor: pointer;
    align-self: center;
    padding-right: 20px;
  }

  .link-sender-id.form {
    grid-area: form;
    margin-top: 10px;

    .form-item {
      padding: 0 20px;
      margin-top: 10px;

      > label {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: colors.$blue-gray-900;
      }
    }

    .actions {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: repeat(2, max-content);
      gap: 10px;
      margin-top: 20px;
      background-color: colors.$light-gray-50;
      border-top: 1px solid colors.$light-gray-100;
      padding: 20px;

      > button {
        width: fit-content;
        border-radius: 5px;

        .label {
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
        }
      }

      .cancel-button {
        color: colors.$blue-gray-900;
      }
    }
  }
}

.sms-marketing-add-on-setup-widget {
  .twilio-integration-setup-label {
    margin-bottom: 24px;

    .title {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: colors.$blue-gray-900;
      margin-bottom: 5px;

      @include viewport.tablet {
        font-size: 24px;
        font-weight: 600;
        line-height: 29px;
      }
    }

    .sub-title {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: colors.$blue-gray-700;

      @include viewport.tablet {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }

  .integration-is-almost-ready-banner {
    padding: 20px 30px;
    margin-bottom: 24px;
    border-radius: 10px;

    .message > .bold {
      font-weight: 500;
    }
  }

  .initial-setup-requirements {
    @include card.container;
    padding: 30px;

    .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      color: colors.$blue-gray-900;
      align-content: center;
      margin-bottom: 30px;
    }

    .steps {
      @include steps();

      .agree-to-terms-and-conditions-step {
        @include step();

        .sub-title > .signed-by {
          margin-top: 5px;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          color: colors.$teal-600;
          font-style: italic;
        }
      }

      .recipient-emails-step {
        @include step();
      }
    }
  }

  .twilio-sender-id-registration {
    @include card.container;
    padding: 30px;
    margin-top: 24px;

    .header {
      margin-bottom: 30px;

      @include viewport.tablet {
        display: grid;
        grid-template-areas: 'title     button';
        grid-template-columns: 1fr max-content;
      }

      .title {
        grid-area: title;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        color: colors.$blue-gray-900;
        align-content: center;
      }

      .link-existing-sender-id-button {
        grid-area: button;
        align-self: center;
        padding: 10px 20px;
        height: fit-content;
        border-radius: 5px;
        margin-top: 10px;

        @include viewport.tablet {
          margin-top: 0;
        }

        .label {
          display: grid;
          grid-auto-flow: column;
          gap: 8px;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
        }
      }
    }

    .steps {
      @include steps();

      .step {
        @include step();
      }
    }

    .link-sender-id-step {
      @include step();

      .link-sender-id.form {
        display: grid;
        gap: 15px;
        padding: 20px;
        margin: 20px;
        border-radius: 5px;
        background-color: colors.$light-gray-50;

        .form-item {
          display: grid;

          > label {
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            color: colors.$blue-gray-900;
            margin-bottom: 5px;
          }
        }

        .link-sender-id-button {
          width: fit-content;
          margin-top: 5px;
          border-radius: 5px;

          .label {
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
          }
        }
      }
    }
  }
}
