@use "../card" as card;
@use "../buttons" as buttons;
@use "../colors" as colors;
@use "../themes/default" as default-theme;

.employee-list-widget {
  .employees {
    display: grid;
    gap: 20px;

    .table-header, .employee {
      @include card.container(); 
      padding: 16px 20px;

      display: grid;
      grid-template-columns: repeat(4, 1fr);
      justify-items: center;
      align-items: center;

      font-size: 13px;
      line-height: 20px;

      .name {
        justify-self: start;
      }

      .actions {
        justify-self: end;
        button {
          @include buttons.primary-transparent();
          padding: 7px 24px;

          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }

    .employee {
      .active-status.active {
        color: #{default-theme.$active-highlight};
      }
    }

    .table-header {
      font-weight: 500;
    }
  }
}