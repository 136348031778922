@use '../card';
@use '../buttons';
@use '../colors';
@use '../common/elements';

.uber-eats-integration-setup-widget {
  @include card.container;
  padding: 32px;

  > .header {
    margin-bottom: 32px;
    .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
    }
    .sub-title {
      margin-top: 8px;
    }
  }

  .setup-steps {
    display: grid;
    gap: 24px;

    .uber-eats-authorisation-card-widget {
      button {
        @include buttons.primary;
        padding: 14px 32px;
        border-radius: 4px;
      }
      &.is-authorised button {
        border: none;
        background: none;
        box-shadow: none;
        text-decoration: underline;
        color: colors.$rose-900;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .uber-eats-check-menu-card-widget {
      .task button {
        border: none;
        background: none;
        box-shadow: none;
        text-decoration: underline;
        color: colors.$blue-600;
        font-size: 14px;
        font-weight: 500;
      }

      .warning {
        display: flex;
        align-items: center;
        color: colors.$orange-800;

        > .icon {
          filter: brightness(0) saturate(100%) invert(20%) sepia(93%) saturate(1797%) hue-rotate(14deg) brightness(96%)
            contrast(89%);
          @include elements.icon(20px);
        }

        > .text {
          margin-left: 8px;
        }
      }
    }

    .uber-eats-store-matching-card-widget {
      display: grid;
      gap: 24px;
      .task button {
        @include buttons.primary;
        padding: 14px 32px;
        border-radius: 4px;
      }
      .task button.disabled {
        background-color: colors.$blue-300 !important;
        color: colors.$white !important;
        cursor: not-allowed;
      }
    }
  }
}
