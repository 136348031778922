@use "../typography" as typography;
@use "../card" as card;
@use "../viewport" as viewport;

@include viewport.mobile {
  .new-order-list-widget {
    .table-headers {
      display: none;
    }
  }
}

@include viewport.tablet {
  .new-order-list-widget {
    .table-headers {
      display: none;
    }
  }
}

@include viewport.desktop {
  .new-order-list-widget {
    margin-top: 10px;

    .table-headers {
      @include card.container;
      @include card.stack;
      padding: 16px 20px;
      margin-bottom: 10px;
      column-gap: 10px;
      grid-template-columns: 3fr 2fr 1fr 2fr 1fr 1fr;
      @include typography.h3;
    }
  }
}
