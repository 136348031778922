@use './colors';

.swal2-container {
  .swal2-popup {
    width: 370px;
    padding: 30px;
    border-radius: 20px;
  }

  .swal2-header {
    padding: 0;

    .swal2-title {
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      color: colors.$blue-gray-900;
    }
  }

  .swal2-content {
    padding: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: colors.$blue-gray-500;
  }

  .swal2-actions {
    flex-flow: column;

    > button {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      width: calc(100% - 30px);
      margin: 5px 15px;

      &.swal2-confirm.confirm-button {
        background-color: colors.$blue-600 !important;
      }

      &.swal2-cancel.cancel-button {
        border: 1px solid colors.$blue-gray-500;
        background-color: white !important;
        color: colors.$blue-gray-700;
      }
    }
  }

  & .warning {
    .swal2-actions {
      > button {
        &.swal2-confirm.confirm-button {
          background-color: colors.$rose-800 !important;
        }
      }
    }
  }
}
