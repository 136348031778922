@use "../buttons"as buttons;
@use '../typography'as typography;
@use '../colors'as colors;
@use "../viewport"as viewport;

.custom-payment-edit-widget {
  @include viewport.mobile {
    width: 100%;
  }

  width: 39vw;
  .header {
    padding: 20px 20px 30px 20px;
    @include typography.h1;
  }
  form {
    .form-items {
      .form-input {
        margin: 10px 20px 0px 20px;

        ::placeholder {
          color: #999999;
        }
        .form-switch {
          padding: 0px;
        }
      }

      label:first-child {
        color: #27283e;
      }

      label {
        @include typography.h3;
        font-weight: 400;
        color: colors.$blue-gray-900;
      }

      .meta {
        .error {
          padding: 0px 15px;
        }
      }

      .form-item:first-child {
        label {
          padding: 0px 20px;
        }
      }
      .inline-title {
        label {
          color: #171725;
        }
      }
      .switch {
        label {
          padding: 0px 20px;
          color: #27283e;
        }
      }
    }

    .form-buttons {
      display: flex;
      gap: 8px;
      padding: 20px;
      button {
        width: max-content;
        padding: 9.5px 32px;
        border-radius: 3px;
        font-size: 13px;
      }
      .submit-btn {
        @include buttons.primary;

        &.disabled {
          background-color: colors.$blue-300 !important;
          color: colors.$white !important;
        }
      }
      .cancel-btn {
        @include buttons.cancel-button;
        background-color: colors.$blue-gray-100 !important;
        color: colors.$blue-gray-900 !important;
      }
    }
  }
}
