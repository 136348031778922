@use "../card" as card;

.domain-edit-page,
.domain-list-page {
  .domain-edit-widget {
    @include card.container;
    form {
      @include card.content;
      margin-top: 20px;

      .qr-code {
        width: 200px;
        height: 200px;
      }
    }
  }

  .react-autosuggest__suggestions-list {
    margin: 0.5rem 0;
    padding: 0;
    list-style-type: none;
    @include card.container();
    @include card.content();

    .suggestion {
      padding: 0.5rem 0rem;
      cursor: pointer;
      span {
        padding: 1rem;
      }
    }
    .suggestion:hover {
      background-color: #f4f4f4;
    }
  }
}
