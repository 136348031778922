@use '../typography';
@use '../viewport';

.manage-store-integrations-page {
  .body > .header {
    font-size: 12px;
    font-weight: 500;
    margin: 20px 0;
  }

  @include viewport.tablet {
    .body > .header {
      font-size: 24px;
      font-weight: 600;
    }
  }
}
