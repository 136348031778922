@use "../card"as card;
@use "../constants"as constants;
@use "../typography"as typography;
@use "../utils"as utils;

.backoffice {
  .category-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-gap: 15px;
  }

  .category {
    overflow: hidden;
    @include card.container;
    @include card.stack;
    display: grid;
    grid-template-rows: 1fr min-content;
    background-color: #fafafb;

    &.accent {
      grid-template-rows: min-content 1fr min-content;
    }

    > .accent {
      height: 6px;
      width: 100%;
    }

    .body {
      @include card.upper;
      cursor: pointer;
      padding: 10px;
    }

    .name {
      @include typography.h2;
      @include utils.curve-padding;
    }

    .description {
      @include typography.h6;
      @include utils.curve-padding;
    }

    .online-only,
    .pos-only,
    .uber-eats-only {
      @include utils.curve-padding;
    }

    .actions {
      @include card.lower;
      @include card.round-buttons;

      .action {
        @include card.round-button;
      }
    }
  }
}
