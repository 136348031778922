@use "./themes/default";
@use "./typography";
@use "./colors";

.form-multi-select-tag-autocomplete {
  padding: 0 15px;

  .multi-select-tags {
    padding-bottom: 12px;
    display: flex;
    flex-wrap: wrap;
  }

  .multi-select-autocomplete {
    &__control {
      border-radius: 10px;
      cursor: text;
    }

    &.invalid {
      padding-bottom: 6px;
      border-color: inherit;

      .multi-select-autocomplete__control:has(input:not(:focus)) {
        border-color: default.$form-element-error;
      }
    }

    &__menu {
      position: relative;
      border-radius: 10px;
      box-shadow: 0px 0px 6px 0px #687a9b80;

      .multi-select-autocomplete__option {
        cursor: pointer;
        color: colors.$blue-gray-900;
      }
    }
  }
}
