@use "../typography"as typography;
@use "../colors" as colors;

.eta-selector-widget {
  display: grid;
  grid-template-areas:
    'back-btn       title         .'
    'etas           etas          etas'
    'subtitle       subtitle      subtitle'
    'confirm-btn    confirm-btn   confirm-btn';
  grid-template-columns: 20px 1fr 20px;
  padding: 20px;

  .back-btn {
    grid-area: back-btn;
    padding-top: 8px;
    cursor: pointer;
  }

  .title {
    grid-area: title;
    @include typography.h1;
    text-align: center;
    margin-bottom: 20px;
  }

  .etas {
    grid-area: etas;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 10px;

    .eta {
      @include typography.h3;
      padding: 10px 5px;
      color: #{colors.$blue};
      border: 1px solid #{colors.$med-gray};
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;

      &.selected {
        border: 1px solid #{colors.$blue};
      }

      &:hover {
        border: 1px solid #{colors.$blue};
      }
    }
  }

  .subtitle {
    grid-area: subtitle;
    @include typography.h3;
    text-align: center;
    font-weight: 300;
    margin-top: 20px;

    > span {
      font-weight: 600;
    }
  }

  .confirm-btn {
    grid-area: confirm-btn;
    font-size: 14px;
    margin-top: 20px;
  }
}
