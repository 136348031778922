.user-restaurant-and-stores-list {
  position: relative;
  top: -50px;
  z-index: 1;

  .user-restaurant-list,
  .user-store-list {
    & .header {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.1px;
      color: var(--fg);
      padding-left: 6px;
      margin: 30px 0 20px 0;
    }
  }

  .user-restaurant-list > .header {
    margin-top: 0;
  }
}
