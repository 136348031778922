@use "../colors";
@use "../viewport";
@use "../card";
@use "../constants";

@mixin card-sub-title {
  color: colors.$blue-gray-700;
  font-size: 16px;
  margin-top: 8px;
}

.swal2-container.google-review-link-confirmation {
  > .swal2-modal {
    padding: 32px 24px;
    width: 375px;
    border-radius: 20px;

    > .swal2-header > .swal2-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: colors.$blue-gray-900;
    }

    > .swal2-content {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: colors.$blue-gray-500;
      margin: 12px 25px 0;
    }

    > .swal2-actions {
      flex-flow: column;
      padding: 0 25px;

      > button {
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        padding: 14px 32px;
      }

      > .swal2-confirm {
        background-color: colors.$blue-600;
      }

      > .swal2-cancel {
        border: 1px solid colors.$blue-gray-500;
        color: colors.$blue-gray-700;
        background-color: white !important;
        margin-top: 12px;
      }
    }
  }
}

.swal2-container.google-review-remove-link-confirmation {
  > .swal2-modal {
    padding: 32px 20px;
    width: 391px;
    border-radius: 20px;

    > .swal2-header > .swal2-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: colors.$blue-gray-900;
    }

    > .swal2-content {
      font-size: 16px;
      line-height: 24px;
      color: colors.$blue-gray-500;
      margin-top: 12px;
    }

    > .swal2-actions {
      flex-flow: column;
      padding: 0 21px;

      > button {
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        padding: 14px 32px;
      }

      > .swal2-confirm {
        background-color: colors.$rose-800 !important;
      }

      > .swal2-cancel {
        border: 1px solid colors.$blue-gray-500;
        color: colors.$blue-gray-700;
        background-color: white !important;
        margin-top: 12px;
      }
    }
  }
}

.google-review-settings-widget {
  padding-bottom: 40px;

  > .header {
    margin: 24px 0;
    display: grid;
    grid-template-areas:
      'logo title status'
      'logo sub-title sub-title';

    grid-template-columns: min-content 1fr max-content;
    gap: 8px 24px;

    .logo {
      grid-area: logo;
      height: 80px;
    }

    .title {
      grid-area: title;
      font-size: 34px;
      font-weight: 600;
      line-height: 40.8px;
      color: colors.$blue-gray-900;
    }

    .sub-title {
      grid-area: sub-title;
      @include card-sub-title();
    }

    .status {
      grid-area: status;
      color: colors.$blue-gray-700;
      &.is-enabled {
        color: colors.$green-600;
      }
    }
  }

  > .settings-info {
    .title {
      color: colors.$blue-gray-900;
      font-size: 24px;
      font-weight: 600;
    }

    .sub-title {
      @include card-sub-title();

      span {
        color: colors.$blue-700;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
