@use '../colors';

.sms-marketing-settings-widget {
  .display-selector {
    display: flex;
    border-bottom: 1px solid colors.$blue-gray-100;

    .option {
      margin-right: 12px;
      cursor: pointer;
      padding: 12px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.005em;
      color: colors.$blue-gray-700;

      &.selected {
        color: colors.$blue-600;
        border-bottom: 4px solid colors.$blue-600;
      }
    }
  }
}
