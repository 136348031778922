$aspect-ratio-list: (
  '4-2': '4 / 2',
  '4-2-5': '4 / 2.5',
  '8-3': '8 / 3',
  '4-3': '4 / 3',
  '1-1': '1 / 1',
  '4-5': '4 / 5',
  '4-6': '4 / 6'
);

@each $ar-key, $ar-value in $aspect-ratio-list {
  .ar-#{$ar-key} {
    aspect-ratio: #{$ar-value};
    object-fit: cover;
  }
}
