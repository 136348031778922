@use "alert";
@use "aspect_ratio";
@use "buttons";
@use "card";
@use "checkbox";
@use "colors";
@use "daypicker";
@use "spinner";
@use "form";
@use "form_key_value_pair";
@use "form_tag_input";
@use "form_address_search";
@use "form_single_select_pill";
@use "form_multi_select_pill";
@use "form-color-picker";
@use "form-multi-select-tag-autocomplete";
@use "form-checkbox-group";
@use "form-banner";
@use "time_input";
@use "dropdown";
@use "switch";
@use "badge";
@use "form_select";
@use "page";
@use "optimized-image";
@use "toast";
@use "time_picker";
@use "eta_picker";
@use "status_indicator";
@use "typography"as typography;
@use "popout";
@use "progress";
@use "form_single_select_overlay";
@use "form-iso-date-picker-input-and-dropdown";
@use "radio";
@use "removable-tag";
@use "sweetalert";
@use "tabs";
@use "widgets/auth";
@use "widgets/backoffice";
@use "widgets/website";
@use "widgets/intro";
@use "widgets/store_select_widget";
@use "widgets/restaurant_website_info_edit_widget";
@use "widgets/pizza-edit-widget";
@use "widgets/deal-edit-widget";
@use "widgets/price_spec_edit_widget";
@use "widgets/restaurant_list_widget";
@use "widgets/store_card_view";
@use "widgets/store_list_widget";
@use "widgets/backoffice_header";
@use "widgets/modifier_specs_edit_widget";
@use "widgets/pizza_settings_edit_widget";
@use "widgets/item_spec_list_widget";
@use "widgets/item_spec_view_widget";
@use "widgets/backoffice_category_list_widget";
@use "widgets/order-view-widget";
@use "widgets/printer_queue_widget";
@use "widgets/store_edit_widget";
@use "widgets/coupon_card_view";
@use "widgets/coupon_edit_widget.scss";
@use "widgets/user_list_widget";
@use "widgets/user_card_view";
@use "widgets/domain_card_widget";
@use "widgets/domain_list_widget";
@use "widgets/stripe_payout_view";
@use "widgets/stripe_balance_widget";
@use "widgets/loyalty_settings_widget";
@use "widgets/store_holidays_edit_widget";
@use "widgets/store_website_info_edit_widget";
@use "widgets/user_restaurant_and_store_list_widget";
@use "widgets/restaurant_customer_list_widget";
@use "widgets/store_trading_hours_edit_widget";
@use "widgets/weekly_hours_edit_widget";
@use "widgets/printer_list_widget";
@use "widgets/public_holiday_surcharge_settings_edit_widget";
@use "widgets/restaurant_edit_widget";
@use "widgets/category_edit_widget";
@use "widgets/half-and-half-edit-widget";
@use "widgets/basic-item-edit-widget";
@use "widgets/custom-item-edit-widget";
@use 'widgets/pos-custom-payments-settings-edit-widget';
@use "widgets/pos-settings-widget";
@use "widgets/pos-lock-screen-settings-edit-widget";
@use "widgets/pos-employee-time-clock-settings-edit-widget";
@use "widgets/pos-employee-shift-break-preset-view-widget";
@use "widgets/pos-employee-shift-break-preset-edit-widget";
@use "widgets/pos-surcharge-settings-widget";
@use "widgets/pos-discount-settings-widget";
@use "widgets/pos_terminal_settings_widget";
@use "widgets/pos_terminal_edit_widget";
@use "widgets/pos_surcharge_edit_widget";
@use "widgets/pos_discount_edit_widget";
@use "widgets/pos-order-view-widget";
@use "widgets/batch_upload_job_view_widget";
@use "widgets/delivery_area_settings_widget";
@use 'widgets/table_edit_widget';
@use "widgets/code_editor_widget";
@use "widgets/restaurant_website_theme_edit_widget";
@use "widgets/qr_code_widget";
@use "widgets/store_website_custom_navbar_edit_widget";
@use "widgets/store_website_custom_page_view_widget";
@use "widgets/store_website_custom_page_version_edit_widget";
@use 'widgets/platform_settings_edit_widget';
@use "widgets/new-order-list-widget";
@use "widgets/new-order-list-item-widget";
@use "widgets/new-order-details-widget";
@use "widgets/new-orders-widget";
@use "widgets/android-install-prompt";
@use "widgets/ios-install-prompt";
@use "widgets/sunmi-cloud-kitchen-printer-edit-widget";
@use "widgets/cloud-printer-settings-widget";
@use "widgets/print-station-list-widget";
@use "widgets/print-station-edit-widget";
@use "widgets/delivery-channel-selector-widget";
@use "widgets/eta-selector-widget";
@use "widgets/time-range-widget";
@use "widgets/item-spec-base-edit-widget";
@use "widgets/manage-item-routing-widget";
@use "widgets/pos-terminal-settings-edit-widget";
@use 'widgets/pos-trading-hours-edit-widget';
@use 'widgets/time-period-edit-widget';
@use "widgets/employee-edit-widget";
@use "widgets/employee-list-widget";
@use "widgets/print-docket-settings-widget";
@use "widgets/pos-order-delivery-status-widget";
@use "widgets/order-transaction-report-widget";
@use "widgets/category_sales_report_settings_widget";
@use "widgets/category_sales_widget";
@use "widgets/category_sales_edit_widget";
@use "widgets/category_sales_list_item_widget";
@use 'widgets/breadcrumbs-widget';
@use 'widgets/loading-widget';
@use 'widgets/alert-widget';
@use 'widgets/manage-store-integration-list-widget';
@use 'widgets/manage-store-integration-list-item-widget';
@use 'widgets/integrations-widget';
@use 'widgets/integrations-list-item-widget';
@use 'widgets/uber-eats-integration-widget';
@use 'widgets/uber-eats-integration-setup-widget';
@use 'widgets/uber-eats-integration-setup-item-widget';
@use 'widgets/uber-eats-store-information-widget';
@use 'widgets/uber-eats-integrate-store-widget';
@use 'widgets/uber-eats-preview-menu-widget';
@use 'widgets/uber-eats-login-button';
@use 'widgets/manage-add-on-list-widget';
@use 'widgets/manage-add-on-list-item-widget';
@use 'widgets/add-ons-widget';
@use 'widgets/add-on-list-item-widget';
@use 'widgets/connected-stores-settings-widget';
@use 'widgets/sms-marketing-add-on-widget';
@use 'widgets/sms-marketing-add-on-info-widget';
@use 'widgets/sms-marketing-add-on-setup-widget';
@use 'widgets/sms-marketing-terms-and-conditions-widget';
@use 'widgets/sms-marketing-recipient-emails-widget';
@use 'widgets/sms-marketing-campaign';
@use 'widgets/sms-marketing-mobile-preview-widget';
@use 'widgets/sms-marketing-campaign-preview-widget';
@use 'widgets/table-map-settings-widget';
@use 'widgets/google-review-settings-widget';
@use 'widgets/google-review-info-pop-up-widget';
@use 'widgets/google-review-setup-widget';
@use 'widgets/uber-eats-pos-order-integration-settings-widget';
@use 'widgets/banner-widget';
@use 'widgets/uber-eats-unauthorised-alert';
@use 'widgets/uber-eats-setup-banner';
@use 'widgets/uber-eats-delete-integration-alert';
@use 'widgets/uber-eats-cancel-integration-setup-alert';
@use 'widgets/uber-eats-integration-action-widget';
@use "widgets/custom-payment-settings-widget";
@use "widgets/custom-payment-edit-widget";
@use "widgets/online-order-payment-settings-widget";
@use "widgets/payments-widget";
@use "widgets/stripe-settings-widget";
@use "widgets/store-sales-report-widget";
@use 'widgets/uber-eats-menu-widget';
@use 'widgets/uber-eats-edit-menu-price-widget';
@use 'widgets/uber-eats-menu-store-hours-widget';
@use 'widgets/uber-eats-menu-item-spec-modifiers-widget';
@use 'widgets/uber-eats-menu-basic-item-spec-modifiers-view-widget';
@use 'widgets/uber-eats-menu-pizza-spec-modifiers-view-widget';
@use 'widgets/uber-eats-menu-deal-spec-modifiers-view-widget';
@use 'widgets/online-order-history-uber-eats-order-list-item-widget';
@use 'widgets/uber-eats-order-view-widget';
@use 'widgets/pos-order-history-list-item-widget';
@use 'widgets/online-order-history-website-order-list-item-widget';
@use 'widgets/online-order-history-google-order-list-item-widget';
@use 'widgets/store-sales-report-filter';
@use 'widgets/pos-terminal-home-page-settings-widget';
@use 'widgets/actions-toggle-widget';
@use 'widgets/anz-worldline-delete-integration-alert';
@use 'widgets/email-order-invoice-widget';
@use 'widgets/sms-marketing-settings-widget';
@use 'widgets/sms-marketing-recipients-widget';
@use 'widgets/table_list_widget';
@use 'widgets/kds-devices-widget';
@use 'widgets/kds-device-view-widget';
@use 'widgets/kds-device-edit-widget';
@use 'widgets/kds-time-settings-edit-widget';
@use 'widgets/kds-prep-time-change-confirm-widget';
@use 'widgets/anz-worldline-integration-widget';

@use "backoffice/online-order-history-page";
@use "backoffice/store_timings_page";
@use "backoffice/delivery_settings_page";
@use "backoffice/menu_page";
@use "backoffice/order_settings_page";
@use "backoffice/printer_page";
@use "backoffice/loyalty-cards-page";
@use "backoffice/monthly_order_stats_page";
@use "backoffice/domain_edit_page";
@use "backoffice/transaction-fee-page";
@use "backoffice/pos-order-history-page";
@use "backoffice/batch_upload_jobs_page";
@use "backoffice/online_ordering_settings_page";
@use 'backoffice/restaurant_browse_page';
@use 'backoffice/table_list_page';
@use 'backoffice/platform_settings_page';
@use "backoffice/store-print-settings-page";
@use "backoffice/sunmi-cloud-kitchen-printers-page";
@use "backoffice/reports-page";
@use "backoffice/dine-in-page";
@use 'backoffice/manage-store-integrations-page';
@use 'backoffice/uber-eats-menu-page';
@use 'backoffice/uber-eats-edit-menu-price-page';
@use 'backoffice/website-order-view-page';
@use 'backoffice/google-order-view-page';
@use 'backoffice/uber-eats-order-view-page';
@use 'backoffice/add-ons-page';
@use 'backoffice/manage-add-ons-page';
@use 'backoffice/sms-marketing-new-campaign-page';

@use "widgets/item-summary-view-widget";
@use "widgets/split-bill-item-summary-view-widget";
@use "widgets/item-description-view-widget";

@use "online_ordering/online_ordering_layout";
@use "online_ordering/online_ordering_menu_page";
@use "online_ordering/online_ordering_header";
@use "online_ordering/online_ordering_banner.scss";
@use "online_ordering/online_ordering_bottom_nav_bar.scss";
@use "online_ordering/cart_page.scss";
@use "online_ordering/online_ordering_sidebar.scss";
@use "online_ordering/order_history_page";
@use "online_ordering/coupons_page.scss" as onlineOrderingOrderHistory;

@use "restaurant_website/restaurant_website_layout.scss";
@use "restaurant_website/restaurant_website_header.scss";
@use "restaurant_website/restaurant_website_page";

@use "store_website/store_website_layout.scss";
@use "store_website/store_website_header.scss";
@use "store_website/store_website_page";

@use "website/categories_page.scss";
@use "website/items_page.scss";
@use "website/website_item_page.scss";
@use "website/modifiers.scss";
@use "website/collection_type_widget.scss";
@use "website/deal_page.scss";
@use "website/item_actions.scss";
@use "website/order_success_page.scss";

@use "table_booking/table-booking-settings-edit-widget";
@use "table_booking/table-booking-settings-unavailable-date-list-widget";
@use "table_booking/table-booking-settings-unavailable-time-of-day-list-widget";
@use "table_booking/table-booking-history-page";
@use "table_booking/table-booking-view-page";
@use "table_booking/_table-booking-history-list-widget";
@use "table_booking/table-booking-history-list-item-widget";
@use "table_booking/table-booking-view-widget";
@use "table_booking/table-booking-back-link-header-widget";
@use "table_booking/table-booking-activity-logs-widget";
@use "table_booking/table-booking-activity-logs-list-widget";
@use "table_booking/table-booking-activity-logs-list-item-widget";
@use 'table_booking/_popout-table-booking-history-list-item-more-actions-widget';

html,
body {
  margin: 0;
}

body {
  font-family: Poppins, sans-serif;
  background-color: var(--bg);
  overscroll-behavior-y: none;
  @include typography.p;
}

a {
  color: var(--link-fg);
  cursor: pointer;
}

b {
  font-weight: 500;
}

textarea {
  resize: none;
}

.component {
  background-color: var(--color-bg);
}

button {
  cursor: pointer;
  box-shadow: 0px 0px 3px rgba(104, 122, 155, 0.25);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*Temporary css declaration to hide emulator warning until internal bug is fixed and disableWarnings flag can be passed in without typescript warnings*/
.firebase-emulator-warning {
  display: none;
}

.highlight::before {
  content: '';
  background: #d1ebff;
  position: absolute;
  left: 0;
  right: -2px;
  bottom: 2px;
  height: 16px;
  z-index: -1;
}
