$_default-margin: 4px;

@mixin vertical($margin: $_default-margin) {
  > *:not(:first-child) {
    margin-top: $margin;
  }
}

@mixin horizontal($margin: $_default-margin) {
  > *:not(:first-child) {
    margin-left: $margin;
  }
}
