@use "../typography";
@use "../card";

.restaurant-browse-page .ais-Hits-list,
.restaurant-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  grid-gap: 15px;
  list-style: none;
  padding: 0;

  .restaurant {
    @include card.container;

    display: flex;
    flex-direction: column;
    cursor: pointer;
    overflow: hidden;

    .name {
      @include typography.h4;
      padding: 10px 10px 0;
    }

    .timezone {
      padding: 0 10px 10px;
      font-size: 12px;
      color: #92929d;
    }

    .description,
    .country {
      display: none;
    }

    &.unauthorized {
      cursor: not-allowed;
    }
  }
}
