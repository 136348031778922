@use "../card"as card;
@use "../constants"as constants;
@use "../typography"as typography;
@use "../utils"as utils;

$padding-horizontal: 20px;

@mixin title {
  .icon {
    margin-right: 12px;
    font-size: 24px;
  }

  label {
    font-family: Fira Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    color: #293340;
  }
}

@mixin section {
  border-bottom: constants.$line;

  &:last-child {
    border-bottom: none;
  }

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    @include title();
  }
}

@mixin section-nvpair {
  padding: 16px $padding-horizontal;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@mixin section-head-body {
  padding: 16px $padding-horizontal;
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-row-gap: 8px;
  grid-template-areas:
    'a b'
    'c d';

  @include title();

  i {
    grid-area: a;
  }
  label {
    grid-area: b;
    align-self: center;
  }
  .value {
    grid-area: d;
  }
}

.stripe-payout-card {
  @include card.container;
  @include card.stack;
  cursor: pointer;
  background-color: #fafafb;
  margin: 15px 0;

  .body {
    @include card.upper;
    padding: 12px;
    display: grid;
    grid-template-columns: 1fr;
    border-bottom: none;
    // grid-template-rows: min-content 1fr min-content;
    // grid-template-areas:
    //   'a amount'
    //   'b description'
    //   'e status'
    //   'c date-created'
    //   'd arrival-time';
  }

  .amount,
  .description,
  .status,
  .date-created,
  .arrival-date {
    @include section;
    @include section-nvpair();
  }

  .amount {
    .value {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #426ed9;
    }
  }

  .description {
    .value {
      @include typography.h6;
      font-size: 13px;
    }
  }

  .arrival-date,
  .date-created {
    .value {
      white-space: nowrap;
      font-family: Poppins;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #696974;
    }
  }

  // .arrival-date {
  //   grid-area: arrival-time;
  //   white-space: nowrap;
  //   align-self: center;
  //   font-family: Poppins;
  //   font-style: normal;
  //   font-weight: 400;
  //   font-size: 14px;
  //   line-height: 21px;
  //   color: #696974;
  // }

  // .status {
  //   grid-area: status;
  //   white-space: nowrap;
  //   align-self: center;
  // }
}
