@use "../card"as card;
@use "../constants"as constants;
@use "../typography"as typography;
@use "../utils"as utils;

.stripe-balance-widget {
  @include card.container;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(2, min-content);
  grid-gap: 10px;

  .balance {
    @include typography.h3;
    padding: 10px 15px;
    white-space: nowrap;
    font-family: Fira Sans;
    text-transform: uppercase;

    .title {
      font-weight: 500;
    }

    .amount {
      font-weight: 500;
      font-size: 18px;

      .country-code {
        font-weight: 400;
        font-size: 14px;
        color: #8d92a3;
      }
    }
  }
}
