@use '../colors';

.breadcrumbs-widget {
  .breadcrumb {
    display: inline;
    color: colors.$blue-gray-500;
  }

  .breadcrumb:not(:first-child)::before {
    content: url('../images/chevron-right-icon.svg');
    align-self: center;
    margin: 0 8px;
    position: relative;
    top: 2px;
  }

  .breadcrumb:last-child {
    color: colors.$blue-600;
    text-decoration: none;
    cursor: text;
    font-weight: 500;
  }
}
