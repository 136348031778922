@use "../buttons";
@use "../colors";
@use "../typography";

.add-ons-page {
  > .header {
    .actions {
      .manage-add-ons-button {
        background-color: colors.$blue-600;

        .label {
          @include typography.h3;
          color: colors.$white;
        }
      }
    }
  }

  .no-add-ons-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 25vh;

    > .icon {
      grid-area: icon;
      height: 64px;
    }

    .title {
      grid-area: title;
      font-size: 24px;
      font-weight: 600;
      color: colors.$blue-gray-700;
      margin-top: 24px;
    }

    .sub-title {
      font-size: 16px;
      color: colors.$blue-gray-500;
      margin-top: 24px;

      .link {
        display: inline-block;
        color: colors.$blue-600;
        font-weight: 500;
        cursor: pointer;
      }
      .icon {
        display: none;
      }
    }
  }

  .add-ons {
    display: grid;
    grid-template-columns: repeat(auto-fill, 552px);
    gap: 20px;
  }
}
