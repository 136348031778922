@use "../card"as card;
@use "../constants"as constants;
@use "../form"as form;
@use "../viewport"as viewport;
@use "../buttons"as buttons;

$padding-horizontal: 15px;

.order-settings-page {
  .body {
    display: grid;
    grid-gap: 20px;
    @include viewport.tablet {
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }
  }

  .checkout-settings,
  .order-settings,
  .printer-settings {
    form {
      padding: 20px 0;
    }
    .title {
      @include card.header();
      @include card.header-font();
    }

    @include card.container();

    .form-item {
      padding: 0 $padding-horizontal;
    }

    .order-monitor {
      .emails {
        border: 3px solid #f9f9f9;
        margin: 15px $padding-horizontal;
        border-radius: 10px;
        padding: 10px $padding-horizontal;
        .form-item {
          padding: 8px 0px;
        }
        .checkbox {
          padding: 0px;
        }
        .send-email-to {
          font-size: 13px;
          padding: 10px 0px 5px 4px;
          span {
            color: red;
          }
        }
        .copy-email {
          font-weight: 300;
          color: #6c6c6c;
          .checkbox {
            all: unset;
          }
          label {
            white-space: nowrap;
          }
        }
      }
    }

    .actions {
      @include card.footer();
      @include buttons.button-group();
      button {
        margin-right: 10px;
      }
    }
  }

  .checkout-settings {
    .upsell-stack {
      .upsell-stack-items {
        margin: 15px 0;

        .upsell-item {
          display: grid;
          grid-template-columns: 1fr min-content;

          .actions {
            padding: 0 $padding-horizontal 0 0;
            align-self: flex-end;
            background-color: white;
            border: none;

            .action {
              @include card.round-button;
            }
          }
        }
      }
      button {
        @include buttons.primary-transparent();
        margin-left: 18px;
      }
    }
  }
}
