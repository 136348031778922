@use '../colors';
@use "../viewport";

.sms-marketing-recipients-widget {
  box-sizing: border-box;
  margin-top: 24px;
  color: colors.$blue-gray-900;

  .no-result {
    margin-top: 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: colors.$blue-gray-500;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    font-weight: 500;

    @include viewport.desktop {
      flex-direction: row;
      align-items: center;
    }

    .text-input {
      min-width: 320px;
      padding: 8px 14px;
      background-color: colors.$white;
      border: 1px solid colors.$blue-gray-100;
      border-radius: 8px;
      box-shadow: 0px 1px 2px 0px #{colors.$blue-gray-900}0d;

      input {
        background-image: url('../images/search-icon-gray.svg');
        background-repeat: no-repeat;
        background-size: 16px;
        border: none;
        outline: none;
        padding-left: 24px;
        width: 90%;

        &::placeholder {
          color: colors.$blue-gray-200;
        }
      }
    }

    .sms-recipient-count {
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.0025em;
      margin-top: 8px;

      @include viewport.desktop {
        margin-top: 0;
      }
    }
  }

  .sms-recipients-list {
    .sms-recipient-list-item {
      display: grid;
      grid-template-columns: 1fr 1fr 48px;
      grid-auto-flow: column;
      padding: 8px 24px;
      line-height: 24px;
      align-items: center;
      background-color: colors.$white;
      border: 1px solid colors.$blue-gray-100;
      border-bottom: none;

      @include viewport.mobile {
        grid-template-areas:
          'name name button'
          'number number button';
        .name {
          grid-area: name;
        }

        .number {
          grid-area: number;
        }

        .unsubscribe-button {
          grid-area: button;
        }
      }

      &:first-of-type {
        border-radius: 8px 8px 0 0;
      }

      &:first-child:nth-last-child(1) {
        border-bottom: 1px solid colors.$blue-gray-100;
        border-radius: 8px;
      }

      &:nth-last-child(1) {
        border-bottom: 1px solid colors.$blue-gray-100;
        border-radius: 0 0 8px 8px;
      }

      .unsubscribe-button {
        padding: 0;
        color: colors.$rose-800;
        button {
          background-color: transparent !important;
          border: none;
          box-shadow: unset;
        }

        .overlay {
          .options {
            right: -10px;
            top: -20px;
            padding: 0 !important;
            min-width: 180px;
          }
        }
      }
    }
  }

  .list-header {
    margin-top: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr 48px;
    padding: 8px 24px;
    font-size: 12;
    font-weight: 500;
    color: colors.$blue-gray-500;

    @include viewport.mobile {
      visibility: hidden;
      margin-top: -8px;
    }
  }

  .back-to-top-button {
    position: fixed;
    top: 90vh;
    right: 50px;
    padding: 13px;
    background-color: colors.$white;
    border-radius: 30px;

    @media (max-height: 844px) {
      top: calc(100% - 150px);
    }

    @media (min-height: 845px) {
      top: calc(100% - 150px);
    }
  }
}
