@use '../colors';
@use '../common/elements';
@use '../common/gap';

.uber-eats-menu-pizza-spec-modifiers-view-widget {
  @include gap.vertical(32px);
  margin-top: 16px;

  > .sizes {
    > .label {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }

    > .options {
      @include gap.vertical(24px);
      margin-top: 16px;

      > .size {
        > .label {
          display: flex;
          justify-content: space-between;

          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
        }

        > .size-toppings {
          @include gap.vertical(8px);
          margin-top: 16px;
          color: colors.$blue-gray-700;

          > .size-topping {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }

  > .remove-toppings {
    > .label {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }

    > .options {
      @include gap.vertical(8px);
      margin-top: 16px;

      > .remove-topping {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
