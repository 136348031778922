.online-ordering-bottom-nav-bar {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  box-shadow: 0px -1px 3px rgba(104, 122, 155, 0.25);
  z-index: 1;
  background-color: white;

  @supports (padding: max(0px)) {
    padding-bottom: max(10px, var(--safe-area-inset-bottom));
  }

  .item {
    display: grid;
    grid-template-rows: min-content min-content;
    text-align: center;
    position: relative;

    .icon {
      font-size: 20px;
    }
    .label {
      font-size: 8px;
    }
  }

  .item,
  .item-with-counter {
    padding-top: 10px;
    &.selected {
      .icon,
      .label {
        color: var(--color-primary);
        font-weight: 500;
      }
    }
  }

  .item-with-counter {
    display: grid;
    grid-template-rows: min-content;
    grid-template-columns: 1fr min-content 1fr;
    text-align: center;

    .content {
      position: relative;
      grid-template-rows: min-content min-content;
      text-align: center;
    }

    .icon {
      font-size: 20px;
    }

    .label {
      font-size: 8px;
    }

    .count {
      position: absolute;
      right: -8px;
      top: 0px;
      background: var(--button-success-bg);
      padding: 1px 5px 0px 6px;
      border-radius: 5px;
      font-size: 10px;
      color: white;
      font-weight: 500;
    }
  }
}
