@use "../card";
@use "../colors";
@use "../viewport";

.manage-add-on-list-item-widget {
  @include card.container;

  @media (max-width: #{viewport.$desktop-width}) {
    .info {
      display: grid;
      padding: 12px;
      grid-template-areas:
        'icon label'
        'icon status'
        'action action';
      grid-template-columns: min-content 1fr;
      gap: 4px 16px;
      align-items: center;

      .icon {
        grid-area: icon;
      }
      .label {
        grid-area: label;
        font-size: 14px;
      }

      .status {
        grid-area: status;
        font-size: 12px;
      }
    }
  }

  .action {
    grid-area: action;
    @include card.footer;
    padding: 12px 16px;
  }

  @include viewport.desktop {
    padding: 24px 20px;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr max-content;

    .info {
      display: grid;
      grid-template-columns: min-content calc(50% - 48px) 50%;
      align-items: center;

      > .label {
        font-size: 16px;
        font-weight: 500;
        margin-left: 12px;
      }
    }
    .action {
      all: unset;
      width: 100px;
    }
  }

  .info > .status {
    color: colors.$blue-gray-700;
    &.is-enabled {
      color: colors.$teal-600;
    }
  }

  .action {
    button {
      background: none;
      box-shadow: none;
      border: 1px solid colors.$blue-400;
      border-radius: 4px;
      color: colors.$blue-600;

      &.remove-button {
        border: 1px solid colors.$rose-500;
        color: colors.$rose-500;
      }
    }
  }
}

.blocked-remove-store-add-on-info-alert {
  padding: 32px 24px;

  .content {
    margin-top: 0;
    width: 100%;

    .body {
      color: colors.$blue-gray-700;
      font-weight: 400;
    }
  }

  .actions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-top: 24px;
    padding: 0 24px;

    button {
      font-size: 16px;
      border-radius: 4px;
      padding: 14px 32px;
    }

    .redirect-button {
      background-color: colors.$blue-600;
      color: colors.$white;
    }

    .cancel-button {
      background: none;
      box-shadow: none;
      outline: 1px solid colors.$blue-gray-500;
      color: colors.$blue-gray-700;
    }
  }
}

.ReactModalPortal:has(.remove-store-add-on-warning-alert) {
  .ReactModal__Content.ReactModal__Content--after-open {
    .modal {
      > .remove-store-add-on-warning-alert {
        padding: 32px 24px;

        .actions {
          max-width: 280px;
        }
      }
    }
  }
}
