@use "../card";
@use "../buttons";
@use "../colors";

.pos-employee-shift-break-preset-view-widget {
  @include card.container;
  margin: 0 20px;

  .fields {
    padding: 20px;
    font-size: 12px;
    color: colors.$blue-gray-900;
    display: grid;
    grid-template-areas:
      'name         name'
      'type-label   type'
      'limit-label  limit-duration';
    gap: 6px;

    .name {
      grid-area: name;
      letter-spacing: 0.1px;
      color: colors.$blue-gray-900;
      font-size: 13px;
      font-style: normal;
      font-weight: 300;
      line-height: 19.5px;
    }

    .label {
      color: colors.$material-gray-600;
    }

    .type-label {
      grid-area: type-label;
    }

    .type {
      grid-area: type;
      text-transform: capitalize;
      justify-self: end;
    }

    .limit-label {
      grid-area: limit-label;
    }

    .limit-duration {
      grid-area: limit-duration;
      justify-self: end;
    }
  }

  .actions {
    @include card.footer();
    @include buttons.button-group();

    .action {
      @include card.round-button;
      cursor: pointer;
    }
  }
}

.swal2-container {
  .delete-work-break-warning {
    max-width: 350px;
    padding: 32px 24px;
    border-radius: 20px;

    .swal2-content {
      color: colors.$blue-gray-900;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      padding: 0;
    }

    .swal2-actions {
      margin-top: 24px;
      flex-direction: row-reverse;
      gap: 16px;

      button {
        border-radius: 4px;
        padding: 10px 30px;
        font-weight: 500;
        font-size: 14px;
        margin: 0;
      }

      .confirm-button {
        background-color: colors.$blue-600;
        color: colors.$white;
      }

      .cancel-button {
        background-color: colors.$white !important;
        color: colors.$blue-gray-700;
        border: 1px solid colors.$blue-gray-700;
      }
    }
  }
}
