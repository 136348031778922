@use "../card" as card;

.restaurant-edit-widget {
  @include card.container;

  form {
    @include card.content;
    margin-top: 20px;
  }
}
