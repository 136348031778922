@use "../card"as card;
@use "../constants"as constants;
@use "../form"as form;
@use "../buttons"as buttons;
@use "../viewport"as viewport;

$padding-horizontal: 15px;

.public-holiday-surcharge-settings-edit-widget {
  @include card.container;

  form {
    @include card.content;
    margin-top: 20px;
  }

  .order-source-mobile-help-text {
    @include viewport.tablet {
      display: none;
    }

    .meta {
      display: block;
      @include viewport.tablet {
        display: none;
      }
    }
  }

  .form-radio-group {
    .meta {
      display: none;

      @include viewport.tablet {
        display: block;
      }
    }
  }

  .radio-group {
    @include viewport.desktop {
      display: flex;
      margin-bottom: 8px;
      flex-wrap: wrap;
    }
  }

  .radio  {
    margin: 0 0 16px 0;
    @include viewport.desktop {
      margin: 0 20px 0 0;
    }
  }
  
  .form-input {
    .settings,
    .holiday {
      @include card.container;
      @include card.stack;
      margin-bottom: 20px;

      .info {
        @include card.upper;
        @include form.inline;
      }

      .actions {
        @include card.lower;
        @include card.round-buttons;
        .action {
          @include card.round-button;
        }
      }
    }

    .holidays {
      button {
        @include buttons.primary-transparent;
      }
    }
  }

  .public-holiday-surcharge-form-item > label {
    @include form.top-align-label();
  }
}
