@use '../viewport';

.uber-eats-delete-integration-alert.alert-widget {
  max-width: 573px;

  .content {
    text-align: left;

    ul {
      margin: 0;
    }
  }

  .actions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 'back-button delete-button';
    gap: 16px;

    button {
      padding: 14px 32px;
      white-space: nowrap;
    }

    .proceed-button {
      grid-area: delete-button;
    }

    .cancel-button {
      grid-area: back-button;
    }
  }

  @include viewport.mobile {
    width: 100%;

    .title {
      font-size: 18px;
    }

    .actions {
      display: flex;
      flex-direction: column;
    }
  }
}
