@use "../typography" as typography;
@use "../colors" as colors;

.table-booking-activity-logs-widget {
  margin-top: 17.5px;

  .activity-log-button {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;

    svg {
      transform: rotate(-90deg);
      margin: 0px 16px 0px 6px;
    }

    &.close {
      svg {
        transform: rotate(0);
      }
    }
  }
}
