@use "../card";
@use '../colors';
@use '../constants';
@use '../typography';
@use '../viewport';

.store-sales-report-widget {
  position: relative;

  button {
    background-color: unset;
    box-shadow: none;
  }

  .store-sales-report-filter-summary {
    display: none;
    @include viewport.mobile {
      display: inline-block;
      font-size: 12px;

      .date,
      .custom-date-range > *,
      .channel {
        display: table;
        font-weight: 400px;
        padding: 5px;
        border: 1px solid colors.$blue-gray-100;
        border-radius: 5px;
        background-color: colors.$white;
        margin-bottom: 10px;
        cursor: pointer;
        > span {
          color: colors.$blue-gray-900;
        }
      }
    }
  }

  .download-store-sales-report {
    position: absolute;
    background-color: colors.$blue-600 !important;
    color: colors.$white !important;
    height: min-content;
    right: 0;
    top: 20px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 14px;
    line-height: 21px;
    padding: 10px 24px;
    @include viewport.mobile {
      top: 3px;
    }
  }

  &:not(:has(.download-store-sales-report)) {
    .store-sales-report-filter {
      .filters {
        @include viewport.mobile {
          margin-top: 0px;
        }
      }
    }
  }

  .store-sales-report-breakdown {
    .header {
      font-weight: 500;
      font-size: 18px;
      color: colors.$blue-gray-900;
      border-bottom: #{colors.$blue-gray-100} 1px solid;
      padding-bottom: 24px;
      margin-bottom: 0;
    }

    .no-report {
      margin: 56px auto;
      text-align: center;
      color: #{colors.$blue-gray-700};
      opacity: 0.5;
    }

    .breakdown-by-gst {
      .net-sales, .gst {
        .label {
          padding-left: 16px;
        }
      }
    }

    .total-sales,
    .total-sales ~ div {
      @include card.container;
      display: grid;
      gap: 24px;
      padding: 24px;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.1);
      margin-bottom: 24px;
      border-radius: 8px;

      .label {
        font-size: 16px;
        color: #838393;
      }
    }

    .total-sales .value {
      font-size: 34px;
      color: colors.$blue-gray-900;
      font-weight: 600;
    }

    .total-sales ~ div {
      .key-value-pair {
        display: grid;
        grid-template-areas: 'label value';
        align-items: center;
        grid-template-columns: 150px 1fr;
        gap: 60px;

        .label {
          grid-area: label;
          display: flex;
          font-size: 16px;
          color: #838393;
          .info {
            margin-left: 4px;
            position: relative;
            img {
              margin-top: 3px;
            }
            .info-text {
              visibility: hidden;
              position: absolute;

              z-index: 2;
              width: 160px;
              padding: 16px;

              background-color: #fff;
              border-radius: 8px;
              box-shadow: 0px 8px 12px 0px rgba(9, 30, 66, 0.15), 0px 0px 1px 0px rgba(9, 30, 66, 0.31);

              color: colors.$blue-gray-900;
              text-align: center;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
              letter-spacing: 0.03px;

              top: -45px;
              left: 120%;
            }

            .info-text::before {
              content: '';
              position: absolute;
              transform: rotate(45deg);
              background-color: #fff;
              padding: 5px;
              z-index: 1;

              top: 47%;
              left: -2%;
            }
          }

          .info:hover {
            .info-text {
              visibility: visible;
            }
          }
        }

        .value {
          color: colors.$blue-gray-900;
          font-weight: 500;
          font-size: 16px;
        }

        @include viewport.mobile {
          display: unset;
          .value {
            word-break: break-all;
          }
        }
      }
    }
  }
}
