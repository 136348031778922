@use "../colors";
@use "../viewport";

.sms-marketing-campaign-preview-widget {
  @include viewport.mobile() {
    max-height: 90vh;
  }

  .header {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    padding: 20px;
    border-bottom: 1px solid colors.$blue-gray-100;
    display: flex;
    justify-content: space-between;

    img {
      cursor: pointer;
    }
  }

  .body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 32px 32px;
    grid-gap: 32px;

    @include viewport.mobile() {
      display: block;
    }

    .campaign-details {
      padding-top: 32px;
      max-width: 320px;

      .label {
        font-weight: 400;
        font-size: 12px;
        color: colors.$blue-gray-500;
        margin-bottom: 6px;
      }

      .detail {
        font-size: 16px;
        margin-bottom: 24px;
        font-weight: 400;

        span {
          font-weight: 500;
          color: colors.$blue-800;
        }

        .actual-cost {
          margin-top: 24px;
        }
      }

      .cost-info {
        display: flex;
        align-items: flex-start;
        background-color: colors.$blue-gray-50;
        padding: 12px;
        font-size: 12px;
        color: colors.$blue-gray-600;
        border-radius: 6px;
        max-width: 320px;

        img {
          width: 14px;
          margin: 2px 6px 0 0;
        }
      }
    }
  }

  .actions {
    display: flex;
    padding: 20px;
    background-color: colors.$light-gray-50;
    box-shadow: 0px 1px 0px 0px #f1f1f5 inset;
    align-items: center;

    &.draft {
      justify-content: flex-end;
    }

    &.sent,
    &.scheduled {
      justify-content: space-between;

      .status-info {
        display: flex;
        align-items: center;

        .status {
          padding: 4px 12px;
          border-radius: 100px;
          margin-right: 8px;
        }

        img {
          margin-left: 10px;
          height: 13px;
          cursor: pointer;
        }
      }

      .status-action {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        color: colors.$rose-800;
        text-decoration: underline;
        cursor: pointer;
      }

      .helper-text {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.004em;
        color: colors.$blue-gray-500;
      }
    }

    &.sent {
      .status {
        color: colors.$green-700;
        background-color: colors.$green-50;
      }
    }

    &.scheduled {
      .status {
        color: colors.$orange-700;
        background-color: colors.$yellow-100;
      }
    }

    @include viewport.mobile() {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
    }

    button {
      margin-left: 12px;
    }
  }
}
