@use "../constants" as constants;
@use "../card" as card;
@use "../typography" as typography;

.printer-list-page .list,
.printer-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 15px;

  .printer {
    @include card.container;

    display: grid;
    grid-template-areas:
      'name               name'
      'id-label           id'
      'password-label     password'
      'created-time-label created-time'
      'actions            actions';
    grid-template-columns: max-content 1fr;
    overflow: hidden;

    .name,
    .id-label,
    .id,
    .password-label,
    .password,
    .created-time-label,
    .created-time,
    .actions {
      padding: 15px;
    }

    .name {
      @include typography.h2;
      grid-area: name;
      border-bottom: constants.$line;
    }

    .id-label {
      grid-area: id-label;
    }

    .created-time-label {
      grid-area: created-time-label;
      color: #696974;
    }

    .password-label {
      grid-area: password-label;
    }

    .id {
      grid-area: id;
      justify-self: end;
    }

    .password {
      grid-area: password;
      justify-self: end;
    }

    .created-time {
      grid-area: created-time;
      justify-self: end;
      color: #696974;
    }

    .actions {
      grid-area: actions;
      border-top: constants.$line;

      button {
        cursor: pointer;
        width: 100px;
      }
    }
  }
}
