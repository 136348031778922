@use "constants"as constants;

progress {
  /* style rules */
  border: 1px solid #ccc;
  background: white;
  height: 8px;
  border-radius: constants.$border-radius;
}

progress::-webkit-progress-bar {
  border-radius: constants.$border-radius;
  background: white;
}

progress::-webkit-progress-bar,
progress::-webkit-progress-value,
progress::-moz-progress-bar {
  border-radius: constants.$border-radius;
}
progress::-webkit-progress-value {
}
progress::-moz-progress-bar {
}
