@use "../card" as card;
@use "../typography" as typography;
@use "../viewport" as viewport;

.restaurant-customers {
  .table-header {
    display: none;
    @include viewport.desktop {
      @include card.container;
      @include card.stack;
      padding: 0 15px;
      margin-bottom: 20px;
      grid-template-columns: repeat(4, 1fr);

      & p {
        font-weight: 500;
      }
    }
  }

  .customer {
    @include card.container;
    @include card.stack;
    padding: 15px;
    margin-bottom: 20px;

    @include viewport.desktop {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .customer > p > span {
    font-weight: 500;
    @include viewport.desktop {
      display: none;
    }
  }
}
