@use "./colors";
@use "./scrollbar";
@use './viewport';

.form-iso-date-picker-input-and-dropdown {
  &.invalid {
    > .DayPickerInput {
      input {
        border: 1px solid;
      }
    }
  }

  > .DayPickerInput {
    input {
      background-image: url('../images/icon-calendar.svg');
      background-repeat: no-repeat;
      background-position: 95% center;
      background-size: 16px;
      padding: 10px 14px;
      border-radius: 8px;

      &:focus {
        outline: none;
      }
    }

    > .DayPickerInput-OverlayWrapper {
      top: inherit;
      z-index: 9999;

      @include viewport.mobile {
        height: 100vh;
      }

      @include viewport.tablet {
        height: 100vh;
      }

      @include viewport.desktop {
        height: unset;
      }

      > .DayPickerInput-Overlay {
        box-shadow: none;

        > .DayPicker {
          margin-bottom: 4px;
          padding-top: 8px;

          > .DayPicker-wrapper {
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            padding: 0;
            position: relative;

            > .DayPicker-NavBar {
              position: absolute;
              right: 0;
              top: -8px;

              > .DayPicker-NavButton {
                width: 24px;
                height: 24px;
                background-repeat: no-repeat;
                background-size: 24px;
                background-position: center;

                &--prev {
                  background-image: url('../images/icon-chevron-down-filled.svg');
                  transform: rotate(90deg);
                }

                &--next {
                  right: 10px;
                  background-image: url('../images/icon-chevron-down-filled.svg');
                  transform: rotate(270deg);
                }
              }
            }

            .DayPicker-Month {
              table-layout: fixed;
              border-spacing: 14px 12px;
              border-collapse: separate;
              margin: 0;

              .month-year-selectors {
                position: absolute;
                top: 0px;
                display: flex;

                .selector {
                  font-weight: 500;
                  &.month-selector {
                    margin-right: 12px;
                    min-width: 150px;
                  }

                  &.year-selector {
                    min-width: 100px;
                  }

                  .form-label {
                    background-color: red;
                  }

                  .drop-down-input {
                    background-color: transparent;
                    text-align: center;
                    position: relative;

                    .value {
                      padding: 4px 8px;
                      background-color: colors.$light-gray-100;
                      border-radius: 8px;
                    }

                    .down-arrow-icon {
                      display: none;
                    }

                    .options {
                      margin-top: 5px;
                      max-height: 280px;
                      overflow-y: scroll;
                      @include scrollbar.hide();
                      text-align: left;
                      font-weight: 400;
                      background-color: colors.$white;
                      border: 1px solid colors.$blue-gray-100;
                      border-radius: 8px;

                      .option {
                        display: flex;
                        justify-content: space-between;
                        padding: 10px;
                        border-bottom: 1px solid colors.$blue-gray-100;

                        /* Remove bottom margin if last option */
                        &:last-child {
                          border-bottom: 0;
                        }
                      }
                    }
                  }
                }
              }

              .DayPicker-Weekday {
                padding-top: 40px;
              }

              .DayPicker-Day {
                color: colors.$blue-gray-900;
                font-weight: 500;

                &:hover {
                  background-color: unset !important;
                }

                &--disabled {
                  color: colors.$blue-gray-100;
                }

                &--selected {
                  background-color: transparent;
                  color: colors.$blue-500;
                }
              }
            }
          }

          &-Day {
            &:hover {
              background-color: unset !important;
            }

            // &--disabled {
            // }

            &--selected {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}
