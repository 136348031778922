@use "../card";
@use "../form";
@use "../buttons";

.table-booking-settings-edit-widget {
  @include card.container;

  form {
    @include card.content;
    margin-top: 20px;

    .form-items > .inline-title {
      margin: 0 -18px;
      label {
        white-space: nowrap;
      }
    }
  }

  .seating-area-info {
    @include card.container;
    @include card.stack;
    margin: 0.8rem 0rem 0.8rem 0rem;
    padding: 1rem;

    button {
      @include buttons.primary-transparent;
    }

    .options {
      margin-top: 1rem;
      .area-name {
        @include card.container;
        @include card.stack;
        margin: 1.2rem 0rem 1.2rem 0rem;
      }

      .info {
        @include card.upper;
        @include form.inline;
      }

      .actions {
        @include card.lower;
        @include card.round-buttons;
        .action {
          @include card.round-button;
        }
      }
    }
  }

  .request-info {
    @include card.container;
    @include card.stack;
    margin: 0.8rem 0rem 0.8rem 0rem;
    padding: 1rem;

    button {
      @include buttons.primary-transparent;
    }

    .options {
      margin-top: 1rem;
      .template {
        @include card.container;
        @include card.stack;
        margin: 1.2rem 0rem 1.2rem 0rem;
      }

      .info {
        @include card.upper;
        @include form.inline;
      }

      .actions {
        @include card.lower;
        @include card.round-buttons;
        .action {
          @include card.round-button;
        }
      }
    }
  }

  .table-booking-unavailability-container > label {
    align-self: flex-start !important;
  }

  .add-unavailable-date-button {
    @include buttons.primary-transparent;
  }
}
