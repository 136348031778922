@use '../colors' as colors;

.table-booking-activity-logs-list-widget {
  .activity-logs-category {
    .activity-logs-category-date {
      margin-top: 20px;
      color: colors.$blue-gray-900;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 8px;
      font-weight: 500;
    }

    .activity-logs {
      display: grid;
      gap: 8px;
    }
  }
}
