@use "../card"as card;
@use "../constants"as constants;
@use "../typography"as typography;
@use "../viewport"as viewport;

$hero-width: 83vw;

.store-website-page {
  //
  display: grid;
  grid-row-gap: 30px;
  margin-bottom: 40px;

  @include viewport.mobile {
    .heros {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: $hero-width;
      grid-auto-columns: $hero-width;
      grid-auto-flow: column;
      overflow-x: auto;
    }

    .heros::-webkit-scrollbar {
      display: none;
    }
    .hero {
      width: $hero-width;
    }
  }

  @include viewport.tablet {
    .heros {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
  }

  .hero {
    @include card.container;
    overflow: hidden;

    .content {
      @include card.content;
    }

    .title {
      @include typography.h2;
    }

    .description {
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 21px;
      color: #666666;
    }

    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .description {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
  }

  .ctas {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;

    a:link,
    a:visited,
    a:hover,
    a:active {
      text-decoration: none;
    }

    .cta {
      padding: 18px;
      padding-bottom: 30px;
      border-radius: 8px;
      @include card.container;

      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #ffffff;
      }

      .icon {
        font-size: 30px;
        color: white;
        // img {
        //   height: 18px;
        //   width: auto;
        // }
      }

      &.food-order {
        background: linear-gradient(148.83deg, #3a91ec 0.88%, #426ed9 100%);
        // box-shadow: 0px 16px 20px rgba(36, 166, 210, 0.3);
      }

      &.book-a-table {
        background: linear-gradient(150.26deg, #ff825f 11.6%, #ff4c51 86.35%);
        // box-shadow: 0px 16px 20px rgba(255, 103, 88, 0.3);
      }
    }
  }

  .quick-links {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 0.5fr));
    grid-gap: 10px;

    .quick-link {
      @include card.container;
      padding: 10px;
      cursor: pointer;

      .icon {
        width: 40px;
        margin: 5px auto;
        color: #1b2749;
      }

      .label {
        text-align: center;
        font-style: normal;
        font-weight: 300;
        font-size: 11px;
        line-height: 16px;
        color: #7f8592;
        padding-top: 3px;
      }
    }
  }

  .about {
    @include card.container;
    @include card.content;
    display: grid;
    grid-template-areas:
      "a b"
      "a c";
    grid-template-columns: min-content 1fr;
    align-items: center;
    cursor: pointer;

    .icon {
      i {
        grid-area: a;
        border-radius: 50%;
        padding: 5px;
        background-color: var(--color-secondary);
        color: white;
      }

      margin-top: 1px;
      margin-right: 15px;
    }

    .title {
      grid-area: b;
      @include typography.h3;
      text-transform: uppercase;
      margin: 8px 0;
    }

    .description {
      grid-area: c;
      font-style: normal;

      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.1px;
      color: #666666;
    }
  }

  $photo-width: 40vw;

  .photos {
    display: grid;
    grid-row-gap: 20px;
    @include card.container;
    @include card.content;
    padding-bottom: 25px;

    .header {
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon {
        i {
          grid-area: a;
          border-radius: 50%;
          padding: 5px;
          background-color: var(--color-secondary);
          color: white;
        }

        margin-top: 1px;
        margin-right: 15px;
      }

      .title {
        grid-area: b;
        @include typography.h3;
        text-transform: uppercase;
        margin: 8px 0;
      }
    }

    .body {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    }

    .image {
      img {
        border-radius: constants.$border-radius;
      }
    }
  }
}
