@use "../viewport"as viewport;
@use "../typography"as typography;

#intro-widget {
  //
  @include viewport.mobile {
    padding: 10px 25px;
    #logo {
      display: none;
    }
  }

  @include viewport.tablet {
    display: grid;
    padding: 25px;
    background-color: white;
    border-right: 1px solid #eee;
    #logo img {
      height: 25px;
      margin-bottom: 20px;
    }
  }

  .graphic {
    text-align: center;

    img {
      width: 70%;
      margin-bottom: 10px;
    }
  }

  .marketing-message {
    @include typography.h1;
    text-align: center;
    // margin-bottom: 20px;
  }
}
