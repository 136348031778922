@use "../viewport"as viewport;

.platform-settings-page {
  .body {
    display: grid;
    grid-gap: 20px;
    @include viewport.tablet {
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }
  }
}
