@use '../colors';

.loading-widget {
  display: grid;
  gap: 16px;
  padding: 32px;
  border-radius: 10px;
  text-align: center;
  width: 50vw;
  min-width: 310px;
  max-width: 573px;
  box-sizing: border-box;

  > .title {
    color: colors.$blue-gray-900;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }

  > .message {
    color: colors.$blue-gray-700;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}
