@use "../card";
@use '../colors';
@use '../constants';
@use '../typography';
@use '../viewport';

.store-sales-report-filter {
  @include viewport.mobile {
    &.desktop-view {
      display: none;
    }
  }

  .header {
    display: flex;
    .title {
      @include typography.h1;
      flex: 1;
    }
    img {
      cursor: pointer;
    }
    @include viewport.tablet {
      display: none;
    }
  }

  .filters {
    display: grid;
    grid-template-rows: auto;
    gap: 20px;
    grid-template-columns: 240px 240px;
    margin-bottom: 15px;
    @include viewport.mobile {
      display: flex;
      flex-direction: column;
      grid-template-columns: 1fr 1fr;
      padding: 30px 30px 0;
    }
    > .form-item {
      label {
        color: colors.$blue-gray-500;
      }
    }
  }
  .date-picker {
    .form-iso-date-picker-input {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 240px 240px;
      gap: 20px;
      margin: 20px 0px;

      @include viewport.mobile {
        display: flex;
        flex-direction: column;
        grid-template-columns: 1fr 1fr;
        padding: 0 30px 30px;
      }

      .sub-label {
        color: colors.$blue-gray-500;
        position: absolute;
        top: 150%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        @include viewport.mobile {
          top: 100%;
        }
      }

      .DayPicker {
        margin-left: 25px;
        margin-top: 10px;
        border-radius: 8px;

        @include viewport.mobile {
          margin-left: 0px;
        }
      }

      .DayPicker-NavButton--prev {
        margin-right: 1.5em;
        background-image: url('../images/icon-chevron-left.svg');
      }

      .DayPicker-NavButton--next {
        background-image: url('../images/icon-chevron-right.svg');
      }

      .current-date {
        .DayPicker-Day--today {
          color: colors.$white !important;
          background-color: colors.$blue-600 !important;
          outline-offset: -5px !important;
          border-radius: 25% !important;
        }
      }

      .selected-date {
        .DayPicker-Day--today {
          color: colors.$blue-gray-900;
          font-weight: normal;
        }
        .DayPicker-Day--highlighted {
          color: colors.$white !important;
          background-color: colors.$blue-600 !important;
          outline-offset: -5px !important;
          border-radius: 25% !important;
        }
      }

      .DayPicker-WeekdaysRow {
        text-transform: uppercase;
        .DayPicker-Weekday {
          // Sunday and Monday
          &:first-child,
          &:nth-child(2) {
            &::after {
              content: 'N';
            }
          }
          // Tuesday
          &:nth-child(3) {
            &::after {
              content: 'E';
            }
          }
          // Wednesday
          &:nth-child(4) {
            &::after {
              content: 'D';
            }
          }
          // Thursday
          &:nth-child(5) {
            &::after {
              content: 'U';
            }
          }
          // Friday
          &:nth-child(6) {
            &::after {
              content: 'I';
            }
          }
          // Saturday
          &:nth-child(7) {
            &::after {
              content: 'T';
            }
          }
        }
      }
    }
  }

  .month-picker {
    margin-bottom: 15px;
    width: 240px;
  }
}
