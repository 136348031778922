@use "../card"as card;
@use "../constants"as constants;
@use "../scrollbar"as scrollbar;
@use "../typography"as typography;
@use "../viewport"as viewport;

.online-ordering {
  padding-top: constants.$header-height;

  header {
    position: fixed;
    top: 0;
    width: 100%;
  }

  main {
    padding: constants.$horizontal-gap;
    padding-top: 20px;
    margin-top: var(--safe-area-inset-top);
  }

  footer {
    display: none;
  }

  @include viewport.mobile {
    main {
      padding-bottom: 100px;
    }
    footer {
      display: grid;
      position: fixed;
      bottom: 0;
      width: 100%;
    }
  }

  @include viewport.desktop {
    main {
      max-width: constants.$max-body-width;
      margin: 0 auto;
    }
  }
}
