@use '../card'as card;
@use '../constants'as constants;
@use '../typography'as typography;

.domain-card {
  @include card.container;
  display: grid;
  grid-template-rows: 1fr min-content;
  cursor: pointer;
  background-color: #fafafb;

  .body {
    @include card.upper;
    display: grid;
    padding: 15px 18px;
    grid-template-columns: 1fr min-content;
    grid-template-rows: min-content min-content 1fr;
    grid-template-areas:
      'domain-name qr-code'
      'restaurant-name qr-code'
      'store-name qr-code';

    .name {
      @include typography.h2;
      grid-area: domain-name;
      align-self: flex-start;
      word-break: break-all;
    }

    .restaurant-name {
      @include typography.h4;
      grid-area: restaurant-name;
      align-self: flex-start;
    }

    .store-name {
      grid-area: store-name;
      align-self: flex-start;
    }

    .qr-code-widget {
      grid-area: qr-code;
    }
  }

  .description {
    @include typography.h6;
    font-size: 13px;
    grid-area: b;
    align-self: flex-start;
  }

  .actions {
    @include card.lower;
    @include card.round-buttons;

    .action {
      @include card.round-button;
    }
  }
}
