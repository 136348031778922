@use '../colors';
@use '../viewport';

body:has(.email-order-invoice-widget) {
  overflow: hidden;
}

.email-order-invoice-widget {
  display: grid;
  grid-template-columns: minmax(0px, 570px);
  max-height: calc(100vh - 100px);

  .header {
    display: grid;
    grid-template-areas: 'title button';
    grid-template-columns: 1fr min-content;
    margin: 32px;

    .title {
      grid-area: title;
      align-self: center;
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      color: #171725;
    }

    .close-button {
      grid-area: button;
      align-self: center;
      cursor: pointer;
    }
  }

  form {
    margin: 0px 32px;

    label {
      color: colors.$blue-gray-900;
    }
    .react-tagsinput-tag {
      margin-bottom: 12px;
      color: #3b825d;
    }
    .react-tagsinput-input[value=''] {
      color: colors.$admin-portal-caption;
    }
    .react-tagsinput-input {
      color: colors.$blue-gray-900;
    }
  }

  .preview {
    display: grid;
    // 100vh - top/bottom spacing - other components
    max-height: calc(100vh - 100px - 382px);
    overflow: auto;

    > .label {
      margin: 32px 32px 8px 32px;
      color: colors.$blue-gray-900;
    }

    .container {
      border: 1px solid colors.$blue-gray-100;
      border-radius: 12px;
      max-width: 506px;
      overflow-y: auto;
      scrollbar-width: none;
      justify-self: center;

      .export {
        overscroll-behavior: contain;
      }

      .secondary-font {
        font-size: 12px;
        line-height: 18px;
        color: colors.$blue-gray-500;
      }

      .normal-font {
        font-size: 14px;
        line-height: 24px;
        color: colors.$restoplus-dark;
        font-weight: 400;
      }

      .normal-bold-font {
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
        color: colors.$restoplus-dark;
      }

      .text-align-right {
        text-align: right;
      }

      .text-align-center {
        text-align: center;
      }

      .dot {
        font-size: 10px;
        line-height: 18px;
        width: 4px;
        margin: 0px 10px;
        color: colors.$restoplus-dark;
        font-weight: 600;
        text-decoration: none;
        cursor: inherit;
      }

      .border-top {
        border-top: 1px solid colors.$blue-gray-100;
      }

      .color-green {
        color: colors.$teal-600;
      }

      .color-red {
        color: colors.$rose-800;
      }

      .email-banner {
        display: grid;
        padding: 39px 30px;
        grid-template-columns: 50px minmax(1px, 542px);
        background-color: colors.$blue-gray-700;
        border-radius: 10px 10px 0px 0px;
        grid-gap: 16px;

        img {
          width: 50px;
          height: 50px;
        }

        .email-subject {
          color: colors.$white;
          text-align: left;
          font-size: 40px;
          line-height: 44px;
          font-weight: 500;
          vertical-align: middle;
          margin-left: 16px;
        }
      }

      .store-details {
        display: grid;
        padding: 20px;
        grid-template-columns: 95px minmax(1px, 488px);
        grid-gap: 15px;
        border-bottom: 1px solid colors.$blue-gray-100;
        background-color: colors.$white;
        justify-items: center;
        margin-bottom: 4px;
        align-items: center;

        .logo {
          width: 95px;
          height: 95px;
          border: 1px solid colors.$blue-gray-100;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          img {
            width: 95px;
            height: 95px;
            border-radius: 8px;
          }
        }

        .detail {
          justify-self: flex-start;

          .name {
            font-size: 24px;
            line-height: 34px;
            color: colors.$blue-gray-900;
            font-weight: 600;
          }

          .abn {
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            color: colors.$blue-gray-900;
          }

          .phone {
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            color: colors.$blue-gray-900;
          }
          .address {
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
            color: colors.$blue-gray-900;
          }
        }
      }

      .order-invoice-info {
        padding: 20px 27.5px;
        background-color: colors.$white;

        .details {
          margin-bottom: 12px;
          margin-top: 4px;
          padding: 12px 24px 16px 24px;

          border: 2px solid colors.$blue-gray-100;
          border-radius: 12px;

          display: grid;
          gap: 12px;
        }

        .detail {
          display: grid;
          grid-template-columns: max-content 1fr;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .label {
          font-weight: 500;
          color: colors.$blue-gray-900;
        }

        .data {
          justify-items: flex-end;
          color: colors.$blue-gray-900;
        }
      }

      .order-summary {
        padding: 0px 27.5px 27px 27.5px;
        background-color: colors.$white;

        .summary {
          padding: 28px 24px 16px 24px;
          border: 2px solid colors.$blue-gray-100;
          border-radius: 20px;
        }

        .title {
          width: 100%;
          text-align: center;
          font-size: 18px;
          line-height: 24px;
          font-weight: 500;
          padding: 6px 0px 2px 0px;
          color: colors.$blue-gray-900;
        }

        .label-format {
          display: grid;
          grid-template-columns: minmax(1px, 434px) 30px 71px;
          padding: 2px 0px 4px 0px;
          border-bottom: 1px solid colors.$blue-gray-100;
        }

        .items {
          padding: 4px 0px 12px 0px;
          border-bottom: 1px solid colors.$blue-gray-100;
          .format {
            display: grid;
            grid-template-columns: minmax(1px, 434px) 30px 71px;
            padding: 2px 0px;
            min-height: 28px;
          }
        }

        .subtotal {
          margin-top: 2px;
        }

        .goods-services-format {
          display: grid;
          grid-template-columns: minmax(1px, 430px) 107px;
          padding: 2px 0px;
        }

        .gst-amount {
          padding-bottom: 5px;
        }
        
        .gst-free-items-text-disclaimer {
          padding: 8px 0 4px 0;
          border-top: 1px solid colors.$blue-gray-100;
        }
      }

      .payment-status {
        display: flex;
        justify-content: center;

        .label {
          max-width: 69%;
          width: 100%;
          padding: 12px 0px;
          border-style: solid;
          border-color: #dee0e6;
          border-width: 1px 0px 1px 0px;
          font-size: 24px;
          font-weight: 600;
          line-height: 28.8px;
          text-align: center;
          color: colors.$teal-600;
          text-transform: uppercase;
          &.color-red {
            color: colors.$rose-800;
          }
          &.color-yellow {
            color: colors.$yellow-700;
          }
        }
      }

      .order-invoice-customer-review-link-button {
        display: flex;
        align-items: center;
        justify-self: center;
        border: 1px solid colors.$material-gray-300;
        background-color: colors.$white;   
        padding: 16px 24px;
        box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.10);
        border: none;
        border-radius: 48px;
        margin-top: 32px;

        > .label {
          display: flex;
          align-items: center;

          img {
            width: 44px;
            height: 44px;
          }

          span {
            color: colors.$blue-gray-900;
            font-size: 18px;
            font-weight: 500;
            padding-left: 16px;
          }
        }
      }

      .footer {
        margin-top: 32px;
        padding-bottom: 46px;
        background-color: colors.$white;
        display: flex;
        grid-gap: 6px;

        .label {
          line-height: 20px;
          margin: 6px 0px auto auto;
          margin-top: 6px;
        }

        .logo {
          width: 100px;
          height: 28px;
          margin: auto auto auto 0px;
        }
      }
      
      .hidden {
        display: none;
      }
    }
  }

  .actions {
    margin-top: 32px;
    position: sticky;
    display: flex;
    padding: 20px 20px 20px 36px;
    align-items: center;
    bottom: 0px;
    background-color: colors.$material-gray-300;

    .download-invoice {
      display: grid;
      grid-template-columns: min-content 1fr;
      column-gap: 8px;
      cursor: pointer;
      text-decoration: none;
      .label {
        color: colors.$blue-800;
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
      }
    }

    .cancel-button {
      border: 1px solid colors.$blue-gray-100;
      margin-right: 16px;
      margin-left: auto;
    }

    .send-button {
      border: 1px solid colors.$blue-600;
      background-color: colors.$blue-600;
      color: colors.$white;
      &.disabled {
        background-color: colors.$blue-300 !important;
        color: colors.$white !important;
        border-color: colors.$blue-300 !important;
      }
    }
  }
}

@include viewport.mobile {
  .ReactModalPortal:has(.email-order-invoice-modal) {
    .ReactModal__Overlay--after-open {
      background-color: #0000005e !important;
    }
    .ReactModal__Content.ReactModal__Content--after-open {
      position: absolute !important;
      width: unset !important;

      .modal {
        max-width: unset;
        max-height: calc(100vh - 50px);
      }
    }
  }

  .email-order-invoice-modal {
    .email-order-invoice-widget {
      max-height: unset;

      .header {
        .title {
          font-size: 20px;
          line-height: 24px;
        }
      }

      .form-item {
        label {
          font-size: 14px;
          line-height: 21px;
        }
        .react-tagsinput-tag {
          font-weight: 500;
          line-height: 19.5px;
        }
        .react-tagsinput-input {
          font-size: 13px;
          font-weight: 300;
          line-height: 19.5px;
          padding: 12px 14px;
        }
      }
    }

    .preview {
      max-height: calc(100vh - 382px);
      margin: 0px 32px 32px 32px;

      > .label {
        margin: 32px 0px 8px 0px;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
      }

      .container {
        .normal-font {
          font-size: 12px;
          line-height: 18px;
          font-weight: 400;
        }

        .normal-bold-font {
          font-size: 12px;
          line-height: 18px;
        }

        .email-banner {
          padding: 18px 18px;
          grid-template-columns: 28px minmax(1px, 542px);
          align-items: center;
          img {
            width: 31px;
            height: 31px;
          }
          .email-subject {
            font-size: 24px;
            margin-left: unset;
            font-weight: 400;
          }
        }

        .store-details {
          margin-bottom: 2px;
          padding: 15px 5px;
          grid-gap: 5px;
          .logo {
            width: 70px;
            height: 70px;
            img {
              width: 70px;
              height: 70px;
            }
          }
          .detail {
            .name {
              font-size: 18px;
              font-weight: 500;
              line-height: 28px;
            }
            .abn {
              font-size: 12px;
              line-height: 18px;
            }
            .phone {
              font-size: 12px;
              line-height: 18px;
            }
            .address {
              font-size: 12px;
              line-height: 18px;
            }
          }
        }

        .order-invoice-info {
          padding: 11px 20px 8px 20px;
          .details {
            padding: 14px 18px;
          }
        }

        .order-summary {
          padding: 8px 20px 11px 20px;

          .title {
            font-weight: 500;
          }
        }
      }
      .footer {
        .logo {
          width: 85px;
          height: 24px;
        }
      }
    }

    .actions {
      margin-top: unset;

      .download-invoice {
        .label {
          font-size: 12px;
          line-height: 18px;
        }
      }

      .cancel-button {
        .label {
          font-size: 12px;
          line-height: 18px;
        }
      }

      .send-button {
        .label {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
}
