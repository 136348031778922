@use "../viewport"as viewport;
@use "../card"as card;
@use "../typography"as typography;

#auth-layout {
  display: grid;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  grid-template-rows: min-content 1fr 0.1fr;
  grid-template-columns: 1fr;

  .nav-bar {
    background-color: white;
    padding: 2px 10px;
    text-align: center;
    box-shadow: 0px 0px 3px rgba(104, 122, 155, 0.25);

    img {
      height: 30px;
      margin-top: 10px;
    }
  }

  .contents {
    display: grid;
    justify-content: center;
  }


  .auth-page {
    @include viewport.tablet {
      @include card.container;
      display: grid;
      grid-template-columns: 1fr 1fr;
      max-width: 700px;
      overflow: hidden;
      margin: 100px auto;
    }

    .auth-actions {
      .spinner {
        text-align: center;
      }
    }

    .page {
      margin: 1.5rem;
      align-self: center;
      .links {
        white-space: pre;
        margin-top: 1.5rem;
        text-align: center;
      }
      button {
        width: 100%;
      }
      .action {
        text-align: center;
      }
    }


  }

}


#firebaseui {
  padding: 20px;
  @include viewport.tablet {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.firebaseui-idp-text {
  font-family: Poppins !important;
}

.firebaseui-idp-button {
  border-radius: 10px !important;
}

#login-widget,
#signup-widget,
#password-reset-widget,
#auth-action-handler-widget {
  min-width: 200px;
  max-width: 350px;
  display: flex;
  flex-direction: column;

  @include viewport.mobile {
    @include card.container;
  }

  @include viewport.tablet {
  }

  .title {
    @include card.header;
  }

  form {
    @include card.content;
    flex: 1;
  }

  .footer {
    @include card.footer;
  }
}

// .firebaseui-info-bar {
//   box-shadow: none !important;
//   -webkit-box-shadow: none !important;
//   -moz-box-shadow: none !important;
//   border: none !important;
// }

.mdl-shadow--2dp {
  box-shadow: none !important;
}

.mdl-shadow--3dp {
  box-shadow: none !important;
}

.mdl-shadow--4dp {
  box-shadow: none !important;
}

.mdl-shadow--6dp {
  box-shadow: none !important;
}

.mdl-shadow--8dp {
  box-shadow: none !important;
}

.mdl-shadow--16dp {
  box-shadow: none !important;
}

.mdl-shadow--24dp {
  box-shadow: none !important;
}
