@use "../card"as card;
@use "../buttons"as buttons;
@use '../typography'as typography;
@use '../colors'as colors;
@use "../viewport"as viewport;

.custom-payment-settings-widget {
  @include card.container;

  form {
    margin: 20px 0px;
  }

  .empty-custom-payments {
    padding: 0px 20px;
  }
  @include viewport.mobile {
    .form-items {
      margin: 0px 20px;
    }
  }

  .custom-payments {
    .custom-payment-list-widget {
      .custom-payment-list-item-widget {
        @include card.container;
        @include card.stack;
        display: grid;
        grid-template-rows: 1fr min-content;
        background-color: transparent;
        margin: 20px;

        .name {
          @include typography.h2;
          padding: 20px 0px 20px 20px;
          font-weight: 400;
        }

        .actions {
          border: 1px solid colors.$blue-gray-50;
          background-color: colors.$light-gray-50;
          display: flex;
          @include card.lower;
          @include card.round-buttons;
          padding: 8px;
          border-radius: 10px;
          .action {
            color: colors.$blue-gray-700 !important;
            &:first-child {
              padding-left: 8px;
            }
            @include card.round-button;
          }
        }
      }
    }
  }

  .add-custom-payment-method-button {
    @include buttons.primary-transparent;
    border-radius: 3px;
    margin: 18px;
  }
}
