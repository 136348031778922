@use "../colors";

.item-description-view-widget {
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;

  > .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    // Add top margin to the
    // element next to the element
    // with `title` class name
    & + * {
      margin-top: 8px;
    }
  }

  > .modifier {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }

  > .simple {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;

    // Add top margin to the element with
    // `title` class name next to the element
    // with `simple` class name, only if the
    // element with `simple` class name
    // is the first child
    &:first-child + .title {
      margin-top: 8px;
    }
  }

  > .break {
    height: 8px;

    // Remove top margin of the
    // element next to the element
    // with `break` class name
    & + * {
      margin-top: 0;
    }
  }
}
