@use '../colors';
@use '../viewport';

.terminate-agreement-modal {
  padding: 32px 24px;

  @include viewport.tablet {
    max-width: 540px;
  }

  .header {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: colors.$blue-gray-900;
  }

  .body {
    margin: 20px 0;

    .notes {
      margin: 20px;
      margin-top: 0;

      .note {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: colors.$blue-gray-900;
      }
    }

    .signed-by-form {
      display: grid;
      gap: 10px 15px;

      @include viewport.tablet {
        grid-template-columns: repeat(2, 1fr);
      }

      > .full-name {
        @include viewport.tablet {
          grid-column: span 2;
        }
      }

      > .form-item {
        > label {
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          margin: 0;
        }

        > .form-input {
          margin-top: 5px;
        }
      }
    }
  }

  .actions {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    margin: 0 20px;

    > button {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    .go-back-button {
      background-color: white;
      border: 1px solid colors.$light-gray-400;
      border-radius: 4px;
      color: colors.$blue-gray-700;
      box-shadow: none;
    }
  }
}

.sms-marketing-terms-and-conditions-widget {
  max-width: 800px;
  max-height: 65vh;

  .header {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr min-content;
    z-index: 1;
    padding: 17px;
    border-bottom: 1px solid colors.$blue-gray-100;
    width: calc(100% - 36px);
    position: fixed;
    background-color: white;
    border-radius: 10px 10px 0 0;

    .label {
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      color: colors.$blue-gray-900;
    }

    .close-icon {
      align-self: center;
      width: 35px;
      cursor: pointer;
    }

    @include viewport.tablet {
      position: absolute;
    }
  }

  .body {
    padding: 90px 20px;

    .terms-and-conditions {
      > .title {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        color: colors.$blue-gray-900;
      }

      .last-updated-date-label {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: colors.$blue-gray-500;
        margin: 10px 0;
      }

      .links {
        display: grid;
        padding: 10px 0;

        > .link {
          margin-bottom: 10px;
        }
      }

      .link {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        color: colors.$blue-600;
        margin-top: 10px;
        margin-bottom: 20px;
        margin-left: 12px;
        display: flex;
        text-decoration: none;

        .bullet {
          margin-right: 10px;
          font-size: 20px;
          color: colors.$blue-gray-500;
        }

        > img {
          margin-left: 3px;
        }

        .label {
          text-decoration: underline;
        }
      }

      > .sub-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: colors.$blue-gray-900;
        margin: 15px 0;
      }

      .temrs {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: colors.$blue-gray-900;
      }
    }

    .signed-by-labels,
    .signed-by-form {
      display: grid;
      gap: 10px 15px;
      margin-top: 20px;

      @include viewport.tablet {
        grid-template-columns: repeat(2, 1fr);
      }

      > .full-name {
        @include viewport.tablet {
          grid-column: span 2;
        }
      }

      > .form-item {
        > label {
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          margin: 0;
        }

        > .form-input {
          margin-top: 5px;
        }
      }

      > .signed-by {
        > .label {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          color: colors.$blue-gray-500;
        }

        > .value {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          margin-top: 8px;
        }
      }
    }
  }

  .actions {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr max-content;
    gap: 15px;
    background-color: colors.$light-gray-50;
    padding: 17px;
    border-top: 1px solid colors.$blue-gray-100;
    position: fixed;
    bottom: 0;
    width: calc(100% - 36px);
    border-radius: 0 0 10px 10px;

    @include viewport.tablet {
      position: absolute;
    }

    .cancel-button,
    .close-button {
      background-color: colors.$blue-gray-100;
      border-radius: 4px;
      color: colors.$blue-gray-900;
      box-shadow: none;
    }

    > button {
      justify-self: flex-end;
    }
  }
}
