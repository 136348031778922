@use "../card"as card;
@use "../typography"as typography;
@use "../switch"as switch;
@use "../constants"as constants;
@use "../form"as form;
@use "../buttons"as buttons;

.store-select-widget {
  .list {
    display: grid;
    grid-gap: 20px;
  }

  .item {
    @include card.container;
    @include card.content;
    display: grid;
    grid-template-areas:
      'a b'
      'a c';
    grid-template-columns: min-content 1fr;
    align-items: center;
    cursor: pointer;

    .icon {
      i {
        grid-area: a;
        border-radius: 50%;
        padding: 5px;
        background-color: var(--color-secondary);
        color: white;
      }

      margin-top: 1px;
      margin-right: 7px;
    }

    .name {
      grid-area: b;
      @include typography.h3;
      margin-bottom: 2px;
    }

    .address {
      grid-area: c;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.1px;
      color: #666666;
    }
  }
}
