@use '../card';
@use '../colors';
@use '../viewport';

.uber-eats-integration-setup-item-widget {
  @include card.container;
  padding: 24px;
  box-shadow: none;

  > .header {
    display: flex;

    > .title {
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
    }
  }

  > .content {
    padding-top: 16px;
    font-size: 16px;
  }
}
