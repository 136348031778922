@use '../card'as card;
@use "../constants" as constants;
@use "../typography" as typography;

.backoffice {
  .batch-upload-jobs-page {
    .batch-upload-jobs-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      grid-gap: 15px;

      .batch-upload-job {
        @include card.container;

        display: grid;
        grid-template-areas:
          'id-group             id-group'
          'status-label         status'
          'failed-stores-label  failed-stores'
          'created-time-label   created-time'
          'date-finished-label  date-finished'
          'actions              actions';
        grid-template-columns: max-content 1fr;
        overflow: hidden;

        .id-label,
        .id,
        .status-label,
        .status,
        .failed-stores-label,
        .failed-stores,
        .created-time-label,
        .created-time,
        .date-finished-label,
        .date-finished,
        .actions {
          padding: 15px;
        }

        .id-group {
          grid-area: id-group;
          display: grid;
          grid-template-areas: 'id-label   id';
          @include typography.h2;
          border-bottom: constants.$line;
        }

        .id-label {
          grid-area: id-label;
        }

        .status-label {
          grid-area: status-label;
        }

        .created-time-label {
          grid-area: created-time-label;
          color: #696974;
        }

        .date-finished-label {
          grid-area: date-finished-label;
          color: #696974;
        }

        .failed-stores-label {
          grid-area: failed-stores-label;
        }

        .id {
          grid-area: id;
          justify-self: end;
        }

        .status {
          grid-area: status;
          justify-self: end;
        }

        .failed-stores {
          grid-area: failed-stores;
          justify-self: end;
        }

        .created-time {
          grid-area: created-time;
          justify-self: end;
          color: #696974;
        }

        .date-finished {
          grid-area: date-finished;
          justify-self: end;
          color: #696974;
        }

        .actions {
          grid-area: actions;
          border-top: constants.$line;

          button {
            cursor: pointer;
            width: 100px;
          }
        }
      }
    }
  }
}
