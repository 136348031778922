@use '../colors';
@use '../viewport';

.manage-store-integration-list-widget {
  > .header {
    display: none;
  }

  .store-integrations {
    display: grid;
    gap: 12px;
  }

  @include viewport.desktop {
    > .header {
      display: grid;
      grid-template-columns: 40% 1fr 1fr 100px;
      padding: 0 20px;
      color: colors.$blue-gray-500;
      align-items: center;
      margin-bottom: 12px;
    }

    .category {
      all: unset;
    }

    .store-integrations {
      display: grid;
    }

    .info {
      display: contents;
    }

    .manage-store-integration-list-item-widget {
      display: grid;
      grid-template-columns: 40% 1fr 1fr 100px;
    }
  }
}
