@use '../themes/default' as default-theme;
@use '../typography' as typography;
@use '../scrollbar' as scrollbar;
@use '../viewport' as viewport;

.new-order-details-widget {
  display: grid;
  grid-template-areas:
    'title'
    'store-order-details'
    'actions';
  max-width: 440px;

  .title {
    grid-area: title;
    display: flex;
    justify-content: space-between;
    padding: 16px 20px;

    .text {
      @include typography.h1;
    }
    .close-icon {
      width: 10px;
      cursor: pointer;
    }
  }

  .store-order-details {
    grid-area: store-order-details;
    display: grid;
    grid-template-areas:
      'store-details'
      'order-details'
      'order-items'
      'amounts';
    max-height: 60vh;
    overflow: auto;
    @include scrollbar.hide();
    font-size: 13px;
    font-weight: 300;

    .store-details {
      grid-area: store-details;
      word-break: break-all;
      display: flex;
      gap: 16px;
      padding: 16px 20px;

      .logo {
        width: 70px;

        .image > img {
          border-radius: 10px;
        }
      }

      .store-name {
        @include typography.h2;
      }
    }

    .order-details {
      display: flex;
      flex-direction: column;
      gap: 8px;
      background-color: #fafafb;
      padding: 20px;

      .order-detail {
        display: grid;
        grid-template-columns: 180px 1fr;

        .label {
          font-weight: 500;
        }
      }

      .order-status .badge {
        width: fit-content;
      }
    }

    .order-items {
      grid-area: order-items;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 20px 0px 10px 0px;
      margin: 0px 20px;
      border-bottom: 1px solid #f0f0f0;

      .item {
        display: flex;
        justify-content: space-between;

        .name {
          font-weight: 500;
        }

        .description {
          @include typography.h6;
          white-space: pre-wrap;
        }

        .comments {
          @include typography.h6;
        }
      }
    }

    .amounts {
      grid-area: amounts;
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 10px 20px;

      > div {
        display: flex;
        justify-content: space-between;
        font-weight: 500;

        .label {
          color: #6c6c6c;
        }

        .value {
          color: #6c6c6c;
        }
      }

      .grand-total {
        .label {
          @include typography.h2;
          font-weight: 600;
        }
        .value {
          @include typography.h2;
          font-weight: 600;
        }
      }
    }
  }

  .actions {
    grid-area: actions;
    display: flex;
    gap: 10px;
    padding: 20px;
    background-color: #fafafb;
    border-top: 1px solid #f0f0f0;

    button {
      width: 100%;
      padding: 9.5px 0px;
      cursor: pointer;

      .label {
        @include typography.h3;
        color: #{default-theme.$bg};
      }
    }

    .reject {
      background-color: #{default-theme.$form-element-error};
    }

    .accept,
    .mark-as-complete {
      background-color: #{default-theme.$button-success-bg};
    }
  }

  .form-single-select-overlay {
    margin-top: -496px;
    margin-left: 242px;

    .options {
      grid-gap: 10px;
      padding: 10px 12px;
      top: -16px;
      left: -16px;
      width: 84%;
    }
  }
}

@include viewport.mobile {
  .new-order-details-widget {
    .form-single-select-overlay {
      .overlay {
        align-items: end;
        justify-content: end;
        padding: 40% 8%;
      }
    }
  }
}
