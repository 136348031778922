@import '../form';

.restaurant-browse-page {
  .ais-SearchBox {
    .ais-SearchBox-form {
      position: relative;
      display: block;
      width: fit-content;
    }

    input {
      @include form-input();
      width: 350px;
      text-indent: 25px;
      background-image: url('../images/search-icon.svg');
      background-repeat: no-repeat;
      background-position: 12px center;
      background-size: 17px;
      margin-bottom: 25px;
    }

    // removes 'x' in search input
    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
      display: none;
    }

    button {
      display: none;
    }
  }

  .ais-Pagination-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .ais-Pagination-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .ais-Pagination {
    color: #3a4570;
  }

  .ais-Pagination-link {
    color: var(--color-primary);
    transition: color 0.2s ease-out;
    text-decoration: none;
  }

  .ais-Pagination-list {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .ais-Pagination-item + .ais-Pagination-item {
    margin-left: 0.3rem;
  }

  .ais-Pagination-link {
    padding: 0.3rem 0.6rem;
    display: block;
    border: 1px solid #c4c8d8;
    border-radius: 5px;
    transition: background-color 0.2s ease-out;
  }

  .ais-Pagination-item--disabled .ais-Pagination-link {
    opacity: 0.6;
    cursor: not-allowed;
    color: var(--message-disabled-text);
  }

  .ais-Pagination-item--disabled .ais-Pagination-link:focus,
  .ais-Pagination-item--disabled .ais-Pagination-link:hover {
    color: var(--message-disabled-text);
  }

  .ais-Pagination-item--selected .ais-Pagination-link {
    color: #fff;
    background-color: var(--color-primary);
    border-color: var(--color-primary);
  }
}
