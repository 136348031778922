@use '../viewport';

.anz-worldline-delete-integration-alert.warning.alert-widget {
  max-width: unset;
  width: 50vw;
  min-width: 357px;
  max-width: 573px;

  > .content {
    text-align: left;
  }

  > .actions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 'cancel-button proceed-button';
    margin-top: unset;

    .proceed-button {
      grid-area: proceed-button;
    }
    .cancel-button {
      grid-area: cancel-button;
    }
  }

  @include viewport.mobile {
    width: 90vw;
    min-width: unset;

    > .actions {
      display: flex;
    }
  }
}
