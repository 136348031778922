@use "../colors";
@use "../viewport";

.category-sales-list-item-widget {
  padding: 0 16px 16px;
  min-height: 180px;
  max-height: 180px;
  overflow-y: scroll;
  border: 1px solid colors.$blue-gray-100;
  border-radius: 12px;
  height: min-content;
  max-width: 350px;
  scrollbar-color: colors.$blue-gray-100 transparent;
  scrollbar-width: thin;
  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
  }

  @include viewport.mobile {
    max-width: 100%;
  }

  &.assigned-items {
    border-color: colors.$teal-500;
  }

  .item {
    cursor: pointer;
    max-width: 280px;
    margin-bottom: 2px;
    padding: 4px 8px;
    border-radius: 6px;
    @include viewport.mobile {
      text-wrap: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &.selected {
      background-color: colors.$blue-gray-50;
    }
  }

  > .text-input {
    input {
      border: none;
      outline: none;
      padding: 12px 16px 8px 32px;
      background-color: transparent;
      background-image: url('../images/search-icon-gray.svg');
      background-repeat: no-repeat;
      background-size: 16px;
      background-position: 8px 10px;

      &::placeholder {
        color: colors.$blue-gray-200;
      }
    }
  }

  .placeholder {
    color: colors.$blue-gray-500;
    padding-top: 18px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: colors.$blue-gray-100;
    border-radius: 8px;
  }
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    height: 90%;
  }

  &::-webkit-scrollbar-track-piece:end {
    margin-bottom: 8px;
  }

  &::-webkit-scrollbar-track-piece:start {
    margin-top: 8px;
  }
}
