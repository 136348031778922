@use '../viewport' as viewport;

.time-period-edit-widget {
  > .form-input {
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
  }

  > .meta > .error {
    display: none;
  }

  .start-time {
    max-width: 300px;
    width: 16vw;
    min-width: max-content;

    .input-box {
      display: grid;
      grid-template-columns: repeat(6, max-content) 1fr;
      align-items: center;
      .la-clock {
        text-align: right;
      }
    }
  }

  .duration {
    input[type='number'] {
      width: 100px;
    }
  }

  .end-time-of-day {
    padding: 10px 20px;
    font-weight: 500;
    font-size: 14px;
  }
}
