@use '../card';
@use '../colors';
@use '../viewport';

.sms-marketing-add-on-info-widget {
  display: grid;
  gap: 20px;

  .sender-informations {
    @include card.container;
    padding: 20px;

    @include viewport.tablet {
      padding: 30px;
    }

    .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      color: colors.$blue-gray-900;
      margin-bottom: 25px;
    }

    .sub-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: colors.$blue-gray-900;
    }

    .informations {
      border-top: 1px solid colors.$light-gray-300;
      margin-top: 10px;
      padding-top: 10px;
      display: grid;
      gap: 15px;

      .information {
        display: grid;
        grid-auto-flow: row;
        gap: 5px;

        @include viewport.tablet {
          grid-auto-flow: column;
          grid-template-columns: 20% 80%;
          gap: 15px;
        }

        > div {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;

          &.label {
            color: colors.$blue-gray-500;
          }

          &.value {
            color: colors.$blue-gray-900;

            .view-terms-link {
              font-size: 14px;
              font-weight: 500;
              line-height: 21px;
              color: colors.$blue-600;
              text-decoration: underline;
            }

            .signed-by {
              font-size: 14px;
              font-weight: 400;
              line-height: 21px;
              color: colors.$blue-gray-500;
              font-style: italic;
              margin-top: 15px;

              @include viewport.tablet {
                margin-top: 5px;
              }
            }
          }
        }
      }
    }
  }

  .email-updates {
    @include card.container;
    padding: 20px;

    @include viewport.tablet {
      padding: 30px;
    }

    .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      color: colors.$blue-gray-900;
      margin-bottom: 10px;
    }

    .sub-title {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: colors.$blue-gray-700;
    }

    > .sms-marketing-recipient-emails-widget {
      margin: 0;

      > .form-item {
        margin-top: 25px;

        label {
          margin-left: 0;
        }

        .react-tagsinput {
          padding-left: 0;
        }
      }

      > .emails-container {
        padding-left: 0;

        .label {
          margin-top: 25px;
        }
      }
    }
  }

  .preview-campaigns {
    @include card.container;
    padding: 20px;

    @include viewport.tablet {
      padding: 30px;
      display: grid;
      grid-template-areas:
        'title       title'
        'sub-title   go-to-sms-campaigns-link';
      grid-template-columns: 1fr max-content;
      gap: 0 15px;
    }

    .title {
      grid-area: title;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      color: colors.$blue-gray-900;
      margin-bottom: 25px;
    }

    .sub-title {
      grid-area: sub-title;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: colors.$blue-gray-900;
      margin-bottom: 10px;

      @include viewport.tablet {
        margin-bottom: 0;
      }
    }

    .go-to-sms-campaigns-link {
      grid-area: go-to-sms-campaigns-link;
      justify-self: flex-end;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      color: colors.$blue-600;
      text-decoration: underline;
    }
  }

  .unlink-sender-id {
    text-decoration: underline;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: colors.$rose-800;
    margin: 40px 15px;
    margin-top: 20px;
    cursor: pointer;
  }
}

.swal2-container {
  &.cancel-scheduled-sms {
    .swal2-popup {
      width: auto;
    }

    .swal2-icon {
      height: 56px;
      width: 56px;
      border-color: colors.$yellow-500;
      color: colors.$yellow-500;
      .swal2-icon-content {
        font-size: 45px;
      }
    }

    .swal2-title {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.005em;
      color: colors.$blue-gray-700;
    }

    .swal2-actions {
      flex-direction: column-reverse;
      @include viewport.desktop {
        flex-direction: row-reverse;
        button {
          min-width: 220px;
        }
      }
    }
  }
}
