@use "../card"as card;
@use "../constants"as constants;
@use "../buttons"as buttons;
@use "../viewport"as viewport;

.printer-queue {
  @include card.container;

  .title {
    @include card.header();
    @include card.header-font();
  }

  .list {
    padding: 12px 18px;
  }

  .order-list-view {
    display: grid;
    grid-template-areas:
      'restaurant-icon  store-name'
      'restaurant-icon  collection-type'
      '.                grand-total'
      '.                actions';

    grid-template-columns: min-content 1fr min-content;
    border-bottom: constants.$line;

    &:last-child {
      border-bottom: none;
    }

    .optimized-image {
      grid-area: restaurant-icon;
      margin: 0 15px;
      align-self: flex-start;
      width: 45px;
      border-radius: constants.$border-radius;
      border: 3px solid #f1f1f5;
      overflow: hidden;
    }

    .store-name {
      grid-area: store-name;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #000521;
      align-self: center;
    }
    .grand-total {
      grid-area: grand-total;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #fa7268;
    }
    .collection-type {
      grid-area: collection-type;
      white-space: nowrap;
      align-self: center;
      font-family: Poppins;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #696974;
    }
    .message {
      grid-area: message;
    }
    .status {
      grid-area: status;
      white-space: nowrap;
      justify-self: start;
      align-self: center;
    }
    .actions {
      grid-area: actions;
      margin-top: 10px;
      padding-top: 15px;
      border-top: constants.$line-dashed;
    }

    .actions {
      display: flex;
      flex-direction: row;
      button {
        @include buttons.primary-transparent;
        @include buttons.badge-button;
        margin-right: 7px;
      }
      .accept-order {
        @include buttons.success-transparent;
      }
      .reject-order {
        @include buttons.failure-transparent;
      }
    }
  }
}
