@use "../card"as card;

.transaction-fee-page {
  .transaction-fee-widget {
    @include card.container;

    .form-items > .form-item > label {
        margin: 0 10px 0 15px;
    }
    
    form {
      @include card.content;
      margin-top: 20px;
    }
  }
}
