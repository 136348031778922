@use '../card';
@use '../constants';
@use '../typography';
@use '../viewport';
@use '../buttons';
@use '../colors';
@use '../elements';
@use '../gap';
@use '../pill';

.online-order-history-website-order-list-item-widget {
  @include card.container;
  @include gap.vertical(18px);
  margin-top: 20px;
  padding: 25px;

  > .header {
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    grid-template-areas:
      'collection-type               website-order-icon           order-status'
      'placed-time                   placed-time                  grand-total';

    .collection-type {
      @include typography.h2;
      grid-area: collection-type;
      color: #000521;
      align-self: center;
      white-space: nowrap;
      margin-bottom: 10px;
    }

    .website-order-icon {
      grid-area: website-order-icon;
      margin-left: 5px;
    }

    .order-status {
      grid-area: order-status;
      white-space: nowrap;
      justify-self: end;
      align-self: center;
      padding: 5px 8px;
      font-size: 13px;
    }

    .placed-time {
      grid-area: placed-time;
      white-space: nowrap;
      align-self: center;
      font-style: normal;
      color: #6c6c6c;
      margin-bottom: 10px;
      font-size: 13px;
      font-weight: 300px;
    }

    .grand-total {
      @include typography.h3;
      grid-area: grand-total;
      font-style: normal;
      align-self: center;
      text-align: right;
    }
  }

  > .body {
    @include gap.vertical(5px);
    border-top: constants.$line-dashed;
    border-bottom: constants.$line-dashed;
    padding: 10px 0px;
    > * {
      display: flex;
      > .icon {
        @include elements.icon(18px);
      }
      > .info {
        margin-left: 10px;
      }
    }

    .delivery-status {
      margin: 15px 0px;
      font-weight: 500;

      > .icon {
        width: 18px;
      }

      &.in-house {
        display: flex;
        color: var(--message-disabled-text);
        .label {
          margin-left: 10px;
        }
      }

      &.uber {
        display: flex;
        .uber-icon {
          width: 30px;
          margin: 0px 10px;
        }
        .label {
          &.ongoing {
            color: var(--message-info-fg);
          }

          &.failed {
            color: var(--message-error-text);
          }

          &.success {
            color: var(--message-success-text);
          }
        }
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    button {
      @include buttons.primary-transparent;
      @include buttons.badge-button;
      padding: 4px 15px;
      margin-right: 10px;
    }
    .accept-order {
      @include buttons.success-transparent;
    }
    .reject-order {
      @include buttons.failure-transparent;
    }
    .options {
      margin-top: 2rem;
    }
  }
}
