@use '../card' as card;
@use '../typography' as typography;
@use '../constants' as constants;

$grid-gap: 20px;

@mixin title {
  @include typography.h3;
  color: var(--color-secondary);
}

@mixin modifiers {
  display: grid;
  grid-gap: $grid-gap;
}

.online-ordering {
  .modifiers {
    @include modifiers();
  }

  .modifier {
    @include card.container;

    .header {
      @include card.header;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .title {
        @include title();
      }
    }

    .options {
      @include card.content;
      display: grid;

      .option:last-child {
        border-bottom: 0px;
      }
    }

    .option {
      display: grid;
      grid-template-columns: min-content 1fr min-content;
      padding: 10px 0;
      border-bottom: constants.$line-dashed;
      font-weight: 400;
      color: #555555;

      &.selected {
        color: var(--fg);
        font-weight: 500;
      }

      input {
        margin-right: 12px;
      }
    }

    .option {
      grid-template-columns: max-content 1fr 75px;
      grid-template-areas: 'a b c';
      align-items: center;

      .clickable {
        grid-area: a;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 10px;
      }

      .quantity {
        grid-area: b;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        height: 20px;

        .number {
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          color: #000521;
        }

        .icon {
          cursor: pointer;
        }

        .dec {
          padding-right: 10px;
          padding-left: 15px;
        }
        .inc {
          padding-left: 10px;
          padding-right: 15px;
        }

        .icon {
          color: #8d92a3;
          font-size: 20px;
        }
      }

      .extra-charge {
        grid-area: c;
        white-space: nowrap;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
