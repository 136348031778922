@use '../colors';
@use '../buttons';
@use '../viewport';

.alert-widget {
  display: flex;
  flex-direction: column;
  padding: 32px;
  text-align: center;
  align-items: center;
  width: 50vw;
  min-width: 357px;
  max-width: 573px;
  box-sizing: border-box;

  > .icon {
    width: 64px;
  }

  > .title {
    color: colors.$blue-gray-900;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    white-space: pre-wrap;
  }

  > .content {
    margin-top: 16px;
    color: colors.$blue-gray-500;
    font-size: 16px;
    font-weight: 400;
  }

  > .actions {
    width: 100%;
    height: min-content;
    margin-top: 24px;

    button {
      @include buttons.primary;
      font-size: 16px;
      font-weight: 500;
      border-radius: 4px;
      width: inherit;
    }
  }

  > a {
    color: colors.$teal-600;
    text-decoration: none;
  }

  &.warning,
  &.info {
    max-width: 397px;

    > .actions {
      display: grid;
      gap: 16px;

      button {
        padding: 14px 32px;
      }

      .cancel-button {
        background: none;
        color: colors.$blue-gray-700;
        border: 1px solid colors.$blue-gray-500;
      }
      .proceed-button {
        background-color: colors.$rose-800;
        color: colors.$white;
      }
    }
  }

  @include viewport.mobile {
    display: flex;
    .actions {
      display: flex;
      flex-direction: column;
    }
  }
}
