@use '../colors';
@use '../viewport';

.uber-eats-store-information-widget {
  .unauthorised-view {
    display: grid;
    grid-template-columns: 1fr min-content;
    gap: 16px;
    align-items: center;
  }

  .no-store-information-view {
    .uber-eats-integrate-store-widget {
      margin-top: 32px;
    }
  }

  .store-information-view {
    .title {
      border-bottom: 1px solid colors.$blue-gray-100;
      font-size: 16px;
      font-weight: 500;
      padding: 12px 0;
    }

    .info {
      display: grid;
      gap: 16px 0;
      padding: 20px 0;
    }

    .key-value-pair {
      display: grid;
      grid-template-columns: 120px 1fr;
      gap: 8px;

      .label {
        color: colors.$blue-gray-500;
      }

      @include viewport.mobile {
        display: unset;
        .value {
          word-break: break-all;
        }
      }
    }
  }
}
