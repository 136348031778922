@use "viewport";
@use './scrollbar';
@use './card';
@use './colors';

@mixin close-button {
  position: absolute;
  width: 27px;
  right: 10px;
  top: 15px;
  cursor: pointer;
}

.ReactModalPortal {
  .ReactModal__Overlay {
    z-index: 1000;
    display: flex;
    background-color: #{colors.$black}99 !important;

    .ReactModal__Content {
      border-radius: 0 !important;
      padding: 0 !important;
      border: unset !important;
      background: none !important;

      .modal {
        @include card.container;
        @include scrollbar.hide;
        max-height: calc(100vh - 100px);
        max-width: 90vw;
        overflow: auto;
        border-radius: 20px;

        .close {
          @include close-button();
        }

        @include viewport.mobile {
          &.bottom-sheet {
            border-radius: 20px 20px 0 0;
            position: fixed;
            max-width: 100vw;
            max-height: -webkit-fill-available;
            left: 0;
            right: 0;
            bottom: 0;
            overflow-x: hidden;
            overflow-y: scroll;
          }
        }
      }
    }
  }
}

@mixin container {
  @include viewport.mobile {
    max-width: 80vw;
    max-height: 80vh;
    overflow: auto;
  }

  @include viewport.tablet {
  }
}
