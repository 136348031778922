@use 'colors' as colors;
@use 'elements' as elements;

/**
  * Form Text Input
  */
@mixin form-text-input {
  display: flex;

  input {
    flex-grow: 1;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: unset;

    padding: 0px 12px;

    &:focus {
      outline: none;
    }

    &:disabled {
      background-color: unset !important;
    }

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: colors.$blue-gray-400;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: colors.$blue-gray-400;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: colors.$blue-gray-400;
    }
  }

  .clear-icon {
    @include elements.icon($size: 24px);
    cursor: pointer;
    margin: -14px 12px 0px 0px;
  }
}
