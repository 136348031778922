@use '../colors';
@use '../viewport';

.uber-eats-integration-action-widget {
  padding: 9.5px 16px;

  .cancel-integration-setup-button,
  .delete-integration-button {
    background: none;
    box-shadow: none;
    font-size: 14px;
    text-decoration: underline;
    display: inline;
    font-weight: 500;
  }

  .delete-integration-button.disabled {
    color: colors.$rose-300 !important;
    cursor: not-allowed;
  }

  .cancel-integration-setup-button.disabled {
    color: colors.$light-gray-700 !important;
    cursor: not-allowed;
  }

  .delete-integration-button {
    color: colors.$rose-800;
  }

  .incorrect-account-notice,
  .disabled-cancellation-help-text {
    display: inline;
    font-size: 14px;
    color: colors.$blue-gray-500;
    margin-left: 16px;
  }

  @include viewport.mobile {
    padding: 0;
    button {
      padding: 0;
    }
  }
}
