@use '../card' as card;
@use '../buttons' as buttons;
@use '../viewport' as viewport;

.table-edit-widget form {
  @include card.container;
  padding: 24px 20px;

  .qr-code {
    display: grid;
    grid-template-columns: 180px 4fr 1fr;

    label {
      justify-self: end;
      margin-right: 15px;
    }

    canvas {
      border: 1px solid var(--form-element-border);
      border-radius: 10px;
    }
  }

  .form-buttons {
    @include buttons.button-group;
    padding: 20px 0;

    .submit-btn {
      padding: 9.5px 32px;
    }
    .cancel-btn {
      @include buttons.primary-transparent;
      padding: 9.5px 20px;
      border: 0.5px solid #dadada;
      box-shadow: none;
    }
  }

  @include viewport.mobile {
    .qr-code {
      display: flex;
      flex-direction: column;
    }
  }
}
