@use "../card"as card;
@use "../constants"as constants;
@use "../form"as form;
@use "../buttons"as buttons;
@use "../viewport"as viewport;

$padding-horizontal: 15px;

.delivery-settings-widget {
  @include card.container;

  form {
    @include card.content;
    margin-top: 20px;
  }

  .form-input {
    .delivery-suburb {
      @include card.container;
      @include card.stack;
      margin-bottom: 20px;

      .info {
        @include card.upper;
        @include form.inline;

        @include viewport.desktop {
          grid-auto-flow: column;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(2, 1fr);
        }
      }

      .actions {
        @include card.lower;
        @include card.round-buttons;
        .action {
          @include card.round-button;
        }
      }
    }

    .delivery-suburbs {
      button {
        @include buttons.primary-transparent;
      }
    }
  }

  .delivery-suburbs-form-item > label {
    @include form.top-align-label();
  }
}
