@use "../card";
@use "../buttons";
@use "../colors";
@use "../viewport";

.employee-edit-widget {
  @include card.container();

  @include viewport.desktop {
    min-width: 367px;
  }

  .header {
    @include card.header();
    padding: 20px;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
  }

  .form-items {
    grid-row-gap: 0;
  }

  .form-item {
    margin: 0 20px;
    padding: 10px 0;  
    > label {
      padding-right: 80px;
    }

    &.permission {
      border-bottom: 1px solid colors.$blue-gray-100;
    }
  }

  .permissions-title {
    margin: 0;
    padding-left: 20px;
    background-color: colors.$blue-gray-50;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.001em;
  }

  .inline-title {
    margin: 0;
  }

  .form-buttons {
    display: flex;
    gap: 8px;
    @include card.footer();

    button {
      width: max-content;
      padding: 9.5px 32px;
    }

    .cancel-btn {
      @include buttons.cancel-button;
    }
  }

  .discount-amount-limit {
    margin-top: 0;

    > .form-input {
      padding-top: 6px;
    }
  }

  .report-permissions-toggle {
    padding-left: 24px;
    cursor: pointer;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.001em;
    display: flex;
    align-items: center;

    .toggle-icon {
      margin-left: 8px;
      &.shown{
        transform: rotate(180deg);
      }
    }
  }

  .report-permissions {
    padding-left: 20px;

    .permissions {
      > .form-item {
        > label {
          padding-right: 0;
        }
      }
    }
  }
}
