@use "../card" as card;
@use "../typography" as typography;
@use "../buttons"as buttons;
@use "../form"as form;
@use "../viewport" as viewport;

.print-station-list-widget {
  @include card.container();
  height: min-content;

  .title {
    @include card.header();
    @include card.header-font();
    @include typography.h3;
  }

  .print-stations {
    padding: 20px 18px;
    display: grid;
    grid-gap: 20px;
  }

  .empty-list {
    padding: 20px;
  }

  .actions > button {
    @include buttons.primary-transparent;
    margin: 20px 18px;
    margin-top: 0;
  }

  .print-station {
    @include card.container;
    .print-station-info {
      padding: 24px;
      display: flex;
      flex-flow: column;
      grid-gap: 24px;
      .name {
        @include typography.h2;
      }
      .id {
        display: grid;
        grid-template-columns: 1fr min-content;
      }
    }
    .actions {
      @include card.footer;
      @include card.round-button;
      .action {
        display: inline;
      }
    }
  }
}
