@use '../card';
@use '../colors';

.integrations-list-item-widget {
  @include card.container;
  max-width: 550px;
  display: grid;
  grid-template-columns: 1fr min-content;
  padding: 24px;

  .info {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-template-areas:
      'logo name'
      'logo status'
      'description description';
    gap: 8px 16px;

    .logo {
      grid-area: logo;
    }
    .name {
      grid-area: name;
      font-size: 18px;
      font-weight: 500;
    }
    .status {
      grid-area: status;
      color: colors.$blue-gray-700;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.035px;
      &.is-enabled {
        color: colors.$green-600;
      }
    }

    .description {
      grid-area: description;
      margin-top: 10px;
      color: colors.$blue-gray-500;
    }
  }
}
