@use "viewport";
@use "colors";

.tabs {
  display: flex;
  border-bottom: 1px solid colors.$blue-gray-100;
  margin-bottom: 24px;
  overflow: hidden;
  height: 100%;
  gap: 12px;

  @include viewport.mobile {
    font-size: 14px;
    overflow-x: scroll;
  }

  .tab {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 12px;
    color: colors.$blue-gray-700;
    text-wrap: nowrap;
    cursor: pointer;

    &.active {
      color: colors.$blue-600;
      font-weight: 500;
      border-bottom: 4px solid colors.$blue-600;
    }

    @include viewport.mobile {
      font-size: 12px;
      padding: 8px;
    }
  }
}
