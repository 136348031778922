@use "../typography"as typography;
@use "../colors" as colors;

.delivery-channel-selector-widget {
  display: grid;
  grid-template-areas:
    'title               title'
    'in-house            uber';
  grid-template-columns: repeat(2, 1fr);
  gap: 0 20px;
  padding: 20px;

  .title {
    grid-area: title;
    @include typography.h1;
    text-align: center;
    margin-bottom: 20px;
  }

  .in-house-delivery-option {
    grid-area: in-house;
  }

  .uber-direct-option {
    grid-area: uber;
  }

  .in-house-delivery-option,
  .uber-direct-option {
    @include typography.h3;
    text-align: center;
    padding: 35px;
    border: 1px solid #{colors.$med-gray};
    border-radius: 10px;
    color: #{colors.$blue};
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      border: 1px solid #{colors.$blue};
    }

    .icon {
      width: 45px;
      margin-bottom: 17px;
    }
  }
}
