@use "../card"as card;
@use "../constants"as constants;
@use "../scrollbar"as scrollbar;
@use "../typography"as typography;
@use "../viewport"as viewport;

.restaurant-website {
  header {
    position: fixed;
    top: 0;
    width: 100%;
  }

  main {
    padding: constants.$horizontal-gap;
    padding-top: constants.$header-height + 20px;
    margin-top: var(--safe-area-inset-top);
  }

  @include viewport.desktop {
    main {
      max-width: constants.$max-body-width;
      margin: 0 auto;
    }
  }
}
