@use '../card'as card;
@use '../constants'as constants;
@use '../typography'as typography;
@use '../viewport'as viewport;

.website-items-page {
  .items {
    display: grid;
    grid-gap: 20px;
  }

  .item {
    @include card.container;
    overflow: hidden;

    @include viewport.mobile {
      .body {
        display: grid;
        grid-template-columns: 1fr min-content;
        grid-template-rows: min-content 1fr min-content;
        grid-template-areas:
          "a a"
          "b c"
          "d d";

        .image {
          overflow: hidden;
          grid-area: a;

          img {
            border-radius: constants.$border-radius;
          }
        }

        .name {
          @include typography.h2;
          grid-area: b;
          align-self: flex-start;
        }
        .price {
          grid-area: c;
          @include typography.h2;
          color: var(--color-secondary);
          align-self: start;
        }

        .description {
          @include typography.h6;
          font-weight: 400;
          font-size: 12px;
          grid-area: d;
          align-self: flex-start;
          padding-bottom: 10px;
        }

        $padding-horizontal: 12px;
        $curve-padding: 6px;

        .name,
        .price,
        .description {
          padding-left: $padding-horizontal + $curve-padding;
          padding-right: $padding-horizontal + $curve-padding;
        }

        .image {
          margin-left: $padding-horizontal;
          margin-right: $padding-horizontal;
          padding-top: 14px;
          padding-bottom: 20px;
          border-bottom: 1px dashed #e9ecf3;
        }

        .name,
        .price {
          padding-top: 12px;
        }

        .description {
          padding-bottom: 15px;
        }
      }
    }
    @include viewport.tablet {
      .body {
        // padding: 12px;
        @include card.content;
        display: grid;
        grid-template-columns: 1fr min-content;
        grid-template-rows: min-content 1fr min-content;
        grid-template-areas:
          "a d"
          "b d"
          "c d";

        .image {
          overflow: hidden;
          grid-area: d;
          width: 100px;
          margin-left: 10px;

          img {
            border-radius: constants.$border-radius;
          }
        }

        .name {
          @include typography.h2;
          grid-area: a;
          align-self: flex-start;
        }

        .description {
          @include typography.h6;
          font-weight: 400;
          font-size: 12px;
          grid-area: b;
          align-self: flex-start;
        }

        .price {
          grid-area: c;
          @include typography.h2;
          color: var(--color-secondary);
          align-self: flex-end;
          margin-top: 4px;
        }
      }
    }

    .action {
      grid-area: e;
      @include card.footer;
      padding: 12px 18px;
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        margin-right: 10px;
      }
      span {
        font-family: Fira Sans;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 25px;
        color: #426ed9;
      }
    }
  }
}
