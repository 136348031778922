@use "../colors";
@use "../typography";

.kds-prep-time-change-confirm-widget {
  padding: 32px 24px;
  border-radius: 20px;
  width: 357px;

  > .title {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color: colors.$blue-gray-900;
    margin-bottom: 16px;
    text-align: center;
  }

  > .message {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: colors.$blue-gray-500;
    margin-bottom: 24px;
    text-align: center;
  }

  > .buttons {
    .confirm-btn {
      padding: 14px 0;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: colors.$white;
      border-radius: 4px;
      margin-bottom: 12px;
      background-color: colors.$blue-600;
      width: 100%;
    }

    .go-back-btn {
      padding: 14px 0;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: colors.$blue-gray-700;
      border-radius: 4px;
      margin-bottom: 12px;
      background-color: colors.$white;
      border: 1px solid colors.$blue-gray-500;
      width: 100%;
    }
  }
}
